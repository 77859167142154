import React from 'react';
import { connect } from 'react-redux';
import { login as loginFetch } from '../../actions/Authentication';
import { push } from 'connected-react-router';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { compose } from 'react-recompose';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DE as TextDE } from '../../lib/Text';
import FooterHint from '../Other/FooterHint';
//import { ThemeProvider } from '@material-ui/core/styles';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: '15vh',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  my: {
    margin: theme.spacing(-1, 0, 1, 0),
  },
});

class Login extends React.Component {
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDelete = () => {
    this.setState({ error: false });
  };

  async handleSubmit(event) {
    if (event !== null) {
      event.preventDefault();
    }

    this.setState({ loading: true });

    if (!this.state.email.trim() || !this.state.password.trim()) {
      return;
    }
    let response = await this.props.dispatch(
      loginFetch({ email: this.state.email, password: this.state.password }),
    );
    //.then(response =>
    //	{
    //		console.log("response", response)
    //		if(response === false) {
    //			this.setState({ loading:false, error: "Server antwortet nicht. Bitte nocheinmal probieren!", errorCount: this.state.errorCount++})
    //		}
    //	}
    //)
    //.catch(error =>
    //	console.log("error", error)
    //)

    if (response !== true) {
      const message = !!response?.message ? response.message : response;
      switch (message) {
        case 'Failed to fetch':
          this.setState({
            loading: true,
            error: TextDE.loginForm.errorRetryCount(this.state.errorCount),
            errorCount: this.state.errorCount + 1,
          });
          await sleep(3000);
          if (this.state.errorCount < 4) {
            this.handleSubmit(null);
          } else {
            this.setState({
              loading: false,
              error: TextDE.loginForm.errorRetryCountFailed(this.state.errorCount),
              errorCount: this.state.errorCount + 1,
            });
          }
          break;
        default:
          this.setState({
            loading: false,
            error: TextDE.loginForm.errorCredentials,
            errorCount: 0,
          });
          break;
      }
    }
  }

  constructor() {
    super();
    this.state = {
      loading: true,
      error: false,
      email: '',
      password: '',
      errorCount: 0,
      passwordVisible: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: false });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Card className={classes.card} variant='outlined'>
              <div
                style={{
                  backgroundImage: 'url(/assets/icon.png)',
                  width: '100%',
                  minHeight: '20vh',
                  height: 'auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  marginBottom: '1em',
                }}
              ></div>
              <div
                style={{
                  backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
                  width: '100%',
                  minHeight: '60px',
                  height: 'auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              ></div>
              {!!this.state.error ? (
                <Alert className={classes.form} severity='error'>
                  {this.state.error}
                </Alert>
              ) : null}
              {this.state.loading ? (
                <Box my={4}>
                  {' '}
                  <CircularProgress />{' '}
                </Box>
              ) : (
                <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                  <TextField
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id='email'
                    label={TextDE.form.email.label}
                    name='email'
                    autoComplete='email'
                    onChange={this.handleChange}
                    value={this.state.email}
                    autoFocus
                  />

                  <FormControl variant='outlined' fullWidth required>
                    <InputLabel htmlFor='outlined-adornment-password'>
                      {TextDE.form.password.label}
                    </InputLabel>
                    <OutlinedInput
                      name='password'
                      id='password'
                      autoComplete='current-password'
                      type={!!this.state.passwordVisible ? 'text' : 'password'}
                      onChange={this.handleChange}
                      value={this.state.password}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              this.setState({
                                passwordVisible: !this.state.passwordVisible,
                              })
                            }
                            edge='end'
                          >
                            {!!this.state.passwordVisible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>

                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={!(this.state.email.length > 0 && this.state.password.length > 0)}
                    endIcon={<LockOutlinedIcon />}
                  >
                    {TextDE.login}
                  </Button>
                </form>
              )}

              <Divider />

              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.my}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Link href='/password/forgot' variant='overline' color='error' underline='always'>
                    {TextDE.passwordReset.label}
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  <Button href='/register' variant='outlined' color='primary'>
                    {TextDE.register.label}
                  </Button>
                </Grid>
              </Grid>

              <FooterHint />
            </Card>
          </div>
        </Container>
      </>
    );
  }
}
//export default Login;

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});
//export default connect(mapStateToProps)(Login);
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Login);
