import React from 'react';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import renderHtml from '../../../../lib/transformHtml';
import IconButton from '@material-ui/core/IconButton';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { readableTime } from '../../../../lib/dateFunctions';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import EditIcon from '@material-ui/icons/Edit';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Typography from '@material-ui/core/Typography';
import { DE as TextDE } from '../../../../lib/Text';
const styles = (theme) => ({
  gridExtended: {
    zIndex: 1,
    transition: 'z-index 33ms',
  },
  gridNotExtended: {
    zIndex: 0,
    transition: 'z-index 400ms',
  },
  cardRoot: {
    float: 'right',
    width: 'calc(100% - 2px)',
    backgroundColor: '#fffcdd',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      transition: 'width 400ms, margin-left 400ms',
    },
  },
  cardRootExtended: {
    float: 'right',
    backgroundColor: '#fffcdd',
    boxShadow: theme.shadows[16],
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 16px)',
    },
    [theme.breakpoints.up('md')]: {
      transition: 'width 400ms, margin-left 400ms',
      width: 'calc(110% - 2px)',
      marginLeft: '-10%',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(7),
      minHeight: theme.spacing(7),
      '&>svg': {
        minWidth: theme.spacing(5),
        minHeight: theme.spacing(5),
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    minHeight: '40px',
    height: 'auto',
    overflow: 'auto',
    fontSize: '120%',
    borderTop: '1px solid #efefef',
    backgroundColor: '#ffffff',
    flexGrow: 1,
  },
  paperSmall: {
    //minHeight: '40px',
    height: '68px',
    //maxHeight: '108px',
    overflow: 'clip',
    cursor: 'pointer',
    fontSize: '120%',
    padding: theme.spacing(1, 2) + '!important',
    borderTop: '1px solid #efefef',
    backgroundColor: '#ffffff',
    flexGrow: 1,
  },
  smallPaddingBottom: {
    paddingBottom: '2px',
  },
});

const respondFunction = 1;
const editFunction = 2;
const deleteFunction = 3;

class Chat extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openMenu: false, expanded: false };
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded, openMenu: false }, () => {
      if (!!this.state.expanded) {
        this.props.expandScrollTo();
      }
    });
  };

  handleOpen = (event) => {
    this.setState({ openMenu: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ openMenu: false });
  };

  callback(type) {
    this.handleClose();
    if (type === editFunction) {
      this.props.editFunction(this.props.item);
    }
    if (type === respondFunction) {
      this.props.respondFunction(this.props.item);
    }
    if (type === deleteFunction) {
      this.props.deleteFunction(this.props.item);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        key={'note-list-' + this.props.index}
        item
        xs={12}
        md={10}
        classes={{ root: this.state.expanded ? classes.gridExtended : classes.gridNotExtended }}
      >
        <Card
          raised
          classes={{ root: this.state.expanded ? classes.cardRootExtended : classes.cardRoot }}
        >
          <CardHeader
            className={!!this.props.item.data?.files?.text && classes.smallPaddingBottom}
            avatar={
              !!this.props.item.creator ? (
                <Avatar
                  className={classes.avatar}
                  alt={this.props.item.creator?.firstname + ' ' + this.props.item.creator?.lastname}
                  src={
                    process.env.REACT_APP_API_URL +
                    '/avatar/' +
                    this.props.item.creator?.uuid +
                    '?thumb'
                  }
                ></Avatar>
              ) : !!this.props.item.data?.creator ? (
                <Avatar
                  className={classes.avatar}
                  alt={this.props.item.data?.creator?.name}
                  src={this.props.item.data?.creator?.avatar}
                />
              ) : (
                <Avatar className={classes.avatar}>
                  <EditIcon />
                </Avatar>
              )
            }
            titleTypographyProps={{ variant: 'h5' }}
            title={
              (!!this.props.item.data?.subject
                ? '"' + this.props.item.data?.subject + '"'
                : 'Chat Nachricht') +
              (this.props.item.data?.receivers && this.props.item.data?.receivers?.length > 0
                ? ` an ${this.props.item.data?.receivers?.join(', ')}`
                : '')
            }
            subheaderTypographyProps={{ variant: 'h6', style: { display: 'block' } }}
            subheader={
              <>
                <SettingsEthernetIcon
                  fontSize='inherit'
                  color={'primary'}
                  style={{ margin: 'auto 12px auto 12px' }}
                />
                {(!!this.props.item.data?.creator
                  ? !!this.props.item.data?.creator?.name
                    ? ` von ${this.props.item.data?.creator?.name} `
                    : !!this.props.item.data?.creator?.lastname
                      ? ` von ${this.props.item.data.creator?.firstname ?? ''} ${
                          this.props.item.data.creator?.lastname ?? ''
                        } `
                      : ''
                  : '') +
                  'via FLiXWORKER Connect um ' +
                  readableTime(this.props.item.created_at)}
                {!!this.props.item.data?.files?.text && (
                  <Typography variant='subtitle2' component='span' display='block'>
                    <AttachFileIcon fontSize='inherit' style={{ margin: 'auto 12px auto 12px' }} />
                    {this.props.item.data?.files?.text}
                  </Typography>
                )}
              </>
            }
            action={
              <>
                <Hidden mdDown>
                  <Button
                    onClick={this.handleOpen}
                    fullWidth
                    variant='outlined'
                    color='primary'
                    startIcon={<MoreHorizIcon />}
                  >
                    {TextDE.procedure.protocol.more}
                  </Button>
                  <Tooltip
                    title={this.props.type.description}
                    aria-label={this.props.type.description}
                  >
                    <Box
                      fontSize='small'
                      component='div'
                      display='flex'
                      justifyContent='space-between'
                      mt={0.5}
                      mx={1}
                    >
                      Typ: {this.props.type.icon}
                    </Box>
                  </Tooltip>
                </Hidden>
                <Hidden lgUp>
                  <IconButton color='primary' aria-label='more' onClick={this.handleOpen}>
                    <MoreHorizIcon />
                  </IconButton>
                </Hidden>
              </>
            }
          />

          <Menu
            id='simple-menu'
            anchorEl={this.state.openMenu}
            open={Boolean(this.state.openMenu)}
            onClose={this.handleClose}
          >
            <MenuItem key={this.props.item.id + '-expandFunction'} onClick={this.toggleExpand}>
              <ListItemIcon>
                {this.state.expanded ? <PhotoSizeSelectSmallIcon /> : <AspectRatioIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  this.state.expanded
                    ? TextDE.procedure.protocol.collapse
                    : TextDE.procedure.protocol.expand
                }
              />
            </MenuItem>
            {!!this.props.item.created_by && !!this.props.editFunction && (
              <MenuItem
                key={this.props.item.id + '-editFunction'}
                onClick={() => this.callback(editFunction)}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={TextDE.procedure.protocol.edit} />
              </MenuItem>
            )}
            {!!!this.props.item.created_by && !!this.props.respondFunction && (
              <MenuItem
                key={this.props.item.id + '-respondFunction'}
                onClick={() => this.callback(respondFunction)}
              >
                <ListItemIcon>
                  <ReplyAllIcon />
                </ListItemIcon>
                <ListItemText primary={TextDE.procedure.protocol.respond} />
              </MenuItem>
            )}
            {!!this.props.deleteFunction && (
              <MenuItem
                key={this.props.item.id + '-deleteFunction'}
                onClick={() => this.callback(deleteFunction)}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={TextDE.delete} />
              </MenuItem>
            )}
          </Menu>
          <CardContent
            onClick={() => !!!this.state.expanded && this.toggleExpand()}
            className={!!this.state.expanded ? classes.paper : classes.paperSmall}
          >
            {renderHtml(
              this.props.item.data?.body,
              this.state.expanded,
              this.toggleExpand,
              false,
              true,
            )}
          </CardContent>
          {this.state.expanded && (
            <CardActions disableSpacing={false}>
              <Button onClick={this.toggleExpand} fullWidth>
                <KeyboardArrowUpIcon />
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    );
  }
}

export default compose(withStyles(styles))(Chat);
