import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { DropzoneArea } from 'material-ui-dropzone';
import Badge from '@material-ui/core/Badge';
import { Constants } from '../../lib/Constants';
import DialogTitle from '@material-ui/core/DialogTitle';
//import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
//import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import CustomToolbarComponent from './components/CustomToolbarComponent';
import { DE as TextDE } from '../../lib/Text';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    minHeight: '40vh',
    maxHeight: '96vh',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column',
  },
  wrapper: {
    marginTop: '1em',
  },
  form: {
    flexGrow: '1',
    display: 'flex',
    flexFlow: 'column',
  },
  dialogTitle: {
    textAlign: 'center',
    margin: theme.spacing(0, -1, 2, -1),
    padding: 0,
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  editorWrapper: {
    width: '100% !important',
    display: 'flex !important',
    flexDirection: 'column',
    height: '300px !important',
  },
  editor: {
    padding: theme.spacing(1, 1),
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: '#EEEEEE',
    borderWidth: '1px',
    height: 'calc( 100% - ' + theme.spacing(1) * 2 + 'px) !important',
    flexGrow: '1',
  },
  active: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
  },
  toolbar: {
    backgroundColor: '#EEEEEE',
  },
  dropzoneSize: {
    height: 'auto',
    minHeight: '218px',
  },
  xs: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&>svg': {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
});
class AddMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      createNewProcedure: true,
      subject: '',
      contacts: [],
      isSubmitting: false,
      hasError: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromText(props.message?.body?.text || ''),
      ),
      editorFocus: false,
      to: props.email || [],
      newFiles: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  handleContactChange = (event, value) => {
    if (value && value.inputValue) {
      this.setState({ to: value });
      return;
    }
    if (
      value.length > 0 &&
      stateToHTML(this.state.editorState.getCurrentContent()) === '<p><br></p>'
    ) {
      let greeting = value
        .map((contact) => {
          if (!!contact.full) {
            return contact.full;
          }
          return false;
        })
        .filter((contact) => contact !== false)
        .join(', ');
      this.setState({
        to: value,
        editorState: EditorState.createWithContent(
          ContentState.createFromText(TextDE.hello(greeting) + ',\n'),
        ),
      });
    } else {
      this.setState({ to: value });
    }
  };

  handleFileChange = async (files) => {
    this.setState({ newFiles: files });
  };

  async handleFormSubmit(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();

    formData.append('type_id', Constants.getInboxItemTypeId('email'));
    formData.append('to', JSON.stringify(this.state.to));
    formData.append('subject', this.state.subject);
    formData.append('body', stateToHTML(this.state.editorState.getCurrentContent()));

    this.state.newFiles.forEach((file, i) => {
      formData.append('uploads[]', file, file.name);
    });
    let url = process.env.REACT_APP_API_URL + '/api/procedures/newAndMail';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    //if (!!response.sent) {
    if (!!response.uuid) {
      this.setState({
        subject: '',
        editorState: EditorState.createEmpty(),
        to: [],
        newFiles: [],
        isSubmitting: false,
        hasError: false,
      });
      this.props.handleclosefunction(response.uuid);
    } else {
      if (!!response.message) {
        this.setState({ isSubmitting: false, hasError: response.message });
      } else {
        this.setState({
          isSubmitting: false,
          hasError: JSON.stringify(response),
        });
      }
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = [];
        for (var key in json) {
          if (!!json[key].emails) {
            if (json[key].emails.length > 1) {
              for (var email in json[key].emails) {
                tmp.push({
                  full:
                    json[key].salutation.name +
                    (json[key].salutation_id === 4
                      ? ' ' + json[key].name
                      : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                        json[key].firstname +
                        ' ' +
                        json[key].name),
                  mail: json[key].emails[email].email,
                  pref: json[key].emails[email].pref,
                  work: json[key].emails[email].work,
                });
              }
            } else if (json[key].emails.length === 1) {
              tmp.push({
                full:
                  json[key].salutation.name +
                  (json[key].salutation_id === 4
                    ? ' ' + json[key].name
                    : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                      json[key].firstname +
                      ' ' +
                      json[key].name),
                mail: json[key].emails[0].email,
                pref: json[key].emails[0].pref,
                work: json[key].emails[0].work,
              });
            }
          }
        }
        this.setState({ contacts: tmp });
      })
      .catch((error) => {
        return false;
      });
  }

  render() {
    const filter = createFilterOptions();
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          {this.state.isSubmitting ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <>
              <DialogTitle className={classes.dialogTitle} id='handle'>
                Neue Mail
                <IconButton
                  aria-label='close'
                  style={{ marginTop: '-1em', float: 'right', width: 0 }}
                  onClick={() => this.props.handleclosefunction(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <form key='AddMail' className={classes.form} onSubmit={this.handleFormSubmit}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Autocomplete
                      id='contactselect'
                      name='uuid'
                      required
                      freeSolo={false}
                      includeInputInList={false}
                      noOptionsText='Keine bekannte/korrekte E-Mail Adresse ...'
                      multiple
                      options={this.state.contacts}
                      renderOption={(option) => {
                        return (
                          <ListItem component='div' key={option.mail}>
                            <ListItemAvatar>
                              <Badge
                                color='primary'
                                badgeContent={option.pref ? <StarIcon className={classes.xs} /> : 0}
                                overlap='rectangular'
                                variant='standard'
                              >
                                <Avatar variant='rounded' className={classes.small}>
                                  {option.work ? <WorkIcon /> : <AlternateEmailIcon />}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText primary={option.mail} secondary={option.full} />
                          </ListItem>
                        );
                      }}
                      value={this.state.to}
                      onChange={this.handleContactChange}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          name='newContact'
                          label={TextDE.mailform.to.label}
                          helperText={TextDE.mailform.to.helperText}
                          onChange={this.handleChange}
                        />
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        // eslint-disable-next-line
                        if (
                          params.inputValue !== '' &&
                          params.inputValue.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          )
                        ) {
                          filtered.push({
                            inputValue: params.inputValue,
                            mail: params.inputValue,
                            full: `"${params.inputValue}" ${TextDE.add}`,
                          });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.mail;
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.marginBottom}>
                    <TextField
                      fullWidth
                      name='subject'
                      label={TextDE.form.subject.label}
                      autoComplete='subject'
                      value={this.state.subject}
                      onChange={this.handleChange()}
                      className={this.state.subject.trim() === '' ? classes.error : null}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      editorState={this.state.editorState}
                      onEditorStateChange={this.onEditorStateChange}
                      wrapperClassName={classes.editorWrapper}
                      editorClassName={clsx(classes.editor, {
                        [classes.active]: this.state.editorFocus,
                      })}
                      onFocus={(event) => {
                        this.setState({ editorFocus: true });
                      }}
                      onBlur={(event) => {
                        this.setState({ editorFocus: false });
                      }}
                      toolbarClassName={classes.toolbar}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'emoji', 'link', 'list'],
                        inline: {
                          options: ['bold', 'italic', 'underline'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                          strikethrough: {
                            className: 'bordered-option-classname',
                          },
                        },
                        blockType: {
                          options: ['Normal', 'H1'],
                          className: 'bordered-option-classname',
                        },
                        fontSize: {
                          options: [10, 12, 16, 20],
                          className: 'bordered-option-classname',
                        },
                        fontFamily: {
                          className: 'bordered-option-classname',
                        },
                        link: {
                          className: 'bordered-option-classname',
                        },
                        emoji: {
                          className: 'bordered-option-classname',
                        },
                      }}
                      toolbarCustomButtons={[
                        <CustomToolbarComponent onChange={this.onEditorStateChange} />,
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      style={{ marginLeft: '1em' }}
                      inputProps={{ style: { color: 'black' } }}
                      disabled
                      multiline
                      fullWidth
                      value={
                        TextDE.mailform.signature.greeting +
                        '\r\n' +
                        this.props.User.firstname +
                        ' ' +
                        this.props.User.lastname +
                        '\r\n\r\n--\r\n' +
                        (!!this.props.User.customer.company?.name
                          ? this.props.User.customer.company?.name
                          : this.props.User.firstname + ' ' + this.props.User.lastname) +
                        (!!this.props.User.customer.company?.address
                          ? '\r\n' +
                            this.props.User.customer.company?.address?.route +
                            ' ' +
                            this.props.User.customer.company?.address?.street_number +
                            ', ' +
                            this.props.User.customer.company?.address?.postal_code +
                            ' ' +
                            this.props.User.customer.company?.address?.city?.locality +
                            '\r\n'
                          : '') +
                        (!!this.props.User.customer.company?.phone
                          ? '\r\n' +
                            TextDE.form.phone.label +
                            ':\t' +
                            this.props.User.customer.company?.phone
                          : !!this.props.User.phone
                          ? '\r\n' + TextDE.form.phone.label + ':\t' + this.props.User.phone
                          : '') +
                        (!!this.props.User.customer.company?.email
                          ? '\r\n' +
                            TextDE.form.email.label +
                            ':\t' +
                            this.props.User.customer.company?.email
                          : '\r\n' + TextDE.form.email.label + ':\t' + this.props.User.email)
                      }
                    />
                    <Typography variant='subtitle1' color='textSecondary'>
                      {TextDE.mailform.signature.label}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <DropzoneArea
                      dropzoneText={TextDE.mailform.attachment.helperTextBig}
                      acceptedFiles={[
                        'application/*',
                        'audio/*',
                        'drawing/*',
                        'image/*',
                        'message/*',
                        'multipart/*',
                        'model/*',
                        'text/*',
                        'video/*',
                      ]}
                      showPreviewsInDropzone={true}
                      useChipsForPreview={true}
                      previewChipProps={{ style: { maxWidth: '100%' } }}
                      showFileNames={true}
                      showAlerts={true}
                      onChange={this.handleFileChange}
                      maxFileSize={30000000}
                      filesLimit={8}
                      dropzoneClass='small'
                    />
                  </Grid>

                  {!!this.state.hasError ? (
                    <Alert severity='error' style={{ width: '100%' }}>
                      <AlertTitle>{this.state.hasError}</AlertTitle>
                      <Typography variant='caption'>
                        {TextDE.mailform.alerts.couldNotBeSent}
                      </Typography>
                    </Alert>
                  ) : (
                    ''
                  )}

                  <Grid item xs={4} className={classes.wrapper}>
                    <Button
                      onClick={() => this.props.handleclosefunction(false)}
                      variant='outlined'
                      color='secondary'
                      fullWidth
                    >
                      {TextDE.close}
                    </Button>
                  </Grid>

                  <Grid item xs={8} className={classes.wrapper}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      disabled={!(this.state.to.length > 0 && this.state.subject !== '')}
                      fullWidth
                    >
                      {TextDE.mailform.send.label}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddMail);
