import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardHeader, Avatar } from '@material-ui/core';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { Constants } from '../../../lib/Constants';

const styles = (theme) => ({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  size: {
    maxWidth: 'calc( 100% - 16px)',
  },
  content: {
    //alignItems: 'center',
    //display: 'flex',
    maxWidth: 'calc( 100% - 96px)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&>svg': {
      height: '87%',
      width: '87%',
    },
  },
  action: {
    marginTop: 'inherit',
    marginRight: '-12px',
    flex: '0 0 auto',
    alignSelf: 'flex-start',
  },
  green: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  orange: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  red: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
});

class TagImageList extends React.Component {
  //	constructor(props) {
  //		super(props):
  //	}

  render() {
    const { classes } = this.props;

    return (
      <ImageList
        style={{ maxHeight: this.props.maxHeight }}
        rowHeight='auto'
        gap={this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.sm ? 8 : 4}
        cols={
          this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.lg
            ? 3
            : this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md
            ? 2
            : 1
        }
      >
        {!!this.props.data &&
          Object.values(this.props.data)
            .sort(function (a, b) {
              return b.count - a.count;
            })
            .map((value) => {
              if (value.count >= 0) {
                return (
                  <ImageListItem key={value.id} cols={1} rows={1}>
                    <Card
                      className={classes.root}
                      onClick={() => {
                        let url = '/nachrichten/alle?tags=' + encodeURI(value.name);
                        if (this.props.currentFetchType !== 'all') {
                          url = url + '&user=' + this.props.User.uuid;
                        }
                        this.props.dispatch(push(url));
                      }}
                    >
                      <CardHeader
                        titleTypographyProps={{
                          variant: 'h5',
                          component: 'p',
                          noWrap: true,
                          className: classes.size,
                        }}
                        title={value.name}
                        subheaderTypographyProps={{
                          variant: 'caption',
                          component: 'p',
                          noWrap: false,
                          className: classes.size,
                        }}
                        subheader={value.notes}
                        classes={{
                          action: classes.action,
                          content: classes.content,
                        }}
                        action={
                          <Avatar
                            aria-label={value.name}
                            variant='rounded'
                            className={
                              value.count > 10
                                ? classes.red
                                : value.count > 0
                                ? classes.orange
                                : classes.green
                            }
                          >
                            {value.count}
                          </Avatar>
                        }
                        avatar={
                          <Avatar aria-label={value.name} className={classes.avatar}>
                            {Constants.getInboxItemType(value.type).icon}
                          </Avatar>
                        }
                      />
                    </Card>
                  </ImageListItem>
                );
              }

              return null;
            })}
      </ImageList>
    );
  }
}

const mapStateToProps = (state) => ({
  Dimensions: state.Dimensions,
  Theme: state.Style.Theme,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(TagImageList);
