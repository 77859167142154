import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';

//import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
//import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

//import CardHeader from '@material-ui/core/CardHeader';
//import CardContent from '@material-ui/core/CardContent';
//import TextField from '@material-ui/core/TextField';
//import MaterialTable from 'material-table'
import MaterialTable from '@material-table/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
//import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Constants } from '../../lib/Constants';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
    borderRadius: '0px',
  },
  bigger: {
    fontSize: '1.7em',
  },
});

class TagsListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      states: {},
      functions: [],
    };
    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  async newTag(newData) {
    if (newData?.name?.trim() === '') {
      return false;
      //"Name fehlt!";
    }
    if (newData?.notes?.trim() === '') {
      return false;
      //"Beschreibung fehlt!";
    }

    let url = new URL(process.env.REACT_APP_API_URL + '/api/customer/functions');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response.success) {
      let tmp = this.state.functions;
      newData.id = response.id;
      tmp.push(newData);
      this.setState({ functions: tmp });
    }
    return true;
  }

  async updateTag(editData) {
    if (!!editData?.id === false) {
      alert('ID fehlt! Sollte nicht passieren...');
      return 'ID fehlt!';
    }
    if (editData?.name?.trim() === '') {
      alert('Name fehlt!');
      return 'Name fehlt!';
    }

    editData['_method'] = 'PUT';
    let url = new URL(process.env.REACT_APP_API_URL + '/api/customer/functions');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(editData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response.success) {
      let tmp = this.state.functions;
      let index = tmp.findIndex((element) => element.id === editData.id);
      tmp[index] = editData;
      this.setState({ functions: tmp });
    }
    return true;
  }

  async deleteTag(deleteData) {
    if (!!deleteData?.id === false) {
      return 'ID fehlt!';
    }

    let tmp = this.state.functions.filter((element) => element.id !== deleteData.id);
    this.setState({ functions: tmp });

    deleteData['_method'] = 'DELETE';
    let url = new URL(process.env.REACT_APP_API_URL + '/api/customer/functions');
    //const response =
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(deleteData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    //if (response.success) {
    //let tmp = this.state.functions.filter( element => element.id === deleteData.id );
    //this.setState({functions: tmp});
    //}
    return true;
  }

  async componentDidMount() {
    let tmp = await fetch(process.env.REACT_APP_API_URL + '/api/procedures/states', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });

    let states = {};
    tmp.forEach((element) => {
      states[element.id] = element.name;
    });

    tmp = await fetch(process.env.REACT_APP_API_URL + '/api/customer/functions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });

    this.setState({
      states: states,
      functions: tmp,
      isFetching: false,
    });
  }

  render() {
    const { classes } = this.props;
    const iconMap = Constants.iconMap();

    return (
      <>
        <Paper className={classes.root} square>
          <MaterialTable
            isLoading={this.state.isFetching}
            title='Schnellfunktionen'
            columns={[
              { title: 'Id', field: 'id', hidden: true },
              {
                title: 'Name',
                field: 'name',
                initialEditValue: '',
                emptyValue: '',
              },
              {
                title: 'Beschreibung',
                field: 'notes',
                editComponent: (props) => (
                  <TextField
                    label='Hinzugefügte Notiz'
                    rows='3'
                    variant='outlined'
                    fullWidth
                    multiline
                    value={props.value}
                    onChange={(event) => {
                      props.onChange(event.target.value);
                    }}
                  />
                ),
                render: (rowData) => (
                  <Typography component='pre' variant='body1'>
                    {rowData.notes}{' '}
                  </Typography>
                ),
              },

              {
                title: 'setzt Status',
                field: 'status_id',
                initialEditValue: 1,
                emptyValue: 1,
                lookup: this.state.states,
              },
              {
                title: 'Erinnern nach X Tagen',
                field: 'remember_days',
                type: 'numeric',
                initialEditValue: 0,
                emptyValue: 0,
              },
              {
                title: 'Entfernt Tags',
                initialEditValue: [],
                emptyValue: [],
                field: 'delete_tags',
                editComponent: (props) => (
                  <Autocomplete
                    multiple
                    options={this.state.functions.map((value) => value.name)}
                    getOptionLabel={(value) => value}
                    style={{ width: '100%' }}
                    value={props.value}
                    onChange={(event, value) => {
                      props.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Entfernt Tags' variant='standard' />
                    )}
                  />
                ),
                render: (rowData) =>
                  rowData.delete_tags.map((value, key) => (
                    <Chip key={key} label={value.name?.de || value} />
                  )),
              },
              {
                title: 'Tags hinzufügen',
                hidden: false,
                initialEditValue: [],
                emptyValue: [],
                field: 'add_tags',
                editComponent: (props) => (
                  <Autocomplete
                    multiple
                    options={this.state.functions.map((value) => value.name)}
                    getOptionLabel={(value) => value}
                    style={{ width: '100%' }}
                    value={props.value}
                    onChange={(event, value) => {
                      props.onChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label='Tags hinzufügen' variant='standard' />
                    )}
                  />
                ),
                render: (rowData) =>
                  rowData.add_tags.map((value, key) => (
                    <Chip key={key} label={value.name?.de || value} />
                  )),
              },

              {
                title: 'Icon',
                hidden: false,
                initialEditValue: '',
                emptyValue: '',
                field: 'type',
                editComponent: (props) => (
                  <Select
                    variant='outlined'
                    value={props.value}
                    onChange={(event, value) => {
                      console.log(event, value);
                      props.onChange(event.target.value);
                    }}
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          paddingRight: '',
                          display: 'flex',
                          flexFlow: 'wrap',
                          maxWidth: '300px',
                          fontSize: '120%',
                        },
                      },
                    }}
                    style={{ fontSize: '200%' }}
                  >
                    {Object.values(iconMap).map((value, index) => (
                      <MenuItem className={classes.bigger} key={value.value} value={value.value}>
                        {value.icon}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                ),
                render: (rowData) => {
                  let tmp = Constants.getInboxItemType(rowData.type);
                  return (
                    <Typography variant='h4'>
                      {!!tmp ? tmp.icon : <RemoveIcon color='inherit' fontSize='inherit' />}
                    </Typography>
                  );
                },
              },

              {
                title: 'Notiz ausfüllen?',
                field: 'dialog',
                type: 'boolean',
                initialEditValue: false,
              },
              {
                title: 'Anzeigen?',
                field: 'active',
                type: 'boolean',
                initialEditValue: true,
              },
            ]}
            data={this.state.isFetching ? [] : this.state.functions}
            options={{
              actionsColumnIndex: 9,
              paging: false,
              headerStyle: { fontWeight: '800', fontSize: '120%' },
              maxBodyHeight:
                this.props.Dimensions.height -
                (this.props.Dimensions.appBarHeight +
                  (this.props.Dimensions.maxWidth < 600 ? 237 : 208)),
            }}
            localization={{
              grouping: {
                placeholder: 'Kopfzeilen hier her ziehen...',
              },
              pagination: {
                labelRowsSelect: 'Zeilen',
                labelDisplayedRows: '{from}-{to} von {count}',
                labelRowsPerPage: 'Zeilen pro Seite:',
                firstAriaLabel: 'Erste Seite',
                firstTooltip: 'Erste Seite',
                previousAriaLabel: 'Vorherige Seite',
                previousTooltip: 'Vorherige Seite',
                nextAriaLabel: 'Nächste Seite',
                nextTooltip: 'Nächste Seite',
                lastAriaLabel: 'Letzte Seite',
                lastTooltip: 'Letzte Seite',
              },
              toolbar: {
                searchPlaceholder: 'Suche...',
              },
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: 'Keine Schnellfunktionen hinterlegt...',
                filterRow: {
                  filterTooltip: 'nach ??? suchen',
                },
              },
            }}
            icons={{
              Search: SearchIcon,
              Clear: ClearIcon,
              Add: AddIcon,
              Check: CheckIcon,
              Delete: DeleteOutlineIcon,
              DetailPanel: ArrowUpwardOutlinedIcon,
              Edit: EditIcon,
              Export: SaveAltIcon,
              Filter: FilterListIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: ChevronRightIcon,
              PreviousPage: ChevronLeftIcon,
              ResetSearch: ClearIcon,
              SortArrow: ArrowUpwardIcon,
              ThirdStateCheck: RemoveIcon,
              ViewColumn: ViewColumnIcon,
            }}
            editable={{
              isEditable: (rowData) => true,
              isDeletable: (rowData) => true,
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  if (!newData?.name || newData?.name?.trim() === '') {
                    alert('Name fehlt!');
                    return reject();
                  }
                  if (!newData?.notes || newData?.notes?.trim() === '') {
                    alert('Beschreibung fehlt!');
                    return reject();
                  }
                  resolve(this.newTag(newData));
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  if (!newData?.name || newData?.name?.trim() === '') {
                    alert('Name fehlt!');
                    return reject();
                  }
                  if (!newData?.notes || newData?.notes?.trim() === '') {
                    alert('Beschreibung fehlt!');
                    return reject();
                  }
                  resolve(this.updateTag(newData));
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  resolve(this.deleteTag(oldData));
                }),
            }}
          />
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TagsListing);
