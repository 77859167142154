import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { DE as TextDE } from '../../../../../lib/Text';

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    minWidth: '50vw',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonGroup: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  input: {
    backgroundColor: 'antiquewhite',
    fontSize: '1.5em',
  },
});

class Comment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      [props.name]: props[props.name],
      hasError: false,
    };
  }

  formChange = (event) => {
    if (event !== null) {
      //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
      this.setState({ [event.target.name]: event.target.value });
    }
  };
  save = async () => {
    this.setState({
      isSubmitting: true,
    });
    // call to api ...
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append(this.props.name, this.state[this.props.name]);

    await fetch(process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState(
          {
            [this.props.name]: this.state[this.props.name],
            isSubmitting: false,
          },
          () => this.props.onChange(this.props.name, this.state[this.props.name]),
        );
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
      });
  };
  render() {
    const { classes } = this.props;

    return (
      <Backdrop className={classes.backdrop} open={this.props.open}>
        <Zoom in={this.props.open}>
          {!!this.state.isSubmitting ? (
            <Paper elevation={4} className={classes.paper} component='center'>
              <CircularProgress />
            </Paper>
          ) : (
            <Paper elevation={4} className={classes.paper}>
              <TextField
                name={this.props.name}
                label={TextDE.performanceReport.comment}
                classes={{ root: classes.input }}
                onChange={this.formChange}
                variant='outlined'
                fullWidth
                multiline
                minRows='5'
                type='text'
                margin='none'
                value={this.state[this.props.name]}
              />
              {!!this.state.hasError && (
                <Alert severity='error' className={classes.center}>
                  <AlertTitle>
                    {this.state.hasError?.status ?? 'Fehler'} -{' '}
                    {this.state.hasError?.statusText ?? 'Fehler'}
                  </AlertTitle>
                  {JSON.stringify(this.state.hasError, null, 4)}
                </Alert>
              )}
              <div className={classes.buttonGroup}>
                <Button
                  onClick={() => this.props.onChange(this.props.name, this.props[this.props.name])}
                  variant='outlined'
                  startIcon={<CloseIcon />}
                  color='secondary'
                >
                  {TextDE.close}
                </Button>
                <Button
                  endIcon={<SaveIcon />}
                  variant={!!this.state[this.props.name] ? 'contained' : 'outlined'}
                  color='primary'
                  disabled={!!!this.state[this.props.name]}
                  onClick={this.save}
                >
                  {TextDE.save}
                </Button>
              </div>
            </Paper>
          )}
        </Zoom>
      </Backdrop>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

export default compose(withStyles(styles), connect(mapStateToProps))(Comment);
