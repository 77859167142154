import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';

import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  margin: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1),
  },
  textFieldJob: {
    minHeight: '20vh',
    //color: theme.palette.text.primary+'!important',
    alignItems: 'flex-start',
  },
  colorize: {
    color: theme.palette.primary.contrastText + '!important',
    backgroundColor: theme.palette.primary.main + '!important',
    borderRadius: '4px',
    padding: theme.spacing(0.5, 1) + '!important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1) + '!important',
    },
  },
  colorizeBig: {
    color: theme.palette.primary.contrastText + '!important',
    backgroundColor: theme.palette.primary.main + '!important',
    borderRadius: '4px',
    padding: theme.spacing(2, 1) + '!important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 1) + '!important',
    },
  },
  position: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
      '&>button': {
        marginLeft: theme.spacing(2),
      },
    },
  },
});

class Subtitle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      newSubtitle: this.props.subtitle,
      model: this.props.model || 'procedures',
    };
    this.handleSubtitleEdit = this.handleSubtitleEdit.bind(this);
    this.handleSubtitleChange = this.handleSubtitleChange.bind(this);
    this.handleSubtitleDelete = this.handleSubtitleDelete.bind(this);
    this.handleSubtitleEditAway = this.handleSubtitleEditAway.bind(this);
    this.submitNewSubtitle = this.submitNewSubtitle.bind(this);
  }

  handleSubtitleEdit = () => {
    this.setState({ edit: true, newSubtitle: this.props.subtitle });
  };

  handleSubtitleChange = (event) => {
    this.setState({ newSubtitle: event.target.value });
  };

  handleSubtitleEditAbbort = () => {
    this.setState({
      edit: false,
      newSubtitle: this.props.subtitle,
    });
  };

  handleSubtitleDelete = () => {
    this.setState({ newSubtitle: '' }, () => this.submitNewSubtitle());
  };

  handleSubtitleEditAway = () => {
    //this.setState({
    //  edit: false,
    //});
  };

  componentWillUnmount() {
    // Sync Data Back to Parent - why you no work
    this.props.saveEditData({ edit: this.state.edit, newSubtitle: this.state.newSubtitle });
  }
  submitNewSubtitle = async () => {
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('subtitle', this.state.newSubtitle);

    await fetch(
      process.env.REACT_APP_API_URL +
        '/api/' +
        this.state.model +
        '/' +
        this.props.match.params.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ edit: false }, () => this.props.callback());
      })
      .catch((error) => {
        return false;
      });
  };

  render() {
    const { classes } = this.props;

    if (this.props.isFetching) {
      return (
        <Typography variant='h4' gutterBottom>
          {' '}
          <CircularProgress />{' '}
        </Typography>
      );
    }

    return (
      <>
        <Box mt={0} mb={1}>
          {this.state.edit ? (
            <ClickAwayListener onClickAway={this.handleSubtitleEditAway}>
              <Grid container justifyContent='center' spacing={0}>
                <Grid item xs={12}>
                  <TextField
                    multiline={true}
                    maxRows={this.props.model === 'jobs' ? 25 : 4}
                    autoFocus={true}
                    fullWidth
                    variant={this.props.variant || 'standard'}
                    required
                    label={TextDE.inbox.subtitle}
                    helperText={TextDE.inbox.subtitleHelper}
                    value={this.state.newSubtitle}
                    onChange={this.handleSubtitleChange}
                    name='newSubtitle'
                    InputProps={
                      this.props.model === 'jobs' ? { className: classes.textFieldJob } : {}
                    }
                  />
                </Grid>
                <Grid item className={classes.position} xs={12}>
                  <Button
                    color='secondary'
                    variant='outlined'
                    onClick={this.handleSubtitleDelete}
                    aria-label={TextDE.delete}
                  >
                    <DeleteIcon />
                    <Hidden mdDown>{TextDE.delete}</Hidden>
                  </Button>

                  <Button
                    color='secondary'
                    variant='outlined'
                    onClick={this.handleSubtitleEditAbbort}
                    aria-label={TextDE.abort}
                  >
                    <CloseIcon />
                    <Hidden mdDown>{TextDE.abort}</Hidden>
                  </Button>

                  <Button
                    color='primary'
                    variant='outlined'
                    onClick={this.submitNewSubtitle}
                    aria-label={TextDE.save}
                  >
                    <SaveIcon />
                    <Hidden mdDown>{TextDE.save}</Hidden>
                  </Button>
                </Grid>
              </Grid>
            </ClickAwayListener>
          ) : (
            <Tooltip title={TextDE.inbox.subtitleTooltip} aria-label={TextDE.edit}>
              <Grid
                container
                justifyContent='center'
                spacing={1}
                className={this.props.model === 'jobs' ? classes.colorizeBig : null}
              >
                <Grid item xs={12} className={classes.colorize} onClick={this.handleSubtitleEdit}>
                  <TextField
                    multiline={true}
                    maxRows={this.props.model === 'jobs' ? 25 : 2}
                    fullWidth
                    variant={this.props.variant || 'standard'}
                    value={this.props.subtitle}
                    InputProps={{
                      style: { color: 'inherit' },
                      className:
                        this.props.model === 'jobs' ? classes.textFieldJob : classes.textField,
                    }}
                    label={TextDE.inbox.subtitle}
                    InputLabelProps={{
                      shrink: true,
                      style: {
                        color: this.props.Theme.palette.primary.contrastText,
                      },
                    }}
                    FormHelperTextProps={{ style: { color: 'inherit' } }}
                  />
                </Grid>
              </Grid>
            </Tooltip>
          )}
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Subtitle);
