import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { DE as TextDE } from '../../lib/Text';

const getCalWeek = (date) => {
  // In JavaScript the Sunday has value 0 as return value of getDay() function.
  // So we have to order them first ascending from Monday to Sunday
  // Monday: ((1+6) % 7) = 0
  // Tuesday ((2+6) % 7) = 1
  // Wednesday: ((3+6) % 7) = 2
  // Thursday: ((4+6) % 7) = 3
  // Friday: ((5+6) % 7) = 4
  // Saturday: ((6+6) % 7) = 5
  // Sunday: ((0+6) % 7) = 6
  // (3 - result) is necessary to get the Thursday of the current week.
  // If we want to have Tuesday it would be (1-result)
  var currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);

  // At the beginnig or end of a year the thursday could be in another year.
  var yearOfThursday = currentThursday.getFullYear();

  // Get first Thursday of the year
  var firstThursday = new Date(
    new Date(yearOfThursday, 0, 4).getTime() +
      (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000,
  );

  // +1 we start with week number 1
  // +0.5 an easy and dirty way to round result (in combinationen with Math.floor)
  var weekNumber = Math.floor(
    1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7,
  );

  return weekNumber;
};

class Clock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    let date = new Date();
    /*date = date.toLocaleString([], {
      hour: '2-digit',
      minute: '2-digit',
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      weekday: 'short',
      hour12: false,
    });*/
    this.setState({
      date: date,
    });
  }

  render() {
    return (
      <Grid
        container
        direction={this.props.isCollapsed ? 'row' : 'column'}
        justifyContent='center'
        alignItems='center'
        spacing={0}
      >
        <Grid item>
          <Typography
            gutterBottom={false}
            variant='h5'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AccessTimeIcon fontSize='inherit' />
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            gutterBottom={false}
            variant='h5'
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {this.state.date.toLocaleString([], {
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </Typography>
        </Grid>
        {this.props.isCollapsed === true && (
          <Grid item xs={12}>
            <Typography
              gutterBottom={false}
              variant='h5'
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              {TextDE.calendar.calWeekShortLabel} {getCalWeek(this.state.date)}&nbsp;-&nbsp;
              {this.state.date.toLocaleString([], {
                month: '2-digit',
                day: '2-digit',
                year: '2-digit',
                weekday: 'short',
              })}
            </Typography>
          </Grid>
        )}
      </Grid>
    );
  }
}
export default Clock;
