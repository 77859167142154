import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from '@material-table/core';
import CustomToolbar from './CustomToolbar';
import CustomDatePicker from './CustomDatePicker';
import CustomPagination from './CustomPagination';
import CustomGroupRow from './CustomGroupRow';

const styles = (theme) => ({
  size: {
    maxWidth: 'calc( 100% - 16px)',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: 'calc( 100% - 96px)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&>svg': {
      height: '87%',
      width: '87%',
    },
  },
  action: {
    marginTop: 'inherit',
    marginRight: '-12px',
    flex: '0 0 auto',
    alignSelf: 'flex-start',
  },
  chartContainer: {
    margin: 'auto',
    paddingBottom: '12px',
  },
  alignment: {
    justifyContent: 'flex-start',
  },
  alignmentIcon: {
    marginLeft: 'auto',
  },
});

class StatisticsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteDialogOpen: false,
      toDeleteRow: null,
    };

    this.redirectTo = this.redirectTo.bind(this);
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.tableRef = React.createRef();
  }

  handleDeleteDialogOpen(data) {
    //console.log("[handleDeleteDialogOpen]", data);
    this.setState({
      deleteDialogOpen: true,
      toDeleteRow: data,
    });
  }

  handleDeleteDialogClose() {
    this.setState({
      deleteDialogOpen: false,
      toDeleteRow: null,
    });
  }
  async handleDelete() {
    // do some network shenananiugans
    this.handleDeleteDialogClose();
    this.tableRef.current.onQueryChange();
  }

  redirectTo(uuid) {
    this.props.dispatch(push('/nachrichten/detail/' + encodeURI(uuid)));
  }

  componentDidUpdate(oldProps, oldState) {
    //console.log("[componentDidUpdate] STATE:",this.state, oldState)
    //console.log("[componentDidUpdate] PROPS",this.props, oldProps)
  }

  render() {
    const localization = {
      header: {
        actions: ' ',
      },
      toolbar: {
        searchTooltip: 'Gesamte Statistik durchsuchen nach ...',
        searchPlaceholder: 'Suche',
      },

      pagination: {
        firstAriaLabel: 'Erste Seite',
        firstTooltip: 'Erste Seite',
        previousAriaLabel: 'Vorherige Seite',
        previousTooltip: 'Vorherige Seite',
        nextAriaLabel: 'Nächste Seite',
        nextTooltip: 'Nächste Seite',
        lastAriaLabel: 'Letzte Seite',
        lastTooltip: 'Letzte Seite',
        labelDisplayedRows: '{from}-{to} von {count}',
        labelRowsSelect: 'Einträge',
        labelRowsPerPage: 'Einträge pro Seite',
      },
      grouping: {
        placeholder: 'Spaltenüberschriften hier her ziehen um zu gruppieren',
        groupedBy: 'Gruppiert nach:',
      },
      body: {
        deleteTooltip: 'Diese Zeile löschen',
        emptyDataSourceMessage: 'Keine Zeiten erfasst',
        filterRow: {
          filterTooltip: 'Filtern nach ...',
        },
        editRow: {
          deleteText: 'Diesen Statistik eintrag endgültig löschen? (Nicht wiederherstellbar!)',
          cancelTooltip: 'Abbrechen',
          saveTooltip: 'Löschen',
        },
      },
    };

    return (
      <>
        <Dialog
          open={this.state.deleteDialogOpen}
          onClose={this.handleDeleteDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{"Use Google's location service?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Let Google help apps determine location. This means sending anonymous location data to
              Google, even when no apps are running.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDeleteDialogClose} color='primary'>
              Disagree
            </Button>
            <Button onClick={this.handleDelete} color='primary' autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <MaterialTable
          tableRef={this.tableRef}
          title='Statistics'
          localization={localization}
          components={{
            GroupRow: (props) => <CustomGroupRow {...props} />,
            Toolbar: (props) => <CustomToolbar {...props} tableRef={this.tableRef} />,
            Pagination: (props) => (
              <CustomPagination
                classes={props.classes}
                rowsPerPage={props.rowsPerPage}
                rowsPerPageOptions={props.rowsPerPageOptions}
                page={props.page}
                count={props.count}
                onPageChange={props.onPageChange}
                onRowsPerPageChange={props.onRowsPerPageChange}
                localization={localization.pagination}
                icons={props.icons}
                tableRef={this.tableRef}
              />
            ),
          }}
          icons={{
            Filter: SearchIcon,
          }}
          options={{
            thirdSortClick: false,
            //addRowPosition: "first",
            pageSize: 15,
            pageSizeOptions: [15, 25, 50],
            paginationType: 'normal',
            export: true,
            columnsButton: false,
            exportButton: true,
            exportAllData: true,
            exportDelimiter: ';',
            filtering: true,
            grouping: true,
            search: false,
            showTitle: false,
            toolbar: true,
            actionsColumnIndex: -1,
            //selection: true,
            maxBodyHeight: `${this.props.height - 170}px`,
            minBodyHeight: `${this.props.height - 170}px`,
            filterCellStyle: { padding: '4px' },
          }}
          actions={[
            {
              icon: ExitToAppIcon,
              tooltip: 'Zum Vorgang gehen',
              onClick: (event, rowData) => this.redirectTo(rowData.inbox_item_uuid),
              //position: "row",
            },
            /*{
              icon: DeleteIcon,
              tooltip: 'Diese erfasste Zeit löschen',
              onClick: (event, rowData) => this.handleDeleteDialogOpen(rowData),
              //position: "row",
            },*/
          ]}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                //console.log("[onRowDelete]", oldData);

                let url = new URL(process.env.REACT_APP_API_URL + '/api/statistics');
                let formData = {};
                formData['_method'] = 'DELETE';
                formData['id'] = oldData.id;

                fetch(url, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.props.Authentication.access_token}`,
                  },
                  body: JSON.stringify(formData),
                })
                  .then((res) => {
                    if (res.ok) {
                      return res.json();
                    } else {
                      throw res;
                    }
                  })
                  .then((json) => {
                    //console.log("[onRowDelete] after delete", json );
                    if (json.success === true) {
                      resolve();
                    } else {
                      reject();
                    }
                  })
                  .catch((error) => {
                    reject(error);
                  });
              }),
          }}
          columns={[
            { title: 'Kategorie', field: 'category', align: 'left', width: '20%' },
            { title: 'Detail', field: 'detail', align: 'left', grouping: false },
            { title: 'Person', field: 'fullname', align: 'left', width: '20%' },
            { title: 'Vorgang', field: 'serial', align: 'left', width: '10%' },
            {
              title: 'Arbeitsauftrag',
              field: 'job',
              filtering: false,
              grouping: false,
              align: 'left',
              width: '10%',
            },
            {
              title: 'Ausführungsdatum',
              field: 'tracked_at',
              type: 'date',
              align: 'left',
              dateSetting: { locale: 'de-DE' },
              filterComponent: (props) => <CustomDatePicker {...props} />,
              defaultSort: 'desc',
            },
            {
              title: 'Zeit',
              field: 'tracked_time',
              type: 'numeric',
              filtering: false,
              grouping: false,
              align: 'left',
              width: '8em',
              render: (rowData) => `${rowData.tracked_time} m`,
            },
            {
              title: 'startDate',
              field: 'startDate',
              type: 'date',
              dateSetting: { locale: 'de-DE' },
              readonly: true,
              editable: 'never',
              export: false,
              filtering: false,
              hidden: true,
              searchable: false,
              sorting: false,
            },
            {
              title: 'endDate',
              field: 'endDate',
              type: 'date',
              dateSetting: { locale: 'de-DE' },
              readonly: true,
              editable: 'never',
              export: false,
              filtering: false,
              hidden: true,
              searchable: false,
              sorting: false,
            },
          ]}
          data={(query) =>
            new Promise((resolve, reject) => {
              //console.log("My Query is:", query);
              let url = new URL(process.env.REACT_APP_API_URL + '/api/statistics');

              query.filters.forEach((filter, index) => {
                if (filter.column.field === 'startDate') {
                  let tmp =
                    filter.value != null
                      ? `${filter.value.getDate()}-${
                          filter.value.getMonth() + 1
                        }-${filter.value.getFullYear()}`
                      : null;
                  url.searchParams.set('dateStart', tmp);
                } else if (filter.column.field === 'endDate') {
                  let tmp =
                    filter.value != null
                      ? `${filter.value.getDate()}-${
                          filter.value.getMonth() + 1
                        }-${filter.value.getFullYear()}`
                      : null;
                  url.searchParams.set('dateEnd', tmp);
                } else {
                  url.searchParams.set(
                    'filter[' + index + ']',
                    filter.column.field + '|' + filter.operator + '|' + filter.value,
                  );
                }
              });

              //url.searchParams.set('orderDirection', query.orderDirection);
              if (query.orderBy != null) {
                url.searchParams.set('orderBy', query.orderBy.field + '|' + query.orderDirection);
              }

              if (query.pageSize === 'Alle') {
                url.searchParams.set('per_page', -1);
              } else {
                url.searchParams.set('per_page', query.pageSize);
              }

              url.searchParams.set('page', query.page + 1);

              //if (query.search.trim() !== '' && query.search.length > 3) {
              //  url.searchParams.set('search', query.search);
              //}

              fetch(url, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${this.props.Authentication.access_token}`,
                },
              })
                .then((res) => {
                  if (res.ok) {
                    return res.json();
                  } else {
                    throw res;
                  }
                })
                .then((json) => {
                  //this.setState({currentTableData: json.data});
                  resolve({
                    data: json.data,
                    page: json.current_page - 1,
                    totalCount: json.total,
                  });
                  //return (!!json?.parts) ? json : false;
                })
                .catch((error) => {
                  return error;
                });
            })
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Dimensions: state.Dimensions,
  Theme: state.Style.Theme,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(StatisticsTable);
