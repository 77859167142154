import React from 'react';
import { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  track: {
    height: '8px',
  },
  rail: {
    color: 'grey',
  },
  thumb: {
    height: '18px',
    width: '18px',
  },
  valueLabel: {
    transform: 'scale(1) translateY(-10px) translateX(6px)!important',
  },
  withBreak: {
    '&>div:nth-of-type(2)': {
      color: 'grey',
    },
    '&>div:nth-of-type(3)': {
      color: 'grey',
    },
  },
});

const days = {
  0: 'Sonntag',
  1: 'Montag',
  2: 'Dienstag',
  3: 'Mittwoch',
  4: 'Donnerstag',
  5: 'Freitag',
  6: 'Samstag',
};

function valuetext(value) {
  return `${value} Uhr`;
}

export default function TestSlider(props) {
  const classes = useStyles();

  const [value, setValue] = React.useState(
    !!props.data.break_start && !!props.data.break_end
      ? [
          props.data.start / 3600,
          props.data.break_start / 3600,
          props.data.break_end / 3600,
          props.data.end / 3600,
        ]
      : [props.data.start / 3600, props.data.end / 3600],
  );
  const [intermission, setBreak] = React.useState(
    !!props.data.break_start &&
      props.data.break_start > 0 &&
      !!props.data.break_end &&
      props.data.break_end > 0,
  );
  const [working, setWorking] = React.useState(!!props.data.open);

  useEffect(() => {
    var tmp = {
      open: working,
      start: value[0] * 3600,
      end: (intermission ? value[3] : value[1]) * 3600,
      break_start: (intermission ? value[1] : 0) * 3600,
      break_end: (intermission ? value[2] : 0) * 3600,
    };
    // eslint-disable-next-line
    props.callback(tmp);
    // eslint-disable-next-line
  }, [intermission, value, working]);
  /*
  useEffect(() => { callCallback();}, [working]);
  useEffect(() => { callCallback();}, [value]);
  useEffect(() => { callCallback();}, [intermission]) ;
  function callCallback() {
    var tmp = {
      open: working,
      start: value[0] * 3600,
      end: ((intermission) ? value[3] : value[1]) * 3600,
      break_start: ((intermission) ? value[1] : 0) * 3600,
      break_end: ((intermission) ? value[2] : 0) * 3600,
    };
    console.log()
    console.log(tmp);
    props.callback(tmp);
  }
  */
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (event, newValue) => {
    if (event.target.name === 'working') {
      setWorking(event.target.checked);
    }
    if (event.target.name === 'intermission') {
      setBreak(event.target.checked);
      var newVal = [];
      if (event.target.checked) {
        if (value.length === 2) {
          newVal = [];
          newVal.push(value[0]);
          newVal.push(value[0] + 2);
          newVal.push(value[0] + 3);
          newVal.push(value[0] + 4 > value[1] ? value[0] + 5 : value[1]);
          setValue(newVal);
        }
      }
      if (!event.target.checked) {
        if (value.length === 4) {
          newVal = [];
          newVal.push(value[0]);
          newVal.push(value[3]);
          setValue(newVal);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Typography id='range-slider' variant='caption' style={{ fontSize: '2em' }} gutterBottom>
        {days[props.day]}
      </Typography>
      <Grid container>
        <Grid item xs={2}>
          <FormControlLabel
            control={<Checkbox checked={working} onChange={handleClick} name='working' />}
            label='Geöffnet?'
          />
        </Grid>
        <Grid item xs={2}>
          <FormControlLabel
            control={<Checkbox checked={intermission} onChange={handleClick} name='intermission' />}
            label='Mit Pause?'
          />
        </Grid>
        <Grid item xs={8}>
          <Slider
            disabled={!working}
            value={value}
            className={intermission ? classes.withBreak : null}
            classes={{
              track: classes.track,
              rail: classes.rail,
              thumb: classes.thumb,
              valueLabel: classes.valueLabel,
            }}
            onChange={handleChange}
            valueLabelDisplay='on'
            ThumbComponent='div'
            valueLabelFormat={(value) => {
              var ret = '';
              if (value.toString().includes('.')) {
                var tmp = value.toString().split('.');
                ret = tmp[0] + ':' + 60 * parseFloat('0.' + tmp[1]);
              } else {
                ret = value;
              }
              return ret;
            }}
            aria-labelledby='range-slider'
            getAriaValueText={valuetext}
            step={0.25}
            marks={[
              { value: 0, label: '0 Uhr' },
              //{ value: 1, label: '1 Uhr'},
              //{ value: 2, label: '2 Uhr'},
              { value: 3, label: '3 Uhr' },
              //{ value: 4, label: '4 Uhr'},
              //{ value: 5, label: '5 Uhr'},
              { value: 6, label: '6 Uhr' },
              //{ value: 7, label: '7 Uhr'},
              //{ value: 8, label: '8 Uhr'},
              { value: 9, label: '9 Uhr' },
              //{ value: 10, label: '10 Uhr'},
              //{ value: 11, label: '11 Uhr'},
              { value: 12, label: '12 Uhr' },
              //{ value: 13, label: '13 Uhr'},
              //{ value: 14, label: '14 Uhr'},
              { value: 15, label: '15 Uhr' },
              //{ value: 16, label: '16 Uhr'},
              //{ value: 17, label: '17 Uhr'},
              { value: 18, label: '18 Uhr' },
              //{ value: 19, label: '19 Uhr'},
              //{ value: 20, label: '20 Uhr'},
              { value: 21, label: '21 Uhr' },
              //{ value: 22, label: '22 Uhr'},
              //{ value: 23, label: '23 Uhr'},
            ]}
            min={0}
            max={24}
          />
        </Grid>
      </Grid>
    </div>
  );
}
