import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { update as userUpdate } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
//import MobileIcon from '@material-ui/icons/PhonelinkEraseOutlined';
//import MobileCheckedIcon from '@material-ui/icons/PhonelinkRingOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import MailCheckedIcon from '@material-ui/icons/Mail';
import PushIcon from '@material-ui/icons/DesktopAccessDisabled';
import PushCheckedIcon from '@material-ui/icons/DesktopWindows';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import UserAvatar from './UserAvatar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import * as PushManager from '../../lib/enable-push';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
});

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#008888',
    },
  },
  checked: {},
})((props) => <Checkbox color='default' {...props} />);

class UserConfigForm extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true, hasError: false });
    let formData = {};
    formData['nickname'] =
      this.state.nickname !== '' && !!this.state.nickname.trim() ? this.state.nickname : null;
    formData['firstname'] =
      this.state.firstname !== '' && !!this.state.firstname.trim() ? this.state.firstname : null;
    formData['lastname'] =
      this.state.lastname !== '' && !!this.state.lastname.trim() ? this.state.lastname : null;
    formData['mobile'] =
      this.state.mobile !== '' && !!this.state.mobile.trim() ? this.state.mobile : null;
    formData['phone'] =
      this.state.phone !== '' && !!this.state.phone.trim() ? this.state.phone : null;
    formData['email'] =
      this.state.email !== '' && !!this.state.email.trim() ? this.state.email : null;
    formData['password'] =
      this.state.password !== '' && !!this.state.password.trim() ? this.state.password : null;
    formData['password_confirmation'] =
      this.state.password_confirmation !== '' && !!this.state.password_confirmation.trim()
        ? this.state.password_confirmation
        : null;
    formData['notificationMail'] =
      this.state.notificationMail !== '' ? this.state.notificationMail : null;
    formData['notificationMobile'] =
      this.state.notificationMobile !== '' ? this.state.notificationMobile : null;
    formData['notificationPush'] =
      this.state.notificationPush !== '' ? this.state.notificationPush : null;
    formData['isAdvancedUser'] =
      this.state.isAdvancedUser !== '' ? this.state.isAdvancedUser : null;

    formData['_method'] = 'PUT';

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/users/' + this.props.User.uuid,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify(formData),
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response.success) {
      this.setState({ isSubmitting: false, hasError: false, data: response });
      //console.log(formData);
      this.props.dispatch(userUpdate(formData));
    } else if (!!response.errors) {
      this.setState({
        isSubmitting: false,
        hasError: response.errors,
        data: response,
      });
    }
  }

  handlePasswordChange = () => (event) => {
    let reCaps = /[A-ZÄÖÜ]/;
    let reSmall = /[a-zäöü]/;
    let reDigit = /[0-9]/;
    let reCode = /[#?!@$%^&*-]/;
    let reFull = /^(?=.*?[A-ZÄÖÜ])(?=.*?[a-zäöü])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    let validated = false;
    let validationMessage = [];
    if (!event.target.value.match(reCaps)) {
      validationMessage.push('Es fehlt mindestens 1 Großbuchstabe (ABC...)');
    }
    if (!event.target.value.match(reSmall)) {
      validationMessage.push('Es fehlt mindestens 1 Kleinbuchstabe (abc...)');
    }
    if (!event.target.value.match(reDigit)) {
      validationMessage.push('Es fehlt mindestens 1 Zahl (123...)');
    }
    if (!event.target.value.match(reCode)) {
      validationMessage.push('Es fehlt mindestens 1 Sonderzeichen (!$#%...)');
    }
    if (event.target.value.length < 8) {
      validationMessage.push(
        'Das Passwort ist zu kurz, bitte noch mindestens ' +
          (8 - event.target.value.length) +
          ' Zeichen',
      );
    }
    if (event.target.value.match(reFull)) {
      validated = true;
    }
    this.setState({
      validationMessage: validationMessage,
      validation: validated,
      [event.target.name]: event.target.value,
    });
  };

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      if (event.target.name === 'notificationPush') {
        new Promise(function (resolve, reject) {
          const permissionResult = Notification.requestPermission(function (result) {
            resolve(result);
          });

          if (permissionResult) {
            permissionResult.then(resolve, reject);
          }
        }).then((permissionResult) => {
          if (permissionResult !== 'granted') {
            throw new Error("We weren't granted permission.");
          } else {
            PushManager.initSW(this.props.Authentication);
          }
        });
      }
      //dispatch({ [event.target.name]: event.target.checked });
    } else if (event.target.type === 'radio') {
      this.setState({
        [event.target.name]:
          event.target.value === 'true' || event.target.value === true ? true : false,
      });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  constructor() {
    super();
    this.state = {
      user: '',
      firstname: '',
      lastname: '',
      nickname: '',
      phone: '',
      mobile: '',
      email: '',
      password: '',
      validationMessage: [],
      validation: false,
      password_confirmation: '',
      notificationPush: false,
      notificationMail: false,
      notificationMobile: false,
      isFetching: false,
      isAdvancedUser: false,
      hasError: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      user: this.props.User.uuid,
      firstname: this.props.User.firstname,
      lastname: this.props.User.lastname,
      nickname: this.props.User.nickname ?? '',
      phone: this.props.User.phone ?? '',
      mobile: this.props.User.mobile ?? '',
      email: this.props.User.email ?? '',
      notificationPush: this.props.User.notification.push ?? false,
      notificationMail: this.props.User.notification.mail ?? false,
      notificationMobile: this.props.User.notification.mobile ?? false,
      isAdvancedUser: this.props.User.isAdvancedUser ?? false,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root} square>
          {this.state.isFetching ? (
            <center>
              <CircularProgress />
            </center>
          ) : this.props.User ? (
            <form key='userform' onSubmit={this.handleFormSubmit}>
              <Grid container spacing={3}>
                <Grid item sm={3} xs={12}>
                  <UserAvatar />
                </Grid>
                <Grid item sm={9} xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        id='nickName'
                        name='nickname'
                        onChange={this.handleChange()}
                        label='Spitzname'
                        fullWidth
                        autoComplete='nickname'
                        value={this.state.nickname}
                      />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                      <TextField
                        required
                        id='firstName'
                        name='firstname'
                        onChange={this.handleChange()}
                        label='First name'
                        fullWidth
                        autoComplete='fname'
                        value={this.state.firstname}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id='lastName'
                        name='lastname'
                        onChange={this.handleChange()}
                        label='Last name'
                        fullWidth
                        autoComplete='lname'
                        value={this.state.lastname}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id='mobile'
                        name='mobile'
                        onChange={this.handleChange()}
                        label='Mobil'
                        fullWidth
                        autoComplete='tel'
                        value={this.state.mobile}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        id='phone'
                        name='phone'
                        onChange={this.handleChange()}
                        label='Festnetz'
                        fullWidth
                        autoComplete='tel'
                        value={this.state.phone}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextField
                        required
                        id='email'
                        name='email'
                        disabled
                        fullWidth
                        label='E-Mail (Login)'
                        autoComplete='email'
                        value={this.state.email}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='h5'> Passwort ändern ... </Typography>
                    </Grid>

                    {this.state.validation === false && this.state.validationMessage.length > 0 ? (
                      <Grid item zeroMinWidth xs={12}>
                        {this.state.validationMessage.map((value) => (
                          <Alert severity='error'> {value} </Alert>
                        ))}
                      </Grid>
                    ) : null}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        value={this.state.password}
                        onChange={this.handlePasswordChange()}
                        id='password'
                        autoComplete='new-password'
                        error={
                          this.state.password !== this.state.password_confirmation ||
                          this.state.validation === false
                            ? true
                            : false
                        }
                        helperText={
                          this.state.validation
                            ? this.state.password !== this.state.password_confirmation
                              ? 'Die Passwörter sind nicht identisch! Wird somit nicht geändert!'
                              : ''
                            : 'Das Passwort muss aus mindestens 8 Zeichen (!$#%...), Zahlen, Buchstaben bestehen'
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        fullWidth
                        name='password_confirmation'
                        label='Password Confirmation'
                        type='password'
                        value={this.state.password_confirmation}
                        onChange={this.handlePasswordChange()}
                        id='password_confirmation'
                        error={
                          this.state.password !== this.state.password_confirmation ||
                          this.state.validation === false
                            ? true
                            : false
                        }
                        helperText={
                          this.state.validation
                            ? this.state.password !== this.state.password_confirmation
                              ? 'Die Passwörter sind nicht identisch! Wird somit nicht geändert!'
                              : ''
                            : 'Das Passwort muss aus mindestens 8 Zeichen (!$#%...), Zahlen, Buchstaben bestehen'
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>Vorgangsansicht</FormLabel>
                        <RadioGroup
                          aria-label='isAdvancedUser'
                          name='isAdvancedUser'
                          value={this.state.isAdvancedUser}
                          onChange={this.handleChange()}
                        >
                          <FormControlLabel
                            value={false}
                            control={<Radio />}
                            label='Kombinierte Ansicht (Kleinbetriebe/ Beginner)'
                          />
                          <FormControlLabel
                            value={true}
                            control={<Radio />}
                            label='Organisation und Auftrag getrennt (Erweiterter Modus!)'
                          />
                        </RadioGroup>
                      </FormControl>
                      {this.props.User.isAdvancedUser !== this.state.isAdvancedUser && (
                        <Alert severity='warning'>
                          <AlertTitle>Achtung</AlertTitle>
                          Diese Änderung tritt erst in Kraft sobald Sie sich erneut anmelden —{' '}
                          <strong>Bitte melden Sie sich ab und erneut an!</strong>
                        </Alert>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='h5'> Benachrichtigungen via ... </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            icon={<MailIcon />}
                            checkedIcon={<MailCheckedIcon />}
                            value='mail'
                            checked={this.state.notificationMail}
                            name='notificationMail'
                            onChange={this.handleChange()}
                          />
                        }
                        label='via E-Mail'
                      />
                    </Grid>
                    {/* <Grid item xs={4}>
								<FormControlLabel
									control={
										<GreenCheckbox 
											icon={<MobileIcon />} 
											checkedIcon={<MobileCheckedIcon />} 
											value="mobile" 
											checked={this.state.notificationMobile}
											name="notificationMobile"
											onChange={this.handleChange()}
										/>
									}
									label="via SMS"
								/>
							</Grid>
							*/}
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <GreenCheckbox
                            icon={<PushIcon />}
                            checkedIcon={<PushCheckedIcon />}
                            value='push'
                            checked={this.state.notificationPush}
                            name='notificationPush'
                            onChange={this.handleChange()}
                          />
                        }
                        label='via Push'
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container justifyContent='flex-end'>
                <Grid item xs={12} sm={6}>
                  {!!this.state.hasError &&
                    this.state.hasError.map((value, key) => (
                      <Alert key={key} severity='error' className={classes.center}>
                        {value}
                      </Alert>
                    ))}
                  <div className={classes.wrapper}>
                    {this.state.isSubmitting ? (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    ) : (
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        disabled={this.state.isSubmitting}
                        fullWidth
                      >
                        Speichern
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            </form>
          ) : null}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserConfigForm);
