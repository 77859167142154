import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { readable } from '../../../lib/dateFunctions';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import Hidden from '@material-ui/core/Hidden';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import RoomIcon from '@material-ui/icons/Room';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CreateIcon from '@material-ui/icons/Create';
import clsx from 'clsx';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import Alert from '@material-ui/lab/Alert';
import CustomToolbarComponent from '../components/CustomToolbarComponent';

import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  root: {
    margin: '0px 6% 8px 6%',
    padding: theme.spacing(1, 1),
    width: '100%',
    borderRadius: '14px',
    backgroundColor: '#ffffff',
  },
  avatar: {
    backgroundColor: 'red',
    color: 'white',
    minWidth: theme.spacing(7),
    minHeight: theme.spacing(7),
    '&>svg': {
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5),
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
  },
  noPad: {
    padding: '0px!important',
  },
  alignMiddle: {
    display: 'flex',
    alignItems: 'center',
    '& > b': {
      margin: theme.spacing(0, 2, 0, 1),
    },
    flexGrow: 1,
  },
  flexRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'end',
    '&>svg': {
      marginRight: '1em',
    },
    justifyContent: 'end',
  },
  active: {
    borderColor: theme.palette.primary.main + '!important',
    borderWidth: '2px!important',
    //'&>div': {},
  },
  required: {
    borderColor: theme.palette.error.main + '!important',
    borderWidth: '1px!important',
    //'&>div': {},
  },
  wrapper: {
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.action.disabled,
    borderWidth: '1px',
    borderBottomStyle: 'none',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  editor: {
    height: '122px',
    '&>div': {
      height: '116px',
      padding: theme.spacing(1, 1),
    },
    [theme.breakpoints.up('lg')]: {
      height: '282px',
      '&>div': {
        height: '250px',
        padding: theme.spacing(1, 2),
      },
    },
  },
  toolbar: {
    backgroundColor: theme.palette.grey['400'],
    paddingBottom: theme.spacing(1),
    marginBottom: 0,
    '&>div': {
      marginBottom: 0,
    },
  },
  header: {
    paddingBottom: 0,
  },
  expanded: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  contact: {
    [theme.breakpoints.up('md')]: {
      flexFlow: 'row-reverse',
      '&>div': {
        textAlign: 'right',
        justifyContent: 'end',
      },
    },
  },
  signatureCollapsed: {
    maxHeight: '80px',
    overflow: 'clip',
  },
  signature: {
    paddingLeft: '1em',
    borderRadius: '2px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderStyle: 'solid',
    borderColor: theme.palette.action.disabled,
    borderWidth: '1px',
    borderTopStyle: 'none',
    cursor: 'pointer',
    transition: 'max-height 400ms',
  },
  content: {
    padding: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(2),
  },
  inquiry: {
    padding: theme.spacing(2),
    whiteSpace: 'pre',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
});

const typeAdminForm = 'adminForm';
const typeFlixInbox = 'flixworkerInbox';
const typeApp = 'consumerApp';
const typeForm = 'remoteForm';
const typePrefForm = 'customerPrefForm';

class Competitive extends React.Component {
  constructor(props) {
    super(props);

    let type = typeFlixInbox;
    switch (props.external?.remote_type ?? 'default') {
      case typeAdminForm:
        type = typeAdminForm;
        break;
      case typeFlixInbox:
        type = typeFlixInbox;
        break;
      case typeApp:
        type = typeApp;
        break;
      case typeForm:
        type = typeForm;
        break;
      case typePrefForm:
        type = typePrefForm;
        break;
      default:
        type = typeFlixInbox;
        break;
    }
    let editorState = EditorState.createEmpty();

    this.state = {
      loading: false,
      open: false,
      editorState: editorState,
      editorFocus: false,
      editorEmpty: true,
      signatureExpanded: false,
      reason: '',
      isExternal: props.external != null,
      type: type,
      errors: [],
      creator:
        props.external?.creator != null
          ? typeof props.external?.creator === typeof 't'
            ? JSON.parse(props.external?.creator)
            : props.external?.creator
          : false,
      contacts:
        props.external?.contacts != null
          ? typeof props.external?.contacts === typeof 't'
            ? JSON.parse(props.external?.contacts)
            : props.external?.contacts
          : false,
      addresses:
        props.external?.addresses != null
          ? typeof props.external?.addresses === typeof 't'
            ? JSON.parse(props.external?.addresses)
            : props.external?.addresses
          : false,
    };
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleRevokeClick = this.handleRevokeClick.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  async handleSendToServer(accept, reason = null) {
    this.setState({ loading: true });
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.props.uuid);

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({
        _method: 'put',
        accept: accept,
        reason: reason,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .catch((error) => {
        let tmp = error.json().then((json) => {
          this.setState({ errors: json, loading: false }, () => console.error(this.state.errors));
          return json;
        });
        return tmp;
      });
  }

  async handleAcceptClick() {
    var response = await this.handleSendToServer(true);
    if (response?.uuid != null) {
      this.props.callbackAccepted();
    }
  }

  async handleRevokeClick() {
    let countCurrentText = this.state.editorState.getCurrentContent().getPlainText().length;
    var countDefaultTextLength =
      `Hallo ${this.state.creator?.salutation ?? ''} ${this.state.creator?.firstname + ' ' + this.state.creator?.lastname},`
        .length;
    //console.log(`[DEBUG] count default = ${countDefaultTextLength} - current length: ${countCurrentText}`);

    if (countCurrentText < countDefaultTextLength + 30) {
      //console.log("Message too short");
      this.setState({ errors: [TextDE.inbox.alerts.informCustomerWhyNotAccept] });
      return;
    }

    let reason = this.state.editorState.getCurrentContent().hasText()
      ? stateToHTML(this.state.editorState.getCurrentContent())
      : this.state.reason;

    var response = await this.handleSendToServer(false, reason);
    if (response.status === 'deleted') {
      this.props.callbackDeclined();
    }
  }

  handleCollapse = () => {
    if (
      !this.state.open &&
      this.state.type === typeForm &&
      !this.state.editorState.getCurrentContent().hasText()
    ) {
      let blocksFromHTML = convertFromHTML(
        `<p> Hallo ${this.state.creator?.salutation ?? ''} ${
          this.state.creator?.firstname + ' ' + this.state.creator?.lastname
        },</p>`,
      );
      let editorState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      editorState = EditorState.createWithContent(editorState);
      this.setState({
        open: !this.state.open,
        editorState: editorState,
        editorEmpty: !editorState.getCurrentContent().hasText(),
      });
      return;
    }

    this.setState({ open: !this.state.open, errors: [] });
  };

  handleChange(event, value) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={this.handleCollapse}
          aria-labelledby='form-dialog-title'
          maxWidth='xl'
        >
          <DialogTitle id='form-dialog-title'>
            {TextDE.inbox[this.state.type].dialog.decline.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {TextDE.inbox[this.state.type].dialog.decline.text}
            </DialogContentText>
            {this.state.type !== typeForm ? (
              <TextField
                multiline
                rows={4}
                autoFocus
                id='reason'
                name='reason'
                placeholder={TextDE.inbox[this.state.type].dialog.decline.label}
                type='text'
                fullWidth
                variant='outlined'
                onChange={this.handleChange}
                value={this.state.reason}
              />
            ) : (
              <>
                <Editor
                  editorState={this.state.editorState}
                  onEditorStateChange={this.onEditorStateChange}
                  wrapperClassName={clsx(classes.wrapper, {
                    [classes.active]: this.state.editorFocus,
                    [classes.required]: this.state.editorEmpty,
                  })}
                  editorClassName={classes.editor}
                  toolbarClassName={classes.toolbar}
                  onFocus={(event) => {
                    this.setState({ editorFocus: true });
                  }}
                  onBlur={(event) => {
                    let isEmpty = !this.state.editorState.getCurrentContent().hasText();
                    this.setState({ editorFocus: false, editorEmpty: isEmpty });
                  }}
                  autoFocus
                  required
                  toolbar={{
                    options: ['inline', 'blockType', 'fontSize', 'emoji', 'link'],
                    inline: {
                      options: ['bold', 'italic', 'underline'],
                      bold: { className: 'bordered-option-classname' },
                      italic: { className: 'bordered-option-classname' },
                      underline: { className: 'bordered-option-classname' },
                      strikethrough: {
                        className: 'bordered-option-classname',
                      },
                    },
                    blockType: {
                      options: ['Normal', 'H1'],
                      className: 'bordered-option-classname',
                    },
                    fontSize: {
                      options: [10, 12, 16, 20],
                      className: 'bordered-option-classname',
                    },
                    fontFamily: {
                      className: 'bordered-option-classname',
                    },
                    link: {
                      className: 'bordered-option-classname',
                    },
                    emoji: {
                      className: 'bordered-option-classname',
                    },
                  }}
                  toolbarCustomButtons={[
                    <CustomToolbarComponent onChange={this.onEditorStateChange} />,
                  ]}
                />
                <Typography
                  component='pre'
                  variant='body1'
                  className={clsx(classes.signature, {
                    [classes.active]: this.state.editorFocus,
                    [classes.required]: this.state.editorEmpty,
                    [classes.signatureCollapsed]: !this.state.signatureExpanded,
                  })}
                  onClick={() =>
                    this.setState({ signatureExpanded: !this.state.signatureExpanded })
                  }
                >
                  {TextDE.mailform.signature.greeting +
                    '\r\n' +
                    this.props.User.customer.company?.name +
                    '\r\n\r\n--\r\n' +
                    this.props.User.customer.company?.name +
                    (!!this.props.User.customer.company?.address &&
                      '\r\n' +
                        this.props.User.customer.company?.address?.route +
                        ' ' +
                        this.props.User.customer.company?.address?.street_number +
                        ', ' +
                        this.props.User.customer.company?.address?.postal_code +
                        ' ' +
                        this.props.User.customer.company?.address?.city?.locality +
                        '\r\n') +
                    (!!this.props.User.customer.company?.phone &&
                      '\r\n' +
                        TextDE.form.phone.label +
                        ':\t' +
                        this.props.User.customer.company?.phone) +
                    (!!this.props.User.customer.company?.email &&
                      '\r\n' +
                        TextDE.form.email.label +
                        ':\t' +
                        this.props.User.customer.company?.email)}
                </Typography>
              </>
            )}
            {!!this.state.open &&
              !!this.state.errors &&
              Object.values(this.state.errors).map((value) => {
                return (
                  <Alert className={classes.mb} severity='error'>
                    {value}
                  </Alert>
                );
              })}
          </DialogContent>
          {this.state.loading ? (
            <center>
              {' '}
              <CircularProgress />{' '}
            </center>
          ) : (
            <DialogActions>
              <Button onClick={this.handleCollapse} color='secondary'>
                {TextDE.abort}
              </Button>
              <Button variant='contained' onClick={this.handleRevokeClick} color='primary'>
                {TextDE.decline}
              </Button>
            </DialogActions>
          )}
        </Dialog>

        <Card raised>
          <CardHeader
            className={classes.header}
            disableTypography={true}
            avatar={
              <Hidden mdDown>
                <Avatar className={classes.avatar} alt='Competitive'>
                  <NewReleasesIcon />
                </Avatar>
              </Hidden>
            }
            action={
              <Hidden mdDown>
                <Typography variant='overline' component='p' className={classes.flexRight}>
                  <UpdateIcon fontSize='small' />
                  {readable(this.props.external.created_at)}
                </Typography>
              </Hidden>
            }
            title={
              <Typography
                display='block'
                variant='h4'
                align='center'
                component='p'
                paragraph={true}
              >
                {TextDE.inbox[this.state.type].text(this.props.competitors.length)}
              </Typography>
            }
          />
          {!this.state.loading ? (
            <CardContent className={classes.content}>
              <Button
                fullWidth
                variant='link'
                component='p'
                onClick={this.props.switchToProtocol}
                startIcon={<ArrowForwardIcon />}
                endIcon={<ArrowBackIcon />}
              >
                {TextDE.inbox[this.state.type].linkText}
              </Button>
              <Divider className={classes.margin} />
              {this.state.creator !== null && !!this.state.creator && (
                <Grid container justifyContent='center' spacing={2}>
                  <Grid item>
                    <Typography variant='h5' align='center'>
                      {TextDE.contact.labelPlural}
                    </Typography>
                    <List dense>
                      {!!this.state.creator && (
                        <ListItem key={'creator'} className={classes.contact}>
                          <ListItemIcon>
                            <CreateIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              this.state.creator?.salutation +
                              ' ' +
                              this.state.creator?.firstname +
                              ' ' +
                              this.state.creator?.lastname
                            }
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                              <>
                                {!!this.state.creator.address && (
                                  <RoomIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.phone && (
                                  <PhoneIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.secondPhone && (
                                  <PhoneIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.email && (
                                  <AlternateEmailIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.secondEmail && (
                                  <AlternateEmailIcon style={{ color: 'green' }} />
                                )}
                              </>
                            }
                          />
                        </ListItem>
                      )}
                      {Object.keys(this.state.contacts).map((value) => {
                        if (
                          !!this.state.contacts[value] &&
                          this.state.contacts[value].email !== this.state.creator.email
                        ) {
                          return (
                            <ListItem key={'contact-' + value} className={classes.contact}>
                              <ListItemIcon>
                                <PermContactCalendarIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  this.state.contacts[value].salutation +
                                  ' ' +
                                  this.state.contacts[value].firstname +
                                  ' ' +
                                  this.state.contacts[value].lastname
                                }
                                secondaryTypographyProps={{ component: 'div' }}
                                secondary={
                                  <>
                                    {!!this.state.contacts[value].address && (
                                      <RoomIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].phone && (
                                      <PhoneIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].secondPhone && (
                                      <PhoneIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].email && (
                                      <AlternateEmailIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].secondEmail && (
                                      <AlternateEmailIcon style={{ color: 'green' }} />
                                    )}
                                  </>
                                }
                              />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </List>
                  </Grid>
                  {!!this.state.addresses && (
                    <Grid item>
                      <Typography variant='h5' align='center'>
                        {TextDE.address.labelPlural}
                      </Typography>
                      <List dense>
                        {Object.keys(this.state.addresses).map((value) => {
                          if (!!this.state.addresses[value]) {
                            return (
                              <ListItem key={'address-' + value}>
                                <ListItemIcon>
                                  {value !== 'Rechnungsadresse' ? <RoomIcon /> : <LocalAtmIcon />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    this.state.addresses[value]['route'] +
                                      ' ' +
                                      this.state.addresses[value]['street_number'] ||
                                    this.state.addresses[value]['streetNumber'] +
                                      ', ' +
                                      this.state.addresses[value]['postal_code'] ||
                                    this.state.addresses[value]['postalCode'] +
                                      ' ' +
                                      this.state.addresses[value]['locality']
                                  }
                                  secondary={this.state.addresses[value]['type']}
                                />
                              </ListItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </List>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid container direction='row' justifyContent='space-around' alignItems='center'>
                <Grid item xs={12}>
                  <Typography component='center' gutterBottom variant='h5'>
                    {TextDE.inbox[this.state.type].buttonsLabel}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {!!!this.state.open &&
                    !!this.state.errors &&
                    Object.values(this.state.errors).map((value) => {
                      return (
                        <Alert className={classes.mb} severity='error'>
                          {value}
                        </Alert>
                      );
                    })}
                </Grid>
                <Grid item xs={4} className={classes.marginTop}>
                  <Button
                    variant='contained'
                    fullWidth
                    color='secondary'
                    onClick={this.handleCollapse}
                  >
                    {TextDE.decline}
                  </Button>
                </Grid>
                <Grid item xs={5} className={classes.marginTop}>
                  <Button
                    variant='contained'
                    fullWidth
                    color='primary'
                    onClick={this.handleAcceptClick}
                  >
                    {TextDE.accept}
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          ) : !!this.state.isExternal && !this.state.loading ? (
            <CardContent className={classes.content}>
              <b>{TextDE.inbox[this.state.type].deletedError.title}</b>
              <br />
              {TextDE.inbox[this.state.type].deletedError.text(this.props.uuid)}
            </CardContent>
          ) : (
            <CardContent className={classes.content}>
              <center>
                <CircularProgress style={{ marginTop: '4em' }} />
              </center>
            </CardContent>
          )}
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication, User: state.User });

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Competitive);
