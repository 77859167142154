import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';

const localeMap = {
  en: 'en',
  fr: 'fr',
  de: 'de',
};

const language = navigator.language;
const timezoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
moment.locale(localeMap[language] === 'undefined' ? 'en' : localeMap[language]);

//function formats(language) {
//    switch(language.substring(0,2)){
//        case "de":
//            return "HH:mm [Uhr, am] DD.MM.YYYY";
//        default:
//            return "dddd, MMMM Do YYYY,HH:mm";
//    }
//}

function timezone(date) {
  let newDate = moment(date);
  if (newDate._isAMomentObject) {
    // return addiontion in milliseconds
    switch (timezoneString) {
      case 'Europe/Berlin':
        return newDate;
      case 'Europe/London':
        return newDate.subtract(1, 'hour');
      case 'Europe/Kaliningrad':
        return newDate.add(1, 'hour');
      // GMT == 0
      default:
        return newDate;
    }
  } else {
    return date;
  }
}

export function isToday(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.diff(moment(), 'days') === 0;
  }
  return date;
}

export function diffDate(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.fromNow();
  }
  return date;
}

export function diffInDays(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.fromNow(true);
  }
  return date;
}

export function readable(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.calendar();
    //return date.format(formats(language));
  }
  return date;
}

export function readableDate(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.format('DD.MM.YYYY');
    //return date.format(formats(language));
  }
  return date;
}

export function readableWithTime(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.format('DD.MM.YYYY [um] HH:mm [Uhr]');
    //return date.format(formats(language));
  }
  return date;
}

export function readableTime(date) {
  date = timezone(date);
  if (date._isAMomentObject) {
    return date.format('HH:mm [Uhr]');
    //return date.format(formats(language));
  }
  return date;
}
