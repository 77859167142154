import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AppointmentsCard from './components/AppointmentsCard';
import Avatar from '@material-ui/core/Avatar';
import Users from './components/Users';
import ContactCard from './components/ContactCard';
import FileCard from './components/FileCard';
import StatusChip from './components/StatusChip';
import Title from './components/Title';
import Tags from './components/Tags';
import Subtitle from './components/Subtitle';
import PerformanceReport from './components/PerformanceReport/index';
import NoteForm from './forms/NoteForm';
import ChatForm from './forms/ChatForm';
import EmailForm from './forms/EmailForm';
import AppointmentForm from './forms/AppointmentForm';
import LinearProgress from '@material-ui/core/LinearProgress';
import Protocol from './components/protocol/Protocol';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import AddIcon from '@material-ui/icons/Add';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CommentIcon from '@material-ui/icons/Comment';
import Card from '@material-ui/core/Card';
import { Constants } from '../../lib/Constants';
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import EventIcon from '@material-ui/icons/Event';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CreateIcon from '@material-ui/icons/Create';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import Hidden from '@material-ui/core/Hidden';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import AddProcedure from './AddProcedure';
import AssignCard from './components/AssignCard';
import MakeNewProcedureCard from './components/MakeNewProcedureCard';
import CompetitiveCard from './components/CompetitiveCard';
import DeleteCard from './components/DeleteCard';
import { DE as TextDE } from '../../lib/Text';
import Collapse from '@material-ui/core/Collapse';
import Fab from '@material-ui/core/Fab';
import FlashOnIcon from '@material-ui/icons/FlashOn';

const styles = (theme) => ({
  fab: {
    zIndex: 999,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },

  fabFast: {
    zIndex: 999,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(10),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(16),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  copyAlert: {
    height: '28px',
    padding: theme.spacing(0, 5),
    margin: '0',
    zIndex: 999,
    top: '-2px',
    position: 'relative',
    width: '10em',
    left: '4px',
    border: '1px solid #000000cc',
    '&>div': {
      padding: '0',
      alignItems: 'center',
      display: 'flex',
    },
  },
  chipRow: {},
  cardHeaderRoot: {
    paddingBottom: '4px',
  },
  navTabs: {
    backgroundColor: theme.palette.action.disabledBackground,
    //width: 'auto',
    borderTop: '1px solid ' + theme.palette.divider,
    height: '52px',
    flexShrink: 0,
    //position: "fixed",
    //right: 0,
    //bottom: "4px",
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  smallText: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'start',
    marginBottom: 0,
    '&>svg': {
      marginBottom: '0!important',
      marginRight: '8px!important',
    },
  },
  iconSizeInherit: {
    '&>svg': {
      height: '4em!important',
      width: '4em!important',
    },
  },
  selectedTab: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[8],
  },
  navTabRoot: {
    borderRight: '1px solid ' + theme.palette.divider,
    minHeight: 'unset',
    maxHeight: 'unset',
    height: '52px',
    paddingTop: 0,
    paddingBottom: '2px',
  },
  interactiveTabs: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  progressBar: {
    //display: 'block',
    //width: '100%',
    height: '6px',
    flexShrink: 0,
  },
  scrollableTab: {
    flexGrow: 1,
    overflow: 'auto',
  },
  fixedHeightTab: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  backButton: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
      width: theme.spacing(5),
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(9),
      marginLeft: theme.spacing(1),
      fontWeight: 'bold',
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
      color: theme.palette.error.main,
      backgroundColor: theme.palette.error.contrastText,
    },
    '&:hover': {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      borderColor: theme.palette.error.main,
      borderWidth: '2px',
    },
  },
  badgeAvatar: {
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    height: '20px',
    display: 'flex',
    padding: '0 6px',
    fontSize: '0.75rem',
    minWidth: '20px',
    width: 'auto',
    boxSizing: 'border-box',
    alignItems: 'center',
    fontWeight: '500',
    lineHeight: '1',
    alignContent: 'center',
    borderRadius: '10px',
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

const INFOTAB = 1;
const PROTOCOLTAB = 2;
const FILETAB = 3;
const REPORTTAB = 4;
const MAILTAB = 5;
const CHATTAB = 6;
const APPOINTMENTTAB = 7;
const NOTETAB = 8;
const MENUTTAB = 9;
const NEWPROCEDURETAB = 10;

class MessageDetail extends React.Component {
  constructor(props) {
    super(props);
    const canSeeUserConfig = props.User.roles.filter((value) =>
      [Constants.roles.ROLES_ADMIN, Constants.roles.ROLES_ORGANIZER].includes(value.name),
    );

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    today = yyyy + '-' + mm + '-' + dd;

    this.state = {
      today: today,

      canSeeUserConfig: canSeeUserConfig.length > 0,

      isFetching: true,
      isInbox: true,
      selectedTab: INFOTAB,
      addMenuOpen: false,
      addMenuOpenFab: false,
      customFunctionsOpen: false,
      customFunctionsOpenFab: false,
      customFunctionDialog: false,
      customFunctionText: '',
      customFunctionTitle: '',
      customFunctionIcon: '',
      customFunctionId: '',

      dialogOpen: false,
      dialogText: '',
      dialogButtonCloseText: '',
      dialogButtonSaveText: '',

      type: Constants.getInboxItemType(0),
      isExternalRequest: false,
      uuid: null,
      subject: '',
      subtitle: '',
      body: null,
      creator: false,
      modifier: false,
      createdAt: 0,
      createdBy: 0,
      updatedAt: 0,
      updatedBy: 0,
      deletedAt: 0,
      deletedBy: 0,
      appointments: [],
      procedureId: false,
      procedureSubject: '',
      procedureSubtitle: '',
      procedureBody: null,
      procedureSerial: '',
      procedureStatus: null,
      procedureTags: [],
      procedureUsers: [],
      procedureContacts: [],
      procedureAddresses: [],
      procedureJobs: [],
      procedureJobsIsFetching: true,
      procedureItems: [],
      media: [],

      externalRequestLinkedConnectUsers: [],
      externalRequest: null,

      isCompetitive: false,
      competitorsCount: 0,

      addMail: false,
      addChat: false,
      addAppointment: false,
      addProcedure: false,

      titleIsFetching: true,
      titleEditData: {},
      subtitleIsFetching: true,
      subtitleEditData: {},

      statusIsFetching: true,

      tagsIsFetching: true,

      protocolIsFetching: true,

      mediaIsFetching: true,

      reportIsFetching: true,

      userIsFetching: true,

      customFunctions: [],
      usersAll: [],
      statesAll: [],

      emailIsFetching: false,
      emailEditData: false,

      chatIsFetching: false,
      chatEditData: false,

      contactIsFetching: true,

      addNote: false,
      noteIsFetching: true,
      noteEditData: false,

      appointmentIsLoading: true,
      appointmentEditData: false,

      cardUserExpand: false,
      cardAddressExpand: false,
      cardContactExpand: false,
      cardAppointmentsExpand: false,
    };

    //this.buttonTest = this.buttonTest.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.openAddMenu = this.openAddMenu.bind(this);
    this.openAddMenuFab = this.openAddMenuFab.bind(this);
    this.closeAddMenu = this.closeAddMenu.bind(this);
    this.toggleCustomFunctionsMenu = this.toggleCustomFunctionsMenu.bind(this);
    this.toggleCustomFunctionsMenuFab = this.toggleCustomFunctionsMenuFab.bind(this);
    this.closeCustomFunctionsMenu = this.closeCustomFunctionsMenu.bind(this);

    this.switchToMail = this.switchToMail.bind(this);
    this.switchToChat = this.switchToChat.bind(this);
    this.switchToAppointment = this.switchToAppointment.bind(this);

    this.switchToNote = this.switchToNote.bind(this);
    this.closeNoteForm = this.closeNoteForm.bind(this);
    this.callbackNoteSend = this.callbackNoteSend.bind(this);
    this.callbackNoteSaveEditData = this.callbackNoteSaveEditData.bind(this);
    this.callbackNoteEdit = this.callbackNoteEdit.bind(this);
    this.callbackNoteDelete = this.callbackNoteDelete.bind(this);

    this.toggleCardsExpansion = this.toggleCardsExpansion.bind(this);

    this.callbackTitleEdit = this.callbackTitleEdit.bind(this);
    this.callbackTitleSaveEditData = this.callbackTitleSaveEditData.bind(this);

    this.callbackSubtitleEdit = this.callbackSubtitleEdit.bind(this);
    this.callbackSubtitleSaveEditData = this.callbackSubtitleSaveEditData.bind(this);

    this.sendMailToContact = this.sendMailToContact.bind(this);

    this.callbackEmailSend = this.callbackEmailSend.bind(this);
    this.callbackEmailSaveEditData = this.callbackEmailSaveEditData.bind(this);
    this.callbackEmailRespond = this.callbackEmailRespond.bind(this);
    this.callbackChatRespond = this.callbackChatRespond.bind(this);

    this.callbackExecuteCustomFunction = this.callbackExecuteCustomFunction.bind(this);

    this.callbackMediaSetIsFetching = this.callbackMediaSetIsFetching.bind(this);
    this.callbackMediaUploaded = this.callbackMediaUploaded.bind(this);
    this.callbackMediaDeleted = this.callbackMediaDeleted.bind(this);

    this.switchToNewProcedure = this.switchToNewProcedure.bind(this);
    this.closeNewProcedureForm = this.closeNewProcedureForm.bind(this);
    this.callbackNewProcedureSend = this.callbackNewProcedureSend.bind(this);

    this.callbackDeclinedCompetitiveInbox = this.callbackDeclinedCompetitiveInbox.bind(this);
    this.callbackAcceptedCompetitiveInbox = this.callbackAcceptedCompetitiveInbox.bind(this);
    this.callbackAssignedInbox = this.callbackAssignedInbox.bind(this);

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleCopyClose = this.handleCopyClose.bind(this);

    this.switchToFile = this.switchToFile.bind(this);
  }

  // Competitive stuff
  callbackDeclinedCompetitiveInbox = () => {
    // redirect back?
    this.props.history.goBack();
  };
  callbackAcceptedCompetitiveInbox = () => {
    this.setState({ isFetching: true }, () =>
      this.fetchData(() => this.setState({ selectedTab: INFOTAB })),
    );
  };
  // End Competitive stuff

  // Assign stuff
  callbackAssignedInbox = (url) => {
    this.props.dispatch(push('/nachrichten/detail/' + url));
    //this.setState({isFetching: true}, () => this.fetchData(() => this.setState({selectedTab: INFOTAB})));
  };
  // End Assign stuff

  // Title Edit stuff
  callbackTitleEdit = () => {
    this.setState({ titleIsFetching: true }, () =>
      this.syncData({ procedureSubject: 'procedure.data.subject' }, { titleIsFetching: false }),
    );
  };
  callbackTitleSaveEditData = (editData) => {
    this.setState({ titleEditData: editData });
  };
  // End Title Edit Stuff

  // Subtitle Edit stuff
  callbackSubtitleEdit = () => {
    this.setState({ isFetching: true, subtitleIsFetching: true }, () =>
      this.syncData(
        { procedureSubtitle: 'procedure.data.subtitle' },
        { subtitleIsFetching: false, isFetching: false },
      ),
    );
  };
  callbackSubtitleSaveEditData = (editData) => {
    this.setState({ subtitleEditData: editData });
  };
  // End Subtitle Edit Stuff

  // status Edit stuff
  callbackStatusEdit = () => {
    this.setState({ isFetching: true, statusIsFetching: true }, () =>
      this.syncData(
        { procedureStatus: 'procedure.status' },
        { statusIsFetching: false, isFetching: false },
      ),
    );
  };
  // End status Edit Stuff

  // Tags Edit stuff
  callbackTagsEdit = () => {
    this.setState({ isFetching: true, tagsIsFetching: true }, () =>
      this.syncData(
        { procedureTags: 'procedure.tags' },
        { tagsIsFetching: false, isFetching: false },
      ),
    );
  };
  // Tags Email Edit Stuff

  // Email Edit stuff
  callbackEmailSend = () => {
    this.setState({ isFetching: true, emailIsFetching: true }, () =>
      this.syncData(
        { procedureItems: 'procedure.items' },
        {
          emailIsFetching: false,
          isFetching: false,
          addMail: false,
          selectedTab: PROTOCOLTAB,
          emailEditData: false,
        },
      ),
    );
  };
  callbackEmailSaveEditData = (editData) => {
    this.setState({ emailEditData: editData });
  };

  sendMailToContact = (contact, email) => {
    let editData = {
      data: {
        formOfAddress: TextDE.contact.generateFormOfAddress(contact),
        from: [
          {
            mail: email,
            personal: `${contact?.firstname ?? ''} ${contact.name}`,
            connect_uuid: contact?.connect_uuid ?? false,
          },
        ],
        subject: this.state.procedureSubject,
      },
    };
    this.callbackEmailRespond(editData);
  };

  callbackEmailRespond = (editData) => {
    if (this.state.addMail === true) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.alreadyWritingEmail,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: TextDE.inbox.navigation.toMail,
        dialogButtonSaveClick: () => this.setState({ selectedTab: MAILTAB }, this.dialogClose),
      });
    } else {
      if (Boolean(this.state.emailEditData) !== false) {
        this.setState({ emailEditData: false }, () => this.callbackEmailRespond(editData));
      } else {
        this.setState(
          {
            emailEditData: {
              formOfAddress: editData.data.formOfAddress || null,
              body: editData.data.body || '',
              to: editData.data.from || [],
              subject: editData.data.subject || '',
            },
            addMail: true,
            selectedTab: MAILTAB,
          },
          this.closeAddMenu,
        );
      }
    }
  };
  switchToMail = () => {
    this.setState({ addMail: true, selectedTab: MAILTAB }, this.closeAddMenu);
  };
  closeMailForm = () => {
    this.setState(
      { addMail: false, selectedTab: INFOTAB, emailEditData: false },
      this.closeAddMenu,
    );
  };
  // End Email Edit Stuff

  // Chat Edit stuff
  callbackChatSend = () => {
    this.setState({ isFetching: true, chatIsFetching: true }, () =>
      this.syncData(
        { procedureItems: 'procedure.items' },
        {
          chatIsFetching: false,
          isFetching: false,
          addChat: false,
          selectedTab: PROTOCOLTAB,
          chatEditData: false,
        },
      ),
    );
  };
  callbackChatSaveEditData = (editData) => {
    this.setState({ chatEditData: editData });
  };
  callbackChatRespond = (editData) => {
    if (this.state.addChat === true) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.alreadyWritingChat,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: TextDE.inbox.navigation.toChat,
        dialogButtonSaveClick: () => this.setState({ selectedTab: CHATTAB }, this.dialogClose),
      });
    } else {
      if (Boolean(this.state.chatEditData) !== false) {
        this.setState({ chatEditData: false }, () => this.callbackChatRespond(editData));
      } else {
        //editData.data.from || []
        //this.state.procedureContacts
        let respondTo = [];
        if (!!editData.data?.creator?.uuid) {
          let contact = this.state.procedureContacts.find(
            (element) => element.contact.connect_uuid === editData.data.creator.uuid,
          );
          if (!!contact) {
            respondTo.push(contact);
          }
        }

        this.setState(
          {
            chatEditData: {
              body: editData.data.body || '',
              to: respondTo,
              subject: editData.data.subject || '',
            },
            addChat: true,
            selectedTab: CHATTAB,
          },
          this.closeAddMenu,
        );
      }
    }
  };
  switchToChat = () => {
    this.setState({ addChat: true, selectedTab: CHATTAB }, this.closeAddMenu);
  };
  closeChatForm = () => {
    this.setState({ addChat: false, selectedTab: INFOTAB, chatEditData: false }, this.closeAddMenu);
  };
  // End Chat Edit Stuff

  // User Edit stuff
  callbackUserEdit = () => {
    this.setState({ isFetching: true, userIsFetching: true }, () =>
      this.syncData(
        { procedureUsers: 'procedure.users' },
        { userIsFetching: false, isFetching: false },
      ),
    );
  };
  // End User Edit Stuff

  // Contact Edit stuff
  callbackContactEdit = () => {
    this.setState({ isFetching: true, contactIsFetching: true }, () =>
      this.syncData(
        { procedureContacts: 'procedure.contacts', procedureAddresses: 'procedure.addresses' },
        { contactIsFetching: false, isFetching: false },
      ),
    );
  };
  // End Contact Edit Stuff

  // NewProcedure stuff
  callbackNewProcedureSend = (uuid = false) => {
    if (!!uuid && (typeof uuid).toLowerCase() === 'string') {
      //this.props.dispatch(push('/nachrichten/detail/' + uuid));
      this.setState({ isFetching: true }, () =>
        this.fetchData(() => this.setState({ addProcedure: false, selectedTab: PROTOCOLTAB })),
      );
    } else {
      this.setState({ addProcedure: false, selectedTab: INFOTAB });
    }
  };
  switchToNewProcedure = () => {
    if (this.state.addProcedure === true) {
      this.setState({ selectedTab: NEWPROCEDURETAB }, this.closeAddMenu);
    } else {
      this.setState({ addProcedure: true, selectedTab: NEWPROCEDURETAB }, this.closeAddMenu);
    }
  };
  closeNewProcedureForm = () => {
    this.setState({ addProcedure: false, selectedTab: NEWPROCEDURETAB }, this.closeAddMenu);
  };
  // End NewProcedure

  // Note Edit stuff
  callbackNoteSend = () => {
    this.setState({ isFetching: true, noteIsFetching: true }, () =>
      this.syncData(
        { procedureItems: 'procedure.items' },
        {
          noteIsFetching: false,
          isFetching: false,
          addNote: false,
          selectedTab: PROTOCOLTAB,
          editNoteText: '',
          editNoteSubject: '',
        },
      ),
    );
  };
  callbackNoteSaveEditData = (editData) => {
    this.setState({ editNoteSubject: editData.subject, editNoteText: editData.text });
  };
  callbackNoteEdit = (editData) => {
    if (this.state.addNote === true) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.alreadyWritingNote,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: TextDE.inbox.navigation.toNote,
        dialogButtonSaveClick: () => this.setState({ selectedTab: NOTETAB }, this.dialogClose),
      });
    } else {
      if (Boolean(this.state.noteEditData) !== false) {
        this.setState({ noteEditData: false }, () => this.callbackNoteEdit(editData));
      } else {
        this.setState(
          {
            noteEditData: {
              subject: editData.data?.subject ?? '',
              body: editData.data?.body ?? '',
              uuid: editData.uuid,
              type: editData.type ?? false,
            },
            addNote: true,
            selectedTab: NOTETAB,
          },
          this.closeAddMenu,
        );
      }
    }
  };
  callbackNoteDelete = (editData) => {
    this.setState({
      dialogOpen: true,
      dialogText: TextDE.inbox.alerts.deleteNote(editData.data?.subject),
      dialogButtonCloseText: TextDE.abort,
      dialogButtonSaveText: TextDE.delete,
      dialogButtonSaveClick: () => this.deleteNoteFunction(editData),
    });
  };

  deleteNoteFunction = async (editData) => {
    let formData = {};
    formData['_method'] = 'DELETE';
    formData['context'] = 'protocol';
    formData['uuid'] = this.props.match.params.uuid;
    formData['protocol'] = editData.uuid;

    await fetch(
      process.env.REACT_APP_API_URL +
        '/api/procedures/' +
        this.props.match.params.uuid +
        '/protocol',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify(formData),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        if (!!json.deleted) {
          this.setState({ isFetching: true, noteIsFetching: true }, () =>
            this.syncData(
              { procedureItems: 'procedure.items' },
              { noteIsFetching: false, isFetching: false, selectedTab: PROTOCOLTAB },
            ),
          );
          this.dialogClose();
        }
      })
      .catch((error) => {
        console.log('DELETE ERROR', error);
        return false;
      });
  };
  //dialogButtonSaveClick =  () => {
  //  console.log("[TODO?]","dialogButtonSaveClick");
  //}
  switchToNote = () => {
    if (this.state.addNote === true) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.alreadyWritingNote,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: TextDE.inbox.navigation.overwriteNote,
        dialogButtonSaveClick: () => this.setState({ selectedTab: NOTETAB }, this.dialogClose),
      });
    } else {
      this.setState({ addNote: true, selectedTab: NOTETAB }, this.closeAddMenu);
    }
  };
  closeNoteForm = () => {
    this.setState({ addNote: false, selectedTab: INFOTAB, noteEditData: false }, this.closeAddMenu);
  };
  // End Note Edit Stuff

  // Start Delete inbox
  openDeleteDialog = () => {
    if (this.state.isCompetitive) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.cantDeleteCompetetive,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: null,
        dialogButtonSaveClick: null,
      });
    } else {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.deleteInquiry(this.state.subject),
        dialogButtonCloseText: TextDE.abort,
        dialogButtonSaveText: TextDE.delete,
        dialogButtonSaveClick: () => this.deleteInboxFunction(),
      });
    }
  };
  async deleteInboxFunction() {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.state.uuid);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'delete' }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.props.history.goBack();
      })
      .catch((error) => {
        console.error(error);
        alert(error);
      });
  }

  // End DElete Stuff

  // Start Appointment Stuff
  switchToAppointment = () => {
    if (this.state.addAppointment === true) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.alreadyWritingAppointment,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: TextDE.inbox.navigation.toAppointment,
        dialogButtonSaveClick: () =>
          this.setState({ addAppointment: true, selectedTab: APPOINTMENTTAB }, this.dialogClose),
      });
    } else {
      this.setState({ addAppointment: true, selectedTab: APPOINTMENTTAB }, this.closeAddMenu);
    }
  };

  callbackAppointmentSaved = () => {
    this.setState({ isFetching: true, appointmentIsLoading: true }, () =>
      this.syncData(
        { appointments: 'appointments', procedureItems: 'procedure.items' },
        {
          appointmentIsLoading: false,
          isFetching: false,
          addAppointment: false,
          selectedTab: INFOTAB,
          appointmentEditData: false,
        },
      ),
    );
  };

  callbackAppointmentDeleted = (uid) => {
    //console.log('callbackAppointmentDeleted first uid: ', uid);
    this.setState({ isFetching: true, appointmentIsLoading: true }, () =>
      this.syncData(
        { appointments: 'appointments' },
        {
          appointmentIsLoading: false,
          isFetching: false,
          addAppointment: false,
          selectedTab: INFOTAB,
          appointmentEditData: false,
        },
        (callbackObject) => {
          //console.log('callbackAppointmentDeleted as callback, uid: ', uid);
          let tmp = this.state.appointments;
          //console.log('callbackAppointmentDeleted as callback, tmp appointments: ', tmp);
          tmp = tmp.filter((elm) => elm.raw?.uid !== uid);
          //console.log('callbackAppointmentDeleted as callback, tmp appointments after filter: ', tmp);
          this.setState({ appointments: tmp }, () => {
            this.setState({ ...callbackObject });
          });
          //console.log('callbackAppointmentDeleted as callback, tmp appointments after setting states');
        },
      ),
    );
  };

  closeAppointmentForm = () => {
    this.setState(
      { addAppointment: false, selectedTab: INFOTAB, appointmentEditData: false },
      this.closeAddMenu,
    );
  };

  editAppointment = (value) => {
    if (this.state.addAppointment === true) {
      this.setState({
        dialogOpen: true,
        dialogText: TextDE.inbox.alerts.alreadyWritingAppointment,
        dialogButtonCloseText: TextDE.ok,
        dialogButtonSaveText: TextDE.inbox.navigation.overwriteAppointment,
        dialogButtonSaveClick: () =>
          this.setState(
            { addAppointment: true, selectedTab: APPOINTMENTTAB, appointmentEditData: value },
            this.dialogClose,
          ),
      });
    } else {
      this.setState(
        { addAppointment: true, selectedTab: APPOINTMENTTAB, appointmentEditData: value },
        this.closeAddMenu,
      );
    }
  };
  // End Appointment Stuff

  // Start Reports Stuff
  callbackCloseReportDetailView = () => {
    this.setState({ isFetching: true, procedureJobsIsFetching: true }, () =>
      this.syncData(
        { procedureJobs: 'procedure.jobs' },
        { procedureJobsIsFetching: false, isFetching: false },
      ),
    );
  };
  callbackNewReport = () => {
    this.setState(
      {
        isFetching: true,
        mediaIsFetching: true,
        procedureJobsIsFetching: true,
      },
      () =>
        this.syncData(
          {
            media: 'media',
            procedureJobs: 'procedure.jobs',
          },
          {
            procedureJobsIsFetching: false,
            mediaIsFetching: false,
            isFetching: false,
          },
        ),
    );
    return;
    //console.log("[TODO?]", "[PerformanceReport]", 'callbackNewReport -> should not be necsessary due to callbackCloseReportDetailView');
  };
  callbackDeletedReport = () => {
    this.callbackCloseReportDetailView();
    return;
    //console.log("[TODO?]", "[PerformanceReport]", 'callbackNewReport -> should not be necsessary due to callbackCloseReportDetailView');
  };
  // End Reports Stuff

  // Start menu/customFunction Stuff
  openAddMenu = (event) => {
    this.setState({
      addMenuOpen: [event.clientX - 2, event.clientY - 4],
    });
  };

  openAddMenuFab = (event) => {
    this.setState({
      addMenuOpenFab: true,
      addMenuOpen: [event.clientX - 2, event.clientY - 4],
    });
  };

  closeAddMenu = () => {
    this.setState({
      addMenuOpenFab: false,
      addMenuOpen: false,
      customFunctionsOpen: false,
      customFunctionsOpenFab: false,
    });
  };

  toggleCustomFunctionsMenu(event) {
    this.setState({ customFunctionsOpen: event.currentTarget });
  }

  toggleCustomFunctionsMenuFab(event) {
    this.setState({ customFunctionsOpenFab: true, customFunctionsOpen: event.currentTarget });
  }

  closeCustomFunctionsMenu(event) {
    this.setState({ customFunctionsOpenFab: false, customFunctionsOpen: false });
  }

  callbackOpenCustomFunctionDialog = (functionId) => {
    let customFunction = this.state.customFunctions.find((element) => element.id === functionId);

    this.setState({
      customFunctionDialog: true,
      customFunctionText: customFunction.notes,
      customFunctionId: functionId,
      customFunctionTitle: customFunction.name ?? false,
      customFunctionIcon: customFunction.type ?? false,
    });
  };
  closeCustomFunctionDialog = () => {
    this.setState({
      customFunctionDialog: false,
      customFunctionText: '',
      customFunctionId: '',
      customFunctionTitle: '',
      customFunctionIcon: '',
    });
  };

  handleCustomFunctionTextChange = (event, value) => {
    this.setState({ customFunctionText: event.target.value });
  };

  executeCustomFunction = (id, dialog) => {
    this.closeCustomFunctionsMenu();
    this.closeAddMenu();
    if (!!dialog) {
      this.callbackOpenCustomFunctionDialog(id);
    } else {
      if (!!this.state.customFunctionDialog) {
        this.callbackExecuteCustomFunction(id, this.state.customFunctionText);
        this.closeCustomFunctionDialog();
      } else {
        this.callbackExecuteCustomFunction(id, false);
        this.closeCustomFunctionDialog();
      }
    }
  };

  callbackExecuteCustomFunction = async (functionId, dialog) => {
    this.setState({
      isFetching: true,
    });

    let data = null;
    if (!!dialog && dialog !== '') {
      data = JSON.stringify({
        uuid: this.state.uuid,
        function: functionId,
        body: dialog,
      });
    } else {
      data = JSON.stringify({ uuid: this.state.uuid, function: functionId });
    }
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/procedures/call', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: data,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });

    if (response.success) {
      this.setState({ isFetching: true, protocolIsFetching: true }, () =>
        this.syncData(
          { procedureItems: 'procedure.items' },
          { protocolIsFetching: false, isFetching: false, selectedTab: PROTOCOLTAB },
        ),
      );
    } else {
      console.error(response);
    }
  };
  // End customFunction Stuff

  // Start Media Stuff

  // Open Specific File
  switchToFile = (fileObject) => {
    this.setState({ selectedMedia: fileObject, selectedTab: FILETAB });
  };

  callbackMediaSetIsFetching = (isFetching) => {
    this.setState({ isFetching: isFetching, mediaIsFetching: isFetching });
  };
  callbackMediaUploaded = (uploadResponse) => {
    // This function gets the whole object already
    let tmpMedia = uploadResponse.concat(this.state.media);
    let tmpMediaJson = tmpMedia.map(JSON.stringify);
    tmpMedia = [...new Set([...tmpMediaJson])].map(JSON.parse);
    this.setState(
      {
        media: tmpMedia.sort(function (a, b) {
          return a.id - b.id;
        }),
      },
      () => this.callbackMediaDeleted(),
    );
  };
  callbackMediaDeleted = (editData) => {
    this.setState({ isFetching: true, mediaIsFetching: true }, () =>
      this.syncData({ media: 'media' }, { mediaIsFetching: false, isFetching: false }),
    );
  };

  // End Media Stuff

  toggleCardsExpansion = (card) => {
    let cardUserExpand = false;
    let cardAddressExpand = false;
    let cardContactExpand = false;
    let cardAppointmentsExpand = false;
    switch (card) {
      case 'UserCard':
        cardUserExpand = !this.state.cardUserExpand;
        break;
      case 'AddressCard':
        cardAddressExpand = !this.state.cardAddressExpand;
        break;
      case 'ContactCard':
        cardContactExpand = !this.state.cardContactExpand;
        break;
      case 'AppointmentsCard':
        cardAppointmentsExpand = !this.state.cardAppointmentsExpand;
        break;
      default:
        break;
    }
    this.setState({
      cardUserExpand: cardUserExpand,
      cardAddressExpand: cardAddressExpand,
      cardContactExpand: cardContactExpand,
      cardAppointmentsExpand: cardAppointmentsExpand,
    });
  };

  componentDidMount() {
    //console.log("componentDidMount");
    this.fetchData(() => {
      //console.log("[DEBUG componentDidMount MessageDetail]", this.props.Router.location.hash, window.location.hash);
      if (!!window?.location?.hash && window.location.hash !== '') {
        //console.log("[DEBUG componentDidMount MessageDetail] We have a hashtag");
        let str = window.location.hash;
        const regex =
          /(job)=([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }

          if (m.length === 3) {
            //console.log(`[DEBUG componentDidMount MessageDetail] Full Match, ` + m[0]);
            //console.log(`[DEBUG componentDidMount MessageDetail] Has Job, ` + m[1]);
            //console.log(`[DEBUG componentDidMount MessageDetail] Has Uuid, ` + m[2]);
            this.handleTabChange(null, REPORTTAB);
            this.setState({ openJobFromUrl: m[2] });
          }
        }
      }
    });
  }

  componentDidUpdate(oldProps, oldState) {
    //if ( prevProps.Router.location.key !== this.props.Router.location.key) {
    if (oldProps?.match?.params?.uuid !== this.props?.match?.params?.uuid) {
      //console.log("componentDidUpdate | RESET!");
      // Reset as in constructor
      this.setState(
        {
          isFetching: true,
          isInbox: true,
          selectedTab: INFOTAB,
          addMenuOpen: false,
          addMenuOpenFab: false,
          customFunctionsOpen: false,
          customFunctionsOpenFab: false,
          customFunctionDialog: false,
          customFunctionText: '',
          customFunctionId: '',
          customFunctionTitle: '',
          customFunctionIcon: '',

          dialogOpen: false,
          dialogText: '',
          dialogButtonCloseText: '',
          dialogButtonSaveText: '',

          type: Constants.getInboxItemType(0),
          isExternalRequest: false,
          uuid: null,
          subject: '',
          subtitle: '',
          body: null,
          creator: false,
          modifier: false,
          createdAt: 0,
          createdBy: 0,
          updatedAt: 0,
          updatedBy: 0,
          deletedAt: 0,
          deletedBy: 0,
          appointments: [],
          procedureId: false,
          procedureSubject: '',
          procedureSubtitle: '',
          procedureBody: null,
          procedureSerial: '',
          procedureStatus: null,
          procedureTags: [],
          procedureUsers: [],
          procedureContacts: [],
          procedureAddresses: [],
          procedureJobs: [],
          procedureJobsIsFetching: true,
          procedureItems: [],
          media: [],

          externalRequestLinkedConnectUsers: [],
          externalRequest: null,

          isCompetitive: false,
          competitorsCount: 0,

          addMail: false,
          addChat: false,
          addAppointment: false,
          addProcedure: false,

          titleIsFetching: true,
          titleEditData: {},
          subtitleIsFetching: true,
          subtitleEditData: {},

          statusIsFetching: true,

          tagsIsFetching: true,

          protocolIsFetching: true,

          mediaIsFetching: true,

          reportIsFetching: true,

          userIsFetching: true,

          customFunctions: [],
          usersAll: [],
          statesAll: [],

          emailIsFetching: false,
          emailEditData: false,

          chatIsFetching: false,
          chatEditData: false,

          contactIsFetching: true,

          addNote: false,
          noteIsFetching: true,
          noteEditData: false,

          appointmentIsLoading: true,
          appointmentEditData: false,

          cardUserExpand: false,
          cardAddressExpand: false,
          cardContactExpand: false,
          cardAppointmentsExpand: false,

          openJobFromUrl: false,
        },
        this.fetchData,
      );
    }
    //else {
    //console.log("componentDidUpdate without Reset");
    //}
  }

  //buttonTest = async () => {
  //  this.setState({ isFetching: true});
  //  await new Promise(r => setTimeout(r, 2000));
  //  this.setState({ isFetching: false});
  //}
  handleTabChange = (event, value) => {
    // Exclude the menu tab and let it be handled by onClick
    if (value !== MENUTTAB) {
      this.setState({ selectedTab: value });
    }
  };

  dialogClose = () => {
    this.setState(
      {
        dialogOpen: false,
        dialogText: '',
        dialogButtonCloseText: '',
        dialogButtonSaveText: '',
        dialogButtonSaveClick: null,
      },
      this.closeAddMenu,
    );
  };

  handleChange(event, value) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async syncData(syncObject, callbackObject, callback = null) {
    const uuid = this.props.match.params.uuid;
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/inbox/' + uuid + '?all=true', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => json)
      .catch((error) => {
        return error;
      });

    if (!!response.uuid) {
      let tmp = {};
      Object.keys(syncObject).forEach((value, index) => {
        if (value === 'media') {
          tmp[value] = [...response.media, ...response.procedure?.media];
        } else if (value === 'procedureItems') {
          let procedureItems = response.procedure?.items ?? [];
          let type = Constants.getInboxItemType('app');
          if (!!response.data) {
            let inboxItemType = type;
            if (!!response.data.from && response.data.from.length > 0) {
              inboxItemType = Constants.getInboxItemType('email');
            }
            procedureItems.push(
              {
                id: 1,
                type_id: type.id,
                created_at: response.procedure?.created_at,
                data: response.procedure?.data,
              },
              {
                id: 0,
                type_id: inboxItemType.id,
                created_at: response.created_at,
                created_by: response.created_by,
                creator: response.creator,
                data: response.data,
              },
            );
          } else {
            procedureItems.push({
              id: 1,
              type_id: type.id,
              created_at: response.procedure?.created_at,
              data: response.procedure?.data,
            });
          }
          procedureItems = procedureItems.sort((a, b) => parseInt(b.id) - parseInt(a.id));
          tmp[value] = procedureItems;
        } else if (syncObject[value].includes('.')) {
          let path = syncObject[value].split('.');
          for (var i = 0; i < path.length; i++) {
            if (i === 0) {
              tmp[value] = response[path[i]];
            } else {
              tmp[value] = tmp[value][path[i]];
            }
          }
        } else {
          tmp[value] = response[syncObject[value]];
        }
      });

      // Always sync tags and status
      tmp.procedureStatus = response.procedure?.status ?? this.state.procedureStatus;
      tmp.procedureTags = response.procedure?.tags ?? this.state.procedureTags;
      tmp.preDefinedTags = [...this.state.customFunctions.map((option) => option.name)];

      //console.log('[SyncDATA]: ',tmp, callback, callbackObject);
      if (typeof callback === 'function') {
        this.setState({ ...tmp });
        callback(callbackObject);
        return;
      }
      this.setState({ ...callbackObject, ...tmp });
    } else {
      console.error('[SyncDATA]: ', response.json(), callbackObject);
      this.setState(callbackObject);
    }
  }

  async fetchData(callback = null) {
    const uuid = this.props.match.params.uuid;
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/inbox/' + uuid + '?all=true', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => json)
      .catch((error) => {
        return error;
      });

    let customFunctions = await fetch(process.env.REACT_APP_API_URL + '/api/customer/functions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json.sort((a, b) => (a.name?.toLowerCase() > b.name?.toLowerCase() ? 1 : -1));
      })
      .catch((error) => {
        return [];
      });

    if (!!response.uuid) {
      let procedureItems = response.procedure?.items ?? [];
      let type = Constants.getInboxItemType('app');
      if (!!response.procedure) {
        procedureItems.push({
          id: 1,
          type_id: type.id,
          created_at: response.procedure?.created_at,
          data: response.procedure?.data,
        });
      }
      if (!!response.data && !!response.data.from && response.data.from.length > 0) {
        type = Constants.getInboxItemType('email');
      }
      procedureItems.push({
        id: 0,
        type_id: type.id,
        created_at: response.created_at,
        created_by: response.created_by,
        creator: response.creator,
        data: response.data,
      });
      procedureItems = procedureItems.sort((a, b) => parseInt(b.id) - parseInt(a.id));

      let usersAll = await fetch(process.env.REACT_APP_API_URL + '/api/users', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          let tmp = [];
          json.forEach((value, key) => {
            if (!!!value.deleted_at) {
              tmp.push({
                uuid: value.uuid,
                firstname: value.firstname,
                nickname: value.nickname,
                lastname: value.lastname,
                email: value.email,
                roles: value.roles.map((role) => role.name),
              });
            }
          });
          tmp = tmp.sort((a, b) =>
            a.lastname > b.lastname ? 1 : b.lastname > a.lastname ? -1 : 0,
          );
          return tmp;
        })
        .catch((error) => {
          return false;
        });

      let statesAll = await fetch(process.env.REACT_APP_API_URL + '/api/jobs/priorities', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => json)
        .catch((error) => {
          return [];
        });

      this.setState(
        {
          isFetching: false,
          isInbox: !!!response.procedure?.id,
          uuid: response.procedure?.uuid ?? response.uuid,

          isCompetitive: !!response.competitive && !!!response.competition_won_by ? true : false,
          competitorsCount: response.competitors?.length ?? 0,

          type: Constants.getInboxItemType(response.type),
          isExternalRequest: !!response.external_request ?? false,
          externalRequest: response.external_request ?? null,

          subject: response.data?.subject ?? null,
          subtitle: response.data?.subtitle ?? null,
          body: response.data?.body ?? null,
          creator: response.creator ?? false,
          modifier: response.modifier ?? false,

          createdAt: response.procedure?.created_at ?? response.created_at,
          createdBy: response.procedure?.created_by ?? response.created_by,
          updatedAt: response.procedure?.updated_at ?? response.updated_at,
          updatedBy: response.procedure?.updated_by ?? response.updated_by,
          deletedAt: response.procedure?.deleted_at ?? response.deleted_at,
          deletedBy: response.procedure?.deleted_by ?? response.deleted_by,

          procedureId: response.procedure?.id ?? false,
          procedureSubject: response.procedure?.data?.subject ?? null,
          procedureSubtitle: response.procedure?.data?.subtitle ?? null,
          procedureBody: response.procedure?.data?.body ?? null,
          procedureSerial: response.procedure?.serial ?? null,
          procedureStatus: response.procedure?.status ?? null,

          procedureTags: response.procedure?.tags ?? [],
          preDefinedTags: [...customFunctions.map((option) => option.name)],
          procedureUsers: response.procedure?.users ?? [],
          procedureContacts: response.procedure?.contacts ?? [],
          procedureAddresses: response.procedure?.addresses ?? [],
          procedureJobs: response.procedure?.jobs ?? [],
          procedureItems: procedureItems,

          externalRequestLinkedConnectUsers: response.external_request?.linked_connect_users ?? [],

          media: [...(response.media ?? []), ...(response.procedure?.media ?? [])].sort(
            function (a, b) {
              return a.id - b.id;
            },
          ),

          appointments: response.appointments,

          customFunctions: customFunctions,
          usersAll: usersAll,
          statesAll: statesAll,
        },
        () =>
          this.setState(
            {
              titleIsFetching: false,
              subtitleIsFetching: false,
              statusIsFetching: false,
              contactIsFetching: false,
              tagsIsFetching: false,
              protocolIsFetching: false,
              mediaIsFetching: false,
              reportIsFetching: false,
              userIsFetching: false,
              appointmentIsLoading: false,
            },
            () => {
              if (typeof callback === 'function') {
                callback();
              }
            },
          ),
      );
    } else {
      this.setState({ hasError: response, isFetching: false });
      console.error(response.json());
    }
  }

  handleCopyClose = () => {
    if (this.state.copy !== false) {
      this.setState({ copy: false });
    }
  };

  copyToClipboard = (identifier, value) => {
    this.setState({ copy: identifier });
    var textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setTimeout(() => this.setState({ copy: false }), 1500);
  };

  render() {
    const { classes } = this.props;

    if (!!this.state.hasError) {
      return (
        <Alert severity='error' className={classes.center}>
          <AlertTitle>
            {this.state.hasError.status} - {this.state.hasError.statusText}
          </AlertTitle>
          {TextDE.inbox.cantShowInboxItem}
        </Alert>
      );
    }

    return (
      <>
        {!!this.state.isInbox === false &&
          (this.state.selectedTab === INFOTAB || this.state.selectedTab === PROTOCOLTAB) && (
            <>
              <Fab
                color='primary'
                aria-label='add'
                className={classes.fab}
                onClick={this.openAddMenuFab}
              >
                <AddIcon />
              </Fab>

              <Fab
                color='primary'
                aria-label='add'
                className={classes.fabFast}
                onClick={this.toggleCustomFunctionsMenuFab}
              >
                <FlashOnIcon />
              </Fab>
            </>
          )}
        <Dialog
          fullWidth={true}
          maxWidth='lg'
          open={this.state.customFunctionDialog}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.closeCustomFunctionDialog();
            }
          }}
        >
          <DialogContent>
            <Typography variant='h2' style={{ fontWeight: 'normal' }} gutterBottom>
              {TextDE.inbox.customFunctions.label}
              {!!this.state.customFunctionTitle ? (
                <b>&nbsp;{this.state.customFunctionTitle}</b>
              ) : (
                ''
              )}
              &nbsp;
              {TextDE.inbox.customFunctions.executeLabel}:
            </Typography>

            <Grid
              container
              spacing={1}
              alignItems='center'
              justifyContent='center'
              alignContent='center'
            >
              <Hidden mdDown>
                <Grid item lg={1}>
                  <center className={classes.iconSizeInherit}>
                    {Constants.getInboxItemType(this.state.customFunctionIcon).icon}
                  </center>
                </Grid>
              </Hidden>
              <Grid item lg={11} md={12} xs={12}>
                <Typography variant='subtitle1' gutterBottom>
                  {TextDE.inbox.customFunctions.dialogHint}
                </Typography>
                <TextField
                  autoFocus
                  name='customFunctionText'
                  fullWidth
                  multiline
                  minRows={8}
                  defaultValue={this.state.customFunctionText}
                  onBlur={this.handleCustomFunctionTextChange}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'space-between' }}>
            <Button onClick={this.closeCustomFunctionDialog} color='secondary' variant='outlined'>
              {TextDE.close}
            </Button>
            <Button
              size='large'
              endIcon={<CreateIcon />}
              onClick={() => this.executeCustomFunction(this.state.customFunctionId, false)}
              color='primary'
              variant='contained'
            >
              {TextDE.save}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.dialogClose}
          aria-labelledby='action dialog'
        >
          <DialogTitle disableTypography>
            <Typography variant='h6' color='textSecondary'>
              {TextDE.inbox.dialog.pleaseConfirm}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText variant='h4' color='primary'>
              {this.state.dialogText}
            </DialogContentText>
          </DialogContent>
          <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button autoFocus onClick={this.dialogClose} color='secondary' variant='outlined'>
              {this.state.dialogButtonCloseText}
            </Button>
            {typeof this.state.dialogButtonSaveClick === 'function' && (
              <Button
                onClick={this.state.dialogButtonSaveClick}
                color='primary'
                autoFocus
                variant='contained'
                size='large'
              >
                {this.state.dialogButtonSaveText}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <div
          style={{
            margin: 0,
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'flex',
          }}
        >
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column',
              flexGrow: '1',
              margin: '8px',
            }}
          >
            <Box m={1} display='flex'>
              <Box style={{ flexGrow: 1 }}>
                <Title
                  type={this.state.type}
                  subject={this.state.procedureSubject ?? this.state.subject ?? 'Neue Anfrage'}
                  isInbox={!!this.state.isInbox}
                  isFetching={this.state.titleIsFetching}
                  callback={this.callbackTitleEdit}
                  saveEditData={this.callbackTitleSaveEditData}
                  editData={this.state.titleEditData}
                />
              </Box>
              <Hidden mdDown>
                <Box style={{ flexShrink: 1 }}>
                  <Users
                    inboxUuid={this.state.uuid}
                    canInviteUsers={this.state.canSeeUserConfig}
                    userIsFetching={this.state.userIsFetching}
                    users={this.state.procedureUsers}
                    usersAll={this.state.usersAll}
                    callback={this.callbackUserEdit}
                  />
                </Box>
              </Hidden>
              <Hidden lgUp>
                <Box style={{ flexShrink: 1 }}>
                  <Tooltip title={TextDE.back} aria-label={TextDE.back}>
                    <IconButton
                      className={classes.backButton}
                      color='primary'
                      variant='outlined'
                      onClick={() => this.props.history.goBack()}
                      aria-label={TextDE.back}
                    >
                      <KeyboardReturnIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Hidden>
              <Hidden mdDown>
                <Box style={{ flexShrink: 1 }}>
                  <Tooltip title={TextDE.back} aria-label={TextDE.back}>
                    <Button
                      className={classes.backButton}
                      color='primary'
                      variant='outlined'
                      onClick={() => this.props.history.goBack()}
                      aria-label={TextDE.back}
                      startIcon={<KeyboardReturnIcon />}
                    >
                      {TextDE.back}
                    </Button>
                  </Tooltip>
                </Box>
              </Hidden>
            </Box>
            {!!!this.state.isInbox && !!!this.state.isFetching && (
              <Box mb={1} ml={1} mr={1} display='flex'>
                <div className={classes.chipRow}>
                  <div style={{ display: 'inline-flex' }}>
                    {!!this.state.isInbox === false && (
                      <Tooltip title={TextDE.procedure.serialTooltip} aria-label='serial'>
                        <Chip
                          label={'ID# ' + this.state.procedureSerial}
                          color='primary'
                          style={{ fontWeight: 'bold' }}
                          className={classes.chip}
                          onClick={(event) =>
                            this.copyToClipboard(
                              'serial',
                              `ID#${this.state.procedureSerial}`.trim(),
                            )
                          }
                        />
                      </Tooltip>
                    )}
                    <Collapse
                      in={this.state.copy === 'serial'}
                      style={{ width: 0, height: 0, zIndex: 999 }}
                    >
                      <Alert
                        className={classes.copyAlert}
                        onClose={this.handleCopyClose}
                        severity='success'
                      >
                        Kopiert
                      </Alert>
                    </Collapse>
                  </div>
                  &nbsp;
                  <StatusChip
                    status={this.state.procedureStatus}
                    isInbox={!!this.state.isInbox}
                    isFetching={this.state.statusIsFetching}
                    callback={this.callbackStatusEdit}
                  />
                  &nbsp;
                  {!!this.state.isInbox === false && (
                    <Tags
                      tags={this.state.procedureTags}
                      preDefinedTags={this.state.preDefinedTags}
                      isInbox={!!this.state.isInbox}
                      isFetching={this.state.tagsIsFetching}
                      callback={this.callbackTagsEdit}
                    />
                  )}
                </div>
              </Box>
            )}
            <Tabs
              selectionFollowsFocus
              orientation='horizontal'
              variant='scrollable'
              value={this.state.selectedTab}
              onChange={this.handleTabChange}
              aria-label='Change View'
              className={classes.navTabs}
            >
              <Tab
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={TextDE.info}
                icon={<InfoOutlinedIcon />}
                aria-label={TextDE.info}
                value={INFOTAB}
              />
              <Tab
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={
                  <>
                    {TextDE.procedure.labelNotes}
                    {this.state.procedureItems?.filter((value) =>
                      value.created_at.startsWith(this.state.today),
                    ).length > 0 && (
                      <Avatar className={classes.badgeAvatar}>
                        {
                          this.state.procedureItems?.filter((value) =>
                            value.created_at.startsWith(this.state.today),
                          ).length
                        }
                      </Avatar>
                    )}
                  </>
                }
                icon={<CreateIcon />}
                aria-label={TextDE.procedure.labelNotes}
                value={PROTOCOLTAB}
              />
              <Tab
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={
                  <>
                    {TextDE.file.labelPlural}
                    {this.state.media?.length > 0 && (
                      <Avatar className={classes.badgeAvatar}>{this.state.media?.length}</Avatar>
                    )}
                  </>
                }
                icon={<AttachFileIcon />}
                aria-label={TextDE.file.labelPlural}
                value={FILETAB}
              />
              {!!this.state.isInbox === false && (
                <Tab
                  classes={{
                    root: classes.navTabRoot,
                    wrapper: classes.smallText,
                    selected: classes.selectedTab,
                  }}
                  label={
                    <>
                      {TextDE.workorder.labelPluralShort}
                      {this.state.procedureJobs.filter(
                        (value) => value.status.name.toLowerCase() !== 'erledigt',
                      ).length > 0 && (
                        <Avatar className={classes.badgeAvatar}>
                          {
                            this.state.procedureJobs?.filter(
                              (value) => value.status.name.toLowerCase() !== 'erledigt',
                            ).length
                          }
                        </Avatar>
                      )}
                    </>
                  }
                  icon={<AssignmentTurnedInIcon />}
                  aria-label={TextDE.workorder.labelPluralShort}
                  value={REPORTTAB}
                />
              )}
              <Tab
                className={classes.interactiveTabs}
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={TextDE.inbox.tabs.email}
                icon={<AlternateEmailIcon />}
                aria-label={TextDE.inbox.tabs.email}
                value={MAILTAB}
                style={{ display: this.state.addMail ? 'flex' : 'none' }}
              />
              <Tab
                className={classes.interactiveTabs}
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={TextDE.inbox.tabs.chat}
                icon={<CommentIcon />}
                aria-label={TextDE.inbox.tabs.chat}
                value={CHATTAB}
                style={{ display: this.state.addChat ? 'flex' : 'none' }}
              />
              <Tab
                className={classes.interactiveTabs}
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={TextDE.inbox.tabs.appointment}
                icon={<EventIcon />}
                aria-label={TextDE.inbox.tabs.appointment}
                value={APPOINTMENTTAB}
                style={{ display: this.state.addAppointment ? 'flex' : 'none' }}
              />
              <Tab
                className={classes.interactiveTabs}
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={
                  Boolean(this.state.noteEditData)
                    ? TextDE.inbox.tabs.noteEdit
                    : TextDE.inbox.tabs.note
                }
                icon={<NoteAddIcon />}
                aria-label={
                  Boolean(this.state.noteEditData)
                    ? TextDE.inbox.tabs.noteEdit
                    : TextDE.inbox.tabs.note
                }
                value={NOTETAB}
                style={{ display: this.state.addNote ? 'flex' : 'none' }}
              />
              {!!!this.state.isCompetitive && !!!this.state.isInbox && (
                <Tab
                  classes={{
                    root: classes.navTabRoot,
                    wrapper: classes.smallText,
                    selected: classes.selectedTab,
                  }}
                  label={TextDE.add}
                  icon={<AddIcon />}
                  aria-label='add more'
                  value={MENUTTAB}
                  onClick={this.openAddMenu}
                />
              )}
              <Tab
                className={classes.interactiveTabs}
                classes={{
                  root: classes.navTabRoot,
                  wrapper: classes.smallText,
                  selected: classes.selectedTab,
                }}
                label={TextDE.inbox.tabs.procedure}
                icon={<AssignmentIcon />}
                aria-label={TextDE.inbox.tabs.procedure}
                value={NEWPROCEDURETAB}
                style={{ display: this.state.isInbox || this.state.addProcedure ? 'flex' : 'none' }}
              />
            </Tabs>

            <Menu
              id='add-menu'
              open={Boolean(this.state.addMenuOpen)}
              onClose={this.closeAddMenu}
              keepMounted={true}
              anchorReference='anchorPosition'
              anchorPosition={
                this.state.addMenuOpen !== false && this.state.addMenuOpen.length === 2
                  ? { top: this.state.addMenuOpen[1], left: this.state.addMenuOpen[0] }
                  : undefined
              }
              transformOrigin={
                !!this.state.addMenuOpenFab
                  ? {
                      vertical: 'bottom',
                      horizontal: 'right',
                    }
                  : {
                      vertical: 'top',
                      horizontal: 'left',
                    }
              }
              PaperProps={{
                style: {
                  maxWidth: '50ch',
                  minWidth: '20ch',
                  maxHeight: '30em',
                },
              }}
            >
              {!!!this.state.isInbox && this.state.customFunctions.length > 0 ? (
                <MenuItem onClick={this.toggleCustomFunctionsMenu}>
                  <ListItemIcon>
                    <CallMissedOutgoingIcon />
                  </ListItemIcon>
                  <ListItemText primary={TextDE.inbox.customFunctions.labelPlural} />
                </MenuItem>
              ) : null}
              {!!!this.state.isInbox && (
                <MenuItem onClick={this.switchToChat}>
                  <ListItemIcon>
                    <CommentIcon />
                  </ListItemIcon>
                  <ListItemText primary={TextDE.inbox.tabs.chat} />
                </MenuItem>
              )}
              {!!!this.state.isInbox && (
                <MenuItem onClick={this.switchToMail}>
                  <ListItemIcon>
                    <AlternateEmailIcon />
                  </ListItemIcon>
                  <ListItemText primary={TextDE.inbox.tabs.email} />
                </MenuItem>
              )}
              {!!!this.state.isInbox && (
                <MenuItem onClick={this.switchToNote}>
                  <ListItemIcon>
                    <NoteAddIcon />
                  </ListItemIcon>
                  <ListItemText primary={TextDE.inbox.tabs.note} />
                </MenuItem>
              )}
              {!!this.state.isInbox && !!!this.state.isCompetitive && (
                <MenuItem onClick={this.switchToNewProcedure}>
                  <ListItemIcon>
                    <AssignmentIcon />
                  </ListItemIcon>
                  <ListItemText primary={TextDE.inbox.tabs.procedure} />
                </MenuItem>
              )}
              {!!!this.state.isInbox && (
                <MenuItem onClick={this.switchToAppointment}>
                  <ListItemIcon>
                    <EventIcon />
                  </ListItemIcon>
                  <ListItemText primary={TextDE.inbox.tabs.appointment} />
                </MenuItem>
              )}
            </Menu>
            <Menu
              anchorEl={this.state.customFunctionsOpen}
              open={Boolean(this.state.customFunctionsOpen)}
              onClose={this.closeCustomFunctionsMenu}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={
                !!this.state.customFunctionsOpenFab
                  ? {
                      vertical: 'bottom',
                      horizontal: 'right',
                    }
                  : {
                      vertical: 'top',
                      horizontal: 'left',
                    }
              }
              PaperProps={{
                style: {
                  maxHeight: '300px',
                  maxWidth: '40ch',
                },
              }}
            >
              {this.state.customFunctions.map((option, index) => (
                <MenuItem
                  key={'custom-function-' + index}
                  onClick={() => this.executeCustomFunction(option.id, option.dialog)}
                >
                  <ListItemIcon>{Constants.getInboxItemType(option.type).icon}</ListItemIcon>
                  <ListItemText primary={option.name} />
                </MenuItem>
              ))}
            </Menu>

            <LinearProgress
              color={!!this.state.isFetching ? 'secondary' : 'primary'}
              className={classes.progressBar}
              value={!!this.state.isFetching ? 21 : 100}
              variant={!!this.state.isFetching ? 'indeterminate' : 'determinate'}
            />
            {/* Using hidden and not display noe for refreshing things */}
            <Box
              role='tabpanel'
              hidden={this.state.selectedTab !== INFOTAB}
              id={`wrapped-tabpanel-${INFOTAB}`}
              aria-labelledby={`wrapped-tab-${INFOTAB}`}
              p={2}
              className={classes.scrollableTab}
            >
              <Grid container spacing={!!this.state.isInbox ? 4 : 1}>
                <Grid item xs={12}>
                  <Subtitle
                    subtitle={this.state.procedureSubtitle ?? this.state.subtitle ?? ''}
                    isInbox={!!this.state.isInbox}
                    isFetching={this.state.subtitleIsFetching}
                    callback={this.callbackSubtitleEdit}
                    saveEditData={this.callbackSubtitleSaveEditData}
                    editData={this.state.subtitleEditData}
                  />
                </Grid>
                {!!this.state.isInbox === false && (
                  <Grid item xs={12} md={7}>
                    <ContactCard
                      linkedConnectUsers={this.state.externalRequestLinkedConnectUsers}
                      canInviteUsers={this.state.canSeeUserConfig}
                      inboxUuid={this.state.uuid}
                      contacts={this.state.procedureContacts}
                      addresses={this.state.procedureAddresses}
                      isInbox={!!this.state.isInbox}
                      isFetching={this.state.contactIsFetching}
                      callback={this.callbackContactEdit}
                      sendMailTo={this.sendMailToContact}
                    />
                  </Grid>
                )}
                {!!this.state.isInbox === false && (
                  <Grid item xs={12} md={5}>
                    <AppointmentsCard
                      appointments={this.state.appointments}
                      callback={this.callbackAppointmentSaved}
                      deleteCallback={this.callbackAppointmentDeleted}
                      editCallback={this.editAppointment}
                      addCallback={this.switchToAppointment}
                      appointmentIsLoading={this.state.appointmentIsLoading}
                    />
                  </Grid>
                )}
                {!!this.state.isInbox && !!!this.state.isFetching && !!this.state.isCompetitive && (
                  <Grid item xs={12}>
                    <CompetitiveCard
                      model='inbox'
                      uuid={this.state.uuid}
                      isCompetitive={this.state.isCompetitive}
                      competitors={this.state.competitorsCount}
                      requestText={this.state.body}
                      external={this.state.externalRequest}
                      switchToProtocol={() => this.setState({ selectedTab: PROTOCOLTAB })}
                      callbackDeclined={this.callbackDeclinedCompetitiveInbox}
                      callbackAccepted={this.callbackAcceptedCompetitiveInbox}
                    />
                  </Grid>
                )}
                {!!this.state.isInbox &&
                  !!!this.state.isFetching &&
                  !!!this.state.isCompetitive && (
                    <Grid item xs={12}>
                      <MakeNewProcedureCard
                        isCompetitive={this.state.isCompetitive}
                        callbackSwitchToProcedure={this.switchToNewProcedure}
                      />
                    </Grid>
                  )}
                {!!this.state.isInbox &&
                  !!!this.state.isFetching &&
                  !!!this.state.isCompetitive && (
                    <Grid item xs={12}>
                      <AssignCard
                        model='inbox'
                        uuid={this.state.uuid}
                        isCompetitive={this.state.isCompetitive}
                        callbackAssigned={this.callbackAssignedInbox}
                      />
                    </Grid>
                  )}

                {!!this.state.isInbox &&
                  !!!this.state.isFetching &&
                  !!!this.state.isCompetitive && (
                    <Grid item xs={12}>
                      <DeleteCard
                        isCompetitive={this.state.isCompetitive}
                        openDeleteDialog={this.openDeleteDialog}
                      />
                    </Grid>
                  )}
              </Grid>
            </Box>

            <Box
              role='tabpanel'
              hidden={this.state.selectedTab !== PROTOCOLTAB}
              id={`wrapped-tabpanel-${PROTOCOLTAB}`}
              aria-labelledby={`wrapped-tab-${PROTOCOLTAB}`}
              p={0}
              pt={1}
              pb={1}
              className={classes.scrollableTab}
            >
              <Protocol
                uuid={this.state.uuid}
                isInbox={this.state.isInbox}
                createdAt={this.state.createdAt}
                createdBy={this.state.creator}
                protocol={this.state.procedureItems}
                isFetching={this.state.protocolIsFetching}
                addChat={this.switchToChat}
                addAppointment={this.switchToAppointment}
                addNote={this.switchToNote}
                addEmail={this.switchToMail}
                openAddMenu={this.openAddMenu}
                switchToFile={this.switchToFile}
                respondEmail={this.callbackEmailRespond}
                respondChat={this.callbackChatRespond}
                editNote={this.callbackNoteEdit}
                deleteNote={this.callbackNoteDelete}
                model={!!this.state.isInbox ? 'inbox' : 'procedures'}
                noteSendCallback={this.callbackNoteSend}
              />
            </Box>

            <Box
              role='tabpanel'
              hidden={this.state.selectedTab !== FILETAB}
              id={`wrapped-tabpanel-${FILETAB}`}
              aria-labelledby={`wrapped-tab-${FILETAB}`}
              className={classes.fixedHeightTab}
            >
              <FileCard
                isInbox={this.state.isInbox}
                isFetching={this.state.mediaIsFetching}
                media={this.state.media}
                selectedMedia={this.state.selectedMedia}
                callbackMediaSetIsFetching={this.callbackMediaSetIsFetching}
                callbackMediaUploaded={this.callbackMediaUploaded}
                callbackMediaDeleted={this.callbackMediaDeleted}
                uuid={this.state.uuid}
              />
            </Box>

            {!!this.state.isInbox === false && (
              <Box
                role='tabpanel'
                hidden={this.state.selectedTab !== REPORTTAB}
                id={`wrapped-tabpanel-${REPORTTAB}`}
                aria-labelledby={`wrapped-tab-${REPORTTAB}`}
                p={2}
                className={classes.scrollableTab}
              >
                <PerformanceReport
                  callbackCloseReportDetailView={this.callbackCloseReportDetailView}
                  callbackNewReport={this.callbackNewReport}
                  callbackDeletedReport={this.callbackDeletedReport}
                  procedureJobsIsFetching={this.state.procedureJobsIsFetching}
                  inboxUuid={this.state.uuid}
                  serial={this.state.procedureSerial}
                  subject={this.state.procedureSubject}
                  subtitle={this.state.procedureSubtitle}
                  comment={this.state.procedureBody}
                  jobs={this.state.procedureJobs || []}
                  openJob={this.state.openJobFromUrl}
                  //hier

                  canInviteUsers={this.state.canSeeUserConfig}
                  usersAll={this.state.usersAll}
                  statesAll={this.state.statesAll}
                  preSelectedUsers={[this.props.User]}
                />
              </Box>
            )}

            {/* Boxes for persisting data (forms?) */}
            <Box
              className={classes.scrollableTab}
              role='tabpanel'
              hidden={this.state.selectedTab !== MAILTAB}
              id={`wrapped-tabpanel-${MAILTAB}`}
              aria-labelledby={`wrapped-tab-${MAILTAB}`}
              p={2}
            >
              <EmailForm
                isInbox={!!this.state.isInbox}
                isFetching={this.state.emailIsFetching}
                uuid={this.state.uuid}
                callbackEmailSend={this.callbackEmailSend}
                callbackNewProcedureFromMail={(uuid) => this.callbackNewProcedureSend(uuid)}
                handleClose={this.closeMailForm}
                saveEditData={this.callbackEmailSaveEditData}
                editData={this.state.emailEditData}
                subject={this.state.procedureSubject || this.state.subject || ''}
                serial={this.state.procedureSerial || false}
                contacts={this.state.procedureContacts || []}
                media={this.state.media}
                callbackMediaSetIsFetching={this.callbackMediaSetIsFetching}
                callbackMediaUploaded={this.callbackMediaUploaded}
                callbackMediaDeleted={this.callbackMediaDeleted}
              />
            </Box>

            <Box
              className={classes.scrollableTab}
              role='tabpanel'
              hidden={this.state.selectedTab !== CHATTAB}
              id={`wrapped-tabpanel-${CHATTAB}`}
              aria-labelledby={`wrapped-tab-${CHATTAB}`}
              p={2}
            >
              <ChatForm
                isInbox={!!this.state.isInbox}
                isFetching={this.state.chatIsFetching}
                callbackChatSend={this.callbackChatSend}
                handleClose={this.closeChatForm}
                saveEditData={this.callbackChatSaveEditData}
                callbackMediaSetIsFetching={this.callbackMediaSetIsFetching}
                callbackMediaUploaded={this.callbackMediaUploaded}
                callbackMediaDeleted={this.callbackMediaDeleted}
                uuid={this.state.uuid}
                media={this.state.media}
                subject={this.state.procedureSubject || this.state.subject || ''}
                serial={this.state.procedureSerial || false}
                editData={this.state.chatEditData}
                contacts={this.state.procedureContacts || []}
              />
            </Box>

            <Box
              className={classes.scrollableTab}
              role='tabpanel'
              hidden={this.state.selectedTab !== NOTETAB}
              id={`wrapped-tabpanel-${NOTETAB}`}
              aria-labelledby={`wrapped-tab-${NOTETAB}`}
              p={2}
            >
              <NoteForm
                model={!!this.state.isInbox ? 'inbox' : 'procedures'}
                uuid={this.state.uuid}
                handleClose={this.closeNoteForm}
                callback={this.callbackNoteSend}
                isFetching={this.state.noteIsFetching}
                editData={this.state.noteEditData}
              />
            </Box>

            <Box
              className={classes.scrollableTab}
              role='tabpanel'
              hidden={this.state.selectedTab !== APPOINTMENTTAB}
              id={`wrapped-tabpanel-${APPOINTMENTTAB}`}
              aria-labelledby={`wrapped-tab-${APPOINTMENTTAB}`}
            >
              <AppointmentForm
                appointmentIsLoading={this.state.appointmentIsLoading}
                inboxUuid={this.state.uuid}
                procedureId={this.state.procedureId}
                subject={this.state.procedureSubject}
                description={this.state.procedureSubtitle}
                addresses={this.state.procedureAddresses}
                users={this.state.procedureUsers}
                usersAll={this.state.usersAll}
                contacts={this.state.procedureContacts}
                appointmentEditData={this.state.appointmentEditData}
                handleClose={this.closeAppointmentForm}
                callback={this.callbackAppointmentSaved}
              />
            </Box>
            <Box
              className={classes.scrollableTab}
              role='tabpanel'
              hidden={this.state.selectedTab !== NEWPROCEDURETAB}
              id={`wrapped-tabpanel-${NEWPROCEDURETAB}`}
              aria-labelledby={`wrapped-tab-${NEWPROCEDURETAB}`}
              p={2}
            >
              <AddProcedure
                dialogData={{
                  subject: this.state.subject,
                  uuid: this.state.uuid,
                }}
                handleclosefunction={(uuid) => this.callbackNewProcedureSend(uuid)}
                hideTitle={true}
              />
            </Box>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  NavMenu: state.NavMenu,
  Theme: state.Style.Theme,
  Dimensions: state.Dimensions,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(MessageDetail);

/*
attendees: [ ...this.state.message?.procedure?.users?.map( 
                          tmp => { return { full: tmp.user.firstname + ' ' + tmp.user.lastname,  email: tmp.user.email, user: tmp.user }}
                        ), ...this.state.message?.procedure?.contacts?.map( 
                          tmp => { return { cn: tmp.contact.firstname + ' ' + tmp.contact.name,  email: tmp.contact.emails[0].email }}
                        )],
                        */
