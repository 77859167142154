import React, { createRef } from 'react';
import Dropzone from 'react-dropzone';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';
import Badge from '@material-ui/core/Badge';
import { DE as TextDE } from '../../../lib/Text';
import { Constants } from '../../../lib/Constants';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, genKey, ContentBlock } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import CustomToolbarComponent from '../components/CustomToolbarComponent';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import Collapse from '@material-ui/core/Collapse';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const switchModelType = (type) => {
  type = type.toLowerCase();
  if (type.includes('procedure')) {
    return '/auth/file/procedure/';
  }

  if (type.includes('inbox')) {
    return '/auth/file/inboxitem/';
  }

  if (type.includes('job')) {
    return '/auth/file/job/';
  }

  return '/false';
};

const styles = (theme) => ({
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  contactChip: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundColor: 'lightgrey',
  },
  maxSized: {
    height: 'auto',
    overflow: 'clip',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '16.6666%',
    },
  },
  wrapper: {
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.action.disabled,
    borderWidth: '1px',
  },
  editor: {
    minHeight: '231px',
    '&>div': {
      minHeight: '200px',
      padding: theme.spacing(1, 2),
    },
  },
  uploadMedia: {
    flexGrow: 1,
    overflow: 'clip',
    maxWidth: '100%',
    [theme.breakpoints.up('xs')]: {
      minWidth: '50%',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '16.6666%',
    },
  },
  active: {
    borderColor: theme.palette.primary.main + '!important',
    borderWidth: '2px!important',
    //'&>div': {},
  },
  required: {
    borderColor: theme.palette.error.main + '!important',
    borderWidth: '1px!important',
    //'&>div': {},
  },
  toolbar: {
    backgroundColor: theme.palette.grey['400'],
    paddingBottom: theme.spacing(1),
    marginBottom: 0,
    '&>div': {
      marginBottom: 0,
    },
  },
  dropzoneSize: {
    height: 'auto',
    minHeight: '218px',
  },
  xs: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&>svg': {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
  labelContacts: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'start',
    marginTop: theme.spacing(2),
  },
  labelChips: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'start',
    marginTop: theme.spacing(1),
  },
  signature: {
    marginLeft: '1em',
    maxHeight: '80px',
    overflow: 'clip',
    cursor: 'pointer',
    transition: 'max-height 400ms',
  },
  signatureExpanded: {
    marginLeft: '1em',
    maxHeight: '100%',
    overflow: 'unset',
    cursor: 'pointer',
    transition: 'max-height 400ms',
  },
  draggedBg: {
    backgroundColor: theme.palette.success.light,
    display: 'block',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  nonDraggedBg: {
    display: 'block',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dragged: {
    backgroundColor: theme.palette.success.contrastText,
    color: theme.palette.success.main,
  },
  nonDragged: {},
  item: {
    scale: 1,
    transition: 'scale 300ms',
    '&>span': {
      display: 'block',
      padding: theme.spacing(1) + 'px!important',
    },
  },
  selectedItem: {
    boxShadow: '0px 0px 5px 4px green',
    scale: 0.9,
    transition: 'scale 300ms',
    '&>span': {
      display: 'flex',
      justifyContent: 'space-between',
      flexShrink: 1,
      padding: theme.spacing(1) + 'px!important',
      '&::before': {
        fontSize: '48px',
        content: '"\\2713"',
        display: 'inline-block',
        color: 'green',
        margin: theme.spacing(-2, 0),
      },
    },
  },
});

class ChatForm extends React.Component {
  constructor(props) {
    super(props);

    this.scrollToRef = React.createRef();

    this.state = {
      subject: props.subject,
      searchResults: [],
      searchNotFound: false,
      isSubmitting: false,
      hasError: false,
      editorState: EditorState.createEmpty(),
      editorFocus: false,
      editorEmpty: true,
      filesDraggedIn: false,
      to: [],
      shareToConnect: [],
      checked: {},
      newFiles: [],
      texttemplates: [],
      signatureExpanded: false,
      attachments: {},
      rejectedFiles: [],
      uploadTileExpanded: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.pushContact = this.pushContact.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.selectFileUpload = this.selectFileUpload.bind(this);
  }

  handleContactSearch = async (event, inputValue) => {
    if (inputValue.trim().length < 3) {
      // do nothing
      return;
    }
    this.setState({ searchIsLoading: true });
    const contacts = await fetch(
      process.env.REACT_APP_API_URL + '/api/contacts/connect/search/' + inputValue.trim(),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        if (response.length > 0) {
          let tmp = [];
          response.forEach((contact) => {
            tmp.push({
              inputValue: '',
              uuid: contact.uuid,
              formOfAddress: TextDE.contact.generateFormOfAddress(contact),
              full: TextDE.contact.generateFullName(contact),
              isConnectUser: contact.connect_uuid,
            });
          });
          return tmp;
        }
        return [];
      });
    this.setState({
      searchResults: contacts,
      searchIsLoading: false,
      searchNotFound: contacts.length < 1,
    });
  };

  async handleUpload(event) {
    if (!!event) {
      event.preventDefault();
    }

    this.props.callbackMediaSetIsFetching(true);

    let formData = new FormData();
    formData.append('_method', 'PUT');
    let newFilesFileNames = [];

    this.state.newFiles.forEach((file, i) => {
      formData.append('uploads[]', file, file.name);
      newFilesFileNames.push(file.name);
    });

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response && !!response.media) {
      this.props.callbackMediaUploaded(response.media);
      this.setState({ newFiles: [], hasError: null, rejectedFiles: [] });
      this.selectFileUpload(response.media, newFilesFileNames);
    } else {
      this.setState({ hasError: response });
    }
  }

  selectFile = (index) => {
    let file = this.props.media[index];
    let tmp = this.state.attachments;
    let fileIndex = file.id + '-' + file.model_id; //tmp.findIndex( (element) => element.id+'_'+element.model_id === file.id+'_'+file.model_id );
    if (!!tmp[fileIndex]) {
      delete tmp[fileIndex];
    } else {
      tmp[fileIndex] = file;
    }
    this.setState({ attachments: tmp });
  };

  selectFileUpload = (media, filenames) => {
    if (Array.isArray(media) && media.length >= 1) {
      let tmp = this.state.attachments;
      media.forEach((file, i) => {
        if (filenames.includes(file.file_name)) {
          let fileIndex = file.id + '-' + file.model_id; //tmp.findIndex( (element) => element.id+'_'+element.model_id === file.id+'_'+file.model_id );
          if (!!tmp[fileIndex]) {
            delete tmp[fileIndex];
          } else {
            tmp[fileIndex] = file;
          }
        }
      });
      this.setState({ attachments: tmp });
    }
  };

  handleOnDragEnter = () => {
    this.setState({ filesDraggedIn: true });
  };

  handleOnDragLeave = () => {
    this.setState({ filesDraggedIn: false });
  };

  handleOnDropRejected = (files) => {
    let rejectedFiles = files.map((element) => {
      if (element.size > 30000000) {
        return TextDE.file.errors.toBig(element.name, element.size);
      }
      return TextDE.file.errors.couldNotBeUploaded(element.name);
    });
    this.setState({ rejectedFiles: rejectedFiles });
  };

  handleFileChange(files) {
    if (files.length > 0) {
      if (this.state.filesDraggedIn) {
        this.setState({ newFiles: files, filesDraggedIn: false }, this.handleUpload);
      } else {
        this.setState({ newFiles: files }, this.handleUpload);
      }
    }
  }

  onEditorStateChange = (editorState) => {
    let isEmpty = !editorState.getCurrentContent().hasText();
    this.setState({ editorState: editorState, editorEmpty: isEmpty });

    //console.log('getLastChangeType', editorState.getLastChangeType(), 'isSelectionAtEndOfContent',editorState.isSelectionAtEndOfContent());
    if (
      'split-block' === editorState.getLastChangeType() &&
      editorState.isSelectionAtEndOfContent() === true
    ) {
      this.scrollToRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
  };

  toggleSignatureExpansion = () => {
    this.setState({ signatureExpanded: !this.state.signatureExpanded });
  };
  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };
  pushContact(contact) {
    let contacts = this.state.to ?? [];
    if (contacts.findIndex((value) => value.mail === contact.mail) === -1) {
      let shareToConnect = this.state.shareToConnect;
      if (contact.isConnectUser !== null && !shareToConnect.includes(contact.isConnectUser)) {
        shareToConnect.push(contact.isConnectUser);
        this.setState({ shareToConnect: shareToConnect });
      }
      contacts.push(contact);
      this.handleContactChange(null, contacts);
    }
  }

  insertTextToEditorState = (direction, textToInsert, editorState) => {
    const selection = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const blockMap = contentState.getBlockMap();
    //const currentBlock = contentState.getBlockForKey(selection.getEndKey())
    const currentBlock = contentState.getBlockForKey(
      direction === 'before' ? blockMap.first().getKey() : blockMap.last().getKey(),
    );
    // Split the blocks
    const blocksBefore = blockMap.toSeq().takeUntil(function (v) {
      return v === currentBlock;
    });

    const blocksAfter = blockMap
      .toSeq()
      .skipUntil(function (v) {
        return v === currentBlock;
      })
      .rest();

    const newBlockKey = genKey();
    let newBlocks =
      direction === 'before'
        ? [
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: 'unstyled',
                text: textToInsert,
              }),
            ],
            [currentBlock.getKey(), currentBlock],
          ]
        : [
            [currentBlock.getKey(), currentBlock],
            [
              newBlockKey,
              new ContentBlock({
                key: newBlockKey,
                type: 'unstyled',
                text: textToInsert,
              }),
            ],
          ];
    const newBlockMap = blocksBefore.concat(newBlocks, blocksAfter).toOrderedMap();
    const newContentState = contentState.merge({
      blockMap: newBlockMap,
      selectionBefore: selection,
      selectionAfter: selection,
    });
    return EditorState.push(editorState, newContentState, 'insert-fragment');
  };

  handleContactChange = (event, value) => {
    let tmp = value
      .filter((element) => element.isConnectUser !== null)
      .map((element) => element.isConnectUser);
    let shareToConnect = this.state.shareToConnect;
    let difference = value.filter((x) => !this.state.to?.includes(x));

    if (difference.length > 0) {
      difference.forEach((element) => {
        if (element.isConnectUser != null) {
          shareToConnect.push(element.isConnectUser);
        }
      });
    }
    shareToConnect = shareToConnect.filter((element) => tmp.includes(element));

    //console.log('filtered shareToConnect:' , shareToConnect);

    if (value && value.inputValue) {
      this.setState({ shareToConnect: shareToConnect, to: value });
      return;
    }
    if (value.length > 0) {
      let greeting = value
        .map((contact) => {
          if (!!contact.formOfAddress) {
            return contact.formOfAddress;
          } else if (!!contact.full) {
            return contact.full;
          }
          return false;
        })
        .filter((contact) => contact !== false)
        .join(' ');

      const newEditorState = this.insertTextToEditorState(
        'before',
        greeting,
        this.state.editorState,
      );

      this.setState({
        shareToConnect: shareToConnect,
        to: value,
        editorState: newEditorState,
        editorEmpty: false,
      });
    } else {
      this.setState({
        shareToConnect: shareToConnect,
        to: value,
      });
    }
  };

  async handleFormSubmit(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();

    formData.append('type_id', Constants.getInboxItemTypeId('chatbot'));
    formData.append('to', JSON.stringify(this.state.to));
    formData.append('shareToConnect', JSON.stringify(this.state.shareToConnect));
    formData.append('subject', this.state.subject);
    formData.append('body', stateToHTML(this.state.editorState.getCurrentContent()));

    Object.keys(this.state.attachments).forEach((file) => {
      formData.append('attachFiles[]', JSON.stringify(this.state.attachments[file]));
    });

    let url = process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid + '/chat';
    formData.append('_method', 'PUT');

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.sent) {
      this.setState({
        subject: '',
        editorState: EditorState.createEmpty(),
        to: [],
        isSubmitting: false,
        hasError: false,
      });
      this.props.callbackChatSend();
    } else {
      if (!!response.message) {
        this.setState({ isSubmitting: false, hasError: response.message });
      } else {
        this.setState({
          isSubmitting: false,
          hasError: JSON.stringify(response),
        });
      }
    }
  }
  handleClose = () => {
    this.setState({
      subject: '',
      editorState: EditorState.createEmpty(),
      to: [],
      isSubmitting: false,
      hasError: false,
      attachments: {},
      uploadTileExpanded: false,
    });
    this.props.handleClose();
  };

  handleShareToConnect = (event, value) => {
    let tmp = this.state.shareToConnect || [];

    if (tmp.includes(event.target.name)) {
      tmp = tmp.filter((element) => element !== event.target.name);
    } else {
      tmp.push(event.target.name);
    }
    //console.log("new shareToConnect", tmp);
    this.setState({ shareToConnect: tmp });
  };

  componentDidUpdate(oldProps, oldState) {
    if (oldProps?.match?.params?.uuid !== this.props?.match?.params?.uuid) {
      this.setState({
        subject: '',
        searchResults: [],
        searchNotFound: false,
        isSubmitting: false,
        hasError: false,
        editorState: EditorState.createEmpty(),
        editorFocus: false,
        editorEmpty: true,
        filesDraggedIn: false,
        to: [],
        shareToConnect: [],
        checked: {},
        newFiles: [],
        texttemplates: [],
        signatureExpanded: false,
        attachments: {},
        rejectedFiles: [],
        uploadTileExpanded: false,
      });
    } else {
      if (
        Boolean(oldProps.subject) === false &&
        Boolean(this.props.subject) !== false &&
        Boolean(this.props.editData) === false
      ) {
        if (this.state.subject !== this.props.subject) {
          if (this.state.subject === '') {
            let subject = this.props.subject;
            if (!!subject.includes(this.props.serial)) {
              //eslint-disable-next-line
              let re = new RegExp(
                `\(\[\\\[\|\\(\\{\]ID#${this.props.serial}\[\\]\|\\)\\}\]\)\\s\*`,
                'gim',
              );
              subject = subject.replace(re, '');
            }
            this.setState({
              subject: subject,
              attachments: {},
              uploadTileExpanded: false,
              isSubmitting: false,
              hasError: false,
            });
          }
        }
      }
    }

    if (Boolean(oldProps.editData) === false && Boolean(this.props.editData) !== false) {
      let editorState = null;
      let to = [];
      let shareToConnect = [];
      let editorEmpty = true;
      if (!!this.props.editData?.body) {
        let blocksFromHTML = convertFromHTML(
          this.props.editData?.body?.html ||
            this.props.editData?.body?.text ||
            this.props.editData?.body,
        );
        editorState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap,
        );
        editorState = EditorState.createWithContent(editorState);
        editorState = this.insertTextToEditorState('before', 'Vorherige Nachricht:', editorState);
        editorEmpty = false;
      } else {
        editorState = EditorState.createEmpty();
      }

      if (!!this.props.editData?.to) {
        let greeting = '';
        to = this.props.editData?.to
          .filter((element) => !!element.contact.connect_uuid)
          .map((element) => {
            let contact = {
              inputValue: '',
              uuid: element.contact.uuid,
              formOfAddress: TextDE.contact.generateFormOfAddress(element.contact),
              full: TextDE.contact.generateFullName(element.contact),
              isConnectUser: element.contact.connect_uuid,
            };

            if (!!contact.isConnectUser) {
              shareToConnect.push(element.isConnectUser);
            }

            if (!!contact.formOfAddress) {
              greeting = contact.formOfAddress + ' ' + greeting;
            } else if (!!contact.full) {
              greeting = greeting + ' ' + contact.full;
            }
            return contact;
          });
        if (greeting !== '') {
          editorState = this.insertTextToEditorState('before', greeting, editorState);
          editorEmpty = false;
        }
      }

      let subject = this.props.editData?.subject || this.props.subject || '';
      if (!!subject.includes(this.props.serial)) {
        //eslint-disable-next-line
        let re = new RegExp(
          `\(\[\\\[\|\\(\\{\]ID#${this.props.serial}\[\\]\|\\)\\}\]\)\\s\*`,
          'gim',
        );
        subject = subject.replace(re, '');
      }

      this.setState({
        subject: subject,
        to: to,
        shareToConnect: shareToConnect,
        attachments: {},
        uploadTileExpanded: false,
        isSubmitting: false,
        hasError: false,
        editorState: editorState,
        editorFocus: false,
        editorEmpty: editorEmpty,
      });
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.isSubmitting) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }

    const dropzoneRef = createRef();
    const openDialog = () => {
      // Note that the ref is set async,
      // so it might be null at some point
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };

    return (
      <Grid component='form' key='ChatForm' onSubmit={this.handleFormSubmit} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            {!!this.props.editData
              ? TextDE.procedure.addEmailForm.titleResponse
              : TextDE.inbox.tabs.email}
          </Typography>
        </Grid>
        <Grid item xs={1} className={classes.labelContacts}>
          <Typography variant='button'>{TextDE.chatform.to.label}:</Typography>
        </Grid>
        <Grid item xs={11}>
          <Autocomplete
            id='contactselect'
            name='uuid'
            multiple
            options={this.state.searchResults}
            value={this.state.to || []}
            onChange={this.handleContactChange}
            onInputChange={this.handleContactSearch}
            disableClearable
            loading={this.state.searchIsLoading}
            loadingText={TextDE.search.label}
            noOptionsText={
              !!this.state.searchNotFound
                ? TextDE.chatform.to.noneFound
                : TextDE.search.errors.inputTooShort(3)
            }
            renderOption={(option) => {
              return (
                <ListItem component='div' ContainerComponent='div' key={option.uuid}>
                  <ListItemAvatar>
                    <Badge
                      color='primary'
                      badgeContent={option.pref ? <StarIcon className={classes.xs} /> : 0}
                      overlap='rectangular'
                      variant='standard'
                    >
                      <Avatar variant='rounded' className={classes.small}>
                        {option.work ? <WorkIcon /> : <AlternateEmailIcon />}
                      </Avatar>
                    </Badge>
                  </ListItemAvatar>
                  <ListItemText primary={option.full} />
                  <ListItemSecondaryAction>
                    {!!option.isConnectUser && (
                      <Tooltip title={TextDE.procedure.addEmailForm.isConnectUser}>
                        <SettingsEthernetIcon color='primary' />
                      </Tooltip>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label={TextDE.chatform.to.label}
                helperText={TextDE.chatform.to.helperText}
                variant='outlined'
                required={this.state.to.length < 1}
                error={this.state.to.length < 1}
              />
            )}
            filterOptions={(options, params) => {
              return options;
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === 'string') {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option.full;
            }}
          />
        </Grid>
        {this.props.contacts.length > 0 && (
          <>
            <Grid item xs={1} className={classes.labelChips}>
              <Typography variant='caption'>{TextDE.chatform.to.suggestions}:</Typography>
            </Grid>
            <Grid item xs={11} className={classes.marginBottom}>
              {this.props.contacts
                ?.filter((element) => !!element.contact.connect_uuid)
                .map((wrap, wrapIndex) => {
                  let fullName = `${wrap.contact.salutation?.name ?? ''} ${
                    wrap.contact.title?.name ?? ''
                  } ${wrap.contact.firstname ?? ''} ${wrap.contact.name}`.trim();
                  return (
                    <Chip
                      key={wrapIndex}
                      className={classes.contactChip}
                      onClick={() =>
                        this.pushContact({
                          uuid: wrap.contact.uuid,
                          inputValue: fullName,
                          full: fullName,
                          isConnectUser: wrap.contact.connect_uuid,
                        })
                      }
                      label={fullName}
                      color='primary'
                      avatar={
                        <Avatar>
                          <AlternateEmailIcon />
                        </Avatar>
                      }
                    />
                  );
                })}
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Editor
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName={clsx(classes.wrapper, {
              [classes.active]: this.state.editorFocus,
              [classes.required]: this.state.editorEmpty,
            })}
            editorClassName={classes.editor}
            toolbarClassName={classes.toolbar}
            onFocus={(event) => {
              this.setState({ editorFocus: true });
            }}
            onBlur={(event) => {
              let isEmpty = !this.state.editorState.getCurrentContent().hasText();
              this.setState({ editorFocus: false, editorEmpty: isEmpty });
            }}
            required
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'emoji', 'link'],
              inline: {
                options: ['bold', 'italic', 'underline'],
                bold: { className: 'bordered-option-classname' },
                italic: { className: 'bordered-option-classname' },
                underline: { className: 'bordered-option-classname' },
                strikethrough: {
                  className: 'bordered-option-classname',
                },
              },
              blockType: {
                options: ['Normal', 'H1'],
                className: 'bordered-option-classname',
              },
              fontSize: {
                options: [10, 12, 16, 20],
                className: 'bordered-option-classname',
              },
              fontFamily: {
                className: 'bordered-option-classname',
              },
              link: {
                className: 'bordered-option-classname',
              },
              emoji: {
                className: 'bordered-option-classname',
              },
            }}
            toolbarCustomButtons={[<CustomToolbarComponent onChange={this.onEditorStateChange} />]}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant='h5'>
            {TextDE.mailform.attachment.labelHelper(Object.keys(this.state.attachments).length)}
            <Button
              endIcon={!!this.state.uploadTileExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              variant='contained'
              color='primary'
              style={{ float: 'right' }}
              onClick={() =>
                this.setState({ uploadTileExpanded: !!!this.state.uploadTileExpanded })
              }
            >
              {!!this.state.uploadTileExpanded
                ? TextDE.mailform.attachment.expandedAttachFiles
                : TextDE.mailform.attachment.attachFilesButton}
            </Button>
          </Typography>
          <Typography variant='subtitle2'>
            <Hidden mdDown>{TextDE.mailform.attachment.helperTextBig}</Hidden>
            <Hidden lgUp>{TextDE.mailform.attachment.helperText}</Hidden>
          </Typography>
          {this.state.rejectedFiles.map((element, index) => (
            <Alert
              className={classes.mb}
              severity='error'
              key={'alert-' + index}
              onClose={() => {
                let tmp = this.state.rejectedFiles;
                tmp = tmp.filter((file) => file !== element);
                this.setState({
                  rejectedFiles: tmp,
                });
              }}
            >
              {element}
            </Alert>
          ))}

          {this.state.newFiles.length > 0 && (
            <Alert
              className={classes.mb}
              key='alert-loading'
              icon={<CircularProgress disableShrink size={20} />}
            >
              {TextDE.mailform.attachment.uploadingLabel(this.state.newFiles.length)}
            </Alert>
          )}

          <Dropzone
            ref={dropzoneRef}
            accept=''
            disabled={false}
            noClick={true}
            onDragLeave={this.handleOnDragLeave}
            onDragEnter={this.handleOnDragEnter}
            onDropRejected={this.handleOnDropRejected}
            onDrop={this.handleFileChange}
            maxFiles={5}
            maxSize={30000000}
            minSize={1}
            multiple={true}
            dropzoneText={TextDE.mailform.attachment.helperTextBig}
          >
            {({ getRootProps, getInputProps }) => (
              <div
                {...getRootProps({
                  className: this.state.filesDraggedIn ? classes.draggedBg : classes.nonDraggedBg,
                })}
              >
                <Collapse in={!!this.state.uploadTileExpanded} timeout='auto'>
                  <Grid container spacing={1}>
                    <input {...getInputProps()} />
                    <Grid key='file-list-upload-button' item className={classes.uploadMedia}>
                      <Button
                        variant='contained'
                        className={this.state.filesDraggedIn ? classes.dragged : classes.nonDragged}
                        color='primary'
                        onClick={openDialog}
                        style={{ height: '100%' }}
                        fullWidth
                      >
                        <CardContent component='center'>
                          <CloudUploadIcon fontSize='large' />
                          <Typography variant='h6' paragraph={true}>
                            {TextDE.mailform.attachment.helperTextBig}
                          </Typography>
                        </CardContent>
                      </Button>
                    </Grid>
                    {this.props.media
                      .sort((a, b) => a.id < b.id)
                      .map((item, index) => {
                        return (
                          <Grid key={'file-list-' + index} item className={classes.maxSized}>
                            <Card
                              raised
                              className={
                                !!this.state.attachments[item.id + '-' + item.model_id]
                                  ? classes.selectedItem
                                  : classes.item
                              }
                            >
                              <CardMedia
                                className={classes.media}
                                image={
                                  [
                                    'application/pdf',
                                    'image/jpeg',
                                    'image/gif',
                                    'image/png',
                                    'image/webp',
                                  ].includes(item.mime_type)
                                    ? process.env.REACT_APP_API_URL +
                                      switchModelType(item.model_type) +
                                      this.props.uuid +
                                      '/' +
                                      item.id +
                                      '/' +
                                      item.file_name +
                                      '?thumb&download'
                                    : '/assets/404.jpg'
                                }
                                title={item.file_name}
                                onClick={() => this.selectFile(index)}
                              />
                              <CardContent component='span'>
                                <Typography
                                  variant='subtitle1'
                                  component='span'
                                  display='inline'
                                  noWrap
                                  align='center'
                                >
                                  {item.file_name}
                                </Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                </Collapse>
              </div>
            )}
          </Dropzone>
        </Grid>

        <Grid
          item
          xs={4}
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}
        >
          <Button onClick={this.handleClose} variant='outlined' color='secondary'>
            {TextDE.close}
          </Button>
        </Grid>
        <Grid item xs={8}>
          {this.state.editorEmpty && (
            <Alert className={classes.mb} severity='error' key='alert-empty-mail'>
              {TextDE.procedure.addEmailForm.alerts.emptyMessage}
            </Alert>
          )}
          <Button
            type='submit'
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={this.state.isSubmitting}
            fullWidth
          >
            {TextDE.send}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ChatForm);
