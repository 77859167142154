import React from 'react';
import Popper from '@material-ui/core/Popper';
//import { ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';

import { withStyles } from '@material-ui/core/styles';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import FaceIcon from '@material-ui/icons/Face';
import Avatar from '@material-ui/core/Avatar';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import { DE as TextDE } from '../../lib/Text';
import { appIsLoading, logout as logoutCall } from '../../actions/Authentication';

import * as PushManager from '../../lib/enable-push';

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const styles = (theme) => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    width: '100%',
  },
  pos: {
    marginBottom: 12,
  },
  zIndex: {
    zIndex: '9000',
  },
  popper: {
    backgroundColor: theme.palette.background.default,
    zIndex: '9000',
    maxWidth: '95vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '33vw',
    },
    '& > div': {
      maxHeight: '300px',
      paddingTop: '4px',
      paddingBottom: '4px',
      overflow: 'auto',
      //border: '1px solid #000000',
    },
  },
  notification: {
    borderBottom: '1px solid #000000',
    '& > span': {
      display: 'flex',
    },
  },
  close: {
    position: 'absolute',
    left: '80%',
  },
  icon: {
    height: 'inherit',
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText,
  },
  iconbutton: {
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
});

class UserButton extends React.Component {
  toggleUser(event) {
    this.setState({ open: !!this.state.open ? null : event.target });
  }

  constructor() {
    super();
    this.state = { open: null, qoute: '' };
    this.toggleUser = this.toggleUser.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout = async () => {
    console.log('[LOGOUT]');
    this.props.dispatch(appIsLoading());
    let unsub = PushManager.unsubscribeUser(this.props.Authentication.access_token);
    console.log('[UNSUB]', unsub);
    await sleep(2000);

    // CALL API TO DESTROY TOKENS?
    let response = await this.props.dispatch(logoutCall(this.props.Authentication.access_token));
    if (response === false) {
      localStorage.clear();
    }
    window.location.href = '/login';
  };

  handleVerify = async () => {
    await fetch(process.env.REACT_APP_API_URL + '/api/users/' + this.props.User.uuid + '/verify', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        alert(TextDE.accountVerifyEmailWasResent);
      })
      .catch((error) => {
        return false;
      });
  };

  componentDidMount() {
    let qoutes = [
      '"Gibt es etwa eine bessere Motivation als den Erfolg?" - Ion Tiriac',
      '"Besessenheit ist der Motor" - Verbissenheit ist die Bremse." - Rudolf Gametowitsch Nurejew',
      '"Lust und Liebe sind die Fittiche zu großen Taten." - Johann Wolfgang von Goethe',
      '"Lust verkürzt den Weg." - William Shakespeare',
      '"Leben ist das, was passiert, während Du fleißig dabei bist, andere Pläne zu schmieden." - John Lennon',
      '"Die Sklaven von heute werden nicht mit Peitschen, sondern mit Terminkalendern angetrieben" - John Steinbeck',
      '"Die größten Schwierigkeiten liegen da, wo wir sie suchen." - Johann Wolfgang v. Goethe',
      '"Der stärkste Trieb in der menschlichen Natur ist der Wunsch, bedeutend zu sein." - John Dewey',
      '"In jedem Menschen steckt ein König. Sprich zu dem König, und er wird herauskommen." - Dt. Sprichwort',
      '"Man liebt das, wofür man sich müht, und man müht sich für das, was man liebt." - Erich Fromm',
      '"Kapital läßt sich beschaffen, Fabriken kann man bauen, Menschen muß man gewinnen." - Hans Christoph von Rohr',
      '"Ich will nicht nur an euern Verstand appellieren. Ich will eure Herzen gewinnen." - Mahatma Gandhi',
      '"Was wir am nötigsten brauchen, ist ein Mensch, der uns zwingt, das zu tun, was wir können." - Ralph Waldo Emerson',
      '"Wir warten unser Leben lang auf den außergewöhnlichen Menschen, statt die gewöhnlichen um uns her in solche zu verwandeln." - Hans Urs von Balthasar',
    ];

    this.setState({ qoute: qoutes[Math.floor(Math.random() * 14)] });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <IconButton
          id='user-titlebar-button'
          className={classes.iconbutton}
          aria-label='User'
          onClick={this.toggleUser}
        >
          <Badge
            color='secondary'
            overlap='rectangular'
            badgeContent={<PriorityHighIcon fontSize='inherit' color='primary' />}
            invisible={!this.props.User.verified ? false : true}
          >
            {!!this.props.User.avatar ? (
              <Avatar className={classes.icon} src={this.props.User.avatar} />
            ) : (
              <FaceIcon />
            )}
          </Badge>
        </IconButton>

        <Popper
          className={classes.zIndex}
          open={!!this.state.open}
          anchorEl={this.state.open}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Card className={classes.popper} variant='outlined'>
                <CardHeader
                  avatar={
                    !!this.props.User.avatar ? (
                      <Avatar className={classes.icon} src={this.props.User.avatar} />
                    ) : (
                      <FaceIcon />
                    )
                  }
                  action={
                    <IconButton onClick={this.toggleUser}>
                      <CloseIcon />
                    </IconButton>
                  }
                  title={
                    this.props.User.firstname +
                    (!!this.props.User.nickname ? ' "' + this.props.User.nickname + '" ' : ' ') +
                    this.props.User.lastname
                  }
                  subheader={this.props.User.customer.company?.name}
                />
                <CardContent>
                  <Typography variant='caption' color='textSecondary'>
                    {this.state.qoute}
                  </Typography>
                </CardContent>
                <CardContent>
                  {!this.props.User.verified ? (
                    <Alert severity='error'>
                      <AlertTitle> {TextDE.accountNotYetConfirmedAlert} </AlertTitle>
                      {TextDE.accountNotYetConfirmedText}
                      <Button
                        variant='outlined'
                        color='inherit'
                        fullWidth
                        onClick={this.handleVerify}
                      >
                        {TextDE.accountVerifyEmailResendButton}
                      </Button>
                    </Alert>
                  ) : null}
                </CardContent>
                <CardActions>
                  <Button
                    variant='outlined'
                    color='secondary'
                    onClick={this.handleLogout}
                    startIcon={<LogoutIcon />}
                    size='small'
                    fullWidth
                  >
                    {TextDE.logout}
                  </Button>
                  <Button
                    to='/settings'
                    onClick={() => this.props.dispatch(push('/settings'))}
                    variant='contained'
                    color='primary'
                    fullWidth
                    className={classes.button}
                  >
                    {TextDE.settings.settingsLabel}
                  </Button>
                </CardActions>
              </Card>
            </Fade>
          )}
        </Popper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserButton);
