import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import UpdateIcon from '@material-ui/icons/Update';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { readable } from '../../../lib/dateFunctions';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import RoomIcon from '@material-ui/icons/Room';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CreateIcon from '@material-ui/icons/Create';

import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  root: {
    margin: '0px 6% 8px 6%',
    padding: theme.spacing(1, 1),
    width: '100%',
    borderRadius: '14px',
    backgroundColor: '#ffffff',
  },
  avatar: {
    backgroundColor: 'red',
    color: 'white',
    minWidth: theme.spacing(7),
    minHeight: theme.spacing(7),
    '&>svg': {
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5),
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
  },
  noPad: {
    padding: '0px!important',
  },
  alignMiddle: {
    display: 'flex',
    alignItems: 'center',
    '& > b': {
      margin: theme.spacing(0, 2, 0, 1),
    },
    flexGrow: 1,
  },
  flexRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'end',
    '&>svg': {
      marginRight: '1em',
    },
    justifyContent: 'end',
  },
  header: {
    paddingBottom: 0,
  },
  expanded: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  content: {
    padding: theme.spacing(2),
  },
  inquiry: {
    padding: theme.spacing(2),
    whiteSpace: 'pre',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
});

class Competitive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      open: false,
      reason: '',
      isExternal: props.external != null,
      creator:
        props.external.creator != null
          ? typeof props.external.creator === typeof 't'
            ? JSON.parse(props.external.creator)
            : props.external.creator
          : false,
      contacts:
        props.external.contacts != null
          ? typeof props.external.contacts === typeof 't'
            ? JSON.parse(props.external.contacts)
            : props.external.contacts
          : false,
      addresses:
        props.external.addresses != null
          ? typeof props.external.addresses === typeof 't'
            ? JSON.parse(props.external.addresses)
            : props.external.addresses
          : false,
    };
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.handleRevokeClick = this.handleRevokeClick.bind(this);
    this.handleCollapse = this.handleCollapse.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleSendToServer(accept, reason = null) {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.props.uuid);

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'put', accept: accept, reason: reason }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.error(error);
        alert(error);
        return error;
      });
  }

  async handleAcceptClick() {
    var response = await this.handleSendToServer(true);
    if (response?.uuid != null) {
      this.props.callbackAcceptedInboxItem();
    }
  }

  async handleRevokeClick() {
    var response = await this.handleSendToServer(false, this.state.reason);
    if (response.status === 'deleted') {
      this.props.history.goBack();
    } else {
      console.error('Something Failed while delete', response);
      alert('Something Failed while delete');
    }
  }

  handleCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  handleChange(event, value) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Dialog
          open={this.state.open}
          onClose={this.handleCollapse}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            {TextDE.inbox.competitive.dialog.decline.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.inbox.competitive.dialog.decline.text}</DialogContentText>
            <TextField
              autoFocus
              id='reason'
              name='reason'
              label={TextDE.inbox.competitive.dialog.decline.label}
              type='text'
              fullWidth
              onChange={this.handleChange}
              value={this.state.reason}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCollapse} color='secondary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleRevokeClick} color='primary'>
              {TextDE.decline}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={false}
          fullWidth={false}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.onClose(event, reason);
            }
          }}
          open={!!this.props.open}
        >
          <CardHeader
            className={classes.header}
            disableTypography={true}
            avatar={
              <Avatar className={classes.avatar} alt='Competitive'>
                <NewReleasesIcon />{' '}
              </Avatar>
            }
            action={
              <Typography variant='overline' component='p' className={classes.flexRight}>
                <UpdateIcon fontSize='small' />
                {readable(this.props.created_at)}
              </Typography>
            }
          />
          {!this.state.loading ? (
            <CardContent className={classes.content}>
              <Typography display='block' variant='h5' component='p' paragraph={true}>
                Ihnen bleiben noch <b>00 H 27 Mins</b> um anzunehmen.
              </Typography>
              <Typography display='block' variant='body1' component='p' paragraph={true}>
                Hier handelt es sich um eine Anfrage die
                <b>
                  {this.props.competitors != null && this.props.competitors.length > 1
                    ? ' an mehrere Unternehmen (' + this.props.competitors.length + ') '
                    : ' nur an Sie '}
                </b>
                gesendet wurde. Bitte teilen Sie mit ob Sie diese Annehmen wollen!
              </Typography>
              {this.state.creator !== null && (
                <Grid container>
                  <Grid item xs={12} md={6}>
                    <Typography variant='h5'>Kontakte</Typography>
                    <List dense>
                      {!!this.state.creator && (
                        <ListItem key={'creator'}>
                          <ListItemIcon>
                            <CreateIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              this.state.creator?.firstname + ' ' + this.state.creator?.lastname
                            }
                            secondaryTypographyProps={{ component: 'div' }}
                            secondary={
                              <>
                                {!!this.state.creator.address && (
                                  <RoomIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.phone && (
                                  <PhoneIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.secondPhone && (
                                  <PhoneIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.email && (
                                  <AlternateEmailIcon style={{ color: 'green' }} />
                                )}
                                {!!this.state.creator.secondEmail && (
                                  <AlternateEmailIcon style={{ color: 'green' }} />
                                )}
                              </>
                            }
                          />
                        </ListItem>
                      )}
                      {Object.keys(this.state.contacts).map((value) => {
                        if (!!this.state.contacts[value]) {
                          return (
                            <ListItem key={'contact-' + value}>
                              <ListItemIcon>
                                <PermContactCalendarIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  this.state.contacts[value].firstname +
                                  ' ' +
                                  this.state.contacts[value].lastname
                                }
                                secondaryTypographyProps={{ component: 'div' }}
                                secondary={
                                  <>
                                    {!!this.state.contacts[value].address && (
                                      <RoomIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].phone && (
                                      <PhoneIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].secondPhone && (
                                      <PhoneIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].email && (
                                      <AlternateEmailIcon style={{ color: 'green' }} />
                                    )}
                                    {!!this.state.contacts[value].secondEmail && (
                                      <AlternateEmailIcon style={{ color: 'green' }} />
                                    )}
                                  </>
                                }
                              />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </List>
                  </Grid>
                  {!!this.state.addresses && (
                    <Grid item xs={12} md={6}>
                      <Typography variant='h5'>Adressen</Typography>
                      <List dense>
                        {Object.keys(this.state.addresses).map((value) => {
                          if (!!this.state.addresses[value]) {
                            return (
                              <ListItem key={'address-' + value}>
                                <ListItemIcon>
                                  {value !== 'Rechnungsadresse' ? <RoomIcon /> : <LocalAtmIcon />}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    this.state.addresses[value]['route'] +
                                      ' ' +
                                      this.state.addresses[value]['street_number'] ||
                                    this.state.addresses[value]['streetNumber'] +
                                      ', ' +
                                      this.state.addresses[value]['postal_code'] ||
                                    this.state.addresses[value]['postalCode'] +
                                      ' ' +
                                      this.state.addresses[value]['locality']
                                  }
                                  secondary={this.state.addresses[value]['type']}
                                />
                              </ListItem>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </List>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid container direction='row' justifyContent='space-around' alignItems='center'>
                <Grid item xs={4}>
                  <Button
                    variant='contained'
                    fullWidth
                    color='secondary'
                    onClick={this.handleCollapse}
                  >
                    Ablehnen
                  </Button>
                </Grid>
                <Grid item xs={5}>
                  <Button
                    variant='contained'
                    fullWidth
                    color='primary'
                    onClick={this.handleAcceptClick}
                  >
                    Annehmen
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          ) : !!this.state.isExternal && !this.state.loading ? (
            <CardContent className={classes.content}>
              <b>Gelöschte Anfrage!</b>
              <br />
              Bitte mit der ID: '{this.props.uuid}' beim Support melden!
            </CardContent>
          ) : (
            <CardContent className={classes.content}>
              {' '}
              <center>
                <CircularProgress style={{ marginTop: '4em' }} />
              </center>{' '}
            </CardContent>
          )}
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Competitive);
