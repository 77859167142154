import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateCompany } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },

  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pullUp: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(0, 1),
  },
});

class UserConfigForm extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: false, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('config', this.state.config);
    formData.append('method', this.state.method);

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/customers/serial', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response) {
      let tmp = this.props.User;
      tmp.customer.procedure_serial_template = response.template;
      tmp.customer.procedure_serial_last = response.last;
      this.props.dispatch(updateCompany(tmp.customer));
      this.setState({ isSubmitting: false, hasError: false });
      //this.props.handleClose()
    } else {
      this.setState({ isSubmitting: false, hasError: true });
    }

    this.setState({ isSubmitting: false, hasError: false });
  }

  handleChange = (event) => {
    let i = 1;
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (event.target.type === 'checkbox') {
      let config = null;
      let example = '';
      switch (event.target.value) {
        case 'random':
          config = '8';
          for (i = 1; i <= config; i++) {
            example = example.concat(Math.floor(Math.random() * 10));
          }
          break;
        case 'date':
          config = '2';
          example = this.state.dateFormats[config];
          break;
        case 'counter':
          config = '3,1';
          example = '001';
          break;
        default:
          config = '2';
          example = this.state.dateFormats[config];
          break;
      }
      this.setState({
        [event.target.name]: event.target.value,
        config: config,
        example: example,
      });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      let example = '';
      if (event.target.name === 'config') {
        switch (this.state.method) {
          case 'random':
            for (i = 1; i <= event.target.value; i++) {
              example = example.concat(Math.floor(Math.random() * 10));
            }
            break;
          case 'date':
            example = this.state.dateFormats[event.target.value];
            break;
          case 'counter':
            break;
          default:
            example = this.state.dateFormats[event.target.value];
            break;
        }
        this.setState({
          [event.target.name]: event.target.value,
          example: example,
        });
      }
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  handleCounter = (event) => {
    let i = '';
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    let example = '';
    if (event.target.name === 'counterStart') {
      example = event.target.value;
      for (i = String(event.target.value).length; i < this.state.counterLength; i++) {
        example = '0'.concat(example);
      }
      this.setState({
        [event.target.name]: event.target.value,
        config: `${this.state.counterLength},${event.target.value}`,
        example: example,
      });
    }
    if (event.target.name === 'counterLength') {
      example = this.state.counterStart;
      for (i = String(this.state.counterStart).length; i < event.target.value; i++) {
        example = '0'.concat(example);
      }
      this.setState({
        [event.target.name]: event.target.value,
        config: `${event.target.value},${this.state.counterStart}`,
        example: example,
      });
    }
  };

  handleSliderChange = (event, newValue) => {
    let example = '';
    for (var i = 1; i <= newValue; i++) {
      example = example.concat(Math.floor(Math.random() * 10));
    }
    this.setState({ example: example, config: newValue });
  };

  valuetext(value) {
    return `${value} Stellen`;
  }

  constructor(props) {
    super(props);
    const date = new Date(Date.now());

    let H = date.getHours();
    H = String(H).length === 1 ? `0${H}` : H;
    let i = date.getMinutes();
    i = String(i).length === 1 ? `0${i}` : i;
    let s = date.getSeconds();
    s = String(s).length === 1 ? `0${s}` : s;

    let Y = date.getFullYear();
    let y = String(Y).substring(String(Y).length - 2);
    let m = date.getMonth();
    m = String(m).length === 1 ? `0${m}` : m;
    let d = date.getDate();
    d = String(d).length === 1 ? `0${d}` : d;

    const formats = {
      0: `${d}.${m}.${Y}	${H}:${i}:${s}`,
      1: `${d}${m}${y}${H}${i}${s}`,
      2: `${y}${m}${d}-271`,
    };

    let re = String(this.props.User.customer.procedure_serial_template).match(/%[A-Z]+[,\d+]*%/m);
    re = String(re[0])
      .substring(1, String(re[0]).length - 1)
      .toLowerCase();
    let config = re.split(',');

    let example = '';
    switch (config[0]) {
      case 'random':
        for (var u = 1; u <= config[1]; u++) {
          example = example.concat(Math.floor(Math.random() * 10));
        }
        break;
      case 'date':
        example = formats[config[1]];
        break;
      case 'counter':
        example = this.props.User.customer.procedure_serial_last.COUNTER;
        for (var z = String(example).length; z < config[1]; z++) {
          example = '0'.concat(example);
        }
        break;
      default:
        example = formats[config[1]];
        break;
    }

    this.state = {
      counterStart: 1,
      counterLength: 3,
      config: config[1],
      method: config[0],
      dateFormats: formats,
      example: example,
      isSubmitting: false,
      hasError: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.valuetext = this.valuetext.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  /*
	componentDidMount() {
		fetch(process.env.REACT_APP_API_URL + '/api/customers',
		{
			method: "GET",
			headers: {
				Authorization: `Bearer ${this.props.Authentication.access_token}`
			},
		})	
		.then( response => response.json() )
		.then( response => {
			let re = String(response.procedure_serial_template).match(/%[A-Z]+[,\d+]*%/m);
			re = String(re[0]).substring(1, String(re[0]).length - 1).toLowerCase();
			let config = re.split(",");
			if (config[0] === "counter") {
				this.setState({config: response.procedure_serial_last.COUNTER +','+config[1], method: config[0], counterStart: response.procedure_serial_last.COUNTER, counterLength: config[1]});
			} else {
				this.setState({config: config[1], method: config[0]});
			}
		})
	}
	*/

  render() {
    const { classes } = this.props;

    return (
      <>
        <Paper className={classes.root} square>
          <form key='configureProcedure' onSubmit={this.handleFormSubmit}>
            <Typography variant='h5'>
              Vorgangsbezeichnung&nbsp;-&nbsp;
              <Chip
                label={'ID# ' + this.state.example}
                color='primary'
                size='small'
                style={{ fontWeight: 'bold', fontSize: '80%' }}
              />
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formLabel}
                  onClick={this.handleChange}
                  control={
                    <Checkbox
                      checked={this.state.method === 'random'}
                      color='secondary'
                      name='method'
                      value='random'
                    />
                  }
                  label={
                    <Typography variant='body1'>
                      Soll eine Zufällige Zahl als eindeutige Kennung generiert werden?{' '}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                {this.state.method === 'random' && (
                  <>
                    <Typography
                      variant='subtitle1'
                      paragraph={true}
                      component='div'
                      gutterBottom
                      display='inline'
                      align='center'
                    >
                      Die Kennung soll &nbsp;
                      <TextField
                        name='config'
                        type='number'
                        className={classes.pullUp}
                        onChange={this.handleChange}
                        value={this.state.config}
                        margin='normal'
                        inputProps={{ min: '6', max: '14' }}
                      />
                      Stellen haben (Beispiel: {this.state.example})
                    </Typography>
                    {/* <Slider
											value={this.state.config}
											getAriaValueText={this.valuetext}
											onChangeCommitted={this.handleSliderChange}
											min={6}
											step={1}
											max={14}
											marks
											valueLabelDisplay="auto"
										/> */}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formLabel}
                  onClick={this.handleChange}
                  control={
                    <Checkbox
                      checked={this.state.method === 'date'}
                      color='secondary'
                      name='method'
                      value='date'
                    />
                  }
                  label={
                    <Typography variant='body1'>
                      Soll das Datum als Grundlage der eindeutigen Kennung genutzt werden?{' '}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                {this.state.method === 'date' && (
                  <RadioGroup name='config' value={this.state.config} onChange={this.handleChange}>
                    <FormControlLabel
                      value='0'
                      control={<Radio />}
                      label={
                        'Klassisches Datumsformat - Beispiel: [' + this.state.dateFormats[0] + ']'
                      }
                    />
                    <FormControlLabel
                      value='1'
                      control={<Radio />}
                      label={
                        'Zusammen, ohne Trennung - Beispiel: [ID#' + this.state.dateFormats[1] + ']'
                      }
                    />
                    <FormControlLabel
                      value='2'
                      control={<Radio />}
                      label={
                        'Kurz, und angehängter täglichem Zähler - Beispiel: [ID#' +
                        this.state.dateFormats[2] +
                        ']'
                      }
                    />
                  </RadioGroup>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FormControlLabel
                  className={classes.formLabel}
                  onClick={this.handleChange}
                  control={
                    <Checkbox
                      checked={this.state.method === 'counter'}
                      color='secondary'
                      name='method'
                      value='counter'
                    />
                  }
                  label={
                    <Typography variant='body1'>
                      Soll eine fortlaufende Zahl als eindeutige Kennung generiert werden?{' '}
                    </Typography>
                  }
                />
              </Grid>

              <Grid item xs={12}>
                {this.state.method === 'counter' && (
                  <Typography
                    variant='subtitle1'
                    paragraph={true}
                    gutterBottom
                    display='inline'
                    align='center'
                    component='div'
                  >
                    Die Kennung soll bei &nbsp;
                    <TextField
                      name='counterStart'
                      type='number'
                      className={classes.pullUp}
                      onChange={this.handleCounter}
                      value={this.state.counterStart}
                      margin='normal'
                      inputProps={{ min: '1' }}
                    />
                    starten und mindestens
                    <TextField
                      name='counterLength'
                      type='number'
                      className={classes.pullUp}
                      onChange={this.handleCounter}
                      value={this.state.counterLength}
                      margin='normal'
                      inputProps={{ min: '3' }}
                    />
                    Stellen haben (Beispiel: {this.state.example})
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                {this.state.hasError ? (
                  <span className='error'>Fehler beim Aktualisieren der Daten...</span>
                ) : (
                  ''
                )}
                <div className={classes.wrapper}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={this.state.isSubmitting}
                    fullWidth
                  >
                    Speichern
                  </Button>
                  {this.state.isSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserConfigForm);
