import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import { Constants } from '../../../lib/Constants';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
//import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DE as TextDE } from '../../../lib/Text';
import CustomToolbarComponent from '../components/CustomToolbarComponent';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

const styles = (theme) => ({
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  floatRight: {
    float: 'right',
  },
  active: {
    borderColor: theme.palette.primary.main + '!important',
    borderWidth: '2px!important',
    //'&>div': {},
  },
  required: {
    borderColor: theme.palette.error.main + '!important',
    borderWidth: '1px!important',
    //'&>div': {},
  },
  wrapper: {
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.action.disabled,
    borderWidth: '1px',
  },
  editor: {
    height: '232px',
    '&>div': {
      height: '216px',
      padding: theme.spacing(1, 1),
    },
    [theme.breakpoints.up('lg')]: {
      height: '432px',
      '&>div': {
        height: '400px',
        padding: theme.spacing(1, 2),
      },
    },
  },
  toolbar: {
    backgroundColor: theme.palette.grey['400'],
    paddingBottom: theme.spacing(1),
    marginBottom: 0,
    '&>div': {
      marginBottom: 0,
    },
  },
  labelSubject: {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'start',
  },
  customLabelInput: {
    padding: '4px 8px 8px 8px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  typeWrap: {
    width: '20%',
    marginTop: '-1px',
  },
  divider: {
    margin: theme.spacing(0, 1),
    height: 'inherit',
  },
});

class NoteForm extends React.Component {
  constructor(props) {
    super(props);

    let editorState = null;
    let noteType = 'note';

    if (!!props.editData?.type && props.editData?.type !== false) {
      noteType = props.editData?.type.name;
    }

    if (!!props.editData?.body) {
      let blocksFromHTML = convertFromHTML(
        props.editData?.body?.html || props.editData?.body?.text || props.editData?.body,
      );
      editorState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      editorState = EditorState.createWithContent(editorState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      subject: props.editData?.subject || '',
      isSubmitting: false,
      hasError: false,
      editorState: editorState,
      editorFocus: false,
      editorEmpty: !editorState.getCurrentContent().hasText(),
      editUUID: props.editData?.uuid || false,
      noteType: noteType,
    };

    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  handleTypeChange = (event) => {
    let subject = this.state.subject;
    //console.log('Subject is: ', subject, !!subject, subject === '');
    if (!!subject === false || subject === '') {
      subject = Constants.getInboxItemType(event.target.value).description ?? '';
      //console.log('Changfe subject to: ', event.target.value, Constants.getInboxItemType(event.target.value).description, Constants.getInboxItemType(event.target.value).description ?? '');
    }
    this.setState({ noteType: event.target.value, subject: subject });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      let subject = this.state.subject.trim();
      let bodySubject = this.state.editorState.getCurrentContent().getFirstBlock().getText().trim();

      if (subject.trim() === '' || bodySubject.startsWith(subject)) {
        bodySubject = bodySubject.length <= 50 ? bodySubject : bodySubject.substr(0, 47) + '...';
        this.setState({ subject: bodySubject });
      }
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleClose = () => {
    this.setState({
      subject: '',
      noteType: 'note',
      isSubmitting: false,
      hasError: false,
      editorState: EditorState.createEmpty(),
      editorFocus: false,
      editorEmpty: true,
      editUUID: false,
    });
    this.props.handleClose();
  };

  async handleFormSubmit(event) {
    event.preventDefault();
    if (!this.state.editorState.getCurrentContent().hasText()) {
      this.setState({
        isSubmitting: false,
        hasError: { message: 'Bitte eine Notiz eintragen!' },
      });
      return false;
    }

    let body = stateToHTML(this.state.editorState.getCurrentContent());
    let subject = this.state.subject;

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'PUT');
    let noteType = Constants.getInboxItemTypeId('note');
    if (!!this.state.noteType && this.state.noteType !== '') {
      noteType = Constants.getInboxItemTypeId(this.state.noteType);
    }
    formData.append('type_id', noteType);
    formData.append('subject', subject);
    formData.append('body', body);
    if (!!this.state.editUUID) {
      formData.append('itemuuid', this.state.editUUID);
    }

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid + '/item',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (response === 'Created') {
      this.setState(
        {
          subject: '',
          noteType: 'note',
          editorState: EditorState.createEmpty(),
          isSubmitting: false,
          hasError: false,
        },
        () => this.props.callback(),
      );
    } else {
      this.setState({ isSubmitting: false, hasError: response });
    }
  }

  componentDidUpdate(oldProps, oldState) {
    //console.log("componentDidUpdate - NoteForm");
    //console.log("componentDidUpdate - Old vs New props-", oldProps, this.props);
    //console.log("componentDidUpdate - Old vs New state-", oldState, this.state);
    if (oldProps?.match?.params?.uuid !== this.props?.match?.params?.uuid) {
      this.setState({
        subject: '',
        isSubmitting: false,
        hasError: false,
        editorState: EditorState.createEmpty(),
        editorFocus: false,
        editorEmpty: true,
        editUUID: false,
        noteType: 'note',
      });
    } else {
      if (Boolean(oldProps.editData) === false && Boolean(this.props.editData) !== false) {
        let editorState = null;
        let noteType = 'note';

        if (!!this.props.editData?.type && this.props.editData?.type !== false) {
          noteType = this.props.editData.type.name;
        }

        if (!!this.props.editData?.body) {
          let blocksFromHTML = convertFromHTML(
            this.props.editData?.body?.html ||
              this.props.editData?.body?.text ||
              this.props.editData?.body,
          );
          editorState = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
          );
          editorState = EditorState.createWithContent(editorState);
        } else {
          editorState = EditorState.createEmpty();
        }

        this.setState({
          subject: this.props.editData?.subject || '',
          isSubmitting: false,
          noteType: noteType,
          hasError: false,
          editorState: editorState,
          editorFocus: false,
          editUUID: this.props.editData?.uuid || false,
        });
      }
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid component='form' key='NoteForm' onSubmit={this.handleFormSubmit} container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h4'>
            {!!this.props.editData ? TextDE.inbox.tabs.noteEdit : TextDE.inbox.tabs.note}
          </Typography>
        </Grid>

        <Grid item xs={1} className={classes.labelSubject}>
          <Typography variant='caption' style={{ marginBottom: 'auto', marginTop: 'auto' }}>
            {TextDE.mailform.subject.label}:
          </Typography>
        </Grid>

        <Grid item xs={11}>
          <Paper variant='outlined' className={classes.customLabelInput}>
            <FormControl variant='standard' className={classes.typeWrap}>
              <InputLabel htmlFor='type-select'>Typ</InputLabel>

              <Select
                id='type-select'
                fullWidth
                value={this.state.noteType}
                onChange={this.handleTypeChange}
                disabled={!Constants.getInboxItemType(this.state.noteType).canBeSelected}
              >
                {Constants.inboxItemTypes
                  /*.filter((value) => value.canBeSelected === true)*/
                  .map((filter) => (
                    <MenuItem
                      key={'filter-id-' + filter.type}
                      value={filter.type}
                      style={{ display: !filter.canBeSelected ? 'none' : 'block' }}
                    >
                      {filter.icon} &nbsp; {filter.description}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <Divider className={classes.divider} orientation='vertical' />

            <TextField
              fullWidth
              name='subject'
              InputLabelProps={{ shrink: true }}
              label={TextDE.form.subject.label}
              autoComplete='subject'
              value={this.state.subject}
              onChange={this.handleChange}
              variant='standard'
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Editor
            editorState={this.state.editorState}
            onEditorStateChange={this.onEditorStateChange}
            wrapperClassName={clsx(classes.wrapper, {
              [classes.active]: this.state.editorFocus,
              [classes.required]: this.state.editorEmpty,
            })}
            editorClassName={classes.editor}
            toolbarClassName={classes.toolbar}
            onFocus={(event) => {
              this.setState({ editorFocus: true });
            }}
            onBlur={(event) => {
              let isEmpty = !this.state.editorState.getCurrentContent().hasText();
              this.setState({ editorFocus: false, editorEmpty: isEmpty });
            }}
            autoFocus
            required
            toolbar={{
              options: ['inline', 'blockType', 'fontSize', 'emoji', 'link'],
              inline: {
                options: ['bold', 'italic', 'underline'],
                bold: { className: 'bordered-option-classname' },
                italic: { className: 'bordered-option-classname' },
                underline: { className: 'bordered-option-classname' },
                strikethrough: {
                  className: 'bordered-option-classname',
                },
              },
              blockType: {
                options: ['Normal', 'H1'],
                className: 'bordered-option-classname',
              },
              fontSize: {
                options: [10, 12, 16, 20],
                className: 'bordered-option-classname',
              },
              fontFamily: {
                className: 'bordered-option-classname',
              },
              link: {
                className: 'bordered-option-classname',
              },
              emoji: {
                className: 'bordered-option-classname',
              },
            }}
            toolbarCustomButtons={[<CustomToolbarComponent onChange={this.onEditorStateChange} />]}
          />
        </Grid>

        <Grid
          item
          xs={4}
          style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-end' }}
        >
          <Button onClick={this.handleClose} variant='outlined' color='secondary' fullWidth>
            {TextDE.close}
          </Button>
        </Grid>

        <Grid item xs={8}>
          {!!this.state.hasError && (
            <Alert severity='error'>
              {TextDE.procedure.addNoteForm.alerts.couldNotBeSaved}:&nbsp;
              {this.state.hasError.message}{' '}
            </Alert>
          )}

          {this.state.isSubmitting ? (
            <CircularProgress size={24} className={classes.buttonProgress} />
          ) : (
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.submit}
              disabled={this.state.isSubmitting}
              fullWidth
            >
              {TextDE.save}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NoteForm);
