import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
//import Card from '@material-ui/core/Card';
//import CardHeader from '@material-ui/core/CardHeader';
//import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
//import Paper from '@material-ui/core/Paper';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
//import MaterialTable from 'material-table';
import MaterialTable from '@material-table/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
//import IconButton from '@material-ui/core/IconButton';
import WorkIcon from '@material-ui/icons/Work';
//import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
//import Badge from '@material-ui/core/Badge';
import Fab from '@material-ui/core/Fab';
//import List from '@material-ui/core/List';
//import ListItem from '@material-ui/core/ListItem';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
//import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
//import PhoneIcon from '@material-ui/icons/Phone';
//import StarIcon from '@material-ui/icons/Star';
//import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
//import HttpIcon from '@material-ui/icons/Http';
//import LocationOnIcon from '@material-ui/icons/LocationOn';
//import UpdateIcon from '@material-ui/icons/Update';
//import Link from '@material-ui/core/Link';
import updateURLParameter from '../../lib/updateURLParameter';
import Dialog from '@material-ui/core/Dialog';
import AddContact from './AddContact';
import ContactDetail from './ContactDetail';

function SimpleDialog(props) {
  const { companys, data, open, handleclosefunction } = props;
  return (
    <Dialog
      fullWidth
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleclosefunction();
        }
      }}
      aria-labelledby='simple-dialog-title'
      open={open}
    >
      <AddContact companys={companys} data={data} handleclosefunction={handleclosefunction} />
    </Dialog>
  );
}

const styles = (theme) => ({
  contactItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: theme.spacing(5),
    height: theme.spacing(5),
    '&>svg': {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
  },
  autosize: {
    margin: '4px 4px 0 4px',
    height: 'calc( 100% - 4px)',
    width: 'calc( 100% - 8px)',
    [theme.breakpoints.down('md')]: {},
    [theme.breakpoints.up('md')]: {},
  },
  height: {
    height: '100%',
  },
  display: {
    display: 'block',
  },
  hidden: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  fab: {
    zIndex: 800,
    position: 'fixed',
    [theme.breakpoints.down('md')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
});

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      config: {
        filtering: false,
        grouping: false,
      },
      params: {
        filter: !!this.props.Router.location.query.filter
          ? this.props.Router.location.query.filter
          : 'none',
        order: !!this.props.Router.location.query.order
          ? this.props.Router.location.query.order
          : 'new',
        search: !!this.props.Router.location.query.search
          ? this.props.Router.location.query.search
          : '',
      },
      isFetching: true,
      hasError: false,
      selected: null,
      data: null,
      dialogOpen: false,
      contacts: [],
      companys: [],
      mailDialogOpen: false,
      to: [],
      message: {},
    };

    this.tableRef = React.createRef();
    this.closeDetail = this.closeDetail.bind(this);
    this.deleteCallback = this.deleteCallback.bind(this);
    this.deleteItemCallback = this.deleteItemCallback.bind(this);
    this.addItemCallback = this.addItemCallback.bind(this);
  }
  addItemCallback(contact) {
    //console.log("callback", contact)
    //console.log(this.state.selected);
    this.setState({ selected: null });
    this.getContacts();
  }
  deleteItemCallback(contact) {
    //console.log("callback", contact)
    this.setState({ selected: null });
    this.getContacts();
  }

  deleteCallback(contact) {
    let contacts = this.state.contacts;
    let index = contacts.findIndex((x) => x.id === contact.id && x.uuid === contact.uuid);
    if (index >= 0) {
      window.history.replaceState('', '', updateURLParameter(window.location.href, 'uuid', ''));
      contacts.splice([index], 1);
      let companys = contacts.filter((contact) => contact.salutation_id === 4);
      companys.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      this.setState({ ...this.state, contacts: contacts, companys: companys, selected: null });
    }
  }

  closeDetail() {
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'uuid', ''));
    this.setState({ selected: null });
  }

  handleDialogClose = (uuid) => {
    if (!!uuid && typeof uuid === 'string') {
      window.history.replaceState('', '', updateURLParameter(window.location.href, 'uuid', uuid));
      this.getContacts();
    }
    this.setState({ ...this.state, dialogOpen: false });
  };

  async getContacts() {
    let params = new URL(document.location).searchParams;
    let uuid = params.get('uuid');

    fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let selected = false;
        json.forEach((value, key) => {
          if (typeof value.address?.id !== 'undefined') {
            //address: {id: 1, street_number: "16", route: "Am Lusbühl", postal_code: "79110", city_id: 1, city: { id: 1, locality: "Freiburg", state: "Baden-Württemberg", country: "Deutschland" }}
            value.tableAddress =
              value.address.route +
              ' ' +
              value.address.street_number +
              ', ' +
              value.address.postal_code +
              ' ' +
              value.address.city.locality;
          }
          if (value.emails.length > 0) {
            // emails: [{id: 1,contact_id: 1, work: true, pref: false, email: "test@mail.de"}]
            value.tableEmails = [];
            value.emails.forEach((email) => {
              value.tableEmails.push(email.email);
            });
            value.tableEmails = value.tableEmails.join(' ');
          }
          if (value.phones.length > 0) {
            // phones: [{id: 1, contact_id: 1, work: false, pref: false, fax: false, cell: true, number: "0151 123 955 81"}]
            value.tablePhoneNumbers = [];
            value.phones.forEach((phone_number) => {
              value.tablePhoneNumbers.push(phone_number.number);
            });
            value.tablePhoneNumbers = value.tablePhoneNumbers.join(' ');
          }
          if (typeof value.tags !== 'undefined') {
            value.tableTags = [];
            value.tags.forEach((tag) => {
              value.tableTags.push(tag.name.de);
            });
            value.tableTags = value.tableTags.join(' ');
          }
          if (!!value.webpages) {
            //typeof value.webpages !== "undefined"
            // webpages: { "1": "http://www.rndmiz.de" },
            value.tableWebpages = Object.values(value.webpages).join(' ');
          }
          if (typeof uuid !== 'undefined' && uuid === value.uuid) {
            //console.log("Found uuid at index:" + prop)
            selected = value;
          }
        });
        //console.log(json[selected])
        let companys = json.filter((contact) => contact.salutation_id === 4);
        companys.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));

        this.setState({
          isFetching: false,
          contacts: json,
          companys: companys,
          selected: !!selected ? selected : null,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ isFetching: false, hasError: error });
        return false;
      });
  }

  componentDidMount() {
    this.getContacts();
  }

  saveFunc = (data) => {
    this.setState({ ...this.state, data: data, dialogOpen: true });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <SimpleDialog
          data={this.state.data}
          companys={this.state.companys}
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
          handleclosefunction={this.handleDialogClose}
        />
        <Fab
          onClick={() => this.saveFunc({})}
          className={classes.fab}
          color='primary'
          aria-label='edit'
          label='Kontakt hinzufügen'
        >
          <AddIcon />
        </Fab>

        <div
          style={{
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'block',
          }}
        >
          <Grid container spacing={1} className={classes.autosize}>
            <Grid
              item
              xs={12}
              md={6}
              lg={4}
              className={
                !!this.state.selected && !!this.state.selected.uuid
                  ? classes.hidden
                  : classes.display
              }
            >
              <MaterialTable
                title={
                  this.props.Dimensions.maxWidth >= this.props.Theme.breakpoints.values.sm
                    ? 'Kontakte'
                    : null
                }
                options={{
                  maxBodyHeight: 'calc(100vh - 142px)',
                  tableLayout: 'auto',
                  actions: false,
                  search: true,
                  searchText: this.state.params.search,
                  debounceInterval: 500,
                  filtering: false,
                  grouping: false,
                  draggable: false,
                  paging: false,
                  sorting: true,
                  emptyRowsWhenPaging: false,
                  toolbarButtonAlignment: 'left',
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      this.state.selected &&
                      this.state.selected.tableData?.id === rowData.tableData?.id
                        ? '#EEE'
                        : '#FFF',
                  }),
                }}
                localization={{
                  grouping: {
                    placeholder: 'Kopfzeilen hier her ziehen...',
                  },
                  pagination: {
                    labelRowsSelect: 'Zeilen',
                    labelDisplayedRows: '{from}-{to} von {count}',
                    labelRowsPerPage: 'Zeilen pro Seite:',
                    firstAriaLabel: 'Erste Seite',
                    firstTooltip: 'Erste Seite',
                    previousAriaLabel: 'Vorherige Seite',
                    previousTooltip: 'Vorherige Seite',
                    nextAriaLabel: 'Nächste Seite',
                    nextTooltip: 'Nächste Seite',
                    lastAriaLabel: 'Letzte Seite',
                    lastTooltip: 'Letzte Seite',
                  },
                  toolbar: {
                    searchPlaceholder: 'Suche...',
                  },
                  header: {
                    actions: 'Funktionen',
                  },
                  body: {
                    emptyDataSourceMessage: this.state.hasError || 'Keine Kontakte angelegt',
                    filterRow: {
                      filterTooltip: 'nach ??? filtern',
                    },
                  },
                }}
                icons={{
                  Search: SearchIcon,
                  Clear: ClearIcon,
                  Add: AddIcon,
                  Check: CheckIcon,
                  Delete: DeleteOutlineIcon,
                  DetailPanel: ChevronRightIcon,
                  Edit: EditIcon,
                  Export: SaveAltIcon,
                  Filter: FilterListIcon,
                  FirstPage: FirstPageIcon,
                  LastPage: LastPageIcon,
                  NextPage: ChevronRightIcon,
                  PreviousPage: ChevronLeftIcon,
                  ResetSearch: ClearIcon,
                  SortArrow: ArrowUpwardIcon,
                  ThirdStateCheck: RemoveIcon,
                  ViewColumn: ViewColumnIcon,
                }}
                columns={[
                  {
                    title: 'Name',
                    field: 'name',
                    hidden: false,
                    searchable: false,
                    cellStyle: { borderBottomWidth: '3px' },
                    sorting: true,
                    defaultSort: 'asc',
                    customSort: (a, b) =>
                      a.name.trim().toLowerCase() > b.name.trim().toLowerCase()
                        ? 1
                        : b.name.trim().toLowerCase() > a.name.trim().toLowerCase()
                          ? -1
                          : 0,
                    render: (rowData) =>
                      rowData.uuid !== null ? (
                        <div className={classes.contactItem}>
                          <Avatar aria-label='recipe' className={classes.avatar}>
                            {rowData.salutation_id === 4 ? <WorkIcon /> : <PersonOutlineIcon />}
                          </Avatar>
                          <Typography variant='h5' component='span'>
                            {rowData.salutation?.name} {rowData.title?.name} {rowData.firstname}{' '}
                            {rowData.name}
                            {!!rowData.company?.name ? (
                              <>
                                <br />{' '}
                                <Typography variant='subtitle2' component='span'>
                                  {rowData.company.name}
                                </Typography>
                              </>
                            ) : null}
                          </Typography>
                        </div>
                      ) : null,
                  },
                  {
                    title: 'Anrede',
                    field: 'salutation.name',
                    hidden: true,
                    sorting: false,
                    searchable: false,
                  },
                  {
                    title: 'Vorname',
                    field: 'firstname',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: 'Nachname',
                    field: 'name',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                  {
                    hidden: true,
                    sorting: false,
                    title: 'Firma',
                    field: 'company.name',
                    searchable: true,
                    headerStyle: { textAlign: 'right', paddingRight: 0 },
                    cellStyle: { textAlign: 'right' },
                  },
                  {
                    title: 'Titel',
                    field: 'title.name',
                    hidden: true,
                    sorting: false,
                    searchable: false,
                  },
                  {
                    title: 'Email',
                    field: 'tableEmails',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: 'Addresse',
                    field: 'tableAddress',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: 'Telefon',
                    field: 'tablePhoneNumbers',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: 'Webseiten',
                    field: 'tableWebpages',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                  {
                    title: 'Tags',
                    field: 'tableTags',
                    hidden: true,
                    sorting: false,
                    searchable: true,
                  },
                ]}
                tableRef={this.tableRef}
                data={this.state.contacts}
                isLoading={!!this.state.isFetching}
                onRowClick={(event, rowData) => {
                  this.setState({ selected: rowData });
                  window.history.replaceState(
                    '',
                    '',
                    updateURLParameter(window.location.href, 'uuid', rowData.uuid),
                  );
                }}
              />
            </Grid>

            {!!this.state.selected && !!this.state.selected.uuid ? (
              <Grid
                item
                xs={12}
                md={6}
                lg={8}
                className={
                  !!this.state.selected && !!this.state.selected.uuid
                    ? classes.display
                    : classes.hidden
                }
              >
                <ContactDetail
                  contact={this.state.selected}
                  close={this.closeDetail}
                  deleteCallback={this.deleteCallback}
                  deleteItemCallback={this.deleteItemCallback}
                  addItemCallback={this.addItemCallback}
                />
              </Grid>
            ) : null}
          </Grid>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Contact);
