import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import { updateUserEmailVerified } from '../../actions/User';
import { readable } from '../../lib/dateFunctions';
import { DE as TextDE } from '../../lib/Text';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',
    height: 'inherit',
    overflowY: 'auto',
  },
});

class VerificationBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verified: !!this.props.User.verified,
      loading: false,
      error: false,
      validation: false,
      base64: this.props.match.params.base64 ?? false,
      url: atob(this.props.match.params.base64 ?? ''),
      checked: !!this.props.User.verified ? true : false,
    };
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.resendVerify = this.resendVerify.bind(this);
    this.verify = this.verify.bind(this);
    this.getPDF = this.getPDF.bind(this);
  }

  componentDidMount() {}

  handleCheckbox() {
    this.setState({ checked: !this.state.checked });
  }

  getPDF() {
    let endpoint = process.env.REACT_APP_API_URL + '/api/email/verify/av';
    fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', 'AVV.pdf');
        a.click();
      });
    });
  }

  verify = async () => {
    this.setState({ loading: true });
    let response = await fetch(this.state.url, {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });
    if (!!response.success) {
      this.setState({ loading: false, error: false, validation: response });
      this.props.dispatch(updateUserEmailVerified({ verified: response.success }));
    } else {
      console.log(response, !!response.message, response.message === 'Invalid signature.');
      if (!!response.message && response.message === 'Invalid signature.') {
        this.resendVerify(false);
        this.setState({
          loading: false,
          validation: false,
          error: TextDE.accountVerifyEmailTooOld,
        });
        return;
      }
      this.setState({
        loading: false,
        validation: false,
        error: !!response.message ? response.message : response,
      });
    }
  };

  resendVerify = async (showAlert = true) => {
    await fetch(process.env.REACT_APP_API_URL + '/api/users/' + this.props.User.uuid + '/verify', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        if (showAlert) {
          alert(TextDE.accountVerifyEmailWasResent);
        }
      })
      .catch((error) => {
        return false;
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Card className={classes.root} square>
          <CardHeader title={TextDE.accountConfirmationLabel} subheader={''} />
          <CardContent>
            <Grid container spacing={2}>
              {!!this.state.verified ? (
                <Grid item xs={12}>
                  <Alert severity='success'>
                    {TextDE.accountAlreadyConfirmed(readable(this.props.User.verified))}
                  </Alert>
                </Grid>
              ) : !!this.props.doNotDisplayHint ? null : (
                <Grid item xs={12}>
                  <Alert
                    severity='error'
                    style={{
                      fontSize: '120%',
                      fontWeight: '800',
                      marginBottom: '8px',
                    }}
                  >
                    {TextDE.accountVerifyEmailLabel}
                  </Alert>
                  <Button
                    disabled={!!this.state.verified}
                    fullWidth
                    variant='contained'
                    color='secondary'
                    startIcon={<RefreshIcon />}
                    onClick={this.resendVerify}
                  >
                    {TextDE.accountNotYetConfirmedText} &nbsp;
                    {TextDE.accountVerifyEmailResendButton}
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant='h6'>{TextDE.hello(this.props.User.firstname)},</Typography>
                <Typography variant='body1' gutterBottom>
                  {TextDE.agbAndAvvHint}
                  <br />
                  {TextDE.thankYou}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title='AGB' />
                  <CardMedia
                    component='img'
                    alt='AGB.pdf'
                    height='140'
                    image='/assets/agb.png'
                    title='AGB.pdf'
                  />
                  <CardActions>
                    <Button size='small' color='primary' target='_blank' href='/assets/agb.pdf'>
                      {TextDE.download}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              <Grid item xs={12} md={6}>
                <Card>
                  <CardHeader title='AVV' />
                  <CardMedia
                    component='img'
                    alt='AVV.pdf'
                    height='140'
                    image='/assets/av.png'
                    title='AVV.pdf'
                  />
                  <CardActions>
                    <Button size='small' color='primary' onClick={this.getPDF}>
                      {TextDE.download}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {this.state.base64 === false ? null : (
                <>
                  <Grid item xs={12}>
                    <Typography variant='body1'>{TextDE.accountVerifyButtonHint}</Typography>
                  </Grid>
                  {!!this.state.verified === false ? (
                    <Alert
                      severity='error'
                      style={{
                        width: '100%',
                        fontSize: '120%',
                        fontWeight: '800',
                        marginLeft: '-16px',
                      }}
                    >
                      {TextDE.accountNotVerifiedHint}
                    </Alert>
                  ) : null}
                  <Grid item xs={12}>
                    <FormControlLabel
                      style={{
                        width: '100%',
                        margin: 0,
                        backgroundColor: this.props.Theme.palette.primary.light,
                        color: this.props.Theme.palette.primary.contrastText,
                      }}
                      value='end'
                      control={
                        <Checkbox
                          disabled={!!this.state.verified}
                          checked={this.state.checked}
                          onChange={this.handleCheckbox}
                          color='inherit'
                        />
                      }
                      label={
                        <Typography>
                          {TextDE.accountVerifyText(
                            this.props.User.firstname,
                            this.props.User.lastname,
                            this.props.User.email,
                          )}
                        </Typography>
                      }
                      labelPlacement='end'
                    />
                  </Grid>
                </>
              )}
              {this.state.base64 === false ? null : (
                <Grid item xs={12}>
                  {this.state.loading ? (
                    <center>
                      <CircularProgress />
                    </center>
                  ) : (
                    <Button
                      disabled={!!!this.state.checked || !!this.state.verified}
                      fullWidth
                      variant='contained'
                      color='default'
                      style={
                        !!!this.state.checked || !!this.state.verified
                          ? {}
                          : {
                              backgroundColor: this.props.Theme.palette.success.light,
                              color: this.props.Theme.palette.success.contrastText,
                            }
                      }
                      startIcon={<SaveIcon />}
                      onClick={this.verify}
                    >
                      {TextDE.accountVerifyButtonLabel}
                    </Button>
                  )}
                  {!!this.state.validation ? (
                    <Alert severity='success'> {TextDE.accountVerifiedAlert}</Alert>
                  ) : null}
                  {!!this.state.error && <Alert severity='error'> {this.state.error} </Alert>}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(VerificationBox);
