import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateCompany } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DE as TextDE } from '../../lib/Text';
import MenuItem from '@material-ui/core/MenuItem';
//import HelpIcon from '@material-ui/icons/Help';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  option: {
    padding: theme.spacing(2, 1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  },
  dividerMargin: {
    margin: theme.spacing(3, -1),
  },
  codeBox: {
    backgroundColor: '#eee',
    padding: theme.spacing(1),
  },
  code: {
    fontFamily:
      'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
  close: {
    maxWidth: 'clamp( 10%, 15%, 20%)',
    margin: theme.spacing(1, 'auto', 1, 0),
  },
  delete: {
    maxWidth: 'clamp( 10%, 20%, 30%)',
    margin: theme.spacing(1, 1, 1, 'auto'),
  },
  submitBody: {
    margin: theme.spacing(1, 0, 1, 'auto'),
    maxWidth: 'clamp( 15%, 25%, 35%)',
  },
  submit: {
    margin: theme.spacing(1, 0, 1, 1),
    maxWidth: 'clamp( 15%, 25%, 35%)',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',

    display: 'flex',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  placeholder: {
    opacity: 0.8,
  },
});

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;
const GAMMA = 2.4;

function luminance(r, g, b) {
  var a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
  });
  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

function contrast(rgb1, rgb2) {
  var lum1 = luminance(...rgb1);
  var lum2 = luminance(...rgb2);
  var brightest = Math.max(lum1, lum2);
  var darkest = Math.min(lum1, lum2);
  return (brightest + 0.05) / (darkest + 0.05);
}

class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      agbText: 'ERROR',

      isSubjectSubmitting: false,
      subjectSaved: false,
      subjects: [{ id: 'neuer eintrag', subject: 'Neuen Eintrag hinzufügen' }],
      selectedSubject: '',
      body: '',
      subject: '',
      hasSubjectError: false,

      isBodySubmitting: false,
      bodySaved: false,
      defaultBody: '',
      hasBodyError: false,

      isLinkSubmitting: false,
      linkSaved: false,
      hasLinkError: false,
      privacyLink: '',

      isColorsSubmitting: false,
      hasColorsError: false,
      colorsSaved: false,
      colors: {
        primary: '',
        primaryFont: '',
        secondary: '',
        secondaryFont: '',
        background: '',
        backgroundFont: '',
      },
    };

    this.handleLinkFormSubmit = this.handleLinkFormSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubjectFormSubmit = this.handleSubjectFormSubmit.bind(this);
    this.handleBodyFormSubmit = this.handleBodyFormSubmit.bind(this);
    this.handlePrimaryColorChange = this.handlePrimaryColorChange.bind(this);
    this.handleSecondaryColorChange = this.handleSecondaryColorChange.bind(this);
    this.handleBackgroundColorChange = this.handleBackgroundColorChange.bind(this);
    this.handleColorsFormSubmit = this.handleColorsFormSubmit.bind(this);
  }

  handlePrimaryColorChange = (color) => {
    let fontColor =
      contrast([color.rgb.r, color.rgb.g, color.rgb.b], [255, 255, 255]) < 4.5
        ? '#000000'
        : '#ffffff';
    this.setState(
      {
        colors: { ...this.state.colors, primary: color.hex, primaryFont: fontColor },
      },
      () => this.handleColorsFormSubmit(null),
    );
  };
  handleSecondaryColorChange = (color) => {
    let fontColor =
      contrast([color.rgb.r, color.rgb.g, color.rgb.b], [255, 255, 255]) < 4.5
        ? '#000000'
        : '#ffffff';
    this.setState(
      {
        colors: { ...this.state.colors, secondary: color.hex, secondaryFont: fontColor },
      },
      () => this.handleColorsFormSubmit(null),
    );
  };
  handleBackgroundColorChange = (color) => {
    let fontColor =
      contrast([color.rgb.r, color.rgb.g, color.rgb.b], [255, 255, 255]) < 4.5
        ? '#000000'
        : '#ffffff';
    this.setState(
      {
        colors: { ...this.state.colors, background: color.hex, backgroundFont: fontColor },
      },
      () => this.handleColorsFormSubmit(null),
    );
  };

  async handleColorsFormSubmit(event) {
    if (event !== null) {
      event.preventDefault();
    }
    this.setState({ isColorsSubmitting: true, hasColorsError: false });
    let formData = {};
    formData['_method'] = 'PUT';
    formData['form_colors'] = this.state.colors;

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/colors', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState(
        {
          isColorsSubmitting: false,
          hasColorsError: false,
          colors: response.company.form_colors,
          colorsSaved: true,
        },
        () => setTimeout(() => this.setState({ colorsSaved: false }), 2999),
      );
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isColorsSubmitting: false, hasColorsError: response });
    }
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/companies/my', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!!response.name) {
          this.setState({
            subjects: [...this.state.subjects, ...response.inquiry_subjects],
            defaultBody: response.inquiry_body,
            privacyLink: response.privacy_link,
            colors: response.form_colors,
            agbText: response.agb_text,
          });
        }
      });
  }

  handleSelect = (event) => {
    let item =
      event.target.value !== ''
        ? this.state.subjects.find((elm) => elm.id === event.target.value)
        : '';
    this.setState({
      selectedSubject: event.target.value,
      subject: item.subject,
      body: item.body ?? '',
    });
  };
  handleChange = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  async handleLinkFormSubmit(event) {
    event.preventDefault();
    this.setState({ isLinkSubmitting: true, hasLinkError: false });
    let formData = {};
    formData['_method'] = 'PUT';
    formData['privacy_link'] = this.state.privacyLink;
    formData['delete'] = this.state.delete;

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/link', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState(
        {
          isLinkSubmitting: false,
          linkSaved: true,
          hasLinkError: false,
          privacyLink: response.company.privacy_link,
          delete: null,
        },
        () => setTimeout(() => this.setState({ linkSaved: false }), 2999),
      );
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isLinkSubmitting: false, hasLinkError: response });
    }
  }

  async handleBodyFormSubmit(event) {
    event.preventDefault();
    this.setState({ isBodySubmitting: true, hasBodyError: false });
    let formData = {};
    formData['_method'] = 'PUT';
    formData['body'] = this.state.defaultBody;
    formData['delete'] = this.state.delete;

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/body', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState(
        {
          isBodySubmitting: false,
          bodySaved: true,
          hasBodyError: false,
          defaultBody: response.company.inquiry_body,
          delete: null,
        },
        () => setTimeout(() => this.setState({ bodySaved: false }), 2999),
      );
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isBodySubmitting: false, hasBodyError: response });
    }
  }

  async handleSubjectFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSubjectSubmitting: true, hasSubjectError: false });
    let formData = {};
    formData['_method'] = 'PUT';
    formData['body'] = this.state.body;
    formData['subject'] = this.state.subject;
    formData['subject_id'] = this.state.selectedSubject.toString();
    formData['delete'] = this.state.delete ?? false;

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/subjects', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState(
        {
          isSubjectSubmitting: false,
          hasSubjectError: false,
          delete: null,
          subject: '',
          selectedSubject: '',
          subjectSaved: true,
          subjects: [
            { id: 'neuer eintrag', subject: 'Neuen Eintrag hinzufügen' },
            ...response.company.inquiry_subjects,
          ],
        },
        () => setTimeout(() => this.setState({ subjectSaved: false }), 2999),
      );
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isSubjectSubmitting: false, hasSubjectError: response });
    }
  }

  render() {
    const { classes } = this.props;
    if (!!!this.props.User.customer.company || !!this.props.User.customer.company?.hidden) {
      return (
        <>
          <Paper className={classes.root} square>
            <Typography variant='h6' gutterBottom>
              Sie sind leider nicht in der Firmenauflistung zu finden. Aktivieren Sie dies bitte
              zunächst!
            </Typography>
            <Button
              variant='contained'
              onClick={() => this.props.dispatch(push('/settings/company'))}
              color='primary'
            >
              zu den Einstellungen
            </Button>
          </Paper>
        </>
      );
    }

    const code = `<!-- FLIXWORKER FORMULAR -->
<script type="text/javascript" src="${process.env.REACT_APP_API_URL}/assets/js/iframeResizer.min.js"></script>
<iframe 
  style="width: 1px;min-width: 100%; border: unset!important;" 
  id="flixworker-form" 
  src="${process.env.REACT_APP_API_URL}/form/company/${this.props.User.customer.company.uuid}" 
  scrolling="no"
  >
</iframe>
<script type="text/javascript">
  var isOldIE = (navigator.userAgent.indexOf("MSIE") !== -1);
  iFrameResize({log: false,heightCalculationMethod: isOldIE ? 'max' : 'lowestElement', checkOrigin: true,bodyBackground: "inherit"},'#flixworker-form');
</script>
<!-- ENDE FLIXWORKER FORMULAR -->`;

    return (
      <>
        <Paper className={classes.root} square>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={4}>
              <Typography variant='h6' style={{ display: 'flex' }}>
                So sieht das Formular ungefähr aus:
                <Button
                  style={{ marginLeft: 'auto', marginRight: '16px' }}
                  variant='text'
                  href={
                    process.env.REACT_APP_API_URL +
                    '/form/company/' +
                    this.props.User.customer.company.uuid
                  }
                >
                  Zur Live-Vorschau
                </Button>
              </Typography>
              <Box p={2}>
                <Paper
                  style={{
                    backgroundColor: this.state.colors.background,
                    color: this.state.colors.backgroundFont,
                    padding: '0 8px',
                    backgroundPosition: '4px calc(100% - 4px)',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                      'url(' +
                      process.env.REACT_APP_API_URL +
                      '/company/logo/' +
                      this.props.User.customer.company.uuid +
                      ')',
                    backgroundSize: 'clamp(15%, 20%, 22%) auto',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                      <Typography variant='h5' style={{ alignSelf: 'center' }}>
                        Betreff
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        value='Beispielhafter Betreff'
                        style={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant='body1'>[ ... ]</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                      <Typography variant='h5' style={{ alignSelf: 'top' }}>
                        Nachricht
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        multiline
                        minRows='4'
                        fullWidth
                        variant='outlined'
                        placeholder={this.state.defaultBody}
                        style={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        accept='image/*'
                        disabled
                        style={{ display: 'none' }}
                        id='contained-button-file'
                        multiple
                        type='file'
                      />
                      <label
                        htmlFor='contained-button-file'
                        style={{ overflow: 'clip', whiteSpace: 'nowrap', display: 'block' }}
                      >
                        <Button
                          variant='contained'
                          fullWidth
                          style={{
                            backgroundColor: this.state.colors.secondary,
                            color: this.state.colors.secondaryFont,
                          }}
                          component='span'
                        >
                          Dateien auswählen
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <Typography>
                        Mit dem Absenden dieses Formulares erkläre ich mich damit einverstanden das
                        meine Daten zur weiteren Verarbeitung und zur beantwortung meiner Anfrage
                        durch '{this.props.User.customer.company.name}' verwendet werden dürfen. Die
                        Datenschutzerklärung habe ich zur Kenntnis genommen.
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <Button
                        style={{
                          backgroundColor: this.state.colors.primary,
                          color: this.state.colors.primaryFont,
                        }}
                        type='button'
                        variant='contained'
                        fullWidth
                      >
                        Absenden
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>

            <Grid item xs={12} lg={8}>
              <Divider className={classes.dividerMargin} />
              <Typography variant='h5'>Konfiguration des Formulares</Typography>
              <Typography variant='body1' gutterBottom>
                Hier können Sie die Textfelder mit möglichen Optionen versehen. Änderungen hier
                können eine weile dauern bis Sie auf Ihrem Formular widergespiegelt werden.
              </Typography>

              <div className={classes.dividerMargin}>&nbsp;</div>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <label for='selected-subject'>Auswählbare 'Betrifft' Felder</label>
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    name='selectedSubject'
                    id='selected-subject'
                    defaultValue={-1}
                    value={this.state.selectedSubject}
                    onChange={this.handleSelect}
                  >
                    {this.state.subjects.map((option) => (
                      <MenuItem className={classes.option} key={option.id} value={option.id}>
                        {option.subject}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                {this.state.selectedSubject !== '' && (
                  <Grid
                    item
                    container
                    spacing={2}
                    component='form'
                    key='subjectform'
                    onSubmit={this.handleSubjectFormSubmit}
                  >
                    <Grid item xs={12} lg={12}>
                      <TextField
                        fullWidth
                        required
                        variant='outlined'
                        name='subject'
                        label={TextDE.form.subject.label}
                        value={this.state.subject}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} lg={12}>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        required
                        variant='outlined'
                        name='body'
                        label={TextDE.form.description.label}
                        value={this.state.body}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    {!!this.state.subjectSaved && (
                      <Grid item xs={12}>
                        <Alert severity='success'>Betreff und Textvorschlag gespeichert</Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      {!!this.state.hasSubjectError && (
                        <Alert severity='error'>
                          <AlertTitle>Fehler beim Aktualisieren der Daten...</AlertTitle>
                          {!!this.state.hasSubjectError?.errors &&
                            this.state.hasSubjectError?.errors?.map((option, key) => (
                              <Typography key={'body-errors-' + key}>{option}</Typography>
                            ))}
                        </Alert>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          type='button'
                          variant='contained'
                          className={classes.close}
                          disabled={this.state.isSubjectSubmitting}
                          fullWidth
                          onClick={() =>
                            this.setState({ body: '', selectedSubject: '', subject: '' })
                          }
                        >
                          Schließen
                        </Button>
                        <Button
                          type='submit'
                          variant='contained'
                          className={classes.delete}
                          disabled={this.state.isSubjectSubmitting}
                          fullWidth
                          onClick={() => this.setState({ delete: true })}
                        >
                          Löschen
                        </Button>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                          disabled={this.state.isSubjectSubmitting}
                          onClick={() => this.setState({ delete: false })}
                          fullWidth
                        >
                          Speichern
                        </Button>
                        {this.state.isSubjectSubmitting && (
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Divider className={classes.dividerMargin} />

              <Grid
                container
                spacing={3}
                component='form'
                key='bodyform'
                onSubmit={this.handleBodyFormSubmit}
              >
                <Grid item xs={12} lg={12}>
                  <label for='selected-body'>Standard Platzhalter (Ausfüllhilfe) </label>
                  <TextField
                    id='selected-body'
                    multiline
                    rows={4}
                    fullWidth
                    required
                    variant='outlined'
                    name='defaultBody'
                    value={this.state.defaultBody}
                    onChange={this.handleChange}
                    className={classes.placeholder}
                  />
                </Grid>
                {!!this.state.bodySaved && (
                  <Grid item xs={12}>
                    <Alert severity='success'>Standard Platzhalter gespeichert</Alert>
                  </Grid>
                )}
                {!!this.state.hasBodyError && (
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <AlertTitle>Fehler beim Aktualisieren der Daten...</AlertTitle>
                      {!!this.state.hasBodyError?.errors &&
                        this.state.hasBodyError?.errors?.map((option, key) => (
                          <Typography key={'body-errors-' + key}>{option}</Typography>
                        ))}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    className={classes.delete}
                    disabled={this.state.isBodySubmitting}
                    fullWidth
                    onClick={() => this.setState({ delete: true })}
                  >
                    Löschen
                  </Button>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={this.state.isBodySubmitting}
                    onClick={() => this.setState({ delete: false })}
                    fullWidth
                  >
                    Speichern
                  </Button>
                  {this.state.isBodySubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </Grid>
              </Grid>

              <Divider className={classes.dividerMargin} />

              <Grid
                container
                spacing={3}
                component='form'
                key='linkform'
                onSubmit={this.handleLinkFormSubmit}
              >
                <Grid item xs={12} lg={12}>
                  <label for='privacy-link'>Link zu Ihren Datenschutzvereinbarungen</label>
                  <TextField
                    type='url'
                    id='privacy-link'
                    fullWidth
                    required
                    placeholder='https://ihre-webseite.de/privacy'
                    variant='outlined'
                    name='privacyLink'
                    value={this.state.privacyLink}
                    onChange={this.handleChange}
                    className={classes.placeholder}
                  />
                </Grid>
                {!!this.state.linkSaved && (
                  <Grid item xs={12}>
                    <Alert severity='success'>Datenschutz Link gespeichert</Alert>
                  </Grid>
                )}
                {!!this.state.hasLinkError && (
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <AlertTitle>Fehler beim Aktualisieren der Daten...</AlertTitle>
                      {!!this.state.hasLinkError?.errors &&
                        this.state.hasLinkError?.errors?.map((option, key) => (
                          <Typography key={'link-errors-' + key}>{option}</Typography>
                        ))}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} style={{ display: 'flex' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    className={classes.delete}
                    disabled={this.state.isLinkSubmitting}
                    fullWidth
                    onClick={() => this.setState({ delete: true })}
                  >
                    Löschen
                  </Button>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={this.state.isLinkSubmitting}
                    onClick={() => this.setState({ delete: false })}
                    fullWidth
                  >
                    Speichern
                  </Button>
                  {this.state.isLinkSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </Grid>
              </Grid>

              <Divider className={classes.dividerMargin} />

              <Grid
                container
                spacing={3}
                component='form'
                key='colorsform'
                onSubmit={this.handleColorsFormSubmit}
              >
                <Grid item xs={12} lg={4} component='center'>
                  <Typography variant='h4' align='left' gutterBottom>
                    Primärfarbe
                  </Typography>
                  <SketchPicker
                    name='primary'
                    disableAlpha={true}
                    presetColors={[]}
                    color={this.state.colors.primary}
                    onChangeComplete={this.handlePrimaryColorChange}
                  />
                </Grid>
                <Grid item xs={12} lg={4} component='center'>
                  <Typography variant='h4' align='left' gutterBottom>
                    Sekundärfarbe
                  </Typography>
                  <SketchPicker
                    name='secondary'
                    disableAlpha={true}
                    presetColors={[]}
                    color={this.state.colors.secondary}
                    onChangeComplete={this.handleSecondaryColorChange}
                  />
                </Grid>
                <Grid item xs={12} lg={4} component='center'>
                  <Typography variant='h4' align='left' gutterBottom>
                    Hintergrund
                  </Typography>
                  <SketchPicker
                    name='background'
                    disableAlpha={true}
                    presetColors={[]}
                    color={this.state.colors.background}
                    onChangeComplete={this.handleBackgroundColorChange}
                  />
                </Grid>
                {!!this.state.colorsSaved && (
                  <Grid item xs={12}>
                    <Alert severity='success'>Farbeschema gespeichert</Alert>
                  </Grid>
                )}
                {!!this.state.hasColorsError && (
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <AlertTitle>Fehler beim Aktualisieren der Daten...</AlertTitle>
                      {!!this.state.hasColorsError?.errors &&
                        this.state.hasColorsError?.errors?.map((option, key) => (
                          <Typography key={'colors-errors-' + key}>{option}</Typography>
                        ))}
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={this.state.isColorsSubmitting}
                    onClick={() => this.setState({ delete: false })}
                    fullWidth
                  >
                    Speichern
                  </Button>
                  {this.state.isColorsSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </Grid>
              </Grid>

              {!!!this.props.User.customer.company?.hidden && (
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <Divider className={classes.dividerMargin} />
                    <Typography variant='subtitle1'>
                      Bitte fügen Sie diesen Code an der gewünschten Stelle auf Ihrer Webseite
                      hinzu.
                      <br />
                      Das Formular wird sich bestmöglichst an die verfügbare Größe anpassen.
                    </Typography>
                    <Box border={1} borderRadius='borderRadius' className={classes.codeBox}>
                      <Typography variant='body1' display='block' className={classes.code}>
                        {code}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <Divider className={classes.dividerMargin} />
                    <Typography variant='subtitle1'>
                      Bitte fügen Sie diesen Text In Ihren Datenschutzvereinbarungen hinzu.
                    </Typography>
                    <Box border={1} borderRadius='borderRadius' className={classes.codeBox}>
                      <Typography variant='body1' display='block' className={classes.code}>
                        {this.state.agbText}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(CustomerForm);
