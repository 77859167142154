import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

class Privacy extends React.Component {
  render() {
    return (
      <Grid container justifyContent='center'>
        <Grid item xs={9}>
          <Card style={{ margin: '10vh 0' }}>
            <CardContent>
              <Typography color='textSecondary' gutterBottom>
                Impressum
              </Typography>
              <Typography variant='body2' component='div'>
                <p>
                  Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
                  EU-Datenschutzgrundverordnung (DSGVO), ist:
                </p>
                <p>
                  FLiXWORKER GmbH
                  <br />
                  Am Lusbühl 16
                  <br />
                  79110 Freiburg
                  <br />
                  <br />
                </p>
                <h5>Vertreten durch:</h5>
                Geschäftsführer Markus Gentner
                <br />
                <p></p>
                <p></p>
                <h5>Kontakt:</h5>
                Telefon: 0761-89750180
                <br />
                E-Mail: info@flixworker.de
                <br />
                <br />
                <p></p>
                <h2>Ihre Betroffenenrechte</h2>
                <p>
                  Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie
                  jederzeit folgende Rechte ausüben:
                </p>
                <ul>
                  <li>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung,</li>
                  <li>Berichtigung unrichtiger personenbezogener Daten,</li>
                  <li>Löschung Ihrer bei uns gespeicherten Daten,</li>
                  <li>
                    Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund gesetzlicher
                    Pflichten noch nicht löschen dürfen,
                  </li>
                  <li>Widerspruch gegen die Verarbeitung Ihrer Daten bei uns und</li>
                  <li>
                    Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben
                    oder einen Vertrag mit uns abgeschlossen haben.
                  </li>
                </ul>
                <p>
                  Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit
                  Wirkung für die Zukunft widerrufen.
                </p>
                <p>
                  Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige
                  Aufsichtsbehörde wenden. Ihre zuständige Aufsichtsbehörde richtet sich nach dem
                  Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder der mutmaßlichen Verletzung. Eine
                  Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit Anschrift
                  finden Sie unter:{' '}
                  <a
                    href='https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
                  </a>
                  .
                </p>
                <h2>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</h2>
                <p>
                  Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser
                  Datenschutzerklärung genannten Zwecken. Eine Übermittlung Ihrer persönlichen Daten
                  an Dritte zu anderen als den genannten Zwecken findet nicht statt. Wir geben Ihre
                  persönlichen Daten nur an Dritte weiter, wenn:
                </p>
                <ul>
                  <li>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,</li>
                  <li>
                    die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,
                  </li>
                  <li>
                    die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,
                  </li>
                </ul>
                <p>
                  die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein
                  Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an
                  der Nichtweitergabe Ihrer Daten haben.
                </p>
                <h2>Löschung bzw. Sperrung der Daten</h2>
                <p>
                  Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir
                  speichern Ihre personenbezogenen Daten daher nur so lange, wie dies zur Erreichung
                  der hier genannten Zwecke erforderlich ist oder wie es die vom Gesetzgeber
                  vorgesehenen vielfältigen Speicherfristen vorsehen. Nach Fortfall des jeweiligen
                  Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten routinemäßig
                  und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
                </p>
                <h2>Erfassung allgemeiner Informationen beim Besuch unserer Website</h2>
                <p>
                  Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies
                  Informationen allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles)
                  beinhalten etwa die Art des Webbrowsers, das verwendete Betriebssystem, den
                  Domainnamen Ihres Internet-Service-Providers und ähnliches. Hierbei handelt es
                  sich ausschließlich um Informationen, welche keine Rückschlüsse auf Ihre Person
                  zulassen.
                </p>
                <p>
                  Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte
                  von Webseiten korrekt auszuliefern und fallen bei Nutzung des Internets zwingend
                  an. Sie werden insbesondere zu folgenden Zwecken verarbeitet:
                </p>
                <ul>
                  <li>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,</li>
                  <li>Sicherstellung einer reibungslosen Nutzung unserer Website,</li>
                  <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
                  <li>zu weiteren administrativen Zwecken.</li>
                </ul>
                <p>
                  Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten
                  Interesse aus den vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten
                  nicht, um Rückschlüsse auf Ihre Person zu ziehen. Empfänger der Daten sind nur die
                  verantwortliche Stelle und ggf. Auftragsverarbeiter.
                </p>
                <p>
                  Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um
                  unseren Internetauftritt und die dahinterstehende Technik zu optimieren.
                </p>
                <h2>Cookies</h2>
                <p>
                  Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind
                  kleine Textdateien, die von einem Websiteserver auf Ihre Festplatte übertragen
                  werden. Hierdurch erhalten wir automatisch bestimmte Daten wie z. B. IP-Adresse,
                  verwendeter Browser, Betriebssystem und Ihre Verbindung zum Internet.
                </p>
                <p>
                  Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf
                  einen Computer zu übertragen. Anhand der in Cookies enthaltenen Informationen
                  können wir Ihnen die Navigation erleichtern und die korrekte Anzeige unserer
                  Webseiten ermöglichen.
                </p>
                <p>
                  In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder
                  ohne Ihre Einwilligung eine Verknüpfung mit personenbezogenen Daten hergestellt.
                </p>
                <p>
                  Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten.
                  Internet-Browser sind regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im
                  Allgemeinen können Sie die Verwendung von Cookies jederzeit über die Einstellungen
                  Ihres Browsers deaktivieren. Bitte verwenden Sie die Hilfefunktionen Ihres
                  Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern können. Bitte
                  beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht
                  funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben.
                </p>
                <h2>Registrierung auf unserer Webseite</h2>
                <p>
                  Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden
                  einige personenbezogene Daten erhoben, wie Name, Anschrift, Kontakt- und
                  Kommunikationsdaten wie Telefonnummer und E-Mail-Adresse. Sind Sie bei uns
                  registriert, können Sie auf Inhalte und Leistungen zugreifen, die wir nur
                  registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die Möglichkeit,
                  bei Bedarf die bei Registrierung angegebenen Daten jederzeit zu ändern oder zu
                  löschen. Selbstverständlich erteilen wir Ihnen darüber hinaus jederzeit Auskunft
                  über die von uns über Sie gespeicherten personenbezogenen Daten. Gerne berichtigen
                  bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen
                  Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang
                  nutzen Sie bitte die am Ende dieser Datenschutzerklärung angegebenen Kontaktdaten.
                </p>
                <h2>Erbringung kostenpflichtiger Leistungen</h2>
                <p>
                  Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten
                  erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen zu können. Wir
                  speichern diese Daten in unseren Systemen bis die gesetzlichen
                  Aufbewahrungsfristen abgelaufen sind.
                </p>
                <h2>SSL-Verschlüsselung</h2>
                <p>
                  Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem
                  aktuellen Stand der Technik entsprechende Verschlüsselungsverfahren (z. B. SSL)
                  über HTTPS.
                </p>
                <h2>Kontaktformular</h2>
                <p>
                  Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in
                  Kontakt, erteilen Sie uns zum Zwecke der Kontaktaufnahme Ihre freiwillige
                  Einwilligung. Hierfür ist die Angabe einer validen E-Mail-Adresse erforderlich.
                  Diese dient der Zuordnung der Anfrage und der anschließenden Beantwortung
                  derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben
                  werden zum Zwecke der Bearbeitung der Anfrage sowie für mögliche Anschlussfragen
                  gespeichert. Nach Erledigung der von Ihnen gestellten Anfrage werden
                  personenbezogene Daten automatisch gelöscht.
                </p>
                <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
                <p>
                  Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
                  darzustellen, verwenden wir auf dieser Website Scriptbibliotheken und
                  Schriftbibliotheken wie z. B. Google Webfonts (
                  <a
                    href='http://www.google.com/webfonts/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://www.google.com/webfonts/
                  </a>
                  ). Google Webfonts werden zur Vermeidung mehrfachen Ladens in den Cache Ihres
                  Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder
                  den Zugriff unterbindet, werden Inhalte in einer Standardschrift angezeigt.
                </p>
                <p>
                  Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine
                  Verbindung zum Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich –
                  aktuell allerdings auch unklar ob und ggf. zu welchen Zwecken – dass Betreiber
                  entsprechender Bibliotheken Daten erheben.
                </p>
                <p>
                  Die Datenschutzrichtlinie des Bibliothekbetreibers Google finden Sie hier:{' '}
                  <a
                    href='https://www.google.com/policies/privacy/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://www.google.com/policies/privacy/
                  </a>
                </p>
                <h2>Google Maps </h2>
                <p>
                  Diese Webseite nutzt Google Maps zur Darstellung interaktiver Karten und zur
                  Erstellung von Anfahrtsbeschreibungen. Google Maps ist ein Kartendienst von Google
                  Inc., 1600 Amphitheatre Parkway, Mountain View, California 94043, USA. Durch die
                  Nutzung von Google Maps können Informationen über die Benutzung dieser Website
                  einschließlich Ihrer IP-Adresse und der im Rahmen der Routenplanerfunktion
                  eingegebenen (Start-) Adresse an Google in den USA übertragen werden. Wenn Sie
                  eine Webseite unseres Internetauftritts aufrufen, die Google Maps enthält, baut
                  Ihr Browser eine direkte Verbindung mit den Servern von Google auf. Der
                  Karteninhalt wird von Google direkt an Ihren Browser übermittelt und von diesem in
                  die Webseite eingebunden. Daher haben wir keinen Einfluss auf den Umfang der auf
                  diese Weise von Google erhobenen Daten. Entsprechend unserem Kenntnisstand sind
                  dies zumindest folgende Daten:{' '}
                </p>
                <ul>
                  <li>Datum und Uhrzeit des Besuchs auf der betreffenden Webseite,</li>
                  <li>Internetadresse oder URL der aufgerufenen Webseite,</li>
                  <li>IP-Adresse, im Rahmen der Routenplanung eingegebene (Start-)Anschrift. </li>
                </ul>
                <p>
                  Auf die weitere Verarbeitung und Nutzung der Daten durch Google haben wir keinen
                  Einfluss und können daher hierfür keine Verantwortung übernehmen. <br />
                  Wenn Sie nicht möchten, dass Google über unseren Internetauftritt Daten über Sie
                  erhebt, verarbeitet oder nutzt, können Sie in Ihrem Browsereinstellungen
                  JavaScript deaktivieren. In diesem Fall können Sie die Kartenanzeige jedoch nicht
                  nutzen. <br />
                  Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der
                  Daten durch Google sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten
                  zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von
                  Google (
                  <a
                    href='https://policies.google.com/privacy?hl=de'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    https://policies.google.com/privacy?hl=de
                  </a>
                  ).
                  <br />
                  Durch die Nutzung unserer Webseite erklären Sie sich mit der Bearbeitung der über
                  Sie erhobenen Daten durch Google Maps Routenplaner in der zuvor beschriebenen Art
                  und Weise und zu dem zuvor benannten Zweck einverstanden. <br />
                  <br />
                  <b>
                    Betroffenenrechte <br />
                  </b>
                  Sie haben folgende Rechte, bezüglich derer Sie sich jederzeit über die oben unter
                  "Verantwortliche Stelle" sowie im{' '}
                  <a href='/impressum' target='_blank' rel='noopener noreferrer'>
                    Impressum
                  </a>{' '}
                  und sonst auf unserer Webseite angegebenen Kontaktdaten oder über die
                  Email-Adresse <a href='mailto:info@flixworker.de'>info(at)flixworker.de</a> an uns
                  wenden können:{' '}
                </p>
                <h2>
                  <strong>Änderung unserer Datenschutzbestimmungen</strong>
                </h2>
                <p>
                  Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den
                  aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer
                  Leistungen in der Datenschutzerklärung umzusetzen, z.B. bei der Einführung neuer
                  Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                </p>
                <h2>
                  <strong>Fragen an den Datenschutzbeauftragten</strong>
                </h2>
                <p>
                  Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder
                  wenden Sie sich direkt an die für den Datenschutz verantwortliche Person in
                  unserer Organisation:
                </p>
                <p>Herr Tobias Weber, E-Mail: info@flixworker.de</p>
                <p>
                  <em>Die Datenschutzerklärung wurde mit dem </em>
                  <a
                    href='https://www.activemind.de/datenschutz/datenschutzhinweis-generator/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <em>Datenschutzerklärungs-Generator der activeMind AG erstellt</em>
                  </a>
                  <em>.</em>
                </p>
              </Typography>
              <hr />
              <h4>Was speichern/benötigen wir an Personenbezogenen Daten von Ihnen? </h4>
              <p></p>
              <ul>
                <li style={{ listStyleType: 'square' }}>
                  Vor-,Nachname <b style={{ color: 'red' }}>*</b>
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Password ( natürlich verschlüsselt!) <b style={{ color: 'red' }}>*</b>
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Wurde Ihr Passwort über die vergessen Funktion neu angefordert? (Wann/Wer/ Reset
                  Token)
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Email-Addresse <b style={{ color: 'red' }}>*</b>
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Letzte Besuchs-IP/Zeit <b style={{ color: 'red' }}>*</b>
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Zu Welcher Kundengruppe Sie gehören (Firma, Mitarbeiter, Handwerker,
                  Dienstleister, Verwalter, Mieter, Eigentümer, Bewohner){' '}
                  <b style={{ color: 'red' }}>*</b>
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Wann der Account erstellt wurde/Wann bearbeitet <b style={{ color: 'red' }}>*</b>
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Ob wir Sie automatisiert via E-Mail kontaktieren dürfen (Benachrichtigungen bei
                  Änderungen Ihrer Vorgänge) <b style={{ color: 'red' }}>*</b>{' '}
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Ob Sie einer Firma/einem Unternehmen zugehörig sind{' '}
                </li>
                <li style={{ listStyleType: 'square' }}>
                  Kontaktdaten über die Email Hinaus sind optional! (Telefonnummern,Fax)
                </li>
                <p></p>
              </ul>
              <h4>Was Speichert Flixworker für unsere Kunden (also für Sie)?</h4>
              <p></p>
              <ul>
                <li class='list-unstyled'>
                  <h5>Personenbezogene Daten dritter</h5>
                </li>
                <p>
                  Da wir unter anderem das Pflegen eines Addressbuches ermöglichen erfassen wir auch
                  Daten deren Ursprung nur Sie kennen. Dennoch wollen wir Sie unterstützen und
                  möchten Ihnen bei der DSGVO Konformen Verwaltung dieser Helfen!
                </p>
                <p style={{ display: 'none' }}>
                  Wir haben (sofern gewünscht) die Möglichkeit implementiert mit den von Ihnen
                  gepflegten Personen/Firmen Datenverarbeitungsverträge auszuhandeln. (DAS MÜSSEN
                  WIR ANGEHEN!!!)
                </p>
                <b>Was wir dabei speichern (können):</b>G<p></p>
                <ul>
                  <li style={{ listStyleType: 'square' }}>
                    Name, Vorname <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Anrede <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Addresse (Postanschrift) <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Festnetz-, Mobilfunk-, Faxnummern und E-Mail Addressen
                  </li>
                  <li style={{ listStyleType: 'square' }}>Firmenzugehörigkeit</li>
                  <li style={{ listStyleType: 'square' }}>Notizen</li>
                  <p></p>
                </ul>
                <li class='list-unstyled'>
                  <h5>Ticket/Auftragsdaten</h5>
                </li>
                <p>
                  In erster Linie ist unsere Software ein Organisationstool für Ihre Tätigkeiten
                  rund um Immobilien. Daher speichern wir hier Ihre ensprechenden Daten, ob dies nun
                  Eintäge im Ticket oder gesendete Nachrichten sind.
                </p>
                <p></p>
                <li class='list-unstyled'>
                  <b>Was wir dabei speichern (können):</b>
                </li>
                <p></p>
                <ul>
                  <li style={{ listStyleType: 'square' }}>
                    Die von Ihnen definierte Priorität (Gering, Mittel, Hoch, Notfall){' '}
                    <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Unterschiedliche Bearbeitungs Stati (Neu, Offen, In Bearbeitung, E-Mail
                    versendet, E-Mail Empfangen, ausgehender Anruf, eingehender Anruf, Warten,
                    Geschlossen) <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Problem/Tätigkeit und weitere Beschreibung <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    dem Ticket Zugewiesene Personen (Veratnwortlicher, Besitzer und weitere){' '}
                    <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Letzte Änderungen (Datum und durch wen) <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Erstellzeitpunkt <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>Ein Eigens von Ihnen befüllbares Feld</li>
                  <li style={{ listStyleType: 'square' }}>
                    Zuordnung um welche Immobilie, Einheit, oder sogar welcher Raum betroffen ist,
                    daraus ergibt sich auch die Verknüpfung der Personen (Verlinkung zu den
                    entsprechenden Gebäude Daten)
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Einzelne Einträge im Ticket (Protokolleinträge), bestehend je nach Art aus:
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Eintragsersteller (Verlinkt zu den Usern) <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Art des Eintrages (Meist automatisch aus folgenden: Persönlich, E-Mail,
                    Telefonisch, Schriftlich, über Dritte, Fax, System (schnell), System
                    (erweitert),System(Email), System ) <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    "Betreff" <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Inhalt des Eintrages/Nachricht <b style={{ color: 'red' }}>*</b>
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Email-Daten (Absende-, Empfängeraddressen) (Wenn eine Email geschrieben wird)
                  </li>
                  <li style={{ listStyleType: 'square' }}>
                    Termindaten, Kontaktinformation (Wenn ein Termin erstellt wurde)
                  </li>
                  <li style={{ listStyleType: 'square' }}>Rechnungstexte, zu berechnende Zeiten</li>
                  <li style={{ listStyleType: 'square' }}>
                    Dateiuploads
                    <br />
                    Wir unterstützen den Upload von Dateien hierzu. Diese sind nur durch Sie/Ihr
                    Unternehmen/Ihre Freigeschalteten Kontakte einsehbar!
                  </li>
                </ul>
              </ul>
            </CardContent>
            <CardActions>
              <NavLink
                to='/login'
                className='NavLinkItem'
                key='/login'
                activeClassName='NavLinkItem-selected'
              >
                Zurück zu Login
              </NavLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default Privacy;
