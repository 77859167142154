import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import FiberNewIcon from '@material-ui/icons/FiberNew'; // new
//import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'; // wait
import SnoozeTwoToneIcon from '@material-ui/icons/SnoozeTwoTone'; // wait
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'; //done
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'; //work

const componentOf = {
  done: <AssignmentTurnedInIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  working: <DirectionsRunIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  wait: <SnoozeTwoToneIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  new: <FiberNewIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
};

const styles = (theme) => ({
  chip: {
    padding: theme.spacing(1.5, 1),
    fontSize: '110%',
  },
  center: {
    justifyContent: 'center',
    display: 'flex',
  },
});

class StatusChip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      stateSelectAnchorEl: null,
      states: [],
      status: this.props.status,
      model: this.props.model || 'procedures',
    };
  }

  handleStateSelectClose = () => {
    this.setState({ stateSelectAnchorEl: null });
  };

  handleStateSelectOpen = (event) => {
    this.setState({ stateSelectAnchorEl: event.currentTarget });
  };

  handleSetStateSelect = async (id) => {
    const newState = this.state.states.find((element) => element.id === id);

    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('status_id', id);

    await fetch(
      process.env.REACT_APP_API_URL +
        '/api/' +
        this.state.model +
        '/' +
        this.props.match.params.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ status: newState, stateSelectAnchorEl: null });
        if (this.props.status === null || this.props.status === 0) {
          this.props.callbackFunction(true);
        }
      })
      .catch((error) => {
        return false;
      });
  };

  componentDidMount() {
    if (this.state.status === null || this.state.status === 0) {
      this.setState({
        status: {
          name: 'Neu',
          icon: 'new',
          description: 'Neu',
          style: { backgroundColor: '#ff0000', color: '#ffffff' },
        },
      });
    }

    if (!!this.props.simpleStates) {
      fetch(process.env.REACT_APP_API_URL + '/api/' + this.state.model + '/states?simple=true', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          this.setState({ states: json, isFetching: false });
        })
        .catch((error) => {
          return false;
        });
    } else {
      fetch(process.env.REACT_APP_API_URL + '/api/' + this.state.model + '/states', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          this.setState({ states: json, isFetching: false });
        })
        .catch((error) => {
          return false;
        });
    }
  }

  render() {
    const { classes } = this.props;

    if (this.state.isFetching) {
      return <Chip className={classes.chip} label={<CircularProgress />} />;
    }

    return (
      <>
        {/* Status and ID */}
        <Tooltip title={this.state.status.description} aria-label='add'>
          <Chip
            className={classes.chip}
            label={this.state.status.name}
            variant='outlined'
            icon={componentOf[this.state.status.icon]}
            style={this.state.status.style}
            onClick={this.handleStateSelectOpen}
          />
        </Tooltip>
        <Menu
          anchorEl={this.state.stateSelectAnchorEl}
          keepMounted
          open={Boolean(this.state.stateSelectAnchorEl)}
          onClose={this.handleStateSelectClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          {this.state.states.map((item, index) => {
            return (
              <MenuItem key={item.id} onClick={() => this.handleSetStateSelect(item.id)}>
                {item.name}
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(StatusChip);
