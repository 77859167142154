import React from 'react';
import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history, persistStore } from './stores/configureStore';
import Routes from './router/Routes';
import { refreshLogin } from './actions/Authentication';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
//import reportWebVitals from './reportWebVitals';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import './index.css';
//import { addNotificationWrapper} from './actions/Notifications'
import { displayNewVersionHint } from './actions/NewVersion';

import { initStateWithPrevTab } from 'redux-state-sync';
import { initMessageListener } from 'redux-state-sync';

let initialState = {};
let toDispatch = [];
let store = null;

if (typeof localStorage.Authentication !== 'undefined') {
  const savedState = JSON.parse(localStorage.Authentication);
  if (savedState.access_token !== false) {
    console.log('==== Found Saved Login - Trigger RELOGIN');
    savedState.loading = Date.now() - 86400000 > savedState.expires_at;
    initialState.Authentication = savedState;
    toDispatch.push('HANDLE_RELOGIN'); //Thunk?!
    toDispatch.push('FETCH_THEME'); //Thunk?!
    toDispatch.push('FETCH_LOGO'); //Thunk?!
    toDispatch.push('FETCH_NAV_MENU'); //Thunk?!
    toDispatch.push('FETCH_USER'); //Thunk?!
    if (typeof localStorage.User !== 'undefined') {
      initialState.User = JSON.parse(localStorage.User);

      if (typeof localStorage.Style !== 'undefined') {
        initialState.Style = JSON.parse(localStorage.Style);
        initialState.Style.Theme.typography.fontSize = parseInt(
          initialState.Style.Theme.typography.fontSize,
        );
        initialState.Style.Theme = responsiveFontSizes(createTheme(initialState.Style.Theme));
        document
          .getElementsByTagName('html')[0]
          .setAttribute(
            'style',
            'background-color: ' + initialState.Style?.Theme?.palette?.background?.html ||
              '#ffffff',
          );
      } else {
        toDispatch.push('FETCH_STYLE');
        //Thunk?!
      }

      if (typeof localStorage.NavMenu !== 'undefined') {
        initialState.NavMenu = JSON.parse(localStorage.NavMenu);
      } else {
        toDispatch.push('FETCH_NAV_MENU'); //Thunk?!
      }
    } else {
      toDispatch.push('FETCH_USER'); //Thunk?!
    }
  } else {
    localStorage.clear();
  }
}
store = configureStore(initialState);
if (Object.keys(initialState).length === 0) {
  initStateWithPrevTab(store);
} else {
  initMessageListener(store);
}

store.subscribe(() => persistStore(store));

if (toDispatch.length > 0) {
  //console.log("toDispatch", toDispatch, 'navigator', navigator);
  if (navigator.onLine) {
    if (!!navigator.serviceWorker) {
      navigator.serviceWorker.getRegistrations().then((registrationsArray) => {
        registrationsArray.forEach(function (registerArr) {
          registerArr.update();
        });
      });
    }
    if (toDispatch.includes('HANDLE_RELOGIN')) {
      store.dispatch(refreshLogin());
    }
  }
}

window.store = store;

/*react 18

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>  
);
*/
ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Routes />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
const swConfig = {
  onUpdate: () => {
    store.dispatch(displayNewVersionHint());
  },
  //onUpdate: (registration) => { console.log(registration); store.dispatch(addNotificationWrapper({type: 'SWUpdate', message: 'Es gibt ein Update! Bitte schließen Sie alle Fenster und laden FLiXWORKER neu!'}));},
  //onSuccess: (registration) => { console.log(registration); store.dispatch(addNotificationWrapper({type: 'SWInstall', message: 'FLiXWORKER wurde erfolgreich installiert!'}));},
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register(swConfig);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
