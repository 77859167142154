import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import SignatureCanvas from 'react-signature-canvas';
import CreateIcon from '@material-ui/icons/Create';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Badge from '@material-ui/core/Badge';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Subject from '../../Inbox/components/PerformanceReport/components/Subject';
import Subtitle from '../../Inbox/components/PerformanceReport/components/Subtitle';
import Comment from '../../Inbox/components/PerformanceReport/components/Comment';
import Address from '../../Inbox/components/PerformanceReport/components/Address';
import Todos from '../../Inbox/components/PerformanceReport/components/Todos';
import Parts from '../../Inbox/components/PerformanceReport/components/Parts';
import PerformanceDate from '../../Inbox/components/PerformanceReport/components/PerformanceDate';
import SaveIcon from '@material-ui/icons/Save';
import { DE as TextDE } from '../../../lib/Text';
import { Constants } from '../../../lib/Constants';
import KeyboardHideIcon from '@material-ui/icons/KeyboardHide';

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = (theme) => ({
  gridTileItem: {
    border: '1px solid ' + theme.palette.primary.main,
    padding: theme.spacing(0, 1),
  },
  gridList: {
    width: '100%',
    transform: 'translateZ(0)',
    paddingBottom: theme.spacing(2),
  },
  titleBar: {
    background: 'transparent',
  },
  titleBarText: {
    color: theme.palette.text.primary,
  },
  summary: {
    fontWeight: '800',
    fontSize: '120%',
  },
  head: {
    fontWeight: '800',
    fontSize: '120%',
  },
  delete: {
    maxWidth: '15%',
    minWidth: '7%',
  },
  description: {
    maxWidth: '65%',
    minWidth: '50%',
  },
  setHeight: {
    minHeight: '20vh',
  },
  todoTable: {
    '&>tbody': {
      '&>tr': {
        '&>th': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '5',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  },
  floatRight: {
    float: 'right',
    margin: theme.spacing(4, 0, 2, 0),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  paper: {
    [theme.breakpoints.up('lg')]: {
      width: '960px',
      margin: theme.spacing(2) + 'px auto',
      padding: theme.spacing(2, 4),
      border: '1px solid ' + theme.palette.divider,
      boxShadow: theme.shadows[4],
    },
  },
  alignScreenSizedRight: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'right',
      '&>button': {
        width: '100%',
      },
    },
  },
  alignScreenSizedCenter: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      '&>button': {
        width: '100%',
      },
    },
  },
  alignScreenSizedLeft: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
      '&>button': {
        width: '100%',
      },
    },
  },
  checkboxSize: {
    '&>span': {
      paddingRight: '1em',
      paddingLeft: '1em',
      '&>svg': {
        width: '1.5em',
        height: '1.5em',
      },
    },
  },
  sigImage: {
    width: '100%',
    maxWidth: '420px',
    maxHeight: '20vh',
    height: 'auto',
  },
  title: {
    padding: '8px',
    height: '54px',
    overflow: 'hidden',
    maxWidth: '100vw',
    margin: '0 -16px',
    //maxWidth: 'calc(100vw - 32px)',
    whiteSpace: 'initial',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  overlayBox: {
    padding: theme.spacing(0, 2),
  },
  redBorder: {
    borderColor: theme.palette.error.main,
  },
  hideKeyboard: {
    width: '60px',
    float: 'right',
    margin: theme.spacing(2, 0, 2, 0),
  },
  labelAlignLeft: {
    textAlign: 'left',
  },
  centerGridItem: {
    [theme.breakpoints.up('lg')]: {
      marginRight: 'auto',
      marginLeft: 'auto',
    },
  },
  input: {
    marginTop: '1em',
    backgroundColor: 'antiquewhite',
    fontSize: '1.5em',
  },
});

class PerformanceReportNew extends React.Component {
  formChange = (event) => {
    if (event !== null) {
      //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  submitPerformanceReport = async () => {
    this.setState({ isSubmitting: true });
    let errors = this.validate();
    if (errors.length > 0) {
      this.setState({ isSubmitting: false, hasError: errors });
      console.log(errors);
      return;
    }

    let formData = {};
    formData['_method'] = 'POST';
    //NEEDED!
    formData['todos'] = JSON.stringify(this.state.todos);
    formData['parts'] = JSON.stringify(this.state.parts);
    formData['address'] =
      this.state.address.route +
      ' ' +
      this.state.address.street_number +
      '\n' +
      this.state.address.postal_code +
      ' ' +
      this.state.address.city.locality;
    formData['serial'] = this.state.serial;
    formData['customerNotAvailable'] = this.state.customerNotAvailable;
    formData['signatureCustomer'] = this.state.signatureCustomer ?? '';
    formData['signatureCustomerPrintLetters'] = this.state.signatureCustomerPrintLetters ?? '';
    formData['signatureWorker'] = this.state.signatureWorker;
    formData['signatureWorkerPrintLetters'] = this.state.signatureWorkerPrintLetters;
    formData['subject'] = this.state.subject;
    formData['text'] = this.state.subtitle;
    formData['date'] = this.state.target_date.format('DD.MM.YYYY');
    formData['comment'] = this.state.comment ?? '';

    formData['mail_to'] =
      !!this.state.mailTo && this.state.mailTo.length > 0
        ? this.state.mailTo.map((mail) => {
            if (!!mail.mail) {
              return mail.mail;
            } else {
              return mail;
            }
          })
        : [];

    formData['procedureIsFinished'] = !!this.state.procedureIsFinished;
    if (!!this.state.stillSomethingTodo) {
      formData['stillSomethingTodo'] = !!this.state.stillSomethingTodo;
      formData['nextThingsTodo'] = this.state.nextThingsTodo;
    }

    let endpoint =
      process.env.REACT_APP_API_URL + '/api/performancereports/' + this.props.job?.uuid;

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
    if (response.saved === true || response.saved === 'true') {
      this.props.callbackFunction();
    }

    this.setState({
      isSubmitting: false,
      hasError: response,
    });
  };

  validate = () => {
    if (!!!this.state.sign) {
      return [];
    }
    let errors = [];
    if (!!!this.state.serial) {
      errors.push('Die ID fehlt');
    }

    if (!!!this.state.subject) {
      errors.push('Der Betreff fehlt');
    }

    //if (!!!this.state.subtitle) {
    //  errors.push("Anmerkungen fehlen");
    //}

    if (!!!this.state.address) {
      errors.push('Der Leistungsort fehlt');
    }

    if (!!!this.state.target_date) {
      errors.push('Das Leistungsdatum fehlt');
    }

    if (
      !!!this.state.todos &&
      (this.state.todos.length > 0 ||
        this.state.parts.length > 0 ||
        (this.state.todos.length > 0 && this.state.parts.length > 0))
    ) {
      errors.push('Min. eine Tätigkeit fehlt');
    }

    if (
      !!!this.state.parts &&
      (this.state.todos.length > 0 ||
        this.state.parts.length > 0 ||
        (this.state.todos.length > 0 && this.state.parts.length > 0))
    ) {
      errors.push('Min. ein Material/ Dienstleistung fehlt');
    }

    if (!!!this.state.signatureCustomer && !!!this.state.customerNotAvailable) {
      errors.push('Die Unterschrift des Kunden fehlt');
    }

    if (!!!this.state.signatureCustomerPrintLetters && !!!this.state.customerNotAvailable) {
      errors.push('Der Klarname des Kunden fehlt');
    }

    if (this.state.mailTo.length === 0 && !!this.state.customerNotAvailable) {
      errors.push(
        'Da der Kunde nicht anzutreffen ist/ nicht unterschreiben kann, bitte mindestens einen Empfänger angeben!',
      );
    }

    if (!!!this.state.signatureWorker) {
      errors.push('Die Unterschrift des ausführenden fehlt');
    }

    if (!!!this.state.signatureWorkerPrintLetters) {
      errors.push('Der Name des ausführenden fehlt');
    }

    return errors;
  };
  constructor(props) {
    super(props);
    this.state = {
      zoomSubject: false,
      zoomTarget_date: false,
      zoomAddress: false,
      zoomSubtitle: false,
      zoomComment: false,
      zoomTodos: false,
      zoomParts: false,

      procedureIsFinished: false,
      stillSomethingTodo: false,
      nextThingsTodo: '',

      mailTo: [],
      contactsEmails: this.formatEmails(),

      openSignatureCustomer: false,
      customerNotAvailable: false,
      signatureCustomer: null,
      signatureCustomerPrintLetters: '',

      openSignatureWorker: false,
      signatureWorker: null,
      signatureWorkerPrintLetters: props.User.firstname + ' ' + props.User.lastname,

      serial: props.procedure?.serial + '/' + props.job?.serial,
      subject: props.job?.data?.subject ?? props.procedure?.data?.subject,
      comment: props.job?.data?.body ?? props.procedure?.data?.comment,
      subtitle:
        props.job?.data?.subtitle ?? props.procedure?.data?.body ?? props.procedure?.data?.body,
      address: this.findPlaceOfPerformance(props.job.addresses ?? []),
      target_date: !!props.job?.target_date ? moment(props.job?.target_date) : moment(),
      todos: props.job?.items ?? [],
      parts: props.job?.parts ?? [],
      usersAll: props.usersAll.map((tmp) => {
        if (!!tmp.user) {
          return tmp.user;
        }
        return tmp;
      }),
    };

    this.zoom = this.zoom.bind(this);
    this.dataCallback = this.dataCallback.bind(this);

    this.validate = this.validate.bind(this);
    this.openSignatureWorker = this.openSignatureWorker.bind(this);
    this.closeSignatureWorker = this.closeSignatureWorker.bind(this);
    this.openSignatureCustomer = this.openSignatureCustomer.bind(this);
    this.toggleCustomerNotAvailable = this.toggleCustomerNotAvailable.bind(this);
    this.toggleProcedureIsFinished = this.toggleProcedureIsFinished.bind(this);
    this.toggleStillSomethingTodo = this.toggleStillSomethingTodo.bind(this);
    this.closeSignatureCustomer = this.closeSignatureCustomer.bind(this);
    this.clearCustomer = this.clearCustomer.bind(this);
    this.trimCustomer = this.trimCustomer.bind(this);
    this.autocompleteCustomer = this.autocompleteCustomer.bind(this);
    this.clearWorker = this.clearWorker.bind(this);
    this.trimWorker = this.trimWorker.bind(this);
    this.autocompleteWorker = this.autocompleteWorker.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleNextThingsTodoChange = this.handleNextThingsTodoChange.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.collapseSign = this.collapseSign.bind(this);
  }

  openSignatureWorker() {
    this.setState({ openSignatureWorker: true });
  }
  closeSignatureWorker() {
    this.setState({ openSignatureWorker: false });
  }

  openSignatureCustomer() {
    this.setState({ openSignatureCustomer: true });
  }
  toggleCustomerNotAvailable() {
    //console.log(this.state.signatureCustomer, this.sigPadCustomer);
    this.setState({ customerNotAvailable: !this.state.customerNotAvailable });
    let errors = this.validate();
    if (errors.length > 0) {
      this.setState({ hasErrors: errors });
    }
  }

  toggleProcedureIsFinished() {
    //console.log(this.state.signatureCustomer, this.sigPadCustomer);
    this.setState({
      procedureIsFinished: !this.state.procedureIsFinished,
      stillSomethingTodo:
        !this.state.procedureIsFinished === true ? false : this.state.stillSomethingTodo,
    });
  }

  toggleStillSomethingTodo() {
    //console.log(this.state.signatureCustomer, this.sigPadCustomer);
    this.setState({
      stillSomethingTodo: !this.state.stillSomethingTodo,
      procedureIsFinished:
        !this.state.stillSomethingTodo === true ? false : this.state.procedureIsFinished,
    });
  }

  handleNextThingsTodoChange = (event) => {
    if (event !== null) {
      //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  closeSignatureCustomer() {
    this.setState({ openSignatureCustomer: false });
  }

  clearCustomer = () => {
    //console.log(this.sigPadCustomer, typeof this.sigPadCustomer);
    this.setState({ signatureCustomer: null });
    if (this.sigPadCustomer !== {}) this.sigPadCustomer.clear();
  };
  trimCustomer = () => {
    this.setState({
      signatureCustomer: this.sigPadCustomer.getTrimmedCanvas().toDataURL('image/png'),
      openSignatureCustomer: false,
    });
  };
  autocompleteCustomer(event, value) {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`, value);
    if (!!event.target.name) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ signatureCustomerPrintLetters: value });
    }
  }

  clearWorker = () => {
    //console.log(this.sigPadWorker, typeof this.sigPadWorker);
    this.setState({ signatureWorker: null });
    if (this.sigPadWorker !== {}) this.sigPadWorker.clear();
  };

  trimWorker = () => {
    this.setState({
      signatureWorker: this.sigPadWorker.getTrimmedCanvas().toDataURL('image/png'),
      openSignatureWorker: false,
    });
  };
  autocompleteWorker(event, value) {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`, value);
    if (!!event.target.name) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ signatureWorkerPrintLetters: value });
    }
  }

  findPlaceOfPerformance = (addresses) => {
    let titleAddress = '';
    let local = [...Constants.tags.addresses.resident, ...Constants.tags.addresses.residentHidden];
    //let titleTags = [];
    addresses?.forEach((value, key) => {
      if (value.tags.findIndex((el) => local.includes(el.name.de)) >= 0) {
        titleAddress = value.address;
        //titleTags = value.tags;
      }
    });
    //return { address: titleAddress, tags: titleTags};
    return titleAddress;
  };

  zoom = (name) => {
    this.setState({
      ['zoom' + capitalizeFirstLetter(name)]: !this.state['zoom' + capitalizeFirstLetter(name)],
    });
  };
  dataCallback = (name, data) => {
    //console.log("dataCallback called", name, data);
    this.setState({ [name]: data }, () => this.zoom(name));
  };

  handleContactChange = (event, value) => {
    if (value && value.inputValue) {
      this.setState({ mailTo: value });
      return;
    }
    this.setState({ mailTo: value });
  };

  componentDidMount() {
    // Nothing
  }

  hideKeyboard() {
    //this set timeout needed for case when hideKeyborad
    //is called inside of 'onfocus' event handler
    console.log('Hiding Keyboard');
    setTimeout(function () {
      //creating temp field
      var field = document.createElement('input');
      field.setAttribute('type', 'text');
      //hiding temp field from peoples eyes
      //-webkit-user-modify is nessesary for Android 4.x
      field.setAttribute(
        'style',
        'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;',
      );
      document.body.appendChild(field);

      //adding onfocus event handler for out temp field
      field.onfocus = function () {
        //this timeout of 200ms is nessasary for Android 2.3.x
        setTimeout(function () {
          field.setAttribute('style', 'display:none;');
          setTimeout(function () {
            document.body.removeChild(field);
            document.body.focus();
          }, 14);
        }, 200);
      };
      //focusing it
      field.focus();
    }, 50);
  }

  async scrollToBottom() {
    if (!!this.state.sign && typeof this.el !== 'undefined') {
      await new Promise((resolve) =>
        setTimeout(resolve, this.props.Theme.transitions.duration.standard),
      );
      this.el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  collapseSign() {
    this.setState({ sign: !!!this.state.sign }, () => this.scrollToBottom());
  }
  formatEmails() {
    let tmp = [];
    if (!!this.props.job?.contacts && this.props.job?.contacts.length > 0) {
      for (var key in this.props.job?.contacts) {
        if (!!this.props.job?.contacts[key].contact.emails) {
          if (this.props.job?.contacts[key].contact.emails.length > 1) {
            for (var email in this.props.job?.contacts[key].contact.emails) {
              tmp.push({
                full:
                  this.props.job?.contacts[key].contact.salutation.name +
                  (!!this.props.job?.contacts[key].contact.title?.id
                    ? ' ' + this.props.job?.contacts[key].contact.title.name + ' '
                    : ' ') +
                  this.props.job?.contacts[key].contact.firstname +
                  ' ' +
                  this.props.job?.contacts[key].contact.name,
                mail: this.props.job?.contacts[key].contact.emails[email].email,
                pref: this.props.job?.contacts[key].contact.emails[email].pref,
                work: this.props.job?.contacts[key].contact.emails[email].work,
              });
            }
          } else if (this.props.job?.contacts[key].contact.emails.length === 1) {
            tmp.push({
              full:
                this.props.job?.contacts[key].contact.salutation.name +
                (!!this.props.job?.contacts[key].contact.title?.id
                  ? ' ' + this.props.job?.contacts[key].contact.title.name + ' '
                  : ' ') +
                this.props.job?.contacts[key].contact.firstname +
                ' ' +
                this.props.job?.contacts[key].contact.name,
              mail: this.props.job?.contacts[key].contact.emails[0].email,
              pref: this.props.job?.contacts[key].contact.emails[0].pref,
              work: this.props.job?.contacts[key].contact.emails[0].work,
            });
          }
        }
      }
    }
    return tmp;
  }

  render() {
    const { classes } = this.props;

    if (this.state.isSubmitting) {
      return (
        <Grid container direction='row' justifyContent='center' alignItems='stretch'>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      );
    }

    const filter = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.mail,
    });
    return (
      <>
        <Dialog
          fullScreen
          open={this.state.openSignatureCustomer}
          onClose={this.closeSignatureCustomer}
        >
          <div className={classes.overlayBox}>
            <Typography variant='h5' className={classes.title}>
              {TextDE.performanceReport.signatureCustomer}
              <IconButton
                className={classes.floatRight}
                edge='start'
                color='inherit'
                onClick={this.closeSignatureCustomer}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
            </Typography>
            <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
              <Grid item style={{ display: 'flex' }}>
                <Autocomplete
                  freeSolo
                  autoFocus
                  autoSelect={true}
                  fullWidth
                  disableClearable
                  name='signatureCustomerPrintLetters'
                  onChange={this.autocompleteCustomer}
                  value={this.state.signatureCustomerPrintLetters}
                  options={this.props.job?.contacts?.map((contact, index) => {
                    return contact.contact.salutation_id === 4
                      ? contact.contact.name
                      : contact.contact.firstname + ' ' + contact.contact.name;
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Name des Unterschreibenden'
                      margin='normal'
                      error={this.state.signatureCustomerPrintLetters.length < 1}
                      variant='outlined'
                      name='signatureCustomerPrintLetters'
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
                <IconButton
                  className={classes.hideKeyboard}
                  edge='start'
                  color='inherit'
                  onClick={this.hideKeyboard}
                  aria-label='close'
                >
                  <KeyboardHideIcon />
                </IconButton>
              </Grid>

              <Grid item>
                <Paper
                  variant='outlined'
                  square
                  className={!!this.state.signatureCustomer ? null : classes.redBorder}
                >
                  {!!this.state.signatureCustomer && (
                    <img
                      alt={this.state.signatureCustomerPrintLetters}
                      style={{
                        width: 'calc(100vw - 60px)',
                        maxHeight: window.innerHeight - 300,
                        height: 'auto',
                      }}
                      src={this.state.signatureCustomer}
                    />
                  )}
                  <SignatureCanvas
                    penColor='darkblue'
                    canvasProps={{
                      width: window.innerWidth - 32,
                      height:
                        window.innerWidth > window.innerHeight && window.innerHeight > 639
                          ? window.innerHeight - 300
                          : window.innerWidth - 32,
                      style: {
                        display: !!this.state.signatureCustomer ? 'none' : 'flex',
                      },
                      onBegin: this.hideKeyboard,
                    }}
                    ref={(ref) => {
                      this.sigPadCustomer = ref;
                    }}
                  />
                </Paper>
                <Typography variant='caption' gutterBottom color='textSecondary'>
                  Falls das unterschreiben nicht funktioniert (z.B. nach Smartphone drehen), bitte
                  einmalig "Löschen" klicken um das Feld neu zu laden.
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent='space-between' style={{ paddingTop: '1em' }}>
                  <Grid item>
                    <Button variant='outlined' color='secondary' onClick={this.clearCustomer}>
                      Löschen
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={this.trimCustomer}
                      disabled={this.state.signatureCustomerPrintLetters.length < 1}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Dialog
          fullScreen
          open={this.state.openSignatureWorker}
          onClose={this.closeSignatureWorker}
        >
          <div className={classes.overlayBox}>
            <Typography variant='h5' className={classes.title}>
              {TextDE.performanceReport.signatureWorker}
              <IconButton
                className={classes.floatRight}
                edge='start'
                color='inherit'
                onClick={this.closeSignatureWorker}
                aria-label='close'
              >
                <CloseIcon />
              </IconButton>
            </Typography>
            <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
              <Grid item style={{ display: 'flex' }}>
                <Autocomplete
                  freeSolo
                  autoFocus
                  autoSelect={true}
                  fullWidth
                  disableClearable
                  name='signatureWorkerPrintLetters'
                  value={this.state.signatureWorkerPrintLetters}
                  onChange={this.autocompleteWorker}
                  options={this.props.job?.users?.map((user, index) => {
                    return user.user.firstname + ' ' + user.user.lastname;
                  })}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name='signatureWorkerPrintLetters'
                      error={this.state.signatureWorkerPrintLetters.length < 1}
                      label='Name des Unterschreibenden'
                      margin='normal'
                      variant='outlined'
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
                <IconButton
                  className={classes.hideKeyboard}
                  edge='start'
                  color='inherit'
                  onClick={this.hideKeyboard}
                  aria-label='close'
                >
                  <KeyboardHideIcon />
                </IconButton>
              </Grid>

              <Grid item>
                <Paper
                  variant='outlined'
                  square
                  className={!!this.state.signatureCustomer ? null : classes.redBorder}
                >
                  {!!this.state.signatureWorker && (
                    <img
                      alt={this.state.signatureWorkerPrintLetters}
                      style={{
                        width: 'calc(100vw - 60px)',
                        maxHeight: window.innerHeight - 300,
                        height: 'auto',
                      }}
                      src={this.state.signatureWorker}
                    />
                  )}
                  <SignatureCanvas
                    penColor='darkblue'
                    canvasProps={{
                      width: window.innerWidth - 32,
                      height:
                        window.innerWidth > window.innerHeight
                          ? window.innerHeight - 300
                          : window.innerWidth - 32,
                      style: {
                        display: !!this.state.signatureWorker ? 'none' : 'flex',
                      },
                      onBegin: this.hideKeyboard,
                    }}
                    ref={(ref) => {
                      this.sigPadWorker = ref;
                    }}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12}>
                <Grid container justifyContent='space-between' style={{ marginTop: '1em' }}>
                  <Grid item>
                    <Button variant='outlined' color='secondary' onClick={this.clearWorker}>
                      Löschen
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={this.trimWorker}
                      disabled={this.state.signatureWorkerPrintLetters.length < 1}
                    >
                      Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Subject
          open={this.state.zoomSubject}
          onChange={this.dataCallback}
          data={this.state.subject}
          name='subject'
          uuid={this.props.job?.uuid}
        />

        <PerformanceDate
          open={this.state.zoomTarget_date}
          onChange={this.dataCallback}
          data={this.state.target_date}
          name='target_date'
          uuid={this.props.job?.uuid}
        />

        <Address
          open={this.state.zoomAddress}
          onChange={this.dataCallback}
          data={this.state.address}
          addresses={this.props.job?.addresses ?? []}
          name='address'
          uuid={this.props.job?.uuid}
        />

        <Subtitle
          open={this.state.zoomSubtitle}
          onChange={this.dataCallback}
          subtitle={this.state.subtitle}
          name='subtitle'
          uuid={this.props.job?.uuid}
        />

        <Comment
          open={this.state.zoomComment}
          onChange={this.dataCallback}
          subtitle={this.state.comment}
          name='comment'
          uuid={this.props.job?.uuid}
        />

        <Grid container spacing={2} justifyContent='space-between' className={classes.paper}>
          <Grid item xs={12} lg={12} onClick={() => this.zoom('target_date')}>
            <Typography
              variant='caption'
              component='h5'
              align='right'
              className={classes.alignScreenSizedLeft}
            >
              {TextDE.performanceReport.performanceDate}
            </Typography>
            <Typography
              variant='h5'
              style={{ margin: '0 8px' }}
              align='right'
              className={classes.alignScreenSizedCenter}
            >
              {this.state.target_date.format('DD.MM.YYYY')}
            </Typography>
            <Button
              size='small'
              className={classes.floatRight}
              endIcon={<CreateIcon />}
              variant='contained'
              color='primary'
            >
              {TextDE.performanceReport.performanceDate} {TextDE.edit}
            </Button>
          </Grid>

          <Grid item xs={12} lg={5} onClick={() => this.zoom('address')}>
            <Typography variant='caption'>{TextDE.address.placeOfPerformance}</Typography>

            <Typography
              variant='h5'
              style={{ margin: '0 8px', whiteSpace: 'pre' }}
              className={classes.alignScreenSizedCenter}
            >
              {this.state.signatureCustomerPrintLetters}
              {!!this.state.signatureCustomerPrintLetters && <br />}
              {this.state.address.route} {this.state.address.street_number} <br />
              {this.state.address.postal_code} {this.state.address.city?.locality} <br />
            </Typography>
            <Button
              size='small'
              className={classes.floatRight}
              endIcon={<CreateIcon />}
              variant='contained'
              color='primary'
            >
              {TextDE.address.placeOfPerformance} {TextDE.edit}
            </Button>
          </Grid>

          <Hidden mdDown>
            <Grid item xs={12} lg={5}>
              <Typography variant='caption'>{TextDE.performanceReport.executedBy}</Typography>
              <Typography variant='h5' align='right' style={{ margin: '0 8px', whiteSpace: 'pre' }}>
                {this.state.signatureWorkerPrintLetters} <br />
                {!!this.props.User.customer.company && (
                  <>
                    {this.props.User.customer.company?.name ?? ''}
                    <br />
                    {this.props.User.customer.company?.address?.route ?? ''}{' '}
                    {this.props.User.customer.company?.address?.street_number ?? ''}
                    <br />
                    {this.props.User.customer.company?.address?.postal_code ?? ''}{' '}
                    {this.props.User.customer.company?.address?.city?.locality ?? ''}
                  </>
                )}
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12} lg={12} onClick={() => this.zoom('subject')}>
            <Typography variant='caption'>{TextDE.procedure.title}</Typography>
            <Typography
              variant='h5'
              style={{ margin: '0 8px' }}
              className={classes.alignScreenSizedCenter}
            >
              {this.state.subject}
            </Typography>
            <Button
              size='small'
              className={classes.floatRight}
              endIcon={<CreateIcon />}
              variant='contained'
              color='primary'
            >
              {TextDE.procedure.title} {TextDE.edit}
            </Button>
          </Grid>

          <Grid item xs={12} lg={12} onClick={() => this.zoom('subtitle')}>
            <Typography variant='caption'>{TextDE.inbox.subtitle}</Typography>
            <Typography
              variant='body1'
              display='block'
              style={{ margin: '0 8px', whiteSpace: 'break-spaces' }}
              className={classes.alignScreenSizedCenter}
            >
              {this.state.subtitle}
            </Typography>
            <Button
              size='small'
              className={classes.floatRight}
              endIcon={<CreateIcon />}
              variant='contained'
              color='primary'
            >
              {TextDE.inbox.subtitle} {TextDE.edit}
            </Button>
          </Grid>

          <Grid item xs={12} lg={12} className={classes.setHeight}>
            <Todos
              open={this.state.zoomTodos}
              onChange={this.dataCallback}
              todos={this.state.todos}
              usersAll={this.state.usersAll}
              preSelectedUsers={this.props.preSelectedUsers}
              name='todos'
              uuid={this.props.job?.uuid}
              timeAsAllowance={!!this.props.job?.data?.timeAsAllowance}
              zoomFunction={() => this.zoom('todos')}
            />
          </Grid>

          <Grid item xs={12} lg={12} className={classes.setHeight}>
            <Parts
              open={this.state.zoomParts}
              onChange={this.dataCallback}
              parts={this.state.parts}
              name='parts'
              uuid={this.props.job?.uuid}
              zoomFunction={() => this.zoom('parts')}
            />
          </Grid>

          <Grid item xs={12} lg={12} onClick={() => this.zoom('comment')}>
            <Typography variant='caption'>{TextDE.performanceReport.comment}</Typography>
            <Typography
              variant='body1'
              display='block'
              style={{ margin: '0 8px', whiteSpace: 'break-spaces' }}
              className={classes.alignScreenSizedCenter}
            >
              {this.state.comment}
            </Typography>
            <Button
              size='small'
              className={classes.floatRight}
              endIcon={<CreateIcon />}
              variant='contained'
              color='primary'
            >
              {TextDE.performanceReport.comment} {TextDE.edit}
            </Button>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Divider />
            <FormControlLabel
              value={this.state.sign}
              id='finishEditing'
              name='finishEditing'
              control={<Checkbox color='primary' className={classes.checkboxSize} />}
              onChange={this.collapseSign}
              label={TextDE.performanceReport.confirmMessage}
              labelPlacement='end'
            />
          </Grid>

          <Collapse in={!!this.state.sign} component='div'>
            <Grid container spacing={0} justifyContent='space-between' alignItems='flex-end'>
              <Grid item xs={12} lg={5}>
                <Typography variant='caption'>
                  {TextDE.performanceReport.signatureWorker}
                </Typography>
                {!!this.state.signatureWorker && (
                  <>
                    <img
                      alt={this.state.signatureWorkerPrintLetters}
                      className={classes.sigImage}
                      src={this.state.signatureWorker}
                    />
                    <Divider />
                    <Typography variant='caption'>
                      {this.state.signatureWorkerPrintLetters}
                    </Typography>
                  </>
                )}
                <Button
                  size='small'
                  endIcon={<CreateIcon />}
                  className={classes.floatRight}
                  variant='contained'
                  color='primary'
                  fullWidth
                  onClick={this.openSignatureWorker}
                >
                  {TextDE.performanceReport.signatureWorker}
                </Button>
              </Grid>

              <Grid item xs={12} lg={5}>
                <Typography variant='caption'>
                  {TextDE.performanceReport.signatureCustomer}
                </Typography>
                {!!!this.state.signatureCustomer && !!!this.state.signatureCustomerPrintLetters && (
                  <Typography align='center' display='block'>
                    <FormControlLabel
                      value={this.state.customerNotAvailable}
                      checked={this.state.customerNotAvailable}
                      id='customerNotAvailable'
                      name='customerNotAvailable'
                      control={<Checkbox color='primary' className={classes.checkboxSize} />}
                      onChange={this.toggleCustomerNotAvailable}
                      label={TextDE.performanceReport.customerNotAvailable}
                      labelPlacement='end'
                      classes={{ label: classes.labelAlignLeft }}
                    />
                  </Typography>
                )}

                {!!this.state.signatureCustomer && !!!this.state.customerNotAvailable && (
                  <>
                    <img
                      alt={this.state.signatureCustomerPrintLetters}
                      className={classes.sigImage}
                      src={this.state.signatureCustomer}
                    />
                    <Divider />
                    <Typography variant='caption'>
                      {this.state.signatureCustomerPrintLetters}
                    </Typography>
                  </>
                )}

                <Button
                  size='small'
                  endIcon={<CreateIcon />}
                  className={classes.floatRight}
                  variant='contained'
                  fullWidth
                  color='primary'
                  disabled={!!this.state.customerNotAvailable}
                  onClick={this.openSignatureCustomer}
                >
                  {TextDE.performanceReport.signatureCustomer}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  id='contactselect'
                  name='uuid'
                  required
                  freeSolo
                  multiple
                  value={this.state.mailTo || []}
                  onChange={this.handleContactChange}
                  includeInputInList={true}
                  disableClearable
                  options={this.state.contactsEmails}
                  renderOption={(option) => {
                    return (
                      <ListItem component='div' key={option.mail}>
                        <ListItemAvatar>
                          <Badge
                            color='primary'
                            badgeContent={option.pref ? <StarIcon className={classes.xs} /> : 0}
                            overlap='circular'
                            variant='standard'
                          >
                            <Avatar variant='rounded' className={classes.small}>
                              {option.work ? <WorkIcon /> : <AlternateEmailIcon />}
                            </Avatar>
                          </Badge>
                        </ListItemAvatar>
                        <ListItemText primary={option.mail} secondary={option.full} />
                      </ListItem>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={TextDE.performanceReport.labelSendCopy}
                      helperText={
                        !!this.state.customerNotAvailable && this.state.mailTo.length === 0
                          ? TextDE.performanceReport.helperSendCopyNeeded
                          : TextDE.performanceReport.helperSendCopy
                      }
                      error={!!this.state.customerNotAvailable && this.state.mailTo.length === 0}
                      required={!!this.state.customerNotAvailable}
                      fullWidth
                      name='newContact'
                    />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        mail: params.inputValue,
                        full: `"${params.inputValue} als Empfänger hinzufügen`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.mail;
                  }}
                />
              </Grid>
              {!!this.state.hasError && (
                <Grid item xs={12} lg={12}>
                  {typeof this.state.hasError === 'string' ? (
                    <Alert severity='error'>{this.state.hasError}</Alert>
                  ) : typeof this.state.hasError.map === 'function' ? (
                    this.state.hasError?.map((value) => <Alert severity='error'>{value}</Alert>)
                  ) : (
                    <Alert severity='error'>Unbekannter Fehler!</Alert>
                  )}
                </Grid>
              )}

              <Grid item xs={12} lg={6} className={classes.centerGridItem}>
                <Grid container direction='column' justifyContent='flex-start' alignItems='center'>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={this.state.stillSomethingTodo}
                      control={
                        <Switch
                          checked={this.state.procedureIsFinished}
                          onChange={this.toggleProcedureIsFinished}
                          name='procedureIsFinished'
                          color='primary'
                        />
                      }
                      label={TextDE.performanceReport.procedureIsFinished}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      disabled={this.state.procedureIsFinished}
                      control={
                        <Switch
                          checked={this.state.stillSomethingTodo}
                          onChange={this.toggleStillSomethingTodo}
                          name='stillSomethingTodo'
                          color='primary'
                        />
                      }
                      label={TextDE.performanceReport.stillSomethingTodo}
                    />
                  </Grid>
                  {!!this.state.stillSomethingTodo && (
                    <Grid item xs={12} style={{ width: '100%' }}>
                      <TextField
                        name='nextThingsTodo'
                        label={TextDE.performanceReport.nextThingsTodo}
                        classes={{ root: classes.input }}
                        onChange={this.handleNextThingsTodoChange}
                        variant='outlined'
                        fullWidth={true}
                        multiline
                        minRows='5'
                        type='text'
                        margin='none'
                        required={!!this.state.stillSomethingTodo}
                        value={this.state.nextThingsTodo}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
          <Grid
            item
            xs={12}
            lg={12}
            ref={(el) => {
              this.el = el;
            }}
          >
            <Button
              endIcon={!!this.state.sign ? <CreateIcon /> : <SaveIcon />}
              className={classes.floatRight}
              disabled={this.validate().length > 0}
              variant='contained'
              color='primary'
              onClick={!!this.state.sign ? this.submitPerformanceReport : this.props.closeFunction}
            >
              {!!this.state.sign ? TextDE.performanceReport.confirmButton : TextDE.save}
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PerformanceReportNew);
