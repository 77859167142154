import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import ContactDetail from '../../Contact/ContactDetail';
import { Constants } from '../../../lib/Constants';
import Alert from '@material-ui/lab/Alert';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import Box from '@material-ui/core/Box';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import { DE as TextDE } from '../../../lib/Text';

const iconHeight = 64;

const styles = (theme) => ({
  backdrop: {
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#fff',
    zIndex: '1001',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  noPad: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  inherit: {
    boxShadow: 'inherit',
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  inheritBackground: {
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  chip: {
    maxWidth: '100%',
  },
  card: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  cardContact: {
    borderRight: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    borderBottom: 'none',
    overflow: 'visible',
    backgroundColor: '#aed581',
    minHeight: '106px',
  },
  cardContactCollapsed: {
    justifyContent: 'start',
    alignContent: 'center',
    display: 'flex',
  },
  maximize4: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(-50%, 2px)',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      marginLeft: '100.5%',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
  maximize: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(calc(-33.333333% - ' + theme.spacing(0.75) + 'px), 2px)',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
  insetlist: {
    flexGrow: 1,
    padding: '0 0 0 ' + theme.spacing(4) + 'px',
    '&>li': {
      borderLeft: '2px solid ' + theme.palette.divider,
      '&>div>p': {
        fontSize: '90%',
      },
      '&>div>div>span': {
        fontSize: '90%',
      },
    },
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  button: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '60vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      //right: iconHeight/1.5+30+'px',
      //marginTop: '-'+iconHeight/1.5+'px',
      top: '10vh',
      left: '1vw',
      maxWidth: '98vw',
      minWidth: '98vw',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: '1002',
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  cardHeaderContent: {
    flex: '1 1 auto',
    width: 0,
    height: 'auto',
    paddingBottom: 0,
  },
  cardHeaderAction: {
    margin: theme.spacing(2, -1, 0, 0),
  },
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
    width: '100%',
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  fullwidth: {
    width: '100%',
  },
});

function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}

class ContactCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleContact: false,
      contactsAll: [],
      contacts: [],
      cardContactNew: false,
      form: {
        model: this.props.model || 'procedures',
        uuid: this.props.uuid || '',
        isSubmitting: false,
        hasError: false,
        contact: null,
        newContact: false,
        tags: [],
      },
      selectedContact: null,
      toDelete: false,
      toShare: null,
    };

    this.handleCardContactExpandClick = this.handleCardContactExpandClick.bind(this);
    this.handleCardContactNewClick = this.handleCardContactNewClick.bind(this);

    this.handleContactAddAddress = this.handleContactAddAddress.bind(this);
    this.handleContactEdit = this.handleContactEdit.bind(this);
    this.handleContactDelete = this.handleContactDelete.bind(this);
    this.callback = this.callback.bind(this);

    this.shareDialog = this.shareDialog.bind(this);
    this.closeShareDialog = this.closeShareDialog.bind(this);

    this.handleContactToConnectAdd = this.handleContactToConnectAdd.bind(this);
    this.handleContactToConnectRemove = this.handleContactToConnectRemove.bind(this);

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
  }

  componentDidMount() {
    let titleContact = false;
    let local = [...Constants.tags.contacts.resident, ...Constants.tags.contacts.residentHidden];
    let tmp = [];
    this.props.contacts.forEach((value, key) => {
      let _tmp = {
        uuid: value.contact.uuid,
        deleted: value.contact.deleted_at === null ? false : true,
        displayName:
          value.contact.salutation.name +
          (value.contact.salutation_id === 4
            ? ' ' + value.contact.name
            : (!!value.contact.title?.id ? ' ' + value.contact.title.name + ' ' : ' ') +
              value.contact.firstname +
              ' ' +
              value.contact.name),
        addressId: value.contact?.address?.id,
        addressObject: value.contact?.address,
        address: (!!value.contact.address?.id
          ? value.contact.address.route +
            ' ' +
            value.contact.address.street_number +
            ', ' +
            value.contact.address.postal_code +
            ' ' +
            value.contact.address.city.locality
          : ''
        ).trim(),
        email: !!value.contact.emails ? value.contact.emails.map((e) => e.email).join(', ') : '',
        phone: !!value.contact.phones ? value.contact.phones.map((e) => e.number).join(', ') : '',
        isUser: !!value.contact.user_id,
        isConnectUser:
          this.props.linkedConnectUsers?.findIndex((element) => {
            if (element.connect_uuid !== false) {
              return element.connect_uuid === value.contact.connect_uuid;
            } else {
              return element.contact_uuid === value.contact.uuid;
            }
            //return false;
          }) >= 0,
        tags: value.tags.map((tag) => tag.name.de),
      };
      if (_tmp.tags.findIndex((el) => local.includes(el)) >= 0) {
        titleContact = _tmp;
      }
      tmp.push(_tmp);
    });

    if (titleContact === false && tmp.length > 0) {
      titleContact = tmp[0];
    }
    this.setState({ contacts: tmp, titleContact: titleContact });

    fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = this.state.contactsAll;
        for (var key in json) {
          tmp.push({
            uuid: json[key].uuid,
            displayName:
              json[key].salutation.name +
              (json[key].salutation_id === 4
                ? ' ' + json[key].name
                : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                  json[key].firstname +
                  ' ' +
                  json[key].name),
            address: (!!json[key].address?.id
              ? json[key].address.route +
                ' ' +
                json[key].address.street_number +
                ', ' +
                json[key].address.postal_code +
                ' ' +
                json[key].address.city.locality
              : ''
            ).trim(),
            addressId: json[key].address?.id,
            addressObject: json[key].address,
            email: !!json[key].emails ? json[key].emails.map((e) => e.email).join(', ') : '',
            phone: !!json[key].phones ? json[key].phones.map((e) => e.number).join(', ') : '',
            isUser: !!json[key].user_id,
            tags: json[key].tags.map((tag) => tag.name.de),
          });
        }
        this.setState({ contactsAll: tmp });
      })
      .catch((error) => {
        return false;
      });
  }

  shareDialog(value) {
    if (!!this.props.canInviteUsers && !!value.uuid && typeof value.uuid === 'string') {
      this.setState({ toShare: value });
    }
  }
  closeShareDialog() {
    this.setState({ toShare: false });
  }

  handleContactToConnectRemove = async () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });
    let formData = {};
    formData['contact'] = this.state.toShare.uuid;
    formData['_method'] = 'PUT';
    formData['shareToConnect'] = false;
    formData['context'] = 'contacts';
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint + '/api/' + this.state.form.model + '/' + formData.uuid + '/' + formData.context;
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      })
      .catch((e) => console.error(e));

    if (response?.uuid === this.state.toShare.uuid) {
      let tmp = this.state.contacts;
      let contactIndex = tmp.findIndex((element) => element.uuid === this.state.toShare.uuid);
      tmp[contactIndex]['isConnectUser'] = false;
      this.setState(
        {
          ...this.state,
          contacts: tmp,
          toShare: false,
          form: {
            model: this.state.form.model,
            uuid: this.state.form.uuid,
            isSubmitting: false,
            hasError: false,
            contact: null,
            newContact: false,
            tags: [],
          },
        },
        this.props.callbackFunction(21),
      );
      return;
    }
    this.setState(
      {
        ...this.state,
        toShare: { error: true },
      },
      this.props.callbackFunction(21),
    );
  };

  handleContactToConnectAdd = async () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });
    let formData = {};
    formData['contact'] = this.state.toShare.uuid;
    formData['_method'] = 'PUT';
    formData['shareToConnect'] = true;
    formData['context'] = 'contacts';
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint + '/api/' + this.state.form.model + '/' + formData.uuid + '/' + formData.context;
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      })
      .catch((e) => console.error(e));

    if (response?.uuid === this.state.toShare.uuid) {
      let tmp = this.state.contacts;
      let contactIndex = tmp.findIndex((element) => element.uuid === this.state.toShare.uuid);
      tmp[contactIndex]['isConnectUser'] = true;
      this.setState(
        {
          ...this.state,
          contacts: tmp,
          toShare: false,
          form: {
            model: this.state.form.model,
            uuid: this.state.form.uuid,
            isSubmitting: false,
            hasError: false,
            contact: null,
            newContact: false,
            tags: [],
          },
        },
        this.props.callbackFunction(21),
      );
      return;
    }
    this.setState(
      {
        ...this.state,
        toShare: { error: true },
      },
      this.props.callbackFunction(21),
    );
  };

  handleCardContactExpandClick = () => {
    this.props.toggleFunction('ContactCard');
    //this.setState({ cardContactExpand: !this.props.open });
  };
  handleCardContactNewClick = () => {
    this.setState({
      ...this.state,
      cardContactNew: !this.state.cardContactNew,
      form: {
        ...this.state.form,
        model: this.state.form.model,
        uuid: this.state.form.uuid,
        isSubmitting: false,
        hasError: false,
        contact: null,
        newContact: false,
        tags: [],
      },
    });
  };

  handleContactChange = (event, value) => {
    //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, value);
    if (value && value.inputValue) {
      this.setState({ form: { ...this.state.form, contact: value } });
      return;
    }

    if (typeof value !== 'undefined' && !!value.uuid) {
      let addressTags = [];
      if (!!value.address) {
        if (
          value.tags.findIndex((el) =>
            [
              ...Constants.tags.contacts.resident,
              ...Constants.tags.contacts.residentHidden,
            ].includes(el),
          ) >= 0
        ) {
          addressTags.push('Leistungsort');
        }
        if (
          value.tags.findIndex((el) =>
            [...Constants.tags.contacts.invoice, ...Constants.tags.contacts.invoiceHidden].includes(
              el,
            ),
          ) >= 0
        ) {
          addressTags.push('Rechnungsadresse');
        }
        this.setState({
          hint:
            'Zusätzlich wird die Adresse "' +
            value.address +
            '" als ' +
            addressTags.join(', ') +
            ' hinterlegt.',
          addressTags: addressTags,
          form: {
            ...this.state.form,
            contact: value,
            newContact: false,
            tags: value.tags || [],
          },
        });
      } else {
        this.setState({
          hint: false,
          addressTags: null,
          form: {
            ...this.state.form,
            contact: value,
            newContact: false,
            tags: value.tags || [],
          },
        });
      }
    } else if (Array.isArray(value)) {
      let addressTags = [];
      if (!!this.state.form?.contact?.address) {
        if (
          value.findIndex((el) =>
            [
              ...Constants.tags.contacts.resident,
              ...Constants.tags.contacts.residentHidden,
            ].includes(el),
          ) >= 0
        ) {
          addressTags.push('Leistungsort');
        }
        if (
          value.findIndex((el) =>
            [...Constants.tags.contacts.invoice, ...Constants.tags.contacts.invoiceHidden].includes(
              el,
            ),
          ) >= 0
        ) {
          addressTags.push('Rechnungsadresse');
        }
      }
      if (addressTags.length > 0) {
        this.setState({
          hint:
            'Zusätzlich wird die Adresse "' +
            this.state.form?.contact?.address +
            '" als ' +
            addressTags.join(', ') +
            ' hinterlegt.',
          addressTags: addressTags,
          form: { ...this.state.form, tags: value },
        });
      } else {
        this.setState({
          hint: false,
          addressTags: null,
          form: { ...this.state.form, tags: value },
        });
      }
      //} else {
      //	// Kein Tag/ Kein Kontalt -> Neuer Kontakt!
      //	//console.log("should be here")
      //	this.setState({ form: { ...this.state.form, contact: value, newContact: value}})
      //}
    } else {
      if (
        event.target.name === 'newContact' &&
        !!this.state.form?.contact?.displayName &&
        event.target.value !== this.state.form?.contact?.displayName
      ) {
        this.setState({
          form: {
            ...this.state.form,
            contact: {
              inputValue: event.target.value,
              uuid: 'false',
              tags: [],
              displayName: event.target.value,
              address: '',
              email: '',
              isUser: false,
              phone: '',
              newContact: true,
              deleted: false,
            },
          },
        });
      } else if (!!this.state.form.contact?.newContact) {
        this.setState({
          form: {
            ...this.state.form,
            contact: {
              ...this.state.form.contact,
              [event.target.name]: event.target.value,
            },
          },
        });
      } else {
        this.setState({
          form: { ...this.state.form, [event.target.name]: event.target.value },
        });
      }
    }
  };

  handleContactAdd = async () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });

    // Create new User or use existing
    let formData = {};
    if (this.state.form.contact.uuid === 'false' && !!this.state.form.contact.newContact) {
      formData['name'] = this.state.form.contact.inputValue;
      formData['phone'] = this.state.form.contact.phone;
      formData['email'] = this.state.form.contact.email;
      formData['contact'] = 'false';
    } else {
      formData['contact'] = this.state.form.contact.uuid;
    }
    formData['_method'] = 'PUT';
    formData['tags'] = this.state.form.tags || [];
    formData['context'] = 'contacts';
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      });

    let control = false;
    let tmp = this.state.contacts;
    let contact = response;
    contact.tags = this.state.form.tags || [];

    for (var i = tmp.length - 1; i >= 0; i--) {
      if (tmp[i].uuid === response.uuid) {
        tmp[i] = { ...tmp[i], tags: arrayUnique([...contact.tags]) };
        control = true;
      }
    }

    if (!control) {
      let hasContact = this.state.contactsAll.find((element) => element.uuid === contact.uuid);
      //console.log(hasContact);
      if (!!hasContact) {
        contact = { ...hasContact, tags: arrayUnique([...contact.tags]) };
      } else {
        contact = this.state.form.contact;
        contact.displayName = contact.inputValue;
        contact.uuid = response.uuid;
        contact.tags = this.state.form.tags || [];
      }
      tmp.push(contact);
    }

    if (!!this.state.addressTags && this.state.addressTags.length > 0 && !!contact.address) {
      // Create new User or use existing
      let formData = {};
      formData['_method'] = 'PUT';
      if (contact.addressId >= 0) {
        formData['address_id'] = contact.addressId;
      }

      formData['address'] = {
        street_number: contact.addressObject.street_number,
        route: contact.addressObject.route,
        locality: contact.addressObject.city.locality,
        administrative_area_level_1: contact.addressObject.city.state,
        state: contact.addressObject.city.state,
        country: contact.addressObject.city.country,
        postal_code: contact.addressObject.postal_code,
      };

      formData['tags'] = this.state.addressTags;
      formData['context'] = 'addresses';
      formData['uuid'] = this.state.form.uuid;

      //let control = false;
      //let tmp = this.state.addresses;
      //let address = {address: this.state.form.address, tags: this.state.form.tags, link: encodeURI((!!this.state.form.address) ? this.state.form.address?.route +'+'+ this.state.form.address?.street_number +'+'+ this.state.form.address?.postal_code + '+' + this.state.form.address?.city?.locality : '')};

      let endpoint = process.env.REACT_APP_API_URL;
      endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
      let response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify(formData),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then(function (response) {
          return response;
        })
        .catch((error) =>
          this.setState({
            hasError: error,
            model: this.state.form.model,
            uuid: this.state.form.uuid,
            isSubmitting: false,
          }),
        );
      this.setState(
        {
          ...this.state,
          hint: false,
          addressTags: null,
          contacts: tmp,
          form: {
            model: this.state.form.model,
            uuid: this.state.form.uuid,
            isSubmitting: false,
            hasError: false,
            contact: null,
            newContact: false,
            tags: [],
          },
        },
        this.props.callbackFunction(response),
      );
      //address.id = response.id;
      //for (var i = tmp.length - 1; i >= 0; i--) {
      //	if ( tmp[i].id === response.id ) {
      //		tmp[i] = address;
      //		control = true;
      //	}
      //}
      //if (!control) {
      //	tmp.push(address);
      //}
      //
      //			//tmp.forEach( (element, index) => {
      //			//	let control = 0;
      //			//	['Zielort', 'Treffpunkt', 'Leistungsort', 'Reparaturstelle'].forEach( tag =>  control = (element.tags.includes(tag)) ? control + 1 : control );
      //			//	if (control === 0) {
      //			//		tmp[index].link = false;
      //			//	}
      //})
    } else {
      this.setState(
        {
          ...this.state,
          hint: false,
          addressTags: null,
          contacts: tmp,
          form: {
            model: this.state.form.model,
            uuid: this.state.form.uuid,
            isSubmitting: false,
            hasError: false,
            contact: null,
            newContact: false,
            tags: [],
          },
        },
        () => this.props.callbackFunction(false),
      );
    }
    if (tmp.length >= 1 && this.state.titleContact === false) {
      this.setState({ titleContact: tmp[0] });
    }
  };

  handleContactAddAddress = async (contact, address) => {
    let formData = {};
    formData['uuid'] = contact.uuid;
    formData['_method'] = 'PUT';
    //NEEDED!
    formData['address_id'] = address.id;

    let endpoint = process.env.REACT_APP_API_URL + '/api/contacts/address/' + formData.uuid;
    //console.log(formData, endpoint);

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!!response !== false) {
          this.setState({ isSubmitting: false });
          this.callback();
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
    return response;
  };

  handleContactEdit = (value) => {
    this.setState({
      ...this.state,
      cardContactNew: true,
      form: {
        ...this.state.form,
        model: this.state.form.model,
        uuid: this.state.form.uuid,
        isSubmitting: false,
        hasError: false,
        contact: value,
        newContact: false,
        tags: value.tags,
      },
    });
  };

  deleteDialog(value) {
    if (!!value.uuid && typeof value.uuid === 'string' && !!value.tags) {
      this.setState({ toDelete: value });
    }
  }
  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  handleContactDelete() {
    let contacts = this.state.contacts;
    contacts = contacts.filter((contact) => contact.uuid !== this.state.toDelete.uuid);
    let titleContact =
      this.state.titleContact?.uuid === this.state.toDelete.uuid ? false : this.state.titleContact;

    this.setState({ contacts: contacts, titleContact: titleContact }, () => {
      if (contacts.length >= 1 && titleContact === false) {
        this.setState({ titleContact: contacts[0] });
      }
    });

    // Drop User from procedure
    let formData = {};
    formData['_method'] = 'DELETE';
    formData['tags'] = this.state.toDelete.tags;
    formData['context'] = 'contacts';
    formData['contact'] = this.state.toDelete.uuid;
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return true;
      });
    this.setState({ toDelete: false }, this.props.callbackFunction(false));
  }

  handleContactClick = (value) => {
    this.setState({ selectedContact: value });
  };

  handleContactClose = () => {
    this.setState({ selectedContact: null });
  };

  callback = () => {
    //console.log("callback");
    let tmp = this.state.selectedContact;
    this.handleContactClose();
    this.handleContactClick(tmp);
  };

  render() {
    const filter = createFilterOptions();
    const { classes } = this.props;

    let localContact = [
      ...Constants.tags.contacts.resident,
      ...Constants.tags.contacts.residentHidden,
    ];
    let paymentContact = [
      ...Constants.tags.contacts.invoice,
      ...Constants.tags.contacts.invoiceHidden,
    ];
    let localAddress = [
      ...Constants.tags.addresses.resident,
      ...Constants.tags.addresses.residentHidden,
    ];
    let paymentAddress = [
      ...Constants.tags.addresses.invoice,
      ...Constants.tags.addresses.invoiceHidden,
    ];

    return (
      <Box ml={{ xs: 0, sm: 0, md: 0, lg: 1, xl: 1 }} mt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }}>
        <Dialog open={!!this.state.toShare} onClose={this.closeShareDialog}>
          <DialogTitle>Vorgang Teilen?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!!this.state.toShare?.isConnectUser
                ? 'Den Zugriff für ' +
                  this.state.toShare?.displayName +
                  ' über die FLiXWORKER Connect App entziehen?'
                : 'Wollen Sie diesen Vorgang mit ' +
                  this.state.toShare?.displayName +
                  ' über die FLiXWORKER Connect App teilen?'}
              {!!this.state.toShare?.error ??
                'Es gab ein Problem! Bitte dieses Fenster schließen und erneut probieren!'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeShareDialog} color='primary'>
              Abbrechen
            </Button>
            {!!this.state.toShare?.isConnectUser && !!!this.state.toShare?.error ? (
              <Button
                variant='contained'
                onClick={this.handleContactToConnectRemove}
                color='secondary'
              >
                {' '}
                Ja, Zugriff aufheben!
              </Button>
            ) : (
              <Button
                variant='contained'
                onClick={this.handleContactToConnectAdd}
                color='secondary'
              >
                {' '}
                Ja, Teilen!
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>{TextDE.contact.deleteTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.contact.deleteText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleContactDelete} color='secondary'>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Card
          raised={false}
          className={clsx(classes.cardContact, {
            [classes.cardContactCollapsed]: !this.props.open,
          })}
        >
          {!!this.state.titleContact && !this.props.open ? (
            <CardHeader
              classes={{
                content: classes.cardHeaderContent,
                root: !this.props.open ? classes.cardHeaderRoot : null,
                action: classes.cardHeaderAction,
              }}
              avatar={
                <IconButton
                  onClick={() => this.handleContactClick(this.state.titleContact)}
                  size='small'
                >
                  <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                    <ContactPhoneIcon />
                  </Avatar>
                </IconButton>
              }
              action={
                <Badge
                  overlap='circular'
                  max={9}
                  badgeContent={this.state.contacts.length}
                  color='secondary'
                >
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.props.open,
                    })}
                    onClick={this.handleCardContactExpandClick}
                    aria-expanded={this.props.open}
                    aria-label='show more'
                  >
                    {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Badge>
              }
              disableTypography={false}
              titleTypographyProps={{
                onClick: () => this.handleContactClick(this.state.titleContact),
                noWrap: false,
                variant: 'subtitle1',
              }}
              subheaderTypographyProps={{
                onClick: () => this.handleContactClick(this.state.titleContact),
                noWrap: true,
              }}
              title={this.state.titleContact.displayName}
              subheader={this.state.titleContact.tags.map((item, index) => (
                <Chip
                  size='small'
                  key={index}
                  onClick={() => this.handleContactClick(this.state.titleContact)}
                  className={classes.chip}
                  label={item}
                />
              ))}
            />
          ) : (
            <CardHeader
              classes={{
                content: classes.cardHeaderContent,
                root: !this.props.open ? classes.cardHeaderRoot : null,
                action: classes.cardHeaderAction,
              }}
              avatar={
                <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                  <ContactPhoneIcon />
                </Avatar>
              }
              action={
                <Badge
                  overlap='circular'
                  max={9}
                  badgeContent={this.state.contacts.length}
                  color='secondary'
                >
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.props.open,
                    })}
                    onClick={this.handleCardContactExpandClick}
                    aria-expanded={this.props.open}
                    aria-label='show more'
                  >
                    {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Badge>
              }
              titleTypographyProps={{ noWrap: true, variant: 'h6' }}
              title={TextDE.contact.labelHelper(this.state.contacts.length)}
            />
          )}
          <Collapse
            classes={{
              wrapperInner: classes.inheritBackground,
              wrapper: classes.inheritBackground,
            }}
            className={clsx(classes.inheritBackground, {
              [classes.maximize]:
                this.props.open &&
                !this.props.canInviteUsers &&
                this.props.expandedWidth >= this.props.Theme.breakpoints.values['md'],
              [classes.maximize4]:
                this.props.open &&
                this.props.canInviteUsers &&
                this.props.expandedWidth >= this.props.Theme.breakpoints.values['md'],
            })}
            style={{
              width:
                this.props.expandedWidth > 599 && this.props.open
                  ? this.props.expandedWidth - 38
                  : '100%',
            }}
            in={this.props.open}
            timeout={{
              enter: 214,
              exit: this.props.expandedWidth < 599 ? 106 : 0,
            }}
            unmountOnExit
          >
            <CardContent className={classes.noPad}>
              <List className={classes.list}>
                {this.state.contacts.map((value) => {
                  const labelId = `list-contacts-label-${value.uuid}`;
                  let addresses = [];
                  if (!!value.addressObject && !!value.addressObject?.id) {
                    addresses.push({
                      address: value.addressObject,
                      isConnected: true,
                      tags: ['Gespeicherte Adresse'],
                    });
                  }
                  if (this.props.addresses.length > 0) {
                    let local = value.tags?.findIndex((el) => localContact.includes(el));
                    let payment =
                      local >= 0
                        ? local
                        : value.tags?.findIndex((el) => paymentContact.includes(el));

                    if (payment >= 0) {
                      this.props.addresses.forEach((element, index) => {
                        let add = element.tags?.findIndex((el) =>
                          localAddress.includes(!!el?.name?.de ? el.name.de : el),
                        );
                        add =
                          add >= 0
                            ? add
                            : element.tags?.findIndex((el) => paymentAddress.includes(el.name.de));
                        if (add >= 0) {
                          if (element.address_id === value.addressId) {
                            addresses[0].tags = [...addresses[0].tags, ...element.tags];
                          } else {
                            addresses.push(element);
                          }
                        }
                      });
                    }
                  }

                  return (
                    <div key={labelId}>
                      <ListItem
                        key={labelId}
                        className={value.deleted ? classes.deleted : null}
                        button
                        onClick={() => this.handleContactClick(value)}
                      >
                        <ListItemText
                          id={labelId}
                          disableTypography={true}
                          primary={<Typography variant='body1'>{value.displayName}</Typography>}
                          secondary={
                            <>
                              {' '}
                              {value.deleted && (
                                <Chip
                                  size='small'
                                  key={999}
                                  className={classes.chip}
                                  label='GELÖSCHT!'
                                />
                              )}{' '}
                              {value.tags.map((item, index) => (
                                <Chip
                                  size='small'
                                  key={index}
                                  className={classes.chip}
                                  label={item}
                                />
                              ))}{' '}
                            </>
                          }
                        />

                        <ListItemSecondaryAction>
                          <Tooltip
                            title={
                              !!value.isConnectUser
                                ? 'Der Kunde kann die laufende Anfrage auf FLiXWORKER Connect einsehen'
                                : 'Den Kontakt in die laufende Anfrage auf FLiXWORKER Connect einladen und so die Kommunikation NOCH einfacher machen!'
                            }
                          >
                            <IconButton
                              color='primary'
                              disableRipple
                              onClick={() => this.shareDialog(value)}
                              aria-label={
                                !!value.isConnectUser
                                  ? 'Der Kunde kann die laufende Anfrage auf FLiXWORKER Connect einsehen'
                                  : 'Den Kontakt in die laufende Anfrage auf FLiXWORKER Connect einladen und so die Kommunikation NOCH einfacher machen!'
                              }
                            >
                              <SettingsEthernetIcon
                                color={!!value.isConnectUser ? 'primary' : 'error'}
                              />
                            </IconButton>
                          </Tooltip>

                          <IconButton
                            color='default'
                            edge='end'
                            aria-label='edit'
                            onClick={() => this.handleContactEdit(value)}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            color='default'
                            edge='end'
                            aria-label='delete'
                            onClick={() => this.deleteDialog(value)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                      {addresses.length > 0 ? (
                        <List className={classes.insetlist} component='div' disablePadding>
                          {addresses.map((address, aIndex) => {
                            return (
                              <ListItem key={aIndex}>
                                <ListItemText
                                  disableTypography={true}
                                  primary={
                                    <Typography variant='body1'>
                                      {address.address.route +
                                        ' ' +
                                        address.address.street_number +
                                        ', ' +
                                        address.address.postal_code +
                                        ' ' +
                                        address.address.city.locality}
                                    </Typography>
                                  }
                                  secondary={address.tags?.map((subItem, subIndex) => (
                                    <Chip
                                      size='small'
                                      key={subIndex}
                                      className={classes.chip}
                                      label={subItem?.name?.de ?? subItem}
                                    />
                                  ))}
                                />
                                {!!address.isConnected ? null : (
                                  <ListItemSecondaryAction>
                                    <Button
                                      variant='text'
                                      color='primary'
                                      startIcon={<AddLocationIcon />}
                                      onClick={() => {
                                        address.isConnected = true;
                                        address.tags.push('Neu Gespeicherte Adresse');
                                        this.handleContactAddAddress(value, address.address);
                                      }}
                                    >
                                      {TextDE.assign}
                                    </Button>
                                  </ListItemSecondaryAction>
                                )}
                              </ListItem>
                            );
                          })}
                        </List>
                      ) : null}
                    </div>
                  );
                })}
              </List>
              <Box component='div' p={2}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={this.handleCardContactNewClick}
                  aria-expanded={this.state.cardContactNew}
                  aria-label={TextDE.add}
                  fullWidth={true}
                >
                  {!!this.state.cardContactNew ? <CloseIcon /> : <AddIcon />}
                  {!!this.state.cardContactNew ? TextDE.close : TextDE.add}
                </Button>
              </Box>
              <Collapse in={this.state.cardContactNew} timeout='auto' unmountOnExit>
                <Divider />
                {this.state.form.isSubmitting ? (
                  <center>
                    <CircularProgress />
                  </center>
                ) : (
                  <Grid container className={classes.root} spacing={0}>
                    <Grid item xs={12}>
                      <Autocomplete
                        id='contactselect'
                        name='uuid'
                        required
                        freeSolo
                        options={this.state.contactsAll}
                        renderOption={(option) => {
                          return (
                            <ListItem component='div' key={option.uuid}>
                              <ListItemAvatar>
                                <Avatar variant='rounded' className={classes.small}>
                                  {option.displayName}
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                disableTypography={true}
                                primary={<Typography> {option.displayName} </Typography>}
                                secondary={
                                  <span>
                                    {option.tags?.length > 0
                                      ? option.tags.map((value, index) => (
                                          <Chip
                                            size='small'
                                            key={index}
                                            component='span'
                                            label={value}
                                          />
                                        ))
                                      : null}
                                  </span>
                                }
                              />
                            </ListItem>
                          );
                        }}
                        value={this.state.form.contact}
                        getOptionSelected={(option) => option.uuid || false}
                        onChange={this.handleContactChange}
                        autoComplete={true}
                        autoSelect={true}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={TextDE.contact.label}
                            placeholder={TextDE.contact.inputPlaceholder}
                            helperText={TextDE.contact.helperText}
                          />
                        )}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.push({
                              inputValue: params.inputValue,
                              uuid: 'false',
                              tags: [],
                              displayName: '"' + params.inputValue + '" Hinzufügen',
                              address: '',
                              email: '',
                              isUser: false,
                              phone: '',
                              newContact: true,
                              deleted: false,
                            });
                          }
                          return filtered;
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.displayName;
                        }}
                      />
                    </Grid>
                    {this.state.form.contact?.newContact ? (
                      <Grid item xs={12}>
                        <TextField
                          id='new-email'
                          name='email'
                          label={TextDE.form.email.label}
                          placeholder={TextDE.form.email.placeholder}
                          helperText={TextDE.form.email.helperText}
                          fullWidth
                          type='email'
                          value={this.state.form.contact?.email}
                          onChange={this.handleContactChange}
                        />
                        <TextField
                          id='new-phone'
                          name='phone'
                          label={TextDE.form.phone.label}
                          placeholder={TextDE.form.phone.placeholder}
                          helperText={TextDE.form.phone.helperText}
                          fullWidth
                          value={this.state.form.contact?.phone}
                          onChange={this.handleContactChange}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        freeSolo
                        id='tags-standard'
                        name='tags'
                        options={Constants.tags.contacts.all()}
                        getOptionLabel={(option) => option}
                        defaultValue={null}
                        value={this.state.form.tags}
                        onChange={this.handleContactChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={TextDE.contact.tagLabel}
                            placeholder={TextDE.contact.tagPlaceholder}
                            helperText={TextDE.contact.tagHelperText}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    {!!this.state.hint ? (
                      <Alert
                        style={{ maxWidth: '100%', width: '100%' }}
                        severity='info'
                        action={
                          <Button
                            color='secondary'
                            size='small'
                            variant='contained'
                            onClick={() => this.setState({ addressTags: [], hint: false })}
                          >
                            Nicht hinzufügen
                          </Button>
                        }
                      >
                        {this.state.hint}
                      </Alert>
                    ) : null}
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={this.handleContactAdd}
                      >
                        {TextDE.add}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Collapse>
            </CardContent>
          </Collapse>
        </Card>

        <Dialog
          open={!!this.state.selectedContact}
          onClose={this.handleContactClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!!this.state.selectedContact ? (
            <ContactDetail
              contact={this.state.selectedContact}
              reload={true}
              close={this.handleContactClose}
              deleteCallback={this.callback}
              deleteItemCallback={this.callback}
              addItemCallback={this.callback}
            />
          ) : null}
        </Dialog>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContactCard);
