import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Constants } from '../../../lib/Constants';
import { DE as TextDE } from '../../../lib/Text';

// ERROR
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneIcon from '@material-ui/icons/Done';
import CancelIcon from '@material-ui/icons/Cancel';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  flex: {
    display: 'flex',
  },
  noMargin: {
    marginTop: '0px',
  },
  makeMeBig: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    fontSize: '125%',
    fontWeight: 'bold',
  },
});

class Tags extends React.Component {
  handleChange = (event, value) => {
    //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, event, value);
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      if (typeof value === 'object') {
        let tags = this.state.newTags;
        value.forEach((option) => {
          let newTag;
          if (typeof option === 'string') {
            newTag = option;
          } else if (option.inputValue) {
            newTag = option.inputValue;
          } else {
            newTag = option.name;
          }
          if (!tags.includes(newTag)) {
            tags.push(newTag);
          }
        });
        this.setState({ newTags: tags });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  async handleAppendTagToProcedure() {
    let newTags = this.props.tags?.map((value) => {
      return value.name.de;
    });
    newTags = newTags.concat(this.state.newTags);

    // Create new User or use existing
    let formData = {};
    formData['_method'] = 'PUT';
    formData['tags'] = newTags;

    await fetch(
      process.env.REACT_APP_API_URL +
        '/api/' +
        (this.props.isInbox ? 'inbox' : 'procedures') +
        '/' +
        this.props.match.params.uuid,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify(formData),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ newTags: [], dialogOpen: false }, this.props.callback);
      })
      .catch((error) => {
        return false;
      });
  }

  async handleRemoveTagFromUser(tag) {
    let newTags = this.props.tags?.filter((value) => {
      if (value.name.de !== tag) {
        return value.name.de;
      }
      return null;
    });
    // Create new User or use existing
    let formData = {};
    formData['_method'] = 'PUT';
    formData['tags'] = newTags.map((value) => value.name.de);

    await fetch(
      process.env.REACT_APP_API_URL +
        '/api/' +
        (this.props.isInbox ? 'inbox' : 'procedures') +
        '/' +
        this.props.match.params.uuid,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify(formData),
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ tags: newTags }, this.props.callback);
      })
      .catch((error) => {
        return false;
      });
  }
  handleCloseMoreTagsClick() {
    this.setState({ dialogOpen: false });
  }

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      hasError: false,
      newTags: [],
      dialogOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleAppendTagToProcedure = this.handleAppendTagToProcedure.bind(this);
    this.handleRemoveTagFromUser = this.handleRemoveTagFromUser.bind(this);
    this.handleCloseMoreTagsClick = this.handleCloseMoreTagsClick.bind(this);
  }

  render() {
    const { classes } = this.props;

    const filter = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.name,
    });

    return (
      <>
        <Dialog
          open={this.state.dialogOpen}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleCloseMoreTagsClick();
            }
          }}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            {TextDE.procedure.tagLabel} {TextDE.add}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.procedure.tagText}</DialogContentText>
            <Typography p='0' variant='subtitle1'>
              Bisher:
            </Typography>

            <ul style={{ listStyleType: 'none' }}>
              {this.props.tags
                ?.filter((value) => value.type !== 'success')
                .map((value, index) => {
                  return (
                    <li key={'chip-list-' + index}>
                      <Chip
                        classes={{ root: classes.makeMeBig }}
                        className={(!!value.type ? value.type : 'warning') + '-border'}
                        icon={
                          value.type === 'success' ? (
                            <DoneIcon style={{ color: 'inherit' }} />
                          ) : value.type === 'error' ? (
                            <ErrorOutlineIcon style={{ color: 'inherit' }} />
                          ) : null
                        }
                        label={value.name.de}
                        variant='outlined'
                        deleteIcon={<CancelIcon style={{ color: 'inherit' }} />}
                        onDelete={() => this.handleRemoveTagFromUser(value.name.de)}
                      />
                    </li>
                  );
                })}
            </ul>

            <Autocomplete
              value={this.state.newTags}
              onChange={this.handleChange}
              includeInputInList={true}
              multiple
              id='tags-standard'
              name='tags'
              freeSolo
              autoSelect
              options={[
                ...[
                  ...Constants.tags.procedures.customer,
                  ...(this.props.preDefinedTags ?? []),
                ].map((option) => {
                  return { name: option };
                }),
              ]}
              filterOptions={(options, params) => {
                let filtered = [];
                if (!!options) {
                  filtered = filter(options, params);
                }

                // Suggest the creation of a new value
                if (params.inputValue !== '') {
                  filtered.unshift(params.inputValue);
                }
                return filtered;
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(option) => {
                if (typeof option === 'string') {
                  return <Typography noWrap>{TextDE.procedure.addTag(option)}</Typography>;
                }
                return <Typography noWrap>{option.name}</Typography>;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label={TextDE.procedure.tagLabel}
                  helperText={TextDE.procedure.tagHelperText}
                  placeholder={TextDE.procedure.tagPlaceholder}
                  fullWidth
                  name='tags'
                />
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseMoreTagsClick} color='secondary'>
              {TextDE.close}
            </Button>
            <Button onClick={this.handleAppendTagToProcedure} color='primary'>
              {TextDE.add}
            </Button>
          </DialogActions>
        </Dialog>

        <Hidden mdDown>
          {this.props.tags
            ?.filter((value) => value.type !== 'success')
            .map((value, index) => {
              return (
                <Chip
                  key={'chip-list-' + index}
                  className={(!!value.type ? value.type : 'warning') + '-border'}
                  icon={
                    value.type === 'success' ? (
                      <DoneIcon style={{ color: 'inherit' }} />
                    ) : value.type === 'error' ? (
                      <ErrorOutlineIcon style={{ color: 'inherit' }} />
                    ) : null
                  }
                  label={value.name.de}
                  variant='outlined'
                  deleteIcon={<CancelIcon style={{ color: 'inherit' }} />}
                  onDelete={() => this.handleRemoveTagFromUser(value.name.de)}
                />
              );
            })}
        </Hidden>
        <Chip
          className={classes.chip}
          label={`${TextDE.procedure.tagLabel} ${TextDE.add}`}
          variant='outlined'
          onClick={() => this.setState({ dialogOpen: true })}
          icon={<AddIcon />}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Tags);
