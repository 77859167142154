/*
 * action types
 */

export const FETCH = 'FETCH_DIMENSIONS';
export const RESET = 'RESET_DIMENSIONS';
/*
 * action creators
 */
export const fetch = (config) => {
  return { type: FETCH, config };
};

export const reset = () => {
  return { type: RESET };
};
