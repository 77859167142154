import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateCustomer } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//import ImageList from '@material-ui/core/ImageList';
//import ImageListItem from '@material-ui/core/ImageListItem';
//import ImageListItemBar from '@material-ui/core/ImageListItemBar';
//import HelpIcon from '@material-ui/icons/Help';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { readable, readableDate } from '../../lib/dateFunctions';
const roundIt = (value) => {
  let tmp = String(value).split('.');
  if (!!tmp[1]) {
    if (tmp[1].length > 2) {
      tmp[1] = String(tmp[1]).substring(0, 2);
    }
    if (tmp[1].length === 0) {
      tmp[1] = '00';
    }
    if (tmp[1].length < 2) {
      tmp[1] = String(tmp[1]) + '0';
    }
  } else {
    tmp[1] = '00';
  }

  return tmp[0] + ',' + tmp[1];
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },

  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pullUp: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(0, 1),
  },
  gridList: {
    [theme.breakpoints.down('md')]: {
      flexWrap: 'nowrap',
      // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
      transform: 'translateZ(0)',
    },
  },
  choosenPackage: {
    border: '1px solid ' + theme.palette.primary.main,
    boxShadow:
      '0px 3px 5px -1px ' +
      theme.palette.primary.main +
      'aa ,0px 5px 8px 0px ' +
      theme.palette.primary.main +
      'cc ,0px 1px 14px 0px ' +
      theme.palette.primary.main +
      'dd ',
  },
  title: {},
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  buttonMargin: {
    marginRight: theme.spacing(2),
  },
});

class UserConfigForm extends React.Component {
  async handlePackageChange(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('package_id', this.state.displayMore);

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/packages', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((e) => e);

    if (!!!response.success) {
      this.setState({
        isSubmitting: false,
        hasError: 'Konnte die Daten nicht speichern!',
      });
    }

    this.setState({
      isSubmitting: false,
      hasError: false,
      package_id: this.state.displayMore,
    });
    this.props.dispatch(updateCustomer(response));
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  constructor(props) {
    super(props);

    this.state = {
      packages: [],
      isSubmitting: false,
      hasError: false,
      package_id: props.User.customer.package_id ?? 1,
      displayMore: props.User.customer.package_id ?? 1,
    };

    this.handlePackageChange = this.handlePackageChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  showPackage(id) {
    this.setState({ displayMore: id });
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/packages', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!!response.packages) {
          this.setState({ packages: response.packages });
        } else {
          this.setState({ hasError: 'Konnte keine Daten abholen' });
        }
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Paper className={classes.root} square>
          <Typography variant='h5'>Ihr Account:</Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant='h6'>
                {!!this.props.User.customer.description &&
                  'Registrierte Kundenname + Adresse: ' + this.props.User.customer.description}
                {!!this.props.User.customer.description && <br />}
                Gebuchtes Paket:{' '}
                <strong>{this.state.packages[this.state.package_id]?.name ?? '...'}</strong>
              </Typography>
              {this.props.User.customer.package_next_billing && (
                <Typography>
                  Nächste Abrechnung erfolgt am:{' '}
                  <strong> {readableDate(this.props.User.customer.package_next_billing)} </strong>
                </Typography>
              )}
              {this.props.User.customer.package_change_date && (
                <Typography>
                  Zuletzt aktualisiert:{' '}
                  <strong>{readable(this.props.User.customer.package_change_date)}</strong> durch{' '}
                  <strong>
                    {this.props.User.customer.package_change_user?.firstname ?? 'das'}{' '}
                    {this.props.User.customer.package_change_user?.lastname ?? 'System'}
                  </strong>
                </Typography>
              )}
            </Grid>

            {!!this.props.User.customer.sponsored && (
              <Grid item xs={12}>
                <Alert severity='info'>
                  <AlertTitle>GRATIS NUTZUNG</AlertTitle>
                  Ihr Account wird von uns bezahlt.
                </Alert>
              </Grid>
            )}
            {/*
						<Grid item xs={12} lg={7}>
							<ImageList className={classes.gridList} cols={2}>
						        {Object.values(this.state.packages).map((value, key) => (
						          <ImageListItem 
						          	key={key} 
						          	className={ 
						          		(value.id === this.state.displayMore) 
						          			? classes.choosenPackage 
						          			: null 
						          		} 
						          	onClick={() => this.showPackage(value.id)}
						           > 
						            <Typography align="right" variant="caption">
						            	{value.price_month} €
						            </Typography>
						            <ImageListItemBar
						              title={value.name}
						              subtitle={value.description}
						              classes={{
						                root: classes.titleBar,
						                title: classes.title,
						              }}
						              actionIcon={
						                <Button 
						                	aria-label={`star ${value.name}`} 
						                	startIcon={<HelpIcon />} 
						                	size="small" 
						                	variant="contained"
						                	classes={{
						                		root: classes.buttonMargin,
						                	}}
						                >
						                	Mehr erfahren
						                </Button>
						              }
						            />
						          </ImageListItem>
						        ))}
						      </ImageList>

						</Grid>
					*/}
            {false && !!this.state.displayMore && (
              <Grid item xs={12} lg={5}>
                <Typography>
                  Paket:{' '}
                  <strong>{this.state.packages[this.state.displayMore]?.name ?? '...'}</strong>
                </Typography>
                <Typography>
                  {this.state.packages[this.state.displayMore]?.description ?? '...'}
                </Typography>
                <TableContainer>
                  <Table className={classes.table} aria-label='simple table'>
                    <TableHead>
                      <TableRow>
                        <TableCell align='right'>Useranzahl</TableCell>
                        <TableCell align='right'>max. Vorgänge</TableCell>
                        <TableCell align='right'>Monatliche Kosten</TableCell>
                        <TableCell align='right'>Jährliche Kosten</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align='right'>
                          {this.state.packages[this.state.displayMore]?.user ?? '...'}
                        </TableCell>
                        <TableCell align='right'>
                          {this.state.packages[this.state.displayMore]?.storage ?? '...'}
                        </TableCell>
                        <TableCell align='right'>
                          {roundIt(
                            this.state.packages[this.state.displayMore]?.price_month ?? '...',
                          )}{' '}
                          €
                        </TableCell>
                        <TableCell align='right'>
                          {roundIt(
                            this.state.packages[this.state.displayMore]?.price_annual ?? '...',
                          )}{' '}
                          €
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align='right'></TableCell>
                        <TableCell align='right'>Gesamtkosten:</TableCell>
                        <TableCell align='right'>
                          {roundIt(
                            this.state.packages[this.state.displayMore]?.price_month *
                              this.state.packages[this.state.displayMore]?.user,
                          )}{' '}
                          €
                        </TableCell>
                        <TableCell align='right'>
                          {roundIt(
                            this.state.packages[this.state.displayMore]?.price_annual *
                              this.state.packages[this.state.displayMore]?.user,
                          )}{' '}
                          €
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography>Sie wollen das Paket wechseln?</Typography>
                <Button
                  fullWidth
                  startIcon={<ChevronRightIcon />}
                  onClick={this.handlePackageChange}
                  color='primary'
                  variant='contained'
                >
                  Ja, zu diesem Paket wechseln!
                </Button>
              </Grid>
            )}
          </Grid>
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserConfigForm);
