import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Box from '@material-ui/core/Box';
import { DE as TextDE } from '../../../lib/Text';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import AlarmIcon from '@material-ui/icons/Alarm';
import DescriptionIcon from '@material-ui/icons/Description';

const iconHeight = 64;

const toCountdown = (start, end) => {
  start = Date.parse(start) / 1000;
  end = Date.parse(end) / 1000;
  let now = Math.floor(Date.now() / 1000);
  let time = 0;
  if (start > now) {
    time = start - now;
    return time > 172800
      ? 'in ' + Math.floor(time / 86400) + ' Tagen'
      : time > 86400
      ? 'Morgen'
      : 'in ' +
        (time > 3600
          ? Math.floor(time / 3600) + ' Std.'
          : time > 60
          ? Math.floor(time / 60) + ' Min.'
          : time + ' Sek');
  } else if (end > now) {
    time = now - start;
    return time > 10800
      ? false
      : 'seit ' +
          (time > 3600
            ? Math.floor(time / 3600) + ' Std.'
            : time > 60
            ? Math.floor(time / 60) + ' Min.'
            : time);
  } else {
    return false;
  }
};

const timeString = (isAllDay, start, end) => {
  start = new Date(start);
  end = new Date(end);
  const compareStart = `${start.getDate()}${start.getFullYear()}${start.getMonth()}`;
  const compareEnd = `${end.getDate()}${end.getFullYear()}${end.getMonth()}`;
  if (isAllDay) {
    if (compareStart === compareEnd) {
      return start.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    } else {
      return (
        start.toLocaleDateString('de-DE', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) +
        ' bis ' +
        end.toLocaleDateString('de-DE', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );
    }
  } else {
    return (
      start.toLocaleTimeString().slice(0, -3) +
      ' Uhr bis ' +
      end.toLocaleTimeString().slice(0, -3) +
      ' Uhr am ' +
      end.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
  }
};

const styles = (theme) => ({
  backdrop: {
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#fff',
    zIndex: '1001',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  noPad: {
    padding: theme.spacing(0),
    '&:last-child': {
      paddingBottom: theme.spacing(0),
    },
  },
  inherit: {
    boxShadow: 'inherit',
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  inheritBackground: {
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  chip: {
    marginRight: theme.spacing(0.8),
    padding: theme.spacing(0.5, 1),
  },
  card: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  cardAppointment: {
    borderRight: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    borderBottom: 'none',
    overflow: 'visible',
    backgroundColor: '#64b5f6',
    minHeight: '106px',
  },
  cardAppointmentCollapsed: {
    justifyContent: 'start',
    alignContent: 'center',
    display: 'flex',
  },
  maximize4: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(-75%, 2px)',
      marginLeft: '100.5%',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },

  maximize: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(calc(-66.666666% - ' + theme.spacing(1.5) + 'px), 2px)',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  button: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '90vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      //right: iconHeight/1.5+30+'px',
      //marginTop: '-'+iconHeight/1.5+'px',
      top: '10vh',
      left: '1vw',
      maxWidth: '98vw',
      minWidth: '98vw',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: '1002',
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  cardHeaderContent: {
    flex: '1 1 auto',
    width: 0,
    height: 'auto',
    paddingBottom: 0,
  },
  cardHeaderAction: {},
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
  },

  listitemroot: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5, 2),
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    border: '0px solid ' + theme.palette.success.dark,
    boxShadow: theme.shadows[4],
    borderRadius: '1em',
  },
  outlined: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  green: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  },
  red: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
});

class AppointmentsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleAppointment: false,
      cardAppointmentsExpand: false,
      cardAppointmentsNew: false,
      disableProcedure: props.disableProcedure,
      suggestions: props.suggestions,
      data: this.props.data,
      toDelete: false,
    };

    this.handleAppointmentsExpandClick = this.handleAppointmentsExpandClick.bind(this);
    this.handleAppointmentsNewClick = this.handleAppointmentsNewClick.bind(this);

    this.handleAppointmentEdit = this.handleAppointmentEdit.bind(this);
    this.handleAppointmentDelete = this.handleAppointmentDelete.bind(this);

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.handleAppointmentsAddClick = this.handleAppointmentsAddClick.bind(this);
  }

  componentDidMount() {
    if (this.props.appointments?.length > 1) {
      let now = Math.floor(Date.now() / 1000);
      let tmp = this.props.appointments.filter((val) => {
        let start = Date.parse(val.start) / 1000;
        let end = Date.parse(val.end) / 1000;
        return start > now && end > now;
      });

      tmp.sort(function (a, b) {
        return Date.parse(a.start) > Date.parse(b.start) ? 1 : -1;
      });
      this.setState({ titleAppointment: tmp[0] });
    } else if (this.props.appointments?.length === 1) {
      this.setState({ titleAppointment: this.props.appointments[0] });
    }
  }

  componentDidUpdate(props, state) {
    // if oldProps.addAppointment != this.props.addAppointment && this.props.addAppointment == true
    if (props.addAppointment !== this.props.addAppointment && this.props.addAppointment === true) {
      this.handleAppointmentsNewClick();
    }
  }

  handleAppointmentsExpandClick = () => {
    this.props.toggleFunction('AppointmentsCard');
    //this.setState({ cardAppointmentsExpand: !this.props.open });
  };

  handleAppointmentsAddClick = () => {
    this.setState({ cardAppointmentsNew: !this.state.cardAppointmentsNew });
  };

  handleAppointmentsNewClick = () => {
    this.setState({ cardAppointmentsNew: true });
  };

  handleAppointmentsNewClose = () => {
    this.setState({ cardAppointmentsNew: false }, () => {
      this.props.closeAppointmentDialog();
    });
  };

  handleAppointmentsNewUpdate = (data) => {
    this.props.callbackFunction(data);
    this.setState({ cardAppointmentsNew: false });
  };

  handleAppointmentEdit = (value) => {
    this.props.editCallback(value);
  };

  deleteDialog(value) {
    if (!!value.raw?.uid && typeof value.raw?.uid === 'string') {
      this.setState({ toDelete: value });
    }
  }

  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  handleAppointmentDelete = () => {
    let url = process.env.REACT_APP_API_URL + '/api/calendar/' + this.state.toDelete.raw?.uid;
    fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({
        uid: this.state.toDelete.raw?.uid,
        //firstoccurence: Date.parse(this.props.data.raw?.firstoccurence)/1000,
        firstoccurence: this.state.toDelete.raw?.firstoccurence,
        //lastoccurence: Date.parse(this.props.data.raw?.lastoccurence)/1000,
        lastoccurence: this.state.toDelete.raw?.lastoccurence,
        etag: this.state.toDelete.raw?.etag,

        calendarId: this.state.toDelete.calendarId,
        id: this.state.toDelete.id,
      }),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });
    this.props.deleteCallback(this.state.toDelete.raw?.uid);
    this.setState({ toDelete: false });
    // Did not reload?
  };

  handleAppointmentClick = (value) => {
    this.setState({ selectedAppointment: value });
  };

  handleAppointmentClose = () => {
    this.setState({ selectedAppointment: null });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box ml={{ xs: 0, sm: 0, md: 0, lg: 1, xl: 1 }} mt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }}>
        <Dialog
          open={!!this.state.toDelete}
          onClose={this.closeDeleteDialog}
          aria-labelledby='action dialog'
        >
          <DialogContent>
            <DialogContentText>
              {TextDE.appointment.deleteTextLong(
                this.state.toDelete?.title,
                timeString(
                  this.state.toDelete?.isAllDay,
                  this.state.toDelete?.start,
                  this.state.toDelete?.end,
                ),
                this.state.toDelete?.location,
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.closeDeleteDialog} color='primary'>
              {TextDE.close}
            </Button>
            <Button onClick={this.handleAppointmentDelete} color='primary' autoFocus>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Card raised={false} className={classes.cardAppointment}>
          <CardHeader
            classes={{
              content: classes.cardHeaderContent,
              root: classes.cardHeaderRoot,
              action: classes.cardHeaderAction,
            }}
            avatar={
              <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                <DateRangeIcon />
              </Avatar>
            }
            titleTypographyProps={{ noWrap: true, variant: 'h6' }}
            title={TextDE.appointment.labelPlural}
            action={
              <>
                <IconButton onClick={this.props.addCallback}>
                  <AddIcon />
                </IconButton>
              </>
            }
          />

          <CardContent className={classes.noPad}>
            <List className={classes.list}>
              {this.props.appointmentIsLoading ? (
                <CircularProgress />
              ) : !(this.props.appointments?.length > 0) ? (
                <ListItem>
                  <ListItemText primary={TextDE.appointment.noAppointment} />
                </ListItem>
              ) : (
                Object.values(this.props.appointments).map((value) => {
                  const labelId = `list-appointment-label-${value.raw.uid}`;
                  const countdown = toCountdown(value.start, value.end);
                  return (
                    <ListItem
                      key={labelId}
                      button
                      onClick={() => this.handleAppointmentClick(value)}
                    >
                      <ListItemText
                        id={labelId}
                        disableTypography={true}
                        primary={
                          <div>
                            <Typography variant='caption'>{countdown}</Typography>
                            <Typography variant='body1'>{value.title}</Typography>
                          </div>
                        }
                        secondary={
                          <div>
                            <Typography color='textSecondary' display='block' variant='body1'>
                              {timeString(value.isAllDay, value.start, value.end)}
                            </Typography>
                            {!!value.location ? (
                              <Typography color='textSecondary' display='block' variant='body2'>
                                {value.location}
                              </Typography>
                            ) : null}
                          </div>
                        }
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          color='default'
                          edge='end'
                          aria-label={TextDE.edit}
                          onClick={() => this.handleAppointmentEdit(value)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          color='default'
                          edge='end'
                          aria-label={TextDE.delete}
                          onClick={() => this.deleteDialog(value)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })
              )}
            </List>
          </CardContent>
        </Card>
        <Modal
          open={!!this.state.selectedAppointment}
          onClose={this.handleAppointmentClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card className={classes.paper}>
            <CardHeader
              title={<Typography variant='h3'>{this.state.selectedAppointment?.title}</Typography>}
              action={
                <IconButton aria-label='close' onClick={this.handleAppointmentClose}>
                  <CloseIcon />
                </IconButton>
              }
            ></CardHeader>
            <CardContent>
              <List>
                {!!this.state.selectedAppointment?.body && (
                  <ListItem>
                    <ListItemIcon>
                      <DescriptionIcon />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        component: 'pre',
                        style: { maxHeight: '200px', overflowY: 'auto' },
                      }}
                      primary={this.state.selectedAppointment?.body}
                      secondary={TextDE.form.description.label}
                    />
                  </ListItem>
                )}

                {!!this.state.selectedAppointment?.start &&
                  !!this.state.selectedAppointment?.end && (
                    <ListItem>
                      <ListItemIcon>
                        <AlarmIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={timeString(
                          this.state.selectedAppointment?.isAllDay,
                          this.state.selectedAppointment?.start,
                          this.state.selectedAppointment?.end,
                        )}
                        secondary='Termin'
                      />
                    </ListItem>
                  )}

                {!!this.state.selectedAppointment?.location && (
                  <ListItem>
                    <ListItemIcon>
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.state.selectedAppointment?.location}
                      secondary='Ort'
                    />
                  </ListItem>
                )}

                {!!this.state.selectedAppointment?.raw?.organizer &&
                !!this.state.selectedAppointment?.raw?.organizer?.uuid ? (
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        this.state.selectedAppointment?.raw?.organizer?.firstname +
                        ' ' +
                        this.state.selectedAppointment?.raw?.organizer?.lastname
                      }
                      secondary={TextDE.appointment.organizerLabel}
                    />
                  </ListItem>
                ) : (
                  <ListItem>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={this.state.selectedAppointment?.raw?.organizer}
                      secondary={TextDE.appointment.organizerLabel}
                    />
                  </ListItem>
                )}

                {!!this.state.selectedAppointment?.attendees &&
                  this.state.selectedAppointment?.attendees.length > 0 && (
                    <ListItem>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText
                        disableTypography
                        primary={
                          <List dense disablePadding>
                            {this.state.selectedAppointment?.attendees.map((attendee, index) => {
                              //let isUser = false;
                              //if (attendee.user?.uuid === this.props.User.uuid) {
                              //  isUser = attendee.partstat;
                              //
                              switch (attendee.partstat) {
                                case 'ACCEPTED':
                                  return (
                                    <ListItem
                                      key={index}
                                      classes={{ root: classes.listitemroot }}
                                      className={classes.green}
                                    >
                                      <ListItemText
                                        inset={false}
                                        disableTypography={true}
                                        primary={
                                          <Typography
                                            display='block'
                                            component='h6'
                                            variant='subtitle1'
                                            style={{ display: 'flex' }}
                                            noWrap
                                          >
                                            {!!attendee.cn
                                              ? attendee.cn + ' / ' + attendee.email
                                              : attendee.email}
                                            <CheckCircleIcon
                                              style={{ marginLeft: 'auto' }}
                                              color='inherit'
                                              fontSize='medium'
                                            />
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  );
                                case 'DECLINED':
                                  return (
                                    <ListItem
                                      key={index}
                                      classes={{ root: classes.listitemroot }}
                                      className={classes.red}
                                    >
                                      <ListItemText
                                        inset={false}
                                        disableTypography={true}
                                        primary={
                                          <Typography
                                            display='block'
                                            component='h6'
                                            variant='subtitle1'
                                            style={{ display: 'flex' }}
                                            noWrap
                                          >
                                            {!!attendee.cn
                                              ? attendee.cn + ' / ' + attendee.email
                                              : attendee.email}
                                            <RemoveCircleIcon
                                              style={{ marginLeft: 'auto' }}
                                              color='inherit'
                                              fontSize='medium'
                                            />
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  );
                                default:
                                  return (
                                    <ListItem
                                      key={index}
                                      classes={{ root: classes.listitemroot }}
                                      className={classes.yellow}
                                    >
                                      <ListItemText
                                        inset={false}
                                        disableTypography={true}
                                        primary={
                                          <Typography
                                            display='block'
                                            component='h6'
                                            variant='subtitle1'
                                            style={{ display: 'flex' }}
                                            noWrap
                                          >
                                            {!!attendee.cn
                                              ? attendee.cn + ' / ' + attendee.email
                                              : attendee.email}
                                            <HelpIcon
                                              style={{ marginLeft: 'auto' }}
                                              color='inherit'
                                              fontSize='medium'
                                            />
                                          </Typography>
                                        }
                                      />
                                    </ListItem>
                                  );
                              }
                            })}
                          </List>
                        }
                      />
                    </ListItem>
                  )}
              </List>
            </CardContent>
          </Card>
        </Modal>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  Dimensions: state.Dimensions,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AppointmentsCard);
