import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { compose } from 'react-recompose';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
import SupportRequestWithoutUser from '../Other/SupportRequestWithoutUser';
import { DE as TextDE } from '../../lib/Text';
import FooterHint from '../Other/FooterHint';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: '15vh',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  NavLinkItem: {
    width: '33%',
    padding: theme.spacing(3),
  },
  alert: {
    width: '100%',
  },
  size: {},
});

class Register extends React.Component {
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePasswordChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    //let re = /^.*(?=.{3,})(?=.*[a-zA-ZäöüÄÖÜ])(?=.*[0-9])(?=.*[\d\X])(?=.*[!$#%]).*$/
    let reCaps = /[A-ZÄÖÜ]/;
    let reSmall = /[a-zäöü]/;
    let reDigit = /[0-9]/;
    let reCode = /[#?!@$%^&*-]/;
    let reFull = /^(?=.*?[A-ZÄÖÜ])(?=.*?[a-zäöü])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    let validated = false;
    let validationMessage = [];
    if (!event.target.value.match(reCaps)) {
      validationMessage.push('Es fehlt mindestens 1 Großbuchstabe (ABC...)');
    }
    if (!event.target.value.match(reSmall)) {
      validationMessage.push('Es fehlt mindestens 1 Kleinbuchstabe (abc...)');
    }
    if (!event.target.value.match(reDigit)) {
      validationMessage.push('Es fehlt mindestens 1 Zahl (123...)');
    }
    if (!event.target.value.match(reCode)) {
      validationMessage.push('Es fehlt mindestens 1 Sonderzeichen (!$#%...)');
    }
    if (event.target.value.length < 8) {
      validationMessage.push(
        'Das Passwort ist zu kurz, bitte noch mindestens ' +
          (8 - event.target.value.length) +
          ' Zeichen',
      );
    }
    if (event.target.value.match(reFull)) {
      validated = true;
    }
    this.setState({
      validationMessage: validationMessage,
      validation: validated,
      [event.target.name]: event.target.value,
    });
  };

  handleDelete = () => {
    this.setState({ error: false });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    if (!this.state.email.trim() && !this.state.password.trim() && this.state.validation) {
      return;
    }

    let formData = new FormData();
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('password_confirmation', this.state.password);
    formData.append('token', this.state.token);

    let response = await fetch(process.env.REACT_APP_API_URL + '/api/password/reset/', {
      method: 'POST',
      //headers: headers,
      body: formData,
      redirect: 'manual',
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (response) {
        return response;
      })
      .catch((error) => alert(JSON.stringify(error)));

    if (typeof response.errors !== 'undefined') {
      this.setState({ loading: false, error: response.message });
    } else {
      alert(response.message + ' Bitte melden Sie sich erneut an.');
      this.props.dispatch(push('/login'));
      return;
    }
    this.setState({ loading: false, password: '', error: JSON.stringify(response) });
  }

  constructor(props) {
    super(props);

    let searchString = (
      /^[?#]/.test(this.props.location.search)
        ? this.props.location.search.slice(1)
        : this.props.location.search
    )
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
        return params;
      }, {});

    this.state = {
      loading: false,
      error: false,
      validationMessage: [],
      validation: false,
      email: searchString.email,
      token: this.props.match.params.token,
      password: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={classes.paper}>
          <Card className={classes.card} variant='outlined'>
            <div
              style={{
                backgroundImage: 'url(/assets/icon.png)',
                width: '100%',
                minHeight: '20vh',
                height: 'auto',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                marginBottom: '1em',
              }}
            ></div>
            <div
              style={{
                backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
                width: '100%',
                minHeight: '60px',
                height: 'auto',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                backgroundPosition: 'center',
              }}
            ></div>
            {this.state.loading ? (
              <Box my={4}>
                {' '}
                <CircularProgress />{' '}
              </Box>
            ) : (
              <form className={classes.form} onSubmit={this.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography>{TextDE.passwordReset.confirmDataLabel}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label={TextDE.form.email.label}
                      name='email'
                      type='email'
                      value={this.state.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      fullWidth
                      name='password'
                      label='Passwort'
                      type='password'
                      value={this.state.password}
                      onChange={this.handlePasswordChange()}
                      id='password'
                      autoComplete='new-password'
                      error={this.state.validation === false ? true : false}
                      helperText={
                        this.state.validation
                          ? TextDE.form.password.validation.criteriaMet
                          : TextDE.form.password.helperText
                      }
                    />
                  </Grid>
                </Grid>
                {!!this.state.error ? (
                  <Alert classes={classes.alert} severity='error'>
                    {this.state.error}
                  </Alert>
                ) : null}
                {this.state.validation === false && this.state.validationMessage.length > 0 ? (
                  <Grid item zeroMinWidth xs={12}>
                    {this.state.validationMessage.map((value, index) => (
                      <Alert key={index} severity='error' closeText={TextDE.close}>
                        {value}
                      </Alert>
                    ))}
                  </Grid>
                ) : null}
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  endIcon={<RotateLeftIcon />}
                  disabled={!(this.state.email.length > 0 && this.state.password.length > 0)}
                >
                  {TextDE.passwordReset.button}
                </Button>
              </form>
            )}
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              className={classes.my}
              spacing={2}
            >
              <Grid item xs={12}>
                <Button
                  href='/login'
                  variant='text'
                  color='primary'
                  size='small'
                  style={{ textAlign: 'center' }}
                >
                  {TextDE.backToLogin}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <SupportRequestWithoutUser />
              </Grid>
            </Grid>
            <FooterHint />
          </Card>
        </div>
      </Container>
    );
  }
}
//export default Login;

const mapStateToProps = (state) => ({ Authentication: state.Authentication });
//export default connect(mapStateToProps)(Login);
export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    //, mapDispatchToProps
  ),
)(Register);
