import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import StatisticsTable from './StatisticsTable';
import { Alert, AlertTitle } from '@material-ui/lab';
import { DE as TextDE } from '../../lib/Text';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
});

class Statistics extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Statistics: true,
    };

    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  componentDidMount() {}

  render() {
    return (
      <>
        <div
          style={{
            margin: 0,
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'flex',
          }}
        >
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column',
              flexGrow: '1',
              margin: '16px',
            }}
          >
            <CardContent style={{ height: '100%', padding: '0' }}>
              {this.props.Dimensions.maxWidth < 1400 ? (
                <Alert
                  variant='filled'
                  severity='warning'
                  style={{
                    position: 'relative',
                    top: '50%',
                    left: '50%',
                    right: '20%',
                    maxWidth: '60%',
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  <AlertTitle>{TextDE.statistic.alertTitle}</AlertTitle>Die Tabelle ist sehr groß,
                  bitte nutzen Sie einen Bildschirm mit mindestens 1600 Pixel in der Breite
                </Alert>
              ) : (
                <StatisticsTable
                  style={{ height: 'inherit' }}
                  height={this.props.Dimensions.height - (this.props.Dimensions.appBarHeight + 32)}
                />
              )}
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Statistics);
