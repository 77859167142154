import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';

import Login from '../components/Login';
import Register from '../components/Register';
import ForgottenPassword from '../components/ForgottenPassword';
import ResetPassword from '../components/ResetPassword';
import AGB from '../components/AGB';
import FAQ from '../components/FAQ';
import Impressum from '../components/Impressum';
import Privacy from '../components/Privacy';

import Preview from '../components/Preview';

import AppContainer from '../components/AppContainer';

class Routes extends Component {
  //constructor(props) {
  //	super(props);
  //}

  componentDidMount() {
    console.log('[ROUTER] Routes mounted!');
    console.log('[ROUTER] Logged in: ' + this.props.isAuthenticated);
  }

  render() {
    return (
      <div id='App'>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/login' component={Login} />
          <Route exact path='/register' component={Register} />
          <Route exact path='/password/forgot' component={ForgottenPassword} />
          <Route path='/password/reset/:token' component={ResetPassword} />
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/agb' component={AGB} />
          <Route exact path='/impressum' component={Impressum} />
          <Route exact path='/faq' component={FAQ} />

          <Route path='/anfrage/anzeigen/:base64' component={Preview} />

          <PrivateRoute
            path='/email/verify/:base64'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='VerifyEmail' />}
          />
          <PrivateRoute
            path='/home'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Home' />}
          />

          <PrivateRoute
            path='/statistics'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Statistics' />}
          />

          <PrivateRoute
            path='/zeiterfassung'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Timetracking' />}
          />
          <PrivateRoute
            path='/kontakte'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Contact' />}
          />

          <PrivateRoute
            exact
            path='/kalender/:appointment'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Calendar' />}
          />
          <PrivateRoute
            path='/kalender'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Calendar' />}
          />

          <PrivateRoute
            exact
            path='/nachrichten/detail/:uuid'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='MessageDetail' />}
          />

          <PrivateRoute
            path='/nachrichten/:filter'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Inbox' />}
          />

          <Redirect from='/todos/detail/:uuid' to='/nachrichten/detail/:uuid' />

          <Redirect from='/todos/:filter' to='/nachrichten/:filter' />

          <PrivateRoute
            path='/settings/:page?'
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Settings' />}
          />

          <PrivateRoute
            authed={this.props.isAuthenticated}
            component={() => <AppContainer component='Miss' />}
          />

          <Route render={() => <div>Miss</div>} />
        </Switch>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isAuthenticated: !!state.Authentication.access_token,
  Authentication: state.Authentication,
});
export default connect(mapStateToProps)(Routes);

// <div> ( this.props.User.uuid !== null ? `LoggedIn as ${this.props.User.first_name} ${this.props.User.last_name} (UUID: ${this.props.User.uuid})` : `Please login!` ) } </div>)} />
