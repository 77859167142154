import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { withReduxStateSync } from 'redux-state-sync';

import AutoRefresh from './AutoRefresh';
import User from './User';
import Style from './Style';
import NavMenu from './NavMenu';
import NewVersion from './NewVersion';
import Dimensions from './Dimensions';
import Authentication from './Authentication';
import Notifications from './Notifications';

function createRootReducer(history) {
  return withReduxStateSync(
    combineReducers({
      router: connectRouter(history),
      Authentication,
      Notifications,
      Style,
      Dimensions,
      User,
      AutoRefresh,
      NavMenu,
      NewVersion,
    }),
  );
}

export default createRootReducer;
