import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import clsx from 'clsx';

import SearchIcon from '@material-ui/icons/Search';

import Card from '@material-ui/core/Card';
//import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import ListItemOrga from './../Inbox/ListItemOrga';

import { DE as TextDE } from '../../lib/Text';
//function sleep(ms) {
//  return new Promise(resolve => setTimeout(resolve, ms));
//}

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    flexGrow: '1',
  },
  margin: {
    margin: theme.spacing(0),
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  textField: {
    flexGrow: '1',
  },
  search: {
    backgroundColor: '#ffffff',
  },
  searchDesktop: {
    backgroundColor: '#ffffff',
    marginLeft: 'auto',
    minWidth: '33%',
    maxWidth: '50%',
    padding: theme.spacing(0, 1),
    '& > input': {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1.8, 0),
      },
      padding: theme.spacing(1, 0),
    },
  },
  searchMobile: {
    backgroundColor: '#ffffff',
    padding: theme.spacing(0, 1),
    '& > input': {
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(1.8, 0),
      },
      padding: theme.spacing(1, 0),
    },
  },
  searchCard: {
    top: '66px',
    right: '1%',
    width: '98%',
    position: 'absolute',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  alignEnd: {
    float: 'right',
  },
});

class Search extends React.Component {
  async search() {
    if (this.state.keyword.length >= 3) {
      this.setState({
        open: true,
      });

      let url = process.env.REACT_APP_API_URL + '/api/search';
      let term = this.state.keyword.trim();
      if (term.indexOf(' ') > 0) {
        let atmp = term.split(' ').map((value) => 'search[]=' + encodeURIComponent(value));
        url = url + '?' + atmp.join('&');
      } else {
        url = url + '?search=' + encodeURIComponent(term);
      }

      let response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          return json;
        })
        .catch((error) => {
          return error;
        });

      if (!!response.data) {
        this.setState({ loading: false, messages: response });
      }
    }
  }

  handleSearch = (event) => {
    this.setState(
      {
        keyword: event.target.value,
      },
      this.search,
    );
  };

  constructor() {
    super();
    this.state = { loading: false, open: false, keyword: '', messages: {} };
    this.search = this.search.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  render() {
    const { classes } = this.props;
    //const widthClass = ( this.props.dimensions.width > 600 ? 'searchDesktop' : 'searchMobile' )

    return (
      <>
        <FormControl className={clsx(classes.margin, classes.textField)} variant='outlined'>
          <OutlinedInput
            className={classes.search}
            size='small'
            color='secondary'
            value={this.state.keyword}
            onChange={this.handleSearch}
            fullWidth
            autoFocus
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={() => this.search()}>
                  {' '}
                  <SearchIcon />{' '}
                </IconButton>{' '}
              </InputAdornment>
            }
            aria-describedby='outlined-weight-helper-text'
            inputProps={{
              'aria-label': 'search',
            }}
            labelWidth={0}
          />
        </FormControl>

        {this.state.open ? (
          <Card raised className={classes.searchCard}>
            <CardContent>
              <Typography className={classes.title} color='textSecondary' gutterBottom>
                {TextDE.search.resultsLabel}:
                <IconButton
                  size='small'
                  className={classes.alignEnd}
                  onClick={() => this.setState({ open: false })}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>

              {this.state.loading ? (
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                >
                  <CircularProgress className={classes.center} />
                </div>
              ) : !!!this.state.messages.data ? (
                <Typography> {TextDE.search.nothingFoundLabel} </Typography>
              ) : (
                <List disablePadding>
                  {Object.values(this.state.messages.data).map((value) => {
                    return (
                      <ListItemOrga
                        key={'procedure-' + value.uuid}
                        hasChange={-1}
                        expanded={false}
                        isAdmin={false}
                        message={value}
                        delete={false}
                        linkTo={(url) =>
                          this.setState({ open: false }, this.props.dispatch(push(url)))
                        }
                      />
                    );
                  })}
                </List>
              )}
            </CardContent>
          </Card>
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Search);
