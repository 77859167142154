import React, { createRef } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import { Constants } from '../../../lib/Constants';
import Alert from '@material-ui/lab/Alert';
import { DE as TextDE } from '../../../lib/Text';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

const styles = (theme) => ({
  alert: {
    padding: theme.spacing(0, 1, 0, 5),
    margin: '4px 0 8px 0',
    zIndex: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderContent: {
    padding: 0,
  },
  cardHeaderAction: {
    margin: '0 0 -8px 0',
  },
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#aed581',
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  customInputRoot: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  xs: {
    width: theme.spacing(1.2),
    height: theme.spacing(1.2),
  },
  small: {
    backgroundColor: theme.palette.text.primary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&>svg': {
      color: theme.palette.background.default,
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  pointer: {
    color: theme.palette.text.disabled,
    cursor: 'copy',
  },
  pointerDetail: {
    cursor: 'pointer',
  },
  floatRight: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  floatRightAdd: {
    float: 'right',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.light,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.dark,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem ' + theme.palette.success.main + 'bb',
    },
  },
  floatRightAbort: {
    float: 'right',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
      borderColor: theme.palette.error.light,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.warning.main,
      borderColor: theme.palette.warning.dark,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem ' + theme.palette.warning.main + 'bb',
    },
  },
  floatRightDel: {
    float: 'right',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.light,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.warning.main,
      borderColor: theme.palette.warning.dark,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem ' + theme.palette.warning.main + 'bb',
    },
  },
  results: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 123123,
    maxHeight: '300px',
    overflow: 'auto',
    boxShadow: theme.shadows[4],
  },
  contactListItem: {
    margin: theme.spacing(-1, -2),
    width: 'calc(100% + 32px)',
    backgroundColor: 'inherit',
  },
  contactListNew: {
    margin: theme.spacing(-1, -2),
    width: 'calc(100% + 32px)',
    backgroundColor: '#cccccc',
  },
});
class ContactCardAddNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      open: false,
      newContact: false,
      phone: '',
      phoneError: false,
      mail: '',
      mailError: false,
      salutation: TextDE.form.salutation.placeholder,
      firstname: '',
      name: '',
      invoiceRecipient: false,
      beneficiary: false,
      otherContactType: false,

      searchResults: [],
      selectedContact: null,
      searchIsLoading: false,
      searchNotFound: false,

      address: '',
      manual_address_input: false,
      hasError: false,
      addressError: '',
      addressObject: {},
      addressAnchor: null,
    };

    this.addressRef = createRef();

    this.selectAddressClose = this.selectAddressClose.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.decodeAddress = this.decodeAddress.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);

    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleAddClose = this.handleAddClose.bind(this);

    this.handleAddNewClick = this.handleAddNewClick.bind(this);
    this.handleAddNewClose = this.handleAddNewClose.bind(this);
    this.handleAddressSetManualEdit = this.handleAddressSetManualEdit.bind(this);
    this.handleAddressResetManualEdit = this.handleAddressResetManualEdit.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleContactSearch = this.handleContactSearch.bind(this);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleContactChange = (event, value) => {
    if (!!value && value?.isNewContact === true && !!value?.inputValue) {
      this.setState({
        name: value.inputValue,
        selectedContact: null,
        newContact: true,
        searchResults: [],
      });
    } else {
      this.setState({ selectedContact: value });
    }
  };

  handleContactSearch = async (event, inputValue) => {
    if (inputValue.trim().length < 3) {
      // do nothing
      return;
    }
    this.setState({ searchIsLoading: true });
    const contacts = await fetch(
      process.env.REACT_APP_API_URL + '/api/contacts/search/' + inputValue.trim(),
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        if (response.length > 0) {
          let tmp = [];
          response.forEach((contact) => {
            tmp.push({
              contactUuid: contact.uuid,
              contactId: contact.id,
              isNewContact: false,
              inputValue: '',
              address: !!contact.address
                ? contact.address.route +
                  ' ' +
                  contact.address.street_number +
                  ', ' +
                  contact.address.postal_code +
                  ' ' +
                  contact.address.city.locality
                : '',
              full: `${contact.salutation?.name ?? ''} ${contact.title?.name ?? ''} ${
                contact.firstname ?? ''
              } ${contact.name}`.trim(),
            });
          });
          return tmp;
        }
        return [];
      });
    this.setState({
      searchResults: contacts,
      searchIsLoading: false,
      searchNotFound: contacts.length < 1,
    });
  };

  //componentDidMount() {
  //  console.log("Did Mount");
  //}

  handleChange = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      let state = { [event.target.name]: event.target.value };
      if (event.target.name === 'phone') {
        if (
          !event.target.value.match(/^([+|00](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/gi)
        ) {
          state.phoneError = TextDE.form.phone.validation.wrongFormat;
        } else {
          state.phoneError = false;
        }
      }

      if (event.target.name === 'mail') {
        // eslint-disable-next-line
        if (
          !event.target.value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          )
        ) {
          state.mailError = TextDE.form.email.validation.wrongFormat;
        } else {
          state.mailError = false;
        }
      }
      this.setState(state);
    }
  };

  handleAddClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleAddClose = () => {
    this.setState({ open: false });
  };

  handleAddNewClick = () => {
    this.setState({ newContact: !this.state.newContact });
  };

  handleAddNewClose = () => {
    this.setState({
      isSubmitting: false,
      open: false,
      newContact: false,
      phone: '',
      phoneError: false,
      mail: '',
      mailError: false,
      salutation: TextDE.form.salutation.placeholder,
      firstname: '',
      name: '',
      invoiceRecipient: false,
      beneficiary: false,
      otherContactType: false,

      searchResults: [],
      selectedContact: null,
      searchIsLoading: false,
      searchNotFound: false,

      address: '',
      manual_address_input: false,
      hasError: false,
      addressError: '',
      addressObject: {},
      addressAnchor: null,
    });
  };

  selectAddressClose = () => {
    this.setState({
      addressAnchor: null,
    });
  };
  async handleFormSubmit(event, other) {
    event.preventDefault();
    /*
    console.log("Form Submit", event, other);
    console.log({
      'newContact': this.state.newContact,
      'phone': this.state.phone,
      'mail': this.state.mail,
      'salutation': this.state.salutation,
      'firstname': this.state.firstname,
      'name': this.state.name,
      'invoiceRecipient': this.state.invoiceRecipient,
      'beneficiary': this.state.beneficiary,
      'selectedContact': this.state.selectedContact,
      'addressObject': this.state.addressObject,
    });*/
    let hasError = [];
    let formData = {};

    if (!!this.state.newContact && !!!this.state.selectedContact) {
      //console.log("Form Submit - Neuer Kontakt");
      if (!!this.state.phoneError) {
        hasError.push(TextDE.form.phone.validation.wrongFormat);
      }

      if (!!this.state.mailError) {
        hasError.push(TextDE.form.email.validation.wrongFormat);
      }

      if (!!this.state.addressError) {
        hasError.push(this.state.addressError);
      }

      if (!this.state.invoiceRecipient && !this.state.beneficiary && !this.state.otherContactType) {
        hasError.push(TextDE.procedure.addProcedureForm.alerts.contactToUse);
      }

      if (hasError.length > 0) {
        this.setState({ hasError: hasError });
        console.log('Has errors', hasError);
        return;
      }

      this.setState({ isSubmitting: true });

      formData['name'] = this.state.name.trim();
      formData['firstname'] = this.state.firstname.trim();
      formData['salutation'] = this.state.salutation.toLowerCase();
      if (!!this.state.phone.trim()) {
        formData['phone'] = this.state.phone.trim();
      }
      if (!!this.state.mail.trim()) {
        formData['email'] = this.state.mail.trim();
      }
      if (!!this.state.address && !!this.state.addressObject) {
        formData['address'] = this.state.addressObject;
      }

      formData['contact'] = 'false';
    } else if (!!this.state.selectedContact.contactUuid) {
      //console.log("Form Submit - bestehender Kontakt");
      formData['contact'] = this.state.selectedContact.contactUuid;
    }

    let tags = [];
    let addressTags = [];

    if (!this.state.invoiceRecipient && !this.state.beneficiary && !this.state.otherContactType) {
      hasError.push(TextDE.procedure.addProcedureForm.alerts.contactToUse);
      this.setState({ hasError: hasError });
      return;
    }

    if (this.state.beneficiary) {
      tags.push(Constants.tags.contacts.resident[0]);
      addressTags.push(Constants.tags.addresses.resident[0]);
    }

    if (this.state.invoiceRecipient) {
      tags.push(Constants.tags.contacts.invoice[0]);
      addressTags.push(Constants.tags.addresses.invoice[0]);
    }

    formData['_method'] = 'PUT';
    formData['context'] = 'contacts';
    formData['uuid'] = this.props.inboxUuid;

    if (tags.length > 0) {
      formData['tags'] = tags;
      formData['addressTags'] = addressTags;
    }

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;

    //console.log("Form data", formData, endpoint);

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      });

    if (!!response?.uuid) {
      this.props.callback();
      this.setState({
        newContact: false,
        phone: '',
        mail: '',
        salutation: 'Frau',
        firstname: '',
        name: '',
        invoiceRecipient: false,
        beneficiary: false,
        selectedContact: null,
        addressObject: {},
        isSubmitting: false,
        open: false,
        address: '',
      });
    } else {
      this.setState({ hasError: JSON.stringify(response), isSubmitting: false });
    }
  }

  selectAddress = (address) => {
    this.setState({
      addressAnchor: null,
      address:
        address.address.route +
        ' ' +
        address.address.street_number +
        ', ' +
        address.address.postal_code +
        ' ' +
        address.address.city.locality,
      addressObject: {
        street_number: address.address.street_number,
        route: address.address.route,
        locality: address.address.city.locality,
        administrative_area_level_1:
          address.address.city.state ?? address.address.city.administrative_area_level_1 ?? '',
        country: address.address.city.country ?? '',
        postal_code: address.address.postal_code,
      },
    });
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => {
        console.error('Error', error);
        if (error === 'ZERO_RESULTS') {
          this.handleAddressSetManualEdit();
        }
      });
  };

  handleAddressSetManualEdit = (event) => {
    this.setState({ manual_address_input: true });
  };

  handleAddressResetManualEdit = (event) => {
    this.setState({ manual_address_input: false });
  };

  handleAddressDetailChange = (event) => {
    this.setState(
      {
        ...this.state,
        addressObject: {
          ...this.state.addressObject,
          [event.target.name]: event.target.value,
          country:
            !!this.state.addressObject.country && this.state.addressObject.country.length >= 2
              ? this.state.addressObject.country
              : 'Deutschland',
        },
      },
      () => {
        let error = this.validateAddressObject(this.state.addressObject);
        this.setState({
          addressError:
            error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
          address: `${this.state.addressObject.route ?? ''} ${this.state.addressObject.street_number ?? ''}, ${
            this.state.addressObject.postal_code ?? ''
          } ${this.state.addressObject.locality ?? ''}`,
        });
      },
    );
  };

  validateAddressObject(newAddress) {
    let error = [];
    if (!!!newAddress.street_number || newAddress?.street_number === '') {
      error.push(TextDE.address.street_number);
    }
    if (!!!newAddress.route || newAddress?.route === '') {
      error.push(TextDE.address.route);
    }
    if (!!!newAddress.locality || newAddress?.locality === '') {
      error.push(TextDE.address.locality);
    }
    if (!!!newAddress.postal_code || newAddress?.postal_code === '') {
      error.push(TextDE.address.postal_code);
    }
    if (
      !!!newAddress.administrative_area_level_1 ||
      newAddress?.administrative_area_level_1 === ''
    ) {
      error.push(TextDE.address.administrative_area_level_1);
    }
    if (!!!newAddress.country || newAddress?.country === '') {
      error.push(TextDE.address.country);
    }
    return error;
  }

  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        newAddress[addressType] = val;
      }
    }

    let error = this.validateAddressObject(newAddress);

    this.setState({
      manual_address_input: error.length > 0,
      addressError: error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
      addressObject: newAddress,
      address: `${newAddress.route ?? ''} ${newAddress.street_number ?? ''}, ${
        newAddress.postal_code ?? ''
      } ${newAddress.locality ?? ''}`,
    });
  };

  handleAddressChange = (value) => {
    if (!!this.state.addressAnchor) {
      this.setState({ address: value });
    } else {
      this.setState({ address: value, addressAnchor: this.addressRef.current });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Card raised key={'new-contact'}>
        <CardHeader
          classes={{
            content: classes.cardHeaderContent,
            root: classes.cardHeaderRoot,
            action: classes.cardHeaderAction,
          }}
          avatar={
            <Avatar aria-label='recipe' className={classes.inlineAvatar}>
              <ContactPhoneIcon />
            </Avatar>
          }
          titleTypographyProps={{ noWrap: true, variant: 'h6' }}
          title={TextDE.contact.addLabel}
          action={
            <Button
              variant='text'
              style={{ height: '100%' }}
              startIcon={this.state.open ? <CloseIcon /> : <AddIcon />}
              onClick={this.handleAddClick}
            >
              {this.state.open ? TextDE.close : TextDE.add}
            </Button>
          }
        />
        <Collapse in={!this.state.newContact && this.state.open}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='subtitle1'>{TextDE.contact.helperText}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id='contactselect'
                  name='uuid'
                  options={this.state.searchResults}
                  value={this.state.selectedContact}
                  onChange={this.handleContactChange}
                  onInputChange={this.handleContactSearch}
                  loading={this.state.searchIsLoading}
                  loadingText='Suche ...'
                  noOptionsText={
                    !!this.state.searchNotFound
                      ? 'Kein bekannter Kontakt oder keine korrekte E-Mail Adresse gefunden ...'
                      : 'zum Suchen bitte min. 3 Zeichen eingeben'
                  }
                  renderOption={(option) => {
                    return (
                      <ListItem
                        component='div'
                        ContainerComponent='div'
                        divider={!!option.isNewContact}
                        classes={{
                          root: !!option.isNewContact
                            ? classes.contactListNew
                            : classes.contactListItem,
                        }}
                        key={option.full}
                      >
                        <ListItemAvatar>
                          <Avatar variant='rounded' className={classes.small}>
                            {!!option.isNewContact ? <AddIcon /> : <AccountCircleIcon />}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primaryTypographyProps={
                            !!option.isNewContact ? { style: { fontWeight: 'bolder' } } : null
                          }
                          primary={option.full}
                          secondary={option.address || ' '}
                        />
                      </ListItem>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label='Name' variant='outlined' />
                  )}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Regular option
                    return option.full;
                  }}
                  filterOptions={(options, params) => {
                    // Suggest the creation of a new value
                    if (params.inputValue.length >= 3) {
                      options.unshift({
                        isNewContact: true,
                        address: TextDE.contact.newContact,
                        inputValue: params.inputValue,
                        full: TextDE.contact.addAsNewContact(params.inputValue),
                      });
                    }

                    return options;
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
        <Collapse in={this.state.selectedContact && this.state.open && !this.state.newContact}>
          <CardContent style={{ paddingTop: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormGroup row style={{ justifyContent: 'space-between' }}>
                  <FormLabel>{TextDE.contact.contactForm.contactTag}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={this.props.paymentContact?.length > 0}
                        checked={this.state.invoiceRecipient}
                        onChange={this.handleChange}
                        name='invoiceRecipient'
                        color='primary'
                      />
                    }
                    label={Constants.tags.contacts.invoice[0]}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={this.props.localContact?.length > 0}
                        checked={this.state.beneficiary}
                        onChange={this.handleChange}
                        name='beneficiary'
                        color='primary'
                      />
                    }
                    label={Constants.tags.contacts.resident[0]}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          this.props.localContact?.length > 0 &&
                          (this.state.beneficiary || this.state.invoiceRecipient)
                        }
                        checked={this.state.otherContactType}
                        onChange={this.handleChange}
                        name='otherContactType'
                        color='primary'
                      />
                    }
                    label={TextDE.contact.contactForm.contactTagUndefinedLabel}
                  />
                </FormGroup>
              </Grid>
              {this.state.hasError !== false && this.state.hasError.length > 0 && (
                <Grid item xs={12}>
                  {this.state.hasError.map((option) => (
                    <Alert severity='error'>{option}</Alert>
                  ))}
                </Grid>
              )}
              <Grid item xs={4}>
                <Button
                  onClick={this.handleAddNewClose}
                  fullWidth
                  variant='contained'
                  className={classes.floatRightAbort}
                >
                  {TextDE.abort}
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  onClick={this.handleFormSubmit}
                  fullWidth
                  variant='contained'
                  className={classes.floatRightAdd}
                >
                  {TextDE.save}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Collapse>
        <Collapse in={this.state.newContact && this.state.open}>
          <CardContent>
            <Grid
              component='form'
              key='EmailForm'
              onSubmit={this.handleFormSubmit}
              container
              spacing={2}
            >
              <Grid item xs={1} className={classes.floatRight}>
                <AccountCircleIcon fontSize='large' />
              </Grid>

              <Grid item xs={3}>
                <TextField
                  classes={{ root: classes.customInputRoot }}
                  size='small'
                  required={true}
                  label={TextDE.form.salutation.label}
                  fullWidth
                  select
                  variant='outlined'
                  name='salutation'
                  value={this.state.salutation}
                  onChange={this.handleChange}
                >
                  {[
                    TextDE.contact.contactForm.salutation_male,
                    TextDE.contact.contactForm.salutation_female,
                    TextDE.contact.contactForm.salutation_other,
                    TextDE.contact.contactForm.company,
                  ].map((option) => (
                    <MenuItem key={'select-' + option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  classes={{ root: classes.customInputRoot }}
                  size='small'
                  required={false}
                  disabled={this.state.salutation === TextDE.contact.contactForm.company}
                  label={TextDE.form.firstname.label}
                  fullWidth
                  variant='outlined'
                  name='firstname'
                  value={this.state.firstname}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  classes={{ root: classes.customInputRoot }}
                  size='small'
                  required={true}
                  label={
                    this.state.salutation === TextDE.contact.contactForm.company
                      ? TextDE.form.companyname.label
                      : TextDE.form.lastname.label
                  }
                  fullWidth
                  variant='outlined'
                  name='name'
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={2} className={classes.floatRight}>
                <PhoneIcon fontSize='medium' />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  disabled={false}
                  size='small'
                  label={TextDE.form.phone.label}
                  type='tel'
                  error={!!this.state.phoneError}
                  helperText={!!this.state.phoneError ? this.state.phoneError : ''}
                  fullWidth
                  variant='outlined'
                  name='phone'
                  value={this.state.phone}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={2} className={classes.floatRight}>
                <AlternateEmailIcon fontSize='medium' />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  disabled={false}
                  size='small'
                  label={TextDE.form.email.label}
                  type='email'
                  error={!!this.state.mailError}
                  helperText={!!this.state.mailError ? this.state.mailError : ''}
                  fullWidth
                  variant='outlined'
                  name='mail'
                  value={this.state.mail}
                  onChange={this.handleChange}
                />
              </Grid>

              <Grid item xs={2} className={classes.floatRight}>
                <LocationOnIcon fontSize='medium' />
              </Grid>
              <Grid item xs={10}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <PlacesAutocomplete
                      value={this.state.address}
                      name='input'
                      label={TextDE.address.label}
                      onChange={this.handleAddressChange}
                      onSelect={this.handleAddressSelect}
                      searchOptions={{ types: ['address'] }}
                      shouldFetchSuggestions={this.state.address.length >= 4}
                    >
                      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div className={classes.container}>
                          <Grid
                            container
                            direction='row'
                            justifyContent='flex-start'
                            alignItems='stretch'
                            spacing={2}
                          >
                            <Grid item xs={8}>
                              <TextField
                                fullWidth
                                variant='outlined'
                                autoComplete='false'
                                {...getInputProps({
                                  label: TextDE.address.label,
                                  placeholder: TextDE.address.inputPlaceholder,
                                  className: 'location-search-input',
                                })}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {!!!this.state.manual_address_input ? (
                                <Button
                                  fullWidth
                                  style={{ height: '100%' }}
                                  onClick={this.handleAddressSetManualEdit}
                                  variant='contained'
                                  color='primary'
                                >
                                  {TextDE.procedure.addProcedureForm.addAddressManually}
                                </Button>
                              ) : (
                                <Button
                                  fullWidth
                                  style={{ height: '100%' }}
                                  onClick={this.handleAddressResetManualEdit}
                                  variant='outlined'
                                  color='secondary'
                                >
                                  {TextDE.procedure.addProcedureForm.addAddressViaSearch}
                                </Button>
                              )}
                            </Grid>
                          </Grid>

                          {/* Somehow evaluate if the Add Address Option was clicked ... */}
                          {suggestions.length > 0 && (
                            <Card className={classes.results}>
                              {loading && (
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              )}
                              {suggestions.map((suggestion, key) => {
                                return (
                                  <CardContent
                                    key={key}
                                    {...getSuggestionItemProps(suggestion, {})}
                                    className={classes.result}
                                  >
                                    <Grid container alignItems='center'>
                                      <Grid item>
                                        <LocationOnIcon className={classes.icon} />
                                      </Grid>
                                      <Grid item xs>
                                        <Typography variant='subtitle1'>
                                          {suggestion.formattedSuggestion.mainText}
                                        </Typography>
                                        <Typography variant='body1' color='textSecondary'>
                                          {suggestion.formattedSuggestion.secondaryText}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </CardContent>
                                );
                              })}
                            </Card>
                          )}
                        </div>
                      )}
                    </PlacesAutocomplete>
                  </Grid>
                  <Grid item xs={12}>
                    {!!this.state.addressError && (
                      <Alert severity='error'>{this.state.addressError}</Alert>
                    )}
                  </Grid>

                  {!!this.state.manual_address_input && (
                    <>
                      <Grid item xs={12}>
                        <Typography paragraph className={classes.mt}>
                          {TextDE.procedure.addProcedureForm.addAddressLabel}:
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <TextField
                              value={this.state.addressObject?.route}
                              name='route'
                              label={TextDE.address.route}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleAddressDetailChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextField
                              value={this.state.addressObject?.street_number}
                              name='street_number'
                              label={TextDE.address.street_number}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleAddressDetailChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              value={this.state.addressObject?.postal_code}
                              name='postal_code'
                              label={TextDE.address.postal_code}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleAddressDetailChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={7}>
                            <TextField
                              value={this.state.addressObject?.locality}
                              name='locality'
                              label={TextDE.address.locality}
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleAddressDetailChange}
                              fullWidth
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth variant='outlined'>
                              <InputLabel
                                id='administrative_area_level_1-label'
                                shrink={true}
                                variant='outlined'
                              >
                                {TextDE.address.administrative_area_level_1}
                              </InputLabel>
                              <Select
                                labelId='administrative_area_level_1-label'
                                id='administrative_area_level_1'
                                name='administrative_area_level_1'
                                label={TextDE.address.administrative_area_level_1}
                                value={this.state.addressObject?.administrative_area_level_1}
                                onChange={this.handleAddressDetailChange}
                              >
                                <MenuItem value=''> Bitte auswählen </MenuItem>
                                <MenuItem value='Baden-Württemberg'>Baden-Württemberg</MenuItem>
                                <MenuItem value='Bayern'> Bayern </MenuItem>
                                <MenuItem value='Berlin'> Berlin </MenuItem>
                                <MenuItem value='Brandenburg'> Brandenburg </MenuItem>
                                <MenuItem value='Bremen'> Bremen </MenuItem>
                                <MenuItem value='Hamburg'> Hamburg </MenuItem>
                                <MenuItem value='Hessen'> Hessen </MenuItem>
                                <MenuItem value='Mecklenburg-Vorpommern'>
                                  Mecklenburg-Vorpommern
                                </MenuItem>
                                <MenuItem value='Niedersachsen'> Niedersachsen </MenuItem>
                                <MenuItem value='Nordrhein-Westfalen'>Nordrhein-Westfalen</MenuItem>
                                <MenuItem value='Rheinland-Pfalz'> Rheinland-Pfalz </MenuItem>
                                <MenuItem value='Saarland'> Saarland </MenuItem>
                                <MenuItem value='Sachsen-Anhalt'> Sachsen-Anhalt </MenuItem>
                                <MenuItem value='Sachsen'> Sachsen </MenuItem>
                                <MenuItem value='Schleswig-Holstein'>Schleswig-Holstein</MenuItem>
                                <MenuItem value='Thüringen'> Thüringen </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              value={'Deutschland'}
                              name='country'
                              label={TextDE.address.country}
                              disabled
                              variant='outlined'
                              InputLabelProps={{ shrink: true }}
                              onChange={this.handleAddressDetailChange}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2}>
                &nbsp;
              </Grid>
              <Grid item xs={10}>
                <FormGroup row style={{ justifyContent: 'space-between' }}>
                  <FormLabel>{TextDE.contact.contactForm.contactTag}</FormLabel>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={this.props.paymentContact?.length > 0}
                        checked={this.state.invoiceRecipient}
                        onChange={this.handleChange}
                        name='invoiceRecipient'
                        color='primary'
                      />
                    }
                    label={Constants.tags.contacts.invoice[0]}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={this.props.localContact?.length > 0}
                        checked={this.state.beneficiary}
                        onChange={this.handleChange}
                        name='beneficiary'
                        color='primary'
                      />
                    }
                    label={Constants.tags.contacts.resident[0]}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={
                          this.props.localContact?.length > 0 &&
                          (this.state.beneficiary || this.state.invoiceRecipient)
                        }
                        checked={this.state.otherContactType}
                        onChange={this.handleChange}
                        name='otherContactType'
                        color='primary'
                      />
                    }
                    label={TextDE.contact.contactForm.contactTagUndefinedLabel}
                  />
                </FormGroup>
              </Grid>
              {this.state.hasError !== false && this.state.hasError.length > 0 && (
                <Grid item xs={12}>
                  {this.state.hasError.map((option) => (
                    <Alert severity='error'>{option}</Alert>
                  ))}
                </Grid>
              )}

              {this.state.isSubmitting && (
                <Grid xs={12} item component='center'>
                  {' '}
                  <CircularProgress disableShrink />{' '}
                </Grid>
              )}
              {!this.state.isSubmitting && (
                <Grid item xs={4}>
                  <Button
                    onClick={this.handleAddNewClose}
                    fullWidth
                    variant='contained'
                    className={classes.floatRightAbort}
                  >
                    {TextDE.abort}
                  </Button>
                </Grid>
              )}
              {!this.state.isSubmitting && (
                <Grid item xs={8}>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    className={classes.floatRightAdd}
                  >
                    {TextDE.save}
                  </Button>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContactCardAddNew);
