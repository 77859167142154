import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
import CircularProgress from '@material-ui/core/CircularProgress';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import error from '../assets/error.png';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  floatRight: {
    marginLeft: 'auto!important',
    float: 'right',
  },
  center: {
    maxHeight: '256px',
    width: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

class BugCatcher extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, description: '', loading: false };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    this.submitError = this.submitError.bind(this);
  }

  async submitError() {
    //let formData = { text: "Fehler gemeldet von "+this.props.User.email+":\nPfad: '"+window.location.href+"'\n\n"+this.state.error.toString() +"\n``` js\n"+JSON.stringify(this.state.errorInfo, "\n", "\t")+"\n```" };
    //let formData = { text: "Fehler gemeldet von "+this.props.User.email+":\nPfad: '"+window.location.href+"'\n\n"+this.state.error.toString() +"\n``` js\n"+this.state.errorInfo+"\n```" };
    //let formData = {
    //  text:
    //    'Fehler gemeldet von ' +
    //    this.props.User.email +
    //    ":\nPfad: '" +
    //    window.location.href +
    //    "'\n\n" +
    //    this.state.error.toString() +
    //    '\n``` js\n' +
    //    this.state.errorInfo.componentStack +
    //    '\n```',
    //};
    this.setState({ loading: true });

    let data = {
      request: this.state.description ?? ' Keine Beschreibung des Fehlers',
      url: window.location.pathname,
      who:
        'Fehler gemeldet von ' +
        (this.props.User?.firstname ?? ' no firstname ') +
        ' ' +
        (this.props.User?.lastname ?? ' no lastname ') +
        '( ' +
        (this.props.User?.email ?? ' no email ') +
        '/' +
        ((this.props.User?.mobile ?? ' no mobile ') || (this.props.User?.phone ?? ' no phone ')) +
        ' / UUID: ' +
        (this.props.User?.uuid ?? ' no uuid ') +
        ' )',
      error: this.state.errorInfo.componentStack,
    };

    let url = new URL(process.env.REACT_APP_API_URL + '/api/send/bugmail');
    data['_method'] = 'POST';
    let response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.error(error);
        return true;
      });

    if (!!response) {
      window.location.reload();
    }
  }

  render() {
    if (this.state.errorInfo) {
      const { classes } = this.props;
      return (
        <>
          <form>
            <Card className={classes.root}>
              <CardHeader title='Oops - das sollte nicht passieren ....' />
              <CardMedia
                component='img'
                alt='FLiXWORKER'
                image={error}
                title='FLiXWORKER'
                className={classes.center}
              />
              <CardContent>
                <details
                  style={{
                    whiteSpace: 'pre-wrap',
                    maxHeight: '400px',
                    overflow: 'auto',
                  }}
                >
                  {this.state.error && this.state.error.toString()}
                  <br />
                  {this.state.errorInfo.componentStack}
                </details>
                <Typography gutterBottom variant='h5' component='h2'>
                  Vielen Dank dass Sie diesen Fehler gefunden haben!
                </Typography>
                <Typography gutterBottom variant='h5' component='h2'>
                  Wie kam es denn dazu?
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  variant='outlined'
                  name='description'
                  label='Beschreibung'
                  required
                  minRows={4}
                  value={this.state.description}
                  onChange={this.handleChange}
                ></TextField>
              </CardContent>

              <CardActions>
                <Button size='small' color='primary' href='/dashboard'>
                  Zur Startseite
                </Button>
                {!!this.state.loading && <CircularProgress className={classes.floatRight} />}
                {!!!this.state.loading && (
                  <Button
                    className={classes.floatRight}
                    variant='contained'
                    color='primary'
                    onClick={this.submitError}
                  >
                    Fehler melden
                  </Button>
                )}
              </CardActions>
            </Card>
          </form>
        </>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Theme: state.Style.Theme,
});

export default compose(withStyles(styles), connect(mapStateToProps))(BugCatcher);
