/*
 * action types
 */

export const FETCH = 'FETCH_NAV_MENU';
export const COLLAPSE = 'COLLAPSE_NAV_MENU';
export const COLLAPSEITEM = 'COLLAPSE_NAV_MENU_ITEM';
export const RESET = 'RESET_NAV_MENU';
export const ADD = 'ADD_NAV_MENU';
export const REMOVE = 'REMOVE_NAV_MENU';
/*
 * action creators
 */
export const get = (config) => {
  return { type: FETCH, config };
};
export const collapse = (config) => {
  return { type: COLLAPSE, config };
};

export const collapseItem = (config) => {
  return { type: COLLAPSEITEM, config };
};

export const reset = () => {
  return { type: RESET };
};

export const saveMenu = (element) => {
  return {
    type: ADD,
    parent: element.parent,
    entry: {
      key: element.key,
      name: element.name,
      icon: !!element.icon ? element.icon : 'ListAlt',
      path: element.path,
      removable: element.removable,
    },
  };
};

export const saveMenuError = (element) => {
  return {
    type: ADD,
    parent: element.parent,
    entry: {
      key: element.key,
      name: element.name,
      icon: !!element.icon ? element.icon : 'ListAlt',
      path: element.path,
      removable: element.removable,
    },
  };
};

export const addCustomMenuPoint = (element) => {
  element = { ...element, removable: true };
  return async function (dispatch, getState) {
    let token = getState().Authentication;
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/users/menu', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ _method: 'PUT', menuentry: element }),
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(saveMenu(element));
        //console.log('[addCustomMenuPoint]', response);
        return response;
      })
      .catch((error) => {
        dispatch(saveMenuError(element));
        //console.log('[addCustomMenuPoint]', error);
        return error;
      });

    if (await response) {
      return response;
    } else {
      return false;
    }
  };
};
export const deleteMenu = (element) => {
  return { type: REMOVE, parent: element.parent, entry: element };
};
export const deleteMenuError = (element) => {
  return { type: REMOVE, parent: element.parent, entry: element };
};
export const removeCustomMenuPoint = (element) => {
  //console.log('[removeCustomMenuPoint]', element);
  return async function (dispatch, getState) {
    let token = getState().Authentication;
    return await fetch(process.env.REACT_APP_API_URL + '/api/users/menu', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token.access_token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ _method: 'DELETE', menuentry: element }),
    })
      .then((res) => {
        //console.log("[DEBUG] fetch", res);
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        //console.log("[DEBUG] Success", json);
        dispatch(deleteMenu(element));
        return true;
      })
      .catch((error) => {
        //console.log("[DEBUG] Error", error);
        return false;
      });
  };
};
