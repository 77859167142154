import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import { DropzoneArea } from 'material-ui-dropzone';
import { DE as TextDE } from '../../lib/Text';

import { Constants } from '../../lib/Constants';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    minHeight: '40vh',
    maxHeight: '99vh',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column',
  },
  wrapper: {
    marginTop: 'auto',
  },
  form: {
    flexGrow: '1',
    display: 'flex',
    flexFlow: 'column',
  },
  dialogTitle: {
    textAlign: 'center',
    margin: theme.spacing(0, -1, 2, -1),
    padding: 0,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
  my: {
    margin: theme.spacing(2, 0),
  },
  fh: {
    height: '100%',
  },
});

class AddFast extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    if (this.state.subject.trim() === '') {
      this.setState({
        hasError: (
          <Alert style={{ width: '90%', float: 'right' }} severity='error'>
            {TextDE.procedure.addProcedureForm.alerts.subjectMissing}
          </Alert>
        ),
      });
      return;
    }
    if (this.state.body.trim() === '') {
      this.setState({
        hasError: (
          <Alert style={{ width: '90%', float: 'right' }} severity='error'>
            {TextDE.procedure.addProcedureForm.alerts.descriptionMissing}
          </Alert>
        ),
      });
      return;
    }

    this.setState({ isSubmitting: true, hasError: false });
    let formData = new FormData();

    formData.append('subject', this.state.subject);
    formData.append('body', this.state.body);

    this.state.uploads.forEach((file, i) => {
      formData.append('uploads[]', file, file.name);
    });

    let endpoint = process.env.REACT_APP_API_URL;

    if (!!this.state.isWorker) {
      formData.append('users[0][uuid]', this.props.User.uuid);
      formData.append(
        'users[0][displayName]',
        this.props.User.firstname + ' ' + this.props.User.lastname,
      );
      formData.append('users[0][tags][]', 'Ersteller');
      this.props.User?.roles?.forEach((value) => formData.append('users[0][tags][]', value.name));
      endpoint = endpoint + '/api/procedures';
    } else {
      endpoint = endpoint + '/api/inbox';
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return 'UNKNOWN ERROR! - ' + error;
      });

    //if (response.trim().toLowerCase() === "created") {
    if (!!response.uuid) {
      this.setState({ isSubmitting: false, hasError: false });
      this.props.handleclosefunction(response.uuid);
    } else {
      this.setState({ isSubmitting: false, hasError: response });
    }
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFileChange = async (files) => {
    this.setState({ uploads: files });
  };

  constructor(props) {
    super(props);

    this.state = {
      display: false,
      isSubmitting: false,
      hasError: false,
      subject: '',
      body: '',
      uploads: [],
      isWorker: !Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
        Constants.roles.ROLES_PHONE,
      ]),
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root}>
          {this.state.isFetching ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <>
              <DialogTitle className={classes.dialogTitle} id='handle'>
                {TextDE.procedure.addFastInboxLabel}
                <IconButton
                  aria-label='close'
                  style={{ marginTop: '-1em', float: 'right', width: 0 }}
                  onClick={() => this.props.handleclosefunction(false)}
                >
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <form onSubmit={this.handleFormSubmit} className={classes.form}>
                <Grid
                  className={classes.fh}
                  container
                  direction='column'
                  justifyContent='flex-start'
                  alignItems='stretch'
                >
                  <Grid item>
                    <TextField
                      fullWidth
                      required
                      id='subject'
                      name='subject'
                      label={TextDE.form.subject.label}
                      type='text'
                      onChange={this.handleChange}
                      value={this.state.subject}
                    />
                  </Grid>
                  <Grid item className={classes.mt}>
                    <TextField
                      fullWidth
                      name='body'
                      label={TextDE.form.description.label}
                      required
                      variant='outlined'
                      multiline
                      minRows={5}
                      maxRows={5}
                      onChange={this.handleChange}
                      value={this.state.body}
                    />
                  </Grid>

                  <Grid item className={classes.my}>
                    <DropzoneArea
                      dropzoneText={TextDE.mailform.attachment.helperTextBig}
                      acceptedFiles={[
                        'application/*',
                        'audio/*',
                        'drawing/*',
                        'image/*',
                        'message/*',
                        'multipart/*',
                        'model/*',
                        'text/*',
                        'video/*',
                      ]}
                      showPreviewsInDropzone={true}
                      useChipsForPreview={true}
                      previewChipProps={{ style: { maxWidth: '100%' } }}
                      showFileNames={true}
                      showAlerts={true}
                      onChange={this.handleFileChange}
                      maxFileSize={30000000}
                      filesLimit={8}
                      dropzoneClass='small'
                    />
                  </Grid>

                  {!!this.state.hasError && (
                    <Grid item>
                      <Alert severity='error' style={{ width: '100%' }}>
                        {this.state.hasError}
                      </Alert>
                    </Grid>
                  )}

                  <Grid item className={classes.wrapper}>
                    {this.state.isSubmitting ? (
                      <center>
                        <CircularProgress size={24} className={classes.buttonProgress} />{' '}
                      </center>
                    ) : (
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        disabled={this.state.isSubmitting}
                        fullWidth
                      >
                        {TextDE.save}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </form>
            </>
          )}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state, ownProps) => ({
  User: state.User,
  Authentication: state.Authentication,
  dialogData: ownProps.dialogData,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(AddFast);
