import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import UpdateIcon from '@material-ui/icons/Update';
import renderHtml from '../../../lib/transformHtml';
import { readable } from '../../../lib/dateFunctions';
import ComputerIcon from '@material-ui/icons/Computer';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 1),
    width: '100%',
    borderRadius: '14px',
    backgroundColor: '#ffffff',
  },
  avatar: {
    minWidth: theme.spacing(7),
    minHeight: theme.spacing(7),
    '&>svg': {
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5),
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
  },
  noPad: {
    padding: '0px!important',
  },
  alignMiddle: {
    display: 'flex',
    alignItems: 'center',
    '& > b': {
      margin: theme.spacing(0, 2, 0, 1),
    },
    flexGrow: 1,
  },
  flexRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'end',
    '&>svg': {
      marginRight: '1em',
    },
    justifyContent: 'end',
  },
  header: {
    paddingBottom: 0,
  },
  expanded: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  content: {
    padding: theme.spacing(2),
    whiteSpace: 'pre',
  },
});

class System extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <Card raised={true} variant='outlined' className={classes.root}>
        <CardHeader
          className={classes.header}
          disableTypography={true}
          avatar={
            <Avatar className={classes.avatar} alt='System'>
              <ComputerIcon />{' '}
            </Avatar>
          }
          title={
            <>
              <Typography className={classes.alignMiddle} variant='h5'>
                {this.props.item.data?.subject}
              </Typography>
            </>
          }
          action={
            <Typography variant='overline' component='p' className={classes.flexRight}>
              <UpdateIcon fontSize='small' />
              {readable(this.props.item.created_at)}
            </Typography>
          }
        />

        <CardContent className={classes.content}>
          {renderHtml(this.props.item.data?.body)}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(System);
