import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { compose } from 'react-recompose';
//import { ThemeProvider } from '@material-ui/core/styles';
import Echo from 'laravel-echo';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { fetchUser, fetchCustomer, fetchOther, setEcho, appendInbox } from '../actions/AutoRefresh';
//import {updateThemeExternal, updateCustomerExternal, updateUserExternal} from '../actions/User'
import { updateCustomerExternal } from '../actions/User';
import { addNotificationWrapper } from '../actions/Notifications';
import { getTheme as fetchTheme, getLogo as fetchLogo } from '../actions/Style';
import { fetch as fetchUserObject } from '../actions/User';
import Tooltip from '@material-ui/core/Tooltip';
import Constants from '../lib/Constants';
import * as PushManager from './enable-push';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
//require('socket.io-client');

function sleep(ms, abortSignal) {
  return new Promise((resolve, reject) => {
    //const error = new DOMException( 'aborted by Unmount', 'AbortError' ); // 1
    const error = 1337; // 1

    if (abortSignal.aborted) {
      // 2
      return reject(error);
    }

    const timeout = setTimeout(() => {
      resolve(1);
    }, ms);

    abortSignal.addEventListener('abort', () => {
      clearTimeout(timeout);
      reject(error);
    });
  }).catch(function (e) {
    //im ignorable
    if (e !== 1337) {
      console.error('[EventSubscriber] componentWillUnmount - abort Error:', e);
    }
  });
}

function play() {
  var audio = new Audio('/assets/alert.wav');
  audio.play();
}

class EventSubscriber extends React.Component {
  constructor(props) {
    super(props);
    this.abortController = new AbortController();
    this.state = {
      abortSignal: this.abortController.signal,
      autoRefresh: false,
      localEchoOptions: false,
      echoInstance: false,
      mounted: false,
      connectError: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.startup = this.startup.bind(this);
    this.callbackEchoFailed = this.callbackEchoFailed.bind(this);
    this.callbackEchoUnavailable = this.callbackEchoUnavailable.bind(this);
    this.callbackEchoConnected = this.callbackEchoConnected.bind(this);
    this.callbackEchoDisconnected = this.callbackEchoDisconnected.bind(this);
    this.callbackEchoConnecting = this.callbackEchoConnecting.bind(this);
  }

  callbackEchoFailed() {
    console.error('[AUTO-REFRESH] Status: connection FAILED');
    this.setState({ connectError: true });
    sleep(15000, this.state.abortSignal).then(this.startup);
  }
  callbackEchoUnavailable() {
    console.error('[AUTO-REFRESH] Status: connection unavailable');
    this.setState({ connectError: true });
    sleep(15000, this.state.abortSignal).then(this.startup);
  }
  callbackEchoConnected() {
    console.log('[AUTO-REFRESH] Status: connected');
    this.setState({ connectError: false });
  }
  callbackEchoDisconnected() {
    console.log('[AUTO-REFRESH] Status: disconnected');
    this.setState({ connectError: true });
  }
  callbackEchoConnecting() {
    console.log('[AUTO-REFRESH] Status: reconnecting');
    this.setState({ connectError: true });
  }

  async startup() {
    if (!!!this.state.mounted) {
      return;
    }
    if (!!this.props.User?.notification?.push) {
      if (!('Notification' in window)) {
        console.log('This browser does not support desktop notification');
      }

      // Let's check whether notification permissions have alredy been granted
      else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        if (
          !!this &&
          !!this.props &&
          !!this.props.Authentication &&
          this.props.Authentication !== null
        ) {
          PushManager.initSW(this.props.Authentication);
        }
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied' || Notification.permission === 'default') {
        await Notification.requestPermission(function (permission) {
          // If the user accepts, let's create a notification
          if (permission === 'granted') {
            new Notification('Danke!');
            if (
              !!this &&
              !!this.props &&
              !!this.props.Authentication &&
              this.props.Authentication !== null
            ) {
              PushManager.initSW(this.props.Authentication);
            }
          }
        });
      }
    }

    let localEchoOptions = this.state.Echo;
    if (!!localEchoOptions === false) {
      window.Pusher = require('pusher-js');
      if (
        typeof window.Pusher !== 'undefined' &&
        this.props.Authentication !== null &&
        this.props.User !== null
      ) {
        localEchoOptions = {
          broadcaster: 'pusher',
          key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
          wsHost: process.env.REACT_APP_MIX_PUSHER_APP_HOST,
          wsPort: process.env.REACT_APP_MIX_PUSHER_APP_PORT,
          wsPath: process.env.REACT_APP_MIX_PUSHER_APP_PATH,
          httpHost: process.env.REACT_APP_MIX_PUSHER_APP_HOST,
          httpPath: process.env.REACT_APP_MIX_PUSHER_APP_PATH,
          //authEndpoint is your apiUrl + /broadcasting/auth
          //authEndpoint is your apiUrl + /broadcasting/auth
          authEndpoint: process.env.REACT_APP_MIX_PUSHER_APP_AUTH,
          // As I'm using JWT tokens, I need to manually set up the headers.
          enabledTransports: ['ws', 'wss'],
          forceTLS: false,
          encrypted: true,
          disableStats: true,
          reconnectionDelay: 5000,
          reconnectionDelayMax: 90000,
          randomizationFactor: 0.4,
          auth: {
            headers: {
              Authorization: `Bearer ${this.props.Authentication.access_token}`,
              Accept: 'application/json',
            },
          },
        };

        this.props.dispatch(setEcho({ Echo: localEchoOptions }));
        this.setState({ localEchoOptions: localEchoOptions });
      }
    }

    let myEcho = false;
    if (!!window.Echo !== false) {
      console.log('[AUTO-REFRESH] Found Window Echo'); //, window.Echo); //, message);
      myEcho = window.Echo.disconnect();
    }
    console.log('[AUTO-REFRESH] Generate new Echo echoInstance'); //, message);
    myEcho = new Echo(localEchoOptions);

    if (myEcho !== false) {
      // Events available here https://pusher.com/docs/channels/using_channels/connection/
      myEcho.connector.pusher.connection.bind('failed', this.callbackEchoFailed);

      myEcho.connector.pusher.connection.bind('unavailable', this.callbackEchoUnavailable);

      myEcho.connector.pusher.connection.bind('connected', this.callbackEchoConnected);

      myEcho.connector.pusher.connection.bind('disconnected', this.callbackEchoDisconnected);

      myEcho.connector.pusher.connection.bind('connecting', this.callbackEchoConnecting);

      if (!this.props.AutoRefresh.fetchCustomer) {
        //this.subscribe([`notifications-customer-${this.props.User.customer.uuid}`]);
        let channelName = `notifications-customer-${this.props.User.customer.uuid}`;
        myEcho
          .private(channelName)
          .notification((message) => {
            play();
            console.log('[AUTO-REFRESH] Customer - Notification'); //, message);
            this.props.dispatch(addNotificationWrapper(message));
          })
          .listen('ChangedCustomerEvent', (newEvent) => {
            console.log('[AUTO-REFRESH] ChangedCustomerEvent'); //, newEvent);
            this.props.dispatch(updateCustomerExternal(newEvent));
          })
          .listen('ChangedUserEvent', (newEvent) => {
            console.log('[AUTO-REFRESH] ChangedUserEvent'); //, newEvent);
            //this.props.dispatch(updateUserExternal(newEvent));
            //this.props.dispatch(fetchUserObject(newEvent.user));
          })
          .listen('ChangedThemeEvent', (newEvent) => {
            console.log('[AUTO-REFRESH] ChangedThemeEvent'); //, newEvent);
            //this.props.dispatch(updateThemeExternal(newEvent));
            this.props.dispatch(fetchTheme(newEvent.theme));
            this.props.dispatch(fetchLogo(newEvent.theme));
          });
        console.log(
          '[AUTO-REFRESH] Subscribed to Event ChangedCustomerEvent, ChangedUserEvent, ChangedThemeEvent on Customer Channel',
        );
        this.props.dispatch(
          fetchCustomer({
            fetchCustomer: !this.props.AutoRefresh.fetchCustomer,
          }),
        );
      }

      if (!this.props.AutoRefresh.fetchUser) {
        //this.subscribe([`notifications-user-${this.props.User.uuid}`]);
        let channelName = `notifications-user-${this.props.User.uuid}`;
        myEcho
          .private(channelName)
          .notification((message) => {
            play();
            console.log('[AUTO-REFRESH] User - Notification'); //, message);
            this.props.dispatch(addNotificationWrapper(message));
          })
          .listen('ChangedUserEvent', (newEvent) => {
            console.log('[AUTO-REFRESH] ChangedUserEvent'); //, newEvent);
            this.props.dispatch(fetchUserObject(newEvent.user));
          });
        console.log('[AUTO-REFRESH] Subscribed to Event ChangedUserEvent on User Channel');
        this.props.dispatch(fetchUser({ fetchUser: !this.props.AutoRefresh.fetchUser }));
      }

      if (this.props.AutoRefresh.fetchOther === false) {
        var path = this.props.router.location.pathname.slice(1).split('/');
        if (typeof Constants.eventMap[path[0]] !== 'undefined') {
          Constants.eventMap[path[0]].forEach((eventName, index) => {
            myEcho
              .private(`notifications-customer-${this.props.User.customer.uuid}`)
              .listen(eventName, (newEvent) => {
                console.log('[AUTO-REFRESH] Customer - Event'); //, newEvent);
                if (
                  !(
                    !!newEvent.dontNotify &&
                    typeof newEvent.dontNotify === 'object' &&
                    newEvent.dontNotify.includes(this.props.User.uuid)
                  )
                ) {
                  this.props.dispatch(appendInbox(newEvent));
                }
              });
            console.log('[AUTO-REFRESH] Subscribed to Event ' + eventName);
          });
          this.props.dispatch(fetchOther({ fetchOther: true }));
        }
      }
      if (!!this.state.mounted) {
        this.setState({ echoInstance: myEcho });
        window.Echo = myEcho;
      }
    }
  }

  handleChange() {
    //var path = this.props.router.location.pathname.slice(1).replace(/\//g, '-');
    var path = this.props.router.location.pathname.slice(1).split('/');
    if (this.props.AutoRefresh.fetchOther) {
      this.unsubscribe(Constants.eventMap[path[0]]);
      this.props.dispatch(fetchOther({ fetchOther: !this.props.AutoRefresh.fetchOther }));
    } else {
      this.subscribe(Constants.eventMap[path[0]]);
      this.props.dispatch(fetchOther({ fetchOther: !this.props.AutoRefresh.fetchOther }));
    }
  }

  componentDidMount() {
    //console.log("[AUTO-REFRESH] Starting ...", this.state, this.props);
    if (!!this.state.mounted === false) {
      sleep(754, this.state.abortSignal).then(() =>
        this.setState({ mounted: true }, () => this.startup()),
      );
    }
  }

  //componentDidUpdate() {
  //  console.log("[AUTO-REFRESH] componentDidUpdate");
  //  //let reload = !this.props.AutoRefresh.fetchCustomer ? 1 : 0;
  //  //reload += !this.props.AutoRefresh.fetchCustomer ? 1 : 0;
  //  //console.log(reload)
  //}

  componentWillUnmount() {
    //console.log("[AUTO-REFRESH] componentWillUnmount", this.state.echoInstance);
    this.abortController.abort();
    //if(this.state.echoInstance !== false) {
    //  try {
    //    this.state.echoInstance.leaveAllChannels();
    //  } catch (error) {
    //    console.error(error);
    //    // Expected output: ReferenceError: nonExistentFunction is not defined
    //    // (Note: the exact output may be browser-dependent)
    //  }
    //  try {
    //    this.state.echoInstance.disconnect();
    //  } catch (error) {
    //    console.error(error);
    //    // Expected output: ReferenceError: nonExistentFunction is not defined
    //    // (Note: the exact output may be browser-dependent)
    //  }
    //}
    //echoInstance
    this.setState({
      autoRefresh: false,
      localEchoOptions: false,
      echoInstance: false,
      mounted: false,
      connectError: true,
    });
    /*
    //Totally useless ...
    if(typeof this.state.Echo !== "undefined" && this.state.Echo !== false ) {
      let channelName = `notifications-customer-${this.props.User.customer.uuid}`;
      let events = ["ChangedCustomerEvent", "ChangedUserEvent", "ChangedThemeEvent"]

      var path = this.props.router.location.pathname.slice(1).split("/");
      if (!!path && Array.isArray(Constants.eventMap[path[0]]) && Constants.eventMap[path[0]]?.length > 0) {
        events.concat( Constants.eventMap[path[0]] );
      }
      events.forEach( (eventName, index) => {
        this.state.Echo.private(channelName).stopListening(eventName);
        console.log("[AUTO-REFRESH] Unsubscribed of Channel Customer from Event " + eventName); 
      });

      channelName = `notifications-user-${this.props.User.uuid}`;
      events = ["ChangedUserEvent"]
      events.forEach( (eventName, index) => {
        this.state.Echo.private(channelName).stopListening(eventName);
        console.log("[AUTO-REFRESH] Unsubscribed of Channel User from Event " + eventName); 
      });
    }
  */
  }

  subscribe(eventNames) {
    if (typeof this.state.Echo !== 'undefined' && this.state.Echo !== false) {
      eventNames.forEach((eventName, index) => {
        this.state.Echo.private(`notifications-customer-${this.props.User.customer.uuid}`).listen(
          eventName,
          (newEvent) => {
            console.log('[AUTO-REFRESH] Customer - Event'); //, newEvent);
            if (
              !(
                !!newEvent.dontNotify &&
                typeof newEvent.dontNotify === 'object' &&
                newEvent.dontNotify.includes(this.props.User.uuid)
              )
            ) {
              this.props.dispatch(appendInbox(newEvent));
            }
          },
        );
        console.log('[AUTO-REFRESH] Subscribed to Event ' + eventName);
      });
    }
  }

  unsubscribe(eventNames) {
    if (typeof this.state.Echo !== 'undefined' && this.state.Echo !== false) {
      eventNames.forEach((eventName, index) => {
        this.state.Echo.private(
          `notifications-customer-${this.props.User.customer.uuid}`,
        ).stopListening(eventName);
        console.log('[AUTO-REFRESH] Unsubscribed from Event ' + eventName);
      });
    }
  }

  render() {
    if (this.state.connectError) {
      return (
        <>
          <Tooltip title={'Verbindung zum Benachrichtigungsserver wird hergestellt!'}>
            <SyncDisabledIcon color='error' />
          </Tooltip>
        </>
      );
    }
    return <NotificationImportantIcon />;
  }
}

// Meh
const mapStateToProps = (state) => ({
  AutoRefresh: state.AutoRefresh,
  router: state.router,
  Authentication: state.Authentication,
  User: state.User,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(connect(mapStateToProps, mapDispatchToProps))(EventSubscriber);
