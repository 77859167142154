import React, { createRef } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ShareIcon from '@material-ui/icons/Share';
import DirectionsIcon from '@material-ui/icons/Directions';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Constants } from '../../../lib/Constants';
import Alert from '@material-ui/lab/Alert';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import Tooltip from '@material-ui/core/Tooltip';
import { DE as TextDE } from '../../../lib/Text';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DescriptionIcon from '@material-ui/icons/Description';
import WorkIcon from '@material-ui/icons/Work';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIcon from '@material-ui/icons/Phone';
import StarIcon from '@material-ui/icons/Star';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SaveIcon from '@material-ui/icons/Save';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  alert: {
    padding: theme.spacing(0, 1, 0, 5),
    margin: '4px 0 8px 0',
    zIndex: 1,
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  cardHeaderContent: {
    height: 'auto',
    paddingBottom: 0,
  },
  cardHeaderAction: {},
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#aed581',
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  customInputRoot: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  xs: {
    width: theme.spacing(1.2),
    height: theme.spacing(1.2),
  },
  small: {
    backgroundColor: theme.palette.text.primary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&>svg': {
      color: theme.palette.background.default,
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  mt: {
    marginTop: theme.spacing(2),
  },
  pointer: {
    color: theme.palette.text.disabled,
    cursor: 'copy',
  },
  pointerDetail: {
    cursor: 'pointer',
  },
  floatRightAdd: {
    marginTop: theme.spacing(1),
    float: 'right',
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.success.light,
      borderColor: theme.palette.success.light,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.success.main,
      borderColor: theme.palette.success.dark,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem ' + theme.palette.success.main + 'bb',
    },
  },
  floatRightDel: {
    marginTop: theme.spacing(1),
    float: 'right',
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.warning.light,
      borderColor: theme.palette.warning.light,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: theme.palette.warning.main,
      borderColor: theme.palette.warning.dark,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem ' + theme.palette.warning.main + 'bb',
    },
  },
  results: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 123123,
    maxHeight: '300px',
    overflow: 'auto',
    boxShadow: theme.shadows[4],
  },
});
class ContactCardDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      copy: false,
      showDetail: false,
      invoiceRecipient: false,
      invoiceRecipientChanged: false,
      beneficiary: false,
      beneficiaryChanged: false,
      newPhoneNumber: '',
      newPhoneNumberError: false,
      newEmailAddress: '',
      newEmailAddressError: false,
      address: '',
      hasError: false,
      manual_address_input: false,
      addressError: '',
      addressObject: {},
      addressAnchor: null,
      toDelete: false,
    };

    this.addressRef = createRef();

    this.selectAddressClose = this.selectAddressClose.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.decodeAddress = this.decodeAddress.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this);

    this.handleSaveNewPhone = this.handleSaveNewPhone.bind(this);

    this.handleSaveNewEmail = this.handleSaveNewEmail.bind(this);

    this.handleSaveNewAddress = this.handleSaveNewAddress.bind(this);

    this.handleAddressSetManualEdit = this.handleAddressSetManualEdit.bind(this);
    this.handleAddressResetManualEdit = this.handleAddressResetManualEdit.bind(this);

    this.handleSaveTags = this.handleSaveTags.bind(this);

    this.handleDetailClick = this.handleDetailClick.bind(this);
    this.handleDetailClose = this.handleDetailClose.bind(this);

    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.handleCopyClose = this.handleCopyClose.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    //console.log("Did Mount");
  }

  componentDidUpdate(oldProps, oldState) {
    let newState = {};
    //console.log("compare oldProps.paymentContact with props.paymentContact (same?): ", oldProps.paymentContact === this.props.paymentContact, oldProps.paymentContact, this.props.paymentContact);
    if (oldProps.paymentContact !== this.props.paymentContact) {
      newState['invoiceRecipientChanged'] = false;
    }
    //console.log("compare oldProps.localContact with props.localContact (same?): ", oldProps.localContact === this.props.localContact, oldProps.localContact, this.props.localContact);
    if (oldProps.localContact !== this.props.localContact) {
      newState['beneficiaryChanged'] = false;
    }

    if (Object.keys(newState).length > 0) {
      this.setState(newState);
    }
  }

  handleChange = (event) => {
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      if (this.state[event.target.name]) {
        this.setState({
          [event.target.name]: event.target.checked,
          [event.target.name + 'Changed']: true,
        });
      } else {
        this.setState({ [event.target.name]: event.target.checked });
      }
    } else {
      let state = { [event.target.name]: event.target.value };
      if (event.target.name === 'newPhoneNumber') {
        if (
          !event.target.value.match(/^([+|00](\d{1,3})\s?)?((\(\d{3,5}\)|\d{3,5})(\s)?)\d{3,8}$/gi)
        ) {
          state.newPhoneNumberError = TextDE.form.phone.validation.wrongFormat;
        } else {
          state.newPhoneNumberError = false;
        }
      }

      if (event.target.name === 'newEmailAddress') {
        // eslint-disable-next-line
        if (
          !event.target.value.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          )
        ) {
          state.newEmailAddressError = TextDE.form.email.validation.wrongFormat;
        } else {
          state.newEmailAddressError = false;
        }
      }
      this.setState(state);
    }
  };

  handleEditClick = () => {
    let invoiceRecipient =
      this.props.contactWrapper.tags.findIndex((el) =>
        [...Constants.tags.contacts.invoice, ...Constants.tags.contacts.invoiceHidden].includes(
          el?.name?.de || el,
        ),
      ) >= 0;
    //let invoiceRecipientChanged = !invoiceRecipient;

    let beneficiary =
      this.props.contactWrapper.tags.findIndex((el) =>
        [...Constants.tags.contacts.resident, ...Constants.tags.contacts.residentHidden].includes(
          el?.name?.de || el,
        ),
      ) >= 0;
    //let beneficiaryChanged = !beneficiary;
    //this.setState({edit: !this.state.edit, invoiceRecipient: invoiceRecipient, beneficiary: beneficiary, invoiceRecipientChanged: invoiceRecipientChanged,beneficiaryChanged: beneficiaryChanged,});
    this.setState({
      edit: !this.state.edit,
      invoiceRecipient: invoiceRecipient,
      beneficiary: beneficiary,
    });
  };

  handleEditClose = () => {
    this.setState({ edit: false });
  };

  handleDeleteClick = () => {
    this.setState({ deleteDialogOpen: true });
  };
  closeDeleteDialog() {
    this.setState({ deleteDialogOpen: false });
  }

  handleSaveNewPhone = async () => {
    let formData = {};
    formData['phones'] = [
      {
        number: this.state.newPhoneNumber,
        work: false,
        pref: false,
        fax: false,
        cell: false,
      },
    ];
    let response = await this.submitContactChange(formData);
    if (response.uuid === this.props.contactWrapper.contact.uuid) {
      this.props.callback();
    }
  };
  handleSaveTags = async () => {
    let formData = {};
    formData['_method'] = 'PUT';
    formData['context'] = 'contacts';
    formData['contact'] = this.props.contactWrapper.contact.uuid;
    let tags = [];
    if (this.state.beneficiary) {
      tags.push(Constants.tags.contacts.resident[0]);
    }

    if (this.state.invoiceRecipient) {
      tags.push(Constants.tags.contacts.invoice[0]);
    }
    formData['tags'] = tags;

    let response = await this.submitProcedureChange(formData);
    if (response.uuid === this.props.contactWrapper.contact.uuid) {
      this.props.callback();
    }
  };
  handleSaveNewEmail = async () => {
    let formData = {};
    formData['emails'] = [
      {
        email: this.state.newEmailAddress,
        work: false,
        pref: false,
      },
    ];

    let response = await this.submitContactChange(formData);
    if (response.uuid === this.props.contactWrapper.contact.uuid) {
      this.props.callback();
    }
  };

  handleSaveNewAddress = async () => {
    let formData = {};
    formData['_method'] = 'PUT';
    formData['address'] = this.state.addressObject;
    formData['context'] = 'addresses';

    let addressTags = [];
    if (this.state.beneficiary) {
      addressTags.push(Constants.tags.addresses.resident[0]);
    }

    if (this.state.invoiceRecipient) {
      addressTags.push(Constants.tags.addresses.invoice[0]);
    }

    formData['tags'] = addressTags;
    // edit the contact ? => let response = await this.submitContactChange(formData);
    let response = await this.submitProcedureChange(formData);
    if (!!response.id) {
      this.setState({
        address: '',
        addressError: false,
        addressObject: false,
      });
      this.props.callback();
    }
  };

  handleDetailClick = () => {
    console.log('handleDetailClick - nyi');
  };

  handleDetailClose = () => {
    console.log('handleDetailClose - nyi');
  };
  async submitProcedureChange(formData) {
    formData['uuid'] = this.props.inboxUuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      });

    return response;
  }

  async submitContactChange(formData) {
    formData['uuid'] = this.props.contactWrapper.contact.uuid;
    formData['_method'] = 'PUT';
    formData['firstname'] = this.props.contactWrapper.contact.firstname;
    formData['name'] = this.props.contactWrapper.contact.name;
    formData['salutation_id'] = this.props.contactWrapper.contact.salutation_id;

    let tags = [];
    let addressTags = [];
    if (this.state.beneficiary) {
      tags.push(Constants.tags.contacts.resident[0]);
      addressTags.push(Constants.tags.addresses.resident[0]);
    }

    if (this.state.invoiceRecipient) {
      tags.push(Constants.tags.contacts.invoice[0]);
      addressTags.push(Constants.tags.addresses.invoice[0]);
    }

    let endpoint = process.env.REACT_APP_API_URL + '/api/contacts/' + formData.uuid;
    //console.log(formData, endpoint);

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
    return response;
  }

  copyToClipboard = (identifier, value) => {
    this.setState({ copy: identifier });
    var textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setTimeout(() => this.setState({ copy: false }), 1500);
  };

  handleCopyClose = () => {
    if (this.state.copy !== false) {
      this.setState({ copy: false });
    }
  };

  selectAddressClose = () => {
    this.setState({
      addressAnchor: null,
    });
  };

  selectAddress = (address) => {
    this.setState({
      addressAnchor: null,
      address:
        address.address.route +
        ' ' +
        address.address.street_number +
        ', ' +
        address.address.postal_code +
        ' ' +
        address.address.city.locality,
      addressObject: {
        street_number: address.address.street_number,
        route: address.address.route,
        locality: address.address.city.locality,
        administrative_area_level_1:
          address.address.city.state ?? address.address.city.administrative_area_level_1 ?? '',
        country: address.address.city.country ?? '',
        postal_code: address.address.postal_code,
      },
    });
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => {
        console.error('Error', error);
        if (error === 'ZERO_RESULTS') {
          this.handleAddressSetManualEdit();
        }
      });
  };

  handleAddressSetManualEdit = (event) => {
    this.setState({ manual_address_input: true });
  };

  handleAddressResetManualEdit = (event) => {
    this.setState({ manual_address_input: false });
  };

  handleAddressDetailChange = (event) => {
    this.setState(
      {
        ...this.state,
        addressObject: {
          ...this.state.addressObject,
          [event.target.name]: event.target.value,
          country:
            !!this.state.addressObject.country && this.state.addressObject.country.length >= 2
              ? this.state.addressObject.country
              : 'Deutschland',
        },
      },
      () => {
        let error = this.validateAddressObject(this.state.addressObject);
        this.setState({
          addressError:
            error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
          address: `${this.state.addressObject.route ?? ''} ${this.state.addressObject.street_number ?? ''}, ${
            this.state.addressObject.postal_code ?? ''
          } ${this.state.addressObject.locality ?? ''}`,
        });
      },
    );
  };

  validateAddressObject(newAddress) {
    let error = [];
    if (!!!newAddress.street_number || newAddress?.street_number === '') {
      error.push(TextDE.address.street_number);
    }
    if (!!!newAddress.route || newAddress?.route === '') {
      error.push(TextDE.address.route);
    }
    if (!!!newAddress.locality || newAddress?.locality === '') {
      error.push(TextDE.address.locality);
    }
    if (!!!newAddress.postal_code || newAddress?.postal_code === '') {
      error.push(TextDE.address.postal_code);
    }
    if (
      !!!newAddress.administrative_area_level_1 ||
      newAddress?.administrative_area_level_1 === ''
    ) {
      error.push(TextDE.address.administrative_area_level_1);
    }
    if (!!!newAddress.country || newAddress?.country === '') {
      error.push(TextDE.address.country);
    }
    return error;
  }

  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        newAddress[addressType] = val;
      }
    }
    let error = this.validateAddressObject(newAddress);

    this.setState({
      manual_address_input: error.length > 0,
      addressError: error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
      addressObject: newAddress,
      address: `${newAddress.route ?? ''} ${newAddress.street_number ?? ''}, ${
        newAddress.postal_code ?? ''
      } ${newAddress.locality ?? ''}`,
    });
  };

  handleAddressChange = (value) => {
    if (!!this.state.addressAnchor) {
      this.setState({ address: value });
    } else {
      this.setState({ address: value, addressAnchor: this.addressRef.current });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Card raised className={classes.mt}>
        <CardHeader
          classes={{
            content: classes.cardHeaderContent,
            root: classes.cardHeaderRoot,
            action: classes.cardHeaderAction,
          }}
          avatar={
            <Avatar aria-label='recipe' className={classes.inlineAvatar}>
              <ContactPhoneIcon />
            </Avatar>
          }
          titleTypographyProps={{ noWrap: true, variant: 'h6' }}
          title={
            this.props.contactWrapper.tags.length > 0
              ? this.props.contactWrapper.tags.map((item) => item.name?.de ?? item).join(', ')
              : TextDE.contact.label
          }
          action={
            <>
              {!!this.props.contactWrapper.contact.connect_uuid && (
                <Tooltip title={TextDE.contact.contactForm.isConnectUser}>
                  <IconButton>
                    <SettingsEthernetIcon />{' '}
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip
                title={
                  !!this.props.hasAccessToInquiry && !!this.props.hasAccessToInquiry?.contact_uuid
                    ? TextDE.contact.contactForm.hasAccessViaConnect
                    : TextDE.contact.contactForm.noAccessViaConnect
                }
              >
                <IconButton
                  onClick={() => this.props.shareFunction(this.props.contactWrapper.contact)}
                  color={
                    !!this.props.hasAccessToInquiry && !!this.props.hasAccessToInquiry?.contact_uuid
                      ? 'primary'
                      : 'secondary'
                  }
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
              <IconButton onClick={this.handleEditClick}>
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={() =>
                  this.props.deleteFunction({
                    ...this.props.contactWrapper.contact,
                    addresses: this.props.addresses,
                  })
                }
              >
                <DeleteIcon />
              </IconButton>
            </>
          }
        />
        <CardContent>
          <Grid container direction='row' justifyContent='space-between' alignItems='baseline'>
            <Grid item xs={12} md={6}>
              <Grid
                container
                spacing={1}
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                <Grid item xs={2} component='center'>
                  <AccountCircleIcon
                    className={classes.pointerDetail}
                    fontSize='large'
                    onClick={() => this.handleDetailClick(this.props.contactWrapper.contact)}
                  />
                </Grid>

                <Grid item xs={10}>
                  <TextField
                    classes={{ root: classes.customInputRoot }}
                    disabled={false}
                    size='small'
                    label='Name'
                    fullWidth
                    variant='outlined'
                    value={`${this.props.contactWrapper.contact.salutation?.name ?? ''} ${
                      this.props.contactWrapper.contact.title?.name ?? ''
                    } ${this.props.contactWrapper.contact.firstname ?? ''} ${
                      this.props.contactWrapper.contact.name
                    }`.trim()}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment
                          className={classes.pointer}
                          position='end'
                          onClick={(event) =>
                            this.copyToClipboard(
                              'name',
                              `${this.props.contactWrapper.contact.salutation?.name ?? ''} ${
                                this.props.contactWrapper.contact.title?.name ?? ''
                              } ${this.props.contactWrapper.contact.firstname ?? ''} ${
                                this.props.contactWrapper.contact.name
                              }`.trim(),
                            )
                          }
                        >
                          <FileCopyIcon fontSize='small' />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Collapse in={this.state.copy === 'name'}>
                    <Alert
                      className={classes.alert}
                      onClose={this.handleCopyClose}
                      severity='success'
                    >
                      {TextDE.copied}
                    </Alert>
                  </Collapse>
                </Grid>
                <Hidden mdDown>
                  <Grid item xs={2}></Grid>
                </Hidden>
                <Grid item xs={12} md={10}>
                  <Collapse in={this.state.edit}>
                    <FormGroup row style={{ justifyContent: 'space-between' }}>
                      <FormLabel style={{ width: '100%' }}>
                        {TextDE.contact.contactForm.contactTag}
                      </FormLabel>
                      <FormControlLabel
                        style={{ justifyContent: 'flex-end' }}
                        control={
                          <Checkbox
                            disabled={
                              !(
                                (this.props.paymentContact?.length > 0 &&
                                  this.state.invoiceRecipient) ||
                                (this.props.paymentContact?.length > 0 &&
                                  this.state.invoiceRecipientChanged) ||
                                this.props.paymentContact?.length === 0
                              )
                            }
                            checked={this.state.invoiceRecipient}
                            onChange={this.handleChange}
                            name='invoiceRecipient'
                            color='primary'
                          />
                        }
                        label={Constants.tags.contacts.invoice[0]}
                      />
                      <FormControlLabel
                        style={{ justifyContent: 'flex-end' }}
                        control={
                          <Checkbox
                            disabled={
                              !(
                                (this.props.localContact?.length > 0 && this.state.beneficiary) ||
                                (this.props.localContact?.length > 0 &&
                                  this.state.beneficiaryChanged) ||
                                this.props.localContact?.length === 0
                              )
                            }
                            checked={this.state.beneficiary}
                            onChange={this.handleChange}
                            name='beneficiary'
                            color='primary'
                          />
                        }
                        label={Constants.tags.contacts.resident[0]}
                      />
                    </FormGroup>
                    <Button
                      className={classes.floatRightAdd}
                      onClick={this.handleSaveTags}
                      startIcon={<SaveIcon />}
                    >
                      {TextDE.save}
                    </Button>
                  </Collapse>
                </Grid>

                {this.props.contactWrapper.contact.phones.map((phone, index) => (
                  <Grid item key={this.props.labelId + '-phone-' + index} xs={12}>
                    <Grid container spacing={0}>
                      <Hidden mdDown>
                        <Grid item xs={2}></Grid>
                      </Hidden>
                      <Grid item xs={2}>
                        <IconButton href={'tel:' + encodeURI(phone.number)} size='small'>
                          <Badge
                            color='primary'
                            overlap='circular'
                            badgeContent={phone.pref ? <StarIcon className={classes.xs} /> : 0}
                            variant='standard'
                          >
                            <Avatar className={classes.small} variant='rounded'>
                              {phone.work ? <WorkIcon /> : null}
                              {phone.cell ? (
                                <PhoneAndroidIcon />
                              ) : phone.fax ? (
                                <DescriptionIcon />
                              ) : (
                                <PhoneIcon />
                              )}
                            </Avatar>
                          </Badge>
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} md={8}>
                        <TextField
                          classes={{ root: classes.customInputRoot }}
                          disabled={false}
                          size='small'
                          fullWidth
                          label={TextDE.form.phone.label}
                          variant='outlined'
                          value={phone.number}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.pointer}
                                position='end'
                                onClick={(event) =>
                                  this.copyToClipboard(phone.number, phone.number)
                                }
                              >
                                <FileCopyIcon fontSize='small' />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Collapse in={this.state.copy === phone.number}>
                          <Alert
                            className={classes.alert}
                            onClose={this.handleCopyClose}
                            severity='success'
                          >
                            {TextDE.copied}
                          </Alert>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Collapse in={this.state.edit}>
                    <Grid container spacing={0} className={classes.mt}>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={10}>
                        <TextField
                          disabled={false}
                          size='small'
                          label={TextDE.form.phone.label}
                          fullWidth
                          variant='outlined'
                          name='newPhoneNumber'
                          value={this.state.newPhoneNumber}
                          onChange={this.handleChange}
                          error={!!this.state.newPhoneNumberError}
                          helperText={this.state.newPhoneNumberError}
                        />
                      </Grid>
                      <Grid item xs={12} className={classes.mb}>
                        <Button
                          disabled={
                            this.state.newPhoneNumber.length <= 1 ||
                            !!this.state.newPhoneNumberError
                          }
                          startIcon={<AddIcon />}
                          variant='outlined'
                          className={classes.floatRightAdd}
                          onClick={this.handleSaveNewPhone}
                        >
                          {TextDE.add}
                        </Button>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
                {this.props.contactWrapper.contact.emails.map((email, index) => (
                  <Grid item key={this.props.labelId + '-email-' + index} xs={12}>
                    <Grid container spacing={0}>
                      <Hidden mdDown>
                        <Grid item xs={2}></Grid>
                      </Hidden>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            this.props.sendMailTo(this.props.contactWrapper.contact, email.email)
                          }
                          size='small'
                        >
                          <Badge
                            color='primary'
                            overlap='circular'
                            badgeContent={email.pref ? <StarIcon className={classes.xs} /> : 0}
                            variant='standard'
                          >
                            <Avatar variant='rounded' className={classes.small}>
                              {email.work ? <WorkIcon /> : <AlternateEmailIcon />}
                            </Avatar>
                          </Badge>
                        </IconButton>
                      </Grid>
                      <Grid item xs={10} md={8}>
                        <TextField
                          classes={{ root: classes.customInputRoot }}
                          disabled={false}
                          size='small'
                          fullWidth
                          label={TextDE.form.email.label}
                          variant='outlined'
                          value={email.email}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.pointer}
                                position='end'
                                onClick={(event) => this.copyToClipboard(email.email, email.email)}
                              >
                                <FileCopyIcon fontSize='small' />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Collapse in={this.state.copy === email.email}>
                          <Alert
                            className={classes.alert}
                            onClose={this.handleCopyClose}
                            severity='success'
                          >
                            {TextDE.copied}
                          </Alert>
                        </Collapse>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Collapse in={this.state.edit}>
                  <Grid container spacing={0} className={classes.mt}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <TextField
                        disabled={false}
                        size='small'
                        label={TextDE.form.email.label}
                        fullWidth
                        variant='outlined'
                        name='newEmailAddress'
                        value={this.state.newEmailAddress}
                        onChange={this.handleChange}
                        error={!!this.state.newEmailAddressError}
                        helperText={this.state.newEmailAddressError}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        disabled={
                          this.state.newEmailAddress.length <= 1 ||
                          !!this.state.newEmailAddressError
                        }
                        onClick={this.handleSaveNewEmail}
                        startIcon={<AddIcon />}
                        variant='outlined'
                        className={classes.floatRightAdd}
                      >
                        {TextDE.add}
                      </Button>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid item md={1}></Grid>
            </Hidden>
            <Hidden smUp>
              <span style={{ height: '16px', width: '100%' }}></span>
            </Hidden>
            <Grid item xs={12} md={5}>
              <Grid
                container
                spacing={1}
                direction='row'
                justifyContent='center'
                alignItems='center'
              >
                {Object.values(this.props.addresses).map((address, index) => (
                  <Grid item key={this.props.labelId + '-address-' + index} xs={12}>
                    <Grid container spacing={0}>
                      <Grid item xs={2} component='center'>
                        <IconButton
                          href={
                            'http://maps.google.com/maps?daddr=' +
                            encodeURI(
                              address.address.route +
                                '+' +
                                address.address.street_number +
                                '+' +
                                address.address.postal_code +
                                '+' +
                                address.address.city.locality,
                            )
                          }
                          target={'_blank'}
                          size='small'
                        >
                          <Avatar className={classes.small} variant='rounded'>
                            <DirectionsIcon />
                          </Avatar>
                        </IconButton>
                      </Grid>

                      <Grid item xs={10}>
                        <TextField
                          classes={{ root: classes.customInputRoot }}
                          disabled={false}
                          size='small'
                          label={
                            address.tags?.length > 0
                              ? address.tags?.map((el) => el.name?.de || el).join(', ')
                              : TextDE.address.label
                          }
                          fullWidth
                          multiline
                          maxRows={2}
                          variant='outlined'
                          value={`${address.address.route} ${address.address.street_number},\n${address.address.postal_code} ${address.address.city.locality}`.trim()}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                className={classes.pointer}
                                position='end'
                                onClick={(event) =>
                                  this.copyToClipboard(
                                    address.address.id,
                                    `${address.address.route} ${address.address.street_number},\n${address.address.postal_code} ${address.address.city.locality}`.trim(),
                                  )
                                }
                              >
                                <FileCopyIcon fontSize='small' />
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Collapse in={this.state.copy === address.address.id}>
                          <Alert
                            className={classes.alert}
                            onClose={this.handleCopyClose}
                            severity='success'
                          >
                            {TextDE.copied}
                          </Alert>
                        </Collapse>
                      </Grid>
                      {address.address.id !== this.props.contactWrapper.contact.address_id && (
                        <Grid item xs={12}>
                          <Collapse in={this.state.edit}>
                            <Button
                              variant='outlined'
                              className={classes.floatRightDel}
                              endIcon={<DeleteIcon />}
                              onClick={() => this.props.deleteAddressFunction(address)}
                            >
                              {TextDE.delete}
                            </Button>
                          </Collapse>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <Collapse in={this.state.edit}>
                  <Grid container spacing={0} className={classes.mt}>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <PlacesAutocomplete
                            value={this.state.address}
                            name='input'
                            label={TextDE.address.label}
                            onChange={this.handleAddressChange}
                            onSelect={this.handleAddressSelect}
                            searchOptions={{ types: ['address'] }}
                            shouldFetchSuggestions={this.state.address.length >= 4}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div className={classes.container}>
                                <Grid
                                  container
                                  direction='row'
                                  justifyContent='flex-start'
                                  alignItems='stretch'
                                  spacing={2}
                                >
                                  <Grid item xs={8}>
                                    <TextField
                                      fullWidth
                                      variant='outlined'
                                      autoComplete='false'
                                      {...getInputProps({
                                        label: TextDE.address.label,
                                        placeholder: TextDE.address.inputPlaceholder,
                                        className: 'location-search-input',
                                      })}
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    {!!!this.state.manual_address_input ? (
                                      <Button
                                        fullWidth
                                        style={{ height: '100%' }}
                                        onClick={this.handleAddressSetManualEdit}
                                        variant='contained'
                                        color='primary'
                                      >
                                        {TextDE.procedure.addProcedureForm.addAddressManually}
                                      </Button>
                                    ) : (
                                      <Button
                                        fullWidth
                                        style={{ height: '100%' }}
                                        onClick={this.handleAddressResetManualEdit}
                                        variant='outlined'
                                        color='secondary'
                                      >
                                        {TextDE.procedure.addProcedureForm.addAddressViaSearch}
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>

                                {/* Somehow evaluate if the Add Address Option was clicked ... */}
                                {suggestions.length > 0 && (
                                  <Card className={classes.results}>
                                    {loading && (
                                      <CircularProgress
                                        size={24}
                                        className={classes.buttonProgress}
                                      />
                                    )}
                                    {suggestions.map((suggestion, key) => {
                                      return (
                                        <CardContent
                                          key={key}
                                          {...getSuggestionItemProps(suggestion, {})}
                                          className={classes.result}
                                        >
                                          <Grid container alignItems='center'>
                                            <Grid item>
                                              <LocationOnIcon className={classes.icon} />
                                            </Grid>
                                            <Grid item xs>
                                              <Typography variant='subtitle1'>
                                                {suggestion.formattedSuggestion.mainText}
                                              </Typography>
                                              <Typography variant='body1' color='textSecondary'>
                                                {suggestion.formattedSuggestion.secondaryText}
                                              </Typography>
                                            </Grid>
                                          </Grid>
                                        </CardContent>
                                      );
                                    })}
                                  </Card>
                                )}
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </Grid>
                        <Grid item xs={12}>
                          {!!this.state.addressError && (
                            <Alert severity='error'>{this.state.addressError}</Alert>
                          )}
                        </Grid>

                        {!!this.state.manual_address_input && (
                          <>
                            <Grid item xs={12}>
                              <Typography paragraph className={classes.mt}>
                                {TextDE.procedure.addProcedureForm.addAddressLabel}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={8}>
                                  <TextField
                                    value={this.state.addressObject?.route}
                                    name='route'
                                    label={TextDE.address.route}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    value={this.state.addressObject?.street_number}
                                    name='street_number'
                                    label={TextDE.address.street_number}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    value={this.state.addressObject?.postal_code}
                                    name='postal_code'
                                    label={TextDE.address.postal_code}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <TextField
                                    value={this.state.addressObject?.locality}
                                    name='locality'
                                    label={TextDE.address.locality}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth variant='outlined'>
                                    <InputLabel
                                      id='administrative_area_level_1-label'
                                      shrink={true}
                                      variant='outlined'
                                    >
                                      {TextDE.address.administrative_area_level_1}
                                    </InputLabel>
                                    <Select
                                      labelId='administrative_area_level_1-label'
                                      id='administrative_area_level_1'
                                      name='administrative_area_level_1'
                                      label={TextDE.address.administrative_area_level_1}
                                      value={this.state.addressObject?.administrative_area_level_1}
                                      onChange={this.handleAddressDetailChange}
                                    >
                                      <MenuItem value=''> Bitte auswählen </MenuItem>
                                      <MenuItem value='Baden-Württemberg'>
                                        Baden-Württemberg
                                      </MenuItem>
                                      <MenuItem value='Bayern'> Bayern </MenuItem>
                                      <MenuItem value='Berlin'> Berlin </MenuItem>
                                      <MenuItem value='Brandenburg'> Brandenburg </MenuItem>
                                      <MenuItem value='Bremen'> Bremen </MenuItem>
                                      <MenuItem value='Hamburg'> Hamburg </MenuItem>
                                      <MenuItem value='Hessen'> Hessen </MenuItem>
                                      <MenuItem value='Mecklenburg-Vorpommern'>
                                        Mecklenburg-Vorpommern
                                      </MenuItem>
                                      <MenuItem value='Niedersachsen'> Niedersachsen </MenuItem>
                                      <MenuItem value='Nordrhein-Westfalen'>
                                        Nordrhein-Westfalen
                                      </MenuItem>
                                      <MenuItem value='Rheinland-Pfalz'> Rheinland-Pfalz </MenuItem>
                                      <MenuItem value='Saarland'> Saarland </MenuItem>
                                      <MenuItem value='Sachsen-Anhalt'> Sachsen-Anhalt </MenuItem>
                                      <MenuItem value='Sachsen'> Sachsen </MenuItem>
                                      <MenuItem value='Schleswig-Holstein'>
                                        Schleswig-Holstein
                                      </MenuItem>
                                      <MenuItem value='Thüringen'> Thüringen </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    value={'Deutschland'}
                                    name='country'
                                    label={TextDE.address.country}
                                    disabled
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        onClick={this.handleSaveNewAddress}
                        disabled={
                          !!this.state.addressError ||
                          Object.values(this.state.addressObject).length <= 1
                        }
                        startIcon={<AddIcon />}
                        variant='outlined'
                        className={classes.floatRightAdd}
                      >
                        {TextDE.add}
                      </Button>
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContactCardDetail);
