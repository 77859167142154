import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Avatar from '@material-ui/core/Avatar';
//import Button from '@material-ui/core/Button';
//import EditIcon from '@material-ui/icons/Edit'
import Typography from '@material-ui/core/Typography';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import SubjectIcon from '@material-ui/icons/Subject';
import UpdateIcon from '@material-ui/icons/Update';
import renderHtml from '../../../lib/transformHtml';
//import LabelImportantIcon from '@material-ui/icons/LabelImportant';
//import Button from '@material-ui/core/Button';
// <Button onClick={() => console.log(this.props.item)}  startIcon={<LabelImportantIcon />} variant="contained" color="primary"> Jetzt Antworten </Button>
//import Collapse from '@material-ui/core/Collapse';
//import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { readable } from '../../../lib/dateFunctions';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 1),
    width: '100%',
    borderRadius: '14px',
    backgroundColor: '#fffcdd',
  },
  alignRight: {
    marginLeft: '20%',
    [theme.breakpoints.down('md')]: {
      marginLeft: '2%',
    },
    backgroundColor: '#efefef',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(10),
      minHeight: theme.spacing(10),
      '&>svg': {
        minWidth: theme.spacing(8),
        minHeight: theme.spacing(8),
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
  },
  noPad: {
    padding: '0px!important',
  },
  alignMiddle: {
    display: 'flex',
    '& > b': {
      display: 'flex',
      '& > svg': {
        margin: theme.spacing(0, 2, 0, 0),
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
      },
    },
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      fontSize: '90%',
    },
    justifyContent: 'flex-start',
    maxHeight: '4em',
    overflow: 'auto',
  },
  alignMiddleSmall: {
    display: 'flex',
    fontSize: 'small',
    '& > b': {
      display: 'flex',
      '& > svg': {
        fontSize: 'large',
        margin: theme.spacing(0, 2, 0, 0),
      },
      [theme.breakpoints.up('sm')]: {
        marginRight: theme.spacing(2),
      },
    },
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      fontSize: '90%',
    },
    justifyContent: 'flex-start',
    maxHeight: '4em',
    overflow: 'auto',
  },
  flexRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'end',
    '&>svg': {
      marginRight: '1em',
    },
    justifyContent: 'end',
  },
  header: {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    '&>div:last-child': {
      margin: '8px 0px 0px auto',
      flexDirection: 'column',
      display: 'flex',
    },
  },

  expanded: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  center: {
    left: '49%',
  },
});

class Chatbot extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <Card
        raised={true}
        variant='outlined'
        className={clsx(classes.root, {
          [classes.alignRight]: this.props.align,
        })}
      >
        <CardHeader
          className={classes.header}
          disableTypography={true}
          avatar={
            <Avatar
              className={classes.avatar}
              alt={
                this.props.item.data?.creator?.name ??
                this.props.item.data.creator?.firstname +
                  ' ' +
                  this.props.item.data.creator?.lastname ??
                ''
              }
              src={
                this.props.item.data?.creator?.avatar ??
                process.env.REACT_APP_API_URL +
                  '/avatar/' +
                  this.props.item.creator?.uuid +
                  '?thumb' ??
                ''
              }
            />
          }
          title={
            <>
              <Typography className={classes.alignMiddle} variant='h5'>
                {this.props.item.data?.creator?.name ??
                  this.props.item.data.creator?.firstname +
                    ' ' +
                    this.props.item.data.creator?.lastname}
              </Typography>
              <Typography className={classes.alignMiddle} variant='h5' component='span'>
                <b>
                  {' '}
                  <SubjectIcon /> Betreff:
                </b>
                {this.props.item.data?.subject}
              </Typography>
              {!!this.props.item.data?.files && (
                <Typography
                  className={classes.alignMiddleSmall}
                  variant='h6'
                  color='textPrimary'
                  component='p'
                >
                  <b>
                    <AttachFileIcon /> Freigaben:
                  </b>
                  {!!this.props.item.data?.files?.text
                    ? this.props.item.data?.files?.text
                    : this.props.item.data?.files?.map((element) => element.file_name)?.join(', ')}
                </Typography>
              )}
            </>
          }
          action={
            <div className={classes.flexRight} style={{ flexDirection: 'column' }}>
              <Typography variant='overline' component='p' className={classes.flexRight}>
                <UpdateIcon fontSize='small' /> {readable(this.props.item.created_at)}
              </Typography>

              <Typography variant='overline' component='p' className={classes.flexRight}>
                <SettingsEthernetIcon color={'primary'} /> via FLiXWORKER Connect
              </Typography>
              {!!!this.props.item.created_by && (
                <Button
                  onClick={() =>
                    this.props.respondFunction(this.props.item.data, [
                      this.props.item.data?.creator,
                    ])
                  }
                  startIcon={<LabelImportantIcon />}
                  variant='contained'
                  color='primary'
                >
                  {' '}
                  Jetzt Antworten{' '}
                </Button>
              )}
            </div>
          }
        />

        <CardContent className={classes.paper}>
          {renderHtml(this.props.item.data?.body)}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Chatbot);
