import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
//import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';

import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocationOnIcon from '@material-ui/icons/LocationOn';
//import Chip from '@material-ui/core/Chip';
import { DE as TextDE } from '../../../../../lib/Text';

import Typography from '@material-ui/core/Typography';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    minWidth: '50vw',
    maxWidth: '90vw',
    //minHeight: '30vh',
    maxHeight: '90vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  input: {
    backgroundColor: 'antiquewhite',
    fontSize: '1.5em',
  },
  buttonGroup: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
  results: {
    backgroundColor: theme.palette.background.paper,
    zIndex: 123123,
    position: 'absolute',
    maxHeight: '300px',
    minWidth: '50vw',
    maxWidth: '90vw',
    overflow: 'auto',
    boxShadow: theme.shadows[4],
  },
  selected: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
});

class Address extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      manual_address_input: false,
      address: !!this.props.data.city
        ? this.props.data.route +
          ' ' +
          this.props.data.street_number +
          ', ' +
          this.props.data.postal_code +
          ' ' +
          this.props.data.city.locality
        : '',
      hasError: false,
      addresses: this.props.addresses,
      addressError: '',
      addressObject: !!props.data.city
        ? {
            street_number: props.data.street_number,
            route: props.data.route,
            locality: props.data.city.locality,
            administrative_area_level_1: props.data.city.state,
            country: props.data.city.country,
            postal_code: props.data.postal_code,
          }
        : {},
    };
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.decodeAddress = this.decodeAddress.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleAddressSetManualEdit = this.handleAddressSetManualEdit.bind(this);
    this.handleAddressResetManualEdit = this.handleAddressResetManualEdit.bind(this);
    this.handleAddressDetailChange = this.handleAddressDetailChange.bind(this);
  }

  selectAddress = (address) => {
    this.setState({
      address:
        address.address.route +
        ' ' +
        address.address.street_number +
        ', ' +
        address.address.postal_code +
        ' ' +
        address.address.city.locality,
      address_id: address.address_id ?? -1,
      addressObject: {
        street_number: address.address.street_number,
        route: address.address.route,
        locality: address.address.city.locality,
        administrative_area_level_1:
          address.address.city.state ?? address.address.city.administrative_area_level_1 ?? '',
        country: address.address.city.country ?? '',
        postal_code: address.address.postal_code,
      },
    });
  };

  formChange = (event, value) => {
    if (value && value.inputValue) {
      this.setState({ [this.props.name]: value });
      return;
    }
    this.setState({ [this.props.name]: value });
  };

  handleAddressAdd = async () => {
    this.setState({ isSubmitting: true });

    // Create new User or use existing
    let formData = {};
    formData['_method'] = 'PUT';
    if (!!this.state.addressObject) {
      formData['address'] = this.state.addressObject;
    }
    if (!!this.state.address_id && this.state.address_id >= 0) {
      formData['address_id'] = this.state.address_id;
    }
    formData['tags'] = [TextDE.address.placeOfPerformance];
    formData['context'] = 'addresses';
    formData['uuid'] = this.props.uuid;
    formData['simpleView'] = true;

    let control = false;
    let tmp = this.state.addresses;
    let address = {
      address: {
        route: this.state.addressObject.route,
        street_number: this.state.addressObject.street_number,
        postal_code: this.state.addressObject.postal_code,
        city: {
          locality: this.state.addressObject.locality,
        },
      },
      tags: [{ name: { de: TextDE.address.placeOfPerformance } }],
    };

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/jobs/' + this.props.uuid + '/' + formData.context;

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      });

    address.id = response.id;
    for (var i = tmp.length - 1; i >= 0; i--) {
      if (tmp[i].address_id === response.id) {
        tmp[i] = address;
        control = true;
      }
    }
    if (!control) {
      tmp.push(address);
    }

    this.setState(
      {
        //[this.props.name]: this.state[this.props.name],
        addresses: tmp,
        manual_address_input: false,
        isSubmitting: false,
      },
      () => this.props.onChange(this.props.name, address.address),
    );
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => {
        console.error('Error', error);
        if (error === 'ZERO_RESULTS') {
          this.handleAddressSetManualEdit();
        }
      });
  };

  handleAddressSetManualEdit = (event) => {
    this.setState({ manual_address_input: true });
  };

  handleAddressResetManualEdit = (event) => {
    this.setState({ manual_address_input: false });
  };

  handleAddressDetailChange = (event) => {
    this.setState(
      {
        ...this.state,
        addressObject: {
          ...this.state.addressObject,
          [event.target.name]: event.target.value,
          country:
            !!this.state.addressObject.country && this.state.addressObject.country.length >= 2
              ? this.state.addressObject.country
              : 'Deutschland',
        },
      },
      () => {
        let error = this.validateAddressObject(this.state.addressObject);
        this.setState({
          addressError:
            error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
          address: `${this.state.addressObject.route ?? ''} ${this.state.addressObject.street_number ?? ''}, ${
            this.state.addressObject.postal_code ?? ''
          } ${this.state.addressObject.locality ?? ''}`,
        });
      },
    );
  };

  validateAddressObject(newAddress) {
    let error = [];
    if (!!!newAddress.street_number || newAddress?.street_number === '') {
      error.push(TextDE.address.street_number);
    }
    if (!!!newAddress.route || newAddress?.route === '') {
      error.push(TextDE.address.route);
    }
    if (!!!newAddress.locality || newAddress?.locality === '') {
      error.push(TextDE.address.locality);
    }
    if (!!!newAddress.postal_code || newAddress?.postal_code === '') {
      error.push(TextDE.address.postal_code);
    }
    if (
      !!!newAddress.administrative_area_level_1 ||
      newAddress?.administrative_area_level_1 === ''
    ) {
      error.push(TextDE.address.administrative_area_level_1);
    }
    if (!!!newAddress.country || newAddress?.country === '') {
      error.push(TextDE.address.country);
    }
    return error;
  }

  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        newAddress[addressType] = val;
      }
    }

    let error = this.validateAddressObject(newAddress);

    this.setState({
      manual_address_input: error.length > 0,
      addressError: error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
      addressObject: newAddress,
      address: `${newAddress.route ?? ''} ${newAddress.street_number ?? ''}, ${
        newAddress.postal_code ?? ''
      } ${newAddress.locality ?? ''}`,
    });
  };

  handleAddressChange = (value) => {
    this.setState({ address: value });
  };

  render() {
    const { classes } = this.props;
    //const filter = createFilterOptions({
    //			  matchFrom: 'start',
    //			  stringify: option => option.address,
    //			});
    return (
      <Backdrop className={classes.backdrop} open={this.props.open}>
        <Zoom in={this.props.open}>
          {!!this.state.isSubmitting ? (
            <Paper elevation={4} className={classes.paper} component='center'>
              <CircularProgress />
            </Paper>
          ) : (
            <Paper elevation={4} className={classes.paper}>
              <PlacesAutocomplete
                value={this.state.address}
                name='address'
                label={TextDE.address.label}
                onChange={this.handleAddressChange}
                onSelect={this.handleAddressSelect}
                searchOptions={{ types: ['address'] }}
                shouldFetchSuggestions={this.state.address.length >= 4}
              >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                  <div className={classes.container}>
                    <Grid
                      container
                      direction='row'
                      justifyContent='flex-start'
                      alignItems='stretch'
                      spacing={2}
                    >
                      <Grid item xs={8}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          autoComplete='false'
                          {...getInputProps({
                            label: TextDE.address.label,
                            placeholder: TextDE.address.inputPlaceholder,
                            className: 'location-search-input',
                          })}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        {!!!this.state.manual_address_input ? (
                          <Button
                            fullWidth
                            style={{ height: '100%' }}
                            onClick={this.handleAddressSetManualEdit}
                            variant='contained'
                            color='primary'
                          >
                            {TextDE.procedure.addProcedureForm.addAddressManually}
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            style={{ height: '100%' }}
                            onClick={this.handleAddressResetManualEdit}
                            variant='outlined'
                            color='secondary'
                          >
                            {TextDE.procedure.addProcedureForm.addAddressViaSearch}
                          </Button>
                        )}
                      </Grid>
                    </Grid>

                    {/* Somehow evaluate if the Add Address Option was clicked ... */}
                    {suggestions.length > 0 && (
                      <Card className={classes.results}>
                        {loading && (
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                        {suggestions.map((suggestion, key) => {
                          return (
                            <CardContent
                              key={key}
                              {...getSuggestionItemProps(suggestion, {})}
                              className={classes.result}
                            >
                              <Grid container alignItems='center'>
                                <Grid item>
                                  <LocationOnIcon className={classes.icon} />
                                </Grid>
                                <Grid item xs>
                                  <Typography variant='subtitle1'>
                                    {suggestion.formattedSuggestion.mainText}
                                  </Typography>
                                  <Typography variant='body1' color='textSecondary'>
                                    {suggestion.formattedSuggestion.secondaryText}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CardContent>
                          );
                        })}
                      </Card>
                    )}
                  </div>
                )}
              </PlacesAutocomplete>

              {!!this.state.addressError && (
                <Alert severity='error'>{this.state.addressError}</Alert>
              )}
              {!!this.state.manual_address_input && (
                <>
                  <Grid item xs={12}>
                    <Typography paragraph className={classes.mt}>
                      {TextDE.procedure.addProcedureForm.addAddressLabel}:
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <TextField
                          value={this.state.addressObject?.route}
                          name='route'
                          label={TextDE.address.route}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleAddressDetailChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          value={this.state.addressObject?.street_number}
                          name='street_number'
                          label={TextDE.address.street_number}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleAddressDetailChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          value={this.state.addressObject?.postal_code}
                          name='postal_code'
                          label={TextDE.address.postal_code}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleAddressDetailChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={7}>
                        <TextField
                          value={this.state.addressObject?.locality}
                          name='locality'
                          label={TextDE.address.locality}
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleAddressDetailChange}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth variant='outlined'>
                          <InputLabel
                            id='administrative_area_level_1-label'
                            shrink={true}
                            variant='outlined'
                          >
                            {TextDE.address.administrative_area_level_1}
                          </InputLabel>
                          <Select
                            labelId='administrative_area_level_1-label'
                            id='administrative_area_level_1'
                            name='administrative_area_level_1'
                            label={TextDE.address.administrative_area_level_1}
                            value={this.state.addressObject?.administrative_area_level_1}
                            onChange={this.handleAddressDetailChange}
                          >
                            <MenuItem value=''> Bitte auswählen </MenuItem>
                            <MenuItem value='Baden-Württemberg'>Baden-Württemberg</MenuItem>
                            <MenuItem value='Bayern'> Bayern </MenuItem>
                            <MenuItem value='Berlin'> Berlin </MenuItem>
                            <MenuItem value='Brandenburg'> Brandenburg </MenuItem>
                            <MenuItem value='Bremen'> Bremen </MenuItem>
                            <MenuItem value='Hamburg'> Hamburg </MenuItem>
                            <MenuItem value='Hessen'> Hessen </MenuItem>
                            <MenuItem value='Mecklenburg-Vorpommern'>
                              Mecklenburg-Vorpommern
                            </MenuItem>
                            <MenuItem value='Niedersachsen'> Niedersachsen </MenuItem>
                            <MenuItem value='Nordrhein-Westfalen'>Nordrhein-Westfalen</MenuItem>
                            <MenuItem value='Rheinland-Pfalz'> Rheinland-Pfalz </MenuItem>
                            <MenuItem value='Saarland'> Saarland </MenuItem>
                            <MenuItem value='Sachsen-Anhalt'> Sachsen-Anhalt </MenuItem>
                            <MenuItem value='Sachsen'> Sachsen </MenuItem>
                            <MenuItem value='Schleswig-Holstein'>Schleswig-Holstein</MenuItem>
                            <MenuItem value='Thüringen'> Thüringen </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          value={'Deutschland'}
                          name='country'
                          label={TextDE.address.country}
                          disabled
                          variant='outlined'
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleAddressDetailChange}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <Typography variant='h5'>Alle hinterlegten Adressen:</Typography>
              <List>
                {this.state.addresses.map((option, index) => (
                  <ListItem
                    button
                    onClick={() => this.selectAddress(option)}
                    key={'grid-' + index}
                    className={
                      option.address.route +
                        ' ' +
                        option.address.street_number +
                        ', ' +
                        option.address.postal_code +
                        ' ' +
                        option.address.city.locality ===
                      this.state.address
                        ? classes.selected
                        : null
                    }
                  >
                    <ListItemIcon>
                      <LocationOnIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        option.address.route +
                        ' ' +
                        option.address.street_number +
                        ', ' +
                        option.address.postal_code +
                        ' ' +
                        option.address.city.locality
                      }
                    />
                  </ListItem>
                ))}
                {/* TODO ADDRESS EDITOR */}
              </List>
              {!!this.state.hasError && (
                <Alert severity='error' className={classes.center}>
                  <AlertTitle>
                    {this.state.hasError?.status ?? 'Fehler'} -{' '}
                    {this.state.hasError?.statusText ?? 'Fehler'}
                  </AlertTitle>
                  {JSON.stringify(this.state.hasError, null, 4)}
                </Alert>
              )}
              <div className={classes.buttonGroup}>
                <Button
                  onClick={() => this.props.onChange(this.props.name, this.props.data)}
                  variant='outlined'
                  startIcon={<CloseIcon />}
                  color='secondary'
                >
                  {TextDE.close}
                </Button>
                <Button
                  endIcon={<SaveIcon />}
                  variant={
                    Object.keys(this.state.addressObject).length >= 4 &&
                    this.state.addressError === ''
                      ? 'contained'
                      : 'outlined'
                  }
                  color='primary'
                  disabled={
                    !!this.state.addressError || Object.values(this.state.addressObject).length <= 1
                  }
                  onClick={this.handleAddressAdd}
                >
                  {TextDE.save}
                </Button>
              </div>
            </Paper>
          )}
        </Zoom>
      </Backdrop>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

export default compose(withStyles(styles), connect(mapStateToProps))(Address);
