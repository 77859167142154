import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

class Impressum extends React.Component {
  render() {
    return (
      <Grid container justifyContent='center'>
        <Grid item xs={9}>
          <Card style={{ margin: '10vh 0' }}>
            <CardContent>
              <Typography color='textSecondary' gutterBottom>
                Impressum
              </Typography>
              <Typography variant='body2' component='div'>
                <h5>Angaben gemäß § 5 TMG:</h5>
                FLiXWORKER GmbH
                <br />
                Am Lusbühl 16
                <br />
                79110 Freiburg
                <br />
                <br />
                <h5>Vertreten durch:</h5>
                Geschäftsführer Markus Gentner
                <br />
                <p></p>
                <p></p>
                <h5>Kontakt:</h5>
                Telefon: 0761-89750180
                <br />
                E-Mail: info@flixworker.de
                <br />
                <br />
                <h5>Registereintrag:</h5>
                Eintragung im Handelsregister.
                <br />
                Registergericht:Freiburg im Breisgau
                <br />
                Registernummer: HRB 713711
                <br />
                <br />
                <h5>Umsatzsteuer:</h5>
                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE302099168
                <br />
                <p></p>
                <p></p>
                <h5>Streitschlichtung</h5>
                Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
                bereit: https://ec.europa.eu/consumers/odr.
                <br />
                Unsere E-Mail-Adresse finden Sie oben im Impressum.
                <br />
                <br />
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.
                <br />
                <p></p>
                <p></p>
                <h5>Haftung für Inhalte</h5>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
                Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
                als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                Informationen zu überwachen oder nach Umständen zu forschen, die auf eine
                rechtswidrige Tätigkeit hinweisen.
                <br />
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
                allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist
                jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich.
                Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte
                umgehend entfernen.
                <br />
                <h5>Haftung für Links</h5>
                Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir
                keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
                Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige
                Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
                <br />
                Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
                Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
                Rechtsverletzungen werden wir derartige Links umgehend entfernen.
                <p></p>
                <p></p>
                <h5>Urheberrecht</h5>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
                unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
                Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
                bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen
                Gebrauch gestattet.
                <br />
                Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die
                Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
                gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam
                werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
                Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                <p></p>
                <p>
                  Bildnachweis: 83765037 – Arbeiter und Geschäftsleute als Team © Robert Kneschke –
                  Fotolia.com
                </p>
              </Typography>
            </CardContent>
            <CardActions>
              <NavLink
                to='/login'
                className='NavLinkItem'
                key='/login'
                activeClassName='NavLinkItem-selected'
              >
                Zurück zu Login
              </NavLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default Impressum;
