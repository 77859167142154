import 'date-fns';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { DE as TextDE } from '../../lib/Text';

const CustomUserPicker = (props) => {
  const [user, setUser] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => {
    setAnchorEl([event.clientX - 2, event.clientY - 4]);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleDelete = () => {
    setAnchorEl(false);
    setUser(null);
    props.onFilterChanged(props.columnDef.tableData.id, null);
  };

  const handleUserClick = (user) => {
    setUser(user);
    setAnchorEl(false);
    props.onFilterChanged(props.columnDef.tableData.id, user.uuid);
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {!!user ? (
        <ListItem component='div' onClick={handleClick} button selected>
          <ListItemAvatar>
            <Avatar
              alt={user.firstname + ' ' + user.lastname}
              src={process.env.REACT_APP_API_URL + '/avatar/' + user.uuid + '?thumb'}
            />
          </ListItemAvatar>
          <ListItemText
            secondaryTypographyProps={{ component: 'div' }}
            primary={user.firstname + ' ' + user.lastname}
          />
        </ListItem>
      ) : (
        <Button
          variant='contained'
          fullWidth
          style={{ display: 'flex', flexGrow: 1 }}
          onClick={handleClick}
        >
          {TextDE.timetracking.labelUserFilter}
        </Button>
      )}

      <Menu
        id='add-menu'
        onClose={handleClose}
        open={Boolean(anchorEl)}
        keepMounted
        anchorReference='anchorPosition'
        anchorPosition={
          anchorEl !== false && anchorEl.length === 2
            ? { top: anchorEl[1], left: anchorEl[0] }
            : undefined
        }
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxWidth: '90ch',
            minWidth: '30ch',
            maxHeight: '30em',
          },
        }}
      >
        <Typography variant='h5' style={{ margin: '8px 8px 12px 8px' }}>
          {TextDE.procedure.addProcedureForm.coworkersLabel}
        </Typography>
        {props.usersAll?.map((option) => (
          <ListItem
            ContainerComponent='div'
            ContainerProps={{ style: { width: '100%' } }}
            key={option.uuid}
            onClick={() => handleUserClick(option)}
          >
            <ListItemAvatar>
              <Avatar
                alt={option.firstname + ' ' + option.lastname}
                src={process.env.REACT_APP_API_URL + '/avatar/' + option.uuid + '?thumb'}
              />
            </ListItemAvatar>
            <ListItemText
              secondaryTypographyProps={{ component: 'div' }}
              primary={option.firstname + ' ' + option.lastname}
              secondary={option.roles.map((value, index) => (
                <Chip key={index} label={value} />
              ))}
            />
          </ListItem>
        ))}
      </Menu>
      {!!user && (
        <IconButton aria-label='delete' onClick={handleDelete}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
export default CustomUserPicker;
