import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
//import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import { removeInbox, clearInbox } from '../../actions/AutoRefresh';

import { withRouter } from 'react-router';
import AddIcon from '@material-ui/icons/Add';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
//import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
//import FilterListIcon from '@material-ui/icons/FilterList';

//import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Typography from '@material-ui/core/Typography';
//import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddProcedure from '../Inbox/AddProcedure';
import updateURLParameter from '../../lib/updateURLParameter';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import PhoneIcon from '@material-ui/icons/Phone';
//import ForumIcon from '@material-ui/icons/Forum';
//import ComputerIcon from '@material-ui/icons/Computer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Constants } from '../../lib/Constants';
//import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
//import ErrorOutlineIcon from '@material-ui/icons/Error';
import ListItemOrga from '../Inbox/ListItemOrga';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import { addCustomMenuPoint } from './../../actions/NavMenu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import DraftsIcon from '@material-ui/icons/Drafts';
import DoneIcon from '@material-ui/icons/Done';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SnoozeTwoToneIcon from '@material-ui/icons/SnoozeTwoTone';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Tooltip from '@material-ui/core/Tooltip';

//import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
//import PaymentIcon from '@material-ui/icons/Payment';
import EuroIcon from '@material-ui/icons/Euro';
//import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ErrorIcon from '@material-ui/icons/Error';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import FastForwardIcon from '@material-ui/icons/FastForward';

import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import SortIcon from '@material-ui/icons/Sort';

function unique(data) {
  return data.filter(function (value, index, data) {
    return data.indexOf(value) === index;
  });
}

const filters = [
  {
    key: 'new',
    name: 'Neu',
    state: 'Neu',
    icon: <FiberNewIcon />,
  },
  {
    key: 'working',
    name: 'Bearbeiten',
    state: 'Bearbeiten',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'on_duty',
    name: 'In Bearbeitung',
    state: 'In Bearbeitung',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'waiting',
    name: 'Warten',
    state: 'Warten',
    icon: <SnoozeTwoToneIcon />,
  },
  {
    key: 'newMail',
    name: 'Neue Nachricht!',
    state: 'Neue Nachricht!',
    icon: <FiberNewIcon />,
  },
  {
    key: 'sentMail',
    name: 'Nachricht versandt',
    state: 'Nachricht versandt',
    icon: <MailOutlineIcon />,
  },
  {
    key: 'issue',
    name: 'Problem',
    state: 'Problem',
    icon: <ErrorIcon />,
  },
  {
    key: 'facted',
    name: 'Fakturiert',
    state: 'Fakturiert',
    icon: <EuroIcon />,
  },
  {
    key: 'done',
    name: 'Erledigt',
    state: 'Erledigt',
    icon: <DoneIcon />,
  },
  {
    key: 'abbort',
    name: 'Storno',
    state: 'Storno',
    icon: <NotInterestedIcon />,
  },
  {
    key: 'deleted',
    name: 'Gelöscht',
    state: 'Gelöscht',
    icon: <DeleteIcon />,
  },
];

const defaultFilterStates = [
  {
    key: 'new',
    name: 'Neu',
    state: 'Neu',
    icon: <FiberNewIcon />,
  },
  {
    key: 'working',
    name: 'Bearbeiten',
    state: 'Bearbeiten',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'on_duty',
    name: 'In Bearbeitung',
    state: 'In Bearbeitung',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'waiting',
    name: 'Warten',
    state: 'Warten',
    icon: <SnoozeTwoToneIcon />,
  },
  {
    key: 'newMail',
    name: 'Neue Nachricht!',
    state: 'Neue Nachricht!',
    icon: <FiberNewIcon />,
  },
  {
    key: 'sentMail',
    name: 'Nachricht versandt',
    state: 'Nachricht versandt',
    icon: <MailOutlineIcon />,
  },
  {
    key: 'issue',
    name: 'Problem',
    state: 'Problem',
    icon: <ErrorIcon />,
  },
];

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
  },
  cardheader: {
    display: 'block',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  chip: {
    flexShrink: '1',
    marginRight: theme.spacing(0.8),
    padding: theme.spacing(0.5, 1),
    '&>span, &>svg, &>div': { color: 'inherit' },
  },

  header: {
    flexShrink: 1,
    display: 'flex',
    marginBottom: theme.spacing(0),
  },
  footer: {
    justifyContent: 'start',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 0,
  },
  list: {
    height: '100%',
    width: '100%',
  },
  listitem: {
    listStyle: 'none',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    float: 'left',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  text: {
    margin: theme.spacing(1),
  },
  customColorSelected: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText + '!important',
  },
  pagination: {
    marginRight: 'auto',
    maxWidth: '60vw',
  },
  menu: {
    marginBottom: '1em',
  },
  customWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    '&>button': {
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  maxWidth: {
    maxWidth: '100%',
  },
  buttonGroupLabel: {
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&>svg': {
      marginRight: '8px',
    },
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText + '!important',
    borderColor: theme.palette.primary.contrastText + '!important',
  },
});

class Inbox extends React.Component {
  openMenu = (event) => {
    this.state.isMounted === true && this.setState({ menuAnchor: event.currentTarget });
  };

  closeMenu = () => {
    this.state.isMounted === true && this.setState({ menuAnchor: null });
  };

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(this.props.Router.location.search);
    //console.log('[constructor]', this.props.Router.location.query.order, props.Router.location.query.order, this.props.match.params.order, props.match.params.order);
    let filterBox = [];
    urlParams.getAll('tags').forEach((element) => {
      if (element.includes(',')) {
        element.split(',').forEach((tag) => {
          filterBox.push('tag:' + tag);
        });
      } else if (element !== '') {
        filterBox.push('tag:' + element);
      }
    });

    urlParams.getAll('search').forEach((element) => {
      if (element.includes(',')) {
        filterBox.push(...element.split(','));
      } else if (element !== '') {
        filterBox.push(element);
      }
    });

    this.state = {
      isMounted: false,
      menuAnchor: null,
      searchDone: false,
      filterBoxValue: unique([...filterBox]),
      filterSelectOpen: false,
      config: {
        filtering: false,
        grouping: false,
        getSizeConf:
          this.props.Theme.typography.fontSize * this.props.Theme.typography.body1.lineHeight + 4 >
          20
            ? this.props.Theme.typography.fontSize * this.props.Theme.typography.body1.lineHeight +
              4
            : 21,
      },
      params: {
        filter: !!this.props.Router.location.query.filter
          ? this.props.Router.location.query.filter
          : !!this.props.match.params.filter
          ? this.props.match.params.filter
          : 'none',
        order: !!this.props.Router.location.query.order
          ? this.props.Router.location.query.order
          : !!this.props.match.params.order
          ? this.props.match.params.order
          : 'oldest',
        size: !!this.props.Router.location.query.size
          ? parseInt(this.props.Router.location.query.size)
          : 15,
        page: !!this.props.Router.location.query.page
          ? parseInt(this.props.Router.location.query.page)
          : 1,
        //tags: ((!!this.props.Router.location.query.tags) ? this.props.Router.location.query.tag : ''),
        tags: [...urlParams.getAll('tags')],

        //search: ((!!this.props.Router.location.query.search) ? this.props.Router.location.query.search : ''),
        search: urlParams.getAll('search'),

        state: [],
        input: '',
      },
      toDelete: {},
      deleteOpen: false,
      isFetching: true,
      error: false,
      openedMessage: { id: null, timestamp: null },
      procedureDialogOpen: false,
      fastDialogOpen: false,
      mailDialogOpen: false,
      dialogData: {},
      saveSearchDialogOpen: false,
      saveSearchName: '',
      messages: {},
      tags: [],
      isAdmin: Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
      ]),
      // CHANGE ME TO NEGATION !Constants.roles....
      isWorker: !Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
        Constants.roles.ROLES_PHONE,
      ]),
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.newItem = this.newItem.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.fetchList = this.fetchList.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleDeleteFunction = this.handleDeleteFunction.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.markAsSeen = this.markAsSeen.bind(this);
    this.saveSearch = this.saveSearch.bind(this);
    this.setOrder = this.setOrder.bind(this);
    this.openFilterSelect = this.openFilterSelect.bind(this);
    this.closeFilterSelect = this.closeFilterSelect.bind(this);
    this.toggleOpenFilterSelect = this.toggleOpenFilterSelect.bind(this);

    this.handleSaveSearchDialogOpen = this.handleSaveSearchDialogOpen.bind(this);
    this.handleSaveSearchDialogClose = this.handleSaveSearchDialogClose.bind(this);
    this.handleSaveSearchChange = this.handleSaveSearchChange.bind(this);
    this.refresh = this.refresh.bind(this);
    this.newFast = this.newFast.bind(this);
  }

  newItem = () => {
    this.state.isMounted === true &&
      this.setState({
        ...this.state,
        procedureDialogOpen: true,
        menuAnchor: null,
      });
  };

  newFast = async () => {
    this.setState({ isSubmitting: true, hasError: false });
    let formData = {
      subject: 'Neu erstellt!',
      body:
        'Schnelleintrag erstellt durch ' +
        this.props.User.firstname +
        ' ' +
        this.props.User.lastname,
      users: [
        {
          uuid: this.props.User.uuid,
          tags: [...this.props.User.roles.map((value) => value.name), 'Ersteller'],
          displayName: this.props.User.firstname + ' ' + this.props.User.lastname,
        },
      ],
    };
    let endpoint = process.env.REACT_APP_API_URL + '/api/procedures';

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return response.json();
      })
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return 'Unbekannter Fehler!' + error;
      });

    //if (response.trim().toLowerCase() === "created") {
    if (!!response.uuid) {
      this.setState(
        { isSubmitting: false, hasError: false },
        this.props.dispatch(push('/nachrichten/detail/' + response.uuid)),
      );
    } else {
      console.error(response);
      this.setState({ isSubmitting: false, hasError: response });
    }
  };

  async handleDialogClose(reload) {
    if (!!reload) {
      this.fetchList(false);
    }
    this.state.isMounted === true && this.setState({ procedureDialogOpen: false });
  }

  handleRedirect(uuid) {
    if (!!uuid && (typeof uuid).toLowerCase() === 'string') {
      this.props.dispatch(push('/nachrichten/detail/' + uuid));
    } else {
      if (!!uuid) {
        this.fetchList(false);
      }
      this.state.isMounted === true && this.setState({ procedureDialogOpen: false });
    }
  }

  async handleMailDialogClose(reload) {
    if (!!reload) {
      this.fetchList(false);
    }
    this.state.isMounted === true && this.setState({ mailDialogOpen: false });
  }

  async handleFastDialogClose(reload) {
    if (!!reload) {
      this.fetchList(false);
    }
    this.state.isMounted === true && this.setState({ fastDialogOpen: false });
  }

  markAsSeen = (item, url) => {
    this.props.dispatch(removeInbox(item.link));
  };

  linkTo = (item, url) => {
    this.markAsSeen(item, url);
    this.props.dispatch(push(url));
  };

  openFilterSelect() {
    this.setState({ filterSelectOpen: true });
  }

  async closeFilterSelect() {
    this.refresh();
    this.setState({ filterSelectOpen: false });
  }

  toggleOpenFilterSelect() {
    this.setState({ filterSelectOpen: !this.state.filterSelectOpen });
  }

  setOrder(event, order) {
    if (
      order === null ||
      this.state.params.order.toString().toLowerCase() === order.toString().toLowerCase()
    ) {
      return;
    }

    window.history.replaceState('', '', updateURLParameter(window.location.href, 'order', order));

    this.setState(
      {
        ...this.state,
        params: {
          ...this.state.params,
          order: order,
        },
      },
      this.refresh,
    );
  }

  handleFilterChange(event, value, reason) {
    //console.log(event, value, reason);
    let tmp = this.state.params;
    let tags = [];
    let search = [];
    switch (reason) {
      case 'select-option':
        //console.log("in select-option: ", value, tmp);
        tmp.tags = value;
        //tmp.input = "";
        break;
      case 'input':
        //console.log("in input change: ", value, typeof value, tmp);
        tmp.input = value;
        break;
      case 'create-option':
        //console.log("in create-option: ", value, tmp);
        tags = [];
        search = [];
        if (typeof value) {
          value.forEach((param) => {
            if (typeof param === 'object') {
              if (!!param.id || !!param['id']) {
                //console.log("APpendding to tags", param);
                tags.push(param);
              } else {
                //console.log("APpendding to search", param);
                search.push(param);
              }
            } else if (typeof param === 'string' && param !== '') {
              //console.log("APpendding to search", param);
              if (param.startsWith('t:')) {
                tags.push(param.substring(2));
              } else if (param.startsWith('tag:')) {
                tags.push(param.substring(4));
              } else {
                search.push(param);
              }
            }
          });
        }
        tmp.tags = tags.length > 0 ? tags : [];
        tmp.search = search.length > 0 ? search : [];
        break;
      case 'blur':
        //console.log("found blur action?", reason, value, event)
        tags = [];
        search = [];
        if (typeof value) {
          value.forEach((param) => {
            if (typeof param === 'object') {
              if (!!param.id || !!param['id']) {
                //console.log("APpendding to tags", param);
                tags.push(param);
              } else {
                console.log('[INTERESTING]', param);
                search.push(param);
              }
            } else if (typeof param === 'string' && param !== '') {
              //console.log("APpendding to search", param);
              //console.log("APpendding to search", param);
              if (param.startsWith('t:')) {
                param = param.substring(2);
                tags.push(param);
              } else if (param.startsWith('tag:')) {
                param = param.substring(4);
                tags.push(param);
              } else {
                search.push(param);
              }
            }
          });
        }
        //tmp.input = "";
        tmp.tags = tags.length > 0 ? tags : [];
        tmp.search = search.length > 0 ? search : [];
        break;
      case 'remove-option':
        //console.log("in remove-option: ", value, tmp);
        tags = [];
        search = [];
        if (typeof value) {
          value.forEach((param) => {
            if (typeof param === 'object') {
              if (!!param.id || !!param['id']) {
                //console.log("APpendding to tags", param);
                tags.push(param);
              } else {
                //console.log("APpendding to search", param);
                search.push(param);
              }
            } else if (typeof param === 'string' && param !== '') {
              //console.log("APpendding to search", param);
              search.push(param);
            }
          });
        }
        //tmp.input = "";
        tmp.tags = tags.length > 0 ? tags : [];
        tmp.search = search.length > 0 ? search : [];
        break;
      case 'clear':
        tmp.tags = [];
        tmp.search = [];
        tmp.input = '';
        break;
      case 'reset':
        //console.log("found reset action?", reason, value, event)
        tmp.input = '';
        break;
      default:
        //console.log("found default action?", reason, value, event)
        break;
    }

    this.state.isMounted === true &&
      this.setState({ ...this.state, params: tmp, filterBoxValue: value });
  }

  async submitFilters() {
    let tags = [];

    let search = [];

    //console.log("In submitFilters", this.state.params);
    if (!!this.state.params.search) {
      if (typeof this.state.params.search === 'object') {
        this.state.params.search.forEach((value) => search.push(value));
      } else {
        search.push(this.state.params.search);
      }
    }

    if (this.state.params.input !== '') {
      search.push(this.state.params.input);
    }

    if (!!this.state.params.tags) {
      this.state.params.tags.forEach((value) => {
        tags.push(value.name ?? value);
      });
    }
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'tags', tags));
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'search', search));
    //console.log("[SEARCH]", {search: search, tags: tags}, this.state)
    this.state.isMounted === true &&
      this.setState(
        {
          ...this.state,
          searchDone: true,
          params: {
            ...this.state.params,
            search: search,
            tags: tags,
          },
        },
        () => this.fetchList(false),
      );
  }

  async fetchList(search) {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox');
    url.searchParams.set('filter', this.state.params.filter);
    url.searchParams.set('order', this.state.params.order);
    let tmp = null;

    //console.log('[THIS IS SEARCH]',this.state.params, search);
    if (typeof search !== 'object') {
      search = {
        tags: this.state.params.tags,
        state: this.state.params.state,
        search: this.state.params.search,
      };
      //console.log("FETCH CUSTOM LIST", search );
    }

    if (typeof search === 'object' && !!search.search && !!search.tags) {
      tmp = unique(search.tags);
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "tags - " + index , value);
        url.searchParams.set('tags[' + index + ']', value);
      });

      tmp = unique(search.search);
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "search - " + index , value);
        url.searchParams.set('search[' + index + ']', value);
      });
      if (!!search.state) {
        tmp = unique(search.state);
        tmp.forEach((value, index) => {
          //console.log("[SEARCH]", "search - " + index , value);
          url.searchParams.set('state[' + index + ']', value);
        });
      }
    }
    url.searchParams.set('page', this.state.params?.page || 1);

    if (!!this.state.params.state) {
      tmp = unique(this.state.params.state);
      let newStates = tmp.map((value) => {
        if (typeof value !== 'object') {
          return filters.find((filter) => {
            return filter.state.toString().toLowerCase() === value.toString().toLowerCase();
          });
        }
        return value;
      });
      this.setState({ ...this.state, params: { ...this.state.params, state: newStates } });
      tmp = newStates.map((value) => value.state);
      window.history.replaceState('', '', updateURLParameter(window.location.href, 'state', tmp));
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "search - " + index , value);
        url.searchParams.set('state[' + index + ']', value);
      });
    }
    url.searchParams.set('size', this.state.params.size);

    let response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return !!json?.data ? json : false;
      })
      .catch((error) => {
        return error;
      });

    if (!!response?.data && this.state.isMounted === true) {
      this.setState({ isFetching: false, messages: response });
    } else {
      console.error(response);
      if (this.state.isMounted === true) {
        this.setState({ isFetching: false, messages: {} });
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    let changes = {};
    //console.log("[Component Did Update] Props:", prevProps, this.props);
    //console.log("[Component Did Update] State:",  prevState, this.state );
    const urlParams = new URLSearchParams(this.props.Router.location.search);
    if (prevProps.Router.location.key !== this.props.Router.location.key) {
      //this.props.Router.location.search ) {
      //console.log("Changes in searc:", prevProps.Router.location.search !== this.props.Router.location.search, prevProps.Router.location.search, this.props.Router.location.search)
      changes['order'] = urlParams.has('order') ? urlParams.get('order') : 'oldest';
      changes['size'] = urlParams.has('size') ? urlParams.get('size') : 15;
      changes['page'] = urlParams.has('page') ? urlParams.get('page') : 1;
      changes['tags'] = urlParams.has('tags') ? urlParams.getAll('tags') : [];

      let states = [];
      if (this.props.match.params.filter === 'alle') {
        states = unique([...urlParams.getAll('state'), ...urlParams.getAll('state[]')]);
        states = states.length > 0 ? states : defaultFilterStates;
      }
      changes['state'] = states;

      changes['search'] = urlParams.has('search') ? urlParams.getAll('search') : [];
    }
    if (prevProps.match.params.filter !== this.props.match.params.filter) {
      //console.log("Changes in filter:", prevProps.match.params.filter !== this.props.match.params.filter, prevProps.match.params.filter, this.props.match.params.filter)
      let states = [];
      if (this.props.match.params.filter === 'alle') {
        states = unique([...urlParams.getAll('state'), ...urlParams.getAll('state[]')]);
        states = states.length > 0 ? states : defaultFilterStates;
      }
      changes['filter'] = this.props.match.params.filter;
      changes['state'] = states;
    }

    //console.log("CHANGES", Object.keys(changes).length)
    if (Object.keys(changes).length >= 1) {
      //console.log("CHANGES", changes);#

      let filterBoxValue = [];
      var tags = [];
      if (Array.isArray(changes.tags)) {
        changes.tags.forEach((param) => {
          if (typeof param === 'object' && (!!param.name || !!param['name'])) {
            tags.push('tag:' + param.name);
          } else if (typeof param === 'string' && param !== '') {
            //console.log("Appendding to search", param);
            tags.push('tag:' + param);
          }
        });
      }

      filterBoxValue = filterBoxValue.concat(tags);
      filterBoxValue = filterBoxValue.concat(Array.isArray(changes.search) ? changes.search : []);
      //console.log('Setting changes', filterBoxValue, changes);
      this.setState(
        {
          ...this.state,
          isMounted: true,
          filterBoxValue: filterBoxValue,
          messages: [],
          isFetching: true,
          params: {
            ...this.state.params,
            ...changes,
          },
        },
        () => this.fetchList(false),
      );
    }
  }

  componentWillUnmount() {
    this.state.isMounted === true && this.setState({ ...this.state, isMounted: false });
  }
  async componentDidMount() {
    let states = [];
    if (this.state.params.filter === 'alle') {
      const urlParams = new URLSearchParams(this.props.Router.location.search);
      states = unique([...urlParams.getAll('state'), ...urlParams.getAll('state[]')]);
      states = states.length > 0 ? states : defaultFilterStates;
    }

    this.setState(
      {
        ...this.state,
        isMounted: true,
        params: {
          ...this.state.params,
          state: states,
        },
      },
      () => this.fetchList(false),
    );
  }

  handleStateChange(event, data) {
    //console.log("[handleStateChange]", event, data, this.state.params.state);
    if (data.props.value === 'close') {
      this.closeFilterSelect();
    }

    var clickedState = filters.find(
      (value) => value.state.toString().toLowerCase() === data.props.value.toString().toLowerCase(),
    );

    if (!!!clickedState) {
      return;
    }
    var newState = this.state.params.state;
    var indexOfState = newState.findIndex(
      (value) =>
        value.state.toString().toLowerCase() === clickedState.state.toString().toLowerCase(),
    );

    if (indexOfState > -1) {
      newState = newState.filter(
        (value) =>
          value.state.toString().toLowerCase() !== clickedState.state.toString().toLowerCase(),
      );
    } else {
      newState.push(clickedState);
    }

    this.setState({
      ...this.state,
      params: { ...this.state.params, state: newState },
    });
    //this.refresh();
  }

  async handleDeleteFunction() {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.state.toDelete.uuid);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'delete' }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response === 'Deleted') {
      this.setState({
        ...this.state,
        toDelete: {},
        deleteOpen: false,
        messages: {
          ...this.state.messages,
          data: this.state.messages.data.filter((element) => element.id !== this.state.toDelete.id),
        },
      });
      return true;
    }
    return false;
  }

  handleDeleteOpen(message) {
    this.state.isMounted === true &&
      this.setState({ ...this.state, toDelete: message, deleteOpen: true });
  }

  handleDeleteClose(message) {
    this.state.isMounted === true &&
      this.setState({ ...this.state, toDelete: {}, deleteOpen: false });
  }

  handleSaveSearchDialogOpen() {
    this.state.isMounted === true && this.setState({ saveSearchDialogOpen: true });
  }
  handleSaveSearchDialogClose() {
    this.state.isMounted === true && this.setState({ saveSearchDialogOpen: false });
  }

  handleSaveSearchChange(event) {
    this.state.isMounted === true && this.setState({ saveSearchName: event.target.value });
  }

  saveSearch() {
    let str = '';
    this.state.params.tags.forEach((value, index) => {
      if (typeof value === 'string') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('tags') + '=' + encodeURIComponent(value);
      } else if (value?.name?.trim() !== '') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('tags') + '=' + encodeURIComponent(value.name);
      }
    });

    this.state.params.search.forEach((value, index) => {
      if (value.trim() !== '') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('search') + '=' + encodeURIComponent(value);
      }
    });

    this.state.params.state.forEach((value, index) => {
      if (value.state.trim() !== '') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('state') + '=' + encodeURIComponent(value.state);
      }
    });

    let name = this.state.saveSearchName !== '' ? this.state.saveSearchName : str.substring(0, 20);

    this.props.dispatch(
      addCustomMenuPoint({
        key: name,
        parent: 'inbox',
        name: name,
        icon: 'ListAltIcon',
        path: '/nachrichten/' + this.state.params.filter + '/' + name + '?' + str,
      }),
    );
    if (this.state.isMounted === true) {
      this.setState({ saveSearchName: '', saveSearchDialogOpen: false });
    }
  }

  async handleSizeChange(event) {
    let size = event.target.value;
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'size', size));
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'page', 1));

    this.state.isMounted === true &&
      this.setState(
        {
          isFetching: true,
          params: { ...this.state.params, page: 1, size: size },
        },
        () => this.fetchList(false),
      );
  }
  async refresh() {
    this.props.dispatch(clearInbox());
    this.state.isMounted === true &&
      this.setState({ isFetching: true }, () => this.fetchList(false));
  }
  async handlePageChange(event, page) {
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'page', page));
    this.state.isMounted === true &&
      this.setState({ isFetching: true, params: { ...this.state.params, page: page } }, () =>
        this.fetchList(false),
      );
  }

  render() {
    const { classes } = this.props;

    const expanded = false; // this.props.Dimensions.width > this.props.Theme.breakpoints.values.md;
    return (
      <>
        <Dialog
          fullScreen={this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.md}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleDialogClose(false);
            }
          }}
          open={this.state.procedureDialogOpen}
        >
          <AddProcedure
            dialogData={this.state.dialogData}
            handleclosefunction={(reload) => this.handleDialogClose(reload)}
          />
        </Dialog>

        <Dialog
          fullWidth
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleDeleteClose();
            }
          }}
          open={this.state.deleteOpen}
        >
          <DialogTitle>Nachricht löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Nachricht sicher löschen?
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleDeleteClose} color='primary'>
              Abbrechen
            </Button>
            <Button
              onClick={this.handleDeleteFunction}
              variant='outlined'
              color='secondary'
              autoFocus
            >
              LÖSCHEN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.saveSearchDialogOpen}
          onClose={this.handleSaveSearchDialogClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogContent>
            <DialogContentText>Name für die gespeicherte Suche:</DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              value={this.state.saveSearchName}
              onChange={this.handleSaveSearchChange}
              label='Name'
              type='text'
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveSearchDialogClose} color='primary'>
              Abbrechen
            </Button>
            <Button variant='contained' onClick={this.saveSearch} color='primary'>
              Speichern
            </Button>
          </DialogActions>
        </Dialog>

        <div
          style={{
            margin: 0,
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'flex',
          }}
        >
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column',
              flexGrow: '1',
              margin: '8px',
            }}
          >
            <CardHeader
              disableTypography
              classes={{
                root: classes.cardheader,
                content: classes.maxWidth,
              }}
              title={
                <Grid container spacing={2}>
                  {this.state.isAdmin && 'alle' === this.state.params.filter ? (
                    <Grid item xs={12}>
                      <FormControl fullWidth variant='outlined' size='small' margin='dense'>
                        <InputLabel id='demo-mutiple-chip-label' shrink>
                          Status auswahl
                        </InputLabel>
                        <Select
                          labelId='demo-mutiple-chip-label'
                          label='Status auswahl'
                          id='demo-mutiple-chip'
                          multiple
                          open={this.state.filterSelectOpen}
                          value={this.state.params.state}
                          onOpen={this.openFilterSelect}
                          onChange={this.handleStateChange}
                          renderValue={(selected) => (
                            <div className={classes.chips}>
                              {selected.map((value) => (
                                <Chip
                                  icon={value.icon}
                                  key={value.state}
                                  label={value.name}
                                  className={classes.chip}
                                  color='primary'
                                  variant='outlined'
                                />
                              ))}
                            </div>
                          )}
                        >
                          <MenuItem disabled>
                            <ListItemText primary='Nach welchen Stati sollen wir filtern?' />
                          </MenuItem>
                          {filters.map((state) => {
                            return (
                              <MenuItem key={state.key} value={state.state}>
                                <Box mx={2} />
                                <ListItemIcon>{state.icon}</ListItemIcon>
                                <ListItemText primary={state.name} />
                                <Checkbox
                                  style={{ marginLeft: 'auto' }}
                                  checked={
                                    this.state.params.state.findIndex(
                                      (value) => value.name === state.name,
                                    ) > -1
                                  }
                                />
                              </MenuItem>
                            );
                          })}

                          <Box key='close-button' value='close' px={4} pb={1} pt={2}>
                            <Button fullWidth variant='contained' color='primary'>
                              {' '}
                              Filter anwenden{' '}
                            </Button>
                          </Box>
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={9} sm={this.state.searchDone ? 8 : 10}>
                    <Autocomplete
                      autoComplete={true}
                      autoSelect={true}
                      includeInputInList={true}
                      autoHighlight={true}
                      size='small'
                      value={this.state.filterBoxValue}
                      multiple
                      freeSolo
                      options={this.state.tags}
                      getOptionLabel={(tag) => {
                        if (!!tag.name) {
                          return tag.name;
                        } else {
                          return tag;
                        }
                      }}
                      style={{ width: '100%' }}
                      onChange={this.handleFilterChange}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          value={this.state.params.input}
                          name='searchInput'
                          label='Suchen ...'
                          variant='outlined'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Button
                      fullWidth
                      size='medium'
                      color='primary'
                      variant='outlined'
                      onClick={this.submitFilters}
                      aria-label='Back'
                    >
                      <SearchIcon />
                      <Hidden smDown>Suchen</Hidden>
                    </Button>
                  </Grid>
                  {this.state.searchDone ? (
                    <Grid item xs={12} sm={2}>
                      <Button
                        fullWidth
                        size='medium'
                        color='primary'
                        variant='contained'
                        onClick={this.handleSaveSearchDialogOpen}
                        aria-label='Back'
                      >
                        <SaveIcon />
                        Suche Speichern
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>
              }
            />
            <CardActions className={classes.footer}>
              <Pagination
                count={Math.ceil(this.state.messages.total / this.state.messages.per_page)}
                className={classes.pagination}
                page={this.state.params.page}
                total={this.state.messages.total}
                size='medium'
                variant='outlined'
                color='primary'
                onChange={this.handlePageChange}
              />

              <Hidden smDown>
                <InputLabel
                  htmlFor='size'
                  style={{
                    fontSize: this.props.Theme.typography.caption.fontSize,
                  }}
                >
                  Einträge pro Seite
                </InputLabel>
                <Select
                  native
                  value={this.state.params.size}
                  onChange={this.handleSizeChange}
                  inputProps={{
                    name: 'size',
                    id: 'size',
                  }}
                >
                  <option value={this.state.params.size}>{this.state.params.size}</option>
                  <option value={15}>15</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </Select>
              </Hidden>

              <Hidden smDown>
                <IconButton onClick={this.refresh}>
                  <RefreshIcon />
                </IconButton>
              </Hidden>

              <ToggleButtonGroup
                size='small'
                value={this.state.params.order}
                exclusive
                onChange={this.setOrder}
                aria-label='text alignment'
              >
                <ToggleButton
                  classes={{ selected: classes.customColorSelected }}
                  value='oldest'
                  aria-label='left aligned'
                >
                  <Tooltip title='Die ältesten Vorgänge auf Seite eins anzeigen'>
                    <Box display='flex'>
                      <SortIcon />
                      <Hidden smDown> &nbsp; Älteste zuerst</Hidden>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  classes={{ selected: classes.customColorSelected }}
                  value='newest'
                  aria-label='right aligned'
                >
                  <Tooltip title='Die neusten Vorgänge auf Seite eins anzeigen'>
                    <Box display='flex'>
                      <SortIcon style={{ transform: 'scaleY(-1)' }} />
                      <Hidden smDown> &nbsp; Neuste Zuerst</Hidden>
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton
                  classes={{ selected: classes.customColorSelected }}
                  value='updated'
                  aria-label='centered'
                >
                  <Tooltip title='Die zuletzt aktualisierten Vorgänge auf Seite eins anzeigen'>
                    <Box display='flex'>
                      <RefreshIcon />
                      <Hidden smDown> &nbsp; zuletzt Aktualisiert</Hidden>
                    </Box>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </CardActions>
            <Divider />
            <CardContent style={{ overflow: 'auto', flexGrow: 1, padding: '0' }}>
              <List disablePadding>
                {this.state.isFetching ? (
                  <center>
                    {' '}
                    <CircularProgress />{' '}
                  </center>
                ) : !!this.state.messages?.data &&
                  Object.keys(this.state.messages?.data).length > 0 ? (
                  Object.values(this.state.messages.data).map((value) => (
                    <ListItemOrga
                      key={'item-' + value.uuid}
                      expanded={expanded}
                      isAdmin={this.state.isAdmin}
                      message={value}
                      delete={this.handleDeleteOpen}
                      hasChange={this.props.AutoRefresh.messages.findIndex((elmt) =>
                        elmt.link.includes(value.uuid),
                      )}
                      linkTo={(url) => this.props.dispatch(push(url))}
                    />
                  ))
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '3em',
                    }}
                  >
                    <CheckCircleIcon fontSize='large' color='primary' />
                    <Typography variant='h5' color='primary'>
                      {' '}
                      Alles erledigt!{' '}
                    </Typography>
                  </Box>
                )}
              </List>
            </CardContent>
          </Card>
        </div>

        <Fab onClick={this.openMenu} className={classes.fab} color='primary' aria-label='edit'>
          <AddIcon />
        </Fab>

        <Menu
          elevation={8}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          id='customized-menu'
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={Boolean(this.state.menuAnchor)}
          onClose={this.closeMenu}
          classes={{ paper: classes.menu }}
        >
          <MenuItem onClick={this.newItem}>
            <ListItemIcon>
              <VerticalSplitIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Vorgangsformular' />
          </MenuItem>
          <MenuItem onClick={this.newFast}>
            <ListItemIcon>
              <FastForwardIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Schneller Eintrag' />
          </MenuItem>
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  isAdvancedUser: state.User?.isAdvancedUser ?? true,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
  AutoRefresh: state.AutoRefresh,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Inbox);
