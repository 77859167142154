import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Menu from '@material-ui/core/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { DE as TextDE } from '../../lib/Text';

const CustomCategoryPicker = (props) => {
  const [category, setCategory] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);

  const handleClick = (event) => {
    setAnchorEl([event.clientX - 2, event.clientY - 4]);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };
  const handleDelete = () => {
    setAnchorEl(false);
    setCategory(null);
    props.onFilterChanged(props.columnDef.tableData.id, null);
  };
  const handleCategoryClick = (category) => {
    setCategory(category);
    setAnchorEl(false);
    props.onFilterChanged(props.columnDef.tableData.id, category.category);
  };

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      {!!category ? (
        <Chip
          label={category.category.toString().toUpperCase()}
          style={{
            marginTop: 'auto',
            marginBottom: 'auto',
            color: category.color,
            backgroundColor: category.backgroundColor,
            flexGrow: 1,
          }}
        />
      ) : (
        <Button
          variant='contained'
          fullWidth
          style={{ display: 'flex', flexGrow: 1 }}
          onClick={handleClick}
        >
          Kategorie filtern...
        </Button>
      )}

      <Menu
        id='add-menu'
        onClose={handleClose}
        open={Boolean(anchorEl)}
        keepMounted
        anchorReference='anchorPosition'
        anchorPosition={
          anchorEl !== false && anchorEl.length === 2
            ? { top: anchorEl[1], left: anchorEl[0] }
            : undefined
        }
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: {
            maxWidth: '90ch',
            minWidth: '30ch',
            maxHeight: '30em',
          },
        }}
      >
        <Typography variant='h5' style={{ margin: '8px 8px 12px 8px' }}>
          {TextDE.timetracking.helperCategoryFilter}
        </Typography>
        {Object.values(props.categories)?.map((option, index) => (
          <ListItem
            ContainerComponent='div'
            ContainerProps={{ style: { width: '100%' } }}
            key={index}
            onClick={() => handleCategoryClick(option)}
          >
            <ListItemAvatar>
              <Avatar
                alt={option.category}
                style={{ backgroundColor: option.backgroundColor, color: option.color }}
              />
            </ListItemAvatar>
            <ListItemText primary={option.category} />
          </ListItem>
        ))}
      </Menu>
      {!!category && (
        <IconButton aria-label='delete' onClick={handleDelete}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
export default CustomCategoryPicker;
