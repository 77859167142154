import {
  FETCH_USER,
  FETCH_CUSTOMER,
  FETCH_OTHER,
  SET_ECHO,
  APPEND_INBOX,
  REMOVE_INBOX,
  CLEAR_INBOX,
  RESET_NOTFICATIONS,
  RESET,
} from '../actions/AutoRefresh';

const initialState = {
  Echo: false,
  fetchUser: false,
  fetchCustomer: false,
  fetchOther: false,
  messages: [],
};

const AutoRefresh = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER:
      //console.log("Called FETCH_USER");
      return { ...state, fetchUser: action.res.fetchUser };

    case FETCH_CUSTOMER:
      //console.log("Called FETCH_CUSTOMER");
      return { ...state, fetchCustomer: action.res.fetchCustomer };

    case FETCH_OTHER:
      //console.log("Called FETCH_OTHER");
      return { ...state, fetchOther: action.res.fetchOther };
    case SET_ECHO:
      //console.log("Called SET_ECHO");
      return { ...state, Echo: action.res.Echo };
    case APPEND_INBOX:
      //console.log("Called APPEND_INBOX");
      //console.log(state.messages);
      //console.log(action.res);
      return { ...state, messages: [...state.messages, action.res] };
    case REMOVE_INBOX:
      //console.log("Called REMOVE_INBOX");
      let tmp = state.messages.filter((element) => element.link !== action.res);
      return { ...state, messages: tmp };
    case CLEAR_INBOX:
      //console.log("Called CLEAR_INBOX");
      return { ...state, messages: [] };
    case RESET_NOTFICATIONS:
      //console.log("Called RESET_NOTFICATIONS");
      return {
        Echo: false,
        fetchUser: false,
        fetchCustomer: false,
        fetchOther: false,
        messages: [],
      };
    case RESET:
      //console.log("Called RESET");
      return initialState;
    default:
      return state;
  }
};
export default AutoRefresh;
