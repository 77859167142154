import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
//import { ThemeProvider } from '@material-ui/core/styles';
import UserConfigForm from './UserConfigForm';
import UserRoles from './UserRoles';
import CompanyConfig from './CompanyConfig';
import EMailConfigForm from './EMailConfigForm';
import ThemePicker from './ThemePicker';
import ProcedureConfig from './ProcedureConfig';
import TodoGenerator from './TodoGenerator';
import PartsListing from './PartsListing';
import TagsListing from './TagsListing';
import TextTemplates from './TextTemplates';
import ExternalForm from './ExternalForm';
import CustomerForm from './CustomerForm';
import ContactImport from './ContactImport';
import Account from './Account';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
//import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
//import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import ColorLensIcon from '@material-ui/icons/ColorLens';
//import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CircularProgress from '@material-ui/core/CircularProgress';
//import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import { Constants } from '../../lib/Constants';
import VerificationBox from '../VerifyEmail/VerificationBox';
//import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
//import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import NotEnabled from './NotEnabled';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const styles = (theme) => ({
  tabPanel: {
    maxHeight: 'inherit',
    overflow: 'auto',
  },
  hidden: {
    display: 'none',
  },
  display: {},
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

// 0="Persönlich"
// 1="Mitarbeiterkonfiguration"
// 2="Unternehmenskonfiguration"
// 3="E-Mail"
// 4="Logo & Farben"
// 5="Vorgangsbezeichnung"
// 6="Todo Generator"
// 7="Material Liste"
// 8="Account"
// 9="Schnellfunktion"
// 10="Textbausteine"
// 11="External Form"
//className={clsx( [classes.menuTitleSmall]: !this.props.NavMenu.collapsed })}
//className={clsx( [classes.hidden]: !this.state.isAdvancedUser })}
//className={this.state.isAdvancedUser ?  classes.display : classes.hidden }

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const pageToIndexMap = {
  me: 0,
  account: 8,
  company: 2,
  users: 1,
  mailserver: 3,
  serial: 5,
  tags: 9,
  templates: 10,
  todos: 6,
  parts: 7,
  style: 4,
  externalForm: 11,
  customerForm: 12,
  contactImport: 13,
};
const indexToPageMap = {
  0: 'me',
  8: 'account',
  2: 'company',
  1: 'users',
  3: 'mailserver',
  5: 'serial',
  9: 'tags',
  10: 'templates',
  6: 'todos',
  7: 'parts',
  4: 'style',
  11: 'externalForm',
  12: 'customerForm',
  13: 'contactImport',
};

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabindex: pageToIndexMap[props.match.params.page] ?? 0,
      personal: true,
      colleagues: false,
      isAdvancedUser: !!props.User.isAdvancedUser ?? false,
      company: false,
      email: false,
      todos: false,
      menuX: null,
      menuY: null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleCompanyMenuClose = () => {
    this.setState({ menu: null });
  };

  handleCompanyMenuSelect = (tabIndex) => {
    //console.log("handleCompanyMenuSelect : " + tabIndex + " / " + indexToPageMap[tabIndex])
    this.setState({ menu: null, tabindex: 102 });
    this.props.dispatch(push('/settings/' + indexToPageMap[tabIndex]));
  };

  handleInquiryMenuClose = () => {
    this.setState({ menu: null });
  };

  handleInquiryMenuSelect = (tabIndex) => {
    //console.log("handleInquiryMenuSelect : " + tabIndex + " / " + indexToPageMap[tabIndex])
    this.setState({ menu: null, tabindex: 103 });
    this.props.dispatch(push('/settings/' + indexToPageMap[tabIndex]));
  };

  handleChange = (event, newValue) => {
    //console.log("handleChange", newValue, Object.keys(indexToPageMap).length, indexToPageMap[newValue]);
    if (newValue <= Object.keys(indexToPageMap).length) {
      this.setState({ ...this.state, tabindex: newValue });
      this.props.dispatch(push('/settings/' + indexToPageMap[newValue]));
    } else {
      this.setState({
        menu: newValue,
        menuX: event.clientX + 8,
        menuY: event.clientY + 12,
      });
    }
  };

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.match.params.page !== this.props.match.params.page) {
      this.setState({
        ...this.state,
        tabindex: pageToIndexMap[this.props.match.params.page] ?? 0,
      });
    }
  }

  componentDidMount() {
    let colleagues = Constants.permissions.check(
      this.props.User,
      Constants.permissions.PERM_COWORKERS_READ,
    )
      ? true
      : Constants.roles.check(this.props.User, [
          Constants.roles.ROLES_NEWLY_REGISTERED,
          Constants.roles.ROLES_ADMIN,
        ]);
    //console.log("i may see colleagues", (colleagues ? "true" : "false" ));
    let organizer = Constants.permissions.check(
      this.props.User,
      Constants.permissions.PERM_COMPANY_READ,
    )
      ? true
      : Constants.roles.check(this.props.User, [
          Constants.roles.ROLES_NEWLY_REGISTERED,
          Constants.roles.ROLES_ORGANIZER,
          Constants.roles.ROLES_ADMIN,
        ]);
    //console.log("i may organize", (organizer ? "true" : "false" ));
    let company = Constants.permissions.check(
      this.props.User,
      Constants.permissions.PERM_COMPANY_READ,
    )
      ? true
      : Constants.roles.check(this.props.User, [
          Constants.roles.ROLES_NEWLY_REGISTERED,
          Constants.roles.ROLES_ADMIN,
        ]);
    //console.log("i may see company", (company ? "true" : "false" ));
    let email = Constants.permissions.check(this.props.User, Constants.permissions.PERM_EMAIL_READ)
      ? true
      : Constants.roles.check(this.props.User, [
          Constants.roles.ROLES_NEWLY_REGISTERED,
          Constants.roles.ROLES_ADMIN,
        ]);
    //console.log("i may see email", (email ? "true" : "false" ));
    let todos = Constants.permissions.check(this.props.User, Constants.permissions.PERM_TODOS_READ)
      ? true
      : Constants.roles.check(this.props.User, [
          Constants.roles.ROLES_NEWLY_REGISTERED,
          Constants.roles.ROLES_ADMIN,
        ]);
    //console.log("i may see todos", (todos ? "true" : "false" ));
    this.setState({
      loading: false,
      colleagues: colleagues,
      company: company,
      email: email,
      todos: todos,
      organizer: organizer,
    });
  }

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return (
        <>
          <center>
            {' '}
            <CircularProgress style={{ marginTop: '2em' }} />{' '}
          </center>
        </>
      );
    } else {
      return (
        <>
          <div
            style={{
              margin: '8px',
              width: this.props.Dimensions.maxWidth - 16,
              maxWidth: this.props.Dimensions.maxWidth - 16,
              height: this.props.Dimensions.height - (this.props.Dimensions.appBarHeight + 16),
              maxHeight: this.props.Dimensions.height - (this.props.Dimensions.appBarHeight + 16),
              overflow: 'auto',
              backgroundColor: '#ffffff',
            }}
          >
            <AppBar position='static' color='default'>
              <Tabs
                value={this.state.tabindex}
                onChange={this.handleChange}
                variant='scrollable'
                scrollButtons='on'
                indicatorColor='primary'
                textColor='primary'
                aria-label='Alle Einstellungen'
              >
                <Tab
                  icon={<AccountBoxIcon />}
                  label='Persönlich'
                  value={pageToIndexMap['me']}
                  {...a11yProps(pageToIndexMap['me'])}
                />
                {/*<Tab
                  icon={
                    <Badge overlap='circular' color='secondary' variant='dot'>
                      <TuneIcon />
                    </Badge>
                  }
                  label='Account'
                  disabled={!this.state.company}
                  value={pageToIndexMap['account']}
                  {...a11yProps(pageToIndexMap['account'])}
                />*/}
                <Tab
                  icon={<BusinessIcon />}
                  label='Unternehmenskonfiguration'
                  disabled={!this.state.company}
                  value={102}
                />

                <Tab
                  icon={<PlaylistAddCheckIcon />}
                  label='Konfiguration'
                  disabled={!this.state.organizer}
                  value={103}
                  //className={this.state.isAdvancedUser ? classes.display : classes.hidden}
                />

                <Tab
                  icon={<ColorLensIcon />}
                  label='Logo & Farben'
                  disabled={!this.state.company}
                  value={pageToIndexMap['style']}
                  {...a11yProps(pageToIndexMap['style'])}
                />
                <Tab
                  value={pageToIndexMap['users']}
                  label='Mitarbeiterkonfiguration'
                  disabled
                  {...a11yProps(1)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['mailserver']}
                  label='E-Mail'
                  disabled
                  {...a11yProps(3)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['serial']}
                  label='Vorgangsbezeichnung'
                  disabled
                  {...a11yProps(5)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['tags']}
                  label='Schnellfunktion'
                  disabled
                  {...a11yProps(9)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['templates']}
                  label='Textbausteine'
                  disabled
                  {...a11yProps(10)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['externalForm']}
                  label='Externes Formular'
                  disabled
                  {...a11yProps(11)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['customerForm']}
                  label='Kunden Formular'
                  disabled
                  {...a11yProps(12)}
                  className={classes.hidden}
                />
                {/*<Tab
                  value={pageToIndexMap['todos']}
                  label='Todo Generator'
                  disabled
                  {...a11yProps(6)}
                  className={classes.hidden}
                />*/}
                <Tab
                  value={pageToIndexMap['parts']}
                  label='Material Liste'
                  disabled
                  {...a11yProps(7)}
                  className={classes.hidden}
                />
                <Tab
                  value={pageToIndexMap['contactImport']}
                  label='Kontakt Importer'
                  disabled
                  {...a11yProps(13)}
                  className={classes.hidden}
                />
              </Tabs>
            </AppBar>

            <Menu
              id='company-menu'
              elevation={8}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorReference='anchorPosition'
              anchorPosition={
                this.state.menuY !== null && this.state.menuX !== null
                  ? { top: this.state.menuY, left: this.state.menuX }
                  : undefined
              }
              keepMounted
              open={Boolean(this.state.menu === 102)}
              onClose={this.handleCompanyMenuClose}
            >
              <MenuItem onClick={() => this.handleCompanyMenuSelect(pageToIndexMap['users'])}>
                Mitarbeiterkonfiguration
              </MenuItem>
              <MenuItem onClick={() => this.handleCompanyMenuSelect(pageToIndexMap['company'])}>
                Unternehmenskonfiguration
              </MenuItem>
            </Menu>

            <Menu
              id='inquiry-menu'
              elevation={8}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              anchorReference='anchorPosition'
              anchorPosition={
                this.state.menuY !== null && this.state.menuX !== null
                  ? { top: this.state.menuY, left: this.state.menuX }
                  : undefined
              }
              keepMounted
              open={Boolean(this.state.menu === 103)}
              onClose={this.handleCompanyMenuClose}
            >
              {/*<MenuItem onClick={() => this.handleInquiryMenuSelect(pageToIndexMap['todos'])}>
                Todo Generator
              </MenuItem>*/}

              <MenuItem onClick={() => this.handleCompanyMenuSelect(pageToIndexMap['mailserver'])}>
                E-Mail
              </MenuItem>
              <MenuItem
                onClick={() => this.handleCompanyMenuSelect(pageToIndexMap['externalForm'])}
              >
                Externes Formular
              </MenuItem>
              <MenuItem
                onClick={() => this.handleCompanyMenuSelect(pageToIndexMap['customerForm'])}
              >
                Kunden Formular
              </MenuItem>
              <MenuItem
                onClick={() => this.handleCompanyMenuSelect(pageToIndexMap['contactImport'])}
              >
                Kontakt Import
              </MenuItem>
              <MenuItem onClick={() => this.handleInquiryMenuSelect(pageToIndexMap['parts'])}>
                Material Liste
              </MenuItem>
              <MenuItem onClick={() => this.handleInquiryMenuSelect(pageToIndexMap['tags'])}>
                Schnellfunktion
              </MenuItem>
              <MenuItem onClick={() => this.handleInquiryMenuSelect(pageToIndexMap['templates'])}>
                Textbausteine
              </MenuItem>
              <MenuItem onClick={() => this.handleInquiryMenuSelect(pageToIndexMap['serial'])}>
                Vorgangsbezeichnung
              </MenuItem>
            </Menu>

            <div
              style={{
                maxHeight:
                  this.props.Dimensions.height - (this.props.Dimensions.appBarHeight + 72 + 16),
              }}
            >
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['me']}
              >
                <UserConfigForm />
                <VerificationBox />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['account']}
              >
                <Account />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['company']}
              >
                <CompanyConfig />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['users']}
              >
                <UserRoles />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['mailserver']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <EMailConfigForm />
                ) : (
                  <NotEnabled data='Mailserver' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['serial']}
              >
                <ProcedureConfig />
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['tags']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <TagsListing />
                ) : (
                  <NotEnabled data='Schnellfunktionen' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['templates']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <TextTemplates />
                ) : (
                  <NotEnabled data='Textvorlagen' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['todos']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <TodoGenerator />
                ) : (
                  <NotEnabled data='Auftragsvorlagen' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['externalForm']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <ExternalForm />
                ) : (
                  <NotEnabled data='Externes Formular' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['customerForm']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <CustomerForm />
                ) : (
                  <NotEnabled data='Kunden Formular' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['contactImport']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <ContactImport />
                ) : (
                  <NotEnabled data='Kontakt Import' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['parts']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <PartsListing />
                ) : (
                  <NotEnabled data='Materiallisten' />
                )}
              </TabPanel>
              <TabPanel
                className={classes.tabPanel}
                value={this.state.tabindex}
                index={pageToIndexMap['style']}
              >
                {this.props.User.customer.package_id > 1 ? (
                  <ThemePicker />
                ) : (
                  <NotEnabled data='Logo und Farbe' />
                )}
              </TabPanel>
            </div>
          </div>
        </>
      );
    }
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Settings);
