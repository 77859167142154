import { THEME, LOGO, RESET } from '../actions/Style';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const initialState = {
  Theme: {
    typography: {
      fontSize: 14,
    },
    background: {
      default: '#EEEEEE',
      html: '#EEEEEE',
    },
    palette: {
      type: 'light',
      primary: {
        main: '#2E88D9',
      },
      secondary: {
        main: '#636E6B',
      },
    },
  },
  Logo: null,
};

const Style = (state = initialState, action) => {
  switch (action.type) {
    case THEME:
      if (!!action.theme) {
        let theme = !!action.theme?.theme ? action.theme.theme : action.theme;

        theme.typography.fontSize = parseInt(theme.typography.fontSize);
        document
          .getElementsByTagName('html')[0]
          .setAttribute(
            'style',
            'background-color: ' + theme.palette?.background?.html || '#ffffff',
          );
        state = { Theme: responsiveFontSizes(createTheme(theme)), Logo: state.Logo };
      } else {
        state = { Theme: responsiveFontSizes(createTheme(state.Theme)), Logo: state.Logo };
      }
      return state;
    case LOGO:
      if (!!action.logo) {
        state = {
          Theme: state.Theme,
          Logo: !!action.logo?.logo ? action.logo.logo : action.logo,
        };
      }
      return state;
    case RESET:
      state = {
        Theme: responsiveFontSizes(createTheme(initialState.Theme)),
        Logo: initialState.Logo,
      };
      return state;
    default:
      if (!!state.Theme.direction) {
        return state;
      }
      state = {
        Theme: responsiveFontSizes(createTheme(initialState.Theme)),
        Logo: initialState.Logo,
      };
      return state;
  }
};
export default Style;
