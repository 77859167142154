import React from 'react';
import Popper from '@material-ui/core/Popper';
//import { ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { removeNotification, removeAllNotification } from '../../actions/Notifications';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import CardActions from '@material-ui/core/CardActions';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep';
import Button from '@material-ui/core/Button';
import { Constants } from '../../lib/Constants';
import EventSubscriber from '../../lib/EventSubscriber';
import { DE as TextDE } from '../../lib/Text';
const styles = (theme) => ({
  noPad: {
    padding: 0,
  },
  smallPad: {
    padding: theme.spacing(1),
  },
  zIndex: {
    zIndex: '9000',
  },
  popper: {
    backgroundColor: theme.palette.background.default,
    zIndex: '9000',
    maxWidth: '95vw',
    minWidth: '30vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '50vw',
    },
  },
  list: {
    maxHeight: '300px',
    width: 'inherit',
    maxWidth: '100%',
    overflow: 'auto',
    //border: '1px solid #000000',
  },
});

class NotificationButton extends React.Component {
  toggleNotifications(event) {
    this.setState({ open: !!this.state.open ? false : event.target });
  }

  async deleteAllNotifications() {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/notifications/all');
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        return res.statusText === 'OK';
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    if (!!response) {
      this.props.dispatch(removeAllNotification());
    }
  }

  async deleteNotification(notification) {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/notifications/' + notification.id);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        return res.statusText === 'OK';
      })
      .catch((error) => {
        console.error(error);
        return error;
      });

    if (!!response) {
      this.props.dispatch(removeNotification(notification));
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      loading: false,
    };
    this.toggleNotifications = this.toggleNotifications.bind(this);
    this.deleteNotification = this.deleteNotification.bind(this);
    this.deleteAllNotifications = this.deleteAllNotifications.bind(this);
    this.handleLink = this.handleLink.bind(this);
  }

  async handleLink(notification) {
    this.deleteNotification(notification);
    this.props.dispatch(push(notification.data.link));
  }

  render() {
    const { classes } = this.props;
    //if (this.props.Notifications.length > 0) {
    return (
      <>
        <IconButton color='inherit' onClick={this.toggleNotifications} className={classes.smallPad}>
          <Badge
            className={classes.margin}
            badgeContent={Object.entries(this.props.Notifications).length}
            color='secondary'
            overlap='rectangular'
          >
            <EventSubscriber />
          </Badge>
        </IconButton>

        <Popper
          className={classes.zIndex}
          open={!!this.state.open}
          placement='top-end'
          anchorEl={document.getElementById('user-titlebar-button')}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Card className={classes.popper} raised>
                <CardHeader
                  className={classes.smallPad}
                  action={
                    <IconButton onClick={this.toggleNotifications}>
                      <CloseIcon />
                    </IconButton>
                  }
                  titleTypographyProps={{ variant: 'h6' }}
                  title={TextDE.notifications.label}
                />

                <Divider />
                <CardContent className={classes.noPad}>
                  <List disablePadding className={classes.list}>
                    {this.props.Notifications.map((notification) => {
                      notification.data = !!notification.data.data
                        ? notification.data.data
                        : notification.data;
                      return (
                        <ListItem
                          button
                          key={notification.id}
                          alignItems='flex-start'
                          onClick={() => this.handleLink(notification)}
                        >
                          <ListItemAvatar>
                            <Avatar>
                              {Constants.getInboxItemType(notification.data?.type).icon}
                            </Avatar>
                          </ListItemAvatar>

                          <ListItemText
                            primary={
                              !!notification.data?.identifier
                                ? notification.data?.identifier
                                : notification.data?.subject
                            }
                            secondary={
                              !!notification.data?.identifier ? (
                                <>
                                  <strong>{notification.data?.subject} -</strong>
                                  {notification.data?.body}
                                </>
                              ) : (
                                notification.data?.body
                              )
                            }
                          />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge='end'
                              aria-label='delete'
                              onClick={() => this.deleteNotification(notification)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardContent>
                <Divider />
                <CardActions className={classes.smallPad} style={{ justifyContent: 'end' }}>
                  <Button
                    variant='contained'
                    color='secondary'
                    startIcon={<DeleteSweepIcon color='inherit' />}
                    onClick={this.deleteAllNotifications}
                  >
                    {TextDE.notifications.deleteAllNotificationsLabel}
                  </Button>
                </CardActions>
              </Card>
            </Fade>
          )}
        </Popper>
      </>
    );
    //} else {
    //  return null;
    //}
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Notifications: state.Notifications,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NotificationButton);
