import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import PersonIcon from '@material-ui/icons/Person';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HelpIcon from '@material-ui/icons/Help';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import AlarmIcon from '@material-ui/icons/Alarm';
import DescriptionIcon from '@material-ui/icons/Description';
import Alert from '@material-ui/lab/Alert';
import LinkIcon from '@material-ui/icons/Link';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { Constants } from '../../lib/Constants';
import { DE as TextDE } from '../../lib/Text';

const styles = (theme) => ({
  listitemroot: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5, 2),
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    border: '0px solid ' + theme.palette.success.dark,
    boxShadow: theme.shadows[4],
    borderRadius: '1em',
  },
  outlined: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.paper,
  },
  green: {
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
  },
  red: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  yellow: {
    color: theme.palette.warning.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const timeString = (isAllDay, start, end) => {
  start = typeof start.toUTCString === 'function' ? new Date(start.toUTCString()) : new Date(start);
  end = typeof end.toUTCString === 'function' ? new Date(end.toUTCString()) : new Date(end);
  const compareStart = `${start.getDate()}${start.getFullYear()}${start.getMonth()}`;
  const compareEnd = `${end.getDate()}${end.getFullYear()}${end.getMonth()}`;
  if (isAllDay) {
    if (compareStart === compareEnd) {
      return start.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    } else {
      return (
        start.toLocaleDateString('de-DE', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }) +
        ' bis ' +
        end.toLocaleDateString('de-DE', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
      );
    }
  } else {
    return (
      start.toLocaleTimeString().slice(0, -3) +
      ' Uhr bis ' +
      end.toLocaleTimeString().slice(0, -3) +
      ' Uhr am ' +
      end.toLocaleDateString('de-DE', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    );
  }
};

class DetailDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      toDelete: false,
      viewOnly: this.props.viewOnly ?? false,
      userIsOrganizer: false,
    };
    this.edit = this.edit.bind(this);
    this.delete = this.delete.bind(this);
    this.setPartstat = this.setPartstat.bind(this);
    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
  }

  componentDidMount() {
    let isOrganizer =
      !!this.props.data?.raw?.organizer?.uuid &&
      this.props.data?.raw?.organizer?.uuid === this.props.User.uuid
        ? true
        : false;
    isOrganizer = isOrganizer
      ? isOrganizer
      : Constants.roles.check(this.props.User, [
          Constants.roles.ROLES_ORGANIZER,
          Constants.roles.ROLES_ADMIN,
        ]);
    this.setState({ userIsOrganizer: isOrganizer });
  }
  deleteDialog() {
    this.setState({ toDelete: true });
  }

  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  async edit() {
    this.props.handleeditfunction(this.props.data);
  }

  async setPartstat(partstat) {
    let url = process.env.REACT_APP_API_URL + '/api/calendar/partstat/' + this.props.data?.raw?.uid;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({
        uid: this.props.data?.raw?.uid,
        //firstoccurence: Date.parse(this.props.data?.raw?.firstoccurence)/1000,
        firstoccurence: this.props.data?.raw?.firstoccurence,
        //lastoccurence: Date.parse(this.props.data?.raw?.lastoccurence)/1000,
        lastoccurence: this.props.data?.raw?.lastoccurence,
        etag: this.props.data?.raw?.etag,
        calendarId: this.props.data?.calendarId,
        id: this.props.data?.id,
        partstat: partstat,
      }),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (!!response && !!response.id && !!response.calendarId) {
      this.props.handleupdatefunction(response);
    }
  }

  async delete() {
    let url = process.env.REACT_APP_API_URL + '/api/calendar/' + this.props.data?.raw?.uid;
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({
        uid: this.props.data?.raw?.uid,
        //firstoccurence: Date.parse(this.props.data?.raw?.firstoccurence)/1000,
        firstoccurence: this.props.data?.raw?.firstoccurence,
        //lastoccurence: Date.parse(this.props.data?.raw?.lastoccurence)/1000,
        lastoccurence: this.props.data?.raw?.lastoccurence,
        etag: this.props.data?.raw?.etag,
        calendarId: this.props.data?.calendarId,
        id: this.props.data?.id,
      }),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (!!response[this.props.data?.raw?.uid]) {
      this.props.handleclosefunction(this.props.data);
    } else {
      this.setState({ hasError: Object.values(response) });
    }

    //if (!!response.success) {
    //    this.setState({ isSubmitting: false, hasError: false });
    //} else {
    //    this.setState({ isSubmitting: false, hasError: response});
    //}
  }

  render() {
    const { classes } = this.props;
    let isUser = false;
    return (
      <>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>Löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.calendar.reallyDeleteAppointment}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              Abbrechen
            </Button>
            <Button variant='contained' onClick={this.delete} color='secondary'>
              LÖSCHEN
            </Button>
          </DialogActions>
        </Dialog>

        <DialogTitle disableTypography>
          <Typography variant='h6' noWrap style={{ marginRight: '2em', maxWidth: '100%' }}>
            {this.props.data?.title}
          </Typography>

          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={this.props.handleclosefunction}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <List>
            {!!this.props.data?.body && (
              <ListItem>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    component: 'pre',
                    style: { maxHeight: '200px', overflowY: 'auto' },
                  }}
                  primary={this.props.data?.body}
                  secondary='Beschreibung'
                />
              </ListItem>
            )}

            {!!this.props.data?.start && !!this.props.data?.end && (
              <ListItem>
                <ListItemIcon>
                  <AlarmIcon />
                </ListItemIcon>
                <ListItemText
                  primary={timeString(
                    this.props.data?.isAllday,
                    this.props.data?.start,
                    this.props.data?.end,
                  )}
                  secondary='Termin'
                />
              </ListItem>
            )}

            {!!this.props.data?.raw?.linkedTo && !!this.props.data?.raw?.linkedDescription && (
              <ListItem
                button
                onClick={() => this.props.dispatch(push(this.props.data?.raw?.linkedTo))}
              >
                <ListItemIcon>
                  <LinkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={this.props.data?.raw?.linkedDescription}
                  secondary={'ID# ' + this.props.data?.raw?.linkItem.serial}
                />
              </ListItem>
            )}

            {!!this.props.data?.location && (
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon />
                </ListItemIcon>
                <ListItemText primary={this.props.data?.location} secondary='Ort' />
              </ListItem>
            )}

            {!!this.props.data?.raw?.organizer && !!this.props.data?.raw?.organizer?.uuid ? (
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    this.props.data?.raw?.organizer?.firstname +
                    ' ' +
                    this.props.data?.raw?.organizer?.lastname
                  }
                  secondary='Organisator'
                />
              </ListItem>
            ) : (
              <ListItem>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={this.props.data?.raw?.organizer} secondary='Organisator' />
              </ListItem>
            )}

            {!!this.props.data?.attendees && this.props.data?.attendees.length > 0 && (
              <ListItem>
                <ListItemIcon>
                  <PeopleIcon />
                </ListItemIcon>
                <ListItemText
                  disableTypography
                  primary={
                    <List dense disablePadding>
                      {this.props.data?.attendees.map((attendee, index) => {
                        if (attendee.user?.uuid === this.props.User.uuid) {
                          isUser = attendee.partstat;
                        }
                        switch (attendee.partstat) {
                          case 'ACCEPTED':
                            return (
                              <ListItem
                                key={index}
                                classes={{ root: classes.listitemroot }}
                                className={classes.green}
                              >
                                <ListItemText
                                  inset={false}
                                  disableTypography={true}
                                  primary={
                                    <Typography
                                      display='block'
                                      component='h6'
                                      variant='subtitle1'
                                      style={{ display: 'flex' }}
                                      noWrap
                                    >
                                      {!!attendee.cn
                                        ? attendee.cn + ' / ' + attendee.email
                                        : attendee.email}
                                      <CheckCircleIcon
                                        style={{ marginLeft: 'auto' }}
                                        color='inherit'
                                        fontSize='medium'
                                      />
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            );
                          case 'DECLINED':
                            return (
                              <ListItem
                                key={index}
                                classes={{ root: classes.listitemroot }}
                                className={classes.red}
                              >
                                <ListItemText
                                  inset={false}
                                  disableTypography={true}
                                  primary={
                                    <Typography
                                      display='block'
                                      component='h6'
                                      variant='subtitle1'
                                      style={{ display: 'flex' }}
                                      noWrap
                                    >
                                      {!!attendee.cn
                                        ? attendee.cn + ' / ' + attendee.email
                                        : attendee.email}
                                      <RemoveCircleIcon
                                        style={{ marginLeft: 'auto' }}
                                        color='inherit'
                                        fontSize='medium'
                                      />
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            );
                          default:
                            return (
                              <ListItem
                                key={index}
                                classes={{ root: classes.listitemroot }}
                                className={classes.yellow}
                              >
                                <ListItemText
                                  inset={false}
                                  disableTypography={true}
                                  primary={
                                    <Typography
                                      display='block'
                                      component='h6'
                                      variant='subtitle1'
                                      style={{ display: 'flex' }}
                                      noWrap
                                    >
                                      {!!attendee.cn
                                        ? attendee.cn + ' / ' + attendee.email
                                        : attendee.email}
                                      <HelpIcon
                                        style={{ marginLeft: 'auto' }}
                                        color='inherit'
                                        fontSize='medium'
                                      />
                                    </Typography>
                                  }
                                />
                              </ListItem>
                            );
                        }
                      })}
                    </List>
                  }
                />
              </ListItem>
            )}
          </List>
          {!!isUser ? (
            <>
              <Typography variant='h5'>Meine Teilnahme:</Typography>

              <ButtonGroup fullWidth>
                <Button
                  startIcon={<CheckCircleIcon />}
                  className={isUser === 'ACCEPTED' ? classes.green : classes.outlined}
                  onClick={() => this.setPartstat('ACCEPTED')}
                >
                  {TextDE.calendar.appointmentInformation.participate}
                </Button>
                <Button
                  startIcon={<HelpIcon />}
                  className={isUser === 'TENTATIVE' ? classes.yellow : classes.outlined}
                  onClick={() => this.setPartstat('TENTATIVE')}
                >
                  {TextDE.calendar.appointmentInformation.maybe}
                </Button>
                <Button
                  startIcon={<RemoveCircleIcon />}
                  className={isUser === 'DECLINED' ? classes.red : classes.outlined}
                  onClick={() => this.setPartstat('DECLINED')}
                >
                  {TextDE.calendar.appointmentInformation.cancel}
                </Button>
              </ButtonGroup>
            </>
          ) : null}
        </DialogContent>
        {!!this.state.hasError
          ? this.state.hasError.map((error) => <Alert severity='error'>{error}</Alert>)
          : null}
        {!!this.state.viewOnly ? (
          <DialogActions>
            <Button
              startIcon={<LinkIcon color='inherit' />}
              variant='outlined'
              color='primary'
              onClick={() => this.props.dispatch(push('/kalender/' + this.props.data?.raw?.uri))}
            >
              {TextDE.calendar.toCalendar}
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            {this.state.userIsOrganizer ? (
              <div style={{ marginRight: 'auto' }}>
                <Button
                  startIcon={<DeleteForeverIcon color='inherit' />}
                  onClick={this.deleteDialog}
                  variant='contained'
                  className={classes.red}
                >
                  {TextDE.delete}
                </Button>
                <Button
                  startIcon={<EditIcon color='inherit' />}
                  onClick={this.edit}
                  variant='contained'
                  style={{ marginLeft: '1em' }}
                  className={classes.yellow}
                >
                  {TextDE.change}
                </Button>
              </div>
            ) : null}
            {!!this.props.data?.raw?.linkedTo && (
              <Button
                startIcon={<LinkIcon color='inherit' />}
                variant='outlined'
                color='primary'
                onClick={() => this.props.dispatch(push(this.props.data?.raw?.linkedTo))}
              >
                {TextDE.calendar.appointmentInformation.toEvent}
              </Button>
            )}
          </DialogActions>
        )}
      </>
    );
  }
}

// Meh
const mapStateToProps = (state, ownProps) => ({
  User: state.User,
  Authentication: state.Authentication,
  Data: ownProps.data,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DetailDialog);
