import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Chip from '@material-ui/core/Chip';
import TodayIcon from '@material-ui/icons/Today';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import SmallNote from './SmallNote';
import FastNote from './FastNote';
import TestMail from './TestMail';
import Chat from './Chat';
//TODO import Appointment from './Appointment';

import { Constants } from '../../../../lib/Constants';
import { readableDate, isToday } from '../../../../lib/dateFunctions';

function NoteWrapNew({
  item,
  index,
  style,
  respondEmail,
  respondChat,
  editNote,
  deleteNote,
  smDown,
  isSameDate,
  expandScrollTo,
  inboxItemTypes,
  currentUserCanEdit,
  switchToFile,
}) {
  let body = null;
  //body = (<Card><CardHeader title={item.data.subject} /></Card>)
  let typeId = !!item.type_id.id ? item.type_id.id : item.type_id;

  switch (typeId) {
    case inboxItemTypes.TelegramIcon.id:
      body = (
        <FastNote
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          expandScrollTo={expandScrollTo}
          editFunction={currentUserCanEdit ? editNote : null}
          deleteFunction={currentUserCanEdit ? deleteNote : null}
          type={inboxItemTypes.TelegramIcon}
        />
      );
      break;
    case inboxItemTypes.app.id:
      body = (
        <SmallNote
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          editFunction={null}
          deleteFunction={null}
          expandScrollTo={expandScrollTo}
          type={inboxItemTypes.note}
        />
      );
      break;

    case inboxItemTypes.chatbot.id:
      body = (
        <Chat
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          respondFunction={respondChat}
          expandScrollTo={expandScrollTo}
          type={inboxItemTypes.chatbot}
        />
      );
      break;
    case inboxItemTypes.note.id:
      body = (
        <SmallNote
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          editFunction={currentUserCanEdit ? editNote : null}
          deleteFunction={currentUserCanEdit ? deleteNote : null}
          expandScrollTo={expandScrollTo}
          type={inboxItemTypes.note}
        />
      );
      break;

    case inboxItemTypes.email.id:
      body = (
        <TestMail
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          respondFunction={respondEmail}
          expandScrollTo={expandScrollTo}
          switchToFile={switchToFile}
          type={inboxItemTypes.email}
        />
      );
      break;
    case inboxItemTypes.appointment.id:
      body = (
        <SmallNote
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          editFunction={null}
          deleteFunction={null}
          expandScrollTo={expandScrollTo}
          type={inboxItemTypes.appointment}
        />
      );
      break;
    default:
      body = (
        <SmallNote
          id={item.uuid || null}
          key={'item-body-' + index}
          item={item}
          index={index}
          editFunction={currentUserCanEdit ? editNote : null}
          deleteFunction={currentUserCanEdit ? deleteNote : null}
          expandScrollTo={expandScrollTo}
          type={Constants.getInboxItemType(item.type_id)}
        />
      );
      break;
  }
  return (
    <Grid container spacing={0} style={style} key={'item-' + index}>
      {!!!smDown && (
        <Hidden smDown>
          <Grid item md={2} key={'note-stepper-' + index}>
            <Box
              ml={2}
              display='flex'
              flexGrow={1}
              justifyContent='flex-start'
              alignContent='center'
              height='100%'
              style={{
                height: '100%',
                marginLeft: '80%',
                width: '6px',
                color: 'grey',
                backgroundColor: 'grey',
                boxShadow:
                  '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
              }}
            >
              <div>
                {!isSameDate ? (
                  <Box
                    bgcolor='transparent'
                    style={{ position: 'relative', transform: 'translate(-100%, 20px)' }}
                    height='48px'
                    display='inline-flex'
                  >
                    <Chip
                      icon={<TodayIcon />}
                      deleteIcon={<ArrowRightAltIcon />}
                      label={readableDate(item.created_at)}
                      color={isToday(item.created_at) ? 'secondary' : 'default'}
                      style={{
                        padding: '8px 16px',
                        fontSize: '115%',
                        fontWeight: 'bold',
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        boxShadow:
                          '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
                      }}
                    />
                  </Box>
                ) : null}
              </div>
            </Box>
          </Grid>
        </Hidden>
      )}
      {body}
    </Grid>
  );
}

export default NoteWrapNew;

// OLD AND NEVER IMPLEMENTED
//case inboxItemTypes.phone.id:
//  body = (
//    <Box>
//      <hr />
//      <h3>PHONE</h3>
//      <pre> {JSON.stringify(Object.keys(item))} </pre>
//      <hr />
//      <pre> {JSON.stringify(Object.keys(item.data))} </pre>
//      <hr />
//    </Box>
//  );
//  break;
//case inboxItemTypes.sensors.id:
//  body = (
//    <Box>
//      <hr />
//      <h3>SENSORS</h3>
//      <pre> {JSON.stringify(Object.keys(item))} </pre>
//      <hr />
//      <pre> {JSON.stringify(Object.keys(item.data))} </pre>
//      <hr />
//    </Box>
//  );
//  break;
//case inboxItemTypes.hook.id:
//  body = (
//    <Box>
//      <hr />
//      <h3>HOOK</h3>
//      <pre> {JSON.stringify(Object.keys(item))} </pre>
//      <hr />
//      <pre> {JSON.stringify(Object.keys(item.data))} </pre>
//      <hr />
//    </Box>
//  );
//  break;
