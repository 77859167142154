import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import { DE as TextDE } from '../../lib/Text';

class FooterHint extends React.Component {
  render() {
    return (
      <Grid
        container
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Grid item xs={4}>
          <Button fullWidth variant='text' size='small' color='inherit' href='/privacy'>
            {TextDE.privacy}
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant='text' size='small' color='inherit' href='/agb'>
            {TextDE.agb}
          </Button>
        </Grid>
        <Grid item xs={4}>
          <Button fullWidth variant='text' size='small' color='inherit' href='/impressum'>
            {TextDE.imprint}
          </Button>
        </Grid>
      </Grid>
    );
  }
}

export default FooterHint;
