import { FETCH, RESET, LOADING_TRUE, LOADING_FALSE, SETSESSION } from '../actions/Authentication';

const initialState = {
  access_token: false,
  expires_at: false,
  refresh_token: false,
  token_type: false,
  loading: true,
  session_expires_at: false,
};
//const initialState = false;

const Authentication = (state = initialState, action) => {
  switch (action.type) {
    case FETCH:
      return {
        ...state,
        access_token: action.res.access_token,
        expires_at: Date.now() + action.res.expires_in * 1000,
        refresh_token: action.res.refresh_token,
        token_type: action.res.token_type,
      };
    case SETSESSION:
      return { ...state, session_expires_at: action.res };
    case RESET:
      return initialState;
    case LOADING_TRUE:
      return { ...state, loading: true };
    case LOADING_FALSE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default Authentication;
