import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import ProceduresBarGraph from './graphs/ProceduresBarGraph';
import TagImageList from './graphs/TagImageList';
import UpcomingAppointments from './graphs/UpcomingAppointments';
import ProceduresSimpleView from './graphs/ProceduresSimpleView';
import TimeTrackingAdd from '../Timetracking/TimeTrackingAdd';
import { Grid } from '@material-ui/core';
import { Constants } from '../../lib/Constants';
import { DE as TextDE } from '../../lib/Text';
import AddProcedure from '../Inbox/AddProcedure';
import AddFast from '../Inbox/AddFast';
import AddMail from '../Inbox/AddMail';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import AddIcon from '@material-ui/icons/Add';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import FastForwardIcon from '@material-ui/icons/FastForward';

const styles = (theme) => ({
  sameHeight: {
    height: '100%',
  },
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
});

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuAnchor: null,
      isFetchingAppointments: true,
      isFetchingProcedures: true,
      isFetchingStatistics: true,
      isFetchingTags: true,
      tags: {},
      procedures: {},
      procedureFetchType: 'all',
      procedureCount: 0,
      jobs: {},
      jobCount: 0,
      statisticsGraph: null,
      statisticTicks: null,
      graphsHeight: 0,
      appointments: [],
      procedureDialogOpen: false,
      mailDialogOpen: false,
      fastDialogOpen: false,
      isAdmin: Constants.roles.check(this.props.User, [Constants.roles.ROLES_ADMIN]),
      // CHANGE ME TO NEGATION !Constants.roles....
      isWorker: !Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
        Constants.roles.ROLES_PHONE,
      ]),
      isAdvancedUser: this.props.User.isAdvancedUser,
    };
    this.barGraphRef = React.createRef();

    this.redirectTo = this.redirectTo.bind(this);
    this.fetchAppointments = this.fetchAppointments.bind(this);

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.newItem = this.newItem.bind(this);
    this.newMail = this.newMail.bind(this);
    this.newFast = this.newFast.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleFastDialogClose = this.handleFastDialogClose.bind(this);
    this.handleMailDialogClose = this.handleMailDialogClose.bind(this);
    this.setGraphsHeight = this.setGraphsHeight.bind(this);
    this.toggleProcedureFetchType = this.toggleProcedureFetchType.bind(this);
    //this.fetchWeekTimes = this.fetchWeekTimes.bind(this);
  }

  newItem = () => {
    this.setState({ procedureDialogOpen: true, menuAnchor: null });
  };

  newMail = () => {
    this.setState({ mailDialogOpen: true, menuAnchor: null });
  };
  newFast = () => {
    this.setState({ fastDialogOpen: true, menuAnchor: null });
  };

  handleDialogClose(uuid) {
    if (!!uuid) {
      this.props.dispatch(push('/nachrichten/detail/' + uuid));
    } else {
      this.setState({ procedureDialogOpen: false });
    }
  }

  handleMailDialogClose(uuid) {
    if (!!uuid) {
      this.props.dispatch(push('/nachrichten/detail/' + uuid));
    } else {
      this.setState({ mailDialogOpen: false });
    }
  }

  handleFastDialogClose(uuid) {
    if (!!uuid) {
      this.props.dispatch(push('/nachrichten/detail/' + uuid));
    } else {
      this.setState({ fastDialogOpen: false });
    }
  }

  openMenu = (event) => {
    this.setState({ menuAnchor: event.currentTarget });
  };
  closeMenu = () => {
    this.setState({ menuAnchor: null });
  };

  redirectTo(data) {
    let state =
      !!data[0] && data[0]._view?.label ? data[0]._view?.label : !!data?.text ? data.text : false;
    if (state !== false) {
      this.props.dispatch(push('/nachrichten/eigene?state=' + encodeURI(state)));
    }
  }
  componentWillUnmount() {
    this.setState({
      isFetchingAppointments: true,
      isFetchingProcedures: true,
      isFetchingStatistics: true,
      isFetchingTags: true,
      graphsHeight: false,
      tags: {},
      procedures: {},
      procedureFetchType: 'all',
      procedureCount: 0,
      jobs: {},
      jobCount: 0,
      appointments: [],
    });
  }

  componentDidMount() {
    this.fetchProcedures();
    this.fetchAppointments();
    //this.fetchWeekTimes();
  }

  toggleProcedureFetchType(event, fetchType) {
    if (this.state.procedureFetchType !== fetchType) {
      this.setState(
        { procedureFetchType: fetchType, isFetchingProcedures: true },
        this.fetchProcedures,
      );
    }
  }

  fetchProcedures = async () => {
    let url = new URL(
      process.env.REACT_APP_API_URL +
        '/api/dashboard' +
        (this.state.procedureFetchType !== 'all'
          ? '?worker=true'
          : (!!this.state.isWorker ? '?worker=' + !!this.state.isWorker : '') +
            (!!this.state.isAdmin ? '?admin=' + !!this.state.isAdmin : '')),
    );
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let procedures = { datasets: [], labels: [] };
        let procedureCount = 0;
        let dataset = {
          data: [],
          borderColor: 'rgba(0, 0, 0, 0.8)',
          borderWidth: 2,
          borderAlign: 'inner',
          hoverBackgroundColor: 'rgba(0, 0, 0, 0.8)',
          backgroundColor: [],
        };
        //console.log("[json.procedures]", json.procedures);
        let orderedProcedures = Object.values(json.procedures).sort((a, b) => a.order > b.order);
        //console.log("[orderedProcedures]", orderedProcedures);
        for (var i = 0; i < orderedProcedures.length; i++) {
          let element = orderedProcedures[i];
          if (element.count >= 0) {
            procedureCount = Number(procedureCount) + Number(element.count);
            dataset.data[element.order] = element.count;
            let color = this.props.Theme.palette.info.light;
            try {
              color = this.props.Theme.palette[element.color[0]][element.color[1]];
            } catch (error) {
              // do nothing ...
            }
            dataset.backgroundColor[element.order] = color;
            procedures.labels[element.order] = element.name;
          }
        }
        procedures.datasets.push(dataset);
        //console.log("[procedures]", procedures);

        /*
          let jobs = { datasets: [], labels: [] };
          let jobCount = 0;
          dataset = {
            data: [],
            borderColor: 'rgba(0, 0, 0, 0.8)',
            borderWidth: 2,
            borderAlign: 'inner',
            hoverBackgroundColor: 'rgba(0, 0, 0, 0.8)',
            backgroundColor: [
              this.props.Theme.palette.primary.main,
              this.props.Theme.palette.secondary.light,
              this.props.Theme.palette.warning.light,
              this.props.Theme.palette.info.light,
              this.props.Theme.palette.success.light,
              this.props.Theme.palette.error.light,
            ],
          };
          Object.values(json.jobs).forEach(function (element, index) {
            if (element.count >= 0) {
              jobCount = Number(jobCount) + Number(element.count);
              dataset.data.push(element.count);
              jobs.labels.push(element.name);
            }
          });
          jobs.datasets.push(dataset);
        this.setState(
          {
            tags: json.tags,
            procedures: procedures,
            procedureCount: procedureCount,
            jobs: jobs,
            jobCount: jobCount,
            isFetchingProcedures: false,
          },
        );
        */
        this.setState(
          {
            tags: json.tags,
            procedures: procedures,
            procedureCount: procedureCount,
            isFetchingProcedures: false,
          },
          () => this.setGraphsHeight(),
        );
      })
      .catch((error) => {
        //alert("Fetching of Procedures/tags failed");
        console.log('Fetching  Procedures/tags failed', error);
        return false;
      });
  };

  setGraphsHeight() {
    this.setState({
      graphsHeight: this.barGraphRef.current.firstChild.clientHeight,
    });
  }

  fetchAppointments = async () => {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/calendar/upcoming');
    fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({
          appointments: json,
          isFetchingAppointments: false,
        });
      })
      .catch((error) => {
        //alert("Fetching appointments failed");
        console.log('Fetching appointments failed', error);
        return false;
      });
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog
          fullScreen={false}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleDialogClose(false);
            }
          }}
          open={this.state.procedureDialogOpen}
        >
          <AddProcedure
            dialogData={{}}
            handleclosefunction={(uuid) => this.handleDialogClose(uuid)}
          />
        </Dialog>

        <Dialog
          fullScreen={false}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleFastDialogClose(false);
            }
          }}
          open={this.state.fastDialogOpen}
        >
          <AddFast handleclosefunction={(uuid) => this.handleFastDialogClose(uuid)} />
        </Dialog>

        <Dialog
          fullScreen={false}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleMailDialogClose(false);
            }
          }}
          open={this.state.mailDialogOpen}
        >
          <AddMail handleclosefunction={(uuid) => this.handleMailDialogClose(uuid)} />
        </Dialog>

        <div
          style={{
            margin:
              this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md
                ? '12px 8px 8px 8px'
                : '4px',
            maxHeight:
              this.props.Dimensions.height -
              this.props.Dimensions.appBarHeight -
              (this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md ? 20 : 8),
            minHeight:
              this.props.Dimensions.height -
              this.props.Dimensions.appBarHeight -
              (this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md ? 20 : 8),
            maxWidth: this.props.Dimensions.maxWidth,
            overflow:
              this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md
                ? 'unset'
                : 'auto',
            backgroundColor: 'inherit',
          }}
        >
          <Grid
            container
            spacing={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='stretch'
            alignContent='flex-start'
          >
            <Grid ref={this.barGraphRef} className={classes.sameHeight} item xs={12} md={6}>
              {!!!this.state.isWorker && (
                <ProceduresBarGraph
                  toggleProcedureFetchType={this.toggleProcedureFetchType}
                  currentFetchType={this.state.procedureFetchType}
                  count={this.state.procedureCount}
                  data={this.state.procedures}
                  isFetching={!!this.state.isFetchingProcedures}
                  maxHeight={this.state.graphsHeight}
                />
              )}

              {!!this.state.isWorker && (
                <ProceduresSimpleView
                  toggleProcedureFetchType={this.toggleProcedureFetchType}
                  currentFetchType={this.state.procedureFetchType}
                  count={this.state.procedureCount}
                  data={this.state.procedures}
                  isFetching={!!this.state.isFetchingProcedures}
                />
              )}
            </Grid>

            <Grid item xs={12} md={6} lg={6}>
              <UpcomingAppointments
                data={this.state.appointments}
                isFetching={
                  !!this.state.isFetchingProcedures &&
                  !!this.state.isFetchingAppointments &&
                  this.state.graphsHeight !== false
                }
                maxHeight={this.state.graphsHeight}
              />
            </Grid>
          </Grid>
          {!!!this.state.isWorker && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TagImageList
                  currentFetchType={this.state.procedureFetchType}
                  data={this.state.tags}
                  isFetching={!!this.state.isFetchingTags}
                />
              </Grid>
            </Grid>
          )}
        </div>
        <Fab onClick={this.openMenu} className={classes.fab} color='primary' aria-label='edit'>
          <AddIcon />
        </Fab>

        <Menu
          elevation={8}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          id='customized-menu'
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={Boolean(this.state.menuAnchor)}
          onClose={this.closeMenu}
          classes={{ paper: classes.menu }}
        >
          <MenuItem onClick={this.newItem}>
            <ListItemIcon>
              <VerticalSplitIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.procedure.addProcedureLabel} />
          </MenuItem>
          <MenuItem onClick={this.newMail}>
            <ListItemIcon>
              <DraftsIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.procedure.addProcedureBySendingMailLabel} />
          </MenuItem>
          <MenuItem onClick={this.newFast}>
            <ListItemIcon>
              <FastForwardIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.procedure.addFastInboxLabel} />
          </MenuItem>
          <TimeTrackingAdd
            asMenuItem={true}
            callback={(reload) => {
              this.closeMenu();
            }}
          />
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Dashboard);
