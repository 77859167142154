import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import SettingsIcon from '@material-ui/icons/Settings';
import Clock from './Clock';
import Divider from '@material-ui/core/Divider';
import { NavLink } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HomeIcon from '@material-ui/icons/Home';
import InboxIcon from '@material-ui/icons/Inbox';
import MarkunreadIcon from '@material-ui/icons/Markunread';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import ContactsIcon from '@material-ui/icons/Contacts';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BallotIcon from '@material-ui/icons/Ballot';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import WorkIcon from '@material-ui/icons/Work';
import PaymentIcon from '@material-ui/icons/Payment';
import EuroIcon from '@material-ui/icons/Euro';
import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ErrorIcon from '@material-ui/icons/Error';
import ListAltIcon from '@material-ui/icons/ListAlt';
import TodayIcon from '@material-ui/icons/Today';
import TimerIcon from '@material-ui/icons/Timer';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import BarChartIcon from '@material-ui/icons/BarChart';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { collapse as navMenuCollapse, removeCustomMenuPoint } from '../../actions/NavMenu';

import { Constants } from '../../lib/Constants';

import SupportRequest from '../Other/SupportRequest';

// Start
const styles = (theme) => ({
  root: {
    paddingTop: '0px',
    display: 'flex',
    flexDirection: 'column',
    '& > :last-child': {
      marginTop: 'auto',
    },
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconInherit: {
    color: 'inherit',
    backgroundColor: 'inherit',
    minWidth: 'unset',
  },
  inherit: {
    padding: theme.spacing(1, 2),
    textDecoration: 'inherit',
    color: 'inherit',
    justifyContent: 'center',
    width: '100%',
    minWidth: '1px',
    backgroundColor: 'inherit',
    '&:hover': {
      textDecoration: 'inherit',
      color: 'inherit',
      backgroundColor: 'inherit',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  linkActive: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    textDecoration: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  bottomButtons: {
    position: 'relative',
    bottom: '1em',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 0,
    ...theme.mixins.toolbar,
  },
  collapsed: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  wide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
    textAlign: 'center',
  },
  none: {
    display: 'none',
  },
  navlink: {
    textDecoration: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    '& > span': {
      [theme.breakpoints.down('sm')]: {
        fontSize: '120%',
      },
      fontSize: '140%',
    },
  },
  noPad: {
    padding: theme.spacing(0),
    overflowY: 'auto',
  },
  menuTitle: {
    textAlign: 'start',
    borderTop: '1px solid ' + theme.palette.divider,
    [theme.breakpoints.down('sm')]: {
      fontSize: '80%',
      textAlign: 'center',
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  menuTitleSmall: {
    fontSize: '80%',
    textAlign: 'center',
    padding: theme.spacing(1, 0),
  },
});

function renderComponent(component) {
  switch (component) {
    case 'HomeIcon':
      return <HomeIcon color='inherit' />;
    case 'InboxIcon':
      return <InboxIcon color='inherit' />;
    case 'MarkunreadIcon':
      return <MarkunreadIcon color='inherit' />;
    case 'FiberNewIcon':
      return <FiberNewIcon color='inherit' />;
    case 'PriorityHighIcon':
      return <PriorityHighIcon color='inherit' />;
    case 'ContactsIcon':
      return <ContactsIcon color='inherit' />;
    case 'AllInclusiveIcon':
      return <AllInclusiveIcon color='inherit' />;
    case 'AssignmentIcon':
      return <AssignmentIcon color='inherit' />;
    case 'BallotIcon':
      return <BallotIcon color='inherit' />;
    case 'AccountTreeIcon':
      return <AccountTreeIcon color='inherit' />;
    case 'AlternateEmailIcon':
      return <AlternateEmailIcon color='inherit' />;
    case 'WorkIcon':
      return <WorkIcon color='inherit' />;
    case 'DeleteIcon':
      return <DeleteIcon color='inherit' />;
    case 'PaymentIcon':
      return <PaymentIcon color='inherit' />;
    case 'EuroIcon':
      return <EuroIcon color='inherit' />;
    case 'ArchiveIcon':
      return <ArchiveIcon color='inherit' />;
    case 'NotInterestedIcon':
      return <NotInterestedIcon color='inherit' />;
    case 'ErrorIcon':
      return <ErrorIcon color='inherit' />;
    case 'ListAltIcon':
      return <ListAltIcon color='inherit' />;
    case 'TodayIcon':
      return <TodayIcon color='inherit' />;
    case 'BarChartIcon':
      return <BarChartIcon color='inherit' />;
    case 'TimerIcon':
      return <TimerIcon color='inherit' />;
    case 'HourglassFullIcon':
      return <HourglassFullIcon color='inherit' />;
    default:
      return <HelpOutlineIcon color='inherit' />;
  }
}

function RenderListItem(data) {
  const { collapsed, parent, subentry, classes, entry, dispatchFunction, remove, hidden } = data;
  if (hidden === true) {
    return null;
  }
  if (typeof entry.submenu !== 'undefined') {
    return (
      <>
        {!!collapsed && (
          <ListItem
            className={clsx(classes.inherit, {
              [classes.nested]: !!subentry && !!collapsed,
            })}
          >
            <ListItemIcon className={classes.iconInherit}>
              {renderComponent(entry.icon)}
            </ListItemIcon>

            <ListItemText className={classes.paddingLeft} primary={entry.name} />
          </ListItem>
        )}
        <Collapse in={!entry.collapsed || !!collapsed} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {entry.submenu.map((subentry, index) => {
              return (
                <RenderListItem
                  dispatchFunction={dispatchFunction}
                  parent={entry.key}
                  remove={remove}
                  key={'list-item-sub-' + index}
                  collapsed={collapsed}
                  subentry={true}
                  classes={classes}
                  entry={subentry}
                />
              );
            })}
          </List>
        </Collapse>
      </>
    );
  } else {
    if (!!entry.removable) {
      return (
        <NavLink
          style={{ width: '100%', display: 'flex' }}
          className={classes.link}
          activeClassName={classes.linkActive}
          to={entry.path}
          exact
          key={entry.name}
          onClick={dispatchFunction}
        >
          <ListItem
            component='div'
            className={clsx(classes.inherit, {
              [classes.nested]: !!subentry && !!collapsed,
            })}
            style={{ width: collapsed ? '80%' : '100%' }}
          >
            <ListItemIcon className={classes.iconInherit}>
              {renderComponent(entry.icon)}
            </ListItemIcon>
            {collapsed && (
              <ListItemText
                primaryTypographyProps={{ noWrap: true }}
                className={classes.paddingLeft}
                primary={entry.name}
              />
            )}
          </ListItem>
          {collapsed && (
            <IconButton
              disableRipple
              color='secondary'
              edge='end'
              size='small'
              style={{ width: '20%' }}
              onClick={() => remove({ ...entry, parent: parent })}
            >
              <DeleteIcon />
            </IconButton>
          )}
        </NavLink>
      );
    }
    return (
      <NavLink
        className={classes.link}
        activeClassName={classes.linkActive}
        to={entry.path}
        exact
        key={entry.name}
        onClick={dispatchFunction}
      >
        <ListItem
          component='div'
          className={clsx(classes.inherit, {
            [classes.nested]: !!subentry && !!collapsed,
          })}
        >
          <ListItemIcon className={classes.iconInherit}>{renderComponent(entry.icon)}</ListItemIcon>
          {collapsed && <ListItemText className={classes.paddingLeft} primary={entry.name} />}
        </ListItem>
      </NavLink>
    );
  }
}

function RenderedList(data) {
  const { isAdvancedUser, isworker, navmenu, classes, dispatchFunction, remove } = data;

  return (
    <List className={classes.noPad} dense component='nav'>
      {navmenu.menu.map((entry, index) => {
        // true = the user is configured as a new user, he did NOT activate the extended displaying
        var hidden = isAdvancedUser
          ? skipIfExtended.includes(entry.key)
          : skipIfBeginner.includes(entry.key);
        if (!!!hidden) {
          hidden = isworker ? skipIfWorker.includes(entry.key) : false;
        }

        return (
          <RenderListItem
            hidden={hidden}
            dispatchFunction={dispatchFunction}
            remove={remove}
            key={'list-item-' + index}
            subentry={false}
            classes={classes}
            collapsed={navmenu.collapsed}
            entry={entry}
          />
        );
      })}
    </List>
  );
}

//meeh
const skipIfWorker = ['inbox', 'statistics'];
const skipIfExtended = ['tasks'];
const skipIfBeginner = ['inbox', 'todos'];

// The List
class NavigationList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logo: this.props.Logo,
      isWorker: !Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
        Constants.roles.ROLES_PHONE,
      ]),
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <div
          id='drawerRoot'
          className={classes.toolbar}
          onClick={this.props.toggleMenu}
          style={{
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage:
              !!this.props.Logo && !!this.props.Logo.dataUrl
                ? 'url(' +
                  this.props.Logo.dataUrl +
                  ')' /*? "url(data:" + this.props.Logo.mime + ';base64,' + this.props.Logo.img +")"*/
                : 'url(/assets/logo_default_fitted_small.png)',
          }}
        ></div>
        <div
          style={{
            height: this.props.dimensions.height - this.props.dimensions.appBarHeight,
            width: this.props.NavMenu.collapsed
              ? this.props.dimensions.drawerWidthOpen
              : this.props.dimensions.drawerWidthClosed,
          }}
          className={classes.root}
        >
          <Divider />

          <Clock isCollapsed={this.props.NavMenu.collapsed} />

          <Typography
            variant='h6'
            className={clsx(classes.menuTitle, {
              [classes.menuTitleSmall]: !this.props.NavMenu.collapsed,
            })}
          >
            Menu
          </Typography>

          {this.props.dimensions.width < this.props.Theme.breakpoints.values.sm ? (
            <RenderedList
              isAdvancedUser={!!this.props.User.isAdvancedUser}
              isworker={this.state.isWorker}
              navmenu={this.props.NavMenu}
              classes={classes}
              remove={(entry) => this.props.dispatch(removeCustomMenuPoint(entry))}
              dispatchFunction={() => this.props.dispatch(navMenuCollapse())}
            />
          ) : (
            <RenderedList
              isAdvancedUser={!!this.props.User.isAdvancedUser}
              isworker={this.state.isWorker}
              navmenu={this.props.NavMenu}
              classes={classes}
              remove={(entry) => this.props.dispatch(removeCustomMenuPoint(entry))}
              dispatchFunction={() => null}
            />
          )}

          <div>
            <SupportRequest collapsed={this.props.NavMenu.collapsed} />

            <NavLink
              className={classes.link}
              activeClassName={classes.linkActive}
              to={'/settings'}
              exact
            >
              <ListItem component='div' className={classes.inherit}>
                <ListItemIcon className={classes.iconInherit}>
                  <SettingsIcon />
                </ListItemIcon>
                {this.props.NavMenu.collapsed && (
                  <ListItemText className={classes.paddingLeft} primary={'Einstellungen'} />
                )}
              </ListItem>
            </NavLink>

            {!!this.props.User.nickname ? (
              <Typography
                variant='h6'
                gutterBottom={true}
                className={!this.props.NavMenu.collapsed ? classes.none : classes.wide}
              >
                "{this.props.User.nickname}"
              </Typography>
            ) : (
              <Typography
                variant='h6'
                gutterBottom={true}
                className={!this.props.NavMenu.collapsed ? classes.none : classes.wide}
              >
                {`${this.props.User.firstname} ${this.props.User.lastname}`}
              </Typography>
            )}

            {!!this.props.User.customer.company?.name && (
              <Typography
                variant='button'
                gutterBottom={true}
                className={!this.props.NavMenu.collapsed ? classes.none : classes.wide}
              >
                {this.props.User.customer.company?.name}
              </Typography>
            )}
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Logo: state.Style.Logo,
  User: state.User,
  Theme: state.Style.Theme,
  Authentication: state.Authentication,
  NavMenu: state.NavMenu,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NavigationList);
