import React from 'react';
//import { push } from 'connected-react-router'
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
//import Input from '@material-ui/core/Input';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
//import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Constants } from '../../../lib/Constants';
import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  growHeight: {
    height: '100%',
  },
  root: {
    width: 'calc(100% - ' + theme.spacing(2) + ')',
    display: 'flex',
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  grow: {
    display: 'flex',
    padding: theme.spacing(0),
    flexGrow: '1',
  },
  fullWidth: {
    width: '100%',
  },
  shrink: {
    display: 'flex',
    padding: theme.spacing(0, 1),
    flexShrink: '1',
    minWidth: '20%',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  results: {
    border: '0px solid ' + theme.palette.text.primary,
    borderRadius: '4px',
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    backgroundColor: '#ffffff',
    position: 'absolute',
    maxHeight: '300px',
    maxWidth: '400px',
    overflow: 'auto',
    zIndex: 1,
    boxShadow: theme.shadows[2],
  },
});

class AddValue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      isSubmitting: false,
      hasError: false,
      uuid: this.props.contact.uuid,
      id: this.props.contact.id,
      type: this.props.type,
      companys: false,
      value: this.props.edit || '',
      salutationList: [],
      titleList: [],
      title: this.props.contact.title || '',
      firstname: this.props.contact.firstname || '',
      name: this.props.contact.name || '',
      salutation_id: this.props.contact.salutation_id || '',
      tags: [],
      work: false,
      pref: false,
      cell: false,
      fax: false,
      contactsTags: Constants.tags.contacts.all(),
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.selectCompany = this.selectCompany.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAddressSelect = this.handleAddressSelect.bind(this);
    this.decodeAddress = this.decodeAddress.bind(this);
    this.handleNumbersChange = this.handleNumbersChange.bind(this);
    this.handleEmailsChange = this.handleEmailsChange.bind(this);
    this.handleWebpagesChange = this.handleWebpagesChange.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
  }

  async handleFormSubmit(event) {
    event.preventDefault();
    //console.log(this.props.contact, this.props.type, this.state);

    let formData = {};

    formData['uuid'] = this.state.uuid;
    formData['id'] = this.state.id;
    formData['_method'] = 'PUT';
    //NEEDED!
    formData['firstname'] = this.props.contact.firstname;
    formData['name'] = this.props.contact.name;
    formData['salutation_id'] = this.props.contact.salutation_id;

    this.setState({ isSubmitting: true });
    switch (this.props.type) {
      case 'name':
        formData['firstname'] = this.state.firstname;
        formData['name'] = this.state.name;
        formData['title'] = this.state.title;
        formData['salutation_id'] = this.state.salutation_id;
        break;
      case 'tags':
        formData['tags'] = [];
        this.state.tags.forEach((value) => {
          formData['tags'].push(value?.name?.de || value);
        });
        break;
      case 'notes':
        //'notes' => 'nullable|string',
        formData['notes'] = this.state.value;
        break;
      case 'address':
        // Addressobject is set
        //'address' => 'sometimes|array',
        //'address.street_number' => 'sometimes|required_with:address',
        //'address.route' => 'sometimes|required_with:address',
        //'address.locality' => 'sometimes|required_with:address',
        //'address.administrative_area_level_1' => 'sometimes|required_with:address',
        //'address.country' => 'sometimes|required_with:address',
        //'address.postal_code' => 'sometimes|required_with:address',
        formData['address'] = this.state.addressObject;
        break;
      case 'company':
        // company - if string ==> New Company, if array, existing
        //'company' => ($request->has('company') and is_array($request->company)) ? 'nullable|array' : 'nullable|string',

        // Check if company exists
        let companyValue = this.state.value;
        if (companyValue.toString().trim().length > 0) {
          if (!!!companyValue?.id && !!!companyValue?.uuid) {
            // find company
            let companyFound = this.state.companys.find((filter) => {
              return (
                filter.name.toString().trim().toLowerCase() ===
                companyValue.toString().trim().toLowerCase()
              );
            });
            if (!!!companyFound) {
              companyValue = companyValue.toString().trim();
            } else if (!!companyFound) {
              companyValue = {
                id: companyFound.id,
                uuid: companyFound.uuid,
                name: companyFound.name,
              };
            }
          }
        }

        formData['company'] = companyValue;
        break;
      case 'numbers':
        formData['phones'] = [
          {
            number: this.state.value,
            work: this.state.work,
            pref: this.state.pref,
            fax: this.state.fax,
            cell: this.state.cell,
          },
        ];
        //phones
        //'phones.*.number' => 'sometimes|regex:/(\(?([\d \-\)\–\+\/\(]+){6,}\)?([ .\-–\/]?)([\d]+))/',
        //'phones.*.work' => 'sometimes|boolean',
        //'phones.*.pref' => 'sometimes|boolean',
        //'phones.*.fax' => 'sometimes|boolean',
        //'phones.*.cell' => 'sometimes|boolean',
        break;
      case 'mails':
        formData['emails'] = [
          {
            email: this.state.value,
            work: this.state.work,
            pref: this.state.pref,
          },
        ];
        //emails
        //'emails.*.email' => 'sometimes|email',
        //'emails.*.work' => 'sometimes|boolean',
        //'emails.*.pref' => 'sometimes|boolean',
        break;
      case 'webpages':
        formData['webpages'] = [
          {
            webpage: this.state.value,
          },
        ];
        //'webpages.*.webpage' => 'sometimes|url',
        break;

      default:
        return false;
    }

    let endpoint = process.env.REACT_APP_API_URL + '/api/contacts/' + formData.uuid;
    //console.log(formData, endpoint);

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!!response) {
          this.setState({ isSubmitting: false });
          this.props.callback(this.props.type);
        }
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
    return response;
  }

  componentDidMount() {
    if (this.props.type === 'company') {
      this.getCompanys();
    } else if (this.props.type === 'name') {
      if (this.props.contact.salutation_id === 4) {
        this.setState({
          isFetching: false,
          salutationList: [{ id: '4', salutation: TextDE.contact.contactForm.company }],
          titleList: ['Dr.'],
        });
      } else {
        this.setState({
          isFetching: false,
          salutationList: [
            { id: '1', salutation: TextDE.contact.contactForm.salutation_male },
            { id: '2', salutation: TextDE.contact.contactForm.salutation_female },
            { id: '3', salutation: TextDE.contact.contactForm.salutation_other },
          ],
          titleList: ['Dr.'],
        });
      }
    } else if (this.props.type === 'tags') {
      let tags = [];
      this.props.contact.tags.forEach((value) => {
        tags.push(value?.name?.de || value);
      });
      this.setState({ isFetching: false, tags: tags });
    } else {
      this.setState({ isFetching: false });
    }
  }

  async getCompanys() {
    fetch(process.env.REACT_APP_API_URL + '/api/contacts/companys', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ isFetching: false, companys: json });
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }

  selectCompany = (event, value) => {
    //console.log(`[SELECT COMPANY] triggered:, `, event, value);
    if (!!value) {
      if (!!value.id) {
        this.setState({
          ...this.state,
          value: { id: value.id, uuid: value.uuid, name: value.name },
        });
      } else if (typeof value === 'string') {
        this.setState({ ...this.state, value: value });
      } else if (value && value.inputValue) {
        // Create a new value from the user input
        this.setState({ ...this.state, value: value.inputValue });
      } else {
        this.setState({ ...this.state, value: value });
      }
    }
  };

  handleTitleChange = (event, value) => {
    //console.log(event, value);
    if (value !== null && value.trim() !== '') {
      this.setState({ ...this.state, title: value });
      return;
    }
  };

  handleChange = (event) => {
    if (event === null) {
      // Meh?
      console.error('This should not happen. [AddValue.js]');
    } else if (typeof event === 'string') {
      //console.log(event);
      this.setState({ ...this.state, value: event });
    } else if (event?.target?.type === 'checkbox') {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`)
      this.setState({
        ...this.state,
        [event.target.name]: event.target.checked,
      });
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`)
      this.setState({ ...this.state, [event.target.name]: event.target.value });
    }
  };

  handleTagsChange = (event, value) => {
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      this.setState({ tags: value });
    }
  };

  handleNumbersChange = (event) => {
    if (event.target.type === 'checkbox') {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.checked,
      });
    } else {
      let number = event.target.value;
      number = number.trim();
      number = number.replace(
        //eslint-disable-next-line
        /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
        '',
      );
      this.setState({
        ...this.state,
        value: number,
        invalid:
          event.target.name === 'number'
            ? !!!number.match(
                new RegExp(
                  //eslint-disable-next-line
                  /^(\(?([\d \-\)\–\+\/\(]?){2,}\)?([ .\-–\/]?)([\d]+))$/i,
                ),
              )
            : false,
      });
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, this.state);
    }
  };
  handleEmailsChange = (event) => {
    if (event.target.type === 'checkbox') {
      this.setState({
        ...this.state,
        [event.target.name]: event.target.checked,
      });
    } else {
      this.setState({
        ...this.state,
        value: event.target.value,
        invalid:
          event.target.name === 'email'
            ? !!!event.target.value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
            : false,
      });
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, this.state);
    }
  };
  handleWebpagesChange = (event) => {
    this.setState({
      ...this.state,
      value: event.target.value,
      invalid: !!!event.target.value.match(
        /^(https?:\/\/)(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i,
      ),
    });
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };

  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    let hasError = [];
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        //componentForm[addressType] = val;
        //console.log(addressType, val);
        //setCompanyState({...companyState, [addressType]: val});
        newAddress[addressType] = val;
      }
    }
    Object.keys(newAddress).forEach((key) => {
      if (newAddress[key] === '') {
        hasError.push(key);
      }
    });

    this.setState({
      ...this.state,
      addressObject: newAddress,
      value: address,
      invalid: hasError.length > 0 ? true : false,
    });
  };

  render() {
    const { classes } = this.props;

    if (this.state.isFetching) {
      return <CircularProgress />;
    }

    switch (this.props.type) {
      case 'name':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <FormControl variant='outlined' fullWidth className={classes.formControl}>
                    <InputLabel id='salutation-input-label' shrink={true}>
                      Anrede
                    </InputLabel>
                    <Select
                      labelId='salutation-input-label'
                      id='salutation-input'
                      name='salutation_id'
                      value={this.state.salutation_id}
                      onChange={this.handleChange}
                    >
                      {this.state.salutationList.map((value, key) => (
                        <MenuItem key={value.id} value={value.id}>
                          {value.salutation}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Autocomplete
                    freeSolo
                    fullWidth
                    name='title'
                    options={['Dr.']}
                    getOptionLabel={(option) => option.name || option}
                    defaultValue={null}
                    value={this.state.title}
                    disabled={this.state.salutation_id === 4}
                    onChange={this.handleTitleChange}
                    onInputChange={this.handleChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        name='title'
                        label={TextDE.form.title.label}
                        placeholder={TextDE.form.title.placeholder}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='firstname'
                    name='firstname'
                    label={TextDE.form.firstname.label}
                    type='text'
                    onChange={this.handleChange}
                    value={this.state.firstname}
                    disabled={this.state.salutation_id === 4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    required
                    id='name'
                    name='name'
                    label={TextDE.form.lastname.label}
                    type='text'
                    onChange={this.handleChange}
                    value={this.state.name}
                  />
                </Grid>
              </Grid>

              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );

      case 'tags':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <Autocomplete
                className={classes.grow}
                multiple
                freeSolo
                name='tags'
                options={this.state.contactsTags}
                getOptionLabel={(option) => option?.name?.de || option}
                defaultValue={null}
                value={this.state.tags}
                onChange={this.handleTagsChange}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={TextDE.contact.contactForm.contactTag}
                    placeholder={TextDE.contact.contactForm.tagPlaceholder}
                    fullWidth
                  />
                )}
              />
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );

      case 'notes':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <TextField
                variant='outlined'
                className={classes.grow}
                label={TextDE.contact.contactForm.notes}
                multiline
                minRows='4'
                name='value'
                onChange={this.handleChange}
                value={this.state.value}
              />
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );

      case 'address':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <div className={classes.grow}>
                <PlacesAutocomplete
                  variant='outlined'
                  value={this.state.value}
                  name='value'
                  onChange={this.handleChange}
                  onSelect={this.handleAddressSelect}
                  searchOptions={{ types: ['address'] }}
                  shouldFetchSuggestions={this.state.value.length > 4}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={classes.fullWidth}>
                      <TextField
                        variant='outlined'
                        name='value'
                        autoComplete='false'
                        label={TextDE.contact.contactForm.adress}
                        fullWidth
                        {...getInputProps({
                          placeholder: TextDE.contact.contactForm.adress,
                          className: 'location-search-input',
                        })}
                        error={this.state.invalid}
                        helperText={TextDE.contact.contactForm.adressHelperText}
                      />

                      {suggestions.length > 0 ? (
                        <div className={classes.results}>
                          <List dense={true} disablePadding={true}>
                            {suggestions.map((suggestion, index) => {
                              return (
                                <ListItem
                                  key={index}
                                  button
                                  {...getSuggestionItemProps(suggestion, {})}
                                >
                                  <ListItemIcon>
                                    <LocationOnIcon />
                                  </ListItemIcon>
                                  <ListItemText
                                    primary={suggestion.formattedSuggestion.mainText}
                                    secondary={suggestion.formattedSuggestion.secondaryText}
                                  />
                                </ListItem>
                              );
                            })}
                          </List>
                        </div>
                      ) : null}
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );

      case 'company':
        const filter = createFilterOptions({
          matchFrom: 'start',
          stringify: (option) => option.name,
        });
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <Autocomplete
                className={classes.grow}
                freeSolo
                autoHighlight={true}
                autoSelect={false}
                options={this.state.companys || []}
                filterOptions={(options, params) => {
                  let filtered = [];
                  if (!!options) {
                    filtered = filter(options, params);
                  }

                  // Suggest the creation of a new value
                  if (params.inputValue !== '') {
                    //console.log(`Will add The Company: ${params.inputValue}`);
                    filtered.unshift(params.inputValue);
                  }
                  return filtered;
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.name ?? option;
                }}
                renderOption={(option) => {
                  if (typeof option === 'string') {
                    return <Typography noWrap>"{option}" als neue Firma hinzufügen</Typography>;
                  }
                  return <Typography noWrap>{option.name}</Typography>;
                }}
                defaultValue={null}
                value={this.state.value}
                name='value'
                onChange={this.selectCompany}
                onClose={(data, eventType, tmp) => {
                  //console.log("Company onClose called", data, eventType, tmp);
                  if (eventType !== 'blur') {
                    //console.log("no blur - return");
                    return;
                  }
                  if (this.state.value.toString().trim().length > 0) {
                    //console.log("we have a value, but does this.state.value equal data.target.value", this.state.value, data.target.value);
                    if (
                      this.state.value &&
                      !!this.state.value?.name &&
                      this.state.value.name.toString().trim().toLowerCase() !==
                        data.target.value.toString().trim().toLowerCase()
                    ) {
                      this.setState({ value: data.target.value.toString().trim() });
                    }
                  } else if (data.target.value.toString().trim().length > 0) {
                    //console.log("no value selected - get current input", data.target.value.toString().trim());
                    this.setState({ value: data.target.value.toString().trim() });
                  }
                }}
                includeInputInList={true}
                renderInput={(params) => (
                  <TextField
                    variant='outlined'
                    {...params}
                    label={TextDE.contact.contactForm.company}
                    placeholder={TextDE.contact.contactForm.companyName}
                    fullWidth
                    name='value'
                  />
                )}
              />
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );
      case 'numbers':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <Grid className={classes.grow} container spacing={0}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    name='number'
                    label={TextDE.contact.contactForm.number}
                    placeholder='0761 89750180'
                    type='tel'
                    onChange={this.handleNumbersChange}
                    value={this.state.value}
                    error={this.state.invalid}
                    helperText={TextDE.contact.contactForm.numberHelperText}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.pref || false}
                        onChange={this.handleNumbersChange}
                        value='pref'
                        name='pref'
                      />
                    }
                    label='Favorit'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.cell || false}
                        onChange={this.handleNumbersChange}
                        value='cell'
                        name='cell'
                      />
                    }
                    label={TextDE.contact.contactForm.numberMobile}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.work || false}
                        onChange={this.handleNumbersChange}
                        value='work'
                        name='work'
                      />
                    }
                    label='Arbeit'
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.fax || false}
                        onChange={this.handleNumbersChange}
                        value='fax'
                        name='fax'
                      />
                    }
                    label={TextDE.contact.contactForm.numberFax}
                  />
                </Grid>
              </Grid>
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );
      case 'mails':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <Grid className={classes.grow} container spacing={0}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    name='value'
                    label={TextDE.contact.contactForm.email}
                    placeholder='max@muster.de'
                    type='email'
                    onChange={this.handleEmailsChange}
                    value={this.state.value}
                    helperText={TextDE.contact.contactForm.emailHelperText}
                    error={this.state.invalid}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.statepref}
                        onChange={this.handleEmailsChange}
                        value='pref'
                        name='pref'
                      />
                    }
                    label={TextDE.contact.contactForm.labelFavorite}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.work}
                        onChange={this.handleEmailsChange}
                        value='work'
                        name='work'
                      />
                    }
                    label={TextDE.contact.contactForm.labelWork}
                  />
                </Grid>
              </Grid>
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );

      case 'webpages':
        return (
          <>
            <form key='contact' onSubmit={this.handleFormSubmit} className={classes.root}>
              <Grid className={classes.grow} container spacing={0}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='status_id'
                    name='webpage'
                    label='Webseite'
                    placeholder='https://flixworker.de'
                    type='url'
                    onChange={this.handleWebpagesChange}
                    value={this.state.value}
                    error={this.state.invalid}
                    helperText={
                      this.state.invalid
                        ? TextDE.contact.contactForm.websiteWrongFormat
                        : TextDE.contact.contactForm.websiteHelperText
                    }
                  />
                </Grid>
              </Grid>
              <div className={classes.shrink}>
                {this.state.isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    fullWidth
                    type='submit'
                    className={classes.growHeight}
                    disabled={!!this.state.invalid}
                    variant='contained'
                  >
                    Speichern
                  </Button>
                )}
              </div>
            </form>
          </>
        );
      default:
        return <p> Error </p>;
    }
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
export default compose(withStyles(styles), connect(mapStateToProps))(AddValue);
