import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';

import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  colorize: {
    flexGrow: '1',
    display: 'grid',
    marginRight: theme.spacing(1),
    color: theme.palette.primary.contrastText + '!important',
    backgroundColor: theme.palette.primary.dark + '!important',
    borderRadius: '4px',
    padding: theme.spacing(0, 1) + '!important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 3) + '!important',
    },
    [theme.breakpoints.down('md')]: {
      //display: 'flex',
      //alignItems: 'center',
    },
    '&>h5': {
      overflow: 'hidden',
      wordWrap: 'anywhere',
      whiteSpace: 'nowrap',
      maxWidth: 'inherit',
    },
  },
  input: {
    flexGrow: '1',
    marginRight: theme.spacing(1),

    padding: theme.spacing(0, 1) + '!important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 3) + '!important',
    },
  },

  wrapper: {
    width: '100%',
    display: 'flex',
  },
  wrapperEdit: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  buttonGroupEdit: {
    [theme.breakpoints.up('md')]: {
      flexShrink: '1',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  inlineFlex: {
    display: 'inline-flex',
    color: 'inherit',
    backgroundColor: 'inherit',
    borderColor: theme.palette.text.primary,
    borderStyle: 'solid',
    borderWidth: '2px',
    '&>svg': {
      width: '85%',
      height: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
  },
  textField: {
    '&>div': {
      fontSize: theme.typography.h4.fontSize + '!important',
    },
  },
  textFieldSmall: {
    '&>div': {
      fontSize: theme.typography.h6.fontSize + '!important',
    },
  },
  backButton: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(9),
      marginLeft: theme.spacing(1),
    },
  },
});

class Title extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      edit: false,
      subject: this.props.subject ?? 'Fülle mich!',
      small: this.props.size === 'small' ? true : false,
      newSubject: '',
      model: this.props.model || 'procedures',
    };
    this.handleTitleEdit = this.handleTitleEdit.bind(this);
    this.handleTitleChange = this.handleTitleChange.bind(this);
    this.handleTitleEditClickAway = this.handleTitleEditClickAway.bind(this);
    this.handleTitleEditClickAbort = this.handleTitleEditClickAbort.bind(this);
    this.submitNewTitle = this.submitNewTitle.bind(this);
  }

  handleTitleEdit = () => {
    if (!!this.props.isInbox === true) {
      // Not editable
    } else {
      this.setState({ edit: true, newSubject: this.state.subject });
    }
  };

  handleTitleChange = (event) => {
    this.setState({ newSubject: event.target.value });
  };

  handleTitleEditClickAbort = () => {
    this.setState({
      isFetching: false,
      edit: false,
      subject: this.state.subject,
      newSubject: '',
    });
  };

  handleTitleEditClickAway = () => {
    this.submitNewTitle();
    //this.setState({ isFetching: false, edit: false, subject: this.props.subject, newSubject: "" });
  };

  submitNewTitle = async () => {
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('subject', this.state.newSubject);

    await fetch(
      process.env.REACT_APP_API_URL +
        '/api/' +
        this.state.model +
        '/' +
        this.props.match.params.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ subject: this.state.newSubject, edit: false });
      })
      .catch((error) => {
        return false;
      });
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    if (this.state.isFetching) {
      return (
        <Typography variant='h4'>
          {' '}
          <CircularProgress />{' '}
        </Typography>
      );
    }

    return (
      <>
        {!!this.state.edit ? (
          <ClickAwayListener onClickAway={this.handleTitleEditClickAway}>
            <div className={classes.wrapperEdit}>
              <div className={classes.input}>
                <TextField
                  multiline={true}
                  maxRows={4}
                  autoFocus={true}
                  fullWidth
                  required
                  label={TextDE.inbox.title}
                  helperText={TextDE.inbox.titleHelper}
                  value={this.state.newSubject}
                  onChange={this.handleTitleChange}
                  name='newSubject'
                />
              </div>
              <div className={classes.buttonGroupEdit}>
                <Button
                  className={classes.backButton}
                  color='secondary'
                  variant='outlined'
                  onClick={this.handleTitleEditClickAbort}
                  aria-label={TextDE.back}
                >
                  <CloseIcon />
                  {TextDE.discard}
                </Button>
                <Button
                  className={classes.backButton}
                  color='primary'
                  variant='outlined'
                  onClick={this.submitNewTitle}
                  aria-label={TextDE.save}
                >
                  <SaveIcon />
                  {TextDE.save}
                </Button>
              </div>
            </div>
          </ClickAwayListener>
        ) : (
          <div className={classes.wrapper}>
            <Tooltip title={TextDE.inbox.titleTooltip} aria-label={TextDE.edit}>
              <div className={classes.colorize} onClick={this.handleTitleEdit}>
                <Typography variant='h5'>{this.state.subject}</Typography>
                <Hidden smDown>
                  <Divider />
                  <Typography variant='subtitle2'>{TextDE.inbox.titleHelper}</Typography>
                </Hidden>
              </div>
            </Tooltip>
            <div className={classes.buttonGroup}>
              <Tooltip title={TextDE.back} aria-label={TextDE.back}>
                <Button
                  className={classes.backButton}
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                    if (!!this.props.overwriteBack) {
                      this.props.overwriteBack();
                    } else {
                      this.props.history.goBack();
                    }
                  }}
                  aria-label={TextDE.back}
                >
                  <KeyboardReturnIcon />
                  <Hidden mdDown>{TextDE.back}</Hidden>
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Title);
