import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SaveIcon from '@material-ui/icons/Save';
import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import MinifiedStyledMenu from './MinifiedStyledMenu';
import { Constants } from '../../lib/Constants';
//import {MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
//import MomentUtils from '@date-io/moment';
import 'moment/locale/de';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import { DE as TextDE } from '../../lib/Text';
import CreateIcon from '@material-ui/icons/Create';
import CustomToolbarComponent from './components/CustomToolbarComponent';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
//import { EditorState, ContentState, convertFromHTML } from 'draft-js';
//import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { DE as TextDE } from '../../lib/Text';

import clsx from 'clsx';
moment.locale('de'); // it is required to select default locale manually
function unique(data) {
  return data.filter(function (value, index, data) {
    return data.indexOf(value) === index;
  });
}

const localContactTags = [
  ...Constants.tags.contacts.resident,
  ...Constants.tags.contacts.residentHidden,
  ...Constants.tags.contacts.creator,
];
const paymentContactTags = [
  ...Constants.tags.contacts.invoice,
  ...Constants.tags.contacts.invoiceHidden,
];
const localAddressTags = [
  ...Constants.tags.addresses.resident,
  ...Constants.tags.addresses.residentHidden,
  ...Constants.tags.addresses.creator,
];
const paymentAddressTags = [
  ...Constants.tags.addresses.invoice,
  ...Constants.tags.addresses.invoiceHidden,
];

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
    width: '100%',
    height: '100%',
    minHeight: '45vh',
  },
  formControl: {
    width: '100%',
    margin: theme.spacing(1),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  bottomFixed: {
    marginTop: 'auto',
  },
  form: {
    flexGrow: '1',
    display: 'flex',
    flexFlow: 'column',
  },
  button: {
    padding: theme.spacing(1, 3),
  },
  mb0: {
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  //button: {
  //  marginRight: theme.spacing(1),
  //},
  dialogTitle: {
    textAlign: 'center',
    //margin: theme.spacing(0,-1,2,-1),
    //padding: 0,
  },
  dialogTitleClose: {
    marginTop: theme.spacing(-2),
    float: 'right',
    marginRight: theme.spacing(-3),
  },
  floatRight: {
    float: 'right',
  },
  saveButton: {
    marginTop: theme.spacing(1),
    float: 'right',
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:disabled': {
      color: theme.palette.success.light,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.divider,
    },
  },
  paddingTop: {
    paddingTop: theme.spacing(3) + 'px!important',
  },
  paddingRight: {
    paddingRight: theme.spacing(2) + 'px!important',
  },
  border: {
    display: 'flex',
    flexFlow: 'column',
  },
  activeStep: {
    borderRadius: '14px',
    border: '2px solid ' + theme.palette.primary.main,
    backgroundColor: '#ffffff',
    boxShadow: theme.shadows[3],
    zIndex: 1,
    padding: theme.spacing(1.5, 1),
    margin: theme.spacing(-1.5, -1),
  },
  step: {
    padding: theme.spacing(1.5, 1),
    margin: theme.spacing(-1.5, -1),
  },
  fullWidth: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  chips: {
    width: '100%',
    display: 'flex',
    //justifyContent: 'flex-end',
    alignContetn: 'center',
  },
  editorWrapper: {
    width: '100% !important',
    display: 'flex !important',
    flexDirection: 'column',
    height: '100%',
  },
  editor: {
    padding: theme.spacing(1, 1),
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: '#EEEEEE',
    borderWidth: '1px',
    minHeight: '200px',
    height: 'calc( 100% - ' + theme.spacing(2) + 'px) !important',
    flexGrow: '1',
  },
  active: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
  },
  toolbar: {
    backgroundColor: '#EEEEEE',
  },
  results: {
    cursor: 'pointer',
    zIndex: 123123,
    position: 'absolute',
    maxHeight: '300px',
    overflow: 'auto',
    width: '100%',
  },
  stepper: {
    padding: theme.spacing(2, 0),
    overflow: 'hidden',
    marginLeft: '-8px',
    marginRight: '-8px',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 6),
    },
  },
  tagsBox: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
    justifyContent: 'start',
    borderRadius: '2px',
  },
});

function getSteps() {
  return [
    TextDE.procedure.addProcedureForm.descriptionLabel,
    TextDE.procedure.addProcedureForm.contactLabel,
    TextDE.procedure.addProcedureForm.coworkerLabel,
    TextDE.procedure.addProcedureForm.checkLabel,
  ];
}

function getStepContent(step, state = null) {
  switch (step) {
    case 0:
      return TextDE.procedure.addProcedureForm.descriptionText;
    case 1:
      return TextDE.procedure.addProcedureForm.contactText(
        !!state.addAddress
          ? state.form.tmp.paymentAddress
            ? Constants.tags.addresses.invoice[0]
            : state.form.tmp.beneficiaryAddress
            ? Constants.tags.addresses.resident[0]
            : TextDE.address.label
          : false,
      );
    case 2:
      return TextDE.procedure.addProcedureForm.coworkerText;
    case 3:
      return TextDE.procedure.addProcedureForm.checkText;
    default:
      return 'ERROR';
  }
}

class AddProcedure extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    if (this.state.form.subject.trim() === '') {
      this.setState({
        form: {
          ...this.state.form,
          hasError: (
            <Alert
              style={{ width: '90%', float: 'right' }}
              severity='error'
              action={
                <Button color='inherit' variant='outlined' onClick={() => this.toStep(0)}>
                  {TextDE.procedure.addProcedureForm.addEntry}
                </Button>
              }
            >
              {TextDE.procedure.addProcedureForm.alerts.subjectMissing}
            </Alert>
          ),
        },
      });
      return;
    }
    this.setState({
      form: { ...this.state.form, isSubmitting: true, hasError: false },
    });
    let formData = {};
    formData['subject'] = this.state.form.subject;
    formData['subtitle'] = this.state.form.body;
    formData['body'] = this.state.form.body;
    formData['html'] = stateToHTML(this.state.editorStateDescription.getCurrentContent());
    formData['addresses'] = this.state.form.addresses;
    formData['contacts'] = this.state.form.contacts;
    formData['users'] = this.state.form.users;

    if (this.state.form.targetDate.diff(moment(), 'days') > 0) {
      formData['target_date'] = this.state.form.targetDate;
    }
    if (this.state.form.rememberDate.diff(moment(), 'days') > 0) {
      formData['remember_date'] = this.state.form.rememberDate;
    }

    let endpoint = process.env.REACT_APP_API_URL;
    if (
      typeof this.state.form.uuid !== 'undefined' &&
      this.state.form.uuid !== null &&
      this.state.form.uuid !== ''
    ) {
      formData['uuid'] = this.state.form.uuid;
      endpoint = endpoint + '/api/procedures/' + formData.uuid;
    } else {
      endpoint = endpoint + '/api/procedures';
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return response.json();
      })
      .then(function (response) {
        return response;
      })
      .catch((error) => error);

    //if (response.trim().toLocaleLowerCase() === "created") {
    if (!!response.uuid) {
      this.setState({
        form: { ...this.state.form, isSubmitting: false, hasError: false },
      });
      this.props.handleclosefunction(response.uuid);
    } else {
      if (!!response.message) {
        this.setState({
          form: {
            ...this.state.form,
            isSubmitting: false,
            hasError: response.message,
          },
        });
      } else {
        this.setState({
          form: {
            ...this.state.form,
            isSubmitting: false,
            hasError: JSON.stringify(response),
          },
        });
      }
    }
  }

  handleUserChange = (event, value) => {
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      if (!!value.uuid) {
        this.setState({
          form: {
            ...this.state.form,
            tmp: {
              ...this.state.form.tmp,
              user_uuid: value,
              user_tags: value.roles || [],
            },
          },
        });
      } else if (Array.isArray(value)) {
        this.setState({
          form: {
            ...this.state.form,
            tmp: { ...this.state.form.tmp, user_tags: value },
          },
        });
      } else {
        // Kein Tag/ Kein Kontalt -> Neuer Kollege!
        this.setState({
          form: {
            ...this.state.form,
            tmp: { ...this.state.form.tmp, user_uuid: false },
          },
        });
      }
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: event.target.value,
          },
        },
      });
    }
  };

  handleUserAdd = (event) => {
    if (!!this.state.form.tmp.user_uuid && !!this.state.form.tmp.user_uuid.displayName) {
      let users = this.state.form.users;
      let indexOfUser = users.findIndex((el) => el.uuid === this.state.form.tmp.user_uuid.uuid);
      if (indexOfUser >= 0) {
        let tmpA = users[indexOfUser];
        tmpA.tags = unique([...tmpA.tags, ...this.state.form.tmp.user_tags]);
        users[indexOfUser] = tmpA;
      } else {
        users.push({
          uuid: this.state.form.tmp.user_uuid.uuid,
          name: this.state.form.tmp.user_uuid.displayName,
          tags: this.state.form.tmp.user_tags,
        });
      }
      this.setState({
        form: {
          ...this.state.form,
          users: users,
          tmp: { ...this.state.form.tmp, user_uuid: '', user_tags: [] },
        },
      });
      return true;
    }
    return false;
  };

  handleUserEdit = (uuid) => {
    let user = this.state.usersAll[this.state.usersAll.findIndex((el) => el.uuid === uuid)];
    let tags = user.tags;

    let users = this.state.form.users;
    let indexOfUser = users.findIndex((el) => el.uuid === uuid);
    if (indexOfUser >= 0) {
      tags = users[indexOfUser].tags ?? tags;
    }

    users = users.filter((user) => user.uuid !== uuid);
    this.setState(
      {
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            user_uuid: user,
            user_tags: tags,
          },
          users: users,
        },
      },
      this.toStep(2),
    );
  };

  handleUserDelete = (uuid) => {
    let users = this.state.form.users;
    users = users.filter((user) => user.uuid !== uuid);
    this.setState({
      form: {
        ...this.state.form,
        users: users,
      },
    });
  };

  handleContactChange = (event, value) => {
    //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, typeof event,event, typeof value,value);
    if (value && value.inputValue) {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, contact_uuid: value },
        },
      });
    } else if (
      typeof value !== 'undefined' &&
      typeof value !== 'boolean' &&
      typeof value.key === 'undefined'
    ) {
      if (!!value.uuid) {
        let paymentContact = false;
        let beneficiaryContact = false;
        let otherContactType = false;
        let alreadyHasPaymentContact = false;
        let alreadyHasBeneficiaryContact = false;

        if (!!value.tags && value.tags.length > 0) {
          paymentContact =
            value.tags.findIndex((tag) => paymentContactTags.includes(tag?.name?.de ?? tag)) >= 0;
          if (!!paymentContact) {
            alreadyHasPaymentContact =
              this.state.form.contacts.findIndex((contact) => {
                return (
                  contact.tags.findIndex((tag) => {
                    return paymentContactTags.includes(tag);
                  }) >= 0
                );
              }) >= 0;
            if (alreadyHasPaymentContact) {
              // Trim out every "Rechnungsempfänger" tag
              value.tags = value.tags.filter(
                (tag) =>
                  paymentContactTags.findIndex(
                    (paymentContactTag) => tag.toLowerCase() === paymentContactTag.toLowerCase(),
                  ) === -1,
              );
              paymentContact = false;
            }
          }
          beneficiaryContact =
            value.tags.findIndex((tag) => localContactTags.includes(tag?.name?.de ?? tag)) >= 0;
          if (!!beneficiaryContact) {
            alreadyHasBeneficiaryContact =
              this.state.form.contacts.findIndex((contact) => {
                return (
                  contact.tags.findIndex((tag) => {
                    return localContactTags.includes(tag);
                  }) >= 0
                );
              }) >= 0;
            if (alreadyHasBeneficiaryContact) {
              // Trim out every "Leistungsempfänger" tag
              value.tags = value.tags.filter(
                (tag) =>
                  localContactTags.findIndex(
                    (localContactTag) => tag.toLowerCase() === localContactTag.toLowerCase(),
                  ) === -1,
              );
              beneficiaryContact = false;
            }
          }
          otherContactType =
            (paymentContact === -1 && beneficiaryContact === -1 && value.tags.length > 0) ||
            (!!alreadyHasPaymentContact && !!alreadyHasBeneficiaryContact);
        }

        this.setState({
          hint: !!value.address
            ? 'Die Adresse "' +
              value.address.route +
              ' ' +
              value.address.street_number +
              ', ' +
              value.address.postal_code +
              ' ' +
              value.address.locality +
              '" wird hinzugefügt.'
            : false,
          form: {
            ...this.state.form,
            tmp: {
              ...this.state.form.tmp,
              contact_uuid: value,
              contact_tags: value.tags || [],
              paymentContact: paymentContact,
              beneficiaryContact: beneficiaryContact,
              otherContactType: otherContactType,
            },
          },
        });
      } else if (Array.isArray(value)) {
        this.setState({
          form: {
            ...this.state.form,
            tmp: { ...this.state.form.tmp, contact_tags: value },
          },
        });
      }
    } else {
      if (!!event?.target?.type && event.target.type.toLowerCase() === 'checkbox') {
        let tags = this.state.form.tmp.contact_tags ?? [];
        switch (event.target.name.toLowerCase()) {
          case 'paymentcontact':
            if (!!event.target.checked) {
              tags.push(Constants.tags.contacts.invoice[0]);
            } else {
              tags = tags.filter(
                (value) => value.toLowerCase() !== Constants.tags.contacts.invoice[0].toLowerCase(),
              );
            }
            break;
          case 'beneficiarycontact':
            if (!!event.target.checked) {
              tags.push(Constants.tags.contacts.resident[0]);
            } else {
              tags = tags.filter(
                (value) =>
                  value.toLowerCase() !== Constants.tags.contacts.resident[0].toLowerCase(),
              );
            }
            break;
          default:
            break;
        }
        tags = unique(tags);
        this.setState({
          form: {
            ...this.state.form,
            tmp: {
              ...this.state.form.tmp,
              contact_tags: tags,
              [event.target.name]: event.target.checked,
            },
          },
        });
      } else if (!!this.state.form.tmp.contact_uuid?.newContact) {
        this.setState({
          form: {
            ...this.state.form,
            tmp: {
              ...this.state.form.tmp,
              contact_uuid: {
                ...this.state.form.tmp.contact_uuid,
                [event.target.name]: event.target.value,
              },
            },
          },
        });
      } else {
        this.setState({
          form: {
            ...this.state.form,
            tmp: {
              ...this.state.form.tmp,
              [event.target.name]: event.target.value,
            },
          },
        });
      }
    }
  };

  handleContactEdit = (uuid) => {
    let contacts = this.state.form.contacts;
    let contact = contacts.find((contact) => contact.uuid === uuid);
    contacts = contacts.filter((contact) => contact.uuid !== uuid);

    let paymentContact = false;
    let beneficiaryContact = false;
    let otherContactType = false;
    let alreadyHasPaymentContact = false;
    let alreadyHasBeneficiaryContact = false;

    if (!!contact.tags && contact.tags.length > 0) {
      paymentContact =
        contact.tags.findIndex((tag) => paymentContactTags.includes(tag?.name?.de ?? tag)) >= 0;
      if (!!paymentContact) {
        alreadyHasPaymentContact =
          contacts.findIndex((contact) => {
            return (
              contact.tags.findIndex((tag) => {
                return paymentContactTags.includes(tag);
              }) >= 0
            );
          }) >= 0;
        if (alreadyHasPaymentContact) {
          // Trim out every "Rechnungsempfänger" tag
          contact.tags = contact.tags.filter(
            (tag) =>
              paymentContactTags.findIndex(
                (paymentContactTag) => tag.toLowerCase() === paymentContactTag.toLowerCase(),
              ) === -1,
          );
          paymentContact = false;
        }
      }
      beneficiaryContact =
        contact.tags.findIndex((tag) => localContactTags.includes(tag?.name?.de ?? tag)) >= 0;
      if (!!beneficiaryContact) {
        alreadyHasBeneficiaryContact =
          contacts.findIndex((contact) => {
            return (
              contact.tags.findIndex((tag) => {
                return localContactTags.includes(tag);
              }) >= 0
            );
          }) >= 0;
        if (alreadyHasBeneficiaryContact) {
          // Trim out every "Leistungsempfänger" tag
          contact.tags = contact.tags.filter(
            (tag) =>
              localContactTags.findIndex(
                (localContactTag) => tag.toLowerCase() === localContactTag.toLowerCase(),
              ) === -1,
          );
          beneficiaryContact = false;
        }
      }
      otherContactType =
        (paymentContact === -1 && beneficiaryContact === -1 && contact.tags.length > 0) ||
        (!!alreadyHasPaymentContact && !!alreadyHasBeneficiaryContact);
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            contact_uuid: contact,
            contact_tags: contact.tags,
            paymentContact: paymentContact,
            beneficiaryContact: beneficiaryContact,
            otherContactType: otherContactType,
          },
          contacts: contacts,
        },
      },
      () => this.toStep(1),
    );
  };

  handleContactAdd = (event) => {
    if (
      !!this.state.form.tmp.paymentContact === false &&
      !!this.state.form.tmp.beneficiaryContact === false &&
      !!this.state.form.tmp.otherContactType === false
    ) {
      this.setState({
        contactAddHint: 'Der Kontakt muss einem der Schlagworte zugeordnet werden!',
      });
      return false;
    }
    if (!!this.state.form.tmp.contact_uuid && !!this.state.form.tmp.contact_uuid.name) {
      let contacts = this.state.form.contacts;

      let indexOfContact =
        !!this.state.form.tmp.contact_uuid.newContact &&
        !!this.state.form.tmp.contact_uuid.inputValue
          ? -1
          : contacts.findIndex((el) => el.uuid === this.state.form.tmp.contact_uuid.uuid);

      if (indexOfContact >= 0 && !!!contacts[indexOfContact].newContact) {
        let tmp = contacts[indexOfContact];
        tmp.tags = unique([...tmp.tags, ...this.state.form.tmp.contact_tags]);
        contacts[indexOfContact] = tmp;
      } else {
        if (
          !!this.state.form.tmp.contact_uuid.newContact &&
          !!this.state.form.tmp.contact_uuid.inputValue
        ) {
          const nameArray = this.state.form.tmp.contact_uuid.inputValue.split(' ');
          var tmpFirstname = '';
          var tmpName = '';
          var tmpSalutation = '';
          if (
            !!this.state.form.tmp.contact_uuid?.salutation &&
            !!this.state.form.tmp.contact_uuid?.name
          ) {
            tmpFirstname = this.state.form.tmp.contact_uuid?.firstname ?? '';
            tmpName = this.state.form.tmp.contact_uuid?.name;
            tmpSalutation = this.state.form.tmp.contact_uuid?.salutation;
          } else if (nameArray.length >= 3) {
            tmpSalutation = nameArray.shift();
            tmpFirstname = nameArray.shift();
            tmpName = nameArray.join(' ');
          } else if (nameArray.length >= 2) {
            tmpFirstname = nameArray.shift();
            tmpName = nameArray.join(' ');
          } else {
            tmpName = nameArray.join(' ');
          }

          contacts.push({
            inputValue: `${this.state.form.tmp.contact_uuid.salutation}${
              !!this.state.form.tmp.contact_uuid.firstname
                ? ' ' + this.state.form.tmp.contact_uuid.firstname + ' '
                : ' '
            }${this.state.form.tmp.contact_uuid.name}`,
            newContact: true,
            uuid: this.state.form.tmp.contact_uuid.uuid,
            firstname: tmpFirstname,
            name: tmpName,
            salutation: tmpSalutation,
            phone: this.state.form.tmp.contact_uuid.phone,
            email: this.state.form.tmp.contact_uuid.email,
            company: this.state.form.tmp.contact_company,
            tags: this.state.form.tmp.contact_tags,
          });
        } else {
          contacts.push({
            uuid: this.state.form.tmp.contact_uuid.uuid,
            firstname: this.state.form.tmp.contact_uuid.firstname,
            name: this.state.form.tmp.contact_uuid.name,
            salutation: this.state.form.tmp.contact_uuid.salutation,
            tags: this.state.form.tmp.contact_tags,
          });
        }
      }

      // marker:
      // If this case happens prefill address input
      /*
      if (!!this.state.form.tmp.contact_uuid.address) {
        let local = [
          ...Constants.tags.contacts.resident,
          ...Constants.tags.contacts.residentHidden,
        ];
        let payment = [
          ...Constants.tags.contacts.invoice,
          ...Constants.tags.contacts.invoiceHidden,
        ];
        let tags = [];
        if (
          [...this.state.form.tmp.contact_uuid.tags, ...this.state.form.tmp.contact_tags].findIndex(
            (el) => local.includes(el),
          ) >= 0
        ) {
          tags.push(Constants.tags.addresses.resident[0]);
        }
        if (
          [...this.state.form.tmp.contact_uuid.tags, ...this.state.form.tmp.contact_tags].findIndex(
            (el) => payment.includes(el),
          ) >= 0
        ) {
          tags.push(Constants.tags.addresses.invoice[0]);
        }
        if (tags.length > 0) {
          let addresses = this.state.form.addresses;
          let indexOfAddress = addresses.findIndex(
            (el) => el.addressObject === this.state.form.tmp.contact_uuid.address,
          );
          if (indexOfAddress >= 0) {
            let tmpA = addresses[indexOfAddress];
            tmpA.tags = unique([...tmpA.tags, ...tags]);
            addresses[indexOfAddress] = tmpA;
          } else {
            addresses.push({
              address:
                this.state.form.tmp.contact_uuid.address?.route +
                ' ' +
                this.state.form.tmp.contact_uuid.address?.street_number +
                ', ' +
                this.state.form.tmp.contact_uuid.address?.postal_code +
                ' ' +
                this.state.form.tmp.contact_uuid.address?.locality,
              addressObject: this.state.form.tmp.contact_uuid.address,
              tags: tags,
            });
          }
          this.setState({
            hint: false,
            contactAddHint: false,
            form: {
              ...this.state.form,
              addresses: addresses,
            },
          });
        }
      } else {
        // dont prefill address input
      }
      */
      // but now always Display the Address input
      // -> After Address is saved, jump back to contact input form

      var addressTags = [];
      if (!!this.state.form.tmp.paymentContact) {
        addressTags.push(Constants.tags.addresses.invoice[0]);
      }
      if (!!this.state.form.tmp.beneficiaryContact) {
        addressTags.push(Constants.tags.addresses.resident[0]);
      }

      this.setState({
        hint: false,
        contactAddHint: false,
        addAddress: true,
        form: {
          ...this.state.form,
          contacts: contacts,
          tmp: {
            ...this.state.form.tmp,
            contact_uuid: '',
            contact_company: '',
            contact_tags: [],
            paymentContact: false,
            beneficiaryContact: false,
            otherContactType: false,
            paymentAddress: !!this.state.form.tmp.paymentContact,
            beneficiaryAddress: !!this.state.form.tmp.beneficiaryContact,
            address: !!this.state.form.tmp.contact_uuid.address
              ? `${this.state.form.tmp.contact_uuid.address.route ?? ''} ${
                  this.state.form.tmp.contact_uuid.address.street_number ?? ''
                }, ${this.state.form.tmp.contact_uuid.address.postal_code ?? ''} ${
                  this.state.form.tmp.contact_uuid.address.locality ?? ''
                }`
              : '',
            address_tags: addressTags,
            addressObject: !!this.state.form.tmp.contact_uuid.address
              ? {
                  administrative_area_level_1:
                    this.state.form.tmp.contact_uuid.address.administrative_area_level_1 ??
                    this.state.form.tmp.contact_uuid.address.state,
                  country: this.state.form.tmp.contact_uuid.address.country,
                  street_number: this.state.form.tmp.contact_uuid.address.street_number,
                  route: this.state.form.tmp.contact_uuid.address.route,
                  locality: this.state.form.tmp.contact_uuid.address.locality,
                  postal_code: this.state.form.tmp.contact_uuid.address.postal_code,
                }
              : {
                  administrative_area_level_1: '',
                  country: '',
                  street_number: '',
                  route: '',
                  locality: '',
                  postal_code: '',
                },
          },
        },
      });
      return true;
    }
    return false;
  };

  selectCompany = (event, value) => {
    if (!!value) {
      this.setState({
        hint: false,
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            contact_company: {
              id: value.id,
              uuid: value.uuid,
              name: value.name,
            },
          },
        },
      });
    }
  };

  handleContactDelete = (uuid) => {
    let contacts = this.state.form.contacts;
    contacts = contacts.filter((contact) => contact.uuid !== uuid);
    this.setState({
      form: {
        ...this.state.form,
        contacts: contacts,
      },
    });
  };

  handleAddressSetManualEdit = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        tmp: {
          ...this.state.form.tmp,
          manual_address_input: true,
          addressObject: {
            ...this.state.form.tmp.addressObject,
            country:
              !!this.state.form.tmp.addressObject.country &&
              this.state.form.tmp.addressObject.country !== ''
                ? this.state.form.tmp.addressObject.country
                : 'Germany',
          },
        },
      },
    });
  };

  handleAddressResetManualEdit = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        tmp: {
          ...this.state.form.tmp,
          manual_address_input: false,
          address: '',
          addressError: false,
          addressObject: {
            administrative_area_level_1: '',
            country: '',
            street_number: '',
            route: '',
            locality: '',
            postal_code: '',
          },
        },
      },
    });
  };

  validateAddressObject() {
    let error = [];
    if (
      !!!this.state.form.tmp?.addressObject?.street_number ||
      this.state.form.tmp?.addressObject?.street_number === ''
    ) {
      error.push(TextDE.address.street_number);
    }
    if (
      !!!this.state.form.tmp?.addressObject?.route ||
      this.state.form.tmp?.addressObject?.route === ''
    ) {
      error.push(TextDE.address.route);
    }
    if (
      !!!this.state.form.tmp?.addressObject?.locality ||
      this.state.form.tmp?.addressObject?.locality === ''
    ) {
      error.push(TextDE.address.locality);
    }
    if (
      !!!this.state.form.tmp?.addressObject?.postal_code ||
      this.state.form.tmp?.addressObject?.postal_code === ''
    ) {
      error.push(TextDE.address.postal_code);
    }
    if (
      !!!this.state.form.tmp?.addressObject?.administrative_area_level_1 ||
      this.state.form.tmp?.addressObject?.administrative_area_level_1 === ''
    ) {
      error.push(TextDE.address.administrative_area_level_1);
    }
    if (
      !!!this.state.form.tmp?.addressObject?.country ||
      this.state.form.tmp?.addressObject?.country === ''
    ) {
      error.push(TextDE.address.country);
    }
    this.setState({
      form: {
        ...this.state.form,
        tmp: {
          ...this.state.form.tmp,
          addressError:
            error.length > 0 ? error.join(',') + TextDE.address.missingHintAddendum : false,
          address: `${this.state.form.tmp.addressObject.route ?? ''} ${
            this.state.form.tmp.addressObject.street_number ?? ''
          }, ${this.state.form.tmp.addressObject.postal_code ?? ''} ${
            this.state.form.tmp.addressObject.locality ?? ''
          } `,
        },
      },
    });
    return error;
  }

  handleAddressDetailChange = (event) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            addressObject: {
              ...this.state.form.tmp.addressObject,
              [event.target.name]: event.target.value,
            },
          },
        },
      },
      this.validateAddressObject,
    );
  };

  handleAddressChange = (event, value) => {
    if (
      typeof value !== 'undefined' &&
      typeof value !== 'boolean' &&
      typeof value.key === 'undefined'
    ) {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, address_tags: value },
        },
      });
    } else if (typeof event === 'string') {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, address: event, manual_address_input: false },
        },
      });
    } else if (event.target.type.toLowerCase() === 'checkbox') {
      let tags = this.state.form.tmp.address_tags ?? [];
      switch (event.target.name.toLowerCase()) {
        case 'paymentaddress':
          if (!!event.target.checked) {
            tags.push(Constants.tags.addresses.invoice[0]);
          } else {
            tags = tags.filter(
              (value) => value.toLowerCase() !== Constants.tags.addresses.invoice[0].toLowerCase(),
            );
          }
          break;
        case 'beneficiaryaddress':
          if (!!event.target.checked) {
            tags.push(Constants.tags.addresses.resident[0]);
          } else {
            tags = tags.filter(
              (value) => value.toLowerCase() !== Constants.tags.addresses.resident[0].toLowerCase(),
            );
          }
          break;
        default:
          break;
      }
      tags = unique(tags);
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            address_tags: tags,
            [event.target.name]: event.target.checked,
          },
        },
      });
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: event.target.value,
          },
        },
      });
    }
    //if (typeof value === 'undefined' || typeof value.key === 'undefined') {
    //  this.setState({ form: { ...this.state.form, tmp: { ...this.state.form.tmp, address_tags: value}}})
    //} else {
    //  //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
    //  this.setState({ form: { ...this.state.form, tmp: { ...this.state.form.tmp, [event.target.name]: event.target.value}}})
    //}
  };

  handleAddressAdd = (event) => {
    if (!!this.state.form.tmp?.address && !!this.state.form.tmp?.addressObject) {
      let addresses = this.state.form.addresses;
      let error = this.validateAddressObject();

      if (error.length > 0) {
        return false;
      }

      if (
        !!this.state.form.tmp.paymentAddress === false &&
        !!this.state.form.tmp.beneficiaryAddress === false
      ) {
        this.setState({
          addressAddHint: TextDE.procedure.addProcedureForm.alerts.addressToUse,
        });
        return false;
      }

      let indexOfAddress = addresses.findIndex(
        (el) => el.addressObject === this.state.form.tmp.addressObject,
      );
      if (indexOfAddress >= 0) {
        let tmpA = addresses[indexOfAddress];
        tmpA.tags = unique([...tmpA.tags, ...this.state.form.tmp.address_tags]);
        addresses[indexOfAddress] = tmpA;
      } else {
        addresses.push({
          address: this.state.form.tmp.address,
          addressObject: this.state.form.tmp.addressObject,
          tags: this.state.form.tmp.address_tags,
        });
      }

      this.setState({
        addressAddHint: false,
        addAddress: false,
        form: {
          ...this.state.form,
          addresses: addresses,
          tmp: {
            ...this.state.form.tmp,
            address: '',
            addressObject: {
              administrative_area_level_1: '',
              country: '',
              street_number: '',
              route: '',
              locality: '',
              postal_code: '',
            },
            manual_address_input: false,
            address_tags: [],
            beneficiaryAddress: false,
            paymentAddress: false,
          },
        },
      });
      return true;
    } else {
      this.setState({
        addressAddHint: false,
        addAddress: false,
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            address: '',
            addressObject: {
              administrative_area_level_1: '',
              country: '',
              street_number: '',
              route: '',
              locality: '',
              postal_code: '',
            },
            manual_address_input: false,
            address_tags: [],
            beneficiaryAddress: false,
            paymentAddress: false,
          },
        },
      });
    }
    return true;
  };

  handleAddressEdit = (address) => {
    let addresses = this.state.form.addresses;
    let tmpAddress = addresses.filter((el) => el.address === address);
    tmpAddress = tmpAddress[0];
    addresses = addresses.filter((el) => el.address !== address);

    let paymentAddress = false;
    let beneficiaryAddress = false;
    let alreadyHasPaymentAddress = false;
    let alreadyHasBeneficiaryAddress = false;

    if (!!tmpAddress.tags && tmpAddress.tags.length > 0) {
      paymentAddress =
        tmpAddress.tags.findIndex((tag) => paymentAddressTags.includes(tag?.name?.de ?? tag)) >= 0;
      if (!!paymentAddress) {
        alreadyHasPaymentAddress =
          addresses.findIndex((address) => {
            return (
              address.tags.findIndex((tag) => {
                return paymentContactTags.includes(tag);
              }) >= 0
            );
          }) >= 0;
        if (alreadyHasPaymentAddress) {
          // Trim out every "Rechnungsempfänger" tag
          tmpAddress.tags = tmpAddress.tags.filter(
            (tag) =>
              paymentAddressTags.findIndex(
                (paymentAddressTag) => tag.toLowerCase() === paymentAddressTag.toLowerCase(),
              ) === -1,
          );
          paymentAddress = false;
        }
      }
      beneficiaryAddress =
        tmpAddress.tags.findIndex((tag) => localAddressTags.includes(tag?.name?.de ?? tag)) >= 0;
      if (!!beneficiaryAddress) {
        alreadyHasBeneficiaryAddress =
          addresses.findIndex((address) => {
            return (
              address.tags.findIndex((tag) => {
                return localAddressTags.includes(tag);
              }) >= 0
            );
          }) >= 0;
        if (alreadyHasBeneficiaryAddress) {
          // Trim out every "Leistungsempfänger" tag
          tmpAddress.tags = tmpAddress.tags.filter(
            (tag) =>
              localAddressTags.findIndex(
                (localAddressTag) => tag.toLowerCase() === localAddressTag.toLowerCase(),
              ) === -1,
          );
          beneficiaryAddress = false;
        }
      }
    }

    this.setState(
      {
        addAddress: true,
        form: {
          ...this.state.form,
          addresses: addresses,
          tmp: {
            ...this.state.form.tmp,
            address: tmpAddress.address,
            addressObject: tmpAddress.addressObject,
            address_tags: tmpAddress.tags,
            paymentAddress: paymentAddress,
            beneficiaryAddress: beneficiaryAddress,
          },
        },
      },
      () => this.toStep(1),
    );
  };

  handleAddressDelete = (address) => {
    let addresses = this.state.form.addresses;
    addresses = addresses.filter((el) => el.address !== address);
    this.setState({
      form: {
        ...this.state.form,
        addresses: addresses,
      },
    });
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => {
        console.error('Error', error);
        if (error === 'ZERO_RESULTS') {
          this.handleAddressSetManualEdit();
        }
      });
  };
  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        //componentForm[addressType] = val;
        //console.log(addressType, val);
        //setCompanyState({...companyState, [addressType]: val});
        newAddress[addressType] = val;
      }
    }
    //this.setState({form: { ...this.state.form, 'address': address, addressObject: newAddress } });
    let error = [];
    if (!!!newAddress.street_number || newAddress?.street_number === '') {
      error.push(TextDE.address.street_number);
    }
    if (!!!newAddress.route || newAddress?.route === '') {
      error.push(TextDE.address.route);
    }
    if (!!!newAddress.locality || newAddress?.locality === '') {
      error.push(TextDE.address.locality);
    }
    if (!!!newAddress.postal_code || newAddress?.postal_code === '') {
      error.push(TextDE.address.postal_code);
    }
    if (
      !!!newAddress.administrative_area_level_1 ||
      newAddress?.administrative_area_level_1 === ''
    ) {
      error.push(TextDE.address.administrative_area_level_1);
    }
    if (!!!newAddress.country || newAddress?.country === '') {
      error.push(TextDE.address.country);
    }
    if (error.length > 0) {
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            manual_address_input: true,
            addressError: error.join(',') + TextDE.address.missingHintAddendum,
            addressObject: newAddress,
            address: `${newAddress.route ?? ''} ${newAddress.street_number ?? ''}, ${
              newAddress.postal_code ?? ''
            } ${newAddress.locality ?? ''} `,
          },
        },
      });
      return false;
    }

    this.setState({
      form: {
        ...this.state.form,
        tmp: {
          ...this.state.form.tmp,
          address: address,
          addressObject: newAddress,
          addressError: false,
          manual_address_input: false,
        },
      },
    });
  };

  backToSearch = () => {
    this.setState({
      form: {
        ...this.state.form,
        tmp: { ...this.state.form.tmp, contact_uuid: null },
      },
    });
  };
  handleChange = (event) => {
    if (event === null) {
      // Skippydiy
    } else if (event.target.type === 'checkbox') {
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.checked },
      });
    } else if (event.target.name === 'contact_company') {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, contact_company: event.target.value },
        },
      });
    } else {
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.value },
      });
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, this.state);
    }
  };

  renderUserList(list) {
    return Object.keys(list).map((key) => {
      return (
        <MenuItem disabled={false} hidden={false} key={key} value={list[key].uuid}>
          {list[key].displayName}
        </MenuItem>
      );
    });
  }

  renderContactList(list) {
    return Object.keys(list).map((key) => {
      return (
        <MenuItem disabled={false} hidden={false} key={key} value={list[key].uuid}>
          <ListItemIcon>
            {list[key].isUser ? (
              <AccountCircleIcon fontSize='small' />
            ) : (
              <RecentActorsIcon fontSize='small' />
            )}
          </ListItemIcon>

          <ListItemText
            primary={list[key].displayName}
            secondary={list[key].tags.map((tag) => (
              <Chip label={tag.name.de} />
            ))}
          />
        </MenuItem>
      );
    });
  }

  constructor(props) {
    super(props);

    const initialStateList = {
      1: { id: '1', displayName: TextDE.new },
    };

    const initialFormState = {
      isSubmitting: false,
      hasError: false,

      uuid: !!props.dialogData?.uuid ? props.dialogData?.uuid : null,

      subject: !!props.dialogData?.subject ? props.dialogData?.subject : '',
      body: '',

      targetDate: moment().subtract(1, 'd'), //.add(3, 'w'),
      rememberDate: moment().subtract(1, 'd'), //.add(1, 'd'),
      addresses: [],
      contacts: [],
      users: [],
      tmp: {
        user_uuid: '',
        user_tags: [],
        contact_uuid: '',
        contact_tags: [],
        paymentContact: false,
        paymentAddress: false,
        beneficiaryContact: false,
        beneficiaryAddress: false,
        otherContactType: false,
        contact_company: '',
        address: '',
        manual_address_input: false,
        addressObject: {
          administrative_area_level_1: '',
          country: '',
          street_number: '',
          route: '',
          locality: '',
          postal_code: '',
        },
        address_tags: [],
      },
    };

    this.state = {
      display: false,
      form: initialFormState,

      stateList: initialStateList,
      salutations: [
        { id: '1', salutation: TextDE.contact.contactForm.salutation_male },
        { id: '2', salutation: TextDE.contact.contactForm.salutation_female },
        { id: '3', salutation: TextDE.contact.contactForm.salutation_other },
        { id: '4', salutation: TextDE.contact.contactForm.company },
      ],
      userList: [],
      contactsAll: [],
      contactsTags: Constants.tags.contacts.all(),
      usersTags: Constants.tags.users.all(),
      addressTags: Constants.tags.addresses.all(),
      activeStep: 0,
      skipped: new Set(),
      hasReachedLastStep: false,
      editorStateDescription: EditorState.createEmpty(),
      editorFocusDescription: false,
      editorStateAdditionalNote: EditorState.createEmpty(),
      editorFocusAdditionalNote: false,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleTargetDateChange = this.handleTargetDateChange.bind(this);
    this.handleRememberDateChange = this.handleRememberDateChange.bind(this);
    this.handleAddressResetManualEdit = this.handleAddressResetManualEdit.bind(this);
    this.handleAddressSetManualEdit = this.handleAddressSetManualEdit.bind(this);
    this.handleSubjectChange = this.handleSubjectChange.bind(this);
    this.validateAddressObject = this.validateAddressObject.bind(this);
    this.steps = getSteps();
    this.isStepOptional = this.isStepOptional.bind(this);
    this.isStepFailed = this.isStepFailed.bind(this);
    this.isStepSkipped = this.isStepSkipped.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.toStep = this.toStep.bind(this);
    this.backToSearch = this.backToSearch.bind(this);
  }

  onEditorStateDescriptionChange = (editorStateDescription) => {
    this.setState({ editorStateDescription });
    let body = editorStateDescription.getCurrentContent().getPlainText();
    let subject = body;
    if (subject.length > 30) {
      subject = subject.substring(0, 27) + '...';
    }
    this.setState({
      form: { ...this.state.form, subject: subject, body: body },
    });
  };

  onEditorStateAdditionalNoteChange = (editorStateAdditionalNote) => {
    this.setState({ editorStateAdditionalNote });
  };

  isStepOptional = (step) => {
    return step >= 1;
  };

  isStepFailed = (step) => {
    let failed = false;
    switch (step) {
      case 0:
        if (!!this.state.form.uuid) {
          failed = this.state.form.subject.length === 0 ? true : false;
        } else {
          failed =
            this.state.form.subject.length === 0
              ? true
              : this.state.form.body.length === 0
              ? true
              : false;
        }
        break;
      case 1:
        if (!this.isStepSkipped(step)) {
          failed = this.state.form.contacts.length === 0 ? true : false;
        }
        break;
      case 2:
        if (!this.isStepSkipped(step)) {
          failed = this.state.form.addresses.length === 0 ? true : false;
        }
        break;
      case 3:
        if (!this.isStepSkipped(step)) {
          failed = this.state.form.users.length === 0 ? true : false;
        }
        break;
      case 4:
        if (!this.isStepSkipped(step)) {
          failed =
            this.state.form.targetDate.diff(moment(), 'days') < 0 ||
            this.state.form.rememberDate.diff(moment(), 'days') < 0
              ? true
              : false;
        }
        break;
      default:
        break;
    }
    return failed;
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  };

  generateSubject() {
    let subject = this.state.editorStateDescription.getCurrentContent().getPlainText();

    if (subject.length > 30) {
      subject = subject.substring(0, 27) + '...';
    }

    if (!!this.state.form.addresses[0]) {
      subject = subject + ' | ' + this.state.form.addresses[0].address.split(',')[0];
    }
    if (!!this.state.form.contacts[0]) {
      subject = subject + ' | ' + this.state.form.contacts[0].name.split(' ').reverse()[0];
    }
    this.setState({ form: { ...this.state.form, subject: subject } });
  }

  handleSubjectChange = (event) => {
    //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
    this.setState({
      form: {
        ...this.state.form,
        subject: event.target.value,
      },
    });
  };

  handleNext() {
    var nextStepCounter = 1;
    if (!this.isStepFailed(this.state.activeStep)) {
      if (this.state.activeStep === 2 && !!this.state.form.tmp.user_uuid) {
        let resp = this.handleUserAdd();
        if (resp === false) {
          return;
        }
      } else if (this.state.activeStep === 1 && !!this.state.form.tmp.contact_uuid) {
        let resp = this.handleContactAdd();
        nextStepCounter = 0;
        if (resp === false) {
          return;
        }
      } else if (this.state.activeStep === 1 && this.state.addAddress === true) {
        let resp = this.handleAddressAdd();
        nextStepCounter = 0;
        if (resp === false) {
          return;
        }
      }
      /*
      else if (this.state.activeStep === 2 && !!this.state.form.tmp.address) {
        console.log('} else if (!!this.state.form.tmp.address)');
        let resp = this.handleAddressAdd();
        if (resp === false) {
          return;
        }
      }*/

      let newSkipped = this.state.skipped;
      if (this.isStepSkipped(this.state.activeStep)) {
        newSkipped = new Set(this.state.skipped.values());
        newSkipped.delete(this.state.activeStep);
      }
      //setActiveStep(prevActiveStep => prevActiveStep + 1);
      this.setState(
        {
          activeStep: this.state.activeStep + nextStepCounter,
          skipped: newSkipped,
          hasReachedLastStep:
            this.state.hasReachedLastStep === false &&
            this.state.activeStep + 1 === this.steps.length - 1,
        },
        () => this.generateSubject(),
      );
    }
  }

  handleBack(event) {
    event.preventDefault();
    //setActiveStep(prevActiveStep => prevActiveStep - 1);
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleSkip(event) {
    event.preventDefault();
    if (!this.isStepOptional(this.state.activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    if (!!this.state.form.tmp.user_uuid) {
      let resp = this.handleUserAdd();
      if (resp === false) {
        return;
      }
    } else if (!!this.state.form.tmp.contact_uuid) {
      let resp = this.handleContactAdd();
      if (resp === false) {
        return;
      }
      return;
    } /* else if (!!this.state.form.tmp.address) {
      let resp = this.handleAddressAdd();
      if (resp === false) {
        return;
      }
    }*/
    //setSkipped(prevSkipped => {
    //  const newSkipped = new Set(prevSkipped.values());
    //  newSkipped.add(this.state.activeStep);
    //  return newSkipped;
    //});
    const newSkipped = new Set(this.state.skipped.values());
    newSkipped.add(this.state.activeStep);
    this.setState({ skipped: newSkipped });

    //setActiveStep(prevActiveStep => prevActiveStep + 1);
    this.setState({
      activeStep: this.state.activeStep + 1,
      hasReachedLastStep:
        this.state.hasReachedLastStep === false &&
        this.state.activeStep + 1 === this.steps.length - 1,
    });
  }

  handleReset() {
    //setActiveStep(0);
    this.setState({ activeStep: 0 });
  }

  toStep(step) {
    this.setState({ activeStep: step });
  }

  handleTargetDateChange = (date) => {
    this.setState({ form: { ...this.state.form, targetDate: date } });
  };

  handleRememberDateChange = (date) => {
    this.setState({ form: { ...this.state.form, rememberDate: date } });
  };

  componentDidUpdate(oldProps, oldState) {
    if (
      this.props.dialogData !== oldProps.dialogData &&
      this.props.dialogData !== null &&
      this.props.dialogData?.uuid != null &&
      this.props.dialogData.uuid !== this.state.form.uuid &&
      (this.state.form.uuid === null || this.state.form.uuid === '')
    ) {
      this.setState({ form: { ...this.state.form, uuid: this.props.dialogData.uuid } });
    }
    if (
      this.props.dialogData !== oldProps.dialogData &&
      this.props.dialogData !== null &&
      this.props.dialogData?.subject != null &&
      this.props.dialogData.subject !== this.state.form.subject &&
      (this.state.form.subject === null || this.state.form.subject === '')
    ) {
      this.setState({ form: { ...this.state.form, subject: this.props.dialogData.subject } });
    }
  }

  async componentDidMount() {
    let usersAll = [];
    let contactsAll = [];

    let response = await fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => json)
      .catch((error) => false);

    if (!!response) {
      usersAll = response.filter((value) => !!!value.deleted_at);
      usersAll = usersAll.map((value) => {
        return {
          uuid: value.uuid,
          displayName:
            value.firstname +
            (!!value.nickname ? ' "' + value.nickname + '" ' : ' ') +
            value.lastname,
          roles: value.roles.map((role) => role.name),
        };
      });
    }

    response = await fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => json)
      .catch((error) => false);

    let companysAll = [];
    if (!!response) {
      companysAll = [];
      contactsAll = response.map((value) => {
        if (value.salutation.id === 4) {
          companysAll.push(value);
        }
        return {
          uuid: value.uuid,
          salutation: value.salutation.name,
          firstname: value.firstname,
          name: value.name,
          deleted: value.deleted_at === null ? false : true,
          isUser: !!value.user_id,
          tags: value.tags.map((tag) => tag.name.de),
          address: !!value.address_id
            ? {
                street_number: value.address.street_number,
                route: value.address.route,
                locality: value.address.city.locality,
                state: value.address.city.state,
                administrative_area_level_1: value.address.city.administrative_area_level_1,
                country: value.address.city.country,
                postal_code: value.address.postal_code,
              }
            : null,
        };
      });
    }

    let users = [];

    let indexOfUser = usersAll.findIndex((el) => el.uuid === this.props.User.uuid);

    if (indexOfUser >= 0) {
      if (usersAll[indexOfUser].roles.includes(Constants.roles.ROLES_ORGANIZER) >= 0) {
        users = [
          {
            uuid: usersAll[indexOfUser].uuid,
            name: usersAll[indexOfUser].displayName,
            tags: [Constants.tags.users.administration[0], 'Ersteller'],
          },
        ];
      } else {
        users = [
          {
            uuid: usersAll[indexOfUser].uuid,
            name: usersAll[indexOfUser].displayName,
            tags: ['Ersteller'],
          },
        ];
      }
    }
    this.setState({
      contactsAll: contactsAll,
      usersAll: usersAll,
      companysAll: companysAll,
      form: {
        ...this.state.form,
        users: users,
      },
    });
  }

  render() {
    const filter = createFilterOptions();
    const { classes } = this.props;
    return (
      <>
        {this.state.isFetching ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          <>
            {!(this.props.hideTitle === true) && (
              <DialogTitle className={classes.dialogTitle} disableTypography>
                <Typography variant='h4'>
                  Neuer Vorgang
                  <IconButton
                    aria-label='close'
                    className={classes.dialogTitleClose}
                    onClick={() => this.props.handleclosefunction(false)}
                  >
                    <CloseIcon />
                  </IconButton>
                </Typography>
              </DialogTitle>
            )}

            <Grid container spacing={2} className={classes.root}>
              <Grid item xs={12}>
                <Stepper
                  alternativeLabel
                  nonLinear
                  activeStep={this.state.activeStep}
                  className={classes.stepper}
                >
                  {this.steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = { error: false, completed: false };
                    if (this.isStepFailed(index)) {
                      labelProps.error = true;
                    } else {
                      labelProps.error = false;
                    }
                    if (this.isStepSkipped(index)) {
                      stepProps.completed = false;
                    } else {
                      stepProps.completed = true;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepButton
                          onClick={() => {
                            if (!!this.state.hasReachedLastStep) {
                              this.toStep(index);
                            }
                          }}
                          className={
                            index === this.state.activeStep ? classes.activeStep : classes.step
                          }
                        >
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: this.state.activeStep === this.steps.length - 1 ? 'none' : 'flex',
                }}
                className={classes.border}
              >
                <Typography variant='h6' component='h6'>
                  {getStepContent(this.state.activeStep, this.state)}
                </Typography>
                <form onSubmit={this.handleFormSubmit} className={classes.form}>
                  {this.state.activeStep === 0 && (
                    <Grid container spacing={0} style={{ height: '100%' }}>
                      <Grid item xs={12}>
                        <Editor
                          placeholder={TextDE.procedure.addProcedureForm.descriptionHint}
                          editorState={this.state.editorStateDescription}
                          onEditorStateChange={this.onEditorStateDescriptionChange}
                          wrapperClassName={classes.editorWrapper}
                          editorClassName={clsx(classes.editor, {
                            [classes.active]: this.state.editorFocusDescription,
                          })}
                          onFocus={(event) => {
                            this.setState({ editorFocusDescription: true });
                          }}
                          onBlur={(event) => {
                            this.setState({ editorFocusDescription: false });
                          }}
                          toolbarClassName={classes.toolbar}
                          toolbar={{
                            options: ['inline', 'fontSize', 'link', 'list'],
                            inline: {
                              options: ['bold', 'italic', 'underline'],
                              bold: { className: 'bordered-option-classname' },
                              italic: {
                                className: 'bordered-option-classname',
                              },
                              underline: {
                                className: 'bordered-option-classname',
                              },
                              strikethrough: {
                                className: 'bordered-option-classname',
                              },
                            },

                            fontSize: {
                              options: [10, 12, 16, 20],
                              className: 'bordered-option-classname',
                            },
                            fontFamily: {
                              className: 'bordered-option-classname',
                            },
                            link: {
                              className: 'bordered-option-classname',
                            },
                          }}
                          toolbarCustomButtons={[
                            <CustomToolbarComponent
                              onChange={this.onEditorStateDescriptionChange}
                            />,
                          ]}
                        />
                      </Grid>
                    </Grid>
                  )}

                  {this.state.activeStep === 1 &&
                    (!!!this.state.addAddress ? (
                      <Grid container spacing={0}>
                        {this.state.form.contacts.length > 0 && (
                          <Hidden mdUp>
                            <Grid item xs={12}>
                              <List dense={false}>
                                {this.state.form.contacts.map((item, index) => {
                                  let text = (
                                    <>
                                      <Typography variant='button'>{item.name}</Typography>
                                      {item.tags.map((chip, chipIndex) => {
                                        return (
                                          <Chip key={`contact-chip-${chipIndex}`} label={chip} />
                                        );
                                      })}
                                    </>
                                  );
                                  return (
                                    <ListItem key={`contacts-${index}`}>
                                      <ListItemAvatar>
                                        <Avatar alt={item.name} src={'/avatar/' + item.name} />
                                      </ListItemAvatar>
                                      <ListItemText primary={text} />
                                      <ListItemSecondaryAction>
                                        <MinifiedStyledMenu
                                          item={item.uuid}
                                          callbackEdit={this.handleContactEdit}
                                          callbackDelete={this.handleContactDelete}
                                        />
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Grid>
                          </Hidden>
                        )}

                        <Grid item xs={12}>
                          <Autocomplete
                            id='contactselect'
                            name='uuid'
                            required
                            freeSolo
                            options={this.state.contactsAll}
                            renderOption={(option) => {
                              var displayName = `${option.salutation}${
                                !!option.firstname ? ' ' + option.firstname + ' ' : ' '
                              }${option.name}`;
                              return (
                                <ListItem component='div' key={option.uuid}>
                                  <ListItemAvatar>
                                    <Avatar variant='rounded' className={classes.small}>
                                      {displayName}
                                    </Avatar>
                                  </ListItemAvatar>
                                  <ListItemText
                                    disableTypography={false}
                                    primary={displayName}
                                    secondaryTypographyProps={{ component: 'span' }}
                                    secondary={
                                      !!option.newContact
                                        ? 'Als neuen Kontakt hinzufügen'
                                        : option.tags?.map((value, index) => (
                                            <Chip key={index} component='span' label={value} />
                                          ))
                                    }
                                  />
                                </ListItem>
                              );
                            }}
                            value={this.state.form.tmp.contact_uuid}
                            getOptionSelected={(option) => option.uuid || false}
                            onChange={this.handleContactChange}
                            defaultValue={null}
                            includeInputInList={true}
                            autoSelect={false}
                            disableClearable
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                fullWidth
                                label={TextDE.contact.label}
                                name='newContact'
                                placeholder={TextDE.contact.inputPlaceholder}
                                helperText={TextDE.contact.helperText}
                              />
                            )}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              // Suggest the creation of a new value
                              if (params.inputValue !== '') {
                                const nameArray = params.inputValue.split(' ');
                                var tmpFirstname = '';
                                var tmpName = '';
                                var tmpSalutation = '';
                                if (nameArray.length >= 3) {
                                  tmpSalutation = nameArray.shift();
                                  tmpFirstname = nameArray.shift();
                                  tmpName = nameArray.join(' ');
                                  tmpSalutation = tmpSalutation.toLowerCase();
                                  if (['hr', 'herr', 'hrr', 'hr.'].includes(tmpSalutation)) {
                                    tmpSalutation = TextDE.contact.contactForm.salutation_male;
                                  } else if (
                                    ['fr', 'frau', 'fra', 'fru', 'fr.'].includes(tmpSalutation)
                                  ) {
                                    tmpSalutation = TextDE.contact.contactForm.salutation_female;
                                  } else if (['fa', 'firma'].includes(tmpSalutation)) {
                                    tmpSalutation = TextDE.contact.contactForm.company;
                                  } else {
                                    tmpSalutation = TextDE.contact.contactForm.salutation_other;
                                  }
                                } else if (nameArray.length >= 2) {
                                  tmpFirstname = nameArray.shift();
                                  tmpName = nameArray.join(' ');
                                } else {
                                  tmpName = nameArray.join(' ');
                                }
                                filtered.push({
                                  inputValue: params.inputValue,
                                  uuid: 'newContact-' + Math.floor(Math.random() * 99),
                                  name: tmpName,
                                  salutation: tmpSalutation,
                                  firstname: tmpFirstname,
                                  email: '',
                                  isUser: false,
                                  phone: '',
                                  newContact: true,
                                  deleted: false,
                                });
                              }
                              return filtered;
                            }}
                            getOptionLabel={(option) => {
                              // Value selected with enter, right from the input
                              if (typeof option === 'string') {
                                return option;
                              }
                              // Add "xxx" option created dynamically
                              if (option.inputValue) {
                                return option.inputValue;
                              }
                              // Regular option
                              return `${option.salutation ?? ''} ${option.firstname ?? ''} ${
                                option.name
                              }`;
                            }}
                            disabled={this.state.form.tmp.contact_uuid?.newContact}
                          />
                        </Grid>
                        {!!this.state.form.tmp.contact_uuid?.newContact && (
                          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button variant='outlined' color='primary' onClick={this.backToSearch}>
                              {TextDE.backToSearch}
                            </Button>
                          </Grid>
                        )}
                        {this.state.form.tmp.contact_uuid?.newContact ? (
                          <Grid item xs={12}>
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                              <FormControl
                                fullWidth
                                className={classes.formControl}
                                style={{ width: '30%' }}
                              >
                                <InputLabel>{TextDE.form.salutation.label}</InputLabel>
                                <Select
                                  id='new-salutation'
                                  name='salutation'
                                  label={TextDE.form.salutation.label}
                                  value={this.state.form.tmp.contact_uuid?.salutation}
                                  onChange={this.handleContactChange}
                                >
                                  {this.state.salutations.map((value, key) => (
                                    <MenuItem key={value.salutation} value={value.salutation}>
                                      {value.salutation}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>

                              <TextField
                                id='new-firstname'
                                name='firstname'
                                label={TextDE.form.firstname.label}
                                type='text'
                                value={this.state.form.tmp.contact_uuid?.firstname}
                                disabled={
                                  this.state.form.tmp.contact_uuid?.salutation.toLowerCase() ===
                                  'firma'
                                }
                                onChange={this.handleContactChange}
                                style={{ width: '30%' }}
                              />
                              <TextField
                                id='new-name'
                                name='name'
                                label={TextDE.form.lastname.label}
                                type='text'
                                value={this.state.form.tmp.contact_uuid?.name}
                                onChange={this.handleContactChange}
                                style={{ width: '30%' }}
                              />
                            </div>
                            <Autocomplete
                              freeSolo
                              name='contact_company'
                              options={this.state.companysAll}
                              renderOption={(option) => (
                                <Typography noWrap>{option.name ?? option}</Typography>
                              )}
                              getOptionLabel={(x) => x.name ?? x}
                              defaultValue={null}
                              value={this.state.form.tmp.contact_company}
                              onChange={this.selectCompany}
                              disabled={
                                this.state.form.tmp.contact_uuid?.salutation.toLowerCase() ===
                                'firma'
                              }
                              includeInputInList={true}
                              onInputChange={this.handleChange}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant='standard'
                                  label={TextDE.form.companyname.label}
                                  placeholder={TextDE.form.companyname.placeholder}
                                  fullWidth
                                  disabled={this.state.form.salutation_id === '4'}
                                  name='contact_company'
                                />
                              )}
                            />
                            <TextField
                              id='new-email'
                              name='email'
                              label={TextDE.form.email.label}
                              placeholder={TextDE.form.email.placeholder}
                              fullWidth
                              type='email'
                              value={this.state.form.tmp.contact_uuid?.email}
                              onChange={this.handleContactChange}
                            />
                            <TextField
                              id='new-phone'
                              name='phone'
                              label={TextDE.form.phone.label}
                              placeholder={TextDE.form.phone.placeholder}
                              fullWidth
                              value={this.state.form.tmp.contact_uuid?.phone}
                              onChange={this.handleContactChange}
                            />
                          </Grid>
                        ) : null}
                        <Grid item xs={12}>
                          <Typography>{TextDE.contact.contactForm.contactTag}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <FormGroup
                            row
                            className={classes.tagsBox}
                            style={
                              !!this.state.contactAddHint
                                ? {
                                    border: '1px solid red',
                                    animation: 'blinkingErrorBackground 4s forwards',
                                  }
                                : {}
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    this.state.form.contacts.findIndex((contact) => {
                                      return (
                                        contact.tags.findIndex((tag) => {
                                          return paymentContactTags.includes(tag);
                                        }) >= 0
                                      );
                                    }) >= 0
                                  }
                                  checked={this.state.form.tmp.paymentContact}
                                  onChange={this.handleContactChange}
                                  name='paymentContact'
                                  color='primary'
                                />
                              }
                              label={Constants.tags.contacts.invoice[0]}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    this.state.form.contacts.findIndex((contact) => {
                                      return (
                                        contact.tags.findIndex((tag) => {
                                          return localContactTags.includes(tag);
                                        }) >= 0
                                      );
                                    }) >= 0
                                  }
                                  checked={this.state.form.tmp.beneficiaryContact}
                                  onChange={this.handleContactChange}
                                  name='beneficiaryContact'
                                  color='primary'
                                />
                              }
                              label={Constants.tags.contacts.resident[0]}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={false}
                                  checked={this.state.form.tmp.otherContactType}
                                  onChange={this.handleContactChange}
                                  name='otherContactType'
                                  color='primary'
                                />
                              }
                              label={TextDE.contact.contactForm.contactTagUndefinedLabel}
                            />
                          </FormGroup>
                        </Grid>
                        {!!this.state.contactAddHint && (
                          <Alert className={classes.fullWidth} severity='warning'>
                            {this.state.contactAddHint}
                          </Alert>
                        )}
                      </Grid>
                    ) : (
                      <Grid container spacing={0}>
                        {this.state.form.addresses.length > 0 && (
                          <Hidden mdUp>
                            <Grid item xs={12}>
                              <List dense={false}>
                                {this.state.form.addresses.map((item, index) => {
                                  let text = (
                                    <>
                                      <Typography variant='button'>{item.address}</Typography>
                                      {item.tags.map((chip, chipIndex) => {
                                        return (
                                          <Chip key={`address-chip-${chipIndex}`} label={chip} />
                                        );
                                      })}
                                    </>
                                  );
                                  return (
                                    <ListItem key={'address-' + index}>
                                      <ListItemAvatar>
                                        <LocationOnIcon />
                                      </ListItemAvatar>
                                      <ListItemText primary={text} />
                                      <ListItemSecondaryAction>
                                        <MinifiedStyledMenu
                                          item={item.address}
                                          callbackEdit={this.handleAddressEdit}
                                          callbackDelete={this.handleAddressDelete}
                                        />
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </Grid>
                          </Hidden>
                        )}

                        <Grid
                          item
                          xs={12}
                          className={classes.paddingTop}
                          style={{ position: 'relative' }}
                        >
                          <PlacesAutocomplete
                            value={this.state.form.tmp.address}
                            name='address'
                            onError={(e) => console.error('Places Autocomplete, onError -> ', e)}
                            onChange={this.handleAddressChange}
                            onSelect={this.handleAddressSelect}
                            searchOptions={{ types: ['address'] }}
                            shouldFetchSuggestions={this.state.form.tmp.address.length > 3}
                          >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                              return (
                                <div className={classes.container}>
                                  <Grid
                                    container
                                    direction='row'
                                    justifyContent='flex-start'
                                    alignItems='flex-end'
                                    spacing={2}
                                  >
                                    <Grid item xs={8}>
                                      <TextField
                                        fullWidth
                                        autoComplete='false'
                                        {...getInputProps({
                                          disabled:
                                            this.state.form.addresses.findIndex((address) => {
                                              return (
                                                address.tags.findIndex((tag) => {
                                                  return paymentAddressTags.includes(tag);
                                                }) >= 0
                                              );
                                            }) >= 0 &&
                                            this.state.form.addresses.findIndex((address) => {
                                              return (
                                                address.tags.findIndex((tag) => {
                                                  return localAddressTags.includes(tag);
                                                }) >= 0
                                              );
                                            }) >= 0,
                                          label: TextDE.address.label,
                                          placeholder: TextDE.address.inputPlaceholder,
                                          className: 'location-search-input',
                                        })}
                                      />
                                    </Grid>
                                    <Grid item xs={4}>
                                      {!!!this.state.form.tmp.manual_address_input ? (
                                        <Button
                                          fullWidth
                                          onClick={this.handleAddressSetManualEdit}
                                          variant='contained'
                                          color='primary'
                                        >
                                          {TextDE.procedure.addProcedureForm.addAddressManually}
                                        </Button>
                                      ) : (
                                        <Button
                                          fullWidth
                                          onClick={this.handleAddressResetManualEdit}
                                          variant='outlined'
                                          color='secondary'
                                        >
                                          {TextDE.procedure.addProcedureForm.addAddressViaSearch}
                                        </Button>
                                      )}
                                    </Grid>
                                  </Grid>

                                  {/* Somehow evaluate if the Add Address Option was clicked ... */}
                                  {suggestions.length > 0 && (
                                    <Card className={classes.results}>
                                      {loading && (
                                        <CircularProgress
                                          size={24}
                                          className={classes.buttonProgress}
                                        />
                                      )}
                                      {suggestions.map((suggestion, key) => {
                                        return (
                                          <CardContent
                                            key={key}
                                            {...getSuggestionItemProps(suggestion, {})}
                                            className={classes.result}
                                          >
                                            <Grid container alignItems='center'>
                                              <Grid item>
                                                <LocationOnIcon className={classes.icon} />
                                              </Grid>
                                              <Grid item xs>
                                                <Typography variant='subtitle1'>
                                                  {suggestion.formattedSuggestion.mainText}
                                                </Typography>
                                                <Typography variant='body1' color='textSecondary'>
                                                  {suggestion.formattedSuggestion.secondaryText}
                                                </Typography>
                                              </Grid>
                                            </Grid>
                                          </CardContent>
                                        );
                                      })}
                                    </Card>
                                  )}
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </Grid>

                        {!!this.state.form.tmp.addressError && (
                          <Grid item xs={12}>
                            <Alert className={classes.fullWidth} severity='error'>
                              {this.state.form.tmp.addressError}
                            </Alert>
                          </Grid>
                        )}

                        {!!this.state.form.tmp.manual_address_input && (
                          <>
                            <Grid item xs={12}>
                              <Typography paragraph className={classes.mt}>
                                {TextDE.procedure.addProcedureForm.addAddressLabel}:
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2}>
                                <Grid item xs={8}>
                                  <TextField
                                    value={this.state.form.tmp?.addressObject?.route}
                                    name='route'
                                    label={TextDE.address.route}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <TextField
                                    value={this.state.form.tmp?.addressObject?.street_number}
                                    name='street_number'
                                    label={TextDE.address.street_number}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <TextField
                                    value={this.state.form.tmp?.addressObject?.postal_code}
                                    name='postal_code'
                                    label={TextDE.address.postal_code}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={7}>
                                  <TextField
                                    value={this.state.form.tmp?.addressObject?.locality}
                                    name='locality'
                                    label={TextDE.address.locality}
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                                <Grid item xs={6}>
                                  <FormControl fullWidth variant='outlined'>
                                    <InputLabel
                                      id='administrative_area_level_1-label'
                                      shrink={true}
                                      variant='outlined'
                                    >
                                      {TextDE.address.administrative_area_level_1}
                                    </InputLabel>
                                    <Select
                                      labelId='administrative_area_level_1-label'
                                      id='administrative_area_level_1'
                                      name='administrative_area_level_1'
                                      label={TextDE.address.administrative_area_level_1}
                                      value={
                                        this.state.form.tmp?.addressObject
                                          ?.administrative_area_level_1
                                      }
                                      onChange={this.handleAddressDetailChange}
                                    >
                                      <MenuItem value=''> Bitte auswählen </MenuItem>
                                      <MenuItem value='Baden-Württemberg'>
                                        Baden-Württemberg
                                      </MenuItem>
                                      <MenuItem value='Bayern'> Bayern </MenuItem>
                                      <MenuItem value='Berlin'> Berlin </MenuItem>
                                      <MenuItem value='Brandenburg'> Brandenburg </MenuItem>
                                      <MenuItem value='Bremen'> Bremen </MenuItem>
                                      <MenuItem value='Hamburg'> Hamburg </MenuItem>
                                      <MenuItem value='Hessen'> Hessen </MenuItem>
                                      <MenuItem value='Mecklenburg-Vorpommern'>
                                        Mecklenburg-Vorpommern
                                      </MenuItem>
                                      <MenuItem value='Niedersachsen'> Niedersachsen </MenuItem>
                                      <MenuItem value='Nordrhein-Westfalen'>
                                        Nordrhein-Westfalen
                                      </MenuItem>
                                      <MenuItem value='Rheinland-Pfalz'> Rheinland-Pfalz </MenuItem>
                                      <MenuItem value='Saarland'> Saarland </MenuItem>
                                      <MenuItem value='Sachsen-Anhalt'> Sachsen-Anhalt </MenuItem>
                                      <MenuItem value='Sachsen'> Sachsen </MenuItem>
                                      <MenuItem value='Schleswig-Holstein'>
                                        Schleswig-Holstein
                                      </MenuItem>
                                      <MenuItem value='Thüringen'> Thüringen </MenuItem>
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                  <TextField
                                    value={'Deutschland'}
                                    name='country'
                                    label={TextDE.address.country}
                                    disabled
                                    variant='outlined'
                                    InputLabelProps={{ shrink: true }}
                                    onChange={this.handleAddressDetailChange}
                                    fullWidth
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12}>
                          <FormGroup
                            row
                            className={classes.tagsBox}
                            style={
                              !!this.state.addressAddHint
                                ? {
                                    border: '1px solid red',
                                    animation: 'blinkingErrorBackground 4s forwards',
                                  }
                                : {}
                            }
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    this.state.form.addresses.findIndex((address) => {
                                      return (
                                        address.tags.findIndex((tag) => {
                                          return paymentAddressTags.includes(tag);
                                        }) >= 0
                                      );
                                    }) >= 0
                                  }
                                  checked={this.state.form.tmp.paymentAddress}
                                  onChange={this.handleAddressChange}
                                  name='paymentAddress'
                                  color='primary'
                                />
                              }
                              label={Constants.tags.addresses.invoice[0]}
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  disabled={
                                    this.state.form.addresses.findIndex((address) => {
                                      return (
                                        address.tags.findIndex((tag) => {
                                          return localAddressTags.includes(tag);
                                        }) >= 0
                                      );
                                    }) >= 0
                                  }
                                  checked={this.state.form.tmp.beneficiaryAddress}
                                  onChange={this.handleAddressChange}
                                  name='beneficiaryAddress'
                                  color='primary'
                                />
                              }
                              label={Constants.tags.addresses.resident[0]}
                            />
                          </FormGroup>
                        </Grid>

                        {this.state.form.addresses.findIndex((address) => {
                          return (
                            address.tags.findIndex((tag) => {
                              return paymentAddressTags.includes(tag);
                            }) >= 0
                          );
                        }) >= 0 &&
                          this.state.form.addresses.findIndex((address) => {
                            return (
                              address.tags.findIndex((tag) => {
                                return localAddressTags.includes(tag);
                              }) >= 0
                            );
                          }) >= 0 && (
                            <Alert className={classes.fullWidth} severity='warning'>
                              {
                                TextDE.procedure.addProcedureForm.alerts
                                  .additionalAddressesInProcedure
                              }
                            </Alert>
                          )}

                        {!!this.state.addressAddHint && (
                          <Alert className={classes.fullWidth} severity='warning'>
                            {this.state.addressAddHint}
                          </Alert>
                        )}
                      </Grid>
                    ))}

                  {this.state.activeStep === 2 && (
                    <Grid container spacing={0}>
                      <Grid item xs={12}></Grid>
                      {this.state.form.users.length > 0 && (
                        <Hidden mdUp>
                          <Grid item xs={12}>
                            <List dense={false}>
                              {this.state.form.users.map((item, index) => {
                                let text = (
                                  <>
                                    <Typography variant='button'>{item.name}</Typography>
                                    {item.tags.map((chip, chipIndex) => {
                                      return <Chip key={`users-chip-${chipIndex}`} label={chip} />;
                                    })}
                                  </>
                                );
                                return (
                                  <ListItem key={item.uuid}>
                                    <ListItemAvatar>
                                      <Avatar alt={item.name} src={'/avatar/' + item.name} />
                                    </ListItemAvatar>
                                    <ListItemText primary={text} />
                                    <ListItemSecondaryAction>
                                      <MinifiedStyledMenu
                                        item={item.uuid}
                                        callbackEdit={this.handleUserEdit}
                                        callbackDelete={this.handleUserDelete}
                                      />
                                    </ListItemSecondaryAction>
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Grid>
                        </Hidden>
                      )}

                      <Grid item xs={12}>
                        <Autocomplete
                          id='userselect'
                          name='user_uuid'
                          options={this.state.usersAll}
                          getOptionLabel={(option) => option.displayName || option}
                          getOptionSelected={(option) => option.uuid || false}
                          defaultValue={null}
                          value={this.state.form.tmp.user_uuid}
                          onChange={this.handleUserChange}
                          renderOption={(option, state) => (
                            <CardHeader
                              key={option.uuid}
                              avatar={
                                <Avatar
                                  alt={option.displayName}
                                  src={
                                    process.env.REACT_APP_API_URL +
                                    '/avatar/' +
                                    option.uuid +
                                    '?thumb'
                                  }
                                ></Avatar>
                              }
                              className='dense'
                              titleTypographyProps={{ variant: 'body1' }}
                              title={option.displayName}
                              subheader={option.roles.map((value, key) => (
                                <Chip key={key} label={value} />
                              ))}
                            />
                          )}
                          disableClearable
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              label={TextDE.procedure.addProcedureForm.coworkerLabel}
                              helperText={TextDE.procedure.addProcedureForm.coworkerText}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          freeSolo
                          id='tags-standard'
                          name='user_tags'
                          options={this.state.usersTags}
                          getOptionLabel={(option) => option}
                          defaultValue={null}
                          value={this.state.form.tmp.user_tags}
                          onChange={this.handleUserChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='standard'
                              label={TextDE.procedure.addProcedureForm.coworkerTypeLabel}
                              placeholder={TextDE.procedure.addProcedureForm.coworkerTypeText}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Hidden smDown>
                        <Grid item sm={12}>
                          <Button
                            onClick={this.handleUserAdd}
                            startIcon={<SaveIcon />}
                            variant='contained'
                            disabled={!!!this.state.form.tmp.user_uuid}
                            className={classes.saveButton}
                          >
                            {TextDE.procedure.addProcedureForm.addAdditionalCoworkerButton}
                          </Button>
                        </Grid>
                      </Hidden>
                      <Hidden mdUp>
                        <Grid item xs={12} className={classes.mb1}>
                          <Button
                            disabled={!!!this.state.form.tmp.user_uuid}
                            className={classes.saveButton}
                            variant='contained'
                            fullWidth
                            onClick={this.handleUserAdd}
                          >
                            <SaveIcon />
                            &nbsp;{TextDE.procedure.addProcedureForm.addAdditionalCoworkerButton}
                          </Button>
                        </Grid>
                      </Hidden>
                    </Grid>
                  )}

                  {this.state.activeStep === 3 && (
                    <Grid container spacing={0}>
                      {/*
                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={"de"}>
                            <Grid item xs={12}>
                              <KeyboardDatePicker
                                emptyLabel
                                disableToolbar
                                disablePast
                                variant="inline"
                                format="DD.MM.YYYY"
                                margin="normal"
                                name="targetDate"
                                label={TextDE.calendar.targetDate}
                                value={this.state.form.targetDate}
                                onChange={this.handleTargetDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                style={{width: '100%'}}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <KeyboardDatePicker
                                emptyLabel
                                disableToolbar
                                disablePast
                                variant="inline"
                                format="DD.MM.YYYY"
                                margin="normal"
                                name="rememberDate"
                                label={TextDE.calendar.rememberDate}
                                value={this.state.form.rememberDate}
                                onChange={this.handleRememberDateChange}
                                KeyboardButtonProps={{
                                  'aria-label': 'change date',
                                }}
                                style={{width: '100%'}}
                              />
                            </Grid>
                            </MuiPickersUtilsProvider>
                          */}
                      {this.state.form.contacts.length === 0 && (
                        <Grid item xs={12}>
                          <Alert
                            severity='warning'
                            action={
                              <Button
                                color='inherit'
                                variant='outlined'
                                onClick={() => this.toStep(1)}
                              >
                                {TextDE.procedure.addProcedureForm.addEntry}
                              </Button>
                            }
                          >
                            {TextDE.procedure.addProcedureForm.alerts.contactsMissing}
                          </Alert>
                        </Grid>
                      )}
                      {this.state.form.addresses.length === 0 && (
                        <Grid item xs={12}>
                          <Alert
                            severity='warning'
                            action={
                              <Button
                                color='inherit'
                                variant='outlined'
                                onClick={() => this.toStep(1)}
                              >
                                {TextDE.procedure.addProcedureForm.addEntry}
                              </Button>
                            }
                          >
                            {TextDE.procedure.addProcedureForm.alerts.addressesMissing}
                          </Alert>
                        </Grid>
                      )}
                      {this.state.form.users.length === 0 && (
                        <Grid item xs={12}>
                          <Alert
                            severity='warning'
                            action={
                              <Button
                                color='inherit'
                                variant='outlined'
                                onClick={() => this.toStep(2)}
                              >
                                {TextDE.procedure.addProcedureForm.addEntry}
                              </Button>
                            }
                          >
                            {TextDE.procedure.addProcedureForm.alerts.usersMissing}
                          </Alert>
                        </Grid>
                      )}
                      {this.state.form.contacts.length > 0 &&
                        this.state.form.addresses.length > 0 &&
                        this.state.form.users.length > 0 && (
                          <Grid item xs={12}>
                            <Alert severity='success'>
                              {TextDE.procedure.addProcedureForm.alerts.success}
                            </Alert>
                          </Grid>
                        )}
                    </Grid>
                  )}
                </form>
              </Grid>
              {this.state.activeStep === this.steps.length - 1 && (
                <Grid item xs={12}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <Typography variant='h6' component='p' gutterBottom>
                        {TextDE.form.subject.label}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        fullWidth
                        value={this.state.form.subject}
                        onChange={this.handleSubjectChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6' component='p' gutterBottom>
                        {TextDE.procedure.addProcedureForm.descriptionLabel}:
                      </Typography>
                    </Grid>

                    {this.state.form.body.trim() === '' && !!!this.state.form.uuid ? (
                      <Alert
                        className={classes.fullWidth}
                        severity='warning'
                        action={
                          <Button color='inherit' variant='outlined' onClick={() => this.toStep(0)}>
                            {TextDE.procedure.addProcedureForm.addEntry}
                          </Button>
                        }
                      >
                        {TextDE.procedure.addProcedureForm.alerts.descriptionMissing}
                      </Alert>
                    ) : (
                      <>
                        <Grid item xs={11}>
                          <Typography variant='subtitle1' component='p' gutterBottom>
                            {this.state.form.body}
                          </Typography>
                        </Grid>
                        <Grid item xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
                          <IconButton onClick={() => this.toStep(0)}>
                            <CreateIcon />
                          </IconButton>
                        </Grid>
                      </>
                    )}

                    <Grid item xs={12}>
                      <Divider />
                      <Typography variant='h6' component='span'>
                        {TextDE.procedure.addProcedureForm.contactLabel}
                      </Typography>
                    </Grid>
                    {this.state.form.contacts.length > 0 ? (
                      <Grid item xs={12}>
                        <List dense={false}>
                          {this.state.form.contacts.map((item, index) => {
                            return (
                              <ListItem key={`contacts-${index}`}>
                                <ListItemAvatar>
                                  <Avatar alt={item.name} src={'/avatar/' + item.name} />
                                </ListItemAvatar>
                                <ListItemText
                                  disableTypography
                                  primary={
                                    <Typography variant='button'>
                                      {item.salutation} {item.firstname} {item.name}
                                    </Typography>
                                  }
                                  secondary={
                                    <div className={classes.chips}>
                                      {item.tags.map((chip, chipIndex) => {
                                        return (
                                          <Chip key={`contact-chip-${chipIndex}`} label={chip} />
                                        );
                                      })}
                                    </div>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <MinifiedStyledMenu
                                    item={item.uuid}
                                    callbackEdit={this.handleContactEdit}
                                    callbackDelete={this.handleContactDelete}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    ) : (
                      <Alert
                        className={classes.fullWidth}
                        severity='warning'
                        action={
                          <Button color='inherit' variant='outlined' onClick={() => this.toStep(1)}>
                            {TextDE.procedure.addProcedureForm.addEntry}
                          </Button>
                        }
                      >
                        {TextDE.procedure.addProcedureForm.alerts.contactsMissing}
                      </Alert>
                    )}

                    <Grid item xs={12}>
                      <Divider />
                      <Typography variant='h6' component='span'>
                        {TextDE.address.label}
                      </Typography>
                    </Grid>
                    {this.state.form.addresses.length > 0 ? (
                      <Grid item xs={12}>
                        <List dense={false}>
                          {this.state.form.addresses.map((item, index) => {
                            return (
                              <ListItem key={'address-' + index}>
                                <ListItemAvatar>
                                  <LocationOnIcon />
                                </ListItemAvatar>
                                <ListItemText
                                  disableTypography
                                  primary={<Typography variant='button'>{item.address}</Typography>}
                                  secondary={
                                    <div className={classes.chips}>
                                      {item.tags.map((chip, chipIndex) => {
                                        return (
                                          <Chip key={`address-chip-${chipIndex}`} label={chip} />
                                        );
                                      })}
                                    </div>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <MinifiedStyledMenu
                                    item={item.address}
                                    callbackEdit={this.handleAddressEdit}
                                    callbackDelete={this.handleAddressDelete}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    ) : (
                      <Alert className={classes.fullWidth} severity='warning'>
                        {TextDE.procedure.addProcedureForm.alerts.addressesMissing}
                      </Alert>
                    )}

                    <Grid item xs={12}>
                      <Divider />
                      <Typography variant='h6' component='span'>
                        {TextDE.procedure.addProcedureForm.coworkerLabel}
                      </Typography>
                    </Grid>
                    {this.state.form.users.length > 0 ? (
                      <Grid item xs={12}>
                        <List dense={false}>
                          {this.state.form.users.map((item, index) => {
                            return (
                              <ListItem key={`users-${index}`}>
                                <ListItemAvatar>
                                  <Avatar
                                    alt={item.name}
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      '/avatar/' +
                                      item.uuid +
                                      '?thumb'
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText
                                  disableTypography
                                  primary={<Typography variant='button'>{item.name}</Typography>}
                                  secondary={
                                    <div className={classes.chips}>
                                      {item.tags.map((chip, chipIndex) => {
                                        return <Chip key={`user-chip-${chipIndex}`} label={chip} />;
                                      })}
                                    </div>
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <MinifiedStyledMenu
                                    item={item.uuid}
                                    callbackEdit={this.handleUserEdit}
                                    callbackDelete={this.handleUserDelete}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    ) : (
                      <Alert
                        className={classes.fullWidth}
                        severity='warning'
                        action={
                          <Button color='inherit' variant='outlined' onClick={() => this.toStep(2)}>
                            {TextDE.procedure.addProcedureForm.addEntry}
                          </Button>
                        }
                      >
                        {TextDE.procedure.addProcedureForm.alerts.contactsMissing}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid item xs={12} className={classes.bottomFixed}>
                {this.state.activeStep === this.steps.length - 1 ? (
                  <Grid container spacing={2}>
                    {!!this.state.form.hasError && (
                      <Grid item xs={12}>
                        {this.state.form.hasError}
                      </Grid>
                    )}
                    <Grid item xs={4}>
                      <div className={classes.wrapper}>
                        <Button fullWidth onClick={this.handleBack} className={classes.button}>
                          {TextDE.back}
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.wrapper}>
                        {this.state.form.isSubmitting ? (
                          <center>
                            <CircularProgress size={24} className={classes.buttonProgress} />
                          </center>
                        ) : (
                          <Button
                            type='submit'
                            onClick={this.handleFormSubmit}
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            disabled={this.state.isSubmitting}
                            fullWidth
                          >
                            {TextDE.procedure.addProcedureForm.addButton}
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} className={classes.bottomFixed}>
                    <Grid item xs>
                      <div className={classes.wrapper}>
                        <Button
                          fullWidth
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                          variant='contained'
                        >
                          {TextDE.back}
                        </Button>
                      </div>
                    </Grid>

                    <Grid item xs>
                      <div className={classes.wrapper}>
                        <Button
                          fullWidth
                          onClick={(event) =>
                            this.isStepFailed(this.state.activeStep) && this.state.activeStep > 0
                              ? this.handleSkip(event)
                              : this.handleNext()
                          }
                          className={classes.button}
                          color={
                            this.isStepFailed(this.state.activeStep) && this.state.activeStep > 0
                              ? 'secondary'
                              : 'primary'
                          }
                          variant={
                            this.isStepFailed(this.state.activeStep) && this.state.activeStep > 0
                              ? 'outlined'
                              : 'contained'
                          }
                          disabled={
                            this.isStepFailed(this.state.activeStep) && this.state.activeStep === 0
                              ? true
                              : false
                          }
                        >
                          {this.isStepFailed(this.state.activeStep) && this.state.activeStep > 0
                            ? this.state.activeStep === 1 && !!this.state.form.tmp.contact_uuid
                              ? TextDE.procedure.addProcedureForm.addAddressButton
                              : TextDE.skip
                            : TextDE.next}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </>
    );
  }
}

// Meh
const mapStateToProps = (state, ownProps) => ({
  User: state.User,
  Authentication: state.Authentication,
  dialogData: ownProps.dialogData,
  Dimensions: state.Dimensions,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AddProcedure);
