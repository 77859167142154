import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { DropzoneArea } from 'material-ui-dropzone';
import Alert from '@material-ui/lab/Alert';
import { Constants } from '../../../lib/Constants';

import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
//import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(95vw - ' + theme.spacing(3) + 'px)',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: '60vw',
      padding: theme.spacing(2, 4, 3),
    },
    maxHeight: '95vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  floatRight: {
    float: 'right',
  },
  editor: {
    padding: theme.spacing(1, 1),
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: '#EEEEEE',
    borderWidth: '1px',
    minHeight: '200px',

    '&>div': {
      padding: theme.spacing(1, 1),
      '&>div': {
        '&>div': {
          '&>div': {
            '&>div': {
              '&>div': {
                margin: 0,
              },
            },
          },
        },
      },
    },
  },
  active: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    '&>div': {},
  },
  toolbar: {
    backgroundColor: '#EEEEEE',
  },
});

class NoteForm extends React.Component {
  constructor(props) {
    super(props);

    let editorState = null;
    if (!!props.editText) {
      let blocksFromHTML = convertFromHTML(
        props.editText.html || props.editText.text || props.editText,
      );
      editorState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap,
      );
      editorState = EditorState.createWithContent(editorState);
    } else {
      editorState = EditorState.createEmpty();
    }

    this.state = {
      subject: props.editSubject || '',
      isSubmitting: false,
      hasError: false,
      openModal: props.openModal || false,
      editorState: editorState,
      editorFocus: false,
      editUUID: props.editUUID || false,
      files: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleOpen = () => {
    this.setState({ openModal: true });
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  handleFileChange = async (files) => {
    this.setState({ files: files });
  };

  async handleFormSubmit(event) {
    event.preventDefault();
    let body = stateToHTML(this.state.editorState.getCurrentContent());
    if (body === '<p><br></p>') {
      this.setState({
        isSubmitting: false,
        hasError: 'Bitte eine Notiz eintragen!',
      });
      return false;
    }
    let subject = this.state.subject;
    if (subject.trim() === '') {
      subject = body.replace(/<[^>]*>?/gm, '');
      subject = subject.length <= 50 ? subject : subject.substr(0, 47) + '...';
    }

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('type_id', Constants.getInboxItemTypeId('note'));
    formData.append('subject', subject);
    formData.append('body', body);
    if (!!this.props.editUUID) {
      formData.append('itemuuid', this.props.editUUID);
    }

    this.state.files.forEach((file, i) => {
      formData.append('uploads[]', file, file.name);
    });

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid + '/item',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (response === 'Created') {
      this.setState({
        subject: '',
        editorState: EditorState.createEmpty(),
        to: [],
        files: [],
        isSubmitting: false,
        hasError: false,
      });
      this.props.handleClose(true);
    } else {
      this.setState({ isSubmitting: false, hasError: response });
    }
  }
  /*
	componentDidMount() {
		console.log("componentDidMount - props",  this.props);
		console.log("componentDidMount - state",  this.state);
	}

	componentDidUpdate( oldProps, oldState ) {
		console.log("componentDidUpdate - Old vs New props-", oldProps, this.props);
		console.log("componentDidUpdate - Old vs New state-", oldState, this.state);
		//console.log("componentDidUpdate -editSubject-", oldProps.editSubject, this.props.editSubject);
		//console.log("componentDidUpdate -editText-", oldProps.editText, this.props.editText);
		//console.log("componentDidUpdate -editUUID-", oldProps.editUUID, this.props.editUUID);
	}
*/

  render() {
    const { classes } = this.props;

    return (
      <>
        <Modal
          open={this.props.openModal}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.props.handleClose(false);
            }
          }}
          className={classes.modal}
        >
          <Paper className={classes.paper}>
            <Box textAlign='center'>
              <Typography variant='h4'>
                {!!this.props.editUUID ? TextDE.inbox.tabs.noteEdit : TextDE.inbox.tabs.note}
                <IconButton
                  className={classes.floatRight}
                  onClick={() => this.props.handleClose(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Typography>
            </Box>

            {this.state.isSubmitting ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <form key='noteform' onSubmit={this.handleFormSubmit}>
                <Grid
                  container
                  spacing={0}
                  style={{
                    width: 'calc(98% - 10px)',
                    marginLeft: 'calc(1% + 5px)',
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      name='subject'
                      label={TextDE.form.subject.label}
                      autoComplete='subject'
                      value={this.state.subject}
                      onChange={this.handleChange()}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      editorState={this.state.editorState}
                      onEditorStateChange={this.onEditorStateChange}
                      wrapperClassName='wrapper-class'
                      editorClassName={clsx(classes.editor, {
                        [classes.active]: this.state.editorFocus,
                      })}
                      toolbarClassName={classes.toolbar}
                      editorRef={this.setEditorReference}
                      onFocus={(event) => {
                        this.setState({ editorFocus: true });
                      }}
                      onBlur={(event) => {
                        this.setState({ editorFocus: false });
                      }}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'emoji', 'link'],
                        inline: {
                          options: ['bold', 'italic', 'underline'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                          strikethrough: {
                            className: 'bordered-option-classname',
                          },
                        },
                        blockType: {
                          options: ['Normal', 'H1'],
                          className: 'bordered-option-classname',
                        },
                        fontSize: {
                          options: [10, 12, 16, 20],
                          className: 'bordered-option-classname',
                        },
                        fontFamily: {
                          className: 'bordered-option-classname',
                        },
                        link: {
                          className: 'bordered-option-classname',
                        },
                        emoji: {
                          className: 'bordered-option-classname',
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Hidden smDown>
                      <DropzoneArea
                        dropzoneText='Datei hier ablegen, oder klicken.'
                        showPreviewsInDropzone={true}
                        useChipsForPreview={true}
                        showFileNames={true}
                        showAlerts={true}
                        onChange={this.handleFileChange}
                        maxFileSize={30000000}
                        filesLimit={8}
                      />
                    </Hidden>
                    <Hidden mdUp>
                      <DropzoneArea
                        dropzoneText='Zum auswählen hier klicken.'
                        showPreviewsInDropzone={true}
                        useChipsForPreview={true}
                        showFileNames={true}
                        showAlerts={true}
                        onChange={this.handleFileChange}
                        maxFileSize={30000000}
                        filesLimit={8}
                        dropzoneClass='small'
                      />
                    </Hidden>
                  </Grid>

                  <Grid item xs={4}>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={() => this.props.handleClose(false)}
                        variant='outlined'
                        color='secondary'
                        fullWidth
                      >
                        Schließen
                      </Button>
                    </div>
                  </Grid>

                  <Grid item xs={8}>
                    {!!this.state.hasError ? (
                      <Alert severity='error' style={{ width: '100%' }}>
                        Die Notiz konnte nicht erstellt werden: {this.state.hasError.message}{' '}
                      </Alert>
                    ) : (
                      ''
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        disabled={this.state.isSubmitting}
                        fullWidth
                      >
                        Speichern
                      </Button>
                      {this.state.isSubmitting && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NoteForm);
