import React from 'react';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import renderHtml from '../../../../lib/transformHtml';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { readableTime } from '../../../../lib/dateFunctions';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import MailIcon from '@material-ui/icons/Mail';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SubjectIcon from '@material-ui/icons/Subject';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import EditIcon from '@material-ui/icons/Edit';
import PhotoSizeSelectSmallIcon from '@material-ui/icons/PhotoSizeSelectSmall';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import DeleteIcon from '@material-ui/icons/Delete';
import { DE as TextDE } from '../../../../lib/Text';
const styles = (theme) => ({
  gridExtended: {
    zIndex: 1,
    transition: 'z-index 33ms',
  },
  gridNotExtended: {
    zIndex: 0,
    transition: 'z-index 400ms',
  },
  cardRoot: {
    float: 'right',
    width: 'calc(100% - 2px)',
    backgroundColor: '#fffcdd',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      transition: 'width 400ms, margin-left 400ms',
    },
  },
  cardRootExtended: {
    float: 'right',
    backgroundColor: '#fffcdd',
    boxShadow: theme.shadows[16],
    [theme.breakpoints.down('sm')]: {
      transition: 'height 233ms',
      width: 'calc(100vw - 16px)',
    },
    [theme.breakpoints.up('md')]: {
      transition: 'width 400ms, margin-left 400ms',
      width: 'calc(110% - 2px)',
      marginLeft: '-10%',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    [theme.breakpoints.up('md')]: {
      minWidth: theme.spacing(7),
      minHeight: theme.spacing(7),
      '&>svg': {
        minWidth: theme.spacing(5),
        minHeight: theme.spacing(5),
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  paper: {
    minHeight: '40px',
    height: 'auto',
    overflow: 'auto',
    fontSize: '120%',
    borderTop: '1px solid #efefef',
    backgroundColor: '#ffffff',
    flexGrow: 1,
  },
  paperSmall: {
    //minHeight: '40px',
    height: '68px',
    //maxHeight: '108px',
    overflow: 'clip',
    cursor: 'pointer',
    fontSize: '120%',
    padding: theme.spacing(1, 2) + '!important',
    borderTop: '1px solid #efefef',
    backgroundColor: '#ffffff',
    flexGrow: 1,
  },
  emailLable: {
    padding: theme.spacing(0, 1),
  },
  emailAttachmentButton: {
    marginRight: theme.spacing(2),
    maxWidth: '24em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&>span': {
      '&>span:first-child': {
        maxWidth: 'calc(24em - 46px)',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  },
});

const respondFunction = 1;
const editFunction = 2;
const deleteFunction = 3;

class TestMail extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openMenu: false, expanded: false };
  }

  toggleExpand = () => {
    this.setState({ expanded: !this.state.expanded, openMenu: false }, () => {
      if (!!this.state.expanded) {
        this.props.expandScrollTo();
      }
    });
  };

  handleOpen = (event) => {
    this.setState({ openMenu: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ openMenu: false });
  };

  callback(type) {
    this.handleClose();
    if (type === editFunction) {
      this.props.editFunction(this.props.item);
    }
    if (type === respondFunction) {
      this.props.respondFunction(this.props.item);
    }
    if (type === deleteFunction) {
      this.props.deleteFunction(this.props.item);
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid
        key={'note-list-' + this.props.index}
        item
        xs={12}
        md={10}
        classes={{ root: this.state.expanded ? classes.gridExtended : classes.gridNotExtended }}
      >
        <Card
          raised
          classes={{ root: this.state.expanded ? classes.cardRootExtended : classes.cardRoot }}
        >
          <Grid
            container
            spacing={0}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Grid item xs={12}>
              <Box display='flex' flexDirection='row'>
                <Box p={1} flexShrink={0}>
                  <Tooltip
                    title={
                      !!this.props.item.creator
                        ? '{TextDE.procedure.protocol.mail.senderLabel}: ' +
                          this.props.item.creator?.firstname +
                          ' ' +
                          this.props.item.creator?.lastname
                        : TextDE.procedure.protocol.mail.mailLabel
                    }
                  >
                    <Avatar
                      className={classes.avatar}
                      alt={
                        !!this.props.item.creator
                          ? this.props.item.creator?.firstname +
                            ' ' +
                            this.props.item.creator?.lastname
                          : TextDE.procedure.protocol.mail.mailLabel
                      }
                      src={
                        process.env.REACT_APP_API_URL +
                        '/avatar/' +
                        this.props.item.creator?.uuid +
                        '?thumb'
                      }
                    >
                      <MailIcon />
                    </Avatar>
                  </Tooltip>
                </Box>
                <Box width='100%'>
                  <List dense={true} disablePadding={true} aria-label='main mail information'>
                    {this.props.item.created_by !== null ? (
                      <Tooltip
                        title={TextDE.procedure.protocol.mail.receiverLabel}
                        aria-label='serial'
                      >
                        <ListItem key={this.props.item.id + '-to'}>
                          <ListItemIcon>
                            <AlternateEmailIcon />
                            <Hidden mdDown>
                              <Typography className={classes.emailLable} variant='subtitle2'>
                                {TextDE.procedure.protocol.mail.receiverLabel}
                              </Typography>
                            </Hidden>
                          </ListItemIcon>
                          <ListItemText
                            key={this.props.item.id + '-to-text'}
                            primary={
                              !!this.props.item.data?.to &&
                              !!this.props.item.data?.to[0] &&
                              !!this.props.item.data?.to[0].personal
                                ? this.props.item.data?.to[0].personal
                                : 'N/A'
                            }
                            secondary={
                              !!this.props.item.data?.to &&
                              !!this.props.item.data?.to[0] &&
                              `${
                                this.props.item.data?.to[0].email ||
                                this.props.item.data?.to[0].mail ||
                                'N/A'
                              }`
                            }
                          />
                        </ListItem>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        title={TextDE.procedure.protocol.mail.senderLabel}
                        aria-label='serial'
                      >
                        <ListItem key={this.props.item.id + '-from'}>
                          <ListItemIcon>
                            <ContactMailIcon />
                            <Hidden mdDown>
                              <Typography className={classes.emailLable} variant='subtitle2'>
                                {TextDE.procedure.protocol.mail.senderLabel}
                              </Typography>
                            </Hidden>
                          </ListItemIcon>
                          <ListItemText
                            key={this.props.item.id + '-from-text'}
                            primary={
                              !!this.props.item.data?.from &&
                              !!this.props.item.data?.from[0] &&
                              !!this.props.item.data?.from[0].personal
                                ? this.props.item.data?.from[0].personal
                                : 'N/A'
                            }
                            secondary={
                              !!this.props.item.data?.from &&
                              !!this.props.item.data?.from[0] &&
                              !!this.props.item.data?.from[0].mail
                                ? this.props.item.data?.from[0].mail
                                : 'N/A'
                            }
                          />
                        </ListItem>
                      </Tooltip>
                    )}
                    {this.props.item.data?.cc?.length >= 1 && (
                      <Collapse in={this.state.expanded}>
                        <ListItem key={this.props.item.id + '-cc'}>
                          <ListItemIcon>
                            <Tooltip
                              title={TextDE.procedure.protocol.mail.ccLabel}
                              aria-label={TextDE.procedure.protocol.mail.ccLabel}
                            >
                              <SupervisorAccountIcon />
                            </Tooltip>
                          </ListItemIcon>
                          <ListItemText
                            key={this.props.item.id + '-cc-text'}
                            primary={this.props.item.data?.cc.map((item, index) => {
                              return (
                                <Chip
                                  key={this.props.item.id + '-chip-' + index}
                                  className={classes.chip}
                                  label={`<${item.personal || item.email || item.mail}>`}
                                />
                              );
                            })}
                          />
                        </ListItem>
                      </Collapse>
                    )}
                  </List>
                </Box>
                <Box p={1} flexShrink={0}>
                  <Hidden mdDown>
                    <Button
                      onClick={this.handleOpen}
                      fullWidth
                      variant='outlined'
                      color='primary'
                      startIcon={<MoreHorizIcon />}
                    >
                      {TextDE.procedure.protocol.more}
                    </Button>
                  </Hidden>
                  <Hidden lgUp>
                    <IconButton color='primary' aria-label='more' onClick={this.handleOpen}>
                      <MoreHorizIcon />
                    </IconButton>
                  </Hidden>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <List dense={true} disablePadding={true} aria-label='main mailbox folders'>
                <ListItem
                  key={this.props.item.id + '-date'}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
                  <ListItemText
                    key={this.props.item.id + '-date-text'}
                    style={{ marginTop: 0, marginBottom: 0 }}
                    primary={
                      (this.props.item.created_by !== null ? 'Versandt um ' : 'Empfangen um ') +
                      readableTime(this.props.item.created_at)
                    }
                    primaryTypographyProps={{ variant: 'subtitle2', color: 'textSecondary' }}
                  />
                </ListItem>
                {this.props.item.data?.subject && (
                  <ListItem key={this.props.item.id + '-subject'}>
                    <ListItemIcon>
                      <Tooltip
                        title='{TextDE.procedure.protocol.mail.subjectLabel}'
                        aria-label='subject'
                      >
                        <SubjectIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      key={this.props.item.id + '-subject-text'}
                      primary={this.props.item.data?.subject}
                    />
                  </ListItem>
                )}
                {!!this.props.item.data?.files && (
                  <Collapse in={this.state.expanded}>
                    <ListItem key={this.props.item.id + '-files'}>
                      <ListItemIcon>
                        <Tooltip
                          title={TextDE.procedure.protocol.mail.attachmentLabel}
                          aria-label='attachments'
                        >
                          <AttachFileIcon />
                        </Tooltip>
                      </ListItemIcon>

                      {!!this.props.item.data?.files?.media
                        ? this.props.item.data?.files?.media.map((value, index) => (
                            <Button
                              key={this.props.item.uuid + '-attachment-link-' + index}
                              color='primary'
                              variant='outlined'
                              onClick={() => this.props.switchToFile(value)}
                              aria-label={value.file_name}
                              size='small'
                              endIcon={<OpenInNewIcon />}
                              className={classes.emailAttachmentButton}
                            >
                              <Typography variant='button'>{value.file_name} </Typography>
                            </Button>
                          ))
                        : !!this.props.item.data?.files?.text}
                    </ListItem>
                  </Collapse>
                )}
              </List>
            </Grid>
          </Grid>
          <Menu
            id='simple-menu'
            anchorEl={this.state.openMenu}
            open={Boolean(this.state.openMenu)}
            onClose={this.handleClose}
          >
            <MenuItem key={this.props.item.id + '-expandFunction'} onClick={this.toggleExpand}>
              <ListItemIcon>
                {this.state.expanded ? <PhotoSizeSelectSmallIcon /> : <AspectRatioIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  this.state.expanded
                    ? TextDE.procedure.protocol.collapse
                    : TextDE.procedure.protocol.expand
                }
              />
            </MenuItem>
            {!!this.props.editFunction && (
              <MenuItem
                key={this.props.item.id + '-editFunction'}
                onClick={() => this.callback(editFunction)}
              >
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={TextDE.procedure.protocol.edit} />
              </MenuItem>
            )}
            {!!this.props.respondFunction && (
              <MenuItem
                key={this.props.item.id + '-respondFunction'}
                onClick={() => this.callback(respondFunction)}
              >
                <ListItemIcon>
                  <ReplyAllIcon />
                </ListItemIcon>
                <ListItemText primary={TextDE.procedure.protocol.respond} />
              </MenuItem>
            )}
            {!!this.props.deleteFunction && (
              <MenuItem
                key={this.props.item.id + '-deleteFunction'}
                onClick={() => this.callback(deleteFunction)}
              >
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText primary={TextDE.delete} />
              </MenuItem>
            )}
          </Menu>
          <CardContent
            onClick={() => !!!this.state.expanded && this.toggleExpand()}
            className={!!this.state.expanded ? classes.paper : classes.paperSmall}
          >
            {renderHtml(
              this.props.item.data?.body,
              this.state.expanded,
              this.toggleExpand,
              false,
              this.state.expanded,
            )}
          </CardContent>
          {this.state.expanded && (
            <CardActions disableSpacing={false}>
              <Button
                onClick={this.toggleExpand}
                fullWidth
                startIcon={<KeyboardArrowUpIcon />}
                endIcon={<KeyboardArrowUpIcon />}
              >
                {TextDE.procedure.protocol.collapse}
              </Button>
            </CardActions>
          )}
        </Card>
      </Grid>
    );
  }
}

export default compose(withStyles(styles))(TestMail);
