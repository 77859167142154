import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import Alert from '@material-ui/lab/Alert';
//function propertiesToArray(obj) {
//    const isObject = val =>
//        typeof val === 'object' && !Array.isArray(val);
//
//    const addDelimiter = (a, b) =>
//        a ? `${a}.${b}` : b;
//
//    const paths = (obj = {}, head = '') => {
//        return Object.entries(obj)
//            .reduce((product, [key, value]) =>
//                {
//                    let fullPath = addDelimiter(head, key)
//                    return isObject(value) ?
//                        product.concat(paths(value, fullPath))
//                    : product.concat(fullPath)
//                }, []);
//    }
//
//    return paths(obj);
//}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  formLabel: {
    margin: theme.spacing(2),
    width: '100%',
  },
  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  pullUp: {
    marginTop: theme.spacing(-1),
    padding: theme.spacing(0, 1),
  },
  paddingRight: {
    paddingRight: '96px',
    paddingTop: 0,
    paddingBottom: 0,
  },
  paddingRightLast: {
    paddingRight: '48px',
  },
  overflow: {
    maxWidth: '90%',
    maxHeight: '100%',
    overflow: 'auto',
  },
  flex: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    display: 'flex',
  },
  grow: {
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(1),
    },
    flexGrow: '1',
  },
  shrink: {
    [theme.breakpoints.down('md')]: {
      marginLeft: 'auto',
      maxWidth: '15em',
    },
    flexShrink: '1',
    marginLeft: theme.spacing(1),
  },
});

class TodoGenerator extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    if (!!this.state.new && this.state.name.trim() === '') {
      this.setState({ hasError: 'Es fehlt der Name der Liste!' });
      return;
    }
    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'POST');
    formData.append('items', JSON.stringify(this.state.items));
    formData.append('name', this.state.name);
    formData.append('new', this.state.new);

    if (Number.isInteger(parseInt(this.state.id))) {
      formData.append('id', this.state.lists[this.state.id].id);
    }

    let response = await fetch(process.env.REACT_APP_API_URL + '/api/todo/templates', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => json);

    if (!!response) {
      let lists = [];
      for (let i = response.length - 1; i >= 0; i--) {
        let todos = [];
        response[i].items.forEach((todo) => {
          if (!todo.parent_todo_id) {
            return todos.push(todo);
          }
          const parentIndex = response[i].items.findIndex((el) => el.id === todo.parent_todo_id);

          if (!response[i].items[parentIndex].children) {
            return (response[i].items[parentIndex].children = [todo]);
          }

          response[i].items[parentIndex].children.push(todo);
        });

        //let foundIndex = lists.findIndex(element => element.id === response[i].id)
        //if (foundIndex >= 0) {
        //	lists[foundIndex] = {name: response[i].name, id: response[i].id, items: todos};
        //} else {
        //}
        lists.push({
          name: response[i].name,
          id: response[i].id,
          items: todos,
        });
      }

      this.setState({
        name: '',
        items: [],
        new: null,
        id: '',
        isSubmitting: false,
        hasError: false,
        lists: lists.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        }),
      });
      //this.fetchList()
    }
  }

  handleChange = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else if (event.target.name === 'id') {
      if (event.target.value === 'new') {
        this.setState({
          id: 'new',
          name: '',
          eta: 0,
          items: [{ description: '', eta: 0, saved: false, children: [] }],
          new: true,
        });
      } else {
        let sum = 0;
        let test = JSON.stringify(this.state.lists[event.target.value].items);
        let matches = test.matchAll(/"eta":"?(\d+)"?/gim);
        for (const match of matches) {
          sum = sum + Number(match[1]);
        }

        this.setState({
          id: event.target.value,
          name: this.state.lists[event.target.value].name,
          items: this.state.lists[event.target.value].items,
          new: false,
          eta: sum,
        });
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleTodoChange = (event, key, key2 = -1, key3 = -1, key4 = -1, key5 = -1) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`, key);

    let tmp = this.state.items;
    if (key5 >= 0) {
      tmp[key].children[key2].children[key3].children[key4].children[key5][event.target.name] =
        event.target.value;
    } else if (key4 >= 0) {
      tmp[key].children[key2].children[key3].children[key4][event.target.name] = event.target.value;
    } else if (key3 >= 0) {
      tmp[key].children[key2].children[key3][event.target.name] = event.target.value;
    } else if (key2 >= 0) {
      tmp[key].children[key2][event.target.name] = event.target.value;
    } else if (key >= 0) {
      tmp[key][event.target.name] = event.target.value;
    }

    if (event.target.name === 'eta') {
      let sum = 0;
      let test = JSON.stringify(tmp);
      let matches = test.matchAll(/"eta":"?(\d+)"?/gim);
      for (const match of matches) {
        sum = sum + Number(match[1]);
      }

      this.setState({ tmp, eta: sum });
    } else {
      this.setState({ tmp });
    }
  };

  appendChild = (event, key, key2 = -1, key3 = -1, key4 = -1) => {
    //console.log(`Add Child: ${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`, key, !!key, key2, !!key2, key3, !!key3, key4, !!key4);
    let tmp = this.state.items;

    /*
		let newId = tmp.length;
		tmp.forEach( (level0,key0) => {
			newId += level0.children.length;
			level0.children.forEach( (level1,key1) => {
				newId += level1.children.length;
				level1.children.forEach( (level2,key2) => {
					newId += level2.children.length;
					level2.children.forEach( (level3,key3) => {
						newId += level3.children.length;
						level3.children.forEach( (level4,key4) => {
							newId += level4.children.length;
							level4.children.forEach( (level5,key5) => {
								newId += level5.children.length;
							})
						})
					})
				})
			})
		})
		*/

    if (key4 >= 0) {
      //tmp[key].children[key2].children[key3].children[key4].children.push({description: '', eta: 0, id: newId, parent: tmp[key].children[key2].children[key3].children[key4].id , saved: false, children: []})
      if (!!!tmp[key].children[key2].children[key3].children[key4].children) {
        tmp[key].children[key2].children[key3].children[key4].children = [];
      }
      tmp[key].children[key2].children[key3].children[key4].children.push({
        description: '',
        eta: 0,
        saved: false,
        children: [],
      });
    } else if (key3 >= 0) {
      //tmp[key].children[key2].children[key3].children.push({description: '', eta: 0, id: newId, parent: tmp[key].children[key2].children[key3].id , saved: false, children: []})
      if (!!!tmp[key].children[key2].children[key3].children) {
        tmp[key].children[key2].children[key3].children = [];
      }
      tmp[key].children[key2].children[key3].children.push({
        description: '',
        eta: 0,
        saved: false,
        children: [],
      });
    } else if (key2 >= 0) {
      //tmp[key].children[key2].children.push({description: '', eta: 0, id: newId, parent: tmp[key].children[key2].id , saved: false, children: []})
      if (!!!tmp[key].children[key2].children) {
        tmp[key].children[key2].children = [];
      }
      tmp[key].children[key2].children.push({
        description: '',
        eta: 0,
        saved: false,
        children: [],
      });
    } else if (key >= 0) {
      //tmp[key].children.push({description: '', eta: 0, id: newId, parent: tmp[key].id , saved: false, children: []})
      if (!!!tmp[key].children) {
        tmp[key].children = [];
      }
      tmp[key].children.push({
        description: '',
        eta: 0,
        saved: false,
        children: [],
      });
    }
    this.setState({ ...this.state, items: tmp });
  };

  appendTodo = (event, key) => {
    //console.log(`Add Todo: ${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`, key);
    let tmp = this.state.items;

    /*
		let newId = tmp.length;
		tmp.forEach( (level0,key0) => {
			newId += level0.children.length;
			level0.children.forEach( (level1,key1) => {
				newId += level1.children.length;
				level1.children.forEach( (level2,key2) => {
					newId += level2.children.length;
					level2.children.forEach( (level3,key3) => {
						newId += level3.children.length;
						level3.children.forEach( (level4,key4) => {
							newId += level4.children.length;
							level4.children.forEach( (level5,key5) => {
								newId += level5.children.length;
							})
						})
					})
				})
			})
		})
		tmp.push({description: '', eta: 0, id: newId, saved: false, children: []})
		*/
    tmp.push({ description: '', eta: 0, saved: false, children: [] });
    this.setState({ ...this.state, items: tmp });
  };

  deleteTodo = (event, key, key2 = -1, key3 = -1, key4 = -1, key5 = -1) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`, key);

    let tmp = this.state.items;
    if (key5 >= 0) {
      delete tmp[key].children[key2].children[key3].children[key4].children[key5];
    } else if (key4 >= 0) {
      delete tmp[key].children[key2].children[key3].children[key4];
    } else if (key3 >= 0) {
      delete tmp[key].children[key2].children[key3];
    } else if (key2 >= 0) {
      delete tmp[key].children[key2];
    } else if (key >= 0) {
      delete tmp[key];
    }

    if (tmp.length === 1 && typeof tmp[tmp.length] === 'undefined') {
      this.setState({
        items: [{ description: '', eta: 0, children: [], saved: false }],
        eta: 0,
      });
    } else {
      this.setState({ tmp });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      name: '',
      items: [{ description: '', eta: 0, children: [], saved: false }],
      eta: 0,
      new: null,
      id: '',
      lists: [],
      template: false,
      isSubmitting: false,
      hasError: false,
    };

    this.handleTodoChange = this.handleTodoChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.fetchList = this.fetchList.bind(this);
  }

  async fetchList() {
    await fetch(process.env.REACT_APP_API_URL + '/api/todo/templates', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        let lists = [];
        //console.log(response);
        for (let i = response.length - 1; i >= 0; i--) {
          let todos = [];
          let done = [];
          response[i].items.forEach((todo) => {
            if (done.indexOf(todo.id) === -1) {
              if (!todo.parent_todo_id) {
                done.push(todo.id);
                return todos.push(todo);
              }
              const parentIndex = response[i].items.findIndex(
                (el) => el.id === todo.parent_todo_id,
              );

              if (!response[i].items[parentIndex].children) {
                done.push(todo.id);
                return (response[i].items[parentIndex].children = [todo]);
              }
              done.push(todo.id);
              response[i].items[parentIndex].children.push(todo);
            }
          });
          //console.log(done);
          lists.push({
            name: response[i].name,
            id: response[i].id,
            items: todos,
          });
        }
        //console.log(lists);
        let sortedList = lists.sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        this.setState({ lists: sortedList });
      });
  }
  componentDidMount() {
    this.fetchList();
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Paper className={classes.root} square>
          <Typography variant='h5' display='inline' align='left' gutterBottom>
            <IconButton disabled color='primary'>
              <PlaylistAddCheckIcon fontSize='inherit' />
            </IconButton>
            Todo's
          </Typography>
          <Grid
            container
            spacing={1}
            direction='row'
            justifyContent='flex-start'
            alignItems='flex-start'
          >
            <Grid item xs={6}>
              <FormControl className={classes.formControl}>
                <InputLabel>Todo Listen</InputLabel>

                <Select name='id' value={this.state.id} onChange={this.handleChange}>
                  {this.state.lists.map((value, key) => {
                    return (
                      <MenuItem key={'list-' + value.id} value={key}>
                        {value.name}
                      </MenuItem>
                    );
                  })}

                  <MenuItem key={'append-new-999'} value='new'>
                    Neu
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {Number.isInteger(parseInt(this.state.id)) ? (
              <Grid item xs={6}>
                <FormControlLabel
                  className={classes.formLabel}
                  onClick={this.handleChange}
                  control={
                    <Checkbox
                      checked={!!this.state.new}
                      color='secondary'
                      name='new'
                      value={this.state.new}
                    />
                  }
                  label={<Typography variant='body1'>Als Neue Vorlage speichern?</Typography>}
                />
              </Grid>
            ) : null}
          </Grid>
          <form key='configureProcedure' onSubmit={this.handleFormSubmit}>
            <Grid container spacing={0}>
              {this.state.new ? (
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name='name'
                    type='text'
                    onChange={this.handleChange}
                    value={this.state.name}
                    margin='normal'
                    label='Name der Liste'
                    variant='filled'
                  />
                  <Divider />
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <List key={'parent-0'}>
                  {this.state.items.map((value, key) => {
                    return (
                      <span key={key}>
                        <ListItem
                          dense
                          className={classes.paddingRight}
                          key={'item-' + key + '-' + value.id}
                        >
                          <ListItemIcon>
                            <Checkbox edge='start' checked={false} tabIndex={-1} disabled />
                          </ListItemIcon>

                          <ListItemText
                            disableTypography={true}
                            className={classes.flex}
                            primary={
                              <>
                                <TextField
                                  size='small'
                                  className={classes.grow}
                                  name='description'
                                  required={true}
                                  onChange={(event) => this.handleTodoChange(event, key)}
                                  value={this.state.items[key].description}
                                  error={!(this.state.items[key].description.length > 0)}
                                  label='Aufgabe'
                                  variant='outlined'
                                />
                                <TextField
                                  className={classes.shrink}
                                  name='eta'
                                  type='number'
                                  inputProps={{ min: 0 }}
                                  onChange={(event) => this.handleTodoChange(event, key)}
                                  value={this.state.items[key].eta}
                                  error={!(this.state.items[key].eta > 0)}
                                  label='Geplante Zeit'
                                  variant='outlined'
                                  size='small'
                                  InputLabelProps={{ shrink: true }}
                                />
                              </>
                            }
                          />

                          <ListItemSecondaryAction>
                            <IconButton
                              onClick={(event) => this.appendChild(event, key)}
                              edge='end'
                              aria-label='add item'
                            >
                              <AddIcon />
                            </IconButton>
                            <IconButton
                              onClick={(event) => this.deleteTodo(event, key)}
                              aria-label='delete item'
                            >
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {value.children !== undefined && value.children.length > 0 ? (
                          <List key={'parent-' + key + '-' + value.id}>
                            {value.children.map((val2, key2) => (
                              <span key={key + '-' + key2}>
                                <ListItem
                                  dense
                                  className={classes.paddingRight}
                                  key={'item-' + key + '-' + key2 + '-' + val2.id}
                                >
                                  <ListItemIcon style={{ paddingLeft: '30px' }}>
                                    <Checkbox edge='start' checked={false} disabled />
                                  </ListItemIcon>

                                  <ListItemText
                                    disableTypography={true}
                                    className={classes.flex}
                                    primary={
                                      <>
                                        <TextField
                                          size='small'
                                          className={classes.grow}
                                          name='description'
                                          required={true}
                                          onChange={(event) =>
                                            this.handleTodoChange(event, key, key2)
                                          }
                                          value={this.state.items[key].children[key2].description}
                                          error={
                                            !(
                                              this.state.items[key].children[key2].description
                                                .length > 0
                                            )
                                          }
                                          label='Aufgabe'
                                          variant='outlined'
                                        />
                                        <TextField
                                          className={classes.shrink}
                                          name='eta'
                                          type='number'
                                          inputProps={{ min: 0 }}
                                          onChange={(event) =>
                                            this.handleTodoChange(event, key, key2)
                                          }
                                          value={this.state.items[key].children[key2].eta}
                                          error={!(this.state.items[key].children[key2].eta > 0)}
                                          label='Geplante Zeit'
                                          variant='outlined'
                                          size='small'
                                          InputLabelProps={{ shrink: true }}
                                        />
                                      </>
                                    }
                                  />

                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={(event) => this.appendChild(event, key, key2)}
                                      edge='end'
                                      aria-label='add item'
                                    >
                                      <AddIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={(event) => this.deleteTodo(event, key, key2)}
                                      aria-label='delete item'
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                                {val2.children !== undefined && val2.children.length > 0 ? (
                                  <List key={'parent-' + key + '-' + key2 + '-' + val2.id}>
                                    {val2.children.map((val3, key3) => (
                                      <span key={key + '-' + key2 + '-' + key3}>
                                        <ListItem
                                          dense
                                          className={classes.paddingRight}
                                          key={
                                            'item-' + key + '-' + key2 + '-' + key3 + '-' + val3.id
                                          }
                                        >
                                          <ListItemIcon style={{ paddingLeft: '60px' }}>
                                            <Checkbox edge='start' checked={false} disabled />
                                          </ListItemIcon>

                                          <ListItemText
                                            disableTypography={true}
                                            className={classes.flex}
                                            primary={
                                              <>
                                                <TextField
                                                  size='small'
                                                  className={classes.grow}
                                                  name='description'
                                                  required={true}
                                                  onChange={(event) =>
                                                    this.handleTodoChange(event, key, key2, key3)
                                                  }
                                                  value={
                                                    this.state.items[key].children[key2].children[
                                                      key3
                                                    ].description
                                                  }
                                                  error={
                                                    !(
                                                      this.state.items[key].children[key2].children[
                                                        key3
                                                      ].description.length > 0
                                                    )
                                                  }
                                                  label='Aufgabe'
                                                  variant='outlined'
                                                />
                                                <TextField
                                                  className={classes.shrink}
                                                  name='eta'
                                                  type='number'
                                                  inputProps={{ min: 0 }}
                                                  onChange={(event) =>
                                                    this.handleTodoChange(event, key, key2, key3)
                                                  }
                                                  value={
                                                    this.state.items[key].children[key2].children[
                                                      key3
                                                    ].eta
                                                  }
                                                  error={
                                                    !(
                                                      this.state.items[key].children[key2].children[
                                                        key3
                                                      ].eta > 0
                                                    )
                                                  }
                                                  label='Geplante Zeit'
                                                  variant='outlined'
                                                  size='small'
                                                  InputLabelProps={{
                                                    shrink: true,
                                                  }}
                                                />
                                              </>
                                            }
                                          />

                                          <ListItemSecondaryAction>
                                            <IconButton
                                              onClick={(event) =>
                                                this.appendChild(event, key, key2, key3)
                                              }
                                              edge='end'
                                              aria-label='add item'
                                            >
                                              <AddIcon />
                                            </IconButton>
                                            <IconButton
                                              onClick={(event) =>
                                                this.deleteTodo(event, key, key2, key3)
                                              }
                                              aria-label='delete item'
                                            >
                                              <DeleteIcon />
                                            </IconButton>
                                          </ListItemSecondaryAction>
                                        </ListItem>
                                        {val3.children !== undefined && val3.children.length > 0 ? (
                                          <List
                                            key={
                                              'parent-' +
                                              key +
                                              '-' +
                                              key2 +
                                              '-' +
                                              key3 +
                                              '-' +
                                              val3.id
                                            }
                                          >
                                            {val3.children.map((val4, key4) => (
                                              <span
                                                key={key + '-' + key2 + '-' + key3 + '-' + key4}
                                              >
                                                <ListItem
                                                  dense
                                                  className={classes.paddingRight}
                                                  key={
                                                    'item-' +
                                                    key +
                                                    '-' +
                                                    key2 +
                                                    '-' +
                                                    key3 +
                                                    '-' +
                                                    key4 +
                                                    '-' +
                                                    val4.id
                                                  }
                                                >
                                                  <ListItemIcon
                                                    style={{
                                                      paddingLeft: '90px',
                                                    }}
                                                  >
                                                    <Checkbox
                                                      edge='start'
                                                      checked={false}
                                                      disabled
                                                    />
                                                  </ListItemIcon>

                                                  <ListItemText
                                                    disableTypography={true}
                                                    className={classes.flex}
                                                    primary={
                                                      <>
                                                        <TextField
                                                          size='small'
                                                          className={classes.grow}
                                                          name='description'
                                                          required={true}
                                                          onChange={(event) =>
                                                            this.handleTodoChange(
                                                              event,
                                                              key,
                                                              key2,
                                                              key3,
                                                              key4,
                                                            )
                                                          }
                                                          value={
                                                            this.state.items[key].children[key2]
                                                              .children[key3].children[key4]
                                                              .description
                                                          }
                                                          error={
                                                            !(
                                                              this.state.items[key].children[key2]
                                                                .children[key3].children[key4]
                                                                .description.length > 0
                                                            )
                                                          }
                                                          label='Aufgabe'
                                                          variant='outlined'
                                                        />
                                                        <TextField
                                                          className={classes.shrink}
                                                          name='eta'
                                                          type='number'
                                                          inputProps={{
                                                            min: 0,
                                                          }}
                                                          onChange={(event) =>
                                                            this.handleTodoChange(
                                                              event,
                                                              key,
                                                              key2,
                                                              key3,
                                                              key4,
                                                            )
                                                          }
                                                          value={
                                                            this.state.items[key].children[key2]
                                                              .children[key3].children[key4].eta
                                                          }
                                                          error={
                                                            !(
                                                              this.state.items[key].children[key2]
                                                                .children[key3].children[key4].eta >
                                                              0
                                                            )
                                                          }
                                                          label='Geplante Zeit'
                                                          variant='outlined'
                                                          size='small'
                                                          InputLabelProps={{
                                                            shrink: true,
                                                          }}
                                                        />
                                                      </>
                                                    }
                                                  />

                                                  <ListItemSecondaryAction>
                                                    <IconButton
                                                      onClick={(event) =>
                                                        this.appendChild(
                                                          event,
                                                          key,
                                                          key2,
                                                          key3,
                                                          key4,
                                                        )
                                                      }
                                                      edge='end'
                                                      aria-label='add item'
                                                    >
                                                      <AddIcon />
                                                    </IconButton>
                                                    <IconButton
                                                      onClick={(event) =>
                                                        this.deleteTodo(
                                                          event,
                                                          key,
                                                          key2,
                                                          key3,
                                                          key4,
                                                        )
                                                      }
                                                      aria-label='delete item'
                                                    >
                                                      <DeleteIcon />
                                                    </IconButton>
                                                  </ListItemSecondaryAction>
                                                </ListItem>

                                                {val4.children !== undefined &&
                                                val4.children.length > 0 ? (
                                                  <List
                                                    key={
                                                      'parent-' +
                                                      key +
                                                      '-' +
                                                      key2 +
                                                      '-' +
                                                      key3 +
                                                      '-' +
                                                      key4 +
                                                      '-' +
                                                      val4.id
                                                    }
                                                  >
                                                    {val4.children.map((val5, key5) => (
                                                      <span
                                                        key={
                                                          key +
                                                          '-' +
                                                          key2 +
                                                          '-' +
                                                          key3 +
                                                          '-' +
                                                          key4 +
                                                          '-' +
                                                          key5
                                                        }
                                                      >
                                                        <ListItem
                                                          dense
                                                          className={classes.paddingRight}
                                                          key={
                                                            'item-' +
                                                            key +
                                                            '-' +
                                                            key2 +
                                                            '-' +
                                                            key3 +
                                                            '-' +
                                                            key4 +
                                                            '-' +
                                                            key5 +
                                                            '-' +
                                                            val5.id
                                                          }
                                                        >
                                                          <ListItemIcon
                                                            style={{
                                                              paddingLeft: '120px',
                                                            }}
                                                          >
                                                            <Checkbox
                                                              edge='start'
                                                              checked={false}
                                                              disabled
                                                            />
                                                          </ListItemIcon>
                                                          <ListItemText
                                                            disableTypography={true}
                                                            className={classes.flex}
                                                            primary={
                                                              <>
                                                                <TextField
                                                                  size='small'
                                                                  className={classes.grow}
                                                                  name='description'
                                                                  required={true}
                                                                  onChange={(event) =>
                                                                    this.handleTodoChange(
                                                                      event,
                                                                      key,
                                                                      key2,
                                                                      key3,
                                                                      key4,
                                                                      key5,
                                                                    )
                                                                  }
                                                                  value={
                                                                    this.state.items[key].children[
                                                                      key2
                                                                    ].children[key3].children[key4]
                                                                      .children[key5].description
                                                                  }
                                                                  error={
                                                                    !(
                                                                      this.state.items[key]
                                                                        .children[key2].children[
                                                                        key3
                                                                      ].children[key4].children[
                                                                        key5
                                                                      ].description.length > 0
                                                                    )
                                                                  }
                                                                  label='Aufgabe'
                                                                  variant='outlined'
                                                                />
                                                                <TextField
                                                                  className={classes.shrink}
                                                                  name='eta'
                                                                  type='number'
                                                                  inputProps={{
                                                                    min: 0,
                                                                  }}
                                                                  onChange={(event) =>
                                                                    this.handleTodoChange(
                                                                      event,
                                                                      key,
                                                                      key2,
                                                                      key3,
                                                                      key4,
                                                                      key5,
                                                                    )
                                                                  }
                                                                  value={
                                                                    this.state.items[key].children[
                                                                      key2
                                                                    ].children[key3].children[key4]
                                                                      .children[key5].eta
                                                                  }
                                                                  error={
                                                                    !(
                                                                      this.state.items[key]
                                                                        .children[key2].children[
                                                                        key3
                                                                      ].children[key4].children[
                                                                        key5
                                                                      ].eta > 0
                                                                    )
                                                                  }
                                                                  label='Geplante Zeit'
                                                                  variant='outlined'
                                                                  size='small'
                                                                  InputLabelProps={{
                                                                    shrink: true,
                                                                  }}
                                                                />
                                                              </>
                                                            }
                                                          />
                                                          <ListItemSecondaryAction>
                                                            <IconButton
                                                              onClick={(event) =>
                                                                this.deleteTodo(
                                                                  event,
                                                                  key,
                                                                  key2,
                                                                  key3,
                                                                  key4,
                                                                  key5,
                                                                )
                                                              }
                                                              aria-label='delete item'
                                                            >
                                                              <DeleteIcon />
                                                            </IconButton>
                                                          </ListItemSecondaryAction>
                                                        </ListItem>
                                                      </span>
                                                    ))}
                                                  </List>
                                                ) : null}
                                              </span>
                                            ))}
                                          </List>
                                        ) : null}
                                      </span>
                                    ))}
                                  </List>
                                ) : null}
                              </span>
                            ))}
                          </List>
                        ) : null}
                      </span>
                    );
                  })}
                  <ListItem dense button onClick={this.appendTodo} key={'addItem-999999'}>
                    <ListItemIcon>
                      <AddIcon />
                    </ListItemIcon>

                    <ListItemText
                      disableTypography={true}
                      className={classes.flex}
                      primary={
                        <>
                          <Typography variant='button' className={classes.grow}>
                            Weiteres Todo hinzufügen
                          </Typography>
                          <Typography variant='button' className={classes.shrink}>
                            Gesamtzeit: &nbsp;
                            {this.state.eta > 60
                              ? Math.floor(this.state.eta / 60) + ' Stunden '
                              : null}
                            {this.state.eta > 60 ? this.state.eta % 60 : this.state.eta} Minuten
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid container direction='row' justifyContent='center' alignItems='center' spacing={0}>
              {Number.isInteger(parseInt(this.state.id)) && this.state.id >= 0 ? (
                <Grid item xs={3} sm={3}>
                  <div className={classes.wrapper}>
                    <Button
                      type='submit'
                      variant='contained'
                      color='secondary'
                      className={classes.submit}
                      disabled={this.state.isSubmitting}
                      fullWidth
                    >
                      Löschen
                    </Button>
                    {this.state.isSubmitting && (
                      <CircularProgress size={24} className={classes.buttonProgress} />
                    )}
                  </div>
                </Grid>
              ) : null}

              <Grid item xs={8} sm={4}>
                {this.state.hasError ? (
                  <Alert severity='error' className={classes.fullWidth}>
                    {this.state.hasError}...
                  </Alert>
                ) : (
                  ''
                )}
                <div className={classes.wrapper}>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    disabled={
                      !(
                        this.state.items.length > 0 &&
                        this.state.id !== '' &&
                        this.state.eta > 0 &&
                        this.state.name.trim() !== ''
                      )
                    }
                    fullWidth
                  >
                    Speichern
                  </Button>
                  {this.state.isSubmitting && (
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  )}
                </div>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(TodoGenerator);
