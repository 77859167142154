import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { NavLink } from 'react-router-dom';

class AGB extends React.Component {
  render() {
    return (
      <Grid container justifyContent='center'>
        <Grid item xs={9}>
          <Card style={{ margin: '10vh 0' }}>
            <CardContent>
              <Typography color='textSecondary' gutterBottom>
                AGBs
              </Typography>
              <Typography variant='body2' component='div'>
                Bekommen Sie von uns während der Registrierung zugeschickt. Dabei müssen diese
                bestätigt werden.
              </Typography>
            </CardContent>
            <CardActions>
              <NavLink
                to='/login'
                className='NavLinkItem'
                key='/login'
                activeClassName='NavLinkItem-selected'
              >
                Zurück zu Login
              </NavLink>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
export default AGB;
