import React from 'react';
//import { ThemeProvider } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';

import NotificationButton from './NotificationButton';
import SystemStatus from './SystemStatus';
import UserButton from './UserButton';
import Breadcrumb from './Breadcrumb';
import Search from './Search';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
//import ErrorIcon from '@material-ui/icons/Error';
const styles = (theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignContent: 'center',
  },
  right: {
    float: 'right',
  },
  grow: {
    flexGrow: '1',
  },
  flex: {
    display: 'flex',
  },
  mr: {
    [theme.breakpoints.down('sm')]: {
      marginRight: '-16px',
    },
  },
  titleBarRight: {
    display: 'flex',
    marginLeft: 'auto',
    justifyContent: 'center',
    alignContent: 'center',
  },
});

class TitleBar extends React.Component {
  toggleSearch() {
    this.setState({ open: !this.state.open });
  }

  constructor() {
    super();
    this.state = { open: false };
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.root}>
          {/*
          this.props.dimensions.width > 600 
          ? <Breadcrumb 
              path={this.props.Router.location.pathname} 
              dispatch={this.props.dispatch} 
            /> 
          : ( this.state.open 
              ? null 
              : <Breadcrumb path={this.props.Router.location.pathname} dispatch={this.props.dispatch} /> 
            ) 
        */}
          {this.props.dimensions.width > this.props.Theme.breakpoints.values.md ? (
            <Breadcrumb path={this.props.Router.location.pathname} dispatch={this.props.dispatch} />
          ) : null}
          {/* 
        <div className={classes.titleBarRight}>
            <IconButton >
              <ErrorIcon color="error" />
            </IconButton>
          <SystemStatus theme={this.props.Theme} />
          <NotificationButton theme={this.props.Theme} />
          <UserButton theme={this.props.Theme} />               
        </div>
          */}
          {this.state.open ? (
            <div style={{ flexGrow: '1', display: 'flex' }}>
              <Search dimensions={this.props.dimensions} theme={this.props.Theme} />
              <IconButton color='inherit' aria-label='Search' onClick={this.toggleSearch}>
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <div className={classes.titleBarRight}>
              <IconButton color='inherit' aria-label='Search' onClick={this.toggleSearch}>
                <SearchIcon />
              </IconButton>
              <SystemStatus theme={this.props.Theme} />
              <NotificationButton theme={this.props.Theme} />
              <UserButton theme={this.props.Theme} />
            </div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Router: state.router,
  User: state.User,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(TitleBar);
