import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
//import CardHeader from '@material-ui/core/CardHeader';
//import CardContent from '@material-ui/core/CardContent';
//import TextField from '@material-ui/core/TextField';
//import MaterialTable from 'material-table';
import MaterialTable from '@material-table/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Backdrop from '@material-ui/core/Backdrop';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
    borderRadius: '0px',
  },
  alert: {
    width: '25vw',
    display: 'flex',
    position: 'fixed',
    top: '20vh',
    left: '50vw',
    zIndex: theme.zIndex.drawer + 2,
    transform: 'translate(-50%, 0)',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
});

class PartsListing extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parts: [],
      error: null,
    };

    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  async newPart(newData) {
    if (newData?.name?.trim() === '') {
      return 'Name fehlt!';
    }
    console.log(newData);
    var unit_id = parseInt(newData?.unit_id ?? 14);
    if (!Number.isInteger(unit_id) || unit_id >= 15 || unit_id < 0) {
      return 'Einheit fehlt!';
    }

    let url = new URL(process.env.REACT_APP_API_URL + '/api/material');
    //console.log(JSON.stringify(newData));
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return !!json?.part ? json : false;
      })
      .catch((error) => {
        return error;
      });

    if (response !== false) {
      let tmp = this.state.parts;
      tmp.push(response.part);
      this.setState({ parts: tmp });
    }
    return true;
  }

  async updatePart(editData) {
    if (!!editData?.id === false) {
      alert('ID fehlt! Sollte nicht passieren...');
      return 'ID fehlt!';
    }
    if (editData?.name?.trim() === '') {
      alert('Name fehlt!');
      return 'Name fehlt!';
    }

    console.log(editData);
    var unit_id = parseInt(editData?.unit_id ?? 14);
    if (!Number.isInteger(unit_id) || unit_id >= 15 || unit_id < 0) {
      alert('Einheit fehlt!');
      return 'Einheit fehlt!';
    }

    editData['_method'] = 'PUT';
    let url = new URL(process.env.REACT_APP_API_URL + '/api/material/' + editData.id);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(editData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return !!json?.part ? json : false;
      })
      .catch((error) => {
        return error;
      });

    if (response !== false) {
      let tmp = this.state.parts;
      tmp.push(response.part);
      this.setState({ parts: tmp });
    }
    return true;
  }

  async deletePart(deleteData) {
    //console.log(deleteData);

    if (!!deleteData?.id === false) {
      return 'ID fehlt!';
    }

    deleteData['_method'] = 'DELETE';
    let url = new URL(process.env.REACT_APP_API_URL + '/api/material/' + deleteData.id);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(deleteData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.part) {
      let tmp = this.state.parts;
      tmp.filter((part) => part.id !== response.part);
      this.setState({ parts: tmp });
      return true;
    }
    if (!!response.error) {
      this.setState({ error: response.error });
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Paper className={classes.root} square>
          {!!this.state.error && (
            <Backdrop
              className={classes.backdrop}
              open={!!this.state.error}
              onClick={() => {
                this.setState({ error: null });
              }}
            >
              <Alert
                className={classes.alert}
                severity='error'
                action={
                  <IconButton
                    aria-label='close'
                    color='inherit'
                    size='small'
                    onClick={() => {
                      this.setState({ error: null });
                    }}
                  >
                    <CloseIcon fontSize='inherit' />
                  </IconButton>
                }
              >
                {this.state.error}
              </Alert>
            </Backdrop>
          )}
          <MaterialTable
            title='Material Liste'
            tableRef={(ref) => {
              this.tableRef = ref;
            }}
            columns={[
              { title: 'Id', field: 'id', hidden: true, emptyValue: '', initialEditValue: '' },
              { title: 'Name', field: 'name', emptyValue: '', initialEditValue: '' },
              {
                title: 'Einheit',
                field: 'unit_id',
                initialEditValue: '14',
                emptyValue: '14',
                lookup: {
                  0: 'Dienstleistung',
                  1: 'Stück',
                  2: 'Packung',
                  3: 'Paket',
                  4: 'Dose',
                  5: 'Rolle',
                  6: 'Tube',
                  7: 'Meter',
                  8: 'Lauf-Meter',
                  9: 'Kilometer',
                  10: 'Quadratmeter',
                  11: 'Kubikmeter',
                  12: 'Liter',
                  13: 'Kilogramm',
                  14: '( Keine Einheit )',
                },
              },
              {
                title: 'Standard Anzahl',
                field: 'default_amount',
                type: 'numeric',
                emptyValue: '1',
                initialEditValue: '1',
              },
              { title: 'Artikel #', field: 'product_id', emptyValue: '', initialEditValue: '' },
              { title: 'Kategorie', field: 'category', emptyValue: '', initialEditValue: '' },
              { title: 'Anmerkung', field: 'notes', emptyValue: '', initialEditValue: '' },
              {
                title: 'Pauschale',
                field: 'allowance',
                type: 'boolean',
                emptyValue: false,
                initialEditValue: false,
              },
              {
                title: 'Preis',
                field: 'price',
                type: 'currency',
                currencySetting: { locale: 'de-DE', currencyCode: 'EUR' },
                emptyValue: '0.00',
                initialEditValue: '0.00',
              },
            ]}
            data={(query) =>
              new Promise((resolve, reject) => {
                let url = new URL(process.env.REACT_APP_API_URL + '/api/material');
                url.searchParams.set('per_page', query.pageSize);
                url.searchParams.set('page', query.page + 1);
                if (query.search.trim() !== '' && query.search.length > 3) {
                  url.searchParams.set('search', query.search);
                }
                fetch(url, {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${this.props.Authentication.access_token}`,
                  },
                })
                  .then((res) => {
                    if (res.ok) {
                      return res.json();
                    } else {
                      throw res;
                    }
                  })
                  .then((json) => {
                    resolve({
                      data: json.data,
                      page: json.current_page - 1,
                      totalCount: json.total,
                    });
                    //return (!!json?.parts) ? json : false;
                  })
                  .catch((error) => {
                    return error;
                  });
              })
            }
            actions={[
              {
                icon: RefreshIcon,
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
              },
            ]}
            options={{
              actionsCellStyle: {
                width: '100%',
                display: 'flex',
                padding: '5px',
                justifyContent: 'end',
              },
              actionsColumnIndex: 9,
              debounceInterval: 500,
              pageSize: 25,
              pageSizeOptions: [25, 50, 100],
              paginationType: 'stepped',
              maxBodyHeight:
                this.props.Dimensions.height -
                (this.props.Dimensions.appBarHeight +
                  (this.props.Dimensions.maxWidth < 600 ? 237 : 208)),
            }}
            localization={{
              grouping: {
                placeholder: 'Kopfzeilen hier her ziehen...',
              },
              pagination: {
                labelRowsSelect: 'Zeilen',
                labelDisplayedRows: '{from}-{to} von {count}',
                labelRowsPerPage: 'Zeilen pro Seite:',
                firstAriaLabel: 'Erste Seite',
                firstTooltip: 'Erste Seite',
                previousAriaLabel: 'Vorherige Seite',
                previousTooltip: 'Vorherige Seite',
                nextAriaLabel: 'Nächste Seite',
                nextTooltip: 'Nächste Seite',
                lastAriaLabel: 'Letzte Seite',
                lastTooltip: 'Letzte Seite',
              },
              toolbar: {
                searchPlaceholder: 'Suche...',
              },
              header: {
                actions: 'Funktionen',
              },
              body: {
                emptyDataSourceMessage: 'Keine Produkte/Dienstleistungen...',
                filterRow: {
                  filterTooltip: 'nach ??? filtern',
                },
              },
            }}
            icons={{
              Search: SearchIcon,
              Clear: ClearIcon,
              Add: AddIcon,
              Check: CheckIcon,
              Delete: DeleteOutlineIcon,
              DetailPanel: ArrowUpwardOutlinedIcon,
              Edit: EditIcon,
              Export: SaveAltIcon,
              Filter: FilterListIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: ChevronRightIcon,
              PreviousPage: ChevronLeftIcon,
              ResetSearch: ClearIcon,
              SortArrow: ArrowUpwardIcon,
              ThirdStateCheck: RemoveIcon,
              ViewColumn: ViewColumnIcon,
            }}
            editable={{
              isEditable: (rowData) => true,
              isDeletable: (rowData) => true,
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  resolve(this.newPart(newData));
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  resolve(this.updatePart(newData));
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  resolve(this.deletePart(oldData));
                }),
            }}
          />
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PartsListing);
