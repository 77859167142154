import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
//import { ThemeProvider } from '@material-ui/core/styles';

import VerificationBox from './VerificationBox';

class VerifyEmail extends React.Component {
  render() {
    return (
      <>
        <div
          style={{
            width: this.props.Dimensions.maxWidth - 32,
            maxWidth: this.props.Dimensions.maxWidth - 32,
            height: this.props.Dimensions.height - (this.props.Dimensions.appBarHeight + 16),
            maxHeight: this.props.Dimensions.height - (this.props.Dimensions.appBarHeight + 16),
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'flex',
            margin: '8px 16px',
          }}
        >
          <VerificationBox doNotDisplayHint={true} />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(connect(mapStateToProps, mapDispatchToProps))(VerifyEmail);
