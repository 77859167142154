import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateCompany } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
//import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DE as TextDE } from '../../lib/Text';
import MenuItem from '@material-ui/core/MenuItem';
//import HelpIcon from '@material-ui/icons/Help';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  option: {
    padding: theme.spacing(2, 1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  },
  dividerMargin: {
    margin: theme.spacing(3, -1),
  },
  codeBox: {
    backgroundColor: '#eee',
    padding: theme.spacing(1),
  },
  code: {
    fontFamily:
      'Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif',
    fontSize: '12px',
    whiteSpace: 'break-spaces',
  },
  close: {
    maxWidth: 'clamp( 10%, 15%, 20%)',
    margin: theme.spacing(1, 'auto', 1, 0),
  },
  delete: {
    maxWidth: 'clamp( 10%, 20%, 30%)',
    margin: theme.spacing(1, 1, 1, 0),
  },
  submitBody: {
    margin: theme.spacing(1, 0, 1, 'auto'),
    maxWidth: 'clamp( 15%, 25%, 35%)',
  },
  submit: {
    margin: theme.spacing(1, 0, 1, 1),
    maxWidth: 'clamp( 15%, 25%, 35%)',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',

    display: 'flex',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class ExternalForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubjectSubmitting: false,
      subjects: [{ id: -1, subject: 'Neuen Eintrag hinzufügen' }],
      selectedSubject: '',
      body: '',
      subject: '',
      hasSubjectError: false,
      hasBodyError: false,
    };

    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubjectFormSubmit = this.handleSubjectFormSubmit.bind(this);
    this.handleBodyFormSubmit = this.handleBodyFormSubmit.bind(this);
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/companies/my', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (!!response.name) {
          this.setState({
            subjects: [...this.state.subjects, ...response.inquiry_subjects],
            body: response.inquiry_body,
          });
        }
      });
  }

  handleSelect = (event) => {
    let subject =
      event.target.value >= 0
        ? this.state.subjects.find((elm) => elm.id === event.target.value).subject
        : '';
    this.setState({
      selectedSubject: event.target.value,
      subject: subject,
    });
  };
  handleChange = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  async handleBodyFormSubmit(event) {
    event.preventDefault();
    this.setState({ isBodySubmitting: true, hasBodyError: false });
    let formData = {};
    formData['_method'] = 'PUT';
    formData['body'] = this.state.body;

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/body', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState({
        isBodySubmitting: false,
        hasBodyError: false,
        body: response.company.inquiry_body,
      });
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isBodySubmitting: false, hasBodyError: response });
    }
  }

  async handleSubjectFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSubjectSubmitting: true, hasSubjectError: false });
    let formData = {};
    formData['_method'] = 'PUT';
    formData['subject'] = this.state.subject;
    formData['subject_id'] = this.state.selectedSubject;
    formData['delete'] = this.state.delete ?? false;

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/subjects', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState({
        isSubjectSubmitting: false,
        hasSubjectError: false,
        delete: null,
        subject: '',
        selectedSubject: '',
        subjects: [
          { id: -1, subject: 'Neuen Eintrag hinzufügen' },
          ...response.company.inquiry_subjects,
        ],
      });
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isSubjectSubmitting: false, hasSubjectError: response });
    }
  }

  render() {
    const { classes } = this.props;
    if (!!!this.props.User.customer.company || !!this.props.User.customer.company?.hidden) {
      return (
        <>
          <Paper className={classes.root} square>
            <Typography variant='h6' gutterBottom>
              Sie sind leider nicht in der Firmenauflistung zu finden. Aktivieren Sie dies bitte
              zunächst!
            </Typography>
            <Button
              variant='contained'
              onClick={() => this.props.dispatch(push('/settings/company'))}
              color='primary'
            >
              zu den Einstellungen
            </Button>
          </Paper>
        </>
      );
    }

    const code = `<!-- FLIXWORKER FORMULAR -->
<script type="text/javascript" src="${process.env.REACT_APP_CONNECT_URL}/js/resizer/iframeResizer.min.js"></script>
<iframe 
  style="width: 1px;min-width: 100%; border: unset!important;" 
  id="flixworker-form" 
  src="${process.env.REACT_APP_CONNECT_URL}/form/company/${this.props.User.customer.company.uuid}" 
  scrolling="no"
  >
</iframe>
<script type="text/javascript">
  var isOldIE = (navigator.userAgent.indexOf("MSIE") !== -1);
  iFrameResize({log: false,heightCalculationMethod: isOldIE ? 'max' : 'lowestElement', checkOrigin: true,bodyBackground: "inherit"},'#flixworker-form');
</script>
<!-- ENDE FLIXWORKER FORMULAR -->`;

    return (
      <>
        <Paper className={classes.root} square>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={4}>
              <Typography variant='h6' style={{ display: 'flex' }}>
                So sieht das Formular ungefähr aus:
                <Button
                  style={{ marginLeft: 'auto', marginRight: '16px' }}
                  variant='text'
                  href={
                    process.env.REACT_APP_CONNECT_URL +
                    '/form/company/' +
                    this.props.User.customer.company.uuid
                  }
                >
                  {' '}
                  Zur Live-Vorschau
                </Button>
              </Typography>
              <Box p={2}>
                <Paper
                  style={{
                    backgroundColor: this.props.Theme.background.default,
                    padding: '0 8px',
                    backgroundPosition: '4px calc(100% - 4px)',
                    backgroundRepeat: 'no-repeat',
                    backgroundImage:
                      'url(' +
                      process.env.REACT_APP_API_URL +
                      '/company/logo/' +
                      this.props.User.customer.company.uuid +
                      ')',
                    backgroundSize: 'clamp(15%, 20%, 22%) auto',
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                      <Typography variant='h5' style={{ alignSelf: 'center' }}>
                        Betreff
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        variant='outlined'
                        value='Beispielhafter Betreff'
                        style={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant='body1'>[ ... ]</Typography>
                    </Grid>
                    <Grid item xs={12} md={4} style={{ display: 'flex' }}>
                      <Typography variant='h5' style={{ alignSelf: 'top' }}>
                        Nachricht
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        multiline
                        minRows='4'
                        fullWidth
                        variant='outlined'
                        value={this.state.body}
                        style={{ backgroundColor: 'white' }}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <input
                        accept='image/*'
                        disabled
                        style={{ display: 'none' }}
                        id='contained-button-file'
                        multiple
                        type='file'
                      />
                      <label
                        htmlFor='contained-button-file'
                        style={{ overflow: 'clip', whiteSpace: 'nowrap', display: 'block' }}
                      >
                        <Button variant='contained' color='secondary' component='span'>
                          Durchsuchen ...
                        </Button>
                        Keine Dateien ausgewählt.
                      </label>
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <FormControlLabel
                        style={{ alignItems: 'flex-start' }}
                        control={<Checkbox name='checkedC' />}
                        label={
                          'Ich habe die Datenschutzerklärung zur Kenntnis genommen und erkläre mich mit der Datenübergabe via FLiXWORKER einverstanden. Sollte die Kapazitätsgrenze der Firma ' +
                          this.props.User.customer.company.name +
                          ' erreicht sein, erlaubt sich FLiXWORKER, Ihre Anfrage an einen Partnerbetrieb weiterzuleiten.'
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={12} md={8}>
                      <Button color='primary' type='button' variant='contained' fullWidth>
                        Absenden
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Divider className={classes.dividerMargin} />
              <Typography variant='h5'>Konfiguration des Formulares</Typography>
              <Typography variant='body1' gutterBottom>
                Hier können Sie die Textfelder mit möglichen Optionen versehen. Änderungen hier
                können eine weile dauern bis Sie auf Ihrem Formular widergespiegelt werden.
              </Typography>
              <form key='subjectform' onSubmit={this.handleSubjectFormSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      fullWidth
                      select
                      variant='outlined'
                      name='selectedSubject'
                      label="Auswählbare 'Betrifft' Felder"
                      defaultValue={-1}
                      value={this.state.selectedSubject}
                      onChange={this.handleSelect}
                    >
                      {this.state.subjects.map((option) => (
                        <MenuItem className={classes.option} key={option.id} value={option.id}>
                          {option.subject}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  {this.state.selectedSubject !== '' && (
                    <Grid item xs={12} lg={12}>
                      <TextField
                        fullWidth
                        required
                        variant='outlined'
                        name='subject'
                        label={TextDE.form.subject.label}
                        value={this.state.subject}
                        onChange={this.handleChange}
                      />
                    </Grid>
                  )}
                  {this.state.selectedSubject !== '' && (
                    <Grid item xs={12}>
                      {!!this.state.hasSubjectError && (
                        <Alert severity='error'>
                          <AlertTitle>Fehler beim Aktualisieren der Daten...</AlertTitle>
                          {!!this.state.hasSubjectError?.errors &&
                            this.state.hasSubjectError?.errors?.map((option, key) => (
                              <Typography key={'body-errors-' + key}>{option}</Typography>
                            ))}
                        </Alert>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          type='button'
                          variant='contained'
                          className={classes.close}
                          disabled={this.state.isSubjectSubmitting}
                          fullWidth
                          onClick={() => this.setState({ selectedSubject: '', subject: '' })}
                        >
                          Schließen
                        </Button>
                        <Button
                          type='submit'
                          variant='contained'
                          className={classes.delete}
                          disabled={this.state.isSubjectSubmitting}
                          fullWidth
                          onClick={() => this.setState({ delete: true })}
                        >
                          Löschen
                        </Button>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                          disabled={this.state.isSubjectSubmitting}
                          onClick={() => this.setState({ delete: false })}
                          fullWidth
                        >
                          Speichern
                        </Button>
                        {this.state.isSubjectSubmitting && (
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>

              <form key='bodyform' onSubmit={this.handleBodyFormSubmit}>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={12}>
                    <TextField
                      fullWidth
                      multiline
                      variant='outlined'
                      name='body'
                      label='Text'
                      minRows={4}
                      value={this.state.body}
                      onChange={this.handleChange}
                    ></TextField>
                  </Grid>

                  {this.state.body !== '' && (
                    <Grid item xs={12}>
                      {!!this.state.hasBodyError && (
                        <Alert severity='error'>
                          <AlertTitle>Fehler beim Aktualisieren der Daten...</AlertTitle>
                          {!!this.state.hasBodyError?.errors &&
                            this.state.hasBodyError?.errors?.map((option, key) => (
                              <Typography key={'body-errors-' + key}>{option}</Typography>
                            ))}
                        </Alert>
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.submitBody}
                          disabled={this.state.isBodySubmitting}
                          onClick={() => this.setState({ delete: false })}
                          fullWidth
                        >
                          Speichern
                        </Button>
                        {this.state.isBodySubmitting && (
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                    </Grid>
                  )}
                </Grid>
              </form>

              {!!!this.props.User.customer.company?.hidden && (
                <>
                  <Divider className={classes.dividerMargin} />
                  <Typography variant='subtitle1'>
                    Bitte fügen Sie diesen Code an der gewünschten Stelle auf Ihrer Webseite hinzu.
                    <br />
                    Das Formular wird sich bestmöglichst an die verfügbare Größe anpassen.
                  </Typography>
                  <Box border={1} borderRadius='borderRadius' className={classes.codeBox}>
                    <Typography variant='body1' display='block' className={classes.code}>
                      {code}
                    </Typography>
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ExternalForm);
