import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
import { Constants } from '../../lib/Constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import Typography from '@material-ui/core/Typography';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CreateIcon from '@material-ui/icons/Create';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import Title from './components/Title';
import TitleIcon from './components/TitleIcon';
import StatusChip from './components/StatusChip';
import AddressCard from './components/AddressCard';
import UserCard from './components/UserCard';
import ContactCard from './components/ContactCard';
import FileCard from './components/FileCard';
import Competitive from './components/Competitive';
import AppointmentsCard from './components/AppointmentsCard';
import Notes from './components/Notes';
import Subtitle from './components/Subtitle';

import NoteForm from './Forms/NoteForm';
import PerformanceReport from './PerformanceReport/index';

import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EmailForm from './Forms/EmailForm';
import ChatForm from './Forms/ChatForm';

import { DE as TextDE } from '../../lib/Text';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1, 1),
    },
  },
  padding: {
    padding: theme.spacing(0, 1) + '!important',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1, 2, 0, 2) + '!important',
    },
  },
  iconHeight: {
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      height: theme.spacing(9),
    },
  },
  chip: {
    width: 'max-content',
    padding: theme.spacing(1.5, 1),
    marginTop: '4px',
    fontSize: '110%',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    //width: "100%",
    margin: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0.5),
      padding: theme.spacing(1, 0, 0),
      overflowY: 'hidden',
      overflowX: 'hidden',
      height: 'calc( 100% - 74px)',
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc(100% - ' + theme.spacing(2) + 'px)',
      overflowY: 'hidden',
      overflowX: 'hidden',
    },
  },
  subContainerBig: {
    margin: 0,
    padding: 0,
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    alignItems: 'flex-start',
    height: 'calc(100% - 208px)',
  },
  subContainerSmall: {
    padding: theme.spacing(1),
    margin: theme.spacing(0),
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  navTabs: {
    backgroundColor: theme.palette.background.paper,
    //width: 'auto',
    borderTop: '1px solid ' + theme.palette.divider,
    height: '60px',
    paddingBottom: '4px',
    //position: "fixed",
    //right: 0,
    //bottom: "4px",
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(0.5),
      borderLeft: '1px solid ' + theme.palette.divider,
      borderRight: '1px solid ' + theme.palette.divider,
    },
    [theme.breakpoints.down('xs')]: {
      left: 0,
    },
  },
  smallText: {
    fontSize: '80%',
    marginBottom: 0,
    '&>svg': {
      marginBottom: '0!important',
    },
  },
  labelIcon: {
    //minHeight: 'unset',
    paddingTop: 0,
    paddingBottom: '2px',
  },
  center: {
    [theme.breakpoints.down('sm')]: {
      margin: '1em',
    },
    [theme.breakpoints.up('md')]: {
      margin: '4em 30%',
    },
  },
});

class SimplifiedMessageDetail extends React.Component {
  constructor(props) {
    super(props);

    //roles to check
    const canSeeUserConfig = props.User.roles.filter((value) =>
      [Constants.roles.ROLES_ADMIN, Constants.roles.ROLES_ORGANIZER].includes(value.name),
    );

    this.state = {
      headerRebuild: false,
      usersAll: [],
      statesAll: [],
      canSeeUserConfig: canSeeUserConfig.length > 0,
      isFetching: true,
      message: null,
      job: null,
      to: [],
      recipients: [],
      deleteOpen: false,
      hasError: false,
      customFunctions: [],
      headerHeight: 20,
      componentsAlign: false,
      editUUID: false,
      editSubject: false,
      editText: false,
      openNoteForm: false,
      openEmailForm: false,
      openChatForm: false,
      openAppointmentForm: false,
      tab: 0,
      cardAddressExpand: false,
      cardUserExpand: false,
      cardContactExpand: false,
      cardAppointmentsExpand: false,
    };

    this.headElement = React.createRef();

    this.updateHeaderHeight = this.updateHeaderHeight.bind(this);

    this.refreshCallback = this.refreshCallback.bind(this);
    this.toggleCardsExpansion = this.toggleCardsExpansion.bind(this);
    this.handleTabs = this.handleTabs.bind(this);
    this.openNoteForm = this.openNoteForm.bind(this);
    this.closeNoteForm = this.closeNoteForm.bind(this);

    this.respondEmailForm = this.respondEmailForm.bind(this);
    this.respondChatForm = this.respondChatForm.bind(this);

    this.callbackAcceptedInboxItem = this.callbackAcceptedInboxItem.bind(this);
    this.handleDeleteFunction = this.handleDeleteFunction.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
  }

  async handleDeleteFunction() {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.state.toDelete.uuid);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'delete' }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response === 'Deleted') {
      this.setState({
        ...this.state,
        toDelete: {},
        deleteOpen: false,
        messages: {
          ...this.state.messages,
          data: this.state.messages.data.filter((element) => element.id !== this.state.toDelete.id),
        },
      });
      return true;
    }
    return false;
  }

  handleDeleteOpen(message) {
    this.setState({ deleteOpen: true });
  }

  handleDeleteClose(message) {
    this.setState({ deleteOpen: false });
  }

  callbackAcceptedInboxItem() {
    //this.setState({ ...this.state, message: { ...this.state.message, competitive: false, competition_won_by: this.props.User.customer_id, }});
    //this.setState({ isFetching: true, }, () => this.fetchData());
    this.setState({ componentsAlign: false }, () =>
      this.fetchData().then((result) => {
        this.updateHeaderHeight();
      }),
    );
  }

  toggleCardsExpansion = (card) => {
    let cardUserExpand = false;
    let cardAddressExpand = false;
    let cardContactExpand = false;
    let cardAppointmentsExpand = false;
    switch (card) {
      case 'UserCard':
        cardUserExpand = !this.state.cardUserExpand;
        break;
      case 'AddressCard':
        cardAddressExpand = !this.state.cardAddressExpand;
        break;
      case 'ContactCard':
        cardContactExpand = !this.state.cardContactExpand;
        break;
      case 'AppointmentsCard':
        cardAppointmentsExpand = !this.state.cardAppointmentsExpand;
        break;
      default:
        break;
    }
    this.setState({
      cardUserExpand: cardUserExpand,
      cardAddressExpand: cardAddressExpand,
      cardContactExpand: cardContactExpand,
      cardAppointmentsExpand: cardAppointmentsExpand,
    });
  };

  callbackEditNoteForm = (uuid, subject, text) => {
    //console.log(uuid, subject, text);
    this.setState(
      {
        editUUID: uuid,
        editSubject: subject,
        editText: text,
      },
      this.setState({ openNoteForm: true }),
    );
  };

  openNoteForm = () => {
    this.setState({ openNoteForm: true });
  };
  closeNoteForm = (success) => {
    this.setState({
      editUUID: false,
      editSubject: false,
      editText: false,
      openNoteForm: false,
    });
    if (success) {
      this.setState({ isFetching: true }, () => this.fetchData());
    }
  };

  openEmailForm = () => {
    this.setState({ openEmailForm: true });
  };
  respondEmailForm(message, contacts) {
    this.setState({
      ...this.state,
      quote: message,
      recipients: contacts ?? [],
      openEmailForm: true,
    });
  }
  closeEmailForm = (reload) => {
    //console.log("Closing email form with reload =", reload)
    if (!!reload) {
      this.setState({ isFetching: true }, () => this.fetchData());
    }
    this.setState({ openEmailForm: false });
  };

  openAppointmentForm = () => {
    this.setState({ openAppointmentForm: true });
  };
  closeAppointmentForm = (reload) => {
    if (!!reload) {
      this.setState({ isFetching: true }, () => this.fetchData());
    }
    this.setState({ openAppointmentForm: false });
  };

  openChatForm = () => {
    this.setState({ openChatForm: true });
  };
  respondChatForm(message, contacts) {
    this.setState({
      ...this.state,
      quote: message,
      recipients: contacts ?? [],
      openChatForm: true,
    });
  }
  closeChatForm = (reload) => {
    if (!!reload) {
      this.setState({ isFetching: true }, () => this.fetchData());
    }
    this.setState({ openChatForm: false });
  };

  async componentDidMount() {
    // statements
    this.fetchData().then((result) => {
      this.updateHeaderHeight();
    });

    fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = this.state.usersAll;
        json.forEach((value, key) => {
          if (!!!value.deleted_at) {
            tmp.push({
              uuid: value.uuid,
              displayName: value.firstname + ' ' + value.lastname,
              firstname: value.firstname,
              lastname: value.lastname,
              roles: value.roles.map((role) => role.name),
            });
          }
        });
        this.setState({ usersAll: tmp });
      })
      .catch((error) => {
        return false;
      });

    fetch(process.env.REACT_APP_API_URL + '/api/jobs/priorities', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({
          statesAll: json,
        });
      })
      .catch((error) => {
        return false;
      });
    window.addEventListener('resize', this.updateHeaderHeight);
  }

  updateHeaderHeight(rebuild = false) {
    if (!!rebuild) {
      this.setState({ headerRebuild: rebuild });
    }
    const height = this.headElement?.clientHeight ?? this.headElement?.current?.clientHeight ?? 20;
    this.setState({ headerHeight: height, componentsAlign: true });
    if (!!rebuild) {
      this.setState({ headerRebuild: false });
    }
  }

  refreshCallback(hardReset = true) {
    this.setState({ componentsAlign: !hardReset }, () =>
      this.fetchData().then((result) => {
        this.updateHeaderHeight(hardReset);
      }),
    );
  }

  async fetchData() {
    const uuid = this.props.match.params.uuid;
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/inbox/' + uuid + '?all=true', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => json)
      .catch((error) => {
        return error;
      });

    if (!!response.uuid) {
      let type = Constants.getInboxItemType('app');
      let noteType = Constants.getInboxItemType('note');

      if (response.procedure !== null) {
        var protocol = response.procedure?.items || [];
        if (!!response.data) {
          protocol.unshift(
            {
              type_id: !!response.creator ? noteType : response.type,
              created_at: response.created_at,
              created_by: response.created_by,
              creator: response.creator,
              data: response.data,
            },
            {
              type_id: type.id,
              created_at: response.procedure?.created_at,
              data: response.procedure?.data,
            },
          );
        } else {
          protocol.unshift({
            type_id: type.id,
            created_at: response.procedure?.created_at,
            data: response.procedure?.data,
          });
        }
        response.protocol = protocol.reverse();
      } else {
        response.protocol = [
          {
            type_id: type.id,
            created_at: response.created_at,
            data: response.data,
          },
        ];
      }
      type = Constants.getInboxItemType(response.type);
      //Penis
      this.setState({
        isFetching: false,
        type: type,
        message: response,
        hideDetails: false,
      });
    } else {
      this.setState({ hasError: response, isFetching: false });
      console.error(response.json());
    }

    let tmp = await fetch(process.env.REACT_APP_API_URL + '/api/customer/functions', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });
    this.setState({ customFunctions: tmp });

    //console.log("REdirect Job: " + (!!this.props.location?.state?.openPerformanceReport ? 'True' : 'False'), this.props.location?.state?.openPerformanceReport)
    if (!!this.props.location?.state?.openPerformanceReport) {
      let elmIndex = this.state.message?.procedure?.jobs.findIndex((el) => {
        //console.log(el)
        let tmp = this.props.location?.state?.openPerformanceReport === el.uuid;
        return tmp;
      });
      if (elmIndex >= 0) {
        this.setState({
          job: this.state.message?.procedure?.jobs[elmIndex],
          tab: 3,
        });
      }
    } else {
      //console.log("[DEBUG componentDidMount MessageDetail]", this.props.Router.location.hash, window.location.hash);
      if (!!window?.location?.hash && window.location.hash !== '') {
        //console.log("[DEBUG componentDidMount MessageDetail] We have a hashtag");
        let str = window.location.hash;
        const regex =
          /(job)=([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/gm;
        let m;
        while ((m = regex.exec(str)) !== null) {
          // This is necessary to avoid infinite loops with zero-width matches
          if (m.index === regex.lastIndex) {
            regex.lastIndex++;
          }

          if (m.length === 3) {
            //console.log(`[DEBUG componentDidMount MessageDetail] Full Match, ` + m[0]);
            //console.log(`[DEBUG componentDidMount MessageDetail] Has Job, ` + m[1]);
            //console.log(`[DEBUG componentDidMount MessageDetail] Has Uuid, ` + m[2]);
            //this.setState({tab: 3, job: m[2]});
            let job = false;
            for (var i = 0; i < this.state.message?.procedure?.jobs.length; i++) {
              job =
                this.state.message?.procedure?.jobs[i].uuid === m[2]
                  ? this.state.message?.procedure?.jobs[i]
                  : job;
            }

            if (!!job) {
              this.setState({
                job: job,
                tab: 3,
              });
            }
          }
        }
      }
    }
  }

  handleTabs = (event, value) => {
    this.setState({ tab: value });
  };

  render() {
    const { classes } = this.props;

    if (this.state.isFetching) {
      return (
        <center>
          <CircularProgress style={{ marginTop: '4em' }} />
        </center>
      );
    }

    if (!!this.state.hasError) {
      return (
        <Alert severity='error' className={classes.center}>
          <AlertTitle>
            {this.state.hasError.status} - {this.state.hasError.statusText}
          </AlertTitle>
          Die gesuchte Nachricht existiert nicht, oder Ihnen fehlen die Berechtigungen um darauf
          zuzugreifen —{' '}
          <strong>
            {' '}
            Wenden Sie sich an Ihren Administrator oder{' '}
            <a href='mailto:support@flixworker.de'>an uns!</a>
          </strong>
        </Alert>
      );
    }

    const fullWidth = this.props.Dimensions.maxWidth;

    return (
      <>
        <div
          style={{
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            backgroundColor: 'inherit',
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <Dialog
            fullWidth
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                this.handleDeleteClose();
              }
            }}
            open={this.state.deleteOpen}
          >
            <DialogTitle id='alert-dialog-title'>Anfrage löschen?</DialogTitle>

            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                Die Anfrage unwiederbringlich löschen?
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleDeleteClose} color='primary'>
                Abbrechen
              </Button>
              <Button
                onClick={this.handleDeleteFunction}
                variant='outlined'
                color='secondary'
                autoFocus
              >
                LÖSCHEN
              </Button>
            </DialogActions>
          </Dialog>

          {!!this.state.message?.competitive && !!!this.state.message?.competition_won_by && (
            <Competitive
              uuid={this.state.message?.uuid}
              creator={this.state.message?.creator}
              data={this.state.message?.data}
              external={this.state.message?.external_request}
              competitors={this.state.message?.competitors}
              created_at={this.state.message?.created_at}
              open={!!this.state.message?.competitive && !!!this.state.message?.competition_won_by}
              callbackAcceptedInboxItem={this.callbackAcceptedInboxItem}
            />
          )}

          {!!this.state.openNoteForm ? (
            <NoteForm
              model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
              uuid={this.state.message?.uuid}
              handleClose={this.closeNoteForm}
              openModal={!!this.state.openNoteForm}
              editSubject={this.state.editSubject}
              editText={this.state.editText}
              editUUID={this.state.editUUID}
            />
          ) : null}
          {!!this.state.openEmailForm ? (
            <EmailForm
              model='procedures'
              uuid={this.state.message.uuid}
              serial={this.state.message?.procedure?.serial ?? false}
              subject={this.state.message?.procedure?.data?.subject}
              handleClose={this.closeEmailForm}
              message={this.state.quote}
              recipients={this.state.recipients}
              contactSuggestions={this.state.message?.procedure?.contacts || []}
              openModal={!!this.state.openEmailForm}
              isProcedure={!!this.state.message.procedure}
            />
          ) : null}

          {!!this.state.openChatForm ? (
            <ChatForm
              model='procedures'
              uuid={this.state.message.uuid}
              handleClose={this.closeChatForm}
              message={this.state.quote}
              recipients={this.state.recipients}
              contactSuggestions={this.state.message?.procedure?.contacts || []}
              openModal={!!this.state.openChatForm}
              isProcedure={!!this.state.message.procedure}
            />
          ) : null}
          <Card className={classes.container}>
            <Grid
              spacing={0}
              container
              ref={(headElement) => {
                this.headElement = headElement;
              }}
            >
              {!!this.state.headerRebuild && (
                <center>
                  <CircularProgress style={{ marginTop: '2em' }} />
                </center>
              )}
              {!!!this.state.headerRebuild && (
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    style={{ flexWrap: 'inherit' }}
                  >
                    <Grid item className={classes.padding} style={{ flexShrink: 1 }}>
                      <TitleIcon icon={this.state.type?.icon ?? 'new'} />
                    </Grid>
                    <Hidden smDown>
                      <Grid item style={{ flexShrink: 1, height: '100%' }}>
                        <Grid
                          container
                          direction='column'
                          justifyContent='space-around'
                          alignItems='center'
                          style={{ height: '100%', width: '100%' }}
                        >
                          <Grid item>
                            <StatusChip
                              status={this.state.message?.procedure?.status ?? 0}
                              simpleStates={true}
                              callbackFunction={(data) => this.refreshCallback(data)}
                            />
                          </Grid>

                          <Grid item>
                            <Tooltip
                              title='Die eindeutige Nummer für diesen Vorgang'
                              aria-label='serial'
                            >
                              <Chip
                                label={
                                  !!this.state.message?.procedure?.serial
                                    ? 'ID# ' + this.state.message.procedure?.serial
                                    : ' N/A '
                                }
                                color='primary'
                                style={{ fontWeight: 'bold' }}
                                className={classes.chip}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Hidden>
                    {!!!this.state.message?.procedure && (
                      <Grid item className={classes.padding} style={{ flexShrink: 1 }}>
                        <IconButton
                          color='secondary'
                          edge='end'
                          aria-label='expand'
                          onClick={this.handleDeleteOpen}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item className={classes.padding} style={{ flexGrow: 1 }}>
                      <Title
                        subject={
                          this.state.message?.procedure?.data?.subject ??
                          this.state.message?.data?.subject ??
                          'Kein Betreff'
                        }
                        isInbox={!!!this.state.message?.procedure}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {!!!this.state.headerRebuild && (
                <Grid className={classes.padding} item xs={12}>
                  <Subtitle
                    model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                    subject={this.state.message?.procedure?.data?.subtitle}
                    callbackFunction={(data) => this.refreshCallback(data)}
                  />
                </Grid>
              )}
              {!!!this.state.headerRebuild && (
                <Hidden mdUp>
                  <Grid item xs={12}>
                    <Grid
                      container
                      direction='row'
                      justifyContent='space-around'
                      alignItems='center'
                      style={{
                        height: '100%',
                        maxWidth: '100%',
                        overflowX: 'auto',
                      }}
                    >
                      <Grid item>
                        <StatusChip
                          status={this.state.message?.procedure?.status ?? 0}
                          simpleStates={true}
                          callbackFunction={(data) => this.refreshCallback(data)}
                        />
                      </Grid>

                      {!!this.state.message?.procedure?.serial && (
                        <Grid item>
                          <Tooltip
                            title='Die eindeutige Nummer für diesen Vorgang'
                            aria-label='serial'
                          >
                            <Chip
                              label={
                                !!this.state.message?.procedure?.serial
                                  ? 'ID# ' + this.state.message.procedure?.serial
                                  : ' N/A '
                              }
                              color='primary'
                              className={classes.chip}
                              style={{ fontWeight: 'bold' }}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Hidden>
              )}
            </Grid>

            {/*Body container*/}
            {this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values['md'] ? (
              this.state.componentsAlign ? (
                <Grid container className={classes.subContainerBig} spacing={1}>
                  <Grid item xs={12} md={this.state.canSeeUserConfig ? 3 : 4}>
                    <AddressCard
                      canSeeUserConfig={this.state.canSeeUserConfig}
                      open={this.state.cardAddressExpand}
                      toggleFunction={(card) => this.toggleCardsExpansion(card)}
                      expandedWidth={fullWidth}
                      model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                      uuid={this.state.message?.uuid}
                      addresses={this.state.message?.procedure?.addresses || []}
                      callbackFunction={(data) => this.refreshCallback(data)}
                    />
                  </Grid>
                  <Grid item xs={12} md={this.state.canSeeUserConfig ? 3 : 4}>
                    <ContactCard
                      linkedConnectUsers={
                        this.state.message.external_request?.linked_connect_users ?? []
                      }
                      canInviteUsers={this.state.canSeeUserConfig}
                      open={this.state.cardContactExpand}
                      toggleFunction={(card) => this.toggleCardsExpansion(card)}
                      expandedWidth={fullWidth}
                      model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                      uuid={this.state.message?.uuid}
                      contacts={this.state.message?.procedure?.contacts || []}
                      addresses={this.state.message?.procedure?.addresses || []}
                      callbackFunction={(data) => this.refreshCallback(data)}
                    />
                  </Grid>
                  <Grid item xs={12} md={this.state.canSeeUserConfig ? 3 : 4}>
                    <AppointmentsCard
                      canSeeUserConfig={this.state.canSeeUserConfig}
                      open={this.state.cardAppointmentsExpand}
                      addAppointment={this.state.openAppointmentForm}
                      closeAppointmentDialog={this.closeAppointmentForm}
                      toggleFunction={(card) => this.toggleCardsExpansion(card)}
                      expandedWidth={fullWidth}
                      appointments={this.state.message?.appointments || []}
                      disableProcedure={true}
                      isProcedure={!!this.state.message?.procedure}
                      suggestions={{
                        users: this.state.message?.procedure?.users || [],
                        contacts: this.state.message?.procedure?.contacts || [],
                        locations: this.state.message?.procedure?.addresses || [],
                        jobs: this.state.message?.procedure?.jobs || [],
                      }}
                      callbackFunction={(data) => this.refreshCallback(data)}
                      data={{
                        title:
                          this.state.message?.procedure?.data?.subject ||
                          this.state.message?.data?.subject,
                        raw: {
                          linkItem: {
                            linkTo: this.state.message?.uuid,
                            uuid: this.state.message?.uuid,
                            linkType: 'procedure',
                            serial: this.state.message?.procedure?.serial,
                            subject:
                              this.state.message?.procedure?.data?.subject ||
                              this.state.message?.data?.subject,
                          },
                        },
                      }}
                    />
                  </Grid>
                  {this.state.canSeeUserConfig && (
                    <Grid item xs={12} md={this.state.canSeeUserConfig ? 3 : 4}>
                      <UserCard
                        open={this.state.cardUserExpand}
                        toggleFunction={(card) => this.toggleCardsExpansion(card)}
                        expandedWidth={fullWidth}
                        model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                        uuid={this.state.message?.uuid}
                        users={this.state.message?.procedure?.users || []}
                        callbackFunction={(data) => this.refreshCallback(data)}
                      />
                    </Grid>
                  )}

                  <Grid item xs={12} md={12}>
                    <Typography variant='h6' gutterBottom={false} component='h6'>
                      {TextDE.note.labelPlural}
                    </Typography>
                    <Notes
                      callbackFunction={(data) => this.refreshCallback(data)}
                      uuid={this.state.message?.uuid}
                      users={this.state.users}
                      notes={this.state.message?.protocol}
                      customFunctions={this.state.customFunctions}
                      addNote={this.openNoteForm}
                      addEmail={this.openEmailForm}
                      respondEmail={this.respondEmailForm}
                      addChat={this.openChatForm}
                      respondChat={this.respondChatForm}
                      addAppointment={this.openAppointmentForm}
                      editNoteFunction={(uuid, subject, text) =>
                        this.callbackEditNoteForm(uuid, subject, text)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='h6' gutterBottom={false} component='h6'>
                      {TextDE.file.labelPlural}
                    </Typography>
                    <FileCard
                      model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                      callbackFunction={(data) => this.refreshCallback(data)}
                      uuid={this.state.message?.uuid}
                      files={{
                        inbox: this.state.message?.media,
                        procedure: this.state.message?.procedure?.media ?? [],
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography variant='h6' gutterBottom={false} component='h6'>
                      {TextDE.performanceReport.labelPlural}
                    </Typography>
                    <PerformanceReport
                      callbackFunction={(data) => this.refreshCallback(data)}
                      inboxUuid={this.state.message?.uuid}
                      procedure={this.state.message?.procedure}
                      jobs={this.state.message?.procedure?.jobs || []}
                      openJob={this.state.job ?? false}
                      usersAll={this.state.usersAll || []}
                      statesAll={this.state.statesAll || []}
                      preSelectedUsers={this.state.message?.procedure?.users || [this.props.User]}
                    />
                  </Grid>
                </Grid>
              ) : (
                <center>
                  <CircularProgress style={{ marginTop: '4em' }} />
                </center>
              )
            ) : this.state.componentsAlign ? (
              <Box
                p={0}
                className={classes.subContainerSmall}
                style={{
                  height:
                    this.props.Dimensions.width >= this.props.Theme.breakpoints.values['md']
                      ? 'calc(100% - 281px)'
                      : 'calc(100% - 207px)',
                }}
              >
                {this.state.tab === 0 && (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <AddressCard
                        open={this.state.cardAddressExpand}
                        toggleFunction={(card) => this.toggleCardsExpansion(card)}
                        expandedWidth={fullWidth}
                        model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                        uuid={this.state.message?.uuid}
                        addresses={this.state.message?.procedure?.addresses || []}
                        callbackFunction={(data) => this.refreshCallback(data)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ContactCard
                        linkedConnectUsers={
                          this.state.message.external_request?.linked_connect_users ?? []
                        }
                        canInviteUsers={this.state.canSeeUserConfig}
                        open={this.state.cardContactExpand}
                        toggleFunction={(card) => this.toggleCardsExpansion(card)}
                        expandedWidth={fullWidth}
                        model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                        uuid={this.state.message?.uuid}
                        contacts={this.state.message?.procedure?.contacts || []}
                        addresses={this.state.message?.procedure?.addresses || []}
                        callbackFunction={(data) => this.refreshCallback(data)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppointmentsCard
                        open={this.state.cardAppointmentsExpand}
                        toggleFunction={(card) => this.toggleCardsExpansion(card)}
                        expandedWidth={fullWidth}
                        appointments={this.state.message?.appointments || []}
                        addAppointment={this.state.openAppointmentForm}
                        closeAppointmentDialog={this.closeAppointmentForm}
                        disableProcedure={true}
                        isProcedure={!!this.state.message?.procedure}
                        suggestions={{
                          users: this.state.message?.procedure?.users || [],
                          contacts: this.state.message?.procedure?.contacts || [],
                          locations: this.state.message?.procedure?.addresses || [],
                          jobs: this.state.message?.procedure?.jobs || [],
                        }}
                        callbackFunction={(data) => this.refreshCallback(data)}
                        data={{
                          title:
                            this.state.message?.procedure?.data?.subject ||
                            this.state.message?.data?.subject,
                          raw: {
                            linkItem: {
                              linkTo: this.state.message?.uuid,
                              uuid: this.state.message?.uuid,
                              linkType: 'procedure',
                              serial: this.state.message?.procedure?.serial,
                              subject:
                                this.state.message?.procedure?.data?.subject ||
                                this.state.message?.data?.subject,
                            },
                          },
                        }}
                      />
                    </Grid>
                    {this.state.canSeeUserConfig && (
                      <Grid item xs={12}>
                        <UserCard
                          open={this.state.cardUserExpand}
                          toggleFunction={(card) => this.toggleCardsExpansion(card)}
                          expandedWidth={fullWidth}
                          model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                          uuid={this.state.message?.uuid}
                          users={this.state.message?.procedure?.users || []}
                          callbackFunction={(data) => this.refreshCallback(data)}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                {this.state.tab === 1 && (
                  <Notes
                    callbackFunction={(data) => this.refreshCallback(data)}
                    uuid={this.state.message?.uuid}
                    users={this.state.users}
                    notes={this.state.message?.protocol}
                    customFunctions={this.state.customFunctions}
                    addNote={this.openNoteForm}
                    addEmail={this.openEmailForm}
                    respondEmail={this.respondEmailForm}
                    addChat={this.openChatForm}
                    respondChat={this.respondChatForm}
                    addAppointment={this.openAppointmentForm}
                    editNoteFunction={(uuid, subject, text) =>
                      this.callbackEditNoteForm(uuid, subject, text)
                    }
                  />
                )}
                {this.state.tab === 2 && (
                  <FileCard
                    model={!!this.state.message?.procedure ? 'procedures' : 'inbox'}
                    callbackFunction={(data) => this.refreshCallback(data)}
                    uuid={this.state.message?.uuid}
                    files={{
                      inbox: this.state.message?.media,
                      procedure: this.state.message?.procedure?.media ?? [],
                    }}
                  />
                )}
                {this.state.tab === 3 && (
                  <PerformanceReport
                    callbackFunction={(data) => this.refreshCallback(data)}
                    inboxUuid={this.state.message?.uuid}
                    procedure={this.state.message?.procedure}
                    jobs={this.state.message?.procedure?.jobs || []}
                    openJob={this.state.job ?? false}
                    usersAll={this.state.usersAll || []}
                    statesAll={this.state.statesAll || []}
                    preSelectedUsers={this.state.message?.procedure?.users || [this.props.User]}
                  />
                )}
              </Box>
            ) : (
              <center>
                <CircularProgress style={{ marginTop: '4em' }} />
              </center>
            )}
          </Card>
          {!(this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values['md']) && (
            <Tabs
              value={this.state.tab}
              onChange={this.handleTabs}
              variant='fullWidth'
              indicatorColor='primary'
              textColor='primary'
              aria-label='icon tabs example'
              className={classes.navTabs}
              style={
                this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values['sm']
                  ? {
                      width: this.props.Dimensions.maxWidth - 10,
                    }
                  : null
              }
            >
              <Tab
                classes={{
                  labelIcon: classes.labelIcon,
                  wrapper: classes.smallText,
                }}
                label={TextDE.info}
                icon={<InfoOutlinedIcon />}
                aria-label='phone'
              />
              <Tab
                classes={{
                  labelIcon: classes.labelIcon,
                  wrapper: classes.smallText,
                }}
                label={TextDE.note.labelPlural}
                icon={<CreateIcon />}
                aria-label='favorite'
              />
              <Tab
                classes={{
                  labelIcon: classes.labelIcon,
                  wrapper: classes.smallText,
                }}
                label={TextDE.file.labelPlural}
                icon={<AttachFileIcon />}
                aria-label='person'
              />
              <Tab
                classes={{
                  labelIcon: classes.labelIcon,
                  wrapper: classes.smallText,
                }}
                label={TextDE.performanceReport.labelPluralShort}
                icon={<AssignmentTurnedInIcon />}
                aria-label='person'
              />
            </Tabs>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(SimplifiedMessageDetail);
