import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
//import MaterialTable from 'material-table';
import MaterialTable from '@material-table/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ArrowUpwardOutlinedIcon from '@material-ui/icons/ArrowUpwardOutlined';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import FilterListIcon from '@material-ui/icons/FilterList';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';

import SearchIcon from '@material-ui/icons/Search';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import RemoveIcon from '@material-ui/icons/Remove';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
//import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Constants } from '../../lib/Constants';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(0),
    borderRadius: '0px',
  },
  bigger: {
    fontSize: '1.7em',
  },
});

class TextTemplates extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetching: true,
      states: {},
      templates: [],
    };
    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  async newTemplate(newData) {
    //if ( newData?.title?.trim() === "") {
    //	return false;
    //	//"Name fehlt!";
    //}
    if (newData?.text?.trim() === '') {
      return false;
      //"Beschreibung fehlt!";
    }

    let url = new URL(process.env.REACT_APP_API_URL + '/api/customer/templates');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(newData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response.success) {
      let tmp = this.state.templates;
      newData.id = response.id;
      tmp.push(newData);
      this.setState({ templates: tmp });
    }
    return true;
  }

  async updateTemplate(editData) {
    if (!!editData?.id === false) {
      alert('ID fehlt! Sollte nicht passieren...');
      return 'ID fehlt!';
    }
    //if ( editData?.title?.trim() === "") {
    //	alert("Name fehlt!");
    //	return "Name fehlt!";
    //}

    editData['_method'] = 'PUT';
    let url = new URL(process.env.REACT_APP_API_URL + '/api/customer/templates');
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(editData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response.success) {
      console.log('imHere');
      let tmp = this.state.templates;
      console.log(tmp);
      let index = tmp.findIndex((element) => element.id === editData.id);
      console.log(index);
      tmp[index] = editData;
      this.setState({ templates: tmp });
    }
    return true;
  }

  async deleteTemplate(deleteData) {
    if (!!deleteData?.id === false) {
      return 'ID fehlt!';
    }
    console.log(deleteData);

    let tmp = this.state.templates.filter((element) => element.id !== deleteData.id);
    this.setState({ templates: tmp });

    deleteData['_method'] = 'DELETE';
    let url = new URL(process.env.REACT_APP_API_URL + '/api/customer/templates');
    //const response =
    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(deleteData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });
    return true;
  }

  async componentDidMount() {
    let tmp = await fetch(process.env.REACT_APP_API_URL + '/api/customer/templates', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });

    this.setState({
      templates: tmp,
      isFetching: false,
    });
  }

  render() {
    const { classes } = this.props;
    const iconMap = Constants.iconMap();

    return (
      <>
        <Paper className={classes.root} square>
          <MaterialTable
            isLoading={this.state.isFetching}
            title='Textbausteine'
            columns={[
              { title: 'Id', field: 'id', hidden: true },
              {
                title: 'Icon',
                cellStyle: { width: '10%' },
                hidden: false,
                initialEditValue: '',
                emptyValue: '',
                field: 'type',
                editComponent: (props) => (
                  <Select
                    variant='outlined'
                    value={props.value}
                    onChange={(event, value) => {
                      props.onChange(event.target.value);
                    }}
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          display: 'flex',
                          flexFlow: 'wrap',
                          maxWidth: '300px',
                          fontSize: '120%',
                        },
                      },
                    }}
                    style={{ fontSize: '200%' }}
                  >
                    {Object.values(iconMap).map((value, index) => (
                      <MenuItem className={classes.bigger} key={value.value} value={value.value}>
                        {value.icon}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                ),
                render: (rowData) => {
                  let tmp = Constants.getInboxItemType(rowData.type);
                  return (
                    <Typography variant='h4'>
                      {!!tmp ? tmp.icon : <RemoveIcon color='inherit' fontSize='inherit' />}
                    </Typography>
                  );
                },
              },
              {
                title: 'Name',
                field: 'title',
                initialEditValue: '',
                emptyValue: '',
                cellStyle: { width: '20%' },
              },
              {
                title: 'Text',
                field: 'text',
                cellStyle: { width: 'auto', maxWidth: '62%' },
                editComponent: (props) => (
                  <TextField
                    label='Hinzugefügter Text'
                    rows='3'
                    variant='outlined'
                    fullWidth
                    multiline
                    value={props.value}
                    onChange={(event) => {
                      props.onChange(event.target.value);
                    }}
                  />
                ),
                render: (rowData) => (
                  <Typography
                    component='pre'
                    style={{
                      width: '100%',
                      maxWidth: '100%',
                      whiteSpace: 'break-spaces',
                    }}
                    variant='body1'
                  >
                    {rowData.text}{' '}
                  </Typography>
                ),
              },
            ]}
            data={this.state.isFetching ? [] : this.state.templates}
            options={{
              actionsColumnIndex: 5,
              actionsCellStyle: { width: '8%' },
              paging: false,
              headerStyle: { fontWeight: '800', fontSize: '120%' },
              maxBodyHeight:
                this.props.Dimensions.height -
                (this.props.Dimensions.appBarHeight +
                  (this.props.Dimensions.maxWidth < 600 ? 237 : 208)),
            }}
            localization={{
              grouping: {
                placeholder: 'Kopfzeilen hier her ziehen...',
              },
              pagination: {
                labelRowsSelect: 'Zeilen',
                labelDisplayedRows: '{from}-{to} von {count}',
                labelRowsPerPage: 'Zeilen pro Seite:',
                firstAriaLabel: 'Erste Seite',
                firstTooltip: 'Erste Seite',
                previousAriaLabel: 'Vorherige Seite',
                previousTooltip: 'Vorherige Seite',
                nextAriaLabel: 'Nächste Seite',
                nextTooltip: 'Nächste Seite',
                lastAriaLabel: 'Letzte Seite',
                lastTooltip: 'Letzte Seite',
              },
              toolbar: {
                searchPlaceholder: 'Suche...',
              },
              header: {
                actions: '',
              },
              body: {
                emptyDataSourceMessage: 'Keine Textbausteine hinterlegt...',
                filterRow: {
                  filterTooltip: 'nach ??? suchen',
                },
                editRow: {
                  deleteText: 'Sicher das Sie diesen Eintrag löschen wollen?',
                  saveTooltip: 'Sichern',
                  cancelTooltip: 'Zurück',
                },
              },
            }}
            icons={{
              Search: SearchIcon,
              Clear: ClearIcon,
              Add: AddIcon,
              Check: CheckIcon,
              Delete: DeleteOutlineIcon,
              DetailPanel: ArrowUpwardOutlinedIcon,
              Edit: EditIcon,
              Export: SaveAltIcon,
              Filter: FilterListIcon,
              FirstPage: FirstPageIcon,
              LastPage: LastPageIcon,
              NextPage: ChevronRightIcon,
              PreviousPage: ChevronLeftIcon,
              ResetSearch: ClearIcon,
              SortArrow: ArrowUpwardIcon,
              ThirdStateCheck: RemoveIcon,
              ViewColumn: ViewColumnIcon,
            }}
            editable={{
              isEditable: (rowData) => true,
              isDeletable: (rowData) => true,
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  //if( !newData?.title || newData?.title?.trim() === "" ) {
                  //	alert("Name fehlt!");
                  //	return reject();
                  //}
                  if (!newData?.text || newData?.text?.trim() === '') {
                    alert('Beschreibung fehlt!');
                    return reject();
                  }
                  resolve(this.newTemplate(newData));
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  //if( !newData?.title || newData?.title?.trim() === "" ) {
                  //	alert("Name fehlt!");
                  //	return reject();
                  //}
                  if (!newData?.text || newData?.text?.trim() === '') {
                    alert('Beschreibung fehlt!');
                    return reject();
                  }
                  resolve(this.updateTemplate(newData));
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  resolve(this.deleteTemplate(oldData));
                }),
            }}
          />
        </Paper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TextTemplates);
