import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  disabled: {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
  },
  active: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  alignEnd: {
    justifyContent: 'end',
  },
  cardHeaderAction: {
    margin: 0,
    height: '100%',
    maxWidth: '33vw',
    minWidth: '20vw',
    [theme.breakpoints.up('md')]: {
      minWidth: '33vw',
    },
  },
});

class AssignItemCard extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card raised={true}>
        <CardContent>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={1}
          >
            <Grid item xs={2} md={1}>
              <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                <DeleteIcon />
              </Avatar>
            </Grid>
            <Grid item xs={10} md={7}>
              <Typography variant='h5'>{TextDE.inbox.deleteLabel}</Typography>
              <Typography variant='subtitle1'>
                {!!this.props.isCompetitive
                  ? TextDE.inbox.alerts.cantDeleteCompetetive
                  : TextDE.inbox.deleteHint}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                size='large'
                fullWidth
                className={!!this.props.isCompetitive ? classes.disabled : classes.active}
                startIcon={<DeleteIcon />}
                color='secondary'
                variant='contained'
                disabled={!!this.props.isCompetitive}
                onClick={this.props.openDeleteDialog}
              >
                {TextDE.delete}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AssignItemCard);
