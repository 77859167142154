import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Zoom from '@material-ui/core/Zoom';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';

import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/de';
import 'moment/locale/fr';

import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import { DE as TextDE } from '../../../../../lib/Text';

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    minWidth: '50vw',
    maxWidth: '90vw',
    //minHeight: '30vh',
    maxHeight: '90vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  input: {
    backgroundColor: 'antiquewhite',
    fontSize: '1.5em',
  },
  buttonGroup: {
    width: '100%',
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class PerformanceDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      [props.name]: this.props.data,
      hasError: false,
    };
  }

  handleDateChange = (date) => {
    this.setState({ [this.props.name]: date });
  };
  save = async () => {
    this.setState({
      isSubmitting: true,
    });
    // call to api ...
    console.log(this.props, this.state);
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append(this.props.name, this.state[this.props.name].format('DD.MM.YYYY'));

    await fetch(process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState(
          {
            [this.props.name]: this.state[this.props.name],
            isSubmitting: false,
          },
          () => this.props.onChange(this.props.name, this.state[this.props.name]),
        );
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
      });
  };
  render() {
    const { classes } = this.props;

    return (
      <Backdrop className={classes.backdrop} open={this.props.open}>
        <Zoom in={this.props.open}>
          {!!this.state.isSubmitting ? (
            <Paper elevation={4} className={classes.paper} component='center'>
              <CircularProgress />
            </Paper>
          ) : (
            <Paper elevation={4} className={classes.paper}>
              <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'de'}>
                <KeyboardDatePicker
                  label={TextDE.performanceReport.performanceDate}
                  inputVariant='outlined'
                  fullWidth
                  disableToolbar
                  format='DD.MM.YYYY'
                  margin='none'
                  inputProps={{ className: classes.input }}
                  name={this.props.name}
                  value={this.state[this.props.name]}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              {!!this.state.hasError && (
                <Alert severity='error' className={classes.center}>
                  <AlertTitle>
                    {this.state.hasError?.status ?? 'Fehler'} -{' '}
                    {this.state.hasError?.statusText ?? 'Fehler'}
                  </AlertTitle>
                  {JSON.stringify(this.state.hasError, null, 4)}
                </Alert>
              )}
              <div className={classes.buttonGroup}>
                <Button
                  variant='outlined'
                  startIcon={<CloseIcon />}
                  color='secondary'
                  onClick={() => this.props.onChange(this.props.name, this.props.data)}
                >
                  {TextDE.close}
                </Button>
                <Button
                  endIcon={<SaveIcon />}
                  variant={this.state[this.props.name].isValid() ? 'contained' : 'outlined'}
                  color='primary'
                  disabled={!this.state[this.props.name].isValid()}
                  onClick={this.save}
                >
                  {TextDE.save}
                </Button>
              </div>
            </Paper>
          )}
        </Zoom>
      </Backdrop>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

export default compose(withStyles(styles), connect(mapStateToProps))(PerformanceDate);
