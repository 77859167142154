import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import { createBrowserHistory } from 'history';

import { createStateSyncMiddleware } from 'redux-state-sync';

import createRootReducer from '../reducers/createRootReducer';

export const history = createBrowserHistory();

export default function configureStore(preloadedState) {
  const store = createStore(
    createRootReducer(history), // root reducer with router state
    preloadedState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        createStateSyncMiddleware({
          whitelist: [
            'FETCH_AUTH',
            'RESET_AUTH',
            'SET_SESSION',
            'REMOVE_NAV_MENU',
            'REMOVE_INBOX',
            'REMOVE_NOTFICATIONS',
            'AUTO_FETCH_RESET',
            'AUTO_FETCH_USER',
            'AUTO_FETCH_CUSTOMER',
            'AUTO_FETCH_OTHER',
            //'SET_ECHO',
            'APPEND_INBOX',
            'REMOVE_INBOX',
            'CLEAR_INBOX',
            //'RESET_NOTFICATIONS',
          ],
        }),
        thunk,
      ),
    ),
  );
  return store;
}

export function persistStore(
  store,
  filter = ['Authentication', 'User', 'NavMenu', 'Style', 'NewVersion'],
) {
  const state = store.getState();
  filter.forEach((element) => {
    if (!!state[element]) {
      let value = null;

      if (typeof state[element] !== 'string') {
        value = JSON.stringify(state[element]);
      } else {
        value = state[element];
      }
      //if(element === "Authentication") {
      //  console.log(typeof value, typeof localStorage.getItem(element) );
      //  console.log( 'Comparing "'+element+'", Result (is same):  ' + (localStorage.getItem(element) === value) , localStorage.getItem(element) , value );
      //  console.log( localStorage.getItem(element).length, value.length);
      //}
      if (localStorage.getItem(element) !== value) {
        localStorage.setItem(element, value);
        console.log(`[ ↳ Persisted '${element}']`, value.substring(0, 30) + '...');
      }
    }
  });
}
