import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';

const CustomDatePicker = (props) => {
  const [date, setDate] = useState(null);

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          style={{ flexGrow: 1, justifyContent: 'center' }}
          disableFuture
          margin='none'
          id='date-picker-dialog'
          format='dd.MM.yyyy'
          clearable={true}
          value={date}
          onChange={(event) => {
            //console.log("Date picker value: ", event);
            //console.log(props.columnDef.tableData.id);
            if (event === null) {
              setDate(null);
              props.onFilterChanged(props.columnDef.tableData.id, null);
            } else {
              setDate(event);
              props.onFilterChanged(
                props.columnDef.tableData.id,
                `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`,
              );
            }
          }}
        />
      </MuiPickersUtilsProvider>
      <IconButton
        aria-label='delete'
        onClick={() => {
          setDate(null);
          props.onFilterChanged(props.columnDef.tableData.id, null);
        }}
      >
        <ClearIcon />
      </IconButton>
    </div>
  );
};
export default CustomDatePicker;
