import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { getTheme as fetchTheme, getLogo as fetchLogo } from '../../actions/Style';
import { compose } from 'react-recompose';
import { alpha, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { createTheme } from '@material-ui/core/styles';
import { SketchPicker } from 'react-color';

// ERROR
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import hwhv from './../../assets/hw+hv.png';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ImageIcon from '@material-ui/icons/Image';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SaveIcon from '@material-ui/icons/Save';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import { DropzoneArea } from 'material-ui-dropzone';
import { getCroppedImg } from '../../lib/cropImage';
import FormatSizeIcon from '@material-ui/icons/FormatSize';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  card: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '29.25%', // 16:9
    backgroundSize: 'contain',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },

  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  example: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
});

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

class ThemePicker extends React.Component {
  async submitNewTheme(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true, hasError: false });
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('background', this.state.config.background);
    formData.append('primary', this.state.config.primary);
    formData.append('secondary', this.state.config.secondary);
    formData.append('fontSize', parseInt(this.state.config.fontSize));
    if (!!this.state.croppedImage) {
      let blob = await fetch(this.state.croppedImage).then((r) => r.blob());
      formData.append('logo', blob, this.state.filename || 'logo.png');
    }

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/customers/theme', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response) {
      this.setState({ isSubmitting: false, hasError: false });
      this.props.dispatch(fetchTheme(response));
      this.props.dispatch(fetchLogo(response));
      //this.props.dispatch(updateCompany(response))
    } else {
      this.setState({ isSubmitting: false, hasError: true });
    }
  }

  handlePrimaryColorChange = (color) => {
    let newTheme = createTheme({
      palette: {
        background: { html: this.state.config.background, default: this.state.config.background },
        primary: { main: color.hex },
        secondary: { main: this.state.config.secondary },
      },
      typography: { fontSize: parseInt(this.state.config.fontSize) },
    });
    this.setState({
      config: { ...this.state.config, primary: color.hex },
      theme: newTheme,
    });
  };
  handleSecondaryColorChange = (color) => {
    let newTheme = createTheme({
      palette: {
        background: { html: this.state.config.background, default: this.state.config.background },
        secondary: { main: color.hex },
        primary: { main: this.state.config.primary },
      },
      typography: { fontSize: parseInt(this.state.config.fontSize) },
    });
    this.setState({
      config: { ...this.state.config, secondary: color.hex },
      theme: newTheme,
    });
  };
  handleBackgroundColorChange = (color) => {
    let newTheme = createTheme({
      palette: {
        background: { html: color.hex, default: color.hex },
        secondary: { main: this.state.config.secondary },
        primary: { main: this.state.config.primary },
      },
      typography: { fontSize: parseInt(this.state.config.fontSize) },
    });
    this.setState({
      config: { ...this.state.config, background: color.hex },
      theme: newTheme,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      hasError: false,
      config: {
        primary: this.props.Theme.palette.primary.main,
        secondary: this.props.Theme.palette.secondary.main,
        background:
          this.props.Theme.palette.background.html || this.props.Theme.palette.background.default,
        fontSize: parseInt(this.props.Theme.typography.fontSize),
      },

      imageSrc: !!this.props.Logo?.dataUrl ? this.props.Logo.dataUrl : null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      zoomStepping: 0.03,
      zoomMin: 0.3,
      zoomMax: 2,
      aspect: 4 / 3,
      theme: createTheme({
        palette: {
          background: {
            default: this.props.Theme.palette.background.default,
            html: this.props.Theme.palette.background.default,
          },
          secondary: { main: this.props.Theme.palette.secondary.main },
          primary: { main: this.props.Theme.palette.primary.main },
        },
        typography: {
          fontSize: parseInt(this.props.Theme.typography.fontSize),
        },
      }),
      croppedImage: null,
    };

    this.submitNewTheme = this.submitNewTheme.bind(this);
  }

  onCropChange = (crop) => {
    this.setState({ crop: crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setCroppedImage(croppedAreaPixels);
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom: zoom });
  };

  onZoomOutClick = () => {
    this.setState({ zoom: this.state.zoom - this.state.zoomStepping });
  };

  onZoomInClick = () => {
    this.setState({ zoom: this.state.zoom + this.state.zoomStepping });
  };

  setFontSizeSmall = () => {
    let newTheme = createTheme({
      typography: { fontSize: 10 },
      palette: {
        background: { html: this.state.config.background },
        secondary: { main: this.state.config.secondary },
        primary: { main: this.state.config.primary },
      },
    });
    this.setState({
      config: { ...this.state.config, fontSize: 10 },
      theme: newTheme,
    });
  };
  setFontSizeMedium = () => {
    let newTheme = createTheme({
      typography: { fontSize: 14 },
      palette: {
        background: { html: this.state.config.background },
        secondary: { main: this.state.config.secondary },
        primary: { main: this.state.config.primary },
      },
    });
    this.setState({
      config: { ...this.state.config, fontSize: 14 },
      theme: newTheme,
    });
  };
  setFontSizeLarge = () => {
    let newTheme = createTheme({
      typography: { fontSize: 18 },
      palette: {
        background: { html: this.state.config.background },
        secondary: { main: this.state.config.secondary },
        primary: { main: this.state.config.primary },
      },
    });
    this.setState({
      config: { ...this.state.config, fontSize: 18 },
      theme: newTheme,
    });
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const filename = e.target.files[0].name;
      const imageDataUrl = await readFile(e.target.files[0]);
      this.setState({
        filename: filename,
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedImage: null,
      });
    }
  };

  handleFileChange = async (files) => {
    if (typeof files[0] === 'object') {
      const imageDataUrl = await readFile(files[0]);
      this.setState({
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        zoom: 0.66,
        croppedImage: null,
      });
    }
  };

  setCroppedImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(this.state.imageSrc, croppedAreaPixels);
    this.setState({ croppedImage });
  };

  componentDidMount() {}

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root} square>
          {this.state.isSubmitting ? (
            <center style={{ minHeight: '30vh' }}>
              <CircularProgress style={{ marginTop: '13vh' }} />
            </center>
          ) : !!this.state.error ? (
            <center>
              {' '}
              <ErrorOutlineIcon fontSize='large' color='error' />{' '}
              <Typography variant='h6'> {this.state.error.error} </Typography>{' '}
            </center>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} md={12}>
                <Grid item xs={12} md={12}>
                  <Typography variant='h4'> Einstellungen Firmenlayout </Typography>
                </Grid>
                <Grid
                  container
                  direction='row'
                  justifyContent='flex-start'
                  alignItems='flex-start'
                  className={classes.root}
                  spacing={2}
                >
                  <Grid item xs={12} lg={4}>
                    <Typography variant='h4' align='left' gutterBottom>
                      {' '}
                      Logo{' '}
                    </Typography>
                    <div style={{ display: 'none' }}>
                      <DropzoneArea
                        dropzoneText='Datei hier ablegen, oder klicken.'
                        filesLimit={1}
                        showPreviewsInDropzone={true}
                        showFileNames={true}
                        showAlerts={true}
                      />
                    </div>
                    {this.state.imageSrc && (
                      <>
                        <div
                          style={{
                            width: '100%',
                            height: '200px',
                            position: 'relative',
                          }}
                        >
                          <Cropper
                            minZoom={0.3}
                            maxZoom={2}
                            image={this.state.imageSrc}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={75 / 24}
                            cropShape='rect'
                            showGrid={false}
                            restrictPosition={false}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                          />
                        </div>
                        <Grid container spacing={2}>
                          <Grid item>
                            <ZoomOutIcon onClick={this.onZoomOutClick} />
                          </Grid>
                          <Grid item xs>
                            <Slider
                              value={this.state.zoom}
                              min={this.state.zoomMin}
                              max={this.state.zoomMax}
                              step={this.state.zoomStepping}
                              aria-labelledby='Zoom'
                              onChange={(e, zoom) => this.onZoomChange(zoom)}
                            />
                          </Grid>
                          <Grid item>
                            <ZoomInIcon onClick={this.onZoomInClick} />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <input
                      accept='image/*'
                      style={{ display: 'none' }}
                      id='contained-button-file'
                      multiple
                      type='file'
                      onChange={this.onFileChange}
                    />
                    <label htmlFor='contained-button-file'>
                      <Button variant='outlined' color='primary' component='span' fullWidth>
                        <ImageIcon /> Neues Logo hochladen
                      </Button>
                    </label>
                  </Grid>

                  <Grid item xs={12} lg={2} component='center'>
                    <Typography variant='h4' align='left' gutterBottom>
                      {' '}
                      Primärfarbe{' '}
                    </Typography>
                    <SketchPicker
                      name='primary'
                      disableAlpha={true}
                      presetColors={[]}
                      color={this.state.config.primary}
                      onChangeComplete={this.handlePrimaryColorChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2} component='center'>
                    <Typography variant='h4' align='left' gutterBottom>
                      {' '}
                      Sekundärfarbe{' '}
                    </Typography>
                    <SketchPicker
                      name='secondary'
                      disableAlpha={true}
                      presetColors={[]}
                      color={this.state.config.secondary}
                      onChangeComplete={this.handleSecondaryColorChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2} component='center'>
                    <Typography variant='h4' align='left' gutterBottom>
                      {' '}
                      Hintergrund{' '}
                    </Typography>
                    <SketchPicker
                      name='background'
                      disableAlpha={true}
                      presetColors={[]}
                      color={this.state.config.background}
                      onChangeComplete={this.handleBackgroundColorChange}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2}>
                    <Grid container style={{ marginBottom: '1em' }} direction='column' spacing={4}>
                      <Grid item>
                        <Typography variant='h4' align='left' gutterBottom>
                          Schriftgröße
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={this.state.config.fontSize === 10 ? 'contained' : 'outlined'}
                          color='primary'
                          fullWidth
                          startIcon={<FormatSizeIcon fontSize='small' />}
                          onClick={this.setFontSizeSmall}
                        >
                          <Typography variant='body1' style={{ fontSize: 10 }}>
                            Klein
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={this.state.config.fontSize === 14 ? 'contained' : 'outlined'}
                          color='primary'
                          fullWidth
                          startIcon={<FormatSizeIcon fontSize='medium' />}
                          onClick={this.setFontSizeMedium}
                        >
                          <Typography variant='body1' style={{ fontSize: 14 }}>
                            Mittel
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant={this.state.config.fontSize === 18 ? 'contained' : 'outlined'}
                          color='primary'
                          fullWidth
                          startIcon={<FormatSizeIcon fontSize='large' />}
                          onClick={this.setFontSizeLarge}
                        >
                          <Typography variant='body1' style={{ fontSize: 18 }}>
                            Groß
                          </Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} component='center'>
                    <Button
                      variant='contained'
                      fullWidth
                      color='primary'
                      component='span'
                      onClick={this.submitNewTheme}
                    >
                      <SaveIcon /> Speichern
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid className={classes.example} item xs={12} lg={12}>
                <Box m={4}>
                  <Typography className={classes.title} variant='h6' noWrap>
                    Vorschau Firmenlayout
                  </Typography>
                  <Paper
                    elevation={24}
                    square={true}
                    style={{ margin: '8px 8px 0 0', border: '1px solid #888888' }}
                  >
                    <ThemeProvider theme={this.state.theme}>
                      <Paper>
                        <AppBar position='static'>
                          <Toolbar style={{ paddingLeft: '0px' }}>
                            <div
                              style={{
                                width: '220px',
                                height: '64px',
                                backgroundSize: '100% auto',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundImage: 'url(' + this.state.croppedImage + ')',
                                backgroundColor: '#ffffff',
                                marginRight: '10px',
                              }}
                            >
                              {' '}
                            </div>
                            <Typography className={classes.title} variant='h6' noWrap>
                              Dashboard
                            </Typography>

                            <div className={classes.grow} />
                            <div className={classes.sectionDesktop}>
                              <IconButton aria-label='show 4 new mails' color='inherit'>
                                <Badge badgeContent={4} color='secondary' overlap='circular'>
                                  <MailIcon />
                                </Badge>
                              </IconButton>
                              <IconButton aria-label='show 17 new notifications' color='inherit'>
                                <Badge badgeContent={17} color='secondary' overlap='circular'>
                                  <NotificationsIcon />
                                </Badge>
                              </IconButton>
                              <IconButton
                                edge='end'
                                aria-label='account of current user'
                                aria-haspopup='true'
                                color='inherit'
                              >
                                <AccountCircle />
                              </IconButton>
                            </div>
                          </Toolbar>
                        </AppBar>
                        <div
                          style={{
                            width: '100%',
                            padding: '0px',
                            margin: '0px',
                            display: 'flex',
                          }}
                        >
                          <div
                            style={{
                              width: '220px',
                              maxWidth: '220px',
                              minWidth: '220px',
                              margin: '0 0 0 0',
                              padding: 0,
                              backgroundColor: '#ffffff',
                              borderRight: '1px solid #DDDDDD',
                            }}
                            role='presentation'
                          >
                            <List>
                              {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                                <ListItem button key={text}>
                                  <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                  </ListItemIcon>
                                  <ListItemText primary={text} />
                                </ListItem>
                              ))}
                            </List>
                          </div>
                          <Grid
                            style={{
                              flexGrow: '1',
                              margin: 0,
                              background: this.state.config.background,
                            }}
                            container
                            className={classes.root}
                            spacing={2}
                          >
                            <Grid item xs={5}>
                              <Card className={classes.card}>
                                <CardHeader
                                  avatar={<Avatar aria-label='example'>E</Avatar>}
                                  action={
                                    <IconButton aria-label='settings'>
                                      <MoreVertIcon />
                                    </IconButton>
                                  }
                                  title='Einfach nur ein Beispiel'
                                  subheader='14.September 2019'
                                />
                                <CardMedia
                                  className={classes.media}
                                  image={hwhv}
                                  title='Beispiel Bild'
                                />
                                <CardContent>
                                  <Typography variant='body2' color='textSecondary' component='p'>
                                    Hier steht ein Beispieltext, dieser hat keinen Mehrwehrt ausser
                                    um die Schriftgröße und Kontraste auszuprobieren.
                                  </Typography>
                                </CardContent>
                                <CardActions disableSpacing>
                                  <IconButton aria-label='add to favorites'>
                                    <FavoriteIcon />
                                  </IconButton>
                                  <IconButton aria-label='share'>
                                    <ShareIcon />
                                  </IconButton>
                                </CardActions>
                              </Card>
                            </Grid>
                            <Grid item xs={7}>
                              <Card className={classes.card}>
                                <CardContent>
                                  <TextField
                                    required
                                    label='Benötigtes Feld'
                                    defaultValue='Hello World'
                                    fullWidth
                                    className={classes.mt}
                                  />
                                  <TextField
                                    disabled
                                    label='Deaktiviertes Feld'
                                    defaultValue='Hello World'
                                    fullWidth
                                    className={classes.mt}
                                  />
                                  <Divider />
                                  <Button
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    className={classes.mt}
                                  >
                                    Primärfarbe
                                  </Button>
                                  <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    className={classes.mt}
                                  >
                                    Sekundärfarbe
                                  </Button>
                                  <Divider />
                                  <Button fullWidth variant='contained' className={classes.mt}>
                                    Standardfarbe
                                  </Button>
                                  <Button
                                    fullWidth
                                    variant='contained'
                                    disabled
                                    className={classes.mt}
                                  >
                                    Deaktiviert
                                  </Button>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </div>
                      </Paper>
                    </ThemeProvider>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          )}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Logo: state.Style.Logo,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ThemePicker);
