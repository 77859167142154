import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateCompany } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import CompanyLogo from './CompanyLogo';

import PhoneIcon from '@material-ui/icons/Phone';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import LocationOnIcon from '@material-ui/icons/LocationOn';

import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';

import TestSlider from './TestSlider';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },

  submit: {
    margin: theme.spacing(3, 0, 1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  container: {
    position: 'relative',
  },
  results: {
    borderRoundedTopLeft: '0px',
    borderRoundedTopRight: '0px',
    backgroundColor: '#ffffff',
    width: '100%',
    position: 'absolute',
    top: '100%',
    left: '0',
    zIndex: '1',
  },
  result: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#cccccc',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
});

class UserConfigForm extends React.Component {
  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        //componentForm[addressType] = val;
        //console.log(addressType, val);
        //setCompanyState({...companyState, [addressType]: val});
        newAddress[addressType] = val;
      }
    }
    Object.assign(this.state, newAddress);
    this.setState(this.state);
    this.setState({ ...this.state, address: address });
    this.setState({ search: true });
  };

  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true, hasError: false });
    let formData = {};
    Object.keys(this.state).forEach((value, index) => {
      if (value === 'allTrades' || value === 'allTradeTags' || value === 'data') {
        return;
      }
      if (typeof this.state[value] === 'string' || typeof this.state[value] === 'boolean') {
        formData[value] = this.state[value];
      } else if (value === 'working_hours') {
        formData[value] = JSON.stringify(this.state[value]);
      } else if (typeof this.state[value] === 'object') {
        var tmp = [];
        this.state[value].forEach((insideValue, insideIndex) => {
          tmp.push(insideValue);
        });
        formData[value] = tmp;
      }
    });
    formData['_method'] = 'PUT';

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.company) {
      this.setState({ isSubmitting: false, hasError: false, data: response });
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isSubmitting: false, hasError: true, data: response });
    }
  }

  handleSelect = (address) => {
    this.setState({ toggleAddress: true });
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  handleHiddenChange = () => {
    this.setState({ hidden: !!!this.state.hidden });
  };

  constructor() {
    super();
    this.state = {
      name: '',
      description: '',
      mobile: '',
      phone: '',
      email: '',
      postal_code: '',
      locality: '',
      country: '',
      administrative_area_level_1: '',
      route: '',
      street_number: '',
      address: '',
      isFetching: false,
      hasError: false,
      trades: [],
      trade_tags: [],
      working_hours: null,
      proximity: 100,
      hidden: true,
      allTrades: [],
      allTradeTags: [],
    };

    //this.handleChange = this.handleChange.bind(this)
    this.refreshState = this.refreshState.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.tradesChange = this.tradesChange.bind(this);
    this.tradeTagsChange = this.tradeTagsChange.bind(this);
    this.workingHoursChange = this.workingHoursChange.bind(this);
    this.handleHiddenChange = this.handleHiddenChange.bind(this);
    this.fetchTrades = this.fetchTrades.bind(this);
    this.fetchTradeTags = this.fetchTradeTags.bind(this);
  }
  componentDidMount() {
    this.refreshState();
  }

  tradesChange(name, value) {
    this.setState({ ...this.state, trades: value });
  }
  tradeTagsChange(name, value) {
    this.setState({ ...this.state, trade_tags: value });
  }

  workingHoursChange(day, data) {
    this.setState({
      ...this.state,
      working_hours: {
        ...this.state.working_hours,
        days: {
          ...this.state.working_hours.days,
          [day]: data,
        },
      },
    });
  }

  async fetchTrades() {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/trades', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => response)
      .catch((error) => console.error(error));

    if (response.trades != null && response.trades.length > 0) {
      this.setState({ allTrades: response.trades });
    }
  }
  async fetchTradeTags() {
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies/tradetags', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => response)
      .catch((error) => console.error(error));

    if (response?.tradeTags != null && response?.tradeTags.length > 0) {
      this.setState({ allTradeTags: response.tradeTags });
    }
  }

  refreshState() {
    this.fetchTrades();
    this.fetchTradeTags();
    let address = '';

    if (
      !!this.props.User &&
      !!this.props.User.customer &&
      !!this.props.User.customer.company &&
      !!this.props.User.customer.company.address &&
      !!this.props.User.customer.company.address.route &&
      !!this.props.User.customer.company.address.street_number &&
      !!this.props.User.customer.company.address.city.locality &&
      !!this.props.User.customer.company.address.city.country
    ) {
      address =
        this.props.User.customer.company.address.route +
        ' ' +
        this.props.User.customer.company.address.street_number +
        ', ' +
        this.props.User.customer.company.address.city.locality +
        ', ' +
        this.props.User.customer.company.address.city.country;
    }

    this.setState({
      name: this.props.User.customer.company?.name ?? '',
      description: this.props.User.customer.company?.description ?? '',
      mobile: this.props.User.customer.company?.mobile ?? this.props.User.mobile ?? '',
      phone: this.props.User.customer.company?.phone ?? this.props.User.phone ?? '',
      email: this.props.User.customer.company?.email ?? this.props.User.email ?? '',
      postal_code: this.props.User.customer.company?.address?.postal_code ?? '',
      locality: this.props.User.customer.company?.address?.city?.locality ?? '',
      country: this.props.User.customer.company?.address?.city?.country ?? '',
      administrative_area_level_1: this.props.User.customer.company?.address?.city?.state ?? '',
      route: this.props.User.customer.company?.address?.route ?? '',
      street_number: this.props.User.customer.company?.address?.street_number ?? '',
      address: address,
      trades: this.props.User.customer.company?.trades.map((value, index) => value.name) ?? [],
      trade_tags:
        this.props.User.customer.company?.trade_tags.map((value, index) => value.name) ?? [],
      working_hours: !!this.props.User.customer.company?.working_hours
        ? JSON.parse(this.props.User.customer.company?.working_hours)
        : {},
      proximity: this.props.User.customer.company?.proximity ?? 100,
      hidden: this.props.User.customer.company?.hidden ?? true,
    });
  }
  render() {
    const searchOptions = {
      types: ['address'],
    };

    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root} square>
          {this.state.isFetching ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <form key='companyform' onSubmit={this.handleFormSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={4} style={{ marginTop: '38px', marginBottom: 'auto' }}>
                  <CompanyLogo />
                </Grid>
                <Grid item xs={12} md={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        required
                        name='name'
                        label='Firmenname'
                        autoComplete='cname'
                        value={this.state.name}
                        onChange={this.handleChange()}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <TextField
                        fullWidth
                        label='Beschreibung'
                        multiline
                        maxRows='4'
                        name='description'
                        value={this.state.description}
                        onChange={this.handleChange()}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        multiple
                        autoSelect
                        options={this.state.allTrades}
                        value={this.state.trades}
                        onChange={this.tradesChange}
                        name='trades'
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant='outlined'
                              color='primary'
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            label='Gewerk/e'
                            placeholder='Welche Gewerke bedienen Sie?'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Autocomplete
                        multiple
                        autoSelect
                        options={this.state.allTradeTags}
                        value={this.state.trade_tags}
                        onChange={this.tradeTagsChange}
                        name='trades'
                        freeSolo
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              variant='outlined'
                              color='primary'
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            variant='outlined'
                            label='Beschreibungen'
                            placeholder='Welche Tätigkeiten führen Sie in den genannten Gewerken aus?'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <FormControlLabel
                        onClick={this.handleHiddenChange}
                        control={
                          <Checkbox checked={!!!this.state.hidden} name='hidden' color='primary' />
                        }
                        label='Möchten Sie in der Firmenauflistung und unserer Auftragsvermittlung erscheinen?'
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id='mobile'
                        name='mobile'
                        label={
                          <>
                            <PhoneAndroidIcon fontSize='inherit' /> Mobiltelefon
                          </>
                        }
                        autoComplete='tel'
                        value={this.state.mobile}
                        onChange={this.handleChange()}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        id='phone'
                        name='phone'
                        label={
                          <>
                            <PhoneIcon fontSize='inherit' /> Telefonnummer
                          </>
                        }
                        autoComplete='tel'
                        value={this.state.phone}
                        onChange={this.handleChange()}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id='email'
                        name='email'
                        label={
                          <>
                            <AlternateEmailIcon fontSize='inherit' /> E-Mail
                          </>
                        }
                        autoComplete='email'
                        value={this.state.email}
                        onChange={this.handleChange()}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <PlacesAutocomplete
                        value={this.state.address}
                        name='address'
                        onChange={this.handleChange()}
                        onSelect={this.handleSelect}
                        searchOptions={searchOptions}
                        shouldFetchSuggestions={this.state.address.length > 4}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className={classes.container}>
                            <TextField
                              fullWidth
                              label={
                                <>
                                  <LocationOnIcon fontSize='inherit' /> Anschrift
                                </>
                              }
                              autoComplete='address'
                              {...getInputProps({
                                placeholder: 'Ihre Adresse...',
                                className: 'location-search-input',
                              })}
                            />
                            {suggestions.length > 0 ? (
                              <Card className={classes.results}>
                                {suggestions.map((suggestion, index) => {
                                  //<CardContent key={suggestion.place_id} onClick={this.handleSelect} className={classes.result}>
                                  return (
                                    <CardContent
                                      key={index}
                                      {...getSuggestionItemProps(suggestion, {})}
                                      className={classes.result}
                                    >
                                      <Grid container alignItems='center'>
                                        <Grid item>
                                          <LocationOnIcon className={classes.icon} />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography variant='subtitle1'>
                                            {suggestion.formattedSuggestion.mainText}
                                          </Typography>
                                          <Typography variant='body1' color='textSecondary'>
                                            {suggestion.formattedSuggestion.secondaryText}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  );
                                })}
                              </Card>
                            ) : null}
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Grid>

                    <Grid
                      item
                      xs={8}
                      md={4}
                      style={{
                        display: !!this.state.toggleAddress ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='route'
                        label='Straße'
                        name='route'
                        value={this.state.route}
                        onChange={this.handleChange()}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      md={8}
                      style={{
                        display: !!this.state.toggleAddress ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='street_number'
                        label='Hausnummer'
                        name='street_number'
                        value={this.state.street_number}
                        onChange={this.handleChange()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={5}
                      md={3}
                      style={{
                        display: !!this.state.toggleAddress ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='postal_code'
                        label='Postleitzahl'
                        name='postal_code'
                        value={this.state.postal_code}
                        fullWidth
                        onChange={this.handleChange()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={7}
                      md={3}
                      style={{
                        display: !!this.state.toggleAddress ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='locality'
                        label='Stadt'
                        name='locality'
                        value={this.state.locality}
                        fullWidth
                        onChange={this.handleChange()}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        display: !!this.state.toggleAddress ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='country'
                        name='country'
                        label='Country'
                        value={this.state.country}
                        onChange={this.handleChange()}
                        fullWidth
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={3}
                      style={{
                        display: !!this.state.toggleAddress ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        id='administrative_area_level_1'
                        label='Bundesland'
                        name='administrative_area_level_1'
                        value={this.state.administrative_area_level_1}
                        onChange={this.handleChange()}
                        fullWidth
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{
                        display: !!this.state.address ? 'block' : 'none',
                      }}
                    >
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        placeholder='Aufträge im Umkreis von ... sind interessant für mich.'
                        id='proximity'
                        label={
                          'Aufträge im Umkreis von ' +
                          this.state.proximity +
                          ' Km sind interessant für mich.'
                        }
                        name='proximity'
                        value={this.state.proximity}
                        fullWidth
                        type='number'
                        onChange={this.handleChange()}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      {this.state.hasError ? (
                        <span className='error'>Fehler beim Aktualisieren der Daten...</span>
                      ) : (
                        ''
                      )}
                      <div className={classes.wrapper}>
                        <Button
                          type='submit'
                          variant='contained'
                          color='primary'
                          className={classes.submit}
                          disabled={this.state.isSubmitting}
                          fullWidth
                        >
                          Speichern
                        </Button>
                        {this.state.isSubmitting && (
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        )}
                      </div>
                    </Grid>
                    <Divider />
                    <Grid item xs={12} md={12}>
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={1}
                          data={this.state.working_hours.days[1]}
                          callback={(data) => this.workingHoursChange(1, data)}
                        />
                      )}
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={2}
                          data={this.state.working_hours.days[2]}
                          callback={(data) => this.workingHoursChange(2, data)}
                        />
                      )}
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={3}
                          data={this.state.working_hours.days[3]}
                          callback={(data) => this.workingHoursChange(3, data)}
                        />
                      )}
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={4}
                          data={this.state.working_hours.days[4]}
                          callback={(data) => this.workingHoursChange(4, data)}
                        />
                      )}
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={5}
                          data={this.state.working_hours.days[5]}
                          callback={(data) => this.workingHoursChange(5, data)}
                        />
                      )}
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={6}
                          data={this.state.working_hours.days[6]}
                          callback={(data) => this.workingHoursChange(6, data)}
                        />
                      )}
                      {!!this.state.working_hours?.days && (
                        <TestSlider
                          day={0}
                          data={this.state.working_hours.days[0]}
                          callback={(data) => this.workingHoursChange(0, data)}
                        />
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserConfigForm);
