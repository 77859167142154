import React from 'react';

function isHTML(str) {
  var doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
}

export function renderHtmlWithoutHeight(html) {
  return renderHtml(html, undefined, undefined, true, true);
}

function renderHtml(html, open, collapseFunction, noHeightLimit = false, renderAsHtml = null) {
  let collapsible = true;
  if (
    typeof open === 'undefined' ||
    typeof collapseFunction === 'undefined' ||
    (typeof open === 'undefined' && typeof collapseFunction === 'undefined') ||
    (open === false && collapseFunction === false)
  ) {
    collapsible = false;
  }

  let displayAsHtml = false;

  let iframeContent = false;
  let textContent = false;

  if (typeof html === 'undefined') {
    textContent = 'Keine Nachricht darstellbar...';
  } else if (typeof html === 'string') {
    textContent = html;
    if (isHTML(html)) {
      iframeContent = html;
      displayAsHtml = true;
    }
  } else if (!!!html.text && !!!html.html) {
    textContent = 'Keine Nachricht im Objekt.';
  } else {
    if (!!html.html) {
      iframeContent = html.html;
      displayAsHtml = true;
    }

    if (!!html.text) {
      textContent = html.text;
      if (iframeContent === false) {
        displayAsHtml = false;
      }
    }
  }

  if (textContent === false || textContent.toString() === '') {
    textContent = iframeContent;
  }
  textContent = textContent.toString().replace(/(<([^>]+)>)/gi, '');

  if (collapsible && noHeightLimit !== true) {
    return (
      <div
        onClick={() => {
          if (!!!open && typeof collapseFunction === 'function') {
            collapseFunction();
          }
        }}
        className={!!open ? 'renderedHtml' : 'multilineTruncate'}
      >
        {/* Collapsed VIEW */}
        {!!!open && <p className={!!open ? 'renderedText' : 'multilineTruncate'}>{textContent}</p>}

        {/* EXPANDED VIEW */}
        {!!open &&
          (!!displayAsHtml ? (
            <iframe
              title='Nachricht'
              sandbox='allow-same-origin'
              srcDoc={iframeContent}
              width='100%'
              height='700px'
              style={{ border: 'none' }}
              onLoad={(event) => {
                event.target.height =
                  event.target.contentWindow.document.body.scrollHeight + 48 + 'px';
              }}
            />
          ) : (
            <p className={!!open ? 'renderedText' : 'multilineTruncate'}> {textContent} </p>
          ))}
      </div>
    );
  }

  return (
    <div className={noHeightLimit ? 'renderedHtmlNoHeight' : 'renderedHtml'}>
      {!!displayAsHtml ? (
        <iframe
          title='Nachricht'
          sandbox='allow-same-origin'
          srcDoc={iframeContent}
          width='100%'
          height='700px'
          style={{ border: 'none' }}
          onLoad={(event) => {
            event.target.height = event.target.contentWindow.document.body.scrollHeight + 48 + 'px';
          }}
        />
      ) : (
        <p className={noHeightLimit ? 'renderedTextNoHeight' : 'renderedText'}> {textContent} </p>
      )}
    </div>
  );
}
export default renderHtml;
