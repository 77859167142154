import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

class Loading extends React.Component {
  render() {
    return (
      <center>
        <CircularProgress />
      </center>
    );
  }
}

export default Loading;
