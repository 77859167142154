import React, { createRef } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
//import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
//import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
//import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
//import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Divider from '@material-ui/core/Divider';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import { readable } from '../../../lib/dateFunctions';
import Fab from '@material-ui/core/Fab';
import { DE as TextDE } from '../../../lib/Text';

//import { Document } from 'react-pdf';
//import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const switchModelType = (type, api = false) => {
  if (typeof type === 'string' || type instanceof String) {
    type = type.toLowerCase();
    if (type.includes('procedure')) {
      return (!!api ? '/api' : '/auth') + '/file/procedure/';
    }

    if (type.includes('inbox')) {
      return (!!api ? '/api' : '/auth') + '/file/inboxitem/';
    }

    if (type.includes('job')) {
      return (!!api ? '/api' : '/auth') + '/file/job/';
    }
  }
  return '/false';
};

const styles = (theme) => ({
  fab: {
    zIndex: 999,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  mb: {
    marginBottom: theme.spacing(1),
  },
  card: {
    height: 'inherit',
    flexGrow: 1,
  },

  cardHeaderGrid: {
    width: 'auto',
    padding: '4px',
    '&>div': {
      width: '100%',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundColor: 'lightgrey',
  },
  imageViewer: {
    height: 'auto',
    width: 'auto',
    maxWidth: '100%',
  },
  buttongroup: {
    width: '100%',
    backgroundColor: '#ffffff',
    '&>button': {
      backgroundColor: '#f2f2f2ed',
    },
  },
  fixSize: {
    maxWidth: '100%',
    //maxHeight: '100%',
    overflow: 'auto',
    flexFlow: 'row',
    flexWrap: 'wrap',
    margin: 0,
    //[theme.breakpoints.up('md')]: {
    //  maxHeight: '40vh',
    //}
  },

  viewerBox: {
    marginBottom: theme.spacing(2),
    height: '70vh',
    display: 'flex',
    flexFlow: 'column',
    /*
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    overflow: 'none',
    top: 0,
    left: 0,
    padding: 0,
    zIndex: 1298,
    backgroundColor: 'rgba(0,0,0,0.87)',
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2),
    },
    */
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1),
    },
  },
  sizeMe: {
    backgroundColor: 'lightgrey',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.breakpoints.values.sm,
    },
    [theme.breakpoints.up('md')]: {
      height: '60vh',
      width: theme.breakpoints.values.md,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.values.lg,
    },
  },
  cardHeader: {
    marginBottom: 0,
    paddingBottom: 0,
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  cardcontent: {
    backgroundColor: 'lightgrey',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexGrow: 1,
    margin: theme.spacing(0.25, 1),
  },
  mediaBig: {
    height: '100%',
    margin: theme.spacing(0.25, 1),
    backgroundSize: 'contain',
    backgroundColor: 'lightgrey',
  },
  maxSized: {
    height: 'auto',
    overflow: 'clip',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      width: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '16.6666%',
    },
  },
  sizedIcons: {
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
  uploadMedia: {
    flexGrow: 1,
    overflow: 'clip',
    maxWidth: '100%',
    [theme.breakpoints.up('xs')]: {
      minWidth: '50%',
    },
    [theme.breakpoints.up('md')]: {
      minWidth: '25%',
    },
    [theme.breakpoints.up('lg')]: {
      minWidth: '16.6666%',
    },
  },
  draggedBg: {
    backgroundColor: theme.palette.success.light,
    display: 'block',
    padding: theme.spacing(2),
    height: 'calc( 100% - ' + theme.spacing(2) + 'px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  nonDraggedBg: {
    display: 'block',
    padding: theme.spacing(2),
    height: 'calc( 100% - ' + theme.spacing(2) + 'px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  dragged: {
    backgroundColor: theme.palette.success.contrastText,
    color: theme.palette.success.main,
  },
  nonDragged: {},
});

class FileCard extends React.Component {
  constructor(props) {
    super(props);

    this.scrollToRef = React.createRef();

    this.state = {
      hasError: false,
      newFiles: [],
      rejectedFiles: [],
      toDelete: false,
      selected: false,
      selectedData: null,
      filesDraggedIn: false,
    };

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);

    this.handleShareClick = this.handleShareClick.bind(this);

    this.downloadGallery = this.downloadGallery.bind(this);
    this.showGallery = this.showGallery.bind(this);
    this.nextGallery = this.nextGallery.bind(this);
    this.previousGallery = this.previousGallery.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
    this.setSelected = this.setSelected.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleDownloadData = this.handleDownloadData.bind(this);
  }

  componentDidMount() {
    //console.log("[FileCard] [Component Did Mount] Props:", this.props);
    //console.log("[FileCard] [Component Did Mount] State:", this.state);
    if (this.props.selectedMedia) {
      //console.log("We have selected Media!", this.props.selectedMedia);
      //console.log("All Media!", this.props.media);
      var key = this.props.media.findIndex((file) => file.uuid === this.props.selectedMedia.uuid);
      //console.log("Found key: ", key);
      this.showGallery(key);
    }
  }

  componentDidUpdate(oldProps, oldState) {
    //console.log("[FileCard] [Component Did Update] Props:", oldProps, this.props);
    //console.log("[FileCard] [Component Did Update] State:",  oldState, this.state, typeof this.state.selectedData, this.state.selectedData );
    if (oldProps.selectedMedia !== this.props.selectedMedia) {
      //console.log("We have selected Media!", this.props.selectedMedia);
      //console.log("All Media!", this.props.media);
      var key = this.props.media.findIndex((file) => file.uuid === this.props.selectedMedia.uuid);
      //console.log("Found key: ", key);
      this.showGallery(key);
    }
  }

  //componentWillUnmount() {
  //  console.log("[FileCard] [Component Will Unmount]",  this.props, this.state );
  //}

  handleOnDragEnter = () => {
    this.setState({ filesDraggedIn: true });
  };

  handleOnDragLeave = () => {
    this.setState({ filesDraggedIn: false });
  };

  handleOnDropRejected = (files) => {
    let rejectedFiles = files.map((element) => {
      if (element.size > 30000000) {
        return TextDE.file.errors.toBig(element.name, element.size);
      }
      return TextDE.file.errors.couldNotBeUploaded(element.name);
    });
    this.setState({ rejectedFiles: rejectedFiles });
  };

  handleFileChange(files) {
    if (files.length > 0) {
      if (this.state.filesDraggedIn) {
        this.setState({ newFiles: files, filesDraggedIn: false }, this.handleUpload);
      } else {
        this.setState({ newFiles: files }, this.handleUpload);
      }
    }
  }

  async handleUpload(event) {
    if (!!event) {
      event.preventDefault();
    }

    this.props.callbackMediaSetIsFetching(true);

    let formData = new FormData();
    formData.append('_method', 'PUT');

    this.state.newFiles.forEach((file, i) => {
      formData.append('uploads[]', file, file.file_name);
    });

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response && !!response.media) {
      this.props.callbackMediaUploaded(response.media);
      this.setState({ newFiles: [], hasError: null, rejectedFiles: [] });
    } else {
      this.setState({ hasError: response });
    }
  }

  handleDownloadData = async (item) => {
    let url =
      process.env.REACT_APP_API_URL +
      switchModelType(item.model_type, true) +
      this.props.uuid +
      '/' +
      item.id +
      '/' +
      item.file_name;

    return await fetch(url + '?download=true', {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then(function (t) {
        return t.blob().then((b) => {
          return URL.createObjectURL(b);
        });
      })
      .catch((error) => false);
  };

  handleDownloadClick = (item) => {
    let url =
      process.env.REACT_APP_API_URL +
      switchModelType(item.model_type, true) +
      this.props.uuid +
      '/' +
      item.id +
      '/' +
      item.file_name;
    fetch(url + '?download=true', {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', item.file_name);
        a.click();
      });
    });
  };

  deleteDialog(item) {
    let url =
      process.env.REACT_APP_API_URL +
      switchModelType(item.model_type, true) +
      this.props.uuid +
      '/' +
      item.id +
      '/' +
      item.file_name;
    if (!!url && typeof url === 'string') {
      this.setState({ toDelete: url });
    }
  }

  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  async handleDeleteClick() {
    let url = new URL(this.state.toDelete);
    this.props.callbackMediaSetIsFetching(true);
    let response = await fetch(url, {
      method: 'DELETE',
      //method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      //body: { '_method': 'DELETE' }
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.deleted) {
      this.props.callbackMediaDeleted();

      this.setState({ toDelete: false });
      if (!!this.state.selected) {
        this.closeGallery();
      }
    }
  }

  handleShareClick = (item) => {
    console.log('handleShareClick - nyi');
  };

  downloadGallery = (index) => {
    this.handleDownloadClick(this.props.media[this.state.selected]);
  };

  setSelected = async (key) => {
    if (key === false) {
      this.setState({ selected: false, selectedData: null });
    }
    if (
      this.props.media[key]?.mime_type?.toLowerCase().indexOf('pdf') >= 0 &&
      this.props.media[key]?.size < 8192 * 1024
    ) {
      var data = await this.handleDownloadData(this.props.media[key]);
      if (data === false) {
        data =
          process.env.REACT_APP_API_URL +
          switchModelType(this.props.media[this.state.selected]?.model_type) +
          this.props.uuid +
          '/' +
          this.props.media[this.state.selected]?.id +
          '/' +
          this.props.media[this.state.selected]?.file_name +
          '?download=true';
      }
      this.setState({ selected: key, selectedData: data });
      return;
    }
    this.setState({ selected: key });
  };

  showGallery = (index) => {
    this.setSelected(index);
    this.scrollToRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  nextGallery = () => {
    const newSelected =
      this.state.selected === this.props.media.length - 1 ? 0 : this.state.selected + 1;
    this.setSelected(newSelected);
  };

  previousGallery = () => {
    const newSelected =
      this.state.selected === 0 ? this.props.media.length - 1 : this.state.selected - 1;
    this.setSelected(newSelected);
  };

  closeGallery = () => {
    this.setSelected(false);
  };

  render() {
    const { classes } = this.props;
    const dropzoneRef = createRef();
    const openDialog = () => {
      // Note that the ref is set async,
      // so it might be null at some point
      if (dropzoneRef.current) {
        dropzoneRef.current.open();
      }
    };

    return (
      <>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>{TextDE.delete}?</DialogTitle>
          <DialogContent>
            <DialogContentText>Die Datei sicher aus dem Vorgang {TextDE.delete}?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleDeleteClick} color='secondary'>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.rejectedFiles.map((element, index) => (
          <Alert
            className={classes.mb}
            severity='error'
            key={'alert-' + index}
            onClose={() => {
              let tmp = this.state.rejectedFiles;
              tmp = tmp.filter((file) => file !== element);
              this.setState({
                rejectedFiles: tmp,
              });
            }}
          >
            {element}
          </Alert>
        ))}

        {this.state.newFiles.length > 0 && (
          <Alert
            className={classes.mb}
            key='alert-loading'
            icon={<CircularProgress disableShrink size={20} />}
          >
            {TextDE.mailform.attachment.uploadingLabel(this.state.newFiles.length)}
          </Alert>
        )}

        <Dropzone
          ref={dropzoneRef}
          accept=''
          disabled={!!this.props.isInbox}
          noClick={true}
          onDragLeave={this.handleOnDragLeave}
          onDragEnter={this.handleOnDragEnter}
          onDropRejected={this.handleOnDropRejected}
          onDrop={this.handleFileChange}
          maxFiles={5}
          maxSize={30000000}
          minSize={1}
          multiple={true}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps({
                className: this.state.filesDraggedIn ? classes.draggedBg : classes.nonDraggedBg,
              })}
            >
              <span style={{ width: '100%', height: '1px' }} ref={this.scrollToRef} />
              <Grid container spacing={1}>
                <input {...getInputProps()} />

                {!!this.props.media && this.props.media.length === 0 && (
                  <Grid item xs={12}>
                    <Alert
                      severity='info'
                      className={classes.mb}
                      key='alert-loading'
                      icon={<SentimentDissatisfiedIcon size={20} />}
                    >
                      {TextDE.file.noFile}
                    </Alert>
                  </Grid>
                )}

                {this.state.selected !== false &&
                  this.state.selected >= 0 &&
                  !!this.props.media[this.state.selected]?.file_name && (
                    <Grid item xs={12} className={classes.viewerBox}>
                      <CardHeader
                        action={
                          <IconButton onClick={this.closeGallery}>
                            <CloseIcon />
                          </IconButton>
                        }
                        title={this.props.media[this.state.selected]?.file_name}
                        subheader={this.props.media[this.state.selected]?.mime_type}
                        className={classes.cardHeader}
                      />
                      <CardActions className={classes.buttonbox}>
                        <ButtonGroup color='default' className={classes.buttongroup} fullWidth>
                          <Button
                            variant='contained'
                            startIcon={<NavigateBeforeIcon />}
                            onClick={this.previousGallery}
                          >
                            <Hidden mdDown>{TextDE.back}</Hidden>
                          </Button>
                          <Button
                            startIcon={<DownloadIcon />}
                            variant='contained'
                            onClick={this.downloadGallery}
                          >
                            <Hidden mdDown>{TextDE.download}</Hidden>
                          </Button>
                          <Button
                            variant='contained'
                            startIcon={<DeleteIcon />}
                            onClick={() => this.deleteDialog(this.props.media[this.state.selected])}
                          >
                            <Hidden mdDown>{TextDE.delete}</Hidden>
                          </Button>
                          <Button
                            startIcon={<CloseIcon />}
                            variant='contained'
                            onClick={this.closeGallery}
                          >
                            <Hidden mdDown>{TextDE.close}</Hidden>
                          </Button>

                          <Button
                            variant='contained'
                            endIcon={<NavigateNextIcon />}
                            onClick={this.nextGallery}
                          >
                            <Hidden mdDown>{TextDE.next}</Hidden>
                          </Button>
                        </ButtonGroup>
                      </CardActions>

                      {this.props.media[this.state.selected]?.mime_type
                        .toLowerCase()
                        .indexOf('image') === -1 ? (
                        <CardMedia className={classes.cardcontent}>
                          <object
                            className={classes.sizeMe}
                            data={this.state.selectedData}
                            name={this.props.media[this.state.selected]?.file_name}
                            type={this.props.media[this.state.selected]?.mime_type}
                            title='Falls Sie dies lesen können, kann Ihr Browser die Datei leider nicht anzeigen. Bitte laden Sie die Datei herunter!'
                            onAbort={(abort) => console.log('abbort!', abort)}
                            onError={(error) => console.error('error!', error)}
                          >
                            <Alert
                              style={{ width: '80%' }}
                              severity='error'
                              variant='filled'
                              icon={<SentimentDissatisfiedIcon fontSize='large' />}
                              action={
                                <Button
                                  color='inherit'
                                  endIcon={<DownloadIcon />}
                                  size='small'
                                  variant='text'
                                  onClick={this.downloadGallery}
                                >
                                  {TextDE.download}
                                </Button>
                              }
                            >
                              <Typography>
                                {TextDE.procedure.alerts.fileCanNotBeDisplayed}
                              </Typography>
                            </Alert>
                          </object>
                        </CardMedia>
                      ) : (
                        <CardMedia
                          className={classes.mediaBig}
                          image={
                            process.env.REACT_APP_API_URL +
                            switchModelType(this.props.media[this.state.selected]?.model_type) +
                            this.props.uuid +
                            '/' +
                            this.props.media[this.state.selected]?.id +
                            '/' +
                            this.props.media[this.state.selected]?.file_name +
                            '?download=true'
                          }
                          title={this.props.media[this.state.selected]?.file_name}
                        />
                      )}
                      <Divider />
                    </Grid>
                  )}

                {this.props.media
                  .sort((a, b) => a.id < b.id)
                  .map((item, index) => {
                    return (
                      <Grid key={'file-list-' + index} item className={classes.maxSized}>
                        <Card raised>
                          <CardMedia
                            className={classes.media}
                            image={
                              !!item.mime_type &&
                              [
                                'application/pdf',
                                'image/jpeg',
                                'image/gif',
                                'image/png',
                                'image/webp',
                              ].includes(item.mime_type)
                                ? process.env.REACT_APP_API_URL +
                                  switchModelType(item.model_type) +
                                  this.props.uuid +
                                  '/' +
                                  item.id +
                                  '/' +
                                  item.file_name +
                                  '?thumb=true&download=true'
                                : '/assets/404.jpg'
                            }
                            title={item.file_name}
                            onClick={() => this.showGallery(index)}
                          />
                          <CardHeader
                            className={classes.cardHeaderGrid}
                            title={item.file_name}
                            subheader={!!item.file_scanned && readable(item.file_scanned)}
                            titleTypographyProps={{
                              variant: 'caption',
                              noWrap: true,
                              style: { maxWidth: '100%' },
                            }}
                            subheaderTypographyProps={{
                              variant: 'caption',
                              noWrap: true,
                              style: { maxWidth: '100%' },
                            }}
                          />

                          <CardActions disableSpacing style={{ marginTop: 0, paddingTop: 0 }}>
                            <IconButton
                              className={classes.sizedIcons}
                              onClick={() => this.handleDownloadClick(item)}
                            >
                              <DownloadIcon />
                            </IconButton>
                            <IconButton
                              className={classes.sizedIcons}
                              onClick={() => this.deleteDialog(item)}
                            >
                              <DeleteIcon />
                            </IconButton>
                            {/*
                            <IconButton
                            className={classes.sizedIcons}
                            onClick={() => this.handleShareClick(item)}
                          >
                            <ShareIcon />
                          </IconButton>
                        */}
                            <Hidden mdDown>
                              <Typography align='right' display='inline' className={classes.card}>
                                {String(item.size / (1024 * 1024)).substring(0, 4)} MB
                              </Typography>
                            </Hidden>
                          </CardActions>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
            </div>
          )}
        </Dropzone>
        {!!!this.props.isInbox && (
          <Fab color='primary' aria-label='add' className={classes.fab} onClick={openDialog}>
            <CloudUploadIcon />
          </Fab>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(FileCard);
