import React from 'react';
import { connect } from 'react-redux';
import { get as authFetch } from '../../actions/Authentication';
import { fetch as userFetch } from '../../actions/User';
import { NavLink } from 'react-router-dom';
import Authentication from '../../lib/Authentication';
//import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-recompose';
import Alert from '@material-ui/lab/Alert';
//import AlertTitle from '@material-ui/lab/AlertTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {},
  avatar: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  NavLinkItem: {
    width: '33%',
    padding: theme.spacing(3),
  },
  alert: {
    width: '100%',
  },
  alignEnd: {
    float: 'right',
  },
  popup: {
    position: 'absolute',
    zIndex: 2000,
    top: '10vh',
    left: '3vw',
    width: '94vw',
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,

    [theme.breakpoints.up('lg')]: {
      fontSize: '120%',
      top: '10vh',
      left: '50vw',
      transform: 'translate(-50%, 0)',
      width: 'auto',
    },
    '&>div': {
      padding: theme.spacing(1),
      '&>svg': {
        fontSize: '140%',
      },
      fontSize: '140%',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: 1000,
    width: '100vw',
    height: '100vh',
  },
});

class Register extends React.Component {
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePasswordChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    //let re = /^.*(?=.{3,})(?=.*[a-zA-ZäöüÄÖÜ])(?=.*[0-9])(?=.*[\d\X])(?=.*[!$#%]).*$/
    let reCaps = /[A-ZÄÖÜ]/;
    let reSmall = /[a-zäöü]/;
    let reDigit = /[0-9]/;
    let reCode = /[#?!@$%^&*-]/;
    let reFull = /^(?=.*?[A-ZÄÖÜ])(?=.*?[a-zäöü])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    let validated = false;
    let validationMessage = [];
    if (!event.target.value.match(reCaps)) {
      validationMessage.push('Es fehlt mindestens 1 Großbuchstabe (ABC...)');
    }
    if (!event.target.value.match(reSmall)) {
      validationMessage.push('Es fehlt mindestens 1 Kleinbuchstabe (abc...)');
    }
    if (!event.target.value.match(reDigit)) {
      validationMessage.push('Es fehlt mindestens 1 Zahl (123...)');
    }
    if (!event.target.value.match(reCode)) {
      validationMessage.push('Es fehlt mindestens 1 Sonderzeichen (!$#%...)');
    }
    if (event.target.value.length < 8) {
      validationMessage.push(
        'Das Passwort ist zu kurz, bitte noch mindestens ' +
          (8 - event.target.value.length) +
          ' Zeichen',
      );
    }
    if (event.target.value.match(reFull)) {
      validated = true;
    }
    this.setState({
      validationMessage: validationMessage,
      validation: validated,
      [event.target.name]: event.target.value,
    });
  };

  handleDelete = () => {
    this.setState({ error: false });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    if (
      !this.state.email.trim() ||
      !this.state.password.trim() ||
      !this.state.firstname.trim() ||
      !this.state.lastname.trim()
    ) {
      return;
    }

    let formData = new FormData();
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('password_confirmation', this.state.password);
    formData.append('firstname', this.state.firstname);
    formData.append('lastname', this.state.lastname);
    /*
		let body = { 
				"email": this.state.email,
				"password": this.state.password,
				"password_confirmation": this.state.password,
				"firstname": this.state.firstname,
				"lastname": this.state.lastname,
			};
		let headers = {"Content-Type": "application/json","Accept": "application/json"}
		*/
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/preregister', {
      method: 'POST',
      //headers: headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => response)
      .catch((error) => error);

    if (!!response.errors) {
      this.setState({ loading: false, error: response.errors.join(' | ') });
      return false;
    } else if (typeof response !== 'object') {
      this.setState({ loading: false, error: response });
      return false;
    }

    formData = {
      username: this.state.email,
      password: this.state.password,
      grant_type: 'password',
      client_id: Authentication.client.id,
      client_secret: Authentication.client.secret,
      scope: '',
    };
    const token = await fetch(process.env.REACT_APP_API_URL + '/oauth/token', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        return response.json();
        //throw Error(response.statusText);
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    if (typeof token.error !== 'undefined') {
      this.setState({ loading: false, error: token.message });
      return token;
    }

    const loginFormData = new FormData();
    loginFormData.append('email', formData.username);
    loginFormData.append('password', formData.password);
    //const session =
    await fetch(process.env.REACT_APP_API_URL + '/auth', {
      method: 'POST',
      body: loginFormData,
      credentials: 'include',
    })
      .then(function (response) {
        //response.headers.forEach((val, key) => { console.log(key, val) })
        if (response.ok) {
          return response.json();
        }
        return response.json();
        //throw Error(response.statusText);
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    const user = await fetch(process.env.REACT_APP_API_URL + '/api/users/my', {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (typeof user !== 'object') {
      this.setState({ error: user });
      return user;
    }

    this.props.dispatch(authFetch(token));
    this.props.dispatch(userFetch(user));
    this.setState({ loading: false });
    this.props.acceptInquiry(token.access_token);
  }

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      validationMessage: [],
      validation: false,
      email: props.user.email ?? '',
      password: props.user.password ?? '',
      firstname: props.user.firstname ?? '',
      lastname: props.user.lastname ?? '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.props.close();
          }
        }}
        open={this.props.open}
      >
        <DialogContent>
          <div
            style={{
              backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
              width: '100%',
              minHeight: '60px',
              height: 'auto',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          >
            <IconButton
              aria-label='Registrierung abbrechen'
              onClick={this.props.close}
              edge='end'
              className={classes.alignEnd}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {this.state.loading ? (
            <Box my={4}>
              {' '}
              <CircularProgress />{' '}
            </Box>
          ) : (
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete='fname'
                    name='firstname'
                    value={this.state.firstname}
                    onChange={this.handleChange}
                    variant='outlined'
                    required
                    fullWidth
                    id='firstname'
                    label='Vorname'
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    value={this.state.lastname}
                    onChange={this.handleChange}
                    id='lastname'
                    label='Nachname'
                    name='lastname'
                    autoComplete='lname'
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    disabled={true}
                    type='email'
                    id='email'
                    label='E-Mail Adresse'
                    name='email'
                    autoComplete='email'
                    value={this.state.email}
                    onChange={this.handleChange}
                  />
                </Grid>
                {this.state.validation === false && this.state.validationMessage.length > 0 ? (
                  <Grid item zeroMinWidth xs={12}>
                    {this.state.validationMessage.map((value, index) => (
                      <Alert key={index} severity='error' closeText='Schließen'>
                        {' '}
                        {value}{' '}
                      </Alert>
                    ))}
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <FormControl
                    variant='outlined'
                    fullWidth
                    required
                    error={this.state.validation === false ? true : false}
                  >
                    <InputLabel htmlFor='outlined-adornment-password'>Passwort</InputLabel>
                    <OutlinedInput
                      name='password'
                      id='password'
                      required
                      autoComplete='new-password'
                      type={!!this.state.passwordVisible ? 'text' : 'password'}
                      onChange={this.handlePasswordChange()}
                      value={this.state.password}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() =>
                              this.setState({
                                passwordVisible: !this.state.passwordVisible,
                              })
                            }
                            edge='end'
                          >
                            {!!this.state.passwordVisible ? (
                              <VisibilityIcon />
                            ) : (
                              <VisibilityOffIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                    <FormHelperText component='div'>
                      {' '}
                      {this.state.validation ? (
                        <Typography align='center' display='block' color='primary'>
                          Sehr gut!
                        </Typography>
                      ) : (
                        'Das Passwort muss aus mindestens 8 Zeichen (!$#%...), Zahlen, Buchstaben bestehen'
                      )}{' '}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              {!!this.state.error ? (
                <Alert classes={classes.alert} severity='error'>
                  {this.state.error}
                </Alert>
              ) : null}
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={classes.submit}
                endIcon={<VpnKeyIcon />}
                disabled={
                  !(
                    this.state.firstname.length > 0 &&
                    this.state.lastname.length > 0 &&
                    this.state.email.length > 0 &&
                    this.state.password.length > 0
                  )
                }
              >
                Registrieren und Online bearbeiten!
              </Button>
            </form>
          )}
        </DialogContent>

        <DialogActions>
          <NavLink
            to='/agb'
            className={classes.NavLinkItem}
            key='/agb'
            activeClassName='NavLinkItem-selected'
            target='_blank'
            rel='noopener noreferrer'
          >
            AGBs
          </NavLink>
          <NavLink
            to='/privacy'
            className={classes.NavLinkItem}
            key='/privacy'
            activeClassName='NavLinkItem-selected'
            target='_blank'
            rel='noopener noreferrer'
          >
            Datenschutz
          </NavLink>
        </DialogActions>
      </Dialog>
    );
  }
}
//export default Login;

const mapStateToProps = (state) => ({ Authentication: state.Authentication });
//export default connect(mapStateToProps)(Login);
export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    //, mapDispatchToProps
  ),
)(Register);
