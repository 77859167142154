import React from 'react';
import Popper from '@material-ui/core/Popper';
//import { ThemeProvider } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import FastForwardIcon from '@material-ui/icons/FastForward';
import Alert from '@material-ui/lab/Alert';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import TimelineIcon from '@material-ui/icons/Timeline';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import { DE as TextDE } from '../../lib/Text';

const iconMap = {
  1: <ErrorIcon fontSize='inherit' color='inherit' />,
  2: <WarningIcon fontSize='inherit' color='inherit' />,
  3: <PauseCircleOutlineIcon fontSize='inherit' color='inherit' />,
  4: <FastForwardIcon fontSize='inherit' color='inherit' />,
};

const styles = (theme) => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
    marginBottom: theme.spacing(2),
  },
  pos: {
    marginBottom: 12,
  },
  zIndex: {
    zIndex: '9000',
  },
  popper: {
    backgroundColor: theme.palette.background.default,
    zIndex: '9000',
    maxWidth: '95vw',
    [theme.breakpoints.up('md')]: {
      maxWidth: '33vw',
    },
    '& > div': {
      maxHeight: '300px',
      paddingTop: '4px',
      paddingBottom: '4px',
      overflow: 'auto',
      //border: '1px solid #000000',
    },
  },
  notification: {
    borderBottom: '1px solid #000000',
    '& > span': {
      display: 'flex',
    },
  },
  close: {
    position: 'absolute',
    left: '80%',
  },
  icon: {
    height: 'inherit',
    backgroundColor: 'transparent',
    color: theme.palette.primary.contrastText,
  },
  iconbutton: {
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
  },
});

class SystemStatus extends React.Component {
  async findUpdate() {
    if (!!navigator.serviceWorker) {
      const updateAvailable = await navigator.serviceWorker.ready.then((worker) => worker.waiting);
      this.setState({ newVersion: !!updateAvailable });
    }
  }
  async dropCaches() {
    // eslint-disable-next-line no-restricted-globals
    location.reload(true);
  }
  async update() {
    console.log('[UPDATE SW]');
    if (!!navigator.serviceWorker) {
      // eslint-disable-next-line no-restricted-globals
      let sw = await navigator.serviceWorker.ready.then((worker) => worker);
      console.log('[UPDATE SW]', sw);
      if (!!sw.waiting) {
        console.log('[UPDATE SW] SW WAITING', sw.waiting);
        sw.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
      if (!!sw.active && !!sw.waiting) {
        console.log('[UPDATE SW] SW ACTIVE', sw.active);
        sw.active.postMessage({ type: 'SKIP_WAITING' });
      }
    }
    // eslint-disable-next-line no-restricted-globals
    location.reload(true);
  }
  async toggleUser(event) {
    this.setState({
      isFetching: true,
      status: [],
      open: !!this.state.open ? null : event.target,
    });
    this.findUpdate();
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/system/status/all', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return false;
      });

    if (!!response && response.length > 0) {
      this.setState({ isFetching: false, status: response });
      return;
    }
    this.setState({
      isFetching: false,
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      open: null,
      status: [],
      isFetching: true,
      badge: false,
      newVersion: !!this.props.NewVersion?.display,
    };
    this.toggleUser = this.toggleUser.bind(this);
    this.findUpdate = this.findUpdate.bind(this);
    this.update = this.update.bind(this);
    this.dropCaches = this.dropCaches.bind(this);
  }

  async componentDidMount() {
    this.findUpdate();
    let found = this.props.Notifications.some(
      (el) => el.type === 'App\\Notifications\\EmailConnectError',
    );
    if (!!found) {
      this.setState({ badge: found });
    } else {
      this.setState({ badge: this.state.newVersion });
    }
  }

  async componentDidUpdate(oldProps) {
    let found = this.props.Notifications.some(
      (el) => el.type === 'App\\Notifications\\EmailConnectError',
    );
    if (found !== this.state.badge) {
      this.setState({ badge: found });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <IconButton
          className={classes.iconbutton}
          aria-label={TextDE.system.systemstateLabel}
          onClick={this.toggleUser}
        >
          <Badge
            className={classes.margin}
            overlap='circular'
            badgeContent={!!this.state.badge ? '!' : !!this.state.newVersion ? '!' : 0}
            color='secondary'
          >
            <TimelineIcon />
          </Badge>
        </IconButton>

        <Popper
          className={classes.zIndex}
          open={!!this.state.open}
          anchorEl={this.state.open}
          transition
        >
          <Card className={classes.popper} variant='outlined'>
            <CardHeader
              action={
                <IconButton onClick={this.toggleUser}>
                  <CloseIcon />
                </IconButton>
              }
              title={TextDE.system.systemstateLabel}
            />
            <CardContent>
              {this.state.isFetching ? (
                <Box component='center' m={1}>
                  <CircularProgress />
                </Box>
              ) : (
                <Box component='div' m={1}>
                  {!!this.state.newVersion ? (
                    <Alert
                      className={classes.title}
                      icon={<SystemUpdateIcon fontSize='inherit' color='inherit' />}
                      severity='warning'
                      action={
                        <Button color='inherit' variant='contained' onClick={this.update}>
                          Aktualisieren
                        </Button>
                      }
                    >
                      Es gibt ein Update! Bitte schließen Sie alle Browser-Fenster oder laden diese
                      nach einem Klick auf aktualisieren neu!
                    </Alert>
                  ) : null}
                  <Alert className={classes.title} severity='success'>
                    {TextDE.system.stateAllFineLabel}
                  </Alert>

                  {!!!this.state.newVersion ? (
                    <Typography variant='h6' color='textSecondary' align='right'>
                      <Button
                        size='small'
                        color='secondary'
                        variant='outlined'
                        onClick={this.dropCaches}
                        startIcon={<SyncIcon />}
                      >
                        {TextDE.system.systemCleanCacheLabel}
                      </Button>
                    </Typography>
                  ) : null}

                  {Object.keys(this.state.status).map((value, index) => {
                    return (
                      <Alert
                        key={index}
                        icon={iconMap[this.state.status[value].icon]}
                        severity={this.state.status[value].severity}
                      >
                        {this.state.status[value].text}
                      </Alert>
                    );
                  })}
                </Box>
              )}
            </CardContent>
          </Card>
        </Popper>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Notifications: state.Notifications,
  NewVersion: state.NewVersion,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(SystemStatus);
