import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExploreIcon from '@material-ui/icons/Explore';
import Modal from '@material-ui/core/Modal';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import LocationOnIcon from '@material-ui/icons/LocalShippingRounded';
import { Constants } from '../../../lib/Constants';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import { DE as TextDE } from '../../../lib/Text';

const iconHeight = 64;

const styles = (theme) => ({
  backdrop: {
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#fff',
    zIndex: '1001',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  noPad: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  inherit: {
    boxShadow: 'inherit',
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  inheritBackground: {
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  chip: {
    maxWidth: '100%',
  },
  card: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  cardAddress: {
    borderRight: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    borderBottom: 'none',
    overflow: 'visible',
    backgroundColor: '#ffd54f',
    minHeight: '106px',
  },
  cardAddressCollapsed: {
    justifyContent: 'start',
    alignContent: 'center',
    display: 'flex',
  },
  maximize: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(0, 2px)',
      marginRight: theme.spacing(0.25),
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  button: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '90vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      //right: iconHeight/1.5+30+'px',
      //marginTop: '-'+iconHeight/1.5+'px',
      top: '10vh',
      left: '1vw',
      maxWidth: '98vw',
      minWidth: '98vw',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: '1002',
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  results: {
    zIndex: 123123,
    position: 'absolute',
    maxHeight: '300px',
    overflow: 'auto',
  },
  cardHeaderContent: {
    flex: '1 1 auto',
    width: 0,
    height: 'auto',
    paddingBottom: 0,
  },
  cardHeaderAction: {
    margin: theme.spacing(2, -1, 0, 0),
  },
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
    width: '100%',
  },
});

class AddressCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      titleAddress: false,
      addressesAll: [],
      addresses: [],
      cardAddressExpand: false,
      cardAddressNew: false,
      form: {
        model: this.props.model || 'procedures',
        uuid: this.props.uuid || '',
        isSubmitting: false,
        hasError: false,
        address: '',
        addressObject: {},
        tags: [],
      },
      selectedContact: null,
      toDelete: false,
    };
    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.handleCardAddressExpandClick = this.handleCardAddressExpandClick.bind(this);
    this.handleCardAddressNewClick = this.handleCardAddressNewClick.bind(this);

    this.handleAddressEdit = this.handleAddressEdit.bind(this);
    this.handleAddressDelete = this.handleAddressDelete.bind(this);
  }

  componentDidMount() {
    let tmp = [];
    let titleAddress = false;
    let local = [...Constants.tags.addresses.resident, ...Constants.tags.addresses.residentHidden];
    //let payment = [ ...Constants.tags.addresses.invoice, ...Constants.tags.addresses.invoiceHidden ];
    this.props.addresses.forEach((value, key) => {
      let hasRelevantTag = value.tags.findIndex((el) => local.includes(el.name.de)) >= 0;
      if (!!hasRelevantTag) {
        titleAddress = {
          id: value.address.id,
          address: !!value.address?.id
            ? value.address.route +
              ' ' +
              value.address.street_number +
              ', ' +
              value.address.postal_code +
              ' ' +
              value.address.city.locality
            : '',
          link: encodeURI(
            !!value.address?.id
              ? value.address.route +
                  '+' +
                  value.address.street_number +
                  '+' +
                  value.address.postal_code +
                  '+' +
                  value.address.city.locality
              : '',
          ),
          tags: value.tags.map((tag) => tag.name.de),
        };
      }
      tmp.push({
        id: value.address.id,
        address: !!value.address?.id
          ? value.address.route +
            ' ' +
            value.address.street_number +
            ', ' +
            value.address.postal_code +
            ' ' +
            value.address.city.locality
          : '',
        link: encodeURI(
          !!hasRelevantTag && !!value.address?.id
            ? value.address.route +
                '+' +
                value.address.street_number +
                '+' +
                value.address.postal_code +
                '+' +
                value.address.city.locality
            : '',
        ),
        tags: value.tags.map((tag) => tag.name.de),
      });
    });
    if (titleAddress === false && tmp.length > 0) {
      titleAddress = tmp[0];
    }
    this.setState({ addresses: tmp, titleAddress: titleAddress });
  }

  handleCardAddressExpandClick = () => {
    this.props.toggleFunction('AddressCard');
    //this.setState({ cardAddressExpand: !this.props.open });
  };
  handleCardAddressNewClick = () => {
    this.setState({
      ...this.state,
      cardAddressNew: !this.state.cardAddressNew,
      form: {
        ...this.state.form,
        isSubmitting: false,
        hasError: false,
        address: '',
        addressObject: {},
        tags: [],
      },
    });
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };

  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        //componentForm[addressType] = val;
        //console.log(addressType, val);
        //setCompanyState({...companyState, [addressType]: val});
        newAddress[addressType] = val;
      }
    }
    //this.setState({form: { ...this.state.form, 'address': address, addressObject: newAddress } });
    this.setState({
      form: { ...this.state.form, address: address, addressObject: newAddress },
    });
  };

  handleAddressChange = (event, value) => {
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      this.setState({ form: { ...this.state.form, tags: value } });
    } else if (typeof event === 'string') {
      this.setState({ form: { ...this.state.form, address: event } });
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.value },
      });
    }
  };

  handleAddressAdd = async () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });

    // Create new User or use existing
    let formData = {};
    formData['_method'] = 'PUT';
    formData['address'] = this.state.form.addressObject;
    if (!!this.state.form.id && this.state.form.id >= 0) {
      formData['address_id'] = this.state.form.id;
    }
    formData['tags'] = this.state.form.tags;
    formData['context'] = 'addresses';
    formData['uuid'] = this.state.form.uuid;

    let control = false;
    let tmp = this.state.addresses;
    let address = {
      address: this.state.form.address,
      tags: this.state.form.tags,
      link: encodeURI(
        !!this.state.form.address
          ? this.state.form.address?.route +
              '+' +
              this.state.form.address?.street_number +
              '+' +
              this.state.form.address?.postal_code +
              '+' +
              this.state.form.address?.city?.locality
          : '',
      ),
    };

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      })
      .catch((error) =>
        this.setState({
          hasError: error,
          model: this.state.form.model,
          uuid: this.state.form.uuid,
          isSubmitting: false,
        }),
      );

    address.id = response.id;
    for (var i = tmp.length - 1; i >= 0; i--) {
      if (tmp[i].id === response.id) {
        tmp[i] = address;
        control = true;
      }
    }
    if (!control) {
      tmp.push(address);
    }

    tmp.forEach((element, index) => {
      let control = 0;
      ['Zielort', 'Treffpunkt', 'Leistungsort', 'Reparaturstelle'].forEach(
        (tag) => (control = element.tags.includes(tag) ? control + 1 : control),
      );
      if (control === 0) {
        tmp[index].link = false;
      }
    });

    this.setState(
      {
        ...this.state,
        addresses: tmp,
        form: {
          model: this.state.form.model,
          uuid: this.state.form.uuid,
          isSubmitting: false,
          hasError: false,
          address: '',
          addressObject: {},
          tags: [],
        },
      },
      () => {
        if (tmp.length >= 1 && this.state.titleAddress === false) {
          this.setState({ titleAddress: tmp[0] });
        }
      },
    );

    if (this.state.form.model === 'inbox') {
      this.props.callbackFunction(true);
    }
  };

  handleAddressEdit = (value) => {
    this.setState({
      ...this.state,
      cardAddressExpand: true,
      cardAddressNew: true,
      form: {
        ...this.state.form,
        isSubmitting: false,
        hasError: false,
        address: value.address,
        id: value.id,
        tags: value.tags,
      },
    });
  };

  deleteDialog(value) {
    if (!!value.id && typeof value.id === 'number' && !!value.tags) {
      this.setState({ toDelete: value });
    }
  }
  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  handleAddressDelete() {
    let addresses = this.state.addresses;
    addresses = addresses.filter((address) => address.id !== this.state.toDelete.id);
    let titleAddress =
      this.state.titleAddress?.id === this.state.toDelete.id ? false : this.state.titleAddress;

    this.setState({ addresses: addresses, titleAddress: titleAddress }, () => {
      if (addresses.length >= 1 && titleAddress === false) {
        this.setState({ titleAddress: addresses[0] });
      }
    });

    // Drop User from procedure
    let formData = {};
    formData['_method'] = 'DELETE';
    formData['tags'] = this.state.toDelete.tags;
    formData['context'] = 'addresses';
    formData['address'] = this.state.toDelete.id;
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint + '/api/' + this.state.form.model + '/' + formData.uuid + '/' + formData.context;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return !!response.deleted;
      });
    this.setState({ toDelete: false });
  }

  handleAddressClick = (value) => {
    this.setState({ selectedContact: value });
  };

  handleAddressClose = () => {
    this.setState({ selectedContact: null });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box mt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }}>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>{TextDE.address.deleteTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.address.deleteText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleAddressDelete} color='secondary'>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Card
          raised={false}
          className={clsx(classes.cardAddress, {
            [classes.cardAddressCollapsed]: !this.props.open,
          })}
        >
          {!!this.state.titleAddress && !this.props.open ? (
            <CardHeader
              classes={{
                content: classes.cardHeaderContent,
                root: !this.props.open ? classes.cardHeaderRoot : null,
                action: classes.cardHeaderAction,
              }}
              avatar={
                <IconButton
                  href={
                    !!this.state.titleAddress.link
                      ? 'http://maps.google.com/maps?daddr=' + this.state.titleAddress.link
                      : ''
                  }
                  target={!!this.state.titleAddress.link ? '_blank' : ''}
                  size='small'
                >
                  <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                    {!!this.state.titleAddress.link ? <LocationOnIcon /> : <ExploreIcon />}
                  </Avatar>
                </IconButton>
              }
              action={
                <Badge
                  overlap='circular'
                  max={9}
                  badgeContent={this.state.addresses.length}
                  color='secondary'
                >
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.props.open,
                    })}
                    onClick={this.handleCardAddressExpandClick}
                    aria-expanded={this.props.open}
                    aria-label='show more'
                  >
                    {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Badge>
              }
              disableTypography={false}
              titleTypographyProps={{
                onClick: this.handleCardAddressExpandClick,
                noWrap: false,
                variant: 'subtitle1',
              }}
              subheaderTypographyProps={{
                onClick: this.handleCardAddressExpandClick,
                noWrap: true,
              }}
              title={this.state.titleAddress.address}
              subheader={this.state.titleAddress.tags.map((item, index) => (
                <Chip size='small' key={index} className={classes.chip} label={item} />
              ))}
            />
          ) : (
            <CardHeader
              classes={{
                content: classes.cardHeaderContent,
                root: !this.props.open ? classes.cardHeaderRoot : null,
                action: classes.cardHeaderAction,
              }}
              avatar={
                <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                  <ExploreIcon />
                </Avatar>
              }
              action={
                <Badge
                  overlap='circular'
                  max={9}
                  badgeContent={this.state.addresses.length}
                  color='secondary'
                >
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.props.open,
                    })}
                    onClick={this.handleCardAddressExpandClick}
                    aria-expanded={this.props.open}
                    aria-label='show more'
                  >
                    {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Badge>
              }
              titleTypographyProps={{ noWrap: true, variant: 'h6' }}
              title={TextDE.address.labelHelper(this.state.addresses.length)}
            />
          )}
          <Collapse
            classes={{
              wrapperInner: classes.inheritBackground,
              wrapper: classes.inheritBackground,
            }}
            className={clsx(classes.inheritBackground, {
              [classes.maximize]:
                this.props.open &&
                this.props.expandedWidth >= this.props.Theme.breakpoints.values['md'],
            })}
            style={{
              width:
                this.props.expandedWidth > 599 && this.props.open
                  ? this.props.expandedWidth - 38
                  : '100%',
            }}
            in={this.props.open}
            timeout={{
              enter: 214,
              exit: this.props.expandedWidth < 599 ? 106 : 0,
            }}
            unmountOnExit
          >
            <CardContent className={classes.noPad}>
              <List className={classes.list}>
                {this.state.addresses.map((value) => {
                  const labelId = `list-addresses-label-${value.id}`;
                  return (
                    <ListItem key={labelId}>
                      <ListItemIcon>
                        <IconButton
                          href={
                            !!value.link ? 'http://maps.google.com/maps?daddr=' + value.link : ''
                          }
                          target={!!value.link ? '_blank' : ''}
                          disabled={!(!!value.link && value.link !== '')}
                        >
                          {!!value.link && value.link !== '' ? (
                            <LocationOnIcon color='primary' />
                          ) : (
                            <ExploreIcon color='primary' />
                          )}
                        </IconButton>
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        disableTypography={true}
                        primary={<Typography variant='body1'>{value.address}</Typography>}
                        secondary={value.tags.map((item, index) => (
                          <Chip size='small' key={index} className={classes.chip} label={item} />
                        ))}
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          color='default'
                          edge='end'
                          aria-label={TextDE.edit}
                          onClick={() => this.handleAddressEdit(value)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          color='default'
                          edge='end'
                          aria-label='delete'
                          onClick={() => this.deleteDialog(value)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Box component='div' p={2}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={this.handleCardAddressNewClick}
                  aria-expanded={this.state.cardAddressNew}
                  aria-label={TextDE.add}
                  fullWidth={true}
                >
                  {this.state.cardAddressNew ? <CloseIcon /> : <AddIcon />}
                  {this.state.cardAddressNew ? TextDE.close : TextDE.add}
                </Button>
              </Box>

              <Collapse in={this.state.cardAddressNew} timeout='auto' unmountOnExit>
                <Divider />
                <CardContent className={classes.noPad}>
                  {this.state.form.isSubmitting ? (
                    <center>
                      <CircularProgress />
                    </center>
                  ) : (
                    <Grid container className={classes.root} spacing={2}>
                      <Grid item xs={12}>
                        <PlacesAutocomplete
                          value={this.state.form.address}
                          name='address'
                          onChange={this.handleAddressChange}
                          onSelect={this.handleAddressSelect}
                          searchOptions={{ types: ['address'] }}
                          shouldFetchSuggestions={this.state.form.address.length >= 4}
                        >
                          {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div>
                              <TextField
                                autoComplete='false'
                                name='address'
                                variant='standard'
                                label={TextDE.address.label}
                                fullWidth
                                {...getInputProps({
                                  placeholder: TextDE.address.inputPlaceholder,
                                  className: 'location-search-input',
                                })}
                              />
                              {suggestions.length > 0 ? (
                                <Card className={classes.results}>
                                  {suggestions.map((suggestion, index) => {
                                    const key = `list-suggestions-${index}`;
                                    return (
                                      <CardContent
                                        key={key}
                                        {...getSuggestionItemProps(suggestion, {})}
                                        className={classes.result}
                                      >
                                        <Grid container alignItems='center'>
                                          <Grid item>
                                            <LocationOnIcon className={classes.icon} />
                                          </Grid>
                                          <Grid item xs>
                                            <Typography variant='subtitle1'>
                                              {suggestion.formattedSuggestion.mainText}
                                            </Typography>
                                            <Typography variant='body1' color='textSecondary'>
                                              {suggestion.formattedSuggestion.secondaryText}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      </CardContent>
                                    );
                                  })}
                                </Card>
                              ) : null}
                            </div>
                          )}
                        </PlacesAutocomplete>
                      </Grid>
                      <Grid item xs={12}>
                        <Autocomplete
                          multiple
                          freeSolo
                          id='tags-standard'
                          name='tags'
                          options={Constants.tags.addresses.all()}
                          getOptionLabel={(option) => option}
                          defaultValue={null}
                          value={this.state.form.tags}
                          onChange={this.handleAddressChange}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={TextDE.address.tagLabel}
                              placeholder={TextDE.address.tagPlaceholder}
                              helperText={TextDE.address.tagHelperText}
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant='contained'
                          color='primary'
                          className={classes.button}
                          startIcon={<SaveIcon />}
                          onClick={this.handleAddressAdd}
                        >
                          {TextDE.save}
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Collapse>
            </CardContent>
          </Collapse>
        </Card>

        <Modal
          open={!!this.state.selectedContact}
          onClose={this.handleAddressClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card className={classes.paper}>
            <CardHeader className='handle'>
              title=
              {<Typography variant='h3'> {this.state.selectedContact?.displayName} </Typography>}
              action=
              {
                <IconButton aria-label='close' onClick={this.handleAddressClose}>
                  {' '}
                  <CloseIcon />{' '}
                </IconButton>
              }
              >
            </CardHeader>
            <CardContent>
              <pre>{JSON.stringify(this.state.selectedContact, 'undefined', 4)}</pre>
            </CardContent>
          </Card>
        </Modal>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AddressCard);
