import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import HelpIcon from '@material-ui/icons/Help';
import Alert from '@material-ui/lab/Alert';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
const styles = (theme) => ({
  inherit: {
    padding: theme.spacing(1, 2),
    textDecoration: 'inherit',
    color: theme.palette.info.dark,
    justifyContent: 'center',
    width: '100%',
    minWidth: '1px',
    backgroundColor: 'inherit',
    '&:hover': {
      textDecoration: 'inherit',
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.dark,
    },
  },
  iconInherit: {
    color: 'inherit',
    backgroundColor: 'inherit',
    minWidth: 'unset',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  flexBox: {
    display: 'flex',
    flexFlow: 'row-reverse',
    maxWidth: '100%',
    width: '100%',
  },
  marginAuto: {
    marginLeft: 'auto',
  },
  alert: {
    width: '100%',
  },
});

class SupportRequestWithoutUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      text: '',
      email: '',
      phone: '',
      name: '',
      errors: [],
      callMeBack: false,
    };
    this.submit = this.submit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePhoneCall = this.handlePhoneCall.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  submit() {
    let errors = [];

    if (this.state.text.length < 1) {
      errors.push('Bitte tragen Sie Ihr Anliegen ein!');
    }
    if (this.state.text.length > 1 && this.state.text.length < 15) {
      errors.push('Bitte tragen Sie Ihr Anliegen ausführlicher ein!');
    }
    if (this.state.name.length < 2) {
      errors.push('Bitte tragen Sie Ihren Namen ein!');
    }
    if (this.state.callMeBack && this.state.phone.length < 5) {
      errors.push('Bitte tragen Sie Ihre Telefonnummer ein!');
    }
    if (!this.state.callMeBack && this.state.email.length < 5) {
      errors.push('Bitte tragen Sie Ihre E-Mail Adresse ein!');
    }
    if (errors.length > 0) {
      this.setState({ errors: errors });
      return;
    }

    let data = {
      request: this.state.text,
      callMeBack: this.state.callMeBack ? true : false,
      who:
        'Support Anfrage von ' +
        this.state.name +
        ' ' +
        '( ' +
        (this.state.email ?? ' Keine Email ') +
        '/' +
        (this.state.phone ?? ' Kein Telefon ') +
        ' )',
    };

    let url = new URL(process.env.REACT_APP_API_URL + '/api/send/supportmail');
    data['_method'] = 'POST';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });
    this.handleClose();
  }

  handleClose(event, reason) {
    if (!!reason && reason === 'backdropClick') {
      return;
    }
    this.setState({
      open: false,
      text: '',
      name: '',
      phone: '',
      email: '',
      errors: [],
    });
  }
  handleChange(event, value) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePhoneCall() {
    this.setState({ callMeBack: !this.state.callMeBack });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <Button
          endIcon={<HelpIcon />}
          className={classes.inherit}
          onClick={() => this.setState({ open: true })}
        >
          Support kontaktieren
        </Button>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
          disableEscapeKeyDown={true}
        >
          <DialogTitle id='form-dialog-title'>Ihr Kontakt zu uns!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Sie können sich nicht mehr anmelden oder haben ein anderes Problem?
              <br />
              <b>Bitte sagen Sie uns wie wir Ihnen helfen können!</b>
            </DialogContentText>
            <TextField
              name='name'
              margin='dense'
              label='Name'
              type='text'
              fullWidth
              required
              onChange={this.handleChange}
            />
            <TextField
              multiline
              required
              autoFocus
              name='text'
              margin='dense'
              label='Ihr Anliegen'
              type='textarea'
              variant='outlined'
              fullWidth
              onChange={this.handleChange}
              rows={4}
            />
            <br />
            <br />
            <Typography variant='h6'>
              Sollen wir Sie anrufen, oder Ihnen via E-Mail helfen?
            </Typography>

            <FormControlLabel
              className={classes.flexBox}
              control={
                <Checkbox
                  name='callMeBack'
                  checked={this.state.callMeBack}
                  onChange={this.handlePhoneCall}
                />
              }
              label={
                <Typography display='inline' align='left'>
                  Bitte rufen Sie mich an
                </Typography>
              }
            />
            <br />
            {this.state.callMeBack && (
              <TextField
                name='phone'
                margin='dense'
                label='Telefon'
                type='text'
                fullWidth
                onChange={this.handleChange}
              />
            )}
            {!this.state.callMeBack && (
              <TextField
                name='email'
                margin='dense'
                label='E-Mail'
                type='text'
                fullWidth
                onChange={this.handleChange}
              />
            )}
            <br />
            <br />
            {this.state.errors.map((value, index) => (
              <Alert key={index} classes={classes.alert} severity='error'>
                {value}
              </Alert>
            ))}
            <br />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} size='large' variant='outlined' color='secondary'>
              Abbrechen
            </Button>
            <Button
              onClick={this.submit}
              size='large'
              variant='contained'
              color='primary'
              className={classes.marginAuto}
            >
              Absenden
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ Theme: state.Style.Theme });

export default compose(withStyles(styles), connect(mapStateToProps))(SupportRequestWithoutUser);
