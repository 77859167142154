import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';

import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { DE as TextDE } from '../../lib/Text';
import { Constants } from '../../lib/Constants';

import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, TimePicker, DatePicker } from '@material-ui/pickers';

const styles = (theme) => ({
  fab: {
    zIndex: 9,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  moveLeft: {
    marginRight: 'auto',
  },
});

class TimeTrackingAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,

      submitting: false,
      errors: null,

      start_time: null,
      disable_start_time: false,
      end_time: null,
      disable_end_time: false,
      trackedAt: new Date(),
      break: 0,
      disable_break: false,
      totalTime: '',
      category: '',
      categories: props.categories ?? [],
      loadingCategories: true,

      isValid: false,

      end_timeAlert: '',
      breakTimeAlert: '',

      usersAll: props.users ?? [],
      loadingUsers: true,
      selectedUser: this.props.User.uuid,
      isOrganizer: Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
      ]),

      edit: null,
    };

    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleTrackedAtChange = this.handleTrackedAtChange.bind(this);
    this.handleBreakChange = this.handleBreakChange.bind(this);
    this.calculateTotalTime = this.calculateTotalTime.bind(this);
    this.callCallback = this.callCallback.bind(this);
    this.fetchCategories = this.fetchCategories.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleTimes = this.handleTimes.bind(this);
    this.setDefaultCategory = this.setDefaultCategory.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchCategories();
  }

  setDefaultCategory() {
    if (!!!this.props.edit || !!!this.props.edit.category) {
      let category = this.state.categories.filter(
        (val) => val.category.toString().toUpperCase() === 'ARBEITSZEIT',
      )[0];
      if (!!category && !!category.category) {
        this.handleTimes(category);
        this.setState({ category: category.category }, () =>
          this.setState({ loadingCategories: false }),
        );
        return;
      }
    } else if (!!this.props.edit.category) {
      this.handleTimes(this.props.edit.category);
    }
    this.setState({ loadingCategories: false });
  }

  fetchUsers() {
    if (this.state.isOrganizer) {
      if (!!!this.props.users) {
        fetch(process.env.REACT_APP_API_URL + '/api/users', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.props.Authentication.access_token}`,
          },
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw res;
            }
          })
          .then((json) => {
            let tmp = [];
            json.forEach((value, key) => {
              if (!!!value.deleted_at) {
                tmp.push({
                  uuid: value.uuid,
                  firstname: value.firstname,
                  nickname: value.nickname,
                  lastname: value.lastname,
                  email: value.email,
                  working_hours: value.working_hours,
                  roles: value.roles.map((role) => role.name),
                });
              }
            });
            tmp = tmp.sort((a, b) =>
              a.lastname > b.lastname ? 1 : b.lastname > a.lastname ? -1 : 0,
            );
            this.setState({ usersAll: tmp }, () =>
              this.setState({ selectedUser: this.props.User.uuid, loadingUsers: false }),
            );
          })
          .catch((error) => {
            console.error('[TIMETRACKINGADD][componentDidMount] get users', error);
          });
      } else {
        if (this.props.users !== this.state.usersAll) {
          this.setState({ usersAll: this.props.users }, () =>
            this.setState({ selectedUser: this.props.User.uuid, loadingUsers: false }),
          );
        } else {
          this.setState({ selectedUser: this.props.User.uuid, loadingUsers: false });
        }
      }
    }
  }

  fetchCategories() {
    if (!!!this.props.categories) {
      fetch(process.env.REACT_APP_API_URL + '/api/timetracking/categories', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          if (!!json.categories) {
            this.setState({ categories: json.categories }, this.setDefaultCategory);
          } else {
            alert('NEU LADEN!');
          }
        })
        .catch((error) => {
          return false;
        });
    } else {
      if (this.props.categories !== this.state.categories) {
        this.setState({ categories: this.props.categories }, this.setDefaultCategory);
      } else {
        this.setDefaultCategory();
      }
    }
  }

  handleTimes(category) {
    //console.log(this.state.usersAll[this.state.selectedUser]);
    let data = {};
    if (!!category.mayEdit === false) {
      data = { ...data, disable_start_time: true, disable_end_time: true, disable_break: true };
    } else {
      data = { ...data, disable_start_time: false, disable_end_time: false, disable_break: false };
    }

    if (parseInt(category.autofill) >= 1 && !!!this.state.edit) {
      if (parseInt(category.autofill) > 0) {
        let startSeconds =
          !!this.props.User?.customer?.company?.working_hours &&
          !!Object.values(JSON.parse(this.props.User?.customer?.company?.working_hours).days).find(
            (tmp) => tmp.open === true,
          ).start
            ? Object.values(
                JSON.parse(this.props.User?.customer?.company?.working_hours).days,
              ).find((tmp) => tmp.open === true).start
            : 28800;
        let startMinutes = parseInt((startSeconds % 3600) / 60);
        let startHours = parseInt(startSeconds / 3600);
        let startTime = new Date();
        startTime.setHours(startHours);
        startTime.setMinutes(startMinutes);

        data = { ...data, start_time: startTime, break: 0 };
        if (parseInt(category.autofill) > 1) {
          let addTime = 1;
          if (
            !!this.state.usersAll[this.state.selectedUser] &&
            !!this.state.usersAll[this.state.selectedUser].working_hours &&
            !!this.state.usersAll[this.state.selectedUser].working_hours[category.base]
          ) {
            addTime = this.state.usersAll[this.state.selectedUser].working_hours[category.base];
          }
          if (
            addTime === 1 &&
            !!this.props.User.working_hours &&
            !!this.props.User.working_hours[category.base]
          ) {
            addTime = this.props.User.working_hours[category.base];
          }

          let addMinutes = parseInt(addTime % 60);
          let addHours = parseInt(addTime / 60);
          let endTime = new Date(startTime);
          endTime.setHours(endTime.getHours() + addHours);
          endTime.setMinutes(endTime.getMinutes() + addMinutes);
          data = { ...data, end_time: endTime };
        }
      }
    } else {
      data = {
        ...data,
        start_time: this.state.start_time ?? null,
        end_time: this.state.end_time ?? null,
      };
    }
    this.setState({ ...data, category: category.category }, this.calculateTotalTime);
  }

  async componentDidUpdate(oldProps, oldState) {
    //console.log('[componentDidUpdate] State:', oldState, this.state);
    //console.log('[componentDidUpdate] Props:', oldProps, this.props);

    if (oldProps.isOrganizer !== this.props.isOrganizer) {
      await this.fetchUsers();
    }
    if (oldProps.categories !== this.props.categories) {
      await this.fetchCategories();
    }

    if (this.props.edit !== null && this.props.edit !== oldProps.edit) {
      let currentDate = new Date();
      let data = {
        dialogOpen: true,
        start_time: !!this.props.edit?.start_time
          ? new Date(
              `${currentDate.getFullYear()}-${
                currentDate.getMonth() + 1
              }-${currentDate.getDate()}T${this.props.edit?.start_time}:00`,
            )
          : this.state.start_time,
        end_time: !!this.props.edit?.end_time
          ? new Date(
              `${currentDate.getFullYear()}-${
                currentDate.getMonth() + 1
              }-${currentDate.getDate()}T${this.props.edit?.end_time}:00`,
            )
          : this.state.end_time,
        trackedAt: !!this.props.edit?.tracked_at
          ? new Date(Date.parse(this.props.edit?.tracked_at))
          : this.state.trackedAt,
        break: this.props.edit?.break ?? this.state.break,
        totalTime: this.props.edit?.totalTime ?? this.state.totalTime,
        category: this.props.edit?.category?.category ?? this.state.category,
        loadingCategories: false,
        loadingUsers: false,
        selectedUser: this.props.edit?.user_id ?? this.state.selectedUser,
      };

      //console.log(
      //  this.props.edit,
      //  data
      //);
      this.setState({ ...this.state, ...data }, this.calculateTotalTime);
    }
  }

  handleStartTimeChange = (event) => {
    if (event === null) {
      this.setState({ start_time: '' });
      return;
    }
    if (!!!this.state.end_time || this.state.end_time > event) {
      this.setState({ start_time: event, end_timeAlert: '' }, this.calculateTotalTime);
      return;
    }
    this.setState(
      { start_time: event, isValid: false, end_timeAlert: 'Ende kann nicht vor Beginn sein!' },
      this.calculateTotalTime,
    );
  };

  handleEndTimeChange = (event) => {
    if (event === null) {
      this.setState({ end_time: '' });
      return;
    }
    if (this.state.start_time < event) {
      this.setState({ end_time: event, end_timeAlert: '' }, this.calculateTotalTime);
      return;
    }
    this.setState(
      { end_time: event, isValid: false, end_timeAlert: 'Ende kann nicht vor Beginn sein!' },
      this.calculateTotalTime,
    );
  };

  calculateTotalTime = () => {
    let text = '';
    if (
      !!this.state.end_time &&
      !!this.state.start_time &&
      this.state.start_time < this.state.end_time
    ) {
      let totalTimeInMins = (this.state.end_time - this.state.start_time) / 60000;
      if (totalTimeInMins > this.state.break) {
        totalTimeInMins = (totalTimeInMins - this.state.break).toFixed(0);
        text = `${Number.parseInt(totalTimeInMins / 60)
          .toString()
          .padStart(2, '0')}:${Number.parseInt(totalTimeInMins % 60)
          .toString()
          .padStart(2, '0')} (${totalTimeInMins} Min)`;
        this.setState({ totalTime: text, breakTimeAlert: '', isValid: true });
        return;
      } else {
        text = 'Ihre Pause ist länger als Ihre Arbeitszeit!';
        this.setState({ totalTime: text, breakTimeAlert: text, isValid: false });
        return;
      }
    }
    this.setState({ totalTime: text, breakTimeAlert: '' });
  };

  handleTrackedAtChange = (event) => {
    if (event === null) {
      this.setState({ trackedAt: '' });
      return;
    }
    this.setState({ trackedAt: event }, this.calculateTotalTime);
  };

  handleBreakChange = (event, data) => {
    if (!!event.target.name && !!event.target.value) {
      this.setState({ [event.target.name]: event.target.value }, this.calculateTotalTime);
    }
  };

  handleChange = (event, data) => {
    if (typeof event !== 'object') {
      console.warn(event, data);
    } else {
      if (!!event.target.name && event.target.type === 'checkbox') {
        this.setState({ [event.target.name]: event.target.checked });
      } else if (!!event.target.name && !!event.target.value) {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
  };

  handleClickOpen = () => {
    this.setState({ dialogOpen: true });
  };

  handleSubmit = () => {
    /*
      'user_id' => 'required|exists:users,uuid',
      'tracked_at' => 'required|date',
      'start_time' => 'required|regex:/^\d{1,2}(:\d{1,2})?$/',
      'end_time' => 'required|regex:/^\d{1,2}(:\d{1,2})?$/',
      'break' => 'nullable|numeric',
      'categories' => 'nullable|json',
    */
    this.setState({ submitting: true });

    let url = new URL(process.env.REACT_APP_API_URL + '/api/timetracking');

    let formData = {};
    formData['_method'] = !!this.props.edit ? 'PUT' : 'POST';
    if (!!this.props.edit) {
      formData['id'] = this.props.edit.id;
    }
    formData['user_id'] = this.props.User.uuid;
    if (this.state.isOrganizer && !!this.state.selectedUser && this.state.selectedUser !== '') {
      formData['user_id'] = this.state.selectedUser;
    }
    formData['tracked_at'] = `${this.state.trackedAt.getFullYear()}-${
      this.state.trackedAt.getMonth() + 1
    }-${this.state.trackedAt.getDate()}`;
    formData['start_time'] = this.state.start_time.toTimeString().toString().substr(0, 5);
    formData['end_time'] = this.state.end_time.toTimeString().toString().substr(0, 5);
    formData['break'] = this.state.break;
    formData['category'] = this.state.category;

    fetch(url, {
      method: formData['_method'],
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        //if (res.ok) {
        return res.json();
        //} else {
        //  throw res;
        //}
      })
      .then((json) => {
        //console.log('[onSubmit]', json);
        if (!!json.errors && Object.values(json.errors).length > 0) {
          //console.log('[onSubmit] has Errors', json.errors);
          this.setState({ submitting: false, errors: json.errors });
        } else {
          this.setState(
            {
              dialogOpen: false,
              submitting: false,
              errors: null,
              start_time: null,
              end_time: null,
              trackedAt: new Date(),
              break: 0,
              totalTime: '',
              category: '',
              isValid: false,
              end_timeAlert: '',
              breakTimeAlert: '',
              edit: null,
            },
            this.callCallback,
          );
        }
      })
      .catch((error) => {
        console.error('[onSubmit][ERROR]', error);
      });
  };

  callCallback = (reload = true) => {
    if (!!this.props.callback) {
      this.props.callback(reload);
    }
  };

  handleClose = () => {
    this.setState(
      {
        dialogOpen: false,
        submitting: false,
        errors: null,
        start_time: null,
        end_time: null,
        trackedAt: new Date(),
        break: 0,
        totalTime: '',
        category: '',
        isValid: false,
        end_timeAlert: '',
        breakTimeAlert: '',
        edit: null,
      },
      () => this.callCallback(false),
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {!!this.props.asMenuItem && (
          <MenuItem onClick={this.handleClickOpen}>
            <ListItemIcon>
              <HourglassFullIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.timetracking.addButtonLabel} />
          </MenuItem>
        )}
        {!!this.props.asButton && (
          <Button
            variant='contained'
            color='primary'
            onClick={this.handleClickOpen}
            startIcon={<HourglassFullIcon />}
          >
            {TextDE.timetracking.addButtonLabel}
          </Button>
        )}
        {(!!this.props.asFab || (!!!this.props.asMenuItem && !!!this.props.asButton)) && (
          <Fab className={classes.fab} onClick={this.handleClickOpen} color='primary'>
            {this.props.icon ?? <AddIcon />}
          </Fab>
        )}

        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>
            {!!this.props.edit ? TextDE.timetracking.editTitle : TextDE.timetracking.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!!this.props.edit
                ? TextDE.timetracking.helperEditText
                : TextDE.timetracking.helperText}
            </DialogContentText>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container spacing={2}>
                {!!this.state.isOrganizer && (
                  <>
                    <Grid item xs={12} md={5}>
                      {TextDE.timetracking.coworkersLabel}
                    </Grid>
                    <Grid item xs={12} md={7}>
                      {!!this.state.loadingUsers ? (
                        <center>
                          <CircularProgress />
                        </center>
                      ) : (
                        <TextField
                          label={TextDE.timetracking.coworkersLabel}
                          id='break-picker-dialog'
                          select
                          value={this.state.selectedUser}
                          name='selectedUser'
                          fullWidth
                          size='small'
                          variant='outlined'
                          onChange={this.handleChange}
                          disabled={!!this.props.edit}
                        >
                          {Object.values(this.state.usersAll).map((option, index) => (
                            <MenuItem key={'user-select-' + index} value={option.uuid}>
                              <ListItem
                                ContainerComponent='div'
                                ContainerProps={{ style: { width: '100%' } }}
                                key={'user-select-list-' + option.uuid}
                              >
                                <ListItemAvatar>
                                  <Avatar
                                    alt={option.firstname + ' ' + option.lastname}
                                    src={
                                      process.env.REACT_APP_API_URL +
                                      '/avatar/' +
                                      option.uuid +
                                      '?thumb'
                                    }
                                  />
                                </ListItemAvatar>
                                <ListItemText primary={option.firstname + ' ' + option.lastname} />
                              </ListItem>
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    </Grid>
                  </>
                )}

                <Grid item xs={12} md={5}>
                  {TextDE.timetracking.trackedAtLabel}
                </Grid>
                <Grid item xs={12} md={7}>
                  <DatePicker
                    style={{ flexGrow: 1, justifyContent: 'center' }}
                    disableFuture
                    label={TextDE.timetracking.trackedAtLabel}
                    id='date-picker-dialog'
                    format='dd.MM.yyyy'
                    clearable={false}
                    disabled={!!this.props.edit}
                    fullWidth
                    value={this.state.trackedAt}
                    size='small'
                    inputVariant='outlined'
                    onChange={this.handleTrackedAtChange}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  {TextDE.timetracking.start_timeLabel}
                </Grid>
                <Grid item xs={12} md={7}>
                  <TimePicker
                    label={TextDE.timetracking.start_timeLabel}
                    helperText={this.state.end_timeAlert}
                    error={!!this.state.end_timeAlert}
                    ampm={false}
                    id='start_time-picker-dialog'
                    format='HH:mm'
                    clearable={false}
                    value={this.state.start_time}
                    disabled={this.state.disable_start_time}
                    fullWidth
                    size='small'
                    inputVariant='outlined'
                    onChange={this.handleStartTimeChange}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Uhr</InputAdornment>,
                    }}
                  />
                </Grid>

                <Grid item xs={12} md={5}>
                  {TextDE.timetracking.end_timeLabel}
                </Grid>
                <Grid item xs={12} md={7}>
                  <TimePicker
                    label={TextDE.timetracking.end_timeLabel}
                    helperText={this.state.end_timeAlert}
                    error={!!this.state.end_timeAlert}
                    ampm={false}
                    id='end_time-picker-dialog'
                    format='HH:mm'
                    clearable={false}
                    value={this.state.end_time}
                    disabled={this.state.disable_end_time}
                    size='small'
                    fullWidth
                    inputVariant='outlined'
                    onChange={this.handleEndTimeChange}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Uhr</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  {TextDE.timetracking.breakLabel}
                </Grid>
                <Grid item xs={12} md={7}>
                  <TextField
                    label={TextDE.timetracking.breakLabel}
                    id='break-picker-dialog'
                    type='number'
                    value={this.state.break}
                    disabled={this.state.disable_break}
                    name='break'
                    fullWidth
                    helperText={this.state.breakTimeAlert}
                    error={!!this.state.breakTimeAlert}
                    size='small'
                    variant='outlined'
                    onChange={this.handleBreakChange}
                    inputProps={{ step: 5, min: 0 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Minuten</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  {TextDE.timetracking.totalLabel}
                </Grid>
                <Grid item xs={12} md={7}>
                  <Typography
                    align='right'
                    component='p'
                    variant='button'
                    style={{ width: '100%' }}
                  >
                    {!!this.state.end_time && !!this.state.start_time
                      ? this.state.totalTime
                      : 'Keine Zeiten eingetragen'}
                  </Typography>
                </Grid>

                <Grid item xs={12} md={5}>
                  {TextDE.timetracking.categoryLabel}
                </Grid>
                <Grid item xs={12} md={7}>
                  {!!this.state.loadingCategories ? (
                    <center>
                      <CircularProgress />
                    </center>
                  ) : (
                    <TextField
                      label={TextDE.timetracking.categoryLabel}
                      id='break-picker-dialog'
                      select
                      value={this.state.category}
                      name='category'
                      fullWidth
                      size='small'
                      variant='outlined'
                      onChange={this.handleChange}
                    >
                      <MenuItem disabled key='category-select-default' value=''>
                        Bitte auswählen
                      </MenuItem>
                      {this.state.categories.map((option, index) => (
                        <MenuItem
                          key={'category-select-' + index}
                          value={option.category}
                          onClick={() => this.handleTimes(option)}
                        >
                          {option.category}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </Grid>

                {!!this.state.errors &&
                  Object.values(this.state.errors).length > 0 &&
                  Object.values(this.state.errors).map((error, index) => (
                    <Grid key={'error-alerts-' + index} item xs={12}>
                      <Alert severity='error'>{error}</Alert>
                    </Grid>
                  ))}
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          {!!this.state.submitting && (
            <center>
              <CircularProgress />
            </center>
          )}
          <DialogActions>
            <Button onClick={this.handleClose} className={classes.moveLeft} color='secondary'>
              {TextDE.close}
            </Button>
            <Button
              disabled={!!!this.state.isValid}
              onClick={this.handleSubmit}
              color='primary'
              variant='contained'
            >
              {!!this.props.edit ? TextDE.edit : TextDE.save}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TimeTrackingAdd);
