import React from 'react';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import renderHtml from '../../../lib/transformHtml';
//import IconButton from '@material-ui/core/IconButton';
//import Collapse from '@material-ui/core/Collapse';
//import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { readable } from '../../../lib/dateFunctions';

const styles = (theme) => ({
  root: {
    maxWidth: 'inherit',
    height: '215px',
    maxHeight: '215px',
    width: 'inherit',
    backgroundColor: '#fffcdd',
    cursor: 'pointer',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    /*
		[theme.breakpoints.up('md')]: {
			minWidth: theme.spacing(5),
			minHeight: theme.spacing(5),
			'&>svg' : {
				minWidth: theme.spacing(3),
				minHeight: theme.spacing(3),
			}
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
		*/
  },
  lessPad: {
    padding: theme.spacing(1),
  },
  paper: {
    borderTop: '1px solid #efefef',
    backgroundColor: '#ffffff',
    minHeight: '100%',
    maxHeight: '100%',
    padding: theme.spacing(1) + '!important',
    overflow: 'clip',
  },
});

class SmallNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleCollapse = () => {
    this.setState({ open: !this.state.open });
  };

  //	componentDidMount() {
  //
  //	}

  render() {
    const { classes } = this.props;

    return (
      <Card raised={true} variant='outlined' classes={{ root: classes.root }}>
        <CardHeader
          avatar={
            !!this.props.item.creator ? (
              <Avatar
                className={classes.avatar}
                alt={this.props.item.creator?.firstname + ' ' + this.props.item.creator?.lastname}
                src={
                  process.env.REACT_APP_API_URL +
                  '/avatar/' +
                  this.props.item.creator?.uuid +
                  '?thumb'
                }
              ></Avatar>
            ) : !!this.props.item.data?.creator ? (
              <Avatar
                className={classes.avatar}
                alt={this.props.item.data?.creator?.name}
                src={this.props.item.data?.creator?.avatar}
              />
            ) : (
              <Avatar className={classes.avatar}>
                <EditIcon />
              </Avatar>
            )
          }
          title={this.props.item.data?.subject}
          subheader={readable(this.props.item.created_at)}
          className={classes.lessPad}
        />

        <CardContent className={classes.paper}>
          {renderHtml(this.props.item.data?.body)}
        </CardContent>
      </Card>
    );
  }
}

export default compose(withStyles(styles))(SmallNote);
