import React from 'react';
import { compose } from 'react-recompose';

import { Constants } from '../../../lib/Constants';

import Mail from './Mail';
import System from './System';
import Note from './Note';
import Appointment from './Appointment';
import Competitive from '../components/Competitive';
import Chatbot from './Chatbot';
// TBD
//import Sensor from './Sensor';
//import Phone from './Phone';
//import Hook  from './Hook';

class NoteWrap extends React.Component {
  render() {
    //const { classes } = this.props;
    let types = Constants.getInboxItemTypesAsObject();
    if (this.props.data === null || this.props.data === '') {
      return null;
    }
    let body = null;
    switch (this.props.data.type_id) {
      case types.app.id: // 1,
        body = (
          <System
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
          />
        );
        break;

      case types.new_procedure.id: // 1,
        body = (
          <System
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
          />
        );
        break;

      case types.new_job.id: // 1,
        body = (
          <System
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
          />
        );
        break;
      //case types.phone.id: // 2,
      //	body = <Phone id={this.props.data.uuid || null} key={"item-"+this.props.index} item={this.props.data} />;
      //	break;

      case types.email.id: // 3,
        body = (
          <Mail
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
            overwriteOpen={false}
            respondFunction={this.props.respondEmail}
          />
        );
        break;

      //case types.sensors.id: // 4,
      //	body = <Sensor id={this.props.data.uuid || null} key={"item-"+this.props.index} item={this.props.data} />;
      //	break;

      case types.chatbot.id: // 5,
        body = (
          <Chatbot
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
            respondFunction={this.props.respondChat}
          />
        );
        break;

      //case types.hook.id: // 6,
      //	body = <Hook id={this.props.data.uuid || null} key={"item-"+this.props.index} item={this.props.data} />;
      //	break;

      case types.note.id: // 7,
        body = (
          <Note
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
            editNoteFunction={(uuid, subject, text) =>
              this.props.editNoteFunction(uuid, subject, text)
            }
          />
        );
        break;

      case types.CalendarTodayIcon.id: // 7,
        body = (
          <Appointment
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
          />
        );
        break;

      case 1337: // 99,
        body = (
          <Competitive
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
            callback={() => console.log('return <Competitive callback')}
          />
        );
        break;

      //case types.other.id: // 99,
      //	body = <Note id={this.props.data.uuid || null} key={"item-"+this.props.index} item={this.props.data} />;
      //	break;

      default:
        body = (
          <Note
            id={this.props.data.uuid || null}
            key={'item-' + this.props.index}
            item={this.props.data}
            editNoteFunction={(uuid, subject, text) =>
              this.props.editNoteFunction(uuid, subject, text)
            }
          />
        );
        break;
    }
    if (body === null) {
      return null;
    }
    return body;
  }
}

//const mapStateToProps = state => ({Authentication: state.Authentication, UserID: state.User.uuid,  Router: state.router})
//
//const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose()(NoteWrap);
//withStyles(styles),
//withRouter,
//connect(
//	mapStateToProps,
//	mapDispatchToProps
//),
