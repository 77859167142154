import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import AlarmIcon from '@material-ui/icons/Alarm';
import DescriptionIcon from '@material-ui/icons/Description';
import LinkIcon from '@material-ui/icons/Link';
import TitleIcon from '@material-ui/icons/Title';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import WorkIcon from '@material-ui/icons/Work';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import StarIcon from '@material-ui/icons/Star';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import Badge from '@material-ui/core/Badge';
import Avatar from '@material-ui/core/Avatar';
import { Constants } from '../../lib/Constants';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import Alert from '@material-ui/lab/Alert';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/de';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
import clsx from 'clsx';
import CustomToolbarComponent from '../Inbox/components/CustomToolbarComponent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DE as TextDE } from '../../lib/Text';

moment.locale('de'); // it is required to select default locale manually

const styles = (theme) => ({
  results: {
    display: 'block',
    position: 'fixed',
    zIndex: '1003',
    maxHeight: '200px',
    overflow: 'auto',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  listoption: {
    '&[aria-disabled="true"]': {
      display: 'none',
    },
  },
  editor: {
    padding: theme.spacing(1, 1),
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: '#EEEEEE',
    borderWidth: '1px',
    minHeight: '200px',
    '&>div': {
      padding: theme.spacing(1, 1),
      '&>div': {
        '&>div': {
          '&>div': {
            '&>div': {
              '&>div': {
                margin: 0,
              },
            },
          },
        },
      },
    },
  },
  active: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    '&>div': {},
  },
  editorEmpty: {
    borderColor: theme.palette.error.main,
    borderWidth: '2px',
    '&>div': {},
  },
  toolbar: {
    height: '54px',
    backgroundColor: '#EEEEEE',
    marginBottom: '0',
    alignItems: 'flex-end',
    '&>div': {
      height: '32px',
      marginBottom: '6px',
    },
    '&>div:nth-child(1)': {
      marginLeft: '12px',
    },
  },
});

const times = () => {
  let times = [];
  let count = 7;

  while (count < 20) {
    times.push((count < 10 ? '0' + count : count) + ':00');
    times.push((count < 10 ? '0' + count : count) + ':15');
    times.push((count < 10 ? '0' + count : count) + ':30');
    times.push((count < 10 ? '0' + count : count) + ':45');
    count = count + 1;
  }
  return times;
};

const timesEnd = (start) => {
  let times = [];
  let count = 7;
  let upperLimit = 20;
  if (!!start) {
    let begin = start.split(':');
    //let minutes = begin[1];
    count = parseInt(begin[0]);
    if (count >= 18) {
      upperLimit = count + 4;
    }

    while (count < upperLimit) {
      times.push((count < 10 ? '0' + count : count) + ':00');
      times.push((count < 10 ? '0' + count : count) + ':15');
      times.push((count < 10 ? '0' + count : count) + ':30');
      times.push((count < 10 ? '0' + count : count) + ':45');
      count = count + 1;
    }
  } else {
    while (count < upperLimit) {
      times.push((count < 10 ? '0' + count : count) + ':00');
      times.push((count < 10 ? '0' + count : count) + ':15');
      times.push((count < 10 ? '0' + count : count) + ':30');
      times.push((count < 10 ? '0' + count : count) + ':45');
      count = count + 1;
    }
  }
  return times;
};

class DetailDialog extends React.Component {
  constructor(props) {
    super(props);
    let attendees = [];
    let users = [];
    if (!!this.props.data?.attendees) {
      this.props.data.attendees.forEach((attendee) => {
        if (!!attendee.user) {
          let tmp = this.props.users.find((element) => element.uuid === attendee.user.uuid);
          if (!!tmp && !!tmp.uuid) {
            users.push(tmp);
          } else {
            users.push({
              full: attendee.full || attendee.email,
              mail: attendee.email,
              uuid: attendee.user.uuid,
            });
          }
        } else {
          attendees.push({
            full: attendee.cn || attendee.email,
            mail: attendee.email,
          });
        }
      });
    }
    const start = !!this.props.data?.start
      ? moment(
          typeof this.props.data?.start.toUTCString === 'function'
            ? new Date(this.props.data?.start.toUTCString())
            : new Date(this.props.data?.start),
        )
      : null;
    //const start = !!this.props.data?.start
    //  ? moment(new Date(this.props.data?.start))
    //  : null;//moment().add(1, 'days').hours(8).minutes(0).seconds(0);
    const starttime = !!start
      ? (start.hour() < 10 ? '0' + start.hour() : start.hour()) +
        ':' +
        (start.minute() < 10 ? '0' + start.minute() : start.minute())
      : '';
    const end = !!this.props.data?.end
      ? moment(
          typeof this.props.data?.end.toUTCString === 'function'
            ? new Date(this.props.data?.end.toUTCString())
            : new Date(this.props.data?.end),
        )
      : null;
    //const end = !!this.props.data?.end
    //  ? moment(new Date(this.props.data?.end))
    //  : null;//moment().add(1, 'days').hours(10).minutes(0).seconds(0);
    const endtime = !!end
      ? (end.hour() < 10 ? '0' + end.hour() : end.hour()) +
        ':' +
        (end.minute() < 10 ? '0' + end.minute() : end.minute())
      : '';
    this.state = {
      isSubmitting: false,
      hasError: false,
      title: this.props.data.title || '',
      //description: this.props.data.body || '',
      location: this.props.data.location || '',
      locationError: '',
      attendees: attendees || [this.props.User.email],
      users: users || [],
      usersAll: this.props.users,
      contactsAll: this.props.contacts,
      linkItem: this.props.data.raw?.linkItem || '',
      keyword: '',
      searchResults: [],
      starttime: starttime,
      endtime: endtime,
      startdate: start,
      enddate: end,
      isAllDay: this.props.data.isAllDay || false,
      editorFocus: false,
      editorState: EditorState.createWithContent(
        ContentState.createFromText(this.props.data.body || ''),
      ),
    };

    this.handleEndDateChange = this.handleEndDateChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleStartDateChange = this.handleStartDateChange.bind(this);
    this.handleStartTimeChange = this.handleStartTimeChange.bind(this);
    this.timeIsValid = this.timeIsValid.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  async componentDidMount() {
    if (!!this.props.disableProcedure) {
      let contacts = await fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          let tmp = [];
          for (var key in json) {
            if (!!json[key].emails) {
              if (json[key].emails.length > 1) {
                for (var email in json[key].emails) {
                  tmp.push({
                    full:
                      json[key].salutation.name +
                      (json[key].salutation_id === 4
                        ? ' ' + json[key].name
                        : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                          json[key].firstname +
                          ' ' +
                          json[key].name),
                    mail: json[key].emails[email].email,
                    pref: json[key].emails[email].pref,
                    work: json[key].emails[email].work,
                  });
                }
              } else if (json[key].emails.length === 1) {
                tmp.push({
                  full:
                    json[key].salutation.name +
                    (json[key].salutation_id === 4
                      ? ' ' + json[key].name
                      : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                        json[key].firstname +
                        ' ' +
                        json[key].name),
                  mail: json[key].emails[0].email,
                  pref: json[key].emails[0].pref,
                  work: json[key].emails[0].work,
                });
              }
            }
          }
          return tmp;
        })
        .catch((error) => {
          console.log('FEHLER', error);
          return [];
        });

      let users = await fetch(process.env.REACT_APP_API_URL + '/api/users', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          let tmp = [];
          json.forEach((value, key) => {
            if (!!!value.deleted_at) {
              tmp.push({
                uuid: value.uuid,
                full: value.firstname + ' ' + value.lastname,
                //full: value.firstname +((!!value.nickname) ? ' "'+value.nickname+'" ': ' ')+ value.lastname,
                mail: value.email,
                roles: value.roles.map((role) => role.name),
              });
            }
          });
          return tmp;
        })
        .catch((error) => {
          console.log('FEHLER', error);
          return [];
        });

      this.setState({ usersAll: users, contactsAll: contacts }, this.getCalendar);
    }
  }

  async submit(event) {
    event.preventDefault();
    //console.log(
    //        {
    //            summary: this.state.title,
    //            description: this.state.description,
    //            isAllDay: this.state.isAllDay,
    //            linkType: this.state.linkItem.linkType,
    //            linkTo: this.state.linkItem.uuid,
    //            attendees: JSON.stringify(this.state.attendees),
    //            firstoccurence: this.state.start.unix(),
    //            lastoccurence: this.state.end.unix(),
    //            location: this.state.location,
    //        }
    //    );

    this.setState({ isSubmitting: true, hasError: false });
    if (this.props.isProcedure === false) {
      let formData = new FormData();
      formData.append('_method', 'PUT');

      let response = await fetch(
        process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.data.raw.linkItem.uuid,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.props.Authentication.access_token}`,
          },
          body: formData,
        },
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          return json;
        })
        .catch((error) => {
          return false;
        });
      if (response === false) {
        this.setState({
          isSubmitting: false,
          hasError: [TextDE.calendar.appointmentForm.errorMessage.somethingWentWrong],
        });
        return;
      }
    }

    let url = process.env.REACT_APP_API_URL + '/api/calendar';
    let method = 'POST';

    let firstoccurence = moment(this.state.startdate);
    let lastoccurence = moment(this.state.enddate);

    if (!!!this.state.isAllDay) {
      if (this.state.starttime.indexOf(':') === -1) {
        this.setState({
          isSubmitting: false,
          hasError: [TextDE.calendar.appointmentForm.errorMessage.startDateTimeWrong],
        });
        return;
      }
      let startTime = this.state.starttime.split(':');
      if (Number.isInteger(parseInt(startTime[0])) && Number.isInteger(parseInt(startTime[1]))) {
        firstoccurence.hours(parseInt(startTime[0]));
        firstoccurence.minutes(parseInt(startTime[1]));
      } else {
        this.setState({
          isSubmitting: false,
          hasError: [TextDE.calendar.appointmentForm.errorMessage.startDateTimeWrong],
        });
        return;
      }

      if (this.state.endtime.indexOf(':') === -1) {
        this.setState({
          isSubmitting: false,
          hasError: [TextDE.calendar.appointmentForm.errorMessage.endDateTimeWrong],
        });
        return;
      }
      let endTime = this.state.endtime.split(':');
      if (Number.isInteger(parseInt(endTime[0])) && Number.isInteger(parseInt(endTime[1]))) {
        lastoccurence.hours(parseInt(endTime[0]));
        lastoccurence.minutes(parseInt(endTime[1]));
      } else {
        this.setState({
          isSubmitting: false,
          hasError: [TextDE.calendar.appointmentForm.errorMessage.endDateTimeWrong],
        });
        return;
      }
    }

    if (!firstoccurence.isValid()) {
      this.setState({
        isSubmitting: false,
        hasError: [TextDE.calendar.appointmentForm.errorMessage.startDateTimeWrong],
      });
      return;
    }

    if (!lastoccurence.isValid()) {
      this.setState({
        isSubmitting: false,
        hasError: [TextDE.calendar.appointmentForm.errorMessage.endDateTimeWrong],
      });
      return;
    }

    if (lastoccurence.isBefore(firstoccurence)) {
      this.setState({
        isSubmitting: false,
        hasError: [TextDE.calendar.appointmentForm.errorMessage.endDateTimeEarlierThanStart],
      });
      return;
    }

    let data = {
      summary: this.state.title,
      //description: this.state.description,
      description: this.state.editorState.getCurrentContent().getPlainText(),
      isAllDay: this.state.isAllDay,
      linkType: this.state.linkItem.linkType,
      linkTo: this.state.linkItem.uuid,
      attendees: JSON.stringify([...this.state.attendees, ...this.state.users]),
      users: JSON.stringify(this.state.users),
      firstoccurence: firstoccurence.unix(),
      lastoccurence: lastoccurence.unix(),
      location: this.state.location,
    };

    if (
      !!this.props.data?.calendarId &&
      !!this.props.data?.id &&
      !!this.props.data?.raw &&
      !!this.props.data?.raw?.uid
    ) {
      url = url + '/' + this.props.data.raw.uid;
      method = 'PUT';
      data.calendarId = this.props.data.calendarId;
      data.id = this.props.data.id;
      data.uid = this.props.data.raw.uid;
    }

    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.id && !!response.calendarId) {
      this.setState({ isSubmitting: false, hasError: false });
      if (!!this.props.data.raw) {
        response['id'] = this.props.data.id;
        response['calendarId'] = this.props.data.calendarId;
        this.props.handleupdatefunction(response);
      } else {
        this.props.handleclosefunction(response);
      }
    } else {
      this.setState({ isSubmitting: false, hasError: response });
    }
  }

  async search() {
    if (this.state.keyword.length >= 3) {
      let url = process.env.REACT_APP_API_URL + '/api/search';
      let term = this.state.keyword.trim();
      if (term.indexOf(' ') > 0) {
        let atmp = term.split(' ').map((value) => 'search[]=' + encodeURIComponent(value));
        url = url + '?' + atmp.join('&');
      } else {
        url = url + '?search=' + encodeURIComponent(term);
      }

      let response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          return json;
        })
        .catch((error) => {
          return error;
        });

      if (!!response.data) {
        let list = [];
        Object.values(response.data).forEach((item) => {
          // Do not add Jobs to this resultlist anymore 05032024
          /*
           * if (item.procedure?.jobs.length > 0) {
           *   item.procedure.jobs.forEach((job) => {
           *     list.push({
           *       uuid: job.uuid,
           *       id: job.id,
           *       linkType: 'job',
           *       type: 9,
           *       serial: '[ID#' + item.procedure.serial + '/' + job.serial + ']',
           *       subject: job.data.subject,
           *       subtitle: job.data.subtitle,
           *       body: job.data.body,
           *     });
           *   });
           * }
           */
          if (!!item.procedure) {
            list.push({
              uuid: item.uuid,
              id: item.procedure.id,
              linkType: 'procedure',
              type: item.type,
              serial: '[ID#' + item.procedure.serial + ']',
              subject: item.procedure.data.subject,
              subtitle: item.procedure.data.subtitle,
              body: item.procedure.data.body,
            });
          }
        });

        this.setState({ searchResults: list });
      }
    }
  }

  handleSearch = (event, value) => {
    this.setState(
      {
        keyword: value,
      },
      this.search,
    );
  };

  handleUserChange = (event, value) => {
    this.setState({ users: value });
  };

  handleContactChange = (event, value) => {
    this.setState({ attendees: value });
  };

  handleLinkToSelect = (event, value) => {
    if (value === null) {
      this.setState({ linkItem: '' });
    } else {
      if (this.state.title !== '') {
        this.setState({ linkItem: value });
      } else {
        this.setState({ linkItem: value, title: value.subject });
      }
    }
  };

  handleEndDateChange = (date, value) => {
    this.setState({ enddate: date });
  };
  handleEndTimeChange = (event, time) => {
    this.setState({ endtime: time });
  };

  timeIsValid = (time, date, timeStart = false) => {
    if ((typeof date).toString().toLowerCase() === 'undefined' || !!date === false) {
      return TextDE.calendar.appointmentForm.helperTextDate;
    } else if (!!date?.isValid === false) {
      return TextDE.calendar.appointmentForm.errorMessage.wrongDate;
    }

    if (!this.validateTime(time)) {
      return TextDE.calendar.appointmentForm.errorMessage.wrongDateTime;
    }

    let tmp = time.toString().split(':');
    if (!((typeof tmp).toString().toLowerCase() === 'object' && tmp.length === 2)) {
      return TextDE.calendar.appointmentForm.errorMessage.wrongDateTime;
    }

    let hours = parseInt(tmp[0]);
    let minutes = parseInt(tmp[1]);
    if (Number.isInteger(parseInt(tmp[0])) && Number.isInteger(parseInt(tmp[1]))) {
      if (hours < 0 || hours > 24) {
        return TextDE.calendar.appointmentForm.errorMessage.wrongDateTimeHour;
      } else if (minutes < 0 || minutes > 59) {
        return TextDE.calendar.appointmentForm.errorMessage.wrongDateTimeMinute;
      } else {
        if (timeStart === false) {
          return '';
        }
        let startTimeValid = this.timeIsValid(timeStart, date);
        if (!!startTimeValid) {
          return startTimeValid;
        }

        if (!this.state.startdate?.isSame(this.state.enddate, 'day')) {
          return '';
        }

        let tmp = timeStart.toString().split(':');
        let startHours = parseInt(tmp[0]);
        if (hours - startHours > 0) {
          return '';
        }
        let startMinutes = parseInt(tmp[1]);
        if (hours - startHours === 0 && minutes - startMinutes > 0) {
          return '';
        }
        return TextDE.calendar.appointmentForm.errorMessage.wrongDateTimeTooShort;
      }
    }
    return TextDE.calendar.appointmentForm.errorMessage.wrongDateTime;
  };

  dateFormatter = (str) => {
    return str;
  };

  validateTime(timeString) {
    const regex = new RegExp('^(0?[0-9]|1[0-9]|2[0-3]):([1-5][0-9]|0?[0-9])$', '');
    return regex.test(timeString);
  }

  handleStartDateChange = (date, value) => {
    this.setState({ startdate: date, enddate: date });
  };
  handleStartTimeChange = (event, time) => {
    this.setState({ starttime: time });
  };

  handleChange = (event, data) => {
    if (typeof event !== 'object') {
      console.warn(event, data);
    } else {
      if (!!event.target.name && event.target.type === 'checkbox') {
        this.setState({ [event.target.name]: event.target.checked });
      } else if (!!event.target.name && !!event.target.value) {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
  };

  handleAddressChange = (event, value) => {
    this.setState({ location: event });
  };

  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      //.then(latLng => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };
  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        //componentForm[addressType] = val;
        //console.log(addressType, val);
        //setCompanyState({...companyState, [addressType]: val});
        newAddress[addressType] = val;
      }
    }
    this.setState({ location: address });
  };

  render() {
    const { classes } = this.props;
    const filter = createFilterOptions();

    let contactSuggestions = [];
    if (
      !!this.props.suggestions &&
      !!this.props.suggestions?.contacts &&
      this.props.suggestions?.contacts.length > 0
    ) {
      this.props.suggestions.contacts.forEach((value) => {
        if (!!value.contact?.emails && value.contact?.emails.length > 0) {
          value.contact.emails.forEach((email) => {
            contactSuggestions.push({
              full: value.contact.firstname + ' ' + value.contact.name,
              mail: email.email,
            });
          });
        }
      });
    }

    return (
      <>
        <form onSubmit={this.submit}>
          <DialogTitle id='scroll-dialog-title'>
            {TextDE.calendar.appointmentForm.title}: {this.state.title}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2} justifyContent='center' alignItems='center'>
              <Grid item xs={2} md={1} className={classes.center}>
                <TitleIcon />
              </Grid>
              <Grid item xs={10} md={11}>
                <TextField
                  fullWidth
                  name='title'
                  value={this.state.title}
                  onChange={this.handleChange}
                  label='Titel'
                  variant='outlined'
                  error={!(this.state.title.length > 0)}
                  required
                />
              </Grid>
              <Grid item xs={2} md={1} className={classes.center}>
                <DescriptionIcon />
              </Grid>
              <Grid item xs={10} md={11}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ width: '100%', padding: '4px 16px' }} shrink={true}>
                    {' '}
                    Beschreibung *
                  </InputLabel>
                  <Editor
                    editorState={this.state.editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    wrapperClassName='wrapper-class'
                    editorClassName={clsx(classes.editor, {
                      [classes.active]: this.state.editorFocus,
                      [classes.editorEmpty]: !this.state.editorState.getCurrentContent().hasText(),
                    })}
                    toolbarClassName={classes.toolbar}
                    onFocus={(event) => {
                      this.setState({ editorFocus: true });
                    }}
                    onBlur={(event) => {
                      this.setState({ editorFocus: false });
                    }}
                    toolbar={{
                      options: ['emoji', 'link'],
                      link: {
                        className: 'bordered-option-classname',
                      },
                      emoji: {
                        className: 'bordered-option-classname',
                      },
                    }}
                    toolbarCustomButtons={[
                      <CustomToolbarComponent onChange={this.onEditorStateChange} />,
                    ]}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={2} md={1} className={classes.center}>
                <AlarmIcon />
              </Grid>
              <Grid item xs={10} md={11}>
                <Grid container justifyContent='center' alignItems='flex-start' spacing={2}>
                  <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'de'}>
                    <Grid item xs={8} lg={2}>
                      <KeyboardDatePicker
                        value={this.state.startdate}
                        label={TextDE.calendar.appointmentForm.beginDateLabel}
                        name='start'
                        placeholder={moment().format('DD.MM.YYYY')}
                        onChange={this.handleStartDateChange}
                        error={!!!this.state.startdate?.isValid}
                        InputLabelProps={{ shrink: true }}
                        invalidDateMessage={TextDE.calendar.appointmentForm.errorMessage.wrongDate}
                        helperText={TextDE.calendar.appointmentForm.helperTextDate}
                        validationError={TextDE.calendar.appointmentForm.errorMessage.noDate}
                        required
                        style={{ width: '100%' }}
                        format='DD.MM.YYYY'
                        inputProps={{ style: { textAlign: 'end' } }}
                        inputVariant='outlined'
                        margin='normal'
                        cancelLabel={TextDE.abort}
                        okLabel={TextDE.choose}
                      />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <Autocomplete
                        autoComplete={false}
                        disabled={this.state.isAllDay}
                        includeInputInList
                        freeSolo
                        size='medium'
                        onChange={this.handleStartTimeChange}
                        name='starttime'
                        required
                        options={times()}
                        value={this.state.starttime}
                        autoSelect
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={this.timeIsValid(
                              this.state.starttime,
                              this.state.startdate,
                            )}
                            variant='outlined'
                            required
                            error={
                              !!!this.state.isAllDay &&
                              !!this.timeIsValid(this.state.starttime, this.state.startdate)
                            }
                            label={TextDE.calendar.appointmentForm.timeOfDay}
                            style={{ marginTop: '16px', marginBottom: '8px' }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} lg={2} style={{ display: 'flex' }}>
                      <Typography component='center' style={{ margin: 'auto' }}>
                        {TextDE.calendar.appointmentForm.until}
                      </Typography>
                    </Grid>
                    <Grid item xs={8} lg={2}>
                      <KeyboardDatePicker
                        value={this.state.enddate}
                        label={TextDE.calendar.appointmentForm.endsDateLabel}
                        name='end'
                        onChange={this.handleEndDateChange}
                        error={!!!this.state.enddate?.isValid}
                        InputLabelProps={{ shrink: true }}
                        placeholder={moment().format('DD.MM.YYYY')}
                        minDate={!!this.state.startdate ? this.state.startdate : null}
                        required
                        style={{ width: '100%' }}
                        format='DD.MM.YYYY'
                        inputVariant='outlined'
                        inputProps={{ style: { textAlign: 'end' } }}
                        margin='normal'
                        cancelLabel={TextDE.abort}
                        okLabel={TextDE.choose}
                        invalidDateMessage={TextDE.calendar.appointmentForm.errorMessage.wrongDate}
                        helperText={TextDE.calendar.appointmentForm.errorMessage.noDate}
                        validationError={TextDE.calendar.appointmentForm.errorMessage.noDate}
                        minDateMessage={
                          TextDE.calendar.appointmentForm.errorMessage.endDateTimeEarlierThanStart
                        }
                      />
                    </Grid>
                    <Grid item xs={4} lg={3}>
                      <Autocomplete
                        freeSolo
                        classes={{ option: classes.listoption }}
                        size='medium'
                        disabled={this.state.isAllDay}
                        onChange={this.handleEndTimeChange}
                        getOptionDisabled={(option) => {
                          if (
                            !!this.state.startdate &&
                            !!this.state.enddate &&
                            typeof this.state.startdate === 'object' &&
                            this.state.startdate.isSame(this.state.enddate, 'day')
                          ) {
                            let startTime = this.state.starttime.split(':');
                            let optionTime = option.split(':');
                            if (parseInt(startTime[0]) > parseInt(optionTime[0])) {
                              return true;
                            } else {
                              if (parseInt(startTime[0]) === parseInt(optionTime[0])) {
                                return parseInt(startTime[1]) > parseInt(optionTime[1]);
                              } else {
                                return false;
                              }
                            }
                          } else {
                            return false;
                          }
                        }}
                        name='endtime'
                        required
                        options={timesEnd(this.state.starttime)}
                        value={this.state.endtime}
                        disableClearable
                        autoSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.timeIsValid(
                              this.state.endtime,
                              this.state.enddate,
                              this.state.starttime,
                            )}
                            fullWidth
                            required
                            variant='outlined'
                            label={TextDE.calendar.appointmentForm.timeOfDay}
                            error={
                              !!!this.state.isAllDay &&
                              !!this.timeIsValid(
                                this.state.endtime,
                                this.state.enddate,
                                this.state.starttime,
                              )
                            }
                            style={{ marginTop: '16px', marginBottom: '8px' }}
                          />
                        )}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>

                  <Grid item xs={12} md={12}>
                    <FormGroup aria-label='position' row>
                      <FormControlLabel
                        checked={this.state.isAllDay}
                        onChange={this.handleChange}
                        control={<Checkbox color='primary' />}
                        label={TextDE.calendar.appointmentForm.isAllDay}
                        labelPlacement='end'
                        name='isAllDay'
                        value='isAllDay'
                      />
                    </FormGroup>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={2} md={1} className={classes.center}>
                <PeopleIcon />
              </Grid>
              <Grid item xs={10} md={11}>
                <Autocomplete
                  name='users'
                  multiple
                  required
                  options={this.state.usersAll}
                  getOptionLabel={(option) => option.full || option}
                  value={this.state.users}
                  onChange={this.handleUserChange}
                  renderOption={(option) => {
                    return (
                      <ListItem component='span' key={option.uuid}>
                        <ListItemAvatar>
                          <Avatar
                            alt={option.full}
                            src={
                              process.env.REACT_APP_API_URL + '/avatar/' + option.uuid + '?thumb'
                            }
                          ></Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          secondaryTypographyProps={{ component: 'span' }}
                          primary={option.full}
                          secondary={option.roles.map((value, index) => (
                            <Chip key={index} label={value} />
                          ))}
                        />
                      </ListItem>
                    );
                  }}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant='outlined'
                      label={TextDE.calendar.appointmentForm.assignAppointment}
                    />
                  )}
                />
              </Grid>

              {!!this.props.suggestions?.users && this.props.suggestions?.users.length > 0 ? (
                <>
                  <Grid item xs={2} md={1}></Grid>
                  <Grid item xs={10} md={11}>
                    {this.props.suggestions?.users.map((value, key) => {
                      return (
                        <Chip
                          key={'users-key-' + key}
                          style={{ marginRight: '8px' }}
                          icon={<AddIcon />}
                          clickable
                          color='primary'
                          variant='outlined'
                          onClick={() =>
                            this.handleUserChange(undefined, [
                              ...this.state.users,
                              {
                                uuid: value.user.uuid,
                                //full: value.user.firstname +((!!value.user.nickname) ? ' \''+value.user.nickname+'\' ': ' ')+ value.user.lastname,
                                full: value.user.firstname + ' ' + value.user.lastname,
                                mail: value.user.email,
                              },
                            ])
                          }
                          label={
                            value.user.firstname +
                            (!!value.user.nickname ? ' "' + value.user.nickname + '" ' : ' ') +
                            value.user.lastname
                          }
                        />
                      );
                    })}
                  </Grid>
                </>
              ) : null}

              <Grid item xs={2} md={1} className={classes.center}>
                <PeopleIcon />
              </Grid>
              <Grid item xs={10} md={11}>
                <Autocomplete
                  id='contactselect'
                  name='uuid'
                  required
                  freeSolo
                  multiple
                  getOptionSelected={(option, value) => option.mail === value.mail}
                  options={this.state.contactsAll}
                  renderOption={(option) => {
                    return (
                      <ListItem component='div' key={option.mail}>
                        <ListItemAvatar>
                          <Badge
                            color='primary'
                            badgeContent={option.pref ? <StarIcon className={classes.xs} /> : 0}
                            overlap='rectangular'
                            variant='standard'
                          >
                            <Avatar variant='rounded' className={classes.small}>
                              {option.work ? <WorkIcon /> : <AlternateEmailIcon />}
                            </Avatar>
                          </Badge>
                        </ListItemAvatar>
                        <ListItemText primary={option.mail} secondary={option.full} />
                      </ListItem>
                    );
                  }}
                  value={this.state.attendees}
                  onChange={this.handleContactChange}
                  includeInputInList={false}
                  disableClearable
                  disabled={!!!this.state.linkItem?.linkType}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label={TextDE.calendar.appointmentForm.assignExternalAttendee}
                      variant='outlined'
                      name='newContact'
                      onChange={this.handleChange}
                    />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    // Suggest the creation of a new value
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        mail: params.inputValue,
                        full: `"${params.inputValue}" ${TextDE.add}`,
                      });
                    }

                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    // Value selected with enter, right from the input
                    if (typeof option === 'string') {
                      return option;
                    }
                    // Add "xxx" option created dynamically
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    // Regular option
                    return option.mail;
                  }}
                />
              </Grid>
              {!!this.props.suggestions?.contacts && this.props.suggestions?.contacts.length > 0 ? (
                <>
                  <Grid item xs={2} md={1}></Grid>
                  <Grid item xs={10} md={11}>
                    {contactSuggestions.map((value, key) => {
                      return (
                        <Chip
                          key={'contact-map-' + key}
                          style={{ marginRight: '8px' }}
                          icon={<AddIcon />}
                          clickable
                          color='primary'
                          variant='outlined'
                          onClick={() =>
                            this.handleContactChange(undefined, [
                              ...this.state.attendees,
                              {
                                full: value.full,
                                mail: value.mail,
                              },
                            ])
                          }
                          label={value.full}
                        />
                      );
                    })}
                  </Grid>
                </>
              ) : null}
              {!!this.props.disableProcedure ? null : (
                <>
                  <Grid item xs={2} md={1} className={classes.center}>
                    <LinkIcon />
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <Autocomplete
                      style={{ width: '100%' }}
                      getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.serial + ' - ' + option.subject
                      }
                      disabled={!!this.props.disableProcedure}
                      options={this.state.searchResults}
                      autoComplete
                      filterSelectedOptions
                      loading={!(this.state.searchResults.length > 0)}
                      loadingText={TextDE.search.label}
                      noOptionsText='Nichts gefunden'
                      value={this.state.linkItem}
                      onChange={this.handleLinkToSelect}
                      onInputChange={this.handleSearch}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={TextDE.calendar.appointmentForm.assignToEvent}
                          variant='outlined'
                          fullWidth
                        />
                      )}
                      renderOption={(option) => {
                        const type = Constants.getInboxItemType(option.type);
                        return (
                          <>
                            <ListItemIcon>{type.icon}</ListItemIcon>
                            <ListItemText
                              primary={option.serial + ' - ' + option.subject}
                              secondary={option.subtitle || option.body}
                            />
                          </>
                        );
                      }}
                    />
                  </Grid>
                </>
              )}

              {!!this.props.suggestions?.jobs && this.props.suggestions?.jobs.length > 0 ? (
                <>
                  <Grid item xs={2} md={1} className={classes.center}>
                    <LinkIcon />
                  </Grid>
                  <Grid item xs={10} md={11}>
                    <ButtonGroup>
                      <Button
                        startIcon={<AccountTreeIcon />}
                        endIcon={
                          this.state.linkItem.uuid === this.props.data?.raw?.linkItem?.uuid ? (
                            <CheckCircleIcon />
                          ) : (
                            <RadioButtonUncheckedIcon />
                          )
                        }
                        color={
                          this.state.linkItem.uuid === this.props.data?.raw?.linkItem?.uuid
                            ? 'primary'
                            : 'secondary'
                        }
                        variant={
                          this.state.linkItem.uuid === this.props.data?.raw?.linkItem?.uuid
                            ? 'contained'
                            : 'outlined'
                        }
                        onClick={() =>
                          this.handleLinkToSelect(undefined, {
                            linkTo: this.props.data.raw?.linkItem.uuid,
                            uuid: this.props.data.raw?.linkItem.uuid,
                            linkType: 'procedure',
                            serial: this.props.data.raw?.linkItem.serial,
                            subject: this.props.data.raw?.linkItem.subject,
                          })
                        }
                      >
                        {this.props.data.raw?.linkItem.serial +
                          ' - ' +
                          this.props.data.raw?.linkItem.subject}
                      </Button>
                      {this.props.suggestions.jobs.map((value, index) => {
                        return (
                          <Button
                            key={'job-map-' + index}
                            startIcon={<WorkIcon />}
                            endIcon={
                              this.state.linkItem.uuid === value.uuid ? (
                                <CheckCircleIcon />
                              ) : (
                                <RadioButtonUncheckedIcon />
                              )
                            }
                            color={
                              this.state.linkItem.uuid === value.uuid ? 'primary' : 'secondary'
                            }
                            variant={
                              this.state.linkItem.uuid === value.uuid ? 'contained' : 'outlined'
                            }
                            onClick={() =>
                              this.handleLinkToSelect(undefined, {
                                linkTo: value.uuid,
                                uuid: value.uuid,
                                linkType: 'job',
                                serial: value.serial,
                                subject: value.data.subject,
                              })
                            }
                          >
                            {value.serial + ' - ' + value.data.subject}
                          </Button>
                        );
                      })}
                    </ButtonGroup>
                  </Grid>
                </>
              ) : null}

              <Grid item xs={2} md={1} className={classes.center}>
                <LocationOnIcon />
              </Grid>
              <Grid item xs={10} md={11}>
                <PlacesAutocomplete
                  value={this.state.location}
                  name='location'
                  onChange={this.handleAddressChange}
                  onSelect={this.handleAddressSelect}
                  searchOptions={{ types: ['address'] }}
                  shouldFetchSuggestions={this.state.location.length >= 4}
                >
                  {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div className={classes.container}>
                      <TextField
                        fullWidth
                        label='Ort'
                        variant='outlined'
                        autoComplete='false'
                        error={!(this.state.location.length > 0)}
                        {...getInputProps({
                          placeholder: 'Ihre Adresse...',
                        })}
                      />

                      {suggestions.length > 0 ? (
                        <Card className={classes.results}>
                          {suggestions.map((suggestion, key) => {
                            return (
                              <CardContent
                                {...getSuggestionItemProps(suggestion, {})}
                                className={classes.result}
                                key={'address-suggestions-' + key}
                              >
                                <Grid container alignItems='center'>
                                  <Grid item>
                                    <LocationOnIcon className={classes.icon} />
                                  </Grid>
                                  <Grid item xs>
                                    <Typography variant='subtitle1'>
                                      {suggestion.formattedSuggestion.mainText}
                                    </Typography>
                                    <Typography variant='body1' color='textSecondary'>
                                      {suggestion.formattedSuggestion.secondaryText}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            );
                          })}
                        </Card>
                      ) : null}
                    </div>
                  )}
                </PlacesAutocomplete>
                {this.state.location.length >= 4 && !!this.state.locationError ? (
                  <Alert className={classes.fullWidth} severity='error'>
                    {this.state.locationError}
                  </Alert>
                ) : null}
              </Grid>
              {!!this.props.suggestions?.locations &&
              this.props.suggestions?.locations.length > 0 ? (
                <>
                  <Grid item xs={2} md={1}></Grid>
                  <Grid item xs={10} md={11}>
                    {this.props.suggestions?.locations.map((value, key) => {
                      return (
                        <Chip
                          key={'address-map-' + key}
                          style={{ marginRight: '8px' }}
                          icon={<AddIcon />}
                          clickable
                          color='primary'
                          variant='outlined'
                          onClick={() =>
                            this.setState({
                              location:
                                value.address.route +
                                ' ' +
                                value.address.street_number +
                                ', ' +
                                value.address.postal_code +
                                ' ' +
                                value.address.city.locality,
                            })
                          }
                          label={
                            value.address.route +
                            ' ' +
                            value.address.street_number +
                            ', ' +
                            value.address.postal_code +
                            ' ' +
                            value.address.city.locality
                          }
                        />
                      );
                    })}
                  </Grid>
                </>
              ) : null}

              {!!this.state.hasError ? (
                typeof this.state.hasError === 'object' ? (
                  Object.values(this.state.hasError).map((error, key) => (
                    <Grid item xs={12} key={'error-' + key}>
                      <Alert severity='error'>{error}</Alert>
                    </Grid>
                  ))
                ) : (
                  <Grid item xs={12}>
                    <Alert severity='error'>
                      <pre>{JSON.stringify(this.state.hasError)}</pre>
                    </Alert>
                  </Grid>
                )
              ) : null}

              {this.state.attendees.length > 0 && (
                <Grid item xs={12}>
                  <Alert className={classes.fullWidth} severity='info'>
                    {TextDE.calendar.appointmentForm.infoWhenAssignedToExternalAttendee}
                  </Alert>
                </Grid>
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            {!!this.state.isSubmitting ? (
              <CircularProgress />
            ) : (
              <>
                <Button
                  onClick={this.props.handleclosefunction}
                  color='secondary'
                  variant='outlined'
                >
                  Schließen
                </Button>
                <Button
                  type='submit'
                  style={{ marginLeft: 'auto' }}
                  color='primary'
                  variant='contained'
                  disabled={
                    !(
                      !!this.state.title &&
                      !!this.state.editorState.getCurrentContent().hasText() &&
                      !!this.state.startdate &&
                      !!this.state.enddate &&
                      ((this.state.starttime.length > 0 && !!!this.state.isAllDay) ||
                        !!this.state.isAllDay) &&
                      ((this.state.endtime.length > 0 && !!!this.state.isAllDay) ||
                        !!this.state.isAllDay) &&
                      !!this.state.location
                    )
                  }
                >
                  {TextDE.save}
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state, ownProps) => ({
  User: state.User,
  Authentication: state.Authentication,
  Data: ownProps.data,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(DetailDialog);
