import React from 'react';
import { connect } from 'react-redux';
//import { Editor } from 'react-draft-wysiwyg';
import { EditorState, Modifier } from 'draft-js';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { DE as TextDE } from '../../../lib/Text';
import { Constants } from '../../../lib/Constants';
import RemoveIcon from '@material-ui/icons/Remove';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';

class CustomToolbarComponent extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    editorState: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = { selection: 'default', templates: [] };
  }

  addTextFragment = (event) => {
    const { editorState, onChange } = this.props;
    const contentState = Modifier.replaceText(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      event.target.value,
      editorState.getCurrentInlineStyle(),
    );
    onChange(EditorState.push(editorState, contentState, 'insert-characters'));
  };

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/customer/templates', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ templates: Object.values(json) });
      })
      .catch((error) => {
        return false;
      });
  }

  render() {
    const iconMap = Constants.iconMap();
    return (
      <Select
        disabled={!!!(this.state.templates.length > 0)}
        variant='outlined'
        style={{ marginTop: -2, minWidth: 150, backgroundColor: '#fff' }}
        value={this.state.selection}
        onChange={this.addTextFragment}
        IconComponent={CollectionsBookmarkIcon}
        SelectDisplayProps={{ style: { padding: '4px 8px' } }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '330px',
              minWidth: '180px',
              overflowY: 'auto',
            },
          },
        }}
        autoWidth
      >
        <MenuItem key={'aab-1'} value={'default'} disabled>
          <Typography variant='inherit' noWrap>
            {TextDE.textModule}
          </Typography>
        </MenuItem>

        <Divider />
        {this.state.templates
          .sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1))
          .map((value, index) => {
            let tmp = iconMap[value.type];
            return (
              <MenuItem key={index} value={value.text}>
                <ListItemIcon>
                  {!!tmp ? tmp.icon : <RemoveIcon color='inherit' fontSize='inherit' />}
                </ListItemIcon>

                <Typography variant='inherit' noWrap>
                  {value.title}
                </Typography>
              </MenuItem>
            );
          })}
      </Select>
    );
  }
}
const mapStateToProps = (state) => ({ Authentication: state.Authentication });

export default connect(mapStateToProps)(CustomToolbarComponent);
