import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';

import Inbox from './Inbox';

class Preview extends React.Component {
  render() {
    return <Inbox doNotDisplayHint={false} />;
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(connect(mapStateToProps, mapDispatchToProps))(Preview);
