import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  CircularProgress,
  Grid,
  Button,
} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LogarithmicScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { DE as TextDE } from '../../../lib/Text';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Badge from '@material-ui/core/Badge';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

ChartJS.register(CategoryScale, LogarithmicScale, BarElement, Title, Tooltip, Legend);

const styles = (theme) => ({
  size: {
    maxWidth: 'calc( 100% - 16px)',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: 'calc( 100% - 96px)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&>svg': {
      height: '87%',
      width: '87%',
    },
  },
  action: {
    marginTop: 'inherit',
    marginRight: '18px',
    flex: '0 0 auto',
    alignSelf: 'flex-start',
  },
  chartContainer: {
    margin: '0px',
    marginTop: '-6px',
    marginBottom: '-14px',
    padding: '0px',
  },
  alignment: {
    justifyContent: 'flex-start',
  },
  alignmentIcon: {
    marginLeft: 'auto',
  },
});

class ProceduresBarGraph extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fetching: true,
      maxHeight: 0,
    };

    this.redirectTo = this.redirectTo.bind(this);
    this.graphClick = this.graphClick.bind(this);
    this.chartRef = React.createRef();
    this.headRef = React.createRef();
  }

  graphClick(event) {
    this.redirectTo(getElementAtEvent(this.chartRef.current, event));
  }

  redirectTo(data) {
    let state = false;
    if (typeof data === 'string') {
      state = data;
    } else if (data.length > 0 && !!this.props.data.labels) {
      state = this.props.data.labels[data[0].index];
    }

    if (typeof state === 'string') {
      if (state === 'Neu') {
        this.props.dispatch(push('/nachrichten/new'));
      } else {
        let url = '/nachrichten/alle?state=' + encodeURI(state);
        if (this.props.currentFetchType !== 'all') {
          url = url + '&user=' + this.props.User.uuid;
        }
        this.props.dispatch(push(url));
      }
    }
  }

  componentDidUpdate(oldProps, oldstate) {
    //console.log(this.props.Dimensions.maxWidth, '>' ,this.props.Theme.breakpoints.values.md);
    if (
      oldProps.isFetching !== this.props.isFetching &&
      this.props.maxHeight !== 0 &&
      this.props.isFetching === false &&
      !!this.headRef.current
    ) {
      this.setState({
        fetching: false,
      });
    }
    if (oldProps.maxHeight !== this.props.maxHeight && !!this.headRef.current) {
      this.setState({
        maxHeight: this.props.maxHeight - this.headRef.current.clientHeight - 20,
      });
    }
  }

  render() {
    const ChartOptions = {
      indexAxis: 'y',
      layout: { padding: { bottom: 0, top: 0 } },
      responsive: true,
      maintainAspectRatio: false,
      aspectRatio: 1,
      tooltips: {
        enabled: true,
        mode: 'nearest',
        borderWidth: 1,
        borderColor: this.props.Theme.palette.divider,
        backgroundColor: '#ffffff',
        titleFontColor: this.props.Theme.palette.text.primary,
        bodyFontColor: this.props.Theme.palette.text.secondary,
        footerFontColor: this.props.Theme.palette.text.secondary,
      },
      elements: {
        bar: {
          borderWidth: 2,
          borderRadius: 4,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          display: function (context) {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            return value > 0;
          },
          borderRadius: 4,
          borderWidth: 0.5,
          borderColor: 'rgba(0, 0, 0, 0.8)',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          color: '#000000',
          font: {
            size: 16,
            weight: 'bold',
          },
          padding: { top: 4, bottom: 2, left: 12, right: 12 },
          offset: 8,
          align: 'start',
          anchor: 'end',
        },
      },
      scales: {
        xAxes: {
          display: true,
          align: 'right',
          type: 'logarithmic',
          ticks: false,
          grid: {
            lineWidth: 0,
            drawBorder: false,
          },
          gridLines: {
            display: false,
          },
        },
        yAxes: {
          display: true,
          align: 'right',
          ticks: false,
          grid: {
            lineWidth: 0,
            drawBorder: false,
          },
        },
      },
    };
    const { classes } = this.props;

    return (
      <Card>
        <CardHeader
          ref={this.headRef}
          classes={{ action: classes.action, content: classes.content }}
          titleTypographyProps={{
            variant: 'h5',
            component: 'p',
            noWrap: true,
            className: classes.size,
          }}
          title={
            (this.props.currentFetchType !== 'all' ? TextDE.my : TextDE.all) +
            ' ' +
            TextDE.procedure.labelPlural
          }
          action={
            <Badge
              badgeContent={this.props.count}
              overlap='rectangular'
              max={9999999}
              color='primary'
            >
              <ToggleButtonGroup
                value={this.props.currentFetchType}
                exclusive
                onChange={this.props.toggleProcedureFetchType}
                aria-label='switch all or my procedures'
              >
                <ToggleButton value='mine'>
                  <AccountTreeIcon color='inherit' /> &nbsp; {TextDE.my}
                </ToggleButton>
                <ToggleButton value='all'>
                  {TextDE.all} &nbsp; <AllInclusiveIcon color='inherit' />
                </ToggleButton>
              </ToggleButtonGroup>
            </Badge>
          }
          avatar={
            <Avatar className={classes.avatar}>
              {this.props.currentFetchType === 'all' ? (
                <AllInclusiveIcon color='inherit' />
              ) : (
                <AccountTreeIcon color='inherit' />
              )}
            </Avatar>
          }
        />
        {!!this.props.isFetching ? (
          <CardContent className={classes.content}>
            <CircularProgress />
          </CardContent>
        ) : (
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12} lg={4}>
                <Grid
                  container
                  spacing={2}
                  justifyContent={
                    this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.md
                      ? 'center'
                      : 'space-between'
                  }
                  alignItems='stretch'
                >
                  {this.props.data.labels.map((label, index) => {
                    return (
                      <Grid key={index} item xs={12}>
                        <Button
                          onClick={(elems) => this.redirectTo(label)}
                          className={classes.alignment}
                          classes={{ endIcon: classes.alignmentIcon }}
                          size='small'
                          fullWidth
                          variant='contained'
                          color='default'
                          startIcon={
                            <Avatar
                              variant='rounded'
                              className={classes.avatar}
                              style={{
                                backgroundColor: this.props.data.datasets[0].backgroundColor[index],
                              }}
                            >
                              {this.props.data.datasets[0].data[index]}
                            </Avatar>
                          }
                          endIcon={<ArrowForwardIosIcon />}
                        >
                          {label}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
              <Hidden mdDown>
                <Grid item xs={12} lg={8}>
                  <div className={classes.chartContainer}>
                    {this.state.maxHeight > 0 ? (
                      <Bar
                        onClick={this.graphClick}
                        data={this.props.data}
                        height={this.state.maxHeight}
                        options={ChartOptions}
                        plugins={[ChartDataLabels]}
                        ref={this.chartRef}
                      />
                    ) : (
                      <center>
                        <CircularProgress />
                      </center>
                    )}
                  </div>
                </Grid>
              </Hidden>
            </Grid>
          </CardContent>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Dimensions: state.Dimensions,
  Theme: state.Style.Theme,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ProceduresBarGraph);
