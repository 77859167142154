import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import InboxPreviewData from './InboxPreviewData';
import Register from './Register';
import Decline from './Decline';
import { DE as TextDE } from '../../lib/Text';
const styles = (theme) => ({
  root: {},
  top: {
    width: '100vw',
  },
  center: {
    height: '100vh',
  },
});

class Inbox extends React.Component {
  constructor(props) {
    super(props);
    console.log();
    this.state = {
      email:
        (props.User?.email !== null &&
          props.Authentication.session_expires_at - new Date().getTime() > 6000 &&
          props.User.email) ??
        '',
      data: false,
      base64: this.props.match.params.base64 ?? false,
      checked: false,
      url: atob(this.props.match.params.base64 ?? ''),
      agbAccept: false,
      displayRegisterForm: false,
    };

    this.acceptInquiry = this.acceptInquiry.bind(this);
    this.declineInquiry = this.declineInquiry.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.getData = this.getData.bind(this);
    this.getPDF = this.getPDF.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDenyClick = this.handleDenyClick.bind(this);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
  }
  async acceptInquiry(token) {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.state.data.data.uuid);

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ _method: 'put', accept: true, reason: null }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.error(error);
        alert(error);
        return error;
      });

    this.props.dispatch(push('/nachrichten/detail/' + this.state.data.data.uuid));
    window.location.reload(true);
  }
  componentDidMount() {}

  handleCheckbox() {
    this.setState({ agbAccept: !this.state.agbAccept });
  }
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  declineInquiry(data) {
    // Post it
    console.log(data);
    alert('schade.. Tschüss!');
  }

  handleDenyClick() {
    this.setState({ displayDeclineForm: true });
  }
  handleAcceptClick() {
    this.setState({ displayRegisterForm: true });
  }

  async handleSubmit(event) {
    if (event !== null) {
      event.preventDefault();
    }

    this.setState({ loading: true });

    if (!this.state.email.trim()) {
      return;
    }
    this.getData();
  }

  getPDF() {
    let endpoint = process.env.REACT_APP_API_URL + '/api/email/verify/av';
    fetch(endpoint, {
      //headers: {
      //	Authorization: `Bearer ${this.props.Authentication.access_token}`
      //},
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', 'AVV.pdf');
        a.click();
      });
    });
  }

  getData = async () => {
    this.setState({ loading: false });
    //let response = await fetch( this.state.url + '&email='+this.state.email, {
    let response = await fetch(this.state.url, {
      //headers: {
      //	Authorization: `Bearer ${this.props.Authentication.access_token}`
      //},
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        this.setState({ loading: false, error: response });
        return error;
      });

    if (!!response.access && response.access === 'granted') {
      this.setState({ loading: false, error: false, data: response });
    } else {
      this.setState({ loading: false, error: response });
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        {!!this.state.data.user && !!this.state.displayRegisterForm && (
          <Register
            user={this.state.data.user}
            open={this.state.displayRegisterForm}
            close={() => this.setState({ displayRegisterForm: false })}
            acceptInquiry={(data) => this.acceptInquiry(data)}
          />
        )}

        {!!this.state.data.user && !!this.state.displayDeclineForm && (
          <Decline
            user={this.state.data.user}
            open={this.state.displayDeclineForm}
            close={() => this.setState({ displayDeclineForm: false })}
            declineInquiry={(data) => this.declineInquiry(data)}
          />
        )}

        <Grid
          container
          className={classes.center}
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={0}
        >
          {!!!this.state.data && (
            <Grid item xs={6}>
              <Card className={classes.root}>
                <div
                  style={{
                    backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
                    width: '100%',
                    minHeight: '80px',
                    height: 'auto',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    marginTop: '2em',
                  }}
                ></div>
                <CardHeader title={TextDE.inboxPreview.title} />
                <CardContent>
                  <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='body1'>{TextDE.inboxPreview.titleHelper}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          variant='outlined'
                          margin='normal'
                          required
                          fullWidth
                          id='email'
                          label={TextDE.form.email.label}
                          placeholder={TextDE.form.email.placeholder}
                          helperText={TextDE.form.email.helperText}
                          name='email'
                          autoComplete='email'
                          onChange={this.handleChange}
                          value={this.state.email}
                          autoFocus
                        />
                      </Grid>

                      <Grid item xs={12} md={8}>
                        <FormControlLabel
                          value='end'
                          control={
                            <Checkbox
                              checked={this.state.agbAccept}
                              onChange={this.handleCheckbox}
                            />
                          }
                          label={<Typography>{TextDE.agbAccept}</Typography>}
                          labelPlacement='end'
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Button size='small' color='primary' target='_blank' href='/assets/agb.pdf'>
                          {TextDE.agb} {TextDE.download}
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        {!this.state.loading && !!this.state.error && (
                          <Alert severity='error' closeText={TextDE.close}>
                            {' '}
                            {this.state.error.error ??
                              JSON.stringify(this.state.error, null, 4)}{' '}
                          </Alert>
                        )}
                        {this.state.loading ? (
                          <center>
                            <CircularProgress />
                          </center>
                        ) : (
                          <Button
                            type='submit'
                            fullWidth
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            disabled={!(this.state.email.length > 3 && !!this.state.agbAccept)}
                            endIcon={<LockOutlinedIcon />}
                          >
                            {TextDE.more}
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Grid>
          )}
          {!!this.state.data && (
            <InboxPreviewData
              data={this.state.data}
              handleDenyClick={this.handleDenyClick}
              handleAcceptClick={this.handleAcceptClick}
            />
          )}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Router: state.router,
  User: state.User,
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Inbox);
