import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
//import {createFilterOptions} from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ContactCardDetail from './ContactCardDetail';
import ContactCardAddNew from './ContactCardAddNew';
import ContactDetail from '../../Contact/ContactDetail';
import { Constants } from '../../../lib/Constants';
import Box from '@material-ui/core/Box';
import { DE as TextDE } from '../../../lib/Text';
const iconHeight = 64;

const styles = (theme) => ({
  backdrop: {
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#fff',
    zIndex: '1001',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  noPad: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  inherit: {
    boxShadow: 'inherit',
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  inheritBackground: {
    borderRadius: 'inherit',
    backgroundColor: 'inherit',
  },
  chip: {
    maxWidth: '100%',
  },
  card: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  cardContact: {
    borderRight: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    borderBottom: 'none',
    overflow: 'visible',
    backgroundColor: '#aed581',
    minHeight: '106px',
    marginBottom: theme.spacing(1),
  },
  cardContactCollapsed: {
    justifyContent: 'start',
    alignContent: 'center',
    display: 'flex',
  },
  maximize4: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(-50%, 2px)',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      marginLeft: '100.5%',
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
  maximize: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(calc(-33.333333% - ' + theme.spacing(0.75) + 'px), 2px)',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
  insetlist: {
    flexGrow: 1,
    padding: '0 0 0 ' + theme.spacing(4) + 'px',
    '&>li': {
      borderLeft: '2px solid ' + theme.palette.divider,
      '&>div>p': {
        fontSize: '90%',
      },
      '&>div>div>span': {
        fontSize: '90%',
      },
    },
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  button: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '60vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      //right: iconHeight/1.5+30+'px',
      //marginTop: '-'+iconHeight/1.5+'px',
      top: '10vh',
      left: '1vw',
      maxWidth: '98vw',
      minWidth: '98vw',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: '1002',
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  cardHeaderContent: {
    height: 'auto',
    paddingBottom: 0,
  },
  cardHeaderAction: {},
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#aed581',
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  fullwidth: {
    width: '100%',
  },
  customInputRoot: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: theme.palette.text.primary,
    },
  },
  xs: {
    width: theme.spacing(1.2),
    height: theme.spacing(1.2),
  },
  small: {
    backgroundColor: theme.palette.text.primary,
    width: theme.spacing(3),
    height: theme.spacing(3),
    '&>svg': {
      color: theme.palette.background.default,
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
    },
  },
  mb: {
    marginBottom: theme.spacing(2),
  },
  pointer: {
    color: theme.palette.text.disabled,
    cursor: 'copy',
  },
  pointerDetail: {
    cursor: 'pointer',
  },
  navigationIcon: {
    backgroundColor: '#ee4a4a',
  },
});

class ContactCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contactsAll: [],
      openSnackbar: false,
      cardContactNew: false,
      form: {
        model: this.props.isInbox ? 'inbox' : 'procedures',
        uuid: this.props.inboxUuid || '',
        isSubmitting: false,
        hasError: false,
        contact: null,
        newContact: false,
        tags: [],
      },
      selectedContact: null,
      contactToDelete: false,
      addressToDelete: false,
      toShare: null,
    };

    this.handleContactDelete = this.handleContactDelete.bind(this);
    this.callback = this.callback.bind(this);

    this.shareDialog = this.shareDialog.bind(this);
    this.closeShareDialog = this.closeShareDialog.bind(this);

    this.handleContactToConnectAdd = this.handleContactToConnectAdd.bind(this);
    this.handleContactToConnectRemove = this.handleContactToConnectRemove.bind(this);

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.deleteAddressDialog = this.deleteAddressDialog.bind(this);
    this.closeDeleteAddressDialog = this.closeDeleteAddressDialog.bind(this);
  }

  shareDialog(value) {
    console.log('Called share dialog', value, this.props.linkedConnectUsers);
    if (!!this.props.canInviteUsers && !!value.uuid && typeof value.uuid === 'string') {
      value.displayName = `${value.salutation?.name ?? ''} ${value.title?.name ?? ''} ${
        value.firstname ?? ''
      } ${value.name}`.trim();
      this.setState({ toShare: value });
    }
  }
  closeShareDialog() {
    this.setState({ toShare: false });
  }

  handleContactToConnectRemove = async () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });
    let formData = {};
    formData['contact'] = this.state.toShare.uuid;
    formData['_method'] = 'PUT';
    formData['shareToConnect'] = false;
    formData['context'] = 'contacts';
    formData['uuid'] = this.props.inboxUuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint +
      '/api/' +
      (this.props.isInbox ? 'inbox' : 'procedures') +
      '/' +
      formData.uuid +
      '/' +
      formData.context;
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      })
      .catch((e) => console.error(e));

    if (response?.uuid === this.state.toShare.uuid) {
      this.setState(
        {
          ...this.state,
          toShare: false,
          form: {
            model: this.props.isInbox ? 'inbox' : 'procedures',
            uuid: this.props.inboxUuid,
            isSubmitting: false,
            hasError: false,
            contact: null,
            newContact: false,
            tags: [],
          },
        },
        this.props.callback(),
      );
      return;
    }
    this.setState(
      {
        ...this.state,
        toShare: { error: true },
      },
      this.props.callback(),
    );
  };

  handleContactToConnectAdd = async () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });
    let formData = {};
    formData['contact'] = this.state.toShare.uuid;
    formData['_method'] = 'PUT';
    formData['shareToConnect'] = true;
    formData['context'] = 'contacts';
    formData['uuid'] = this.props.inboxUuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint +
      '/api/' +
      (this.props.isInbox ? 'inbox' : 'procedures') +
      '/' +
      formData.uuid +
      '/' +
      formData.context;
    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return response;
      })
      .catch((e) => console.error(e));

    if (response?.uuid === this.state.toShare.uuid) {
      this.setState(
        {
          ...this.state,
          toShare: false,
          form: {
            model: this.props.isInbox ? 'inbox' : 'procedures',
            uuid: this.props.inboxUuid,
            isSubmitting: false,
            hasError: false,
            contact: null,
            newContact: false,
            tags: [],
          },
        },
        this.props.callback(),
      );
      return;
    }
    console.log('ERROR', response);
    this.setState(
      {
        ...this.state,
        toShare: false,
      },
      this.props.callback(),
    );
  };

  deleteDialog(value) {
    if (!!this.props.canInviteUsers && !!value.uuid && typeof value.uuid === 'string') {
      console.log(value);
      value.displayName = `${value.salutation?.name ?? ''} ${value.title?.name ?? ''} ${
        value.firstname ?? ''
      } ${value.name}`.trim();
      this.setState({ contactToDelete: value });
    }
  }

  closeDeleteDialog() {
    this.setState({ contactToDelete: false });
  }

  handleContactDelete() {
    // Drop User from procedure
    let formData = {};
    formData['_method'] = 'DELETE';
    formData['tags'] = this.state.contactToDelete.tags?.map((tag) => tag?.name?.de ?? tag);
    formData['context'] = 'contacts';
    formData['contact'] = this.state.contactToDelete.uuid;
    formData['uuid'] = this.props.inboxUuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return true;
      });

    if (
      !!this.state.contactToDelete.addresses &&
      Object.keys(this.state.contactToDelete.addresses).length > 0
    ) {
      Object.values(this.state.contactToDelete.addresses).forEach((address) => {
        let formData = {};
        formData['_method'] = 'DELETE';
        formData['tags'] = address.tags?.map((tag) => tag?.name?.de ?? tag);
        formData['context'] = 'addresses';
        formData['uuid'] = this.props.inboxUuid;
        formData['address'] = address.address_id ?? address.address.id;
        if (parseInt(formData['address']) >= 0) {
          let endpoint = process.env.REACT_APP_API_URL;
          endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
          fetch(endpoint, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${this.props.Authentication.access_token}`,
            },
            body: JSON.stringify(formData),
          })
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                throw res;
              }
            })
            .then(function (response) {
              return true;
            });
        }
      });
    }
    this.setState({ contactToDelete: false }, () => this.props.callback());
  }

  deleteAddressDialog(value) {
    if (!!this.props.canInviteUsers && !!value.id && !!value.address_id) {
      value.displayName = `${value.address.route ?? ''} ${value.address.street_number ?? ''} ${
        value.address.postal_code ?? ''
      } ${value.address.city.locality ?? ''}`.trim();
      this.setState({ addressToDelete: value });
    }
  }

  closeDeleteAddressDialog() {
    this.setState({ addressToDelete: false });
  }

  handleDeleteAddress = () => {
    // Drop Address from procedure
    let formData = {};
    formData['_method'] = 'DELETE';
    formData['tags'] = this.state.addressToDelete.tags.map((tag) => tag?.name?.de ?? tag);
    formData['context'] = 'addresses';
    formData['uuid'] = this.props.inboxUuid;
    formData['address'] = this.state.addressToDelete.address_id;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/procedures/' + formData.uuid + '/' + formData.context;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        return true;
      });
    this.setState({ addressToDelete: false }, () => this.props.callback());
  };

  handleContactClick = (value) => {
    this.setState({ selectedContact: value });
  };

  handleContactClose = () => {
    this.setState({ selectedContact: null });
  };

  callback = () => {
    //console.log("callback");
    let tmp = this.state.selectedContact;
    this.handleContactClose();
    this.handleContactClick(tmp);
  };

  copyToClipboard = (event, value) => {
    this.setState({ openSnackbar: true });
    var textField = document.createElement('textarea');
    textField.innerText = value;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  handleSnackbarClose = () => {
    this.setState({ openSnackbar: false });
  };

  render() {
    //const filter = createFilterOptions();
    //const { classes } = this.props;

    let localContactTags = [
      ...Constants.tags.contacts.resident,
      ...Constants.tags.contacts.residentHidden,
      ...Constants.tags.contacts.creator,
    ];
    let paymentContactTags = [
      ...Constants.tags.contacts.invoice,
      ...Constants.tags.contacts.invoiceHidden,
    ];
    let localAddress = [
      ...Constants.tags.addresses.resident,
      ...Constants.tags.addresses.residentHidden,
      ...Constants.tags.addresses.creator,
    ];
    let paymentAddress = [
      ...Constants.tags.addresses.invoice,
      ...Constants.tags.addresses.invoiceHidden,
    ];

    return (
      <Box ml={0} mt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }}>
        <Dialog open={!!this.state.toShare} onClose={this.closeShareDialog}>
          <DialogTitle>Vorgang Teilen?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {!!this.state.toShare?.isConnectUser
                ? TextDE.procedure.shareConnectDialog.revokeAccessText(
                    this.state.toShare?.displayName,
                  )
                : TextDE.procedure.shareConnectDialog.grantAccessText(
                    this.state.toShare?.displayName,
                  )}
              {!!this.state.toShare?.error ??
                TextDE.procedure.shareConnectDialog.alerts.unknownIssue}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeShareDialog} color='primary'>
              {TextDE.abort}
            </Button>
            {!!this.state.toShare?.isConnectUser && !!!this.state.toShare?.error ? (
              <Button
                variant='contained'
                onClick={this.handleContactToConnectRemove}
                color='secondary'
              >
                {TextDE.procedure.shareConnectDialog.revokeAccessButton}
              </Button>
            ) : (
              <Button
                variant='contained'
                onClick={this.handleContactToConnectAdd}
                color='secondary'
              >
                {TextDE.procedure.shareConnectDialog.grantAccessButton}
              </Button>
            )}
          </DialogActions>
        </Dialog>

        <Dialog open={!!this.state.contactToDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>{TextDE.contact.deleteTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {TextDE.contact.deleteTextNamedFunction(this.state.contactToDelete.displayName)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleContactDelete} color='secondary'>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={!!this.state.addressToDelete} onClose={this.closeDeleteAddressDialog}>
          <DialogTitle>{TextDE.contact.deleteTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {TextDE.address.deleteTextNamedFunction(this.state.addressToDelete.displayName)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteAddressDialog} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleDeleteAddress} color='secondary'>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={!!this.state.selectedContact}
          onClose={this.handleContactClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {!!this.state.selectedContact ? (
            <ContactDetail
              contact={this.state.selectedContact}
              reload={true}
              close={this.handleContactClose}
              deleteCallback={this.callback}
              deleteItemCallback={this.callback}
              addItemCallback={this.callback}
              callback={this.props.callback}
              sendMailTo={this.props.sendMailTo}
            />
          ) : null}
        </Dialog>

        <ContactCardAddNew
          callback={this.props.callback}
          inboxUuid={this.props.inboxUuid}
          localContact={this.props.contacts.filter(
            (contactWrap) =>
              contactWrap.tags.findIndex((tag) =>
                localContactTags.includes(tag?.name?.de ?? tag),
              ) >= 0,
          )}
          paymentContact={this.props.contacts.filter(
            (contactWrap) =>
              contactWrap.tags.findIndex((tag) =>
                paymentContactTags.includes(tag?.name?.de ?? tag),
              ) >= 0,
          )}
        />

        {this.props.contacts.map((value) => {
          const labelId = `list-contacts-label-${value.contact.uuid}`;

          let addresses = {};

          if (!!value.contact.address && !!value.contact.address?.id) {
            addresses[value.contact.address.id] = {
              address: value.contact.address,
              isConnected: true,
              tags: [TextDE.contact.savedAddressTag],
            };
          }

          //console.log("[MAPPING THE ADDRESSES]", this.props.addresses);

          if (this.props.addresses.length > 0) {
            //console.log("[GETTING MY ARRAYS]", value);

            let local = value.tags?.findIndex((el) => localContactTags.includes(el.name?.de ?? el));
            //console.log("[AM I LOCAL - index]", local >= 0);

            let payment = value.tags?.findIndex((el) =>
              paymentContactTags.includes(el.name?.de ?? el),
            );
            //console.log("[AM I payment - index]", payment >= 0);

            this.props.addresses.forEach((globalAddress, index) => {
              //console.log("[SORT ADDRESSES - index]", globalAddress);
              let add = {};

              if (local >= 0) {
                let tmp = globalAddress.tags?.findIndex((el) =>
                  localAddress.includes(el.name?.de ?? el),
                );
                if (tmp >= 0) {
                  add[globalAddress.address.id] = globalAddress;
                }
              }
              if (payment >= 0) {
                let tmp = globalAddress.tags?.findIndex((el) =>
                  paymentAddress.includes(el.name?.de ?? el),
                );
                if (tmp >= 0) {
                  add[globalAddress.address.id] = globalAddress;
                }
              }
              //console.log("[SORT ADDRESSES - what to ad]", add);
              if (Object.keys(add).length > 0) {
                //console.log("[SORT ADDRESSES - show me]", addresses, add);
                addresses = { ...addresses, ...add };
                //console.log("[SORT ADDRESSES - show me after]", addresses, add);
              }
            });
          }

          let hasAccessToInquiry = this.props.linkedConnectUsers.find(
            (contactWrap) => contactWrap.contact_uuid === value.contact.uuid,
          );

          return (
            <ContactCardDetail
              inboxUuid={this.props.inboxUuid}
              key={labelId}
              labelId={labelId}
              contactWrapper={value}
              hasAccessToInquiry={hasAccessToInquiry}
              addresses={addresses}
              callback={this.props.callback}
              shareFunction={this.shareDialog}
              deleteFunction={this.deleteDialog}
              deleteAddressFunction={this.deleteAddressDialog}
              localContact={this.props.contacts.filter(
                (contactWrap) =>
                  contactWrap.tags.findIndex((tag) =>
                    localContactTags.includes(tag?.name?.de ?? tag),
                  ) >= 0,
              )}
              paymentContact={this.props.contacts.filter(
                (contactWrap) =>
                  contactWrap.tags.findIndex((tag) =>
                    paymentContactTags.includes(tag?.name?.de ?? tag),
                  ) >= 0,
              )}
              sendMailTo={this.props.sendMailTo}
            />
          );
        })}
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(ContactCard);
