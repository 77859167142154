import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
//import Typography from '@material-ui/core/Typography';
//import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
//import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TextField from '@material-ui/core/TextField';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
//import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import AssignmentReturnOutlinedIcon from '@material-ui/icons/AssignmentReturnOutlined';
import Alert from '@material-ui/lab/Alert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { Constants } from '../../../lib/Constants';
import { DE as TextDE } from '../../../lib/Text';

const styles = (theme) => ({
  root: {
    width: '100%',
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  noPad: {
    padding: theme.spacing(0),
  },
  button: {
    float: 'right',
    height: '100%',
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

class AssignItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isFetching: true,
      hasError: false,
      cardAssignItemNew: false,
      procedureSerial: '',
      searchResults: [],
    };

    this.handleAssignItemAdd = this.handleAssignItemAdd.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  handleCardAssignItemClick = () => {
    this.setState({
      ...this.state,
      cardAssignItemExpand: true,
      cardAssignItemNew: true,
      procedureSerial: '',
      procedureId: '',
      procedures: [],
    });
  };

  handleAssignItemAdd = async () => {
    if (!!this.props.isCompetitive) return;
    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('procedureSerial', this.state.procedureSerial);
    if (!!this.state.procedureId) {
      formData.append('procedureId', this.state.procedureId);
    }
    formData.append('model', this.props.model);
    formData.append('uuid', this.props.uuid);

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/itemToProcedure',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.redirect) {
      this.props.callbackAssigned(response.redirect);
    } else {
      if (!!response.message) {
        this.setState({ hasError: response.message });
      } else {
        this.setState({ hasError: JSON.stringify(response.message) });
      }
    }
  };

  handleAssignItemChange = (event, value) => {
    if (!!value && typeof value === 'object') {
      if (!!value.id && !!value.serial) {
        this.setState({
          procedureSerial: value.rawSerial,
          procedureId: value.id,
        });
      } else {
        this.setState({ procedureSerial: value.rawSerial, procedureId: false });
      }
    }
  };

  async search() {
    if (this.state.keyword.length >= 3) {
      let url = process.env.REACT_APP_API_URL + '/api/search';
      let term = this.state.keyword.trim();
      if (term.indexOf(' ') > 0) {
        let atmp = term.split(' ').map((value) => 'search[]=' + encodeURIComponent(value));
        url = url + '?' + atmp.join('&');
      } else {
        url = url + '?search=' + encodeURIComponent(term);
      }

      let response = await fetch(url, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          return json;
        })
        .catch((error) => {
          return error;
        });

      if (!!response.data) {
        let list = [];
        Object.values(response.data).forEach((item) => {
          if (!!item.procedure) {
            list.push({
              rawSerial: item.procedure.serial,
              uuid: item.uuid,
              id: item.procedure.id,
              linkType: 'procedure',
              type: item.type,
              serial: '[ID#' + item.procedure.serial + ']',
              subject: item.procedure.data.subject,
              subtitle: item.procedure.data.subtitle,
              body: item.procedure.data.body,
            });
          }
        });

        this.setState({ searchResults: list });
      }
    }
  }

  handleSearch = (event, value) => {
    this.setState(
      {
        keyword: value,
      },
      this.search,
    );
  };

  render() {
    const filter = createFilterOptions();
    const { classes } = this.props;
    return (
      <Card raised={true}>
        <CardContent>
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={1}
          >
            <Grid item xs={2} md={1}>
              <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                <AssignmentReturnOutlinedIcon />
              </Avatar>
            </Grid>
            <Grid item xs={10} md={11}>
              <Typography variant='h5'>{TextDE.inbox.assign.title}</Typography>
              <Typography variant='subtitle1'>
                {!!this.props.isCompetitive
                  ? TextDE.inbox.assign.alerts.isCompetitive
                  : TextDE.inbox.assign.helper}
              </Typography>
            </Grid>

            {!!this.state.hasError && (
              <Grid item xs={12}>
                <Alert style={{ width: '100%' }} severity='error'>
                  {this.state.hasError}
                </Alert>
              </Grid>
            )}

            {!this.state.isSubmitting && (
              <Grid item xs={12} md={8}>
                <Autocomplete
                  freeSolo
                  style={{ width: '100%' }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        rawSerial: params.inputValue,
                        serial: params.inputValue,
                        subject: TextDE.inbox.assign.alerts.searchResponse(params.inputValue),
                        id: '',
                      });
                    }
                    return filtered;
                  }}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.serial + ' - ' + option.subject;
                  }}
                  options={this.state.searchResults}
                  autoComplete
                  filterSelectedOptions
                  loading={!(this.state.searchResults.length > 0)}
                  loadingText='Suchen...'
                  noOptionsText='Nichts gefunden'
                  value={this.state.linkItem}
                  onChange={this.handleAssignItemChange}
                  onInputChange={this.handleSearch}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size='small'
                      variant='outlined'
                      label={TextDE.inbox.assign.label}
                      name='newAssignItem'
                    />
                  )}
                  renderOption={(option) => {
                    const type = Constants.getInboxItemType(option.type);
                    return (
                      <>
                        <ListItemIcon>{type.icon}</ListItemIcon>
                        <ListItemText
                          primary={option.serial + ' - ' + option.subject}
                          secondary={option.subtitle || option.body}
                        />
                      </>
                    );
                  }}
                />
              </Grid>
            )}

            <Grid item xs={12} md={4}>
              {this.state.isSubmitting ? (
                <center>
                  <CircularProgress />
                </center>
              ) : (
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  className={classes.button}
                  startIcon={<SaveIcon />}
                  disabled={!(this.state.procedureSerial?.length > 0)}
                  onClick={this.handleAssignItemAdd}
                >
                  {TextDE.inbox.assign.moveToButton}
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AssignItemCard);
