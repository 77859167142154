import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from '@material-ui/lab/Alert';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';
import HttpIcon from '@material-ui/icons/Http';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import DescriptionIcon from '@material-ui/icons/Description';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Badge from '@material-ui/core/Badge';
import Checkbox from '@material-ui/core/Checkbox';
import Hidden from '@material-ui/core/Hidden';
import EditIcon from '@material-ui/icons/Edit';
import { Constants } from '../../lib/Constants';
import { DE as TextDE } from '../../lib/Text';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
    minHeight: '40vh',
    maxHeight: '99vh',
    overflow: 'auto',
    display: 'flex',
    flexFlow: 'column',
  },
  button: {
    padding: theme.spacing(1, 3),
  },
  mb0: {
    marginBottom: '0px',
    paddingBottom: '0px',
  },
  mb1: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      borderBottom: '1px solid ' + theme.palette.divider,
    },
    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
      borderBottom: '1px solid ' + theme.palette.divider,
    },
  },
  form: {
    flexGrow: '1',
    display: 'flex',
    flexFlow: 'column',
  },
  fullWidth: {
    width: '100%',
  },
  dialogTitle: {
    textAlign: 'center',
    margin: theme.spacing(0, -1, 2, -1),
    padding: 0,
  },
  floatRight: {
    float: 'right',
  },
  xs: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&>svg': {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
  bottomFixed: {
    marginTop: 'auto',
  },
});

function getSteps() {
  return [
    TextDE.contact.addContact.name,
    TextDE.contact.contactForm.email,
    TextDE.contact.addContact.phone,
    TextDE.contact.contactForm.adress,
    TextDE.contact.contactForm.notes,
  ];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return TextDE.contact.addContact.name;
    case 1:
      return TextDE.contact.contactForm.email;
    case 2:
      return TextDE.contact.addContact.phone;
    case 3:
      return TextDE.contact.contactForm.adress;
    case 4:
      return TextDE.contact.contactForm.notes;
    default:
      return 'Unbekannter Schritt';
  }
}

class AddContact extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({
      form: { ...this.state.form, isSubmitting: true, hasError: false },
    });
    let formData = {};

    formData['firstname'] = this.state.form.firstname;
    formData['name'] = this.state.form.name;
    formData['title_id'] = this.state.form.title_id;
    formData['title'] = this.state.form.title;
    formData['salutation_id'] = this.state.form.salutation_id;
    //formData['salutation'] = this.state.form.salutation;
    formData['tags'] = this.state.form.tags;
    formData['company'] = this.state.form.company;

    if (!this.state.skipped.has(1)) {
      formData['emails'] = this.state.form.emails;
    }
    if (!this.state.skipped.has(2)) {
      formData['phones'] = this.state.form.numbers;
    }
    if (!this.state.skipped.has(3)) {
      formData['address'] = this.state.form.address;
    }
    if (!this.state.skipped.has(4)) {
      formData['webpages'] = this.state.form.webpages;
      formData['notes'] = this.state.form.notes;
    }

    let endpoint = process.env.REACT_APP_API_URL;
    if (
      typeof this.state.form.uuid !== 'undefined' &&
      this.state.form.uuid !== null &&
      this.state.form.uuid.length > 16
    ) {
      formData['uuid'] = this.state.form.uuid;
      endpoint = endpoint + '/api/contacts/' + formData.uuid;
      formData['_method'] = 'PUT';
    } else {
      endpoint = endpoint + '/api/contacts';
      formData['_method'] = 'POST';
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });
    if (!!response.uuid) {
      this.setState({
        form: { ...this.state.form, isSubmitting: false, hasError: false },
      });
      this.props.handleclosefunction(response.uuid);
    } else {
      this.setState({
        form: { ...this.state.form, isSubmitting: false, hasError: response },
      });
    }
    //this.props.handleclosefunction();
  }
  handleNumbersChange = (event) => {
    if (event.target.type === 'checkbox') {
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: event.target.checked,
          },
        },
      });
    } else {
      let number = event.target.value;
      number = number.trim();
      number = number.replace(
        //eslint-disable-next-line
        /[^A-Za-z 0-9 \.,\?""!@#\$%\^&\*\(\)-_=\+;:<>\/\\\|\}\{\[\]`~]*/g,
        '',
      );
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: number,
            number_invalid:
              event.target.name === 'number'
                ? !!!number.match(
                    new RegExp(
                      //eslint-disable-next-line
                      /^(\(?([\d \-\)\–\+\/\(]?){2,}\)?([ .\-–\/]?)([\d]+))$/i,
                    ),
                  )
                : false,
          },
        },
      });
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, this.state);
    }
  };
  handleNumbersEdit = (number) => {
    let numbers = this.state.form.numbers;
    let toChange = null;
    for (var i = numbers.length - 1; i >= 0; i--) {
      if (numbers[i].number === number) {
        toChange = numbers[i];
        delete numbers[i];
      }
    }
    if (typeof toChange === 'object') {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          numbers: numbers,
          tmp: {
            ...this.state.form.tmp,
            number: toChange.number,
            number_id: toChange.id,
            number_pref: toChange.pref,
            number_cell: toChange.cell,
            number_fax: toChange.fax,
            number_work: toChange.work,
          },
        },
      });
    }
  };
  handleNumbersAdd = (event) => {
    //eslint-disable-next-line
    if (
      !!this.state.form.tmp.number &&
      !!this.state.form.tmp.number.match(
        //eslint-disable-next-line
        new RegExp(/^(\(?([\d \-\)\–\+\/\(]?){2,}\)?([ .\-–\/]?)([\d]+))$/i),
      )
    ) {
      let numbers = this.state.form.numbers;
      numbers.push({
        number: this.state.form.tmp.number,
        pref: this.state.form.tmp.number_pref,
        work: this.state.form.tmp.number_work,
        cell: this.state.form.tmp.number_cell,
        fax: this.state.form.tmp.number_fax,
        id: this.state.form.tmp.number_id,
      });
      this.setState({
        form: {
          ...this.state.form,
          numbers: numbers,
        },
      });

      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            number_invalid: false,
            number: '',
            number_pref: false,
            number_work: false,
            number_cell: false,
            number_fax: false,
            number_id: null,
          },
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, number_invalid: true },
        },
      });
    }
  };
  handleNumbersDelete = (number) => {
    let numbers = this.state.form.numbers;
    numbers = numbers.filter((item) => item.number !== number);
    this.setState({
      form: {
        ...this.state.form,
        numbers: numbers,
      },
    });
  };

  handleEmailsChange = (event) => {
    if (event.target.type === 'checkbox') {
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: event.target.checked,
          },
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: event.target.value,
            email_invalid:
              event.target.name === 'email'
                ? !!!event.target.value.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
                : false,
          },
        },
      });
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, this.state);
    }
  };
  handleEmailsAdd = (event) => {
    if (
      !!this.state.form.tmp.email &&
      !!this.state.form.tmp.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
    ) {
      let emails = this.state.form.emails;
      emails.push({
        email: this.state.form.tmp.email,
        pref: this.state.form.tmp.email_pref,
        work: this.state.form.tmp.email_work,
        id: this.state.form.tmp.email_id,
      });

      this.setState({
        form: {
          ...this.state.form,
          emails: emails,
          tmp: {
            ...this.state.form.tmp,
            email: '',
            email_pref: false,
            email_work: false,
            email_id: null,
            email_invalid: false,
          },
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, email_invalid: true },
        },
      });
    }
  };
  handleEmailsEdit = (email) => {
    let emails = this.state.form.emails;
    let toChange = null;
    for (var i = emails.length - 1; i >= 0; i--) {
      if (emails[i].email === email) {
        toChange = emails[i];
        delete emails[i];
      }
    }
    if (typeof toChange === 'object') {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          emails: emails,
          tmp: {
            ...this.state.form.tmp,
            email: toChange.email,
            email_id: toChange.id,
            email_pref: toChange.pref,
            email_work: toChange.work,
          },
        },
      });
    }
  };
  handleEmailsDelete = (email) => {
    let emails = this.state.form.emails;
    emails = emails.filter((item) => item.email !== email);
    this.setState({
      form: {
        ...this.state.form,
        emails: emails,
      },
    });
  };

  handleWebpagesChange = (event) => {
    this.setState({
      form: {
        ...this.state.form,
        tmp: {
          ...this.state.form.tmp,
          [event.target.name]: event.target.value,
          webpage_invalid: !!!event.target.value.match(
            /^(https?:\/\/)(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i,
          ),
        },
      },
    });
  };
  handleWebpagesEdit = (webpage) => {
    let webpages = this.state.form.webpages;
    let toChange = null;
    for (var i = webpages.length - 1; i >= 0; i--) {
      if (!!webpages[i] && !!webpages[i].webpage) {
        if (webpages[i].webpage === webpage) {
          toChange = webpages[i];
          delete webpages[i];
        }
      } else if (!!webpages[i] && webpages[i] === webpage) {
        toChange = webpages[i];
        delete webpages[i];
      }
    }
    if (typeof toChange === 'object') {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          webpages: webpages,
          tmp: {
            ...this.state.form.tmp,
            webpage: toChange.webpage,
          },
        },
      });
    } else if (typeof toChange === 'string') {
      this.setState({
        ...this.state,
        form: {
          ...this.state.form,
          webpages: webpages,
          tmp: {
            ...this.state.form.tmp,
            webpage: toChange,
          },
        },
      });
    }
  };
  handleWebpagesAdd = (event) => {
    if (
      !!this.state.form.tmp.webpage &&
      !!this.state.form.tmp.webpage.match(
        /^(https?:\/\/)?(www\.)?([a-zA-Z0-9]+(-?[a-zA-Z0-9])*\.)+[\w]{2,}(\/\S*)?$/i,
      )
    ) {
      let webpages = this.state.form.webpages;
      webpages.push({
        webpage: this.state.form.tmp.webpage,
      });
      this.setState({
        form: {
          ...this.state.form,
          webpages: webpages,
        },
      });

      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, webpage: '', webpage_invalid: false },
        },
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, webpage_invalid: true },
        },
      });
    }
  };

  handleWebpagesDelete = (webpage) => {
    let webpages = this.state.form.webpages;
    webpages = webpages.filter((item) => item.webpage !== webpage);
    this.setState({
      form: {
        ...this.state.form,
        webpages: webpages,
      },
    });
  };

  handleAddressChange = (event, value) => {
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, address_tags: value },
        },
      });
    } else if (typeof event === 'string') {
      this.setState({
        form: {
          ...this.state.form,
          tmp: { ...this.state.form.tmp, address: event },
        },
      });
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      this.setState({
        form: {
          ...this.state.form,
          tmp: {
            ...this.state.form.tmp,
            [event.target.name]: event.target.value,
          },
        },
      });
    }
    //if (typeof value === 'undefined' || typeof value.key === 'undefined') {
    //	this.setState({ form: { ...this.state.form, tmp: { ...this.state.form.tmp, address_tags: value}}})
    //} else {
    //	//console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
    //	this.setState({ form: { ...this.state.form, tmp: { ...this.state.form.tmp, [event.target.name]: event.target.value}}})
    //}
  };
  handleAddressAdd = (event) => {
    let addresses = this.state.form.addresses;
    addresses.push({
      address: this.state.form.tmp.address,
      addressObject: this.state.form.tmp.addressObject,
      tags: this.state.form.tmp.address_tags,
    });
    this.setState({
      form: {
        ...this.state.form,
        addresses: addresses,
      },
    });

    this.setState({
      form: {
        ...this.state.form,
        tmp: {
          ...this.state.form.tmp,
          address: '',
          addressObject: {},
          address_tags: [],
        },
      },
    });
  };
  handleAddressDelete = (address) => {
    let addresses = this.state.form.addresses;
    addresses = addresses.filter((address) => address.address !== address);
    this.setState({
      form: {
        ...this.state.form,
        addresses: addresses,
      },
    });
  };
  handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        return this.decodeAddress(results[0].address_components, results[0].formatted_address);
      })
      .then((newAddress) => {
        let error = '';
        error =
          error +
          (newAddress?.street_number.length > 0
            ? ''
            : TextDE.contact.addContact.errorMessage.noStreet);
        error =
          error +
          (newAddress?.route.length > 0
            ? ''
            : TextDE.contact.addContact.errorMessage.noHouseNumber);
        error =
          error +
          (newAddress?.locality.length > 0 ? '' : TextDE.contact.addContact.errorMessage.noCity);
        error =
          error +
          (newAddress?.administrative_area_level_1.length > 0
            ? ''
            : TextDE.contact.addContact.errorMessage.noCity);
        error =
          error +
          (newAddress?.country.length > 0 ? '' : TextDE.contact.addContact.errorMessage.noCountry);
        error =
          error +
          (newAddress?.postal_code.length > 0
            ? ''
            : TextDE.contact.addContact.errorMessage.noZipCode);

        this.setState({
          form: {
            ...this.state.form,
            tmp: {
              ...this.state.form.tmp,
              address_error: error,
              address_invalid: error.length > 0,
            },
          },
        });
      })
      .catch((error) => console.error('Error', error));
  };
  decodeAddress = (address_components, address) => {
    let componentForm = {
      street_number: 'short_name',
      route: 'long_name',
      locality: 'long_name',
      administrative_area_level_1: 'long_name',
      country: 'long_name',
      postal_code: 'short_name',
    };
    let newAddress = {
      street_number: '',
      route: '',
      locality: '',
      administrative_area_level_1: '',
      country: '',
      postal_code: '',
    };
    for (var i = 0; i < address_components.length; i++) {
      var addressType = address_components[i].types[0];
      if (componentForm[addressType]) {
        let val = address_components[i][componentForm[addressType]];
        //componentForm[addressType] = val;
        //console.log(addressType, val);
        //setCompanyState({...companyState, [addressType]: val});
        newAddress[addressType] = val;
      }
    }
    //this.setState({form: { ...this.state.form, 'address': address, addressObject: newAddress } });
    this.setState({
      form: {
        ...this.state.form,
        address: newAddress,
        tmp: {
          ...this.state.form.tmp,
          address: address,
          addressObject: newAddress,
        },
      },
    });
    return newAddress;
  };

  handleTagsChange = (event, value) => {
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      this.setState({ form: { ...this.state.form, tags: value } });
    }
  };

  selectCompany = (event, value) => {
    //console.log(`[SELECT COMPANY] triggered:, `, event, value);
    if (!!value) {
      if (!!value.id) {
        this.setState({
          form: {
            ...this.state.form,
            company: { id: value.id, uuid: value.uuid, name: value.name },
          },
        });
      } else if (typeof value === 'string') {
        this.setState({ form: { ...this.state.form, company: value } });
      } else if (value && value.inputValue) {
        // Create a new value from the user input
        this.setState({ form: { ...this.state.form, company: value.inputValue } });
      } else {
        this.setState({ form: { ...this.state.form, company: value } });
      }
    }
  };

  handleChange = (event) => {
    //console.log(`[HANDLE CHANGE] ${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`, this.state);
    if (event === null) {
      // Skippydiy
    } else if (event.target.type === 'checkbox') {
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.checked },
      });
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : checked: ${event.target.checked}`);
    } else {
      if (event.target.name === 'salutation_id' && event.target.value === 4) {
        this.setState({
          ...this.state,
          form: { ...this.state.form, [event.target.name]: event.target.value },
        });
      } else if (
        event.target.name === 'salutation_id' &&
        event.target.value < 4 &&
        event.target.value > 0
      ) {
        this.setState({
          ...this.state,
          form: { ...this.state.form, [event.target.name]: event.target.value },
        });
      } else {
        this.setState({
          form: { ...this.state.form, [event.target.name]: event.target.value },
        });
      }
    }
  };

  constructor(props) {
    super(props);
    const initialFormState = {
      isSubmitting: false,
      hasError: false,
      uuid: !!this.props.data?.uuid ? this.props.data?.uuid : null,
      firstname: !!this.props.data?.firstname ? this.props.data?.firstname : '',
      name: !!this.props.data?.name ? this.props.data?.name : '',
      title: !!this.props.data?.title ? this.props.data?.title?.name : '',
      salutation_id: !!this.props.data?.salutation_id ? this.props.data?.salutation_id : '',
      salutation: !!this.props.data?.salutation ? this.props.data?.salutation : '',
      company_id: !!this.props.data?.company_id ? this.props.data?.company_id : 0,
      company: !!this.props.data?.company ? this.props.data?.company?.name : '',
      notes: !!this.props.data?.notes ? this.props.data?.notes : '',
      address: {
        street_number: !!this.props.data?.address?.street_number
          ? this.props.data?.address?.street_number
          : '',
        route: !!this.props.data?.address?.route ? this.props.data?.address?.route : '',
        locality: !!this.props.data?.address?.city?.locality
          ? this.props.data?.address?.city?.locality
          : '',
        administrative_area_level_1: !!this.props.data?.address?.city?.state
          ? this.props.data?.address?.city?.state
          : '',
        country: !!this.props.data?.address?.city?.country
          ? this.props.data?.address?.city?.country
          : '',
        postal_code: !!this.props.data?.address?.postal_code
          ? this.props.data?.address?.postal_code
          : '',
      },
      webpages: !!this.props.data?.webpages ? this.props.data?.webpages : [],
      numbers: !!this.props.data?.phones ? this.props.data?.phones : [],
      emails: !!this.props.data?.emails ? this.props.data?.emails : [],
      tags: !!this.props.data?.tags
        ? this.props.data?.tags.map((tag, index) => {
            return tag.name.de;
          })
        : [],
      tmp: {
        webpage: '',
        number: '',
        number_pref: false,
        number_work: false,
        number_cell: false,
        number_fax: false,
        number_id: null,
        email: '',
        email_work: false,
        email_pref: false,
        email_id: null,
        email_invalid: false,
        address: !!this.props.data?.address
          ? (!!this.props.data?.address?.route ? this.props.data?.address?.route : '') +
            ' ' +
            (!!this.props.data?.address?.street_number
              ? this.props.data?.address?.street_number
              : '') +
            ' ' +
            (!!this.props.data?.address?.postal_code ? this.props.data?.address?.postal_code : '') +
            ' ' +
            (!!this.props.data?.address?.city?.locality
              ? this.props.data?.address?.city?.locality
              : '') +
            ' ' +
            (!!this.props.data?.address?.city?.country
              ? this.props.data?.address?.city?.country
              : '')
          : '',
        addressObject: {},
      },
    };

    let initialSalutationList = [];
    if (initialFormState.salutation_id === '') {
      initialSalutationList = [
        { id: '1', salutation: 'Herr' },
        { id: '2', salutation: 'Frau' },
        { id: '3', salutation: 'Divers' },
        { id: '4', salutation: 'Firma' },
      ];
    } else if (initialFormState.salutation_id !== '4') {
      initialSalutationList = [
        { id: '1', salutation: 'Herr' },
        { id: '2', salutation: 'Frau' },
        { id: '3', salutation: 'Divers' },
      ];
    } else {
      initialSalutationList = [{ id: '4', salutation: 'Firma' }];
    }
    const initialTitleList = {};

    this.state = {
      display: false,
      form: initialFormState,
      salutationList: initialSalutationList,
      titleList: initialTitleList,
      companys: !!this.props.companys ? this.props.companys : [],
      activeStep: 0,
      skipped: new Set(),
      contactsTags: Constants.tags.contacts.all(),
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.steps = getSteps();

    this.handleNumbersChange = this.handleNumbersChange.bind(this);
    this.handleNumbersEdit = this.handleNumbersEdit.bind(this);
    this.handleNumbersAdd = this.handleNumbersAdd.bind(this);
    this.handleNumbersDelete = this.handleNumbersDelete.bind(this);
    this.handleEmailsChange = this.handleEmailsChange.bind(this);
    this.handleEmailsEdit = this.handleEmailsEdit.bind(this);
    this.handleEmailsAdd = this.handleEmailsAdd.bind(this);
    this.handleEmailsDelete = this.handleEmailsDelete.bind(this);
    this.handleWebpagesChange = this.handleWebpagesChange.bind(this);
    this.handleWebpagesEdit = this.handleWebpagesEdit.bind(this);
    this.handleWebpagesAdd = this.handleWebpagesAdd.bind(this);
    this.handleWebpagesDelete = this.handleWebpagesDelete.bind(this);
    this.handleTagsChange = this.handleTagsChange.bind(this);
    this.selectCompany = this.selectCompany.bind(this);

    this.isStepOptional = this.isStepOptional.bind(this);
    this.isStepFailed = this.isStepFailed.bind(this);
    this.isStepSkipped = this.isStepSkipped.bind(this);

    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleSkip = this.handleSkip.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  isStepOptional = (step) => {
    return step >= 1;
  };

  isStepFailed = (step) => {
    let failed = false;
    if (!this.isStepSkipped(step)) {
      switch (step) {
        case 0:
          if (this.state.form.salutation_id === '4') {
            failed =
              this.state.form.name.length >= 1 && this.state.form.salutation_id === '4'
                ? false
                : true;
          } else {
            failed =
              //this.state.form.firstname.length >= 1 &&
              this.state.form.name.length >= 1 &&
              this.state.form.salutation_id !== '4' &&
              this.state.form.salutation_id > 0
                ? false
                : true;
          }

          break;
        case 1:
          failed = this.state.form.emails.length > 0 ? false : true;
          break;
        case 2:
          failed = this.state.form.numbers.length > 0 ? false : true;
          break;
        case 3:
          failed =
            this.state.form.address?.street_number.length > 0 &&
            this.state.form.address?.route.length > 0 &&
            this.state.form.address?.locality.length > 0 &&
            this.state.form.address?.administrative_area_level_1.length > 0 &&
            this.state.form.address?.country.length > 0 &&
            this.state.form.address?.postal_code.length > 0
              ? false
              : true;
          break;
        case 4:
          failed =
            this.state.form.notes.length > 0 || this.state.form.webpages.length > 0 ? false : true;
          break;
        default:
          break;
      }
    }
    return failed;
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  };

  handleNext(event) {
    event.preventDefault();
    if (!this.isStepFailed(this.state.activeStep)) {
      let newSkipped = this.state.skipped;
      if (this.isStepSkipped(this.state.activeStep)) {
        newSkipped = new Set(this.state.skipped.values());
        newSkipped.delete(this.state.activeStep);
      }
      this.setState({ activeStep: this.state.activeStep + 1 });
      this.setState({ skipped: newSkipped });
    }
  }

  handleBack(event) {
    event.preventDefault();
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  handleSkip(event) {
    event.preventDefault();
    if (!this.isStepOptional(this.state.activeStep)) {
      // You probably want to guard against something like this,
      throw new Error("You can't skip a step that isn't optional.");
    }

    const newSkipped = new Set(this.state.skipped.values());
    newSkipped.add(this.state.activeStep);
    this.setState({ skipped: newSkipped });
    this.setState({ activeStep: this.state.activeStep + 1 });
  }

  handleReset() {
    this.setState({ activeStep: 0 });
  }

  componentDidMount() {
    // Fetch something?
  }

  render() {
    const { classes } = this.props;

    const filter = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.name,
    });

    return (
      <>
        <Paper className={classes.root}>
          {this.state.isFetching ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <>
              <DialogTitle className={classes.dialogTitle}>
                {getStepContent(this.state.activeStep)}
                <IconButton
                  aria-label='close'
                  style={{ marginTop: '-1em', float: 'right', width: 0 }}
                  onClick={this.props.handleclosefunction}
                >
                  <CloseIcon />
                </IconButton>
                <Stepper
                  className={classes.mb0}
                  alternativeLabel
                  nonLinear
                  activeStep={this.state.activeStep}
                >
                  {this.steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (!this.isStepOptional(index) && this.isStepFailed(index)) {
                      labelProps.optional = (
                        <Typography variant='caption' color='error'>
                          Benötigt!
                        </Typography>
                      );
                    }
                    if (this.isStepFailed(index)) {
                      labelProps.error = true;
                    } else {
                      labelProps.error = false;
                    }
                    if (this.isStepSkipped(index)) {
                      stepProps.completed = false;
                    } else {
                      stepProps.completed = true;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>
                          <Hidden smDown>{label}</Hidden>
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </DialogTitle>

              <form key='contact' onSubmit={this.handleFormSubmit} className={classes.form}>
                {this.state.activeStep === 0 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Anrede</InputLabel>
                        <Select
                          name='salutation_id'
                          value={this.state.form.salutation_id}
                          onChange={this.handleChange}
                        >
                          {this.state.salutationList.map((value, key) => (
                            <MenuItem key={value.id} value={value.id}>
                              {value.salutation}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Autocomplete
                        freeSolo
                        name='title'
                        options={Constants.tags.contacts.titles}
                        getOptionLabel={(option) => option}
                        defaultValue={null}
                        value={this.state.form.title}
                        disabled={this.state.form.salutation_id === '4'}
                        onChange={this.handleChange}
                        onInputChange={this.handleChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name='title'
                            variant='standard'
                            label='Titel'
                            placeholder='Titel'
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id='firstname'
                        name='firstname'
                        label='Vorname'
                        type='text'
                        onChange={this.handleChange}
                        value={this.state.form.firstname}
                        disabled={this.state.form.salutation_id === '4'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        required
                        id='name'
                        name='name'
                        label={this.state.form.salutation_id === '4' ? 'Firmenname' : 'Nachname'}
                        type='text'
                        onChange={this.handleChange}
                        value={this.state.form.name}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Autocomplete
                        freeSolo
                        name='company'
                        options={this.props.companys}
                        filterOptions={(options, params) => {
                          let filtered = [];
                          if (!!options) {
                            filtered = filter(options, params);
                          }

                          // Suggest the creation of a new value
                          if (params.inputValue !== '') {
                            filtered.unshift(params.inputValue);
                          }
                          return filtered;
                        }}
                        renderOption={(option) => {
                          if (typeof option === 'string') {
                            return (
                              <Typography noWrap>"{option}" als neue Firma hinzufügen</Typography>
                            );
                          }
                          return <Typography noWrap>{option.name}</Typography>;
                        }}
                        getOptionLabel={(option) => {
                          // Value selected with enter, right from the input
                          if (typeof option === 'string') {
                            return option;
                          }
                          // Add "xxx" option created dynamically
                          if (option.inputValue) {
                            return option.inputValue;
                          }
                          // Regular option
                          return option.name ?? option;
                        }}
                        value={
                          this.state.form.salutation_id === '4'
                            ? this.state.form.name
                            : !!this.state.form.company?.name
                            ? this.state.form.company.name
                            : this.state.form.company
                        }
                        onChange={this.selectCompany}
                        disabled={this.state.form.salutation_id === '4'}
                        includeInputInList={true}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='standard'
                            label='Firma'
                            placeholder='Firmenname'
                            fullWidth
                            disabled={this.state.form.salutation_id === '4'}
                            name='company'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <Autocomplete
                        multiple
                        freeSolo
                        name='tags'
                        options={this.state.contactsTags}
                        getOptionLabel={(option) => option}
                        defaultValue={null}
                        value={this.state.form.tags}
                        onChange={this.handleTagsChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='standard'
                            label='Der Kontakt ist ...?'
                            placeholder='Gruppen'
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
                {this.state.activeStep === 1 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='span'>
                        {' '}
                        E-Mail hinzufügen:{' '}
                      </Typography>
                    </Grid>
                    {this.state.form.emails.length > 0 && (
                      <Grid item xs={12}>
                        <List dense={false}>
                          {this.state.form.emails.map((item, index) => {
                            let text = (
                              <>
                                <Typography variant='subtitle1'>{item.email}</Typography>
                              </>
                            );
                            return (
                              <ListItem key={item.email}>
                                <ListItemAvatar>
                                  <Badge
                                    color='primary'
                                    badgeContent={
                                      item.pref ? <StarIcon className={classes.xs} /> : 0
                                    }
                                    variant='standard'
                                    overlap='rectangular'
                                  >
                                    <Avatar variant='rounded' className={classes.small}>
                                      {item.work ? <WorkIcon /> : <AlternateEmailIcon />}
                                    </Avatar>
                                  </Badge>
                                </ListItemAvatar>
                                <ListItemText primary={text} />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={() => this.handleEmailsEdit(item.email)}
                                    edge='end'
                                    aria-label='delete'
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() => this.handleEmailsDelete(item.email)}
                                    edge='end'
                                    aria-label='delete'
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            );
                          })}
                        </List>
                      </Grid>
                    )}

                    <Grid item xs={12}>
                      <Grid container justifyContent='space-evenly' spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            id='status_id'
                            name='email'
                            label='E-Mail'
                            placeholder='max@muster.de'
                            type='email'
                            onChange={this.handleEmailsChange}
                            value={this.state.form.tmp.email}
                            helperText='Einen E-Mail Addresse dem neuen Kontakt zuweisen (*empfohlen!)'
                            error={this.state.form.tmp.email_invalid}
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.form.tmp.email_pref}
                                onChange={this.handleEmailsChange}
                                value='email_pref'
                                name='email_pref'
                              />
                            }
                            label='Favorit'
                          />
                        </Grid>
                        <Grid item>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={this.state.form.tmp.email_work}
                                onChange={this.handleEmailsChange}
                                value='email_work'
                                name='email_work'
                              />
                            }
                            label='Arbeit'
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} className={classes.mb1}>
                      <Button variant='contained' fullWidth onClick={this.handleEmailsAdd}>
                        <SaveIcon /> Hinzufügen
                      </Button>
                    </Grid>
                  </Grid>
                )}

                {this.state.activeStep === 2 && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h5' component='span'>
                          {' '}
                          Telefonnummern hinzufügen:{' '}
                        </Typography>
                      </Grid>
                      {this.state.form.numbers.length > 0 && (
                        <Grid item xs={12}>
                          <List dense={false}>
                            {this.state.form.numbers.map((item, index) => {
                              let text = (
                                <>
                                  <Typography variant='subtitle1'>{item.number}</Typography>
                                </>
                              );
                              return (
                                <ListItem key={item.number}>
                                  <ListItemAvatar>
                                    <Badge
                                      color='primary'
                                      badgeContent={
                                        item.pref ? <StarIcon className={classes.xs} /> : 0
                                      }
                                      variant='standard'
                                      overlap='rectangular'
                                    >
                                      <Avatar variant='rounded' className={classes.small}>
                                        {item.work ? <WorkIcon /> : null}
                                        {item.cell ? (
                                          <PhoneAndroidIcon />
                                        ) : item.fax ? (
                                          <DescriptionIcon />
                                        ) : (
                                          <PhoneIcon />
                                        )}
                                      </Avatar>
                                    </Badge>
                                  </ListItemAvatar>

                                  <ListItemText primary={text} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      onClick={() => this.handleNumbersEdit(item.number)}
                                      edge='end'
                                      aria-label='delete'
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => this.handleNumbersDelete(item.number)}
                                      edge='end'
                                      aria-label='delete'
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <Grid container justifyContent='space-evenly' spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              id='status_id'
                              name='number'
                              label='Nummer'
                              placeholder='0761 89750180'
                              type='tel'
                              onChange={this.handleNumbersChange}
                              value={this.state.form.tmp.number}
                              error={this.state.form.tmp.number_invalid}
                              helperText='Einen Nummer dem neuen Kontakt zuweisen (*empfohlen)'
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.tmp.number_pref}
                                  onChange={this.handleNumbersChange}
                                  value='number_pref'
                                  name='number_pref'
                                />
                              }
                              label='Favorit'
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.tmp.number_cell}
                                  onChange={this.handleNumbersChange}
                                  value='number_cell'
                                  name='number_cell'
                                />
                              }
                              label='Mobil'
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.tmp.number_work}
                                  onChange={this.handleNumbersChange}
                                  value='number_work'
                                  name='number_work'
                                />
                              }
                              label='Arbeit'
                            />
                          </Grid>
                          <Grid item>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={this.state.form.tmp.number_fax}
                                  onChange={this.handleNumbersChange}
                                  value='number_fax'
                                  name='number_fax'
                                />
                              }
                              label='Faxnummer'
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item xs={12} className={classes.mb1}>
                        <Button variant='contained' fullWidth onClick={this.handleNumbersAdd}>
                          <SaveIcon /> Hinzufügen
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

                {this.state.activeStep === 3 && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant='h5' component='span'>
                        {' '}
                        Adresse zuweisen:{' '}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <PlacesAutocomplete
                        value={this.state.form.tmp.address}
                        name='address'
                        onChange={this.handleAddressChange}
                        onSelect={this.handleAddressSelect}
                        searchOptions={{ types: ['address'] }}
                        shouldFetchSuggestions={this.state.form.tmp.address.length >= 4}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                          <div className={classes.container}>
                            <Input
                              error={this.state.form.tmp.address_invalid}
                              fullWidth
                              autoComplete='false'
                              {...getInputProps({
                                placeholder: 'z.B. Am Lusbühl 16, Freiburg...',
                              })}
                            />
                            {suggestions.length > 0 ? (
                              <Card className={classes.results}>
                                {suggestions.map((suggestion, index) => {
                                  return (
                                    <CardContent
                                      {...getSuggestionItemProps(suggestion, {})}
                                      key={index}
                                      className={classes.result}
                                    >
                                      <Grid container alignItems='center'>
                                        <Grid item>
                                          <LocationOnIcon className={classes.icon} />
                                        </Grid>
                                        <Grid item xs>
                                          <Typography variant='subtitle1'>
                                            {suggestion.formattedSuggestion.mainText}
                                          </Typography>
                                          <Typography variant='body1' color='textSecondary'>
                                            {suggestion.formattedSuggestion.secondaryText}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  );
                                })}
                              </Card>
                            ) : null}
                          </div>
                        )}
                      </PlacesAutocomplete>
                      {this.state.form.tmp.address_invalid && (
                        <Typography color='secondary' variant='subtitle1' component='pre'>
                          {this.state.form.tmp.address_error}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}

                {this.state.activeStep === 4 && (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id='outlined-multiline-static'
                          name='notes'
                          label='Optionale Notiz'
                          multiline
                          minRows='4'
                          onChange={this.handleChange}
                          value={this.state.form.notes}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h5' component='span'>
                          {' '}
                          Webseiten hinzufügen:{' '}
                        </Typography>
                      </Grid>

                      {this.state.form.webpages.length > 0 && (
                        <Grid item xs={12}>
                          <List dense={false}>
                            {Object.keys(this.state.form.webpages).map((item, key) => (
                              <ListItem key={key}>
                                <ListItemAvatar>
                                  <Avatar variant='rounded' className={classes.small}>
                                    <HttpIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                  style={{ fontSize: '146%' }}
                                  primary={
                                    this.state.form.webpages[item]?.webpage ||
                                    this.state.form.webpages[item]
                                  }
                                />
                                <ListItemSecondaryAction>
                                  <IconButton
                                    onClick={() =>
                                      this.handleWebpagesEdit(
                                        this.state.form.webpages[item]?.webpage ||
                                          this.state.form.webpages[item],
                                      )
                                    }
                                    edge='end'
                                    aria-label='delete'
                                  >
                                    <EditIcon />
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      this.handleWebpagesDelete(
                                        this.state.form.webpages[item]?.webpage ||
                                          this.state.form.webpages[item],
                                      )
                                    }
                                    edge='end'
                                    aria-label='delete'
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        </Grid>
                      )}

                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          id='status_id'
                          name='webpage'
                          label='Webseite'
                          placeholder='https://flixworker.de'
                          type='url'
                          onChange={this.handleWebpagesChange}
                          value={this.state.form.tmp.webpage}
                          error={this.state.form.tmp.webpage_invalid}
                          helperText={
                            this.state.form.tmp.webpage_invalid
                              ? "Das Format ist falsch! Bitte 'https://www.beispiel.de' einhalten!"
                              : 'Eine Webseite dem neuen Kontakt zuweisen'
                          }
                        />
                      </Grid>

                      <Grid item xs={12} className={classes.mb1}>
                        <Button variant='contained' fullWidth onClick={this.handleWebpagesAdd}>
                          <SaveIcon /> Hinzufügen
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}

                {this.state.activeStep === this.steps.length - 1 ? (
                  <Grid container className={classes.bottomFixed} spacing={2}>
                    <Grid item xs={4}>
                      <div className={classes.wrapper}>
                        <Button
                          fullWidth
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                          variant='contained'
                        >
                          Zurück
                        </Button>
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <div className={classes.wrapper}>
                        {this.state.form.isSubmitting ? (
                          <center>
                            <CircularProgress size={24} className={classes.buttonProgress} />{' '}
                          </center>
                        ) : (
                          <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            className={classes.submit}
                            disabled={this.state.isSubmitting || !!this.state.hasError}
                            fullWidth
                          >
                            Speichern
                          </Button>
                        )}
                      </div>
                    </Grid>
                    {!!this.state.form.hasError && (
                      <Alert severity='error' style={{ width: '100%' }}>
                        <Typography component='pre'>
                          {' '}
                          {JSON.stringify(this.state.form.hasError, undefined, 4)}{' '}
                        </Typography>
                      </Alert>
                    )}
                  </Grid>
                ) : (
                  <Grid container className={classes.bottomFixed} spacing={2}>
                    <Grid item xs>
                      <div className={classes.wrapper}>
                        <Button
                          fullWidth
                          disabled={this.state.activeStep === 0}
                          onClick={this.handleBack}
                          className={classes.button}
                          variant='contained'
                        >
                          Zurück
                        </Button>
                      </div>
                    </Grid>
                    {this.isStepOptional(this.state.activeStep) && (
                      <Grid item xs>
                        <div className={classes.wrapper}>
                          <Button
                            fullWidth
                            onClick={this.handleSkip}
                            className={classes.button}
                            color='secondary'
                            variant='outlined'
                          >
                            Überspringen
                          </Button>
                        </div>
                      </Grid>
                    )}
                    <Grid item xs>
                      <div className={classes.wrapper}>
                        <Button
                          fullWidth
                          onClick={this.handleNext}
                          className={classes.button}
                          color='primary'
                          variant='contained'
                          disabled={!!this.isStepFailed(this.state.activeStep)}
                        >
                          Weiter
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                )}
              </form>
            </>
          )}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state, ownProps) => ({
  User: state.User,
  Authentication: state.Authentication,
  data: ownProps.data,
  companys: ownProps.companys,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(AddContact);
