import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import PriorityChip from './components/PriorityChip';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import Users from './Users';

import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import Hidden from '@material-ui/core/Hidden';
import { readable } from '../../../../lib/dateFunctions';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import FiberNewIcon from '@material-ui/icons/FiberNew'; // new
import SnoozeTwoToneIcon from '@material-ui/icons/SnoozeTwoTone'; // wait
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'; //done
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'; //work
import { DE as TextDE } from '../../../../lib/Text';

const componentOf = {
  done: <AssignmentTurnedInIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  working: <DirectionsRunIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  wait: <SnoozeTwoToneIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  new: <FiberNewIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
};

const styles = (theme) => ({
  fab: {
    zIndex: 999,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  fixSize: {
    maxWidth: '100%',
    //maxHeight: '100%',
    overflow: 'auto',
    flexFlow: 'row',
    flexWrap: 'wrap',
    margin: 0,
    //[theme.breakpoints.up('md')]: {
    //	maxHeight: '40vh',
    //}
  },
  newTile: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    cursor: 'pointer',
  },
  maxSized: {
    overflow: 'clip',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  chip: {
    width: 'max-content',
    padding: theme.spacing(1.5, 1),
    marginTop: '4px',
    justifyContent: 'center',
    display: 'flex',
  },
});

class PerformanceReportCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { classes } = this.props;
    return (
      <Card
        elevation={4}
        style={{
          marginBottom: '8px',
          border: '1px solid ' + this.props.job.status.style.backgroundColor,
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item xs={7}>
              <Typography
                align='right'
                color='textSecondary'
                variant='caption'
                gutterBottom
                component='div'
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Tooltip title={TextDE.workorder.idTooltip} aria-label='add'>
                  <Chip
                    label={
                      !!this.props.job.serial
                        ? 'ID#' + this.props.procedureSerial + '/' + this.props.job.serial
                        : 'N/A'
                    }
                    color='primary'
                    size='small'
                    className={classes.chip}
                    style={{ fontWeight: 'bold', marginRight: 'auto' }}
                  />
                </Tooltip>
              </Typography>

              <Typography color='initial' component='h6' variant='body1' noWrap>
                {this.props.job.data?.subject}
              </Typography>
              <Typography color='initial' component='h6' variant='body2' noWrap gutterBottom>
                {this.props.job.data?.subtitle ?? this.props.job.data?.body}
              </Typography>
              <Typography
                align='left'
                color='initial'
                component='h6'
                variant='caption'
                noWrap
                gutterBottom
              >
                <UpdateIcon fontSize='inherit' /> {TextDE.lastChange}&nbsp;
                {readable(this.props.job.updated_at)}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Typography
                align='right'
                color='textSecondary'
                variant='caption'
                gutterBottom
                component='div'
                style={{ display: 'flex', justifyContent: 'end' }}
              >
                <Tooltip title={this.props.job.status.description} aria-label='add'>
                  <Chip
                    label={this.props.job.status.name}
                    size='small'
                    className={classes.chip}
                    icon={componentOf[this.props.job.status.icon]}
                    style={{
                      color: this.props.job.status.style.color,
                      backgroundColor: this.props.job.status.style.backgroundColor,
                      marginRight: '8px',
                    }}
                  />
                </Tooltip>

                <PriorityChip
                  model='jobs'
                  status={this.props.job.priority}
                  statesAll={this.props.statesAll}
                  uuid={this.props.job.uuid}
                />
              </Typography>

              <Users
                callback={this.props.callbackUserUpdate}
                users={this.props.job.users}
                usersAll={this.props.usersAll}
                jobUuid={this.props.job.uuid}
                canInviteUsers={this.props.canInviteUsers}
                disableEdit={this.props.job.status.name.toLowerCase() === 'erledigt'}
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardActions>
          <Hidden mdDown>
            {this.props.job.status.name.toLowerCase() === 'erledigt' ? (
              <Button
                variant='contained'
                color='primary'
                startIcon={<PictureAsPdfIcon color='inherit' />}
                onClick={() =>
                  this.props.getPDF(
                    this.props.job.uuid,
                    this.props.job.performancereports[0].uuid,
                    this.props.job.performancereports[0].data.serial,
                  )
                }
              >
                Download
              </Button>
            ) : (
              <Button
                variant='contained'
                color='primary'
                startIcon={<PrintIcon color='inherit' />}
                onClick={() => this.props.previewPDF(this.props.job)}
              >
                {TextDE.workorder.previewButton}
              </Button>
            )}
            {this.props.job.status.name.toLowerCase() !== 'erledigt' && (
              <Button
                variant='outlined'
                color='secondary'
                style={{ marginLeft: 'auto' }}
                onClick={() => this.props.showDeleteDialog(this.props.job)}
                startIcon={<DeleteIcon />}
              >
                {TextDE.delete}
              </Button>
            )}
            {this.props.job.status.name.toLowerCase() !== 'erledigt' && (
              <Button
                variant='outlined'
                color='secondary'
                onClick={() => this.props.editPDF(this.props.job)}
                startIcon={<EditIcon />}
              >
                {TextDE.edit}
              </Button>
            )}
          </Hidden>
          <Hidden lgUp>
            {this.props.job.status.name.toLowerCase() === 'erledigt' ? (
              <IconButton
                color='primary'
                onClick={() =>
                  this.props.getPDF(
                    this.props.job.uuid,
                    this.props.job.performancereports[0].uuid,
                    this.props.job.performancereports[0].data.serial,
                  )
                }
              >
                <PictureAsPdfIcon />
              </IconButton>
            ) : (
              <IconButton color='primary' onClick={() => this.props.previewPDF(this.props.job)}>
                <PrintIcon />
              </IconButton>
            )}
            {this.props.job.status.name.toLowerCase() !== 'erledigt' && (
              <IconButton
                color='secondary'
                style={{ marginLeft: 'auto' }}
                onClick={() => this.props.showDeleteDialog(this.props.job)}
              >
                <DeleteIcon />
              </IconButton>
            )}
            {this.props.job.status.name.toLowerCase() !== 'erledigt' && (
              <IconButton color='secondary' onClick={() => this.props.editPDF(this.props.job)}>
                <EditIcon />
              </IconButton>
            )}
          </Hidden>
        </CardActions>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Theme: state.Style.Theme,
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PerformanceReportCard);
