import React from 'react';
import ComputerIcon from '@material-ui/icons/Computer';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard';
import ChatIcon from '@material-ui/icons/Chat';
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import NotesIcon from '@material-ui/icons/Notes';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import WorkIcon from '@material-ui/icons/Work';
import AnnouncementIcon from '@material-ui/icons/Announcement';

import BuildIcon from '@material-ui/icons/Build';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import AddIcCallIcon from '@material-ui/icons/AddIcCall';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';
import AlarmAddIcon from '@material-ui/icons/AlarmAdd';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import BusinessIcon from '@material-ui/icons/Business';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CallIcon from '@material-ui/icons/Call';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import CardTravelIcon from '@material-ui/icons/CardTravel';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import FavoriteIcon from '@material-ui/icons/Favorite';
import HistoryIcon from '@material-ui/icons/History';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import RateReviewIcon from '@material-ui/icons/RateReview';
import RestoreIcon from '@material-ui/icons/Restore';
import TimerIcon from '@material-ui/icons/Timer';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import TelegramIcon from '@material-ui/icons/Telegram';
import TrafficIcon from '@material-ui/icons/Traffic';

function unique(data) {
  return data.filter(function (value, index, data) {
    return data.indexOf(value) === index;
  });
}

export const Constants = {
  eventMap: {
    nachrichten: ['NewInboxItemEvent', 'NewProcedureItemEvent', 'NewProcedureEvent'],
  },
  inboxItemTypes: [
    {
      id: 1,
      type: 'app',
      icon: <ComputerIcon fontSize='inherit' color='inherit' />,
      iconName: 'ComputerIcon',
      description: 'Systemnachricht',
      canBeSelected: false,
    },
    {
      id: 2,
      type: 'phone',
      icon: <PhoneIcon fontSize='inherit' color='inherit' />,
      iconName: 'PhoneIcon',
      description: 'Telefonnotiz',
      canBeSelected: true,
    },
    {
      id: 3,
      type: 'email',
      icon: <MailIcon fontSize='inherit' color='inherit' />,
      iconName: 'MailIcon',
      description: 'Ein-/Ausgehende E-Mail',
      canBeSelected: true,
    },
    {
      id: 4,
      type: 'sensors',
      icon: <DeveloperBoardIcon fontSize='inherit' color='inherit' />,
      iconName: 'DeveloperBoardIcon',
      description: 'Sensoren Meldung',
      canBeSelected: false,
    },
    {
      id: 5,
      type: 'chatbot',
      icon: <ChatIcon fontSize='inherit' color='inherit' />,
      iconName: 'ChatIcon',
      description: 'Chatbot',
      canBeSelected: false,
    },
    {
      id: 6,
      type: 'hook',
      icon: <SubdirectoryArrowLeftIcon fontSize='inherit' color='inherit' />,
      iconName: 'SubdirectoryArrowLeftIcon',
      description: 'hook',
      canBeSelected: false,
    },
    {
      id: 7,
      type: 'note',
      icon: <NotesIcon fontSize='inherit' color='inherit' />,
      iconName: 'NotesIcon',
      description: 'Notiz',
      canBeSelected: true,
    },
    {
      id: 8,
      type: 'new_procedure',
      icon: <DoubleArrowIcon fontSize='inherit' color='inherit' />,
      iconName: 'DoubleArrowIcon',
      description: 'Vorgang angelegt',
      canBeSelected: false,
    },
    {
      id: 9,
      type: 'new_job',
      icon: <WorkIcon fontSize='inherit' color='inherit' />,
      iconName: 'WorkIcon',
      description: 'Arbeitsauftrag angelegt',
      canBeSelected: false,
    },
    {
      id: 10,
      type: 'job_issue',
      icon: <AnnouncementIcon fontSize='inherit' color='inherit' />,
      iconName: 'AnnouncementIcon',
      description: 'Problem im Arbeitsauftrag',
      canBeSelected: false,
    },
    {
      id: 11,
      type: 'appointment',
      icon: <CalendarTodayIcon fontSize='inherit' color='inherit' />,
      iconName: 'CalendarTodayIcon',
      description: 'Termin',
      canBeSelected: true,
    },
    {
      id: 99,
      type: 'other',
      icon: <DeviceUnknownIcon fontSize='inherit' color='inherit' />,
      iconName: 'DeviceUnknownIcon',
      description: 'Andere Kategorie',
      canBeSelected: true,
    },
    {
      id: 201,
      type: 'BuildIcon',
      icon: <BuildIcon fontSize='inherit' color='inherit' />,
      iconName: 'BuildIcon',
      description: 'BuildIcon',
      canBeSelected: false,
    },
    {
      id: 202,
      type: 'AccessTimeIcon',
      icon: <AccessTimeIcon fontSize='inherit' color='inherit' />,
      iconName: 'AccessTimeIcon',
      description: 'AccessTimeIcon',
      canBeSelected: false,
    },
    {
      id: 203,
      type: 'AccountBalanceIcon',
      icon: <AccountBalanceIcon fontSize='inherit' color='inherit' />,
      iconName: 'AccountBalanceIcon',
      description: 'AccountBalanceIcon',
      canBeSelected: false,
    },
    {
      id: 204,
      type: 'AddAlarmIcon',
      icon: <AddAlarmIcon fontSize='inherit' color='inherit' />,
      iconName: 'AddAlarmIcon',
      description: 'AddAlarmIcon',
      canBeSelected: false,
    },
    {
      id: 205,
      type: 'AddIcCallIcon',
      icon: <AddIcCallIcon fontSize='inherit' color='inherit' />,
      iconName: 'AddIcCallIcon',
      description: 'AddIcCallIcon',
      canBeSelected: false,
    },
    {
      id: 206,
      type: 'AirportShuttleIcon',
      icon: <AirportShuttleIcon fontSize='inherit' color='inherit' />,
      iconName: 'AirportShuttleIcon',
      description: 'AirportShuttleIcon',
      canBeSelected: false,
    },
    {
      id: 207,
      type: 'AlarmOffIcon',
      icon: <AlarmOffIcon fontSize='inherit' color='inherit' />,
      iconName: 'AlarmOffIcon',
      description: 'AlarmOffIcon',
      canBeSelected: false,
    },
    {
      id: 208,
      type: 'AlarmAddIcon',
      icon: <AlarmAddIcon fontSize='inherit' color='inherit' />,
      iconName: 'AlarmAddIcon',
      description: 'AlarmAddIcon',
      canBeSelected: false,
    },
    {
      id: 209,
      type: 'AlarmOnIcon',
      icon: <AlarmOnIcon fontSize='inherit' color='inherit' />,
      iconName: 'AlarmOnIcon',
      description: 'AlarmOnIcon',
      canBeSelected: false,
    },
    {
      id: 210,
      type: 'AlternateEmailIcon',
      icon: <AlternateEmailIcon fontSize='inherit' color='inherit' />,
      iconName: 'AlternateEmailIcon',
      description: 'AlternateEmailIcon',
      canBeSelected: false,
    },
    {
      id: 211,
      type: 'AssignmentTurnedInIcon',
      icon: <AssignmentTurnedInIcon fontSize='inherit' color='inherit' />,
      iconName: 'AssignmentTurnedInIcon',
      description: 'AssignmentTurnedInIcon',
      canBeSelected: false,
    },
    {
      id: 212,
      type: 'AttachFileIcon',
      icon: <AttachFileIcon fontSize='inherit' color='inherit' />,
      iconName: 'AttachFileIcon',
      description: 'AttachFileIcon',
      canBeSelected: false,
    },
    {
      id: 213,
      type: 'AutorenewIcon',
      icon: <AutorenewIcon fontSize='inherit' color='inherit' />,
      iconName: 'AutorenewIcon',
      description: 'AutorenewIcon',
      canBeSelected: false,
    },
    {
      id: 214,
      type: 'AvTimerIcon',
      icon: <AvTimerIcon fontSize='inherit' color='inherit' />,
      iconName: 'AvTimerIcon',
      description: 'AvTimerIcon',
      canBeSelected: false,
    },
    {
      id: 215,
      type: 'BeenhereIcon',
      icon: <BeenhereIcon fontSize='inherit' color='inherit' />,
      iconName: 'BeenhereIcon',
      description: 'BeenhereIcon',
      canBeSelected: false,
    },
    {
      id: 216,
      type: 'BorderColorIcon',
      icon: <BorderColorIcon fontSize='inherit' color='inherit' />,
      iconName: 'BorderColorIcon',
      description: 'BorderColorIcon',
      canBeSelected: false,
    },
    {
      id: 217,
      type: 'BusinessIcon',
      icon: <BusinessIcon fontSize='inherit' color='inherit' />,
      iconName: 'BusinessIcon',
      description: 'BusinessIcon',
      canBeSelected: false,
    },
    {
      id: 218,
      type: 'CalendarTodayIcon',
      icon: <CalendarTodayIcon fontSize='inherit' color='inherit' />,
      iconName: 'CalendarTodayIcon',
      description: 'CalendarTodayIcon',
      canBeSelected: false,
    },
    {
      id: 219,
      type: 'CallIcon',
      icon: <CallIcon fontSize='inherit' color='inherit' />,
      iconName: 'CallIcon',
      description: 'CallIcon',
      canBeSelected: false,
    },
    {
      id: 220,
      type: 'CallSplitIcon',
      icon: <CallSplitIcon fontSize='inherit' color='inherit' />,
      iconName: 'CallSplitIcon',
      description: 'CallSplitIcon',
      canBeSelected: false,
    },
    {
      id: 221,
      type: 'CancelPresentationIcon',
      icon: <CancelPresentationIcon fontSize='inherit' color='inherit' />,
      iconName: 'CancelPresentationIcon',
      description: 'CancelPresentationIcon',
      canBeSelected: false,
    },
    {
      id: 222,
      type: 'CardTravelIcon',
      icon: <CardTravelIcon fontSize='inherit' color='inherit' />,
      iconName: 'CardTravelIcon',
      description: 'CardTravelIcon',
      canBeSelected: false,
    },
    {
      id: 223,
      type: 'CheckIcon',
      icon: <CheckIcon fontSize='inherit' color='inherit' />,
      iconName: 'CheckIcon',
      description: 'CheckIcon',
      canBeSelected: false,
    },
    {
      id: 224,
      type: 'CheckBoxIcon',
      icon: <CheckBoxIcon fontSize='inherit' color='inherit' />,
      iconName: 'CheckBoxIcon',
      description: 'CheckBoxIcon',
      canBeSelected: false,
    },
    {
      id: 225,
      type: 'ClearIcon',
      icon: <ClearIcon fontSize='inherit' color='inherit' />,
      iconName: 'ClearIcon',
      description: 'ClearIcon',
      canBeSelected: false,
    },
    {
      id: 226,
      type: 'CompareArrowsIcon',
      icon: <CompareArrowsIcon fontSize='inherit' color='inherit' />,
      iconName: 'CompareArrowsIcon',
      description: 'CompareArrowsIcon',
      canBeSelected: false,
    },
    {
      id: 227,
      type: 'CreateIcon',
      icon: <CreateIcon fontSize='inherit' color='inherit' />,
      iconName: 'CreateIcon',
      description: 'CreateIcon',
      canBeSelected: false,
    },
    {
      id: 228,
      type: 'DoneIcon',
      icon: <DoneIcon fontSize='inherit' color='inherit' />,
      iconName: 'DoneIcon',
      description: 'DoneIcon',
      canBeSelected: false,
    },
    {
      id: 229,
      type: 'DoneAllIcon',
      icon: <DoneAllIcon fontSize='inherit' color='inherit' />,
      iconName: 'DoneAllIcon',
      description: 'DoneAllIcon',
      canBeSelected: false,
    },
    {
      id: 230,
      type: 'FavoriteIcon',
      icon: <FavoriteIcon fontSize='inherit' color='inherit' />,
      iconName: 'FavoriteIcon',
      description: 'FavoriteIcon',
      canBeSelected: false,
    },
    {
      id: 231,
      type: 'HistoryIcon',
      icon: <HistoryIcon fontSize='inherit' color='inherit' />,
      iconName: 'HistoryIcon',
      description: 'HistoryIcon',
      canBeSelected: false,
    },
    {
      id: 232,
      type: 'LocalGroceryStoreIcon',
      icon: <LocalGroceryStoreIcon fontSize='inherit' color='inherit' />,
      iconName: 'LocalGroceryStoreIcon',
      description: 'LocalGroceryStoreIcon',
      canBeSelected: false,
    },
    {
      id: 233,
      type: 'MailOutlineIcon',
      icon: <MailOutlineIcon fontSize='inherit' color='inherit' />,
      iconName: 'MailOutlineIcon',
      description: 'MailOutlineIcon',
      canBeSelected: false,
    },
    {
      id: 234,
      type: 'NotificationsIcon',
      icon: <NotificationsIcon fontSize='inherit' color='inherit' />,
      iconName: 'NotificationsIcon',
      description: 'NotificationsIcon',
      canBeSelected: false,
    },
    {
      id: 235,
      type: 'PersonPinIcon',
      icon: <PersonPinIcon fontSize='inherit' color='inherit' />,
      iconName: 'PersonPinIcon',
      description: 'PersonPinIcon',
      canBeSelected: false,
    },
    {
      id: 236,
      type: 'RateReviewIcon',
      icon: <RateReviewIcon fontSize='inherit' color='inherit' />,
      iconName: 'RateReviewIcon',
      description: 'RateReviewIcon',
      canBeSelected: false,
    },
    {
      id: 237,
      type: 'RestoreIcon',
      icon: <RestoreIcon fontSize='inherit' color='inherit' />,
      iconName: 'RestoreIcon',
      description: 'RestoreIcon',
      canBeSelected: false,
    },
    {
      id: 238,
      type: 'TimerIcon',
      icon: <TimerIcon fontSize='inherit' color='inherit' />,
      iconName: 'TimerIcon',
      description: 'TimerIcon',
      canBeSelected: false,
    },
    {
      id: 239,
      type: 'WorkOutlineIcon',
      icon: <WorkOutlineIcon fontSize='inherit' color='inherit' />,
      iconName: 'WorkOutlineIcon',
      description: 'WorkOutlineIcon',
      canBeSelected: false,
    },
    {
      id: 240,
      type: 'TelegramIcon',
      icon: <TelegramIcon fontSize='inherit' color='inherit' />,
      iconName: 'TelegramIcon',
      description: 'Schnellnotiz',
      canBeSelected: false,
    },
    {
      id: 241,
      type: 'TrafficIcon',
      icon: <TrafficIcon fontSize='inherit' color='inherit' />,
      iconName: 'TrafficIcon',
      description: 'TrafficIcon',
      canBeSelected: false,
    },
  ],
  iconMap: function () {
    let types = {};
    this.inboxItemTypes.forEach((value) => {
      types[value.iconName] = { value: value.type, icon: value.icon };
    });
    return types;
  },
  getInboxItemType: function (find) {
    let index = false;
    if (Number.isInteger(parseInt(find))) {
      index = this.inboxItemTypes.findIndex((element) => element.id === parseInt(find));
    } else {
      index = this.inboxItemTypes.findIndex((element) => element.type === find);
    }
    index = index >= 0 ? index : 0;
    return !!index ? this.inboxItemTypes[index] : this.inboxItemTypes[0];
  },
  getInboxItemTypeId: function (find) {
    let index = false;
    if (Number.isInteger(parseInt(find))) {
      index = this.inboxItemTypes.findIndex((element) => element.id === parseInt(find));
    } else {
      index = this.inboxItemTypes.findIndex((element) => element.type === find);
    }
    return !!index ? this.inboxItemTypes[index].id : this.inboxItemTypes[0].id;
  },
  getInboxItemTypesAsObject: function (filter = false) {
    let types = {};

    if (!!filter && filter.toLowerCase() === 'inbox') {
      this.inboxItemTypes.forEach((value) => {
        if (value.id < 100) {
          types = { ...types, [value.type]: value };
        }
        return types;
      });
    }

    this.inboxItemTypes.forEach((value) => {
      types = { ...types, [value.type]: value };
    });
    return types;
  },

  checkUserRolesAndPermissions: function (user, role, permission) {
    if (!!user.roles) {
      if (this.roles.check(user, role) === false) {
        return this.permissions.check(user, permission);
      } else {
        return true;
      }
    }
    return false;
  },
  roles: {
    ROLES_NEWLY_REGISTERED: 'Frisch Registriert!',
    ROLES_ADMIN: 'Administrator/in',
    ROLES_ORGANIZER: 'Organisator/in',
    ROLES_WORKER: 'Arbeiter/in',
    ROLES_CUSTOMER: 'Kunde/Kundin',
    ROLES_FINANCIAL: 'Prokura',
    ROLES_PHONE: 'Telefonist/in',
    ROLES_FLIXWORKER_SERVICE: 'FLIX-Service',
    ROLES_FLIXWORKER_ADMIN: 'FLIX-Admin',
    check: function (user, role) {
      if (!!user.roles) {
        let result = null;
        if (typeof role === 'string') {
          result = user.roles.filter((value) => role === value.name);
        } else if (role.length > 0) {
          result = user.roles.filter((value) => role.includes(value.name));
        } else {
          result = [];
        }
        return !!(result.length > 0);
      }
      return false;
    },
  },
  tags: {
    contacts: {
      titles: ['Dr.', 'Prof. Dr.'],
      resident: ['Leistungsempfänger', 'Bewohner', 'Eigentümer'],
      residentHidden: ['Mieter', 'Ansprechpartner', 'Vor Ort Kontakt'],
      invoice: ['Rechnungsempfänger', 'Verwalter'],
      invoiceHidden: ['Eigentümer', 'Besitzer'],
      other: ['Lieferant'],
      otherHidden: ['Verwalter'],
      creator: ['Ersteller'],
      all: function () {
        return unique([...this.resident, ...this.invoice, ...this.other]);
      },
      allHidden: function () {
        return unique([
          ...this.resident,
          ...this.residentHidden,
          ...this.invoice,
          ...this.invoiceHidden,
          ...this.other,
          ...this.otherHidden,
        ]);
      },
    },
    users: {
      administration: ['Organisator', 'Besitzer'],
      administrationHidden: ['Organisator/in'],
      execute: ['Arbeiter', 'Helfer'],
      executeHidden: ['Arbeiter/in', 'Ausführend'],
      controlling: ['Prokura'],
      controllingHidden: ['Begleiter'],
      all: function () {
        return unique([...this.administration, ...this.execute, ...this.controlling]);
      },
      allHidden: function () {
        return unique([
          ...this.administration,
          ...this.administrationHidden,
          ...this.execute,
          ...this.executeHidden,
          ...this.controlling,
          ...this.controllingHidden,
        ]);
      },
    },
    addresses: {
      resident: ['Leistungsort'],
      residentHidden: ['Zielort', 'Reparaturstelle'],
      invoice: ['Rechnungsanschrift'],
      invoiceHidden: ['Rechnungsadresse'],
      other: ['Treffpunkt'],
      otherHidden: [],
      creator: ['Ersteller'],
      all: function () {
        return unique([...this.resident, ...this.invoice, ...this.other]);
      },
      allHidden: function () {
        return unique([
          ...this.resident,
          ...this.residentHidden,
          ...this.invoice,
          ...this.invoiceHidden,
          ...this.other,
          ...this.otherHidden,
        ]);
      },
    },
    procedures: {
      customer: ['Kein Termine nötig', 'Werkstattauftrag', 'Angebot erstellen', 'Serviceauftrag'],
      predefined: [
        'Keine Adresse!',
        'Adresse eingetragen!',
        'Keine Kontakte!',
        'Kontakte eingetragen!',
        "Keine ToDo's!",
        "ToDo's eingetragen!",
        'Keine Kollegen!',
        'Kollegen eingetragen!',
      ],
      all: function () {
        return unique([...this.predefined, ...this.customer]);
      },
      allHidden: function () {
        return unique([...this.predefined, ...this.customer]);
      },
    },
    jobs: {
      customer: ['Kein Termine nötig', 'Werkstattauftrag', 'Angebot erstellen', 'Serviceauftrag'],
      predefined: [
        'Keine Adresse!',
        'Adresse eingetragen!',
        'Keine Kontakte!',
        'Kontakte eingetragen!',
        "Keine ToDo's!",
        "ToDo's eingetragen!",
        'Keine Kollegen!',
        'Kollegen eingetragen!',
      ],
      all: function () {
        return unique([...this.predefined, ...this.customer]);
      },
      allHidden: function () {
        return unique([...this.predefined, ...this.customer]);
      },
    },
  },
  permissions: {
    PERM_GLOBAL_READ: 'global read',
    PERM_GLOBAL_ADD: 'global add',
    PERM_GLOBAL_CHANGE: 'global change',
    PERM_GLOBAL_DELETE: 'global delete',
    PERM_GLOBAL_CLOSE: 'global close',
    PERM_GLOBAL_ARCHIVE: 'global archive',
    PERM_CONTACT_READ: 'contact read',
    PERM_CONTACT_ADD: 'contact add',
    PERM_CONTACT_CHANGE: 'contact change',
    PERM_CONTACT_DELETE: 'contact delete',
    PERM_INBOX_READ: 'inbox read',
    PERM_INBOX_ADD: 'inbox add',
    PERM_INBOX_ACCEPT: 'inbox accept',
    PERM_INBOX_DECLINE: 'inbox decline',
    PERM_INBOX_CHANGE: 'inbox change',
    PERM_INBOX_DELETE: 'inbox delete',
    PERM_INBOX_ARCHIVE: 'inbox archive',
    PERM_PROCEDURE_READ: 'procedure read',
    PERM_PROCEDURE_ADD: 'procedure add',
    PERM_PROCEDURE_CHANGE: 'procedure change',
    PERM_PROCEDURE_CLOSE: 'procedure close',
    PERM_PROCEDURE_DELETE: 'procedure delete',
    PERM_PROCEDURE_ARCHIVE: 'procedure archive',
    PERM_PROCEDURE_FACTER: 'procedure facter',
    PERM_JOB_READ: 'job read',
    PERM_JOB_ADD: 'job add',
    PERM_JOB_CHANGE: 'job change',
    PERM_JOB_CLOSE: 'job close',
    PERM_JOB_DELETE: 'job delete',
    PERM_JOB_ARCHIVE: 'job archive',
    PERM_JOB_FACTER: 'job facter',
    PERM_EMAIL_READ: 'settings email read',
    PERM_EMAIL_ADD: 'settings email add',
    PERM_EMAIL_CHANGE: 'settings email change',
    PERM_EMAIL_DELETE: 'settings email delete',
    PERM_COMPANY_READ: 'settings company read',
    PERM_COMPANY_ADD: 'settings company add',
    PERM_COMPANY_CHANGE: 'settings company change',
    PERM_COMPANY_DELETE: 'settings company delete',
    PERM_TODOS_READ: 'settings todos read',
    PERM_TODOS_ADD: 'settings todos add',
    PERM_TODOS_CHANGE: 'settings todos change',
    PERM_TODOS_DELETE: 'settings todos delete',
    PERM_COWORKERS_READ: 'settings coworkers read',
    PERM_COWORKERS_ADD: 'settings coworkers add',
    PERM_COWORKERS_CHANGE: 'settings coworkers change',
    PERM_COWORKERS_DELETE: 'settings coworkers delete',

    check: function (user, permission) {
      if (!!user.permissions) {
        let result = null;
        if (typeof permission === 'string') {
          result = user.permissions.filter((value) => permission === value.name);
        } else if (permission.length > 0) {
          result = user.permissions.filter((value) => permission.includes(value.name));
        } else {
          result = [];
        }
        return !!(result.length > 0);
      }
      return false;
    },
  },
  unique: function (data) {
    return unique(data);
  },
};
export default Constants;
