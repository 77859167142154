import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { Constants } from '../../../lib/Constants';
import { DE as TextDE } from '../../../lib/Text';
import { Card, CardHeader, CardContent, Typography, CircularProgress } from '@material-ui/core';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import Chip from '@material-ui/core/Chip';

const styles = (theme) => ({
  appointmentList: {
    flexGrow: 1,
    paddingTop: 0,
    paddingBottom: '0!important',
    paddingLeft: 0,
    paddingRight: 0,
    overflowY: 'auto',
  },
  title: {
    fontWeight: 700,
    padding: theme.spacing(1, 2),
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  icon: {
    height: 32,
    width: 32,
  },
  maxHeight: {
    [theme.breakpoints.up('md')]: {
      maxHeight: '100%',
      overflowY: 'auto',
    },
  },
});

const toCountdown = (start, end) => {
  start = Date.parse(start) / 1000;
  end = Date.parse(end) / 1000;
  let now = Math.floor(Date.now() / 1000);
  let time = 0;
  if (start > now) {
    time = start - now;
    return time > 172800
      ? 'in ' + Math.floor(time / 86400) + ' Tagen'
      : time > 86400
      ? 'Morgen'
      : 'in ' +
        (time > 3600
          ? Math.floor(time / 3600) + ' Std.'
          : time > 60
          ? Math.floor(time / 60) + ' Min.'
          : time + ' Sek.');
  } else if (end > now) {
    time = now - start;
    return time > 172800
      ? 'seit ' + Math.floor(time / 86400) + ' Tagen'
      : time > 86400
      ? 'Gestern'
      : 'seit ' +
        (time > 3600
          ? Math.floor(time / 3600) + ' Std.'
          : time > 60
          ? Math.floor(time / 60) + ' Min.'
          : time + ' Sek.');
  } else {
    return '';
  }
};

class UpcomingAppointments extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Card style={{ height: this.props.maxHeight, display: 'flex', flexFlow: 'column' }}>
        <CardHeader
          classes={{ action: classes.action, content: classes.content }}
          titleTypographyProps={{
            variant: 'h5',
            component: 'p',
            noWrap: true,
            className: classes.size,
          }}
          title={TextDE.upcoming + ' ' + TextDE.appointment.labelPlural}
          action={<Avatar variant='rounded'>{this.props.data.length}</Avatar>}
          avatar={
            <Avatar className={classes.avatar}>
              <EventAvailableIcon color='inherit' />
            </Avatar>
          }
        />
        <CardContent className={classes.appointmentList}>
          {this.props.isFetching ? (
            <center>
              {' '}
              <CircularProgress />{' '}
            </center>
          ) : (
            <List dense={true}>
              {this.props.data.length > 0 ? (
                this.props.data.map((value, index) => {
                  let icon = Constants.getInboxItemType(value.type);
                  let countdown = toCountdown(value.start, value.end);
                  return (
                    <ListItem
                      key={index}
                      button
                      onClick={() => {
                        if (!!value?.url) {
                          this.props.dispatch(push(value.url));
                        } else {
                          this.props.dispatch(push('/kalender/' + value.uri));
                        }
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.avatar}>{icon.icon}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        primary={
                          <div>
                            <Typography variant='caption' style={{ display: 'flex' }}>
                              <span style={{ flexGrow: 1 }}>{countdown}</span>
                              {!!value.serial && (
                                <Chip
                                  label={'ID# ' + value.serial}
                                  color='primary'
                                  size='small'
                                  style={{
                                    fontSize: '130%',
                                    fontWeight: 'bold',
                                  }}
                                />
                              )}
                            </Typography>
                            <Typography variant='h5'>{value.title || value.subject}</Typography>
                          </div>
                        }
                        secondary={
                          <Typography variant='caption' color='textSecondary'>
                            {value.time}
                          </Typography>
                        }
                      />
                    </ListItem>
                  );
                })
              ) : (
                <Typography
                  color='textSecondary'
                  variant='subtitle2'
                  align='center'
                  gutterBottom
                  paragraph
                >
                  {TextDE.appointment.noAppointment}
                </Typography>
              )}
            </List>
          )}
        </CardContent>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UpcomingAppointments);
