export function initSW(token) {
  if (
    typeof token !== 'undefined' &&
    typeof token.access_token !== 'undefined' &&
    'serviceWorker' in navigator &&
    'PushManager' in window
  ) {
    //register the service worker
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      //console.log("[CUSTOM SW] Registrations", registrations);
      if (registrations.length > 0) {
        registrations[0].pushManager.getSubscription().then((subscription) => {
          if (!(subscription === null)) {
            console.log('[CUSTOM SW] already subscribed'); //, subscription );
            storePushSubscription(subscription, token);
          } else {
            initPush(token);
          }
        });
      } else {
        initPush(token);
      }
    });

    //navigator.serviceWorker.register('/custom-sw.js')
    //    .then(() => {
    //        initPush(token);
    //    })
    //    .catch((err) => {
    //        console.log(err)
    //    });
  }
}

function initPush(token) {
  console.log('[CUSTOM SW] Serviceworker is Ready:', !!navigator.serviceWorker.ready);
  if (!navigator.serviceWorker.ready) {
    return;
  }

  if (Notification.permission === 'granted') {
    subscribeUser(token);
  } else {
    unsubscribeUser(token);
  }
}

function subscribeUser(token) {
  console.log('[CUSTOM SW] Subscribe User to push');
  navigator.serviceWorker.ready
    .then((registration) => {
      const subscribeOptions = {
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          'BI1iZSTnx7FGPqQbxVakuLhkpHvQGL3g4uLIAmqZSJjsAPG_rgZUVm42eJi9SuHkRhs4rHRo_rOUq9TaO11d7Ak',
        ),
      };

      return registration.pushManager.subscribe(subscribeOptions);
    })
    .then((pushSubscription) => {
      console.log('[CUSTOM SW] PushManager initiated');
      console.log('[CUSTOM SW] Received PushSubscription'); //, JSON.stringify(pushSubscription));
      storePushSubscription(pushSubscription, token);
    })
    .catch((error) => {
      console.log('[CUSTOM SW] PushManager failed', error);
    });
}
export async function unsubscribeUser(token) {
  console.log('[CUSTOM SW] Unsubscribe User from push'); //, token);
  return await navigator.serviceWorker.ready.then(function (registration) {
    registration.pushManager.getSubscription().then((subscription) => {
      return subscription
        .unsubscribe()
        .then(function (successful) {
          return removePushSubscription(subscription, token);
        })
        .catch(function (e) {
          // Unsubscription failed
          return false;
        });
    });
  });
}

function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  var base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  var rawData = window.atob(base64);
  var outputArray = new Uint8Array(rawData.length);

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function storePushSubscription(pushSubscription, token) {
  console.log('[CUSTOM SW] Serviceworker, push subscription'); //, navigator.serviceWorker, pushSubscription )
  if (typeof token.access_token) {
    fetch(process.env.REACT_APP_API_URL + '/api/push', {
      method: 'POST',
      body: JSON.stringify(pushSubscription),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access_token} `,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        console.log('[CUSTOM SW] Sucessfully Subscribed');
        //console.log(res)
      })
      .catch((err) => {
        console.log('[CUSTOM SW] ERROR', err);
      });
  }
}

async function removePushSubscription(pushSubscription, token) {
  console.log('[CUSTOM SW] Serviceworker, push unsubscribe'); //, navigator.serviceWorker, pushSubscription )
  return await fetch(process.env.REACT_APP_API_URL + '/api/push/unsubscribe', {
    method: 'POST',
    body: JSON.stringify(pushSubscription),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token} `,
    },
  })
    .then((res) => {
      console.log('[removePushSubscription]', res);
      return res.json();
    })
    .then((res) => {
      console.log('[CUSTOM SW] Sucessfully Unsubscribed');
      console.log('[removePushSubscription]', res);
      return res;
    })
    .catch((err) => {
      console.log('[CUSTOM SW] ERROR', err);
      return false;
    });
}
