import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up('lg')]: {
      margin: '16px 25%',
      width: '50%',
    },
  },
  floatRight: {
    float: 'right',
    paddingRight: '10%',
  },
});

class NotEnabled extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Alert className={classes.root} severity='error'>
        <AlertTitle> Upgraden Sie jetzt um diese Funktion zu aktivieren!</AlertTitle>
        <Grid container spacing={3}>
          {!!this.props.User.customer.description && (
            <Grid item xs={12}>
              <Typography variant='h6'>Sie nutzen derzeit die Gratis Version.</Typography>
              {!!this.props.data && (
                <Typography variant='h4'>
                  Die Funktionen unter <br />
                  <strong>"{this.props.data}"</strong>
                  <br /> sind so leider nicht verfügbar!
                </Typography>
              )}
            </Grid>
          )}

          <Grid item xs={8}>
            <Typography variant='h6'>
              Gebuchtes Paket: <strong className={classes.floatRight}>GRATIS Account</strong>
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant='contained'
              fullWidth
              startIcon={<ChevronRightIcon />}
              color='primary'
              onClick={() => this.props.dispatch(push('/settings/account'))}
            >
              Paket wechseln
            </Button>
          </Grid>
        </Grid>
      </Alert>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(NotEnabled);
