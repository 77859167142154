import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import UpdateIcon from '@material-ui/icons/Update';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import RoomIcon from '@material-ui/icons/Room';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CreateIcon from '@material-ui/icons/Create';
import renderHtml from '../../lib/transformHtml';
import { DE as TextDE } from '../../lib/Text';
import { readable } from '../../lib/dateFunctions';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 1),
    width: '88vw',
    backgroundColor: '#ffffff',
  },
  avatar: {
    backgroundColor: 'red',
    color: 'white',
    minWidth: theme.spacing(7),
    minHeight: theme.spacing(7),
    '&>svg': {
      minWidth: theme.spacing(5),
      minHeight: theme.spacing(5),
    },
  },
  paper: {
    padding: theme.spacing(1, 2),
    backgroundColor: '#ffffff',
  },
  noPad: {
    padding: '0px!important',
  },
  alignMiddle: {
    display: 'flex',
    alignItems: 'center',
    '& > b': {
      margin: theme.spacing(0, 2, 0, 1),
    },
    flexGrow: 1,
  },
  flexRight: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'end',
    '&>svg': {
      marginRight: '1em',
    },
    justifyContent: 'end',
  },
  header: {
    paddingBottom: 0,
  },
  expanded: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),
  },
  content: {
    padding: theme.spacing(2),
  },
  inquiry: {
    padding: theme.spacing(2),
    whiteSpace: 'pre',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
});

class Inbox extends React.Component {
  constructor(props) {
    super(props);
    var since = (new Date() - new Date('2021-08-02T07:38:23.000000Z')) / 60000;
    var deadline = false;
    if (since > 1440) {
      deadline = true;
      since = (
        <>
          {' '}
          Der Kunde wartet seit <b style={{ color: 'red' }}>{parseInt(since / 1440)} Tagen!</b>
        </>
      );
    } else if (since > 360) {
      deadline = true;
      since = (
        <>
          {' '}
          Der Kunde wartet seit <b style={{ color: 'red' }}>{parseInt(since / 60)} Stunden!</b>
        </>
      );
    } else if (since > 60) {
      since = (
        <>
          {' '}
          Die Anfrage wurde vor <b>{parseInt(since / 60)} Stunden</b> gestellt
        </>
      );
    } else {
      since = (
        <>
          {' '}
          Die Anfrage hat der Kunde vor <b>{parseInt(since)} Minuten</b> gestellt
        </>
      );
    }
    this.state = {
      since: since,
      deadline: deadline,
    };
    //this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  componentDidMount() {}

  render() {
    const { classes } = this.props;

    if (!!!this.props.data) {
      return null;
    }

    return (
      <Grid item>
        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            title={TextDE.inbox.competitive.titlePreview}
            titleTypographyProps={{ variant: 'h4' }}
            avatar={
              <Avatar className={classes.avatar} alt={TextDE.inbox.competitive.title}>
                <NewReleasesIcon />{' '}
              </Avatar>
            }
            action={
              <Typography variant='overline' component='p' className={classes.flexRight}>
                <UpdateIcon fontSize='small' />
                {readable(this.props.data.data.created_at)}
              </Typography>
            }
          />

          <CardContent className={classes.content}>
            <Typography display='block' variant='h5' component='p' paragraph={true}>
              {this.state.since}
            </Typography>
            {!!this.state.deadline && (
              <Typography display='block' variant='h5' component='p' paragraph={true}>
                Ihnen bleiben noch <b>00 H 27 Mins</b> um anzunehmen.
              </Typography>
            )}

            <Typography display='block' variant='body1' component='p' paragraph={true}>
              {TextDE.inbox.competitive.text(this.props.data.data.competitors.length)}
            </Typography>

            <Typography variant='h6'>
              {TextDE.inbox.title}: <strong>{this.props.data?.data?.data?.subject}</strong>
            </Typography>

            <Paper variant='outlined'>
              <div className={classes.inquiry}>{renderHtml(this.props.data?.data?.data?.body)}</div>
            </Paper>

            {this.props.data?.data?.media?.length > 1 && (
              <Typography variant='h6' gutterBottom>
                {TextDE.file.labelPlural}: <strong>{this.props.data?.data?.media?.length}</strong>{' '}
                Anhänge
              </Typography>
            )}
            {this.props.data?.data?.media?.length === 1 && (
              <Typography variant='h6' gutterBottom>
                {TextDE.file.label}: <strong>{this.props.data?.data?.media?.length}</strong> Datei
              </Typography>
            )}

            {Object.keys(this.props.data.data.external_request).length > 0 && (
              <Grid container>
                <Grid item xs={12} md={6}>
                  <Typography variant='h5'>
                    {this.props.data.data.external_request.contacts.length > 0
                      ? TextDE.contact.labelPlural
                      : TextDE.contact.label}
                  </Typography>
                  <List dense>
                    {!!this.props.data.data.external_request.creator && (
                      <ListItem>
                        <ListItemIcon>
                          <CreateIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            this.props.data.data.external_request.creator?.firstname +
                            ' ' +
                            this.props.data.data.external_request.creator?.lastname +
                            ' (Ersteller)'
                          }
                          secondaryTypographyProps={{ component: 'div' }}
                          secondary={
                            <>
                              {!!this.props.data.data.external_request.creator.address && (
                                <RoomIcon style={{ color: 'green' }} />
                              )}
                              {!!this.props.data.data.external_request.creator.phone && (
                                <PhoneIcon style={{ color: 'green' }} />
                              )}
                              {!!this.props.data.data.external_request.creator.secondPhone && (
                                <PhoneIcon style={{ color: 'green' }} />
                              )}
                              {!!this.props.data.data.external_request.creator.email && (
                                <AlternateEmailIcon style={{ color: 'green' }} />
                              )}
                              {!!this.props.data.data.external_request.creator.secondEmail && (
                                <AlternateEmailIcon style={{ color: 'green' }} />
                              )}
                            </>
                          }
                        />
                      </ListItem>
                    )}
                    {Object.keys(this.props.data.data.external_request.contacts).map(
                      (value, index) => {
                        if (!!this.props.data.data.external_request.contacts[value]) {
                          return (
                            <ListItem key={index}>
                              <ListItemIcon>
                                <PermContactCalendarIcon />
                              </ListItemIcon>
                              <ListItemText
                                primary={
                                  this.props.data.data.external_request.contacts[value].firstname +
                                  ' ' +
                                  this.props.data.data.external_request.contacts[value].lastname +
                                  (this.props.data.data.external_request.contacts.length > 1
                                    ? ''
                                    : ' (Ansprechpartner)')
                                }
                                secondaryTypographyProps={{ component: 'div' }}
                                secondary={
                                  <>
                                    {!!this.props.data.data.external_request.contacts[value]
                                      .address && <RoomIcon style={{ color: 'green' }} />}
                                    {!!this.props.data.data.external_request.contacts[value]
                                      .phone && <PhoneIcon style={{ color: 'green' }} />}
                                    {!!this.props.data.data.external_request.contacts[value]
                                      .secondPhone && <PhoneIcon style={{ color: 'green' }} />}
                                    {!!this.props.data.data.external_request.contacts[value]
                                      .email && <AlternateEmailIcon style={{ color: 'green' }} />}
                                    {!!this.props.data.data.external_request.contacts[value]
                                      .secondEmail && (
                                      <AlternateEmailIcon style={{ color: 'green' }} />
                                    )}
                                  </>
                                }
                              />
                            </ListItem>
                          );
                        } else {
                          return null;
                        }
                      },
                    )}
                  </List>
                </Grid>
                {!!this.props.data.data.external_request.addresses && (
                  <Grid item xs={12} md={6}>
                    <Typography variant='h5'>
                      {this.props.data.data.external_request.addresses.length > 1
                        ? TextDE.address.labelPlural
                        : TextDE.address.label}
                    </Typography>
                    <List dense>
                      {Object.keys(this.props.data.data.external_request.addresses).map(
                        (value, index) => {
                          if (!!this.props.data.data.external_request.addresses[value]) {
                            return (
                              <ListItem key={index}>
                                <ListItemIcon>
                                  {this.props.data.data.external_request.addresses[value][
                                    'type'
                                  ] !== 'Rechnungsadresse' ? (
                                    <RoomIcon />
                                  ) : (
                                    <LocalAtmIcon />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  primary={
                                    this.props.data.data.external_request.addresses[value][
                                      'route'
                                    ] +
                                      ' ' +
                                      this.props.data.data.external_request.addresses[value][
                                        'street_number'
                                      ] ||
                                    this.props.data.data.external_request.addresses[value][
                                      'streetNumber'
                                    ] +
                                      ', ' +
                                      this.props.data.data.external_request.addresses[value][
                                        'postal_code'
                                      ] ||
                                    this.props.data.data.external_request.addresses[value][
                                      'postalCode'
                                    ] +
                                      ' ' +
                                      this.props.data.data.external_request.addresses[value][
                                        'locality'
                                      ]
                                  }
                                  secondary={
                                    this.props.data.data.external_request.addresses[value][
                                      'type'
                                    ] || TextDE.address.label
                                  }
                                />
                              </ListItem>
                            );
                          } else {
                            return null;
                          }
                        },
                      )}
                    </List>
                  </Grid>
                )}
              </Grid>
            )}
            <Grid
              className={classes.marginTop}
              container
              direction='row'
              justifyContent='space-around'
              alignItems='center'
            >
              <Grid item xs={4}>
                <Button
                  variant='contained'
                  fullWidth
                  color='secondary'
                  onClick={this.props.handleDenyClick}
                >
                  {TextDE.decline}
                </Button>
              </Grid>
              <Grid item xs={5}>
                <Button
                  variant='contained'
                  fullWidth
                  color='primary'
                  onClick={this.props.handleAcceptClick}
                >
                  {TextDE.accept}
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({ Theme: state.Style.Theme });

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Inbox);
