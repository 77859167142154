/*
 * action types
 */
export const RESET = 'AUTO_FETCH_RESET';
export const FETCH_USER = 'AUTO_FETCH_USER';
export const FETCH_CUSTOMER = 'AUTO_FETCH_CUSTOMER';
export const FETCH_OTHER = 'AUTO_FETCH_OTHER';
export const SET_ECHO = 'SET_ECHO';
export const APPEND_INBOX = 'APPEND_INBOX';
export const REMOVE_INBOX = 'REMOVE_INBOX';
export const CLEAR_INBOX = 'CLEAR_INBOX';
export const RESET_NOTFICATIONS = 'RESET_NOTFICATIONS';

/*
 * action creators
 */
export const fetchUser = (res) => {
  return { type: FETCH_USER, res };
};
export const fetchCustomer = (res) => {
  return { type: FETCH_CUSTOMER, res };
};
export const fetchOther = (res) => {
  return { type: FETCH_OTHER, res };
};
export const setEcho = (res) => {
  return { type: SET_ECHO, res };
};
export const appendInbox = (res) => {
  return { type: APPEND_INBOX, res };
};
export const removeInbox = (res) => {
  return { type: REMOVE_INBOX, res };
};
export const clearInbox = (res) => {
  return { type: CLEAR_INBOX };
};
export const resetNotifications = (res) => {
  return { type: RESET_NOTFICATIONS };
};

export const reset = () => {
  return { type: RESET };
};
