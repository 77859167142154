import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';

import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';

import { DE as TextDE } from '../../../../../lib/Text';

import moment from 'moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import 'moment/locale/de';
import 'moment/locale/fr';

import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    minWidth: '50vw',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  input: {
    backgroundColor: 'antiquewhite',
    fontSize: '1.5em',
  },
  summary: {
    fontWeight: '800',
    fontSize: '120%',
  },
  head: {
    fontWeight: '800',
    fontSize: '120%',
  },
  delete: {
    display: 'block',
    maxWidth: '160px',
    minWidth: '7%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  description: {
    maxWidth: '65%',
    minWidth: '50%',
  },
  todoTable: {
    marginBottom: theme.spacing(2),
    '&>tbody': {
      '&>tr': {
        '&>th': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '5',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  },
  floatRight: {
    float: 'right',
  },
  checkboxSize: {
    '&>span': {
      paddingRight: '0em',
      paddingLeft: '0em',
      '&>svg': {
        width: '1.5em',
        height: '1.5em',
      },
    },
  },
});

class Todos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      hasError: false,
      editId: false,
      deleteTodo: false,
      [props.name]: props[props.name],
      users:
        props.preSelectedUsers
          .map((user) => {
            let index = props.usersAll.findIndex((elm) => elm.uuid === user.uuid);
            if (index >= 0) {
              return props.usersAll[index];
            }
            return null;
          })
          .filter((elm) => elm !== null) ?? [],
      tracked_at: moment(),
      timetrack_hours: 0,
      timetrack_minutes: 0,
      description: '',
      timeAsAllowance: props.timeAsAllowance,
    };
    this.handleMinuteSliderChange = this.handleMinuteSliderChange.bind(this);
    this.handleHourSliderChange = this.handleHourSliderChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.autocompleteFormChange = this.autocompleteFormChange.bind(this);

    this.editTodo = this.editTodo.bind(this);
    this.deleteTodo = this.deleteTodo.bind(this);
    this.changeTimesAsAllowance = this.changeTimesAsAllowance.bind(this);
  }

  changeTimesAsAllowance = async () => {
    var currentTimeAsAllowance = !!!this.state.timeAsAllowance;
    let formData = {
      _method: 'PUT',
      timeAsAllowance: currentTimeAsAllowance,
    };

    let endpoint = process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid;

    let response = await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
        return error;
      });

    if (response.uuid === this.props.uuid) {
      this.setState({
        timeAsAllowance: currentTimeAsAllowance,
      });
      return;
    }
  };

  handleMinuteSliderChange = (event, value) => {
    this.setState({ timetrack_minutes: value });
  };
  handleHourSliderChange = (event, value) => {
    this.setState({ timetrack_hours: value });
  };
  handleChange = (event, value) => {
    if (Array.isArray(value)) {
      this.setState({ timetrack_users: value });
    } else if (event._isAMomentObject) {
      this.setState({ tracked_at: event });
    } else if (typeof event.getMonth === 'function') {
      this.setState({ tracked_at: event });
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      if (event.target.type === 'checkbox') {
        this.setState({ [event.target.name]: event.target.checked });
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
  };
  addTodo = async () => {
    let response = await this.save(
      process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid + '/v2/todo',
    );
    if (response.success === true || response.success === 'true') {
      let todoItems = this.state[this.props.name];
      todoItems.push(response.todoItem);
      this.setState(
        {
          [this.props.name]: todoItems,
          isSubmitting: false,
          users:
            this.props.preSelectedUsers.map((user) => {
              return {
                ...user,
                displayName: user.firstname + ' ' + user.lastname,
              };
            }) ?? [],
          tracked_at: moment(),
          timetrack_hours: 0,
          timetrack_minutes: 0,
          description: '',
        },
        () => this.props.onChange(this.props.name, this.state[this.props.name]),
      );
      return;
    }
    this.setState({
      isSubmitting: false,
      hasError: response,
    });
  };
  updateTodo = async () => {
    let response = await this.save(
      process.env.REACT_APP_API_URL +
        '/api/jobs/' +
        this.props.uuid +
        '/v2/todo/' +
        this.state.editId,
    );
    if (response.success === true || response.success === 'true') {
      let todoItems = this.state[this.props.name];
      let editIndex = todoItems.findIndex((el) => el.id === response.todoItem.id);
      todoItems[editIndex] = response.todoItem;

      this.setState(
        {
          [this.props.name]: todoItems,
          isSubmitting: false,
          hasError: false,
          users:
            this.props.preSelectedUsers.map((user) => {
              return {
                ...user,
                displayName: user.firstname + ' ' + user.lastname,
              };
            }) ?? [],
          tracked_at: moment(),
          timetrack_hours: 0,
          timetrack_minutes: 0,
          description: '',
        },
        () => this.props.onChange(this.props.name, this.state[this.props.name]),
      );
      return;
    }
    this.setState({
      isSubmitting: false,
      hasError: response,
    });
  };

  editTodo = (todo) => {
    this.setState(
      {
        users: todo.timetracks?.map((track) => {
          return {
            ...track.user,
            displayName: track.user.firstname + ' ' + track.user.lastname,
          };
        }),
        tracked_at: !!todo.timetracks[0]?.tracked_at
          ? moment(todo.timetracks[0]?.tracked_at)
          : moment(),
        //props[props.name][0]["timetracks"][0]["tracked_at"] ??
        timetrack_hours: !!todo.timetracks[0] ? parseInt(todo.timetracks[0].tracked_time / 60) : 0,
        timetrack_minutes: !!todo.timetracks[0]
          ? parseInt(todo.timetracks[0].tracked_time % 60)
          : 0,
        description: todo.description ?? '',
        editId: todo.id,
      },
      () => this.props.zoomFunction(),
    );
  };

  deleteTodo = (todo) => {
    this.setState({ deleteTodo: todo }, () => this.props.zoomFunction());
  };

  delete = async () => {
    if (!!!this.state.deleteTodo) {
      this.setState({
        hasError: {
          status: TextDE.performanceReport.tableTodo.notFound,
        },
      });
      return;
    }
    let todo = this.state.deleteTodo;
    this.setState({ isSubmitting: true });
    let response = await fetch(
      process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid + '/v2/todo/' + todo.id,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
        return error;
      });

    if (response.success === true || response.success === 'true') {
      let todoItems = this.state[this.props.name];
      todoItems = todoItems.filter((el) => el.id !== todo.id);
      if (this.state.editId === todo.id) {
        this.setState(
          {
            isSubmitting: false,
            hasError: false,
            editId: false,
            deleteTodo: false,
            //[this.props.name]: this.props[this.props.name],
            [this.props.name]: todoItems,
            users:
              this.props.preSelectedUsers.map((user) => {
                return {
                  ...user,
                  displayName: user.firstname + ' ' + user.lastname,
                };
              }) ?? [],
            tracked_at: moment(),
            timetrack_hours: 0,
            timetrack_minutes: 0,
            description: '',
          },
          () => this.props.onChange(this.props.name, this.state[this.props.name]),
        );
        return;
      }
      this.setState(
        {
          [this.props.name]: todoItems,
          isSubmitting: false,
          deleteTodo: false,
        },
        () => this.props.onChange(this.props.name, this.state[this.props.name]),
      );
      return;
    }
    this.setState({
      isSubmitting: false,
      hasError: response,
    });
  };
  autocompleteFormChange(event, value) {
    this.setState({ users: value });
  }
  openAddTodo = () => {
    if (!!this.state.editId) {
      this.setState(
        {
          isSubmitting: false,
          hasError: false,
          editId: false,
          deleteTodo: false,
          users:
            this.props.preSelectedUsers.map((user) => {
              return {
                ...user,
                displayName: user.firstname + ' ' + user.lastname,
              };
            }) ?? [],
          tracked_at: moment(),
          timetrack_hours: 0,
          timetrack_minutes: 0,
          description: '',
        },
        () => this.props.zoomFunction(),
      );
      return;
    }
    this.props.zoomFunction();
  };

  save = async (endpoint) => {
    this.setState({
      isSubmitting: true,
    });
    // call to api ...
    let formData = {
      _method: 'PUT',
      users: this.state.users.map((user) => user.uuid),
      tracked_at: this.state.tracked_at,
      time: parseInt(this.state.timetrack_hours * 60) + parseInt(this.state.timetrack_minutes),
      description: this.state.description,
    };

    return await fetch(endpoint, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
        return error;
      });
  };

  render() {
    const { classes } = this.props;
    let totalTime = 0;
    return (
      <>
        <Backdrop className={classes.backdrop} open={this.props.open}>
          <Zoom in={this.props.open}>
            {!!this.state.isSubmitting ? (
              <Paper elevation={4} className={classes.paper} component='center'>
                <CircularProgress />
              </Paper>
            ) : !!!this.state.deleteTodo ? (
              <Paper elevation={4} className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <TextField
                      name='description'
                      value={this.state.description}
                      InputProps={{ classes: { root: classes.input } }}
                      onChange={this.handleChange}
                      fullWidth
                      multiline
                      minRows={1}
                      maxRows={5}
                      margin='none'
                      variant='outlined'
                      label={TextDE.performanceReport.tableTodo.labelTodo}
                      helperText={TextDE.performanceReport.tableTodo.helperTodo}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Autocomplete
                      id='users'
                      name='users'
                      multiple
                      options={this.props.usersAll}
                      getOptionLabel={(option) =>
                        !!option.displayName
                          ? option.displayName
                          : option.firstname + ' ' + option.lastname
                      }
                      value={this.state.users}
                      classes={{ inputRoot: classes.input }}
                      onChange={this.autocompleteFormChange}
                      renderOption={(option, state) => (
                        <CardHeader
                          key={option.uuid}
                          avatar={
                            <Avatar
                              alt={
                                !!option.displayName
                                  ? option.displayName
                                  : option.firstname + ' ' + option.lastname
                              }
                              src={
                                process.env.REACT_APP_API_URL + '/avatar/' + option.uuid + '?thumb'
                              }
                            >
                              {!!option.displayName
                                ? option.displayName
                                : option.firstname + ' ' + option.lastname}
                            </Avatar>
                          }
                          titleTypographyProps={{ variant: 'body1' }}
                          title={
                            !!option.displayName
                              ? option.displayName
                              : option.firstname + ' ' + option.lastname
                          }
                        />
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          margin='none'
                          variant='outlined'
                          label={TextDE.performanceReport.tableTodo.labelUsers}
                          helperText={TextDE.performanceReport.tableTodo.helperUsers}
                        />
                      )}
                    />
                  </Grid>
                  <Hidden lgUp>
                    <Grid item xs={12} md={6}>
                      <Typography gutterBottom>
                        {TextDE.performanceReport.tableTodo.helperTimeHours}
                      </Typography>

                      <Slider
                        defaultValue={0}
                        step={1}
                        min={0}
                        max={14}
                        onChange={this.handleHourSliderChange}
                        value={this.state.timetrack_hours}
                        name='timetrack_hours'
                        valueLabelDisplay='auto'
                        marks={[
                          {
                            value: 1,
                            label: '1',
                          },
                          {
                            value: 2,
                            label: '2',
                          },
                          {
                            value: 3,
                            label: '3',
                          },
                          {
                            value: 4,
                            label: '4',
                          },
                          {
                            value: 5,
                            label: '5',
                          },
                          {
                            value: 6,
                            label: '6',
                          },
                          {
                            value: 7,
                            label: '7',
                          },
                          {
                            value: 8,
                            label: '8',
                          },
                          {
                            value: 9,
                            label: '9',
                          },
                          {
                            value: 10,
                            label: '10',
                          },
                          {
                            value: 11,
                            label: '11',
                          },
                          {
                            value: 12,
                            label: '12',
                          },
                          {
                            value: 13,
                            label: '13',
                          },
                          {
                            value: 14,
                            label: '14',
                          },
                        ]}
                      />
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Typography gutterBottom>
                        {TextDE.performanceReport.tableTodo.helperTimeMinutes}
                      </Typography>
                      <Box m={1}>
                        <Slider
                          defaultValue={0}
                          step={15}
                          min={0}
                          max={60}
                          value={this.state.timetrack_minutes}
                          name='timetrack_minutes'
                          onChange={this.handleMinuteSliderChange}
                          valueLabelDisplay='auto'
                          marks={[
                            {
                              value: 0,
                              label: '0',
                            },
                            {
                              value: 15,
                              label: '15',
                            },
                            {
                              value: 30,
                              label: '30',
                            },
                            {
                              value: 45,
                              label: '45',
                            },
                            {
                              value: 60,
                              label: '60',
                            },
                          ]}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography gutterBottom>
                        {TextDE.performanceReport.tableTodo.labelTotalTime} (p.P):
                        <strong>
                          &nbsp; {this.state.timetrack_hours}{' '}
                          {TextDE.performanceReport.tableTodo.labelTimeHours}{' '}
                          {this.state.timetrack_minutes}{' '}
                          {TextDE.performanceReport.tableTodo.labelTimeMinutes}
                        </strong>
                      </Typography>
                    </Grid>
                  </Hidden>

                  <Hidden mdDown>
                    <Grid item md={6} style={{ display: 'flex' }}>
                      <TextField
                        label={TextDE.performanceReport.tableTodo.labelTimeHours}
                        helperText={TextDE.performanceReport.tableTodo.helperTimeHours}
                        type='number'
                        inputProps={{ min: 0, max: 23 }}
                        onChange={this.handleChange}
                        name='timetrack_hours'
                        value={this.state.timetrack_hours}
                        variant='outlined'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ classes: { root: classes.input } }}
                        style={{ flexGrow: 1, marginRight: '8px' }}
                      />
                      <Typography align='center' variant='h3' component='span'>
                        :
                      </Typography>
                      <TextField
                        label={TextDE.performanceReport.tableTodo.labelTimeMinutes}
                        helperText={TextDE.performanceReport.tableTodo.helperTimeMinutes}
                        type='number'
                        inputProps={{ min: 0, max: 59 }}
                        onChange={this.handleChange}
                        name='timetrack_minutes'
                        value={this.state.timetrack_minutes}
                        variant='outlined'
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{ classes: { root: classes.input } }}
                        style={{ flexGrow: 1, marginLeft: '8px' }}
                      />
                    </Grid>
                  </Hidden>

                  <Grid item xs={12} lg={6}>
                    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'de'}>
                      <KeyboardDatePicker
                        disableToolbar
                        inputVariant='outlined'
                        format='DD.MM.YYYY'
                        margin='none'
                        name='tracked_at'
                        label={TextDE.performanceReport.tableTodo.labelDoneAtDate}
                        value={this.state.tracked_at}
                        onChange={this.handleChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        style={{ width: '100%' }}
                        InputProps={{ classes: { root: classes.input } }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <Button
                      className={classes.floatRight}
                      startIcon={<AddIcon color='inherit' />}
                      variant={
                        this.state.users?.length > 0 &&
                        this.state.description?.length > 3 &&
                        this.state.timetrack_hours * 60 + this.state.timetrack_minutes > 0
                          ? 'contained'
                          : 'outlined'
                      }
                      color={
                        this.state.users?.length > 0 &&
                        this.state.description?.length > 3 &&
                        this.state.timetrack_hours * 60 + this.state.timetrack_minutes > 0
                          ? 'primary'
                          : 'secondary'
                      }
                      disabled={
                        !(
                          this.state.users?.length > 0 &&
                          this.state.description?.length > 3 &&
                          this.state.timetrack_hours * 60 + this.state.timetrack_minutes > 0
                        )
                      }
                      onClick={!!this.state.editId ? this.updateTodo : this.addTodo}
                    >
                      {!!this.state.editId
                        ? TextDE.performanceReport.tableTodo.editTodo
                        : TextDE.performanceReport.tableTodo.addTodo}
                    </Button>
                  </Grid>
                </Grid>

                {!!this.state.hasError && (
                  <Alert severity='error' className={classes.center}>
                    <AlertTitle>
                      {this.state.hasError?.status ?? 'Fehler'} -{' '}
                      {this.state.hasError?.statusText ?? 'Fehler'}
                    </AlertTitle>
                    {JSON.stringify(this.state.hasError, null, 4)}
                  </Alert>
                )}
                <Button
                  onClick={() => this.props.onChange(this.props.name, this.state[this.props.name])}
                >
                  {TextDE.close}
                </Button>
              </Paper>
            ) : (
              <Paper elevation={4} className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>
                      {TextDE.performanceReport.tableTodo.labelTodoDelete}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography gutterBottom>{this.state.deleteTodo.description}</Typography>
                  </Grid>

                  {!!this.state.hasError && (
                    <Grid item xs={12}>
                      <Alert severity='error' className={classes.center}>
                        <AlertTitle>
                          {this.state.hasError?.status ?? 'Fehler'} -{' '}
                          {this.state.hasError?.statusText ?? 'Fehler'}
                        </AlertTitle>
                        {JSON.stringify(this.state.hasError, null, 4)}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Button
                      onClick={() =>
                        this.setState({ deleteTodo: false }, () => this.props.zoomFunction())
                      }
                    >
                      {TextDE.abort}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.floatRight}
                      startIcon={<DeleteIcon color='inherit' />}
                      variant='contained'
                      color='secondary'
                      onClick={this.delete}
                    >
                      {TextDE.delete}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Zoom>
        </Backdrop>

        <TableContainer component={Grid}>
          <Table className={classes.todoTable} size='small'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>
                  {TextDE.performanceReport.tableTodo.labelTodo}
                </TableCell>
                <TableCell align='right' className={classes.head}>
                  {TextDE.performanceReport.tableTodo.labelUsers}
                </TableCell>
                <TableCell align='right' className={classes.head}>
                  {TextDE.performanceReport.tableTodo.labelTime}
                </TableCell>
                <TableCell align='right' className={classes.head}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.todos.map((todo, index) => {
                var time = 0;
                let displayTime = 0;
                let users = [];
                todo.timetracks?.forEach((track) => {
                  time = time + track.tracked_time;
                  displayTime = track.tracked_time;
                  users.push(track.user.firstname + ' ' + track.user.lastname);
                });
                totalTime = totalTime + time;
                return (
                  <TableRow key={index}>
                    <TableCell className={classes.description}>
                      {!!todo.timetracks[0]?.tracked_at && (
                        <small>
                          Geleistet am:{' '}
                          <b>{moment(todo.timetracks[0]?.tracked_at).format('DD.MM.YYYY')}</b>
                          <br />
                        </small>
                      )}
                      {todo.description}
                      {!!todo.comment ? (
                        <small>
                          <br /> {TextDE.performanceReport.labelNotes}: {todo.comment}{' '}
                        </small>
                      ) : (
                        ''
                      )}
                    </TableCell>
                    <TableCell align='right'>{users.join(', \n')}</TableCell>
                    <TableCell align='right'>
                      {displayTime > 60 ? Math.floor(displayTime / 60) + ' Std. ' : null}{' '}
                      {displayTime > 60 ? displayTime % 60 : displayTime} Min
                    </TableCell>
                    <TableCell align='right' className={classes.delete}>
                      <IconButton onClick={() => this.editTodo(todo)}>
                        <CreateIcon />
                      </IconButton>
                      <IconButton onClick={() => this.deleteTodo(todo)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Typography variant='h5' align='right' gutterBottom>
          {TextDE.performanceReport.tableTodo.labelTotalTime}:
          <strong>
            {totalTime > 60 ? Math.floor(totalTime / 60) + ' Std. ' : null}{' '}
            {totalTime > 60 ? totalTime % 60 : totalTime} Min
          </strong>
        </Typography>
        {!!!this.state.timeAsAllowance && (
          <Hidden smDown>
            <Typography
              variant='body2'
              align='justify'
              gutterBottom
              style={{ paddingLeft: '45%', fontStyle: 'oblique' }}
            >
              Die oben ausgewiesene Stundenanzahl beinhaltet die An- und Abfahrt zur bzw. von der
              Baustelle sowie die arbeitsvorbereitenden Arbeiten für Ihren Auftrag.
            </Typography>
          </Hidden>
        )}
        {!!!this.state.timeAsAllowance && (
          <Hidden mdUp>
            <Typography
              variant='body2'
              align='justify'
              gutterBottom
              style={{ fontStyle: 'oblique' }}
            >
              Die oben ausgewiesene Stundenanzahl beinhaltet die An- und Abfahrt zur bzw. von der
              Baustelle sowie die arbeitsvorbereitenden Arbeiten für Ihren Auftrag.
            </Typography>
          </Hidden>
        )}
        <Box component='div' align='right' mt={1} mb={3}>
          <Tooltip title={TextDE.performanceReport.tableTodo.timeNotShownTooltip}>
            <FormControlLabel
              value={this.state.timeAsAllowance}
              id='timeAllowance'
              name='timeAllowance'
              control={
                <Checkbox
                  color='primary'
                  className={classes.checkboxSize}
                  checked={this.state.timeAsAllowance}
                />
              }
              onChange={this.changeTimesAsAllowance}
              label={TextDE.performanceReport.tableTodo.labelThisIsAnAllowanceJob}
              labelPlacement='start'
            />
          </Tooltip>
        </Box>

        <Button
          size='small'
          endIcon={<CreateIcon />}
          fullWidth
          variant='contained'
          color='primary'
          onClick={this.openAddTodo}
        >
          {TextDE.performanceReport.tableTodo.labelTodoPlural} {TextDE.add}
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

export default compose(withStyles(styles), connect(mapStateToProps))(Todos);
