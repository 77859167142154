import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { Constants } from '../../lib/Constants';
import Badge from '@material-ui/core/Badge';
import HotelIcon from '@material-ui/icons/Hotel'; // none
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople'; // ultralow
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk'; //low - mediumlow
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun'; //medium
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike'; // mediumhigh
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar'; //high
import LocalAirportIcon from '@material-ui/icons/LocalAirport'; // ultrahigh
import FiberNewIcon from '@material-ui/icons/FiberNew'; // new
import SnoozeTwoToneIcon from '@material-ui/icons/SnoozeTwoTone'; // wait
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'; //done
import ErrorOutlineIcon from '@material-ui/icons/Error';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import UpdateIcon from '@material-ui/icons/Update';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { diffDate, diffInDays } from '../../lib/dateFunctions';
import DoneIcon from '@material-ui/icons/Done';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AvatarGroup from '@material-ui/lab/AvatarGroup';

const customHeight = 64;

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  times: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  item: {
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  avatar: {
    marginTop: 'auto',
    marginBottom: 'auto',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  avatarGroupWrap: {
    width: customHeight + 'px!important',
    height: customHeight + 'px!important',
    marginLeft: '-' + customHeight * 0.25 + 'px!important',
    '&:last-child': {
      marginLeft: '-' + customHeight * 0.4 + 'px!important',
    },
    '&:first-child': {
      marginLeft: '0px!important',
    },
  },
  avatarIcon: {
    height: customHeight * 0.6 + 'px!important',
    borderColor: theme.palette.primary.main,
    width: customHeight + 'px!important',
    transform: 'scaleX(-1)',
  },
  avatarWrap: {
    height: 'inherit',
    width: 'auto',
  },
  shrinkAndCenter: {
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.up('lg')]: {
      flexShrink: 1,
      marginRight: theme.spacing(1),
      flexBasis: '0%',
    },
  },
  grow: {
    [theme.breakpoints.up('lg')]: {
      flexGrow: 1,
      flexBasis: '0%',
      maxWidth: '70%',
      marginRight: 'auto',
    },
  },
  timeContainer: {
    flexShrink: 1,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
}));

const badgeIconOf = {
  done: <AssignmentTurnedInIcon style={{ color: 'inherit' }} />,
  working: <DirectionsRunIcon style={{ color: 'inherit' }} />,
  wait: <SnoozeTwoToneIcon style={{ color: 'inherit' }} />,
  new: <FiberNewIcon style={{ color: 'inherit' }} />,
  none: <HotelIcon style={{ color: 'inherit' }} />,
  ultralow: <EmojiPeopleIcon style={{ color: 'inherit' }} />,
  low: <DirectionsWalkIcon style={{ color: 'inherit' }} />,
  mediumlow: <DirectionsWalkIcon style={{ color: 'inherit' }} />,
  medium: <DirectionsRunIcon style={{ color: 'inherit' }} />,
  mediumhigh: <DirectionsBikeIcon style={{ color: 'inherit' }} />,
  high: <DirectionsCarIcon style={{ color: 'inherit' }} />,
  ultrahigh: <LocalAirportIcon style={{ color: 'inherit' }} />,
  emergency: <NewReleasesIcon style={{ color: 'inherit' }} />,
};

//const icons =  {
//		[Constants.inboxItemTypes.app.id]: Constants.inboxItemTypes.app.icon,
//		[Constants.inboxItemTypes.phone.id]: Constants.inboxItemTypes.phone.icon,
//		[Constants.inboxItemTypes.email.id]: Constants.inboxItemTypes.email.icon,
//		[Constants.inboxItemTypes.sensors.id]: Constants.inboxItemTypes.sensors.icon,
//		[Constants.inboxItemTypes.chatbot.id]: Constants.inboxItemTypes.chatbot.icon,
//		[Constants.inboxItemTypes.hook.id]: Constants.inboxItemTypes.hook.icon,
//		[Constants.inboxItemTypes.note.id]: Constants.inboxItemTypes.note.icon,
//		[Constants.inboxItemTypes.new_procedure.id]: Constants.inboxItemTypes.new_procedure.icon,
//		[Constants.inboxItemTypes.new_job.id]: Constants.inboxItemTypes.new_job.icon,
//		[Constants.inboxItemTypes.other.id]: Constants.inboxItemTypes.other.icon,
//	};
//

export default function ListItemOrga(props) {
  const classes = useStyles();

  const dispatch = (event) => {
    if (event.target.localName === 'svg') {
      if (!!event.target.id && event.target.id.toLowerCase().endsWith('delete')) {
        props.delete(message);
      }
    } else if (event.target.localName === 'button') {
      if (!!event.target.id && event.target.id.toLowerCase().endsWith('delete')) {
        props.delete(message);
      }
    } else if (event.target.localName === 'path') {
      if (
        !!event.target.parentElement.id &&
        event.target.parentElement.id.toLowerCase().endsWith('delete')
      ) {
        props.delete(message);
      }
    } else {
      props.linkTo('/nachrichten/detail/' + message.uuid);
    }
  };

  let message = props.message;

  const type = Constants.getInboxItemType(message.type);
  //console.log("This item has:" + ((!!props.newItem || props.hasChange !== -1) ?  "newItem" : "item" ), props.newItem , props.hasChange );
  return (
    <>
      <ListItem button onClick={dispatch} alignItems='flex-start' className={classes.item}>
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='stretch'
          spacing={2}
        >
          <Grid
            item
            xs={2}
            sm={1}
            md={false}
            lg={false}
            xl={false}
            className={classes.shrinkAndCenter}
          >
            <Tooltip arrow title={type.description}>
              <Badge
                badgeContent={message.procedure?.jobs?.length}
                overlap='rectangular'
                color='error'
              >
                <Avatar className={classes.avatar}>{type.icon}</Avatar>
              </Badge>
            </Tooltip>
          </Grid>
          <Grid item xs={10} sm={11} md={false} lg={false} xl={false} className={classes.grow}>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {!!message.procedure?.data?.subject ? (
                  <Typography variant='h5' className={classes.truncate}>
                    {message.procedure?.data?.subject}
                  </Typography>
                ) : !!message.data?.subject ? (
                  <Typography variant='h5' className={classes.truncate}>
                    {message.data?.subject}
                  </Typography>
                ) : null}

                {!!message.procedure?.data?.subtitle ? (
                  <Typography className={classes.truncate} color='textSecondary'>
                    {message.procedure?.data?.subtitle}
                  </Typography>
                ) : !!message.data?.subtitle ? (
                  <Typography className={classes.truncate} color='textSecondary'>
                    {message.data?.subtitle}
                  </Typography>
                ) : !!message.data?.from ? (
                  <Typography className={classes.truncate} color='textSecondary'>
                    <ContactMailIcon fontSize='inherit' />
                    &nbsp;
                    {message.data?.from[0].full}
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={12} className={classes.truncate}>
                {!!message.procedure ? (
                  <>
                    {!!message.procedure?.status && (
                      <Chip
                        label={message.procedure?.status.name}
                        icon={badgeIconOf[message.procedure?.status.icon]}
                        size='small'
                        variant='outlined'
                        style={{
                          color: message.procedure?.status.style.color,
                          backgroundColor: message.procedure?.status.style.backgroundColor,
                        }}
                      />
                    )}
                    &nbsp;
                    {!!message.procedure?.serial && (
                      <Chip
                        label={'ID# ' + message.procedure?.serial}
                        color='primary'
                        size='small'
                        style={{ fontWeight: 'bold' }}
                      />
                    )}
                    &nbsp;
                    <Hidden only={['xs', 'sm']}>
                      {!!message.procedure?.tags &&
                        message.procedure?.tags.map((val, key) => {
                          if (val.type !== 'success') {
                            return (
                              <Chip
                                key={'chip-tags-' + message.id + '-' + key}
                                size='small'
                                className={(!!val.type ? val.type : 'warning') + '-border'}
                                icon={
                                  val.type === 'success' ? (
                                    <DoneIcon style={{ color: 'inherit' }} />
                                  ) : val.type === 'error' ? (
                                    <ErrorOutlineIcon style={{ color: 'inherit' }} />
                                  ) : (
                                    <InfoIcon style={{ color: 'inherit' }} />
                                  )
                                }
                                variant='outlined'
                                label={val.name.de}
                              />
                            );
                          }
                          return null;
                        })}
                    </Hidden>
                  </>
                ) : !!message.data?.body ? (
                  <>
                    <Typography className={classes.truncate} color='textSecondary'>
                      {!!message.data?.body?.text
                        ? message.data?.body.text
                        : !!message.data?.body?.html
                        ? message.data?.body.html.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
                        : !!message.data?.body && typeof message.data?.body === 'string'
                        ? message.data?.body.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '')
                        : ''}
                    </Typography>
                    {!!message.data?.files?.text && (
                      <Typography className={classes.truncate} color='textSecondary'>
                        <AttachFileIcon /> {message.data?.files?.text}
                      </Typography>
                    )}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          {!!message.procedure && !!message.procedure.users && (
            <Hidden mdDown>
              <Grid item className={classes.shrinkAndCenter}>
                <AvatarGroup max={5} spacing='small' classes={{ avatar: classes.avatarGroupWrap }}>
                  {message.procedure.users.map((userWrap) => (
                    <Tooltip
                      key={userWrap.user.uuid}
                      title={`${userWrap.user.firstname} ${userWrap.user.lastname}`}
                      className={classes.tooltipWrap}
                    >
                      <Avatar
                        alt={`${userWrap.user.firstname} ${userWrap.user.lastname}`}
                        src={
                          process.env.REACT_APP_API_URL + '/avatar/' + userWrap.user.uuid + '?thumb'
                        }
                        classes={{ root: classes.avatarWrap }}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              </Grid>
            </Hidden>
          )}
          {!!!message.procedure && !!!message.competitive && (
            <Hidden mdDown>
              <Grid item className={classes.shrinkAndCenter}>
                <IconButton
                  color='secondary'
                  edge='end'
                  aria-label='delete'
                  id={message.uuid + '-button-delete'}
                >
                  <DeleteIcon id={message.uuid + '-icon-delete'} />
                </IconButton>
              </Grid>
            </Hidden>
          )}
          <Hidden mdDown>
            <Grid item className={classes.timeContainer}>
              <Typography variant='subtitle2' className={classes.times} align='right'>
                <HourglassEmptyIcon fontSize='small' /> &nbsp;
                {diffInDays(message.created_at)}
              </Typography>
              <Typography variant='subtitle2' className={classes.times} align='right'>
                <UpdateIcon fontSize='small' /> &nbsp;
                {diffDate(message.updated_at)}
              </Typography>
            </Grid>
          </Hidden>
        </Grid>
      </ListItem>
    </>
  );
}
