import { Authentication } from '../lib/Authentication';
import { push } from 'connected-react-router';
import { reset as AutoRefreshReset } from './AutoRefresh';
import { reset as UserReset, fetch as userFetch } from './User';
import { reset as StyleReset, setStyling } from './Style';
import { reset as NavMenuReset } from './NavMenu';
import { reset as DimensionsReset } from './Dimensions';
import { reset as AuthenticationReset } from './Authentication';
import { reset as NotificationsReset, getNotificationsWrapper } from './Notifications';
/*
 * action types
 */

export const FETCH = 'FETCH_AUTH';
export const RESET = 'RESET_AUTH';
export const LOADING_TRUE = 'LOADING_TRUE';
export const LOADING_FALSE = 'LOADING_FALSE';
export const SETSESSION = 'SET_SESSION';
/*
 * action creators
 */
export const get = (res) => {
  return { type: FETCH, res };
};

export const reset = () => {
  return { type: RESET };
};

export const appIsLoading = () => {
  return { type: LOADING_TRUE };
};

export const appFinishedLoading = () => {
  return { type: LOADING_FALSE };
};

export const logout = (data) => {
  return async function (dispatch) {
    await fetch(process.env.REACT_APP_API_URL + '/logout', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${data}`,
      },
    })
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });

    let response = await fetch(process.env.REACT_APP_API_URL + '/api/logout', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${data}`,
      },
    })
      .then((response) => response)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    if (!!response) {
      deleteAllCookies();
      dispatch(reset());
      dispatch(AutoRefreshReset());
      dispatch(UserReset());
      dispatch(StyleReset());
      dispatch(NavMenuReset());
      dispatch(DimensionsReset());
      dispatch(AuthenticationReset());
      dispatch(NotificationsReset());
      localStorage.clear();
      return response;
    } else {
      return false;
    }
  };
};

export const login = (data) => {
  return async function (dispatch) {
    const formData = {
      username: data.email,
      password: data.password,
      grant_type: 'password',
      client_id: Authentication.client.id,
      client_secret: Authentication.client.secret,
      scope: '',
    };
    let response = await fetch(process.env.REACT_APP_API_URL + '/oauth/token', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((response) => {
        if (!!response.access_token) {
          return dispatch(get(response)) && dispatch(dataCollect(response));
        } else {
          dispatch(reset());
          dispatch(AutoRefreshReset());
          dispatch(UserReset());
          dispatch(StyleReset());
          dispatch(NavMenuReset());
          dispatch(DimensionsReset());
          dispatch(AuthenticationReset());
          dispatch(NotificationsReset());
          return response;
        }
      })
      .catch((error) => {
        dispatch(reset());
        dispatch(AutoRefreshReset());
        dispatch(UserReset());
        dispatch(StyleReset());
        dispatch(NavMenuReset());
        dispatch(DimensionsReset());
        dispatch(AuthenticationReset());
        dispatch(NotificationsReset());
        return error;
      });

    return response;
  };
};

export const setWebSession = (res) => {
  return { type: SETSESSION, res };
};

export const getWebSession = (data) => {
  return async function (dispatch) {
    console.log('[REFRESH WEB-SESSION] Begin');

    let formData = new FormData();
    formData.append('token', data.token.access_token);
    formData.append('uuid', data.user.uuid);

    return await fetch(process.env.REACT_APP_API_URL + '/auth', {
      method: 'POST',
      //headers: {
      //  	"Content-Type": "application/json",
      //},
      credentials: 'include',
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log('[REFRESH WEB-SESSION] Response:', response);
        if (response.state === 'OK') {
          return dispatch(setWebSession(response.expires));
        } else {
          console.log('[REFRESH WEB-SESSION] Error', response);
          return dispatch(setWebSession(Date.now() - 100000));
        }
      })
      .catch((error) => {
        console.log('[REFRESH WEB-SESSION] Error', error);
        return dispatch(setWebSession(Date.now() - 100000));
      });
  };
};
export const refreshLogin = () => {
  return async function (dispatch, getState) {
    let token = getState().Authentication;
    if (Date.now() - 86400000 > token.expires_at) {
      //if ( true ) { /// TESTING
      dispatch(appIsLoading());
      console.log('[RELOGIN] Begin');
      const formData = {
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
        client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
        grant_type: 'refresh_token',
        refresh_token: token.refresh_token,
        scope: '',
      };

      await fetch(process.env.REACT_APP_API_URL + '/oauth/token', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((response) => {
          if (!!response.access_token) {
            return dispatch(get(response)) && dispatch(dataCollect(response));
          } else {
            dispatch(reset());
            dispatch(AutoRefreshReset());
            dispatch(UserReset());
            dispatch(StyleReset());
            dispatch(NavMenuReset());
            dispatch(DimensionsReset());
            dispatch(AuthenticationReset());
            dispatch(NotificationsReset());
            return false;
          }
        })
        .catch((error) => {
          dispatch(reset());
          dispatch(AutoRefreshReset());
          dispatch(UserReset());
          dispatch(StyleReset());
          dispatch(NavMenuReset());
          dispatch(DimensionsReset());
          dispatch(AuthenticationReset());
          dispatch(NotificationsReset());
          return false;
        });
    } else {
      return dispatch(dataCollect(token));
    }
    return false;
  };
};

export const dataCollect = (token) => {
  return async function (dispatch, getState) {
    dispatch(NotificationsReset());
    let user = await fetch(process.env.REACT_APP_API_URL + '/api/users/my', {
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
    })
      .then((response) => response.json())
      .then(function (response) {
        dispatch(userFetch(response));
        dispatch(setStyling(response.config));
        return true;
      })
      .catch((error) => {
        console.error('[RELOGIN]', error);
        dispatch(reset());
        dispatch(AutoRefreshReset());
        dispatch(UserReset());
        dispatch(StyleReset());
        dispatch(NavMenuReset());
        dispatch(DimensionsReset());
        dispatch(AuthenticationReset());
        dispatch(NotificationsReset());
        return false;
      });

    if (await user) {
      //await sleep(3000)
      //.then( async () => {
      let notifications = await fetch(process.env.REACT_APP_API_URL + '/api/notifications', {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      })
        .then((response) => response.json())
        .then(function (response) {
          dispatch(getNotificationsWrapper(response));
          return true;
        })
        .catch((error) => {
          console.error('[RELOGIN]', error);
          dispatch(reset());
          dispatch(AutoRefreshReset());
          dispatch(UserReset());
          dispatch(StyleReset());
          dispatch(NavMenuReset());
          dispatch(DimensionsReset());
          dispatch(AuthenticationReset());
          dispatch(NotificationsReset());
          return false;
        });
      //})
      if (!!token.session_expires_at === false || token.session_expires_at <= Date.now()) {
        dispatch(getWebSession({ token: token, user: getState().User }));
      }
      let router = getState().router;
      if (['/register', '/login', '/'].includes(router.location.pathname)) {
        console.log(
          '[RELOGIN] - Redirect - Route in array: ["/register", "/login", "/"].includes ',
          router.location.pathname,
          ['/register', '/login/', '/'].includes(router.location.pathname),
        );
        dispatch(push('/dashboard'));
      }
      if (getState().Authentication.loading) {
        dispatch(appFinishedLoading());
      }
      return notifications;
    } else {
      console.error('[RELOGIN] #3', user);
      dispatch(reset());
      dispatch(AutoRefreshReset());
      dispatch(UserReset());
      dispatch(StyleReset());
      dispatch(NavMenuReset());
      dispatch(DimensionsReset());
      dispatch(AuthenticationReset());
      dispatch(NotificationsReset());
    }
  };
};

function deleteAllCookies() {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  }
}
