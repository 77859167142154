import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
//import { ThemeProvider } from '@material-ui/core/styles';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
//import CardHeader from '@material-ui/core/CardHeader';
//import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
//import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import NoteWrap from '../Notes/index';
import SmallNote from '../Notes/SmallNote';
import Hidden from '@material-ui/core/Hidden';
import Pagination from '@material-ui/lab/Pagination';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import CommentIcon from '@material-ui/icons/Comment';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import EventIcon from '@material-ui/icons/Event';
import { Constants } from '../../../lib/Constants';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
const iconHeight = 84;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
    width: '70vw',
  },
  notesCard: {
    backgroundColor: 'transparent',
    overflow: 'auto',
  },
  noPad: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  wrap: {
    whiteSpace: 'break-spaces',
  },
  wrapNote: {
    whiteSpace: 'break-spaces',
    maxHeight: '5em',
    overflow: 'hidden',
  },
  chip: {
    marginRight: theme.spacing(0.8),
    padding: theme.spacing(0.5, 1),
  },
  card: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    flexGrow: 1,
    float: 'right',
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '90vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      right: iconHeight / 1.5 + 30 + 'px',
      marginTop: '-' + iconHeight / 1.5 + 'px',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: 1001,
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  pointer: {
    cursor: 'pointer',
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  box: {
    maxHeight: '72vh',
    maxWidth: '100%',
  },
  buttongroup: {
    backgroundColor: '#fffcdd',
    '&>button': {
      backgroundColor: '#f2f2f2',
    },
    //display: 'block',
    margin: theme.spacing(-2, 0, 2, 0),
  },
  maxHeight: {
    maxHeight: '4em',
    overflow: 'unset',
    textOverflow: 'ellipsis',
  },
  dropzoneParagraph: {
    display: 'inline-flex',
  },
  dropzoneSize: {
    [theme.breakpoints.down('sm')]: {
      height: '55px',
      maxHeight: '55px',
      minHeight: '20px',
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&>div': {
        '&>svg': {
          top: '12px',
          marginLeft: '10px',
          position: 'relative',
        },
      },
    },
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      minHeight: '218px',
    },
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  dense: {
    paddingBottom: 0,
  },
  fixSize: {
    maxWidth: '100%',
    //maxHeight: '240px',
    overflowX: 'auto',
    display: 'flex',
    //flexFlow: 'row',
    flexFlow: 'column',
    margin: 0,
  },
  textfield: {
    marginBottom: '1em',
  },
  fabRight: {
    float: 'right',
    marginLeft: 'auto',
    height: '56px',
    width: '56px',
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      width: 'auto',
      borderRadius: '16px',
      padding: theme.spacing(0, 2),
    },
  },
  fabLeft: {
    float: 'left',
    marginRight: 'auto',
    height: '56px',
    width: '56px',
    [theme.breakpoints.up('sm')]: {
      height: 'auto',
      width: 'auto',
      borderRadius: '16px',
      padding: theme.spacing(0, 2),
    },
  },
  padGridY: {
    padding: theme.spacing(0, 1.2, 2, 1.2),
    overflow: 'hidden',
  },
  maximizeMe: {
    margin: theme.spacing(0, 0, 0, -2),
  },
  maxSized: {
    height: '225px',
    overflow: 'clip',
    /*
		[theme.breakpoints.up('xs')]: {
			minWidth: '50%',
			maxWidth: '50%',
		},
		[theme.breakpoints.up('md')]: {
			minWidth: '33.333333%',
			maxWidth: '33.333333%',
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: '25%',
			maxWidth: '25%',
		},
		*/
  },
  firstNote: {
    /*
		[theme.breakpoints.up('xs')]: {
			minWidth: '80%',
			maxWidth: '80%',
		},
		[theme.breakpoints.up('md')]: {
			minWidth: '55.555555%',
			maxWidth: '55.555555%',
		},
		[theme.breakpoints.up('lg')]: {
			minWidth: '45%',
			maxWidth: '45%',
		},
		*/
  },
});

class Notes extends React.Component {
  componentDidMount() {}

  constructor(props) {
    super(props);
    this.state = {
      notes: props.notes,
      selected: 0,
      isSubmitting: false,
      hasError: false,
      noteText: '',
      noteEdit: false,
      noteId: false,
      noteLink_to: [],
      drawerOpen: false,
      customFunctions: props.customFunctions || [],
      customFunctionsOpen: null,
      customFunctionDialog: false,
      customFunctionBody: null,
      customFunctionId: null,
    };
    this.toggleFabMenu = this.toggleFabMenu.bind(this);
    this.closeFabMenu = this.closeFabMenu.bind(this);
    this.toggleCustomFunctionsMenu = this.toggleCustomFunctionsMenu.bind(this);
    this.closeCustomFunctionsMenu = this.closeCustomFunctionsMenu.bind(this);
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.showNote = this.showNote.bind(this);
    this.editNote = this.editNote.bind(this);
    this.nextNote = this.nextNote.bind(this);
    this.previousNote = this.previousNote.bind(this);
    this.closeNote = this.closeNote.bind(this);

    this.callCustomFunction = this.callCustomFunction.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  editNote = () => {
    this.setState({
      noteLink_to: this.state.notes[this.state.selected].data?.link_to,
      noteText: this.state.notes[this.state.selected].data?.body,
      noteEdit: true,
    });
  };

  saveNote = async () => {
    // send to Server
    this.setState({ isSubmitting: true, hasError: false });
    var notes = this.state.notes;
    let formData = new FormData();
    formData.append('_method', 'PUT');
    if (this.state.selected !== null) {
      formData.append('uuid', notes[this.state.selected].uuid);
    }
    formData.append('body', this.state.noteText);
    formData.append('link_to', JSON.stringify(this.state.noteLink_to));

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid + '/item',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (this.state.selected === null) {
      notes.push(response);
    } else {
      notes[this.state.selected] = response;
    }

    this.setState(
      {
        isSubmitting: false,
        notes: notes,
        noteEdit: false,
        noteText: '',
        noteId: false,
        noteLink_to: [],
      },
      this.props.callbackFunction(null),
    );
  };
  deleteNote = async () => {
    // send to Server
    this.setState({ isSubmitting: true, hasError: false });
    var notes = this.state.notes;
    let formData = new FormData();
    formData.append('_method', 'DELETE');
    if (this.state.selected !== null) {
      formData.append('uuid', notes[this.state.selected].uuid);
      const response = await fetch(
        process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid + '/item/delete',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.props.Authentication.access_token}`,
          },
          body: formData,
        },
      )
        .then((response) => response.json())
        .then(function (response) {
          return response;
        })
        .catch((error) => {
          return error;
        });
      if (!!response.delete) {
        notes.splice(this.state.selected, 1);
        this.setState({
          isSubmitting: false,
          notes: notes,
          noteEdit: false,
          noteText: '',
          noteId: false,
          noteLink_to: [],
        });
      } else {
        this.setState({ isSubmitting: false, hasError: response });
      }
    }
  };
  handlePaginationChange = (event, value) => {
    this.setState({ selected: value - 1 });
  };

  handleInputChange = (event, value) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleDrawerOpen = (value) => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleUserChange = (event, value) => {
    //console.log(event, value)
    if (typeof value !== 'undefined' && typeof value === 'object') {
      this.setState({ noteLink_to: value });
    }
  };
  showNote = (index) => {
    this.setState({ selected: index });
  };
  nextNote = () => {
    this.setState({
      selected: this.state.selected === this.state.notes.length - 1 ? 0 : this.state.selected + 1,
    });
  };
  previousNote = () => {
    this.setState({
      selected: this.state.selected === 0 ? this.state.notes.length - 1 : this.state.selected - 1,
    });
  };
  closeNote = () => {
    this.setState({
      selected: null,
      noteEdit: false,
      noteText: '',
      noteId: false,
      noteLink_to: [],
    });
  };
  closeEdit = () => {
    this.setState({
      noteEdit: false,
      noteText: '',
      noteId: false,
      noteLink_to: [],
    });
  };

  openChatForm = () => {
    this.closeFabMenu();
    this.props.addChat();
  };
  openEmailForm = () => {
    this.closeFabMenu();
    this.props.addEmail();
  };
  openNoteForm = () => {
    this.closeFabMenu();
    this.props.addNote();
  };
  openAppointmentForm = () => {
    this.closeFabMenu();
    this.props.addAppointment();
  };

  toggleFabMenu(event) {
    this.setState({ fabMenuOpen: event.currentTarget });
  }

  closeFabMenu(event) {
    this.setState({ fabMenuOpen: null });
  }

  toggleCustomFunctionsMenu(event) {
    this.setState({ customFunctionsOpen: event.currentTarget });
  }

  closeCustomFunctionsMenu(event) {
    this.setState({ customFunctionsOpen: null });
  }

  async callCustomFunction(functionId, dialog) {
    if (!!dialog) {
      let index = this.state.customFunctions.findIndex((element) => element.id === functionId);
      this.setState({
        customFunctionDialog: true,
        customFunctionBody: this.state.customFunctions[index].notes,
        customFunctionId: functionId,
        customFunctionsOpen: null,
        fabMenuOpen: null,
      });
    } else {
      this.setState({
        ...this.state,
        isSubmitting: true,
        customFunctionsOpen: null,
        fabMenuOpen: null,
      });
      const uuid = this.props.match.params.uuid;
      let data = null;
      if (this.state.customFunctionBody !== '') {
        data = JSON.stringify({
          uuid: uuid,
          function: functionId,
          body: this.state.customFunctionBody,
        });
      } else {
        data = JSON.stringify({ uuid: uuid, function: functionId });
      }
      let response = await fetch(process.env.REACT_APP_API_URL + '/api/procedures/call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: data,
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          return json;
        })
        .catch((error) => {
          return false;
        });

      if (response.success) {
        this.setState(
          {
            customFunctionDialog: false,
            customFunctionBody: '',
            customFunctionId: '',
            customFunctionsOpen: null,
          },
          () => this.props.callbackFunction(true),
        );
      } else {
        console.error(response);
      }
    }
  }

  handleChange(event, value) {
    this.setState({ [event.target.name]: event.target.value });
  }

  render() {
    const { classes } = this.props;
    /* <Button onClick={this.editNote} startIcon={<EditIcon />}> Ändern </Button> */
    return (
      <>
        <Box mt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }} className={classes.notesCard}>
          <Dialog
            open={this.state.customFunctionDialog}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                this.toggleCustomFunctionDialog();
              }
            }}
            fullWidth={true}
          >
            <DialogTitle>Bitte ergänzen:</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                name='customFunctionBody'
                fullWidth
                multiline
                value={this.state.customFunctionBody}
                onChange={this.handleChange}
                variant='outlined'
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleCustomFunctionDialog} color='secondary'>
                Abbrechen
              </Button>
              <Button
                onClick={() => this.callCustomFunction(this.state.customFunctionId, false)}
                color='primary'
              >
                Speichern
              </Button>
            </DialogActions>
          </Dialog>

          <Menu
            anchorEl={this.state.fabMenuOpen}
            keepMounted
            open={Boolean(this.state.fabMenuOpen)}
            onClose={this.closeFabMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            {this.state.customFunctions.length > 0 ? (
              <MenuItem className={classes.bigger} onClick={this.toggleCustomFunctionsMenu}>
                <CallMissedOutgoingIcon className={classes.mr2} /> Schnellaktionen
              </MenuItem>
            ) : null}
            <MenuItem className={classes.bigger} onClick={this.openChatForm}>
              <AlternateEmailIcon className={classes.mr2} /> Chat
            </MenuItem>
            <MenuItem className={classes.bigger} onClick={this.openEmailForm}>
              <AlternateEmailIcon className={classes.mr2} /> E-Mail
            </MenuItem>
            <MenuItem className={classes.bigger} onClick={this.openNoteForm}>
              <CommentIcon className={classes.mr2} /> Notiz
            </MenuItem>
            <MenuItem className={classes.bigger} onClick={this.openAppointmentForm}>
              <EventIcon className={classes.mr2} /> Termin
            </MenuItem>
          </Menu>
          <Menu
            anchorEl={this.state.customFunctionsOpen}
            keepMounted
            open={Boolean(this.state.customFunctionsOpen)}
            onClose={this.closeCustomFunctionsMenu}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                maxHeight: '300px',
                maxWidth: '40ch',
              },
            }}
          >
            {this.state.customFunctions
              .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
              .map((option, index) => (
                <MenuItem
                  className={classes.bigger}
                  key={index}
                  onClick={() => this.callCustomFunction(option.id, option.dialog)}
                >
                  <ListItemIcon>{Constants.getInboxItemType(option.type).icon}</ListItemIcon>
                  {option.name}
                </MenuItem>
              ))}
          </Menu>

          {!!this.state.notes[this.state.selected] || this.state.noteEdit ? (
            !!this.state.isSubmitting ? (
              <CircularProgress />
            ) : (
              <Grid
                container
                alignItems='center'
                alignContent='center'
                justifyContent='center'
                className={classes.padGridY}
              >
                <Grid item xs={12} className={classes.maximizeMe}>
                  <NoteWrap
                    data={this.state.notes[this.state.selected]}
                    editNoteFunction={(uuid, subject, text) =>
                      this.props.editNoteFunction(uuid, subject, text)
                    }
                    respondEmail={this.props.respondEmail}
                    respondChat={this.props.respondChat}
                    index={'2123'}
                  />
                </Grid>
              </Grid>
            )
          ) : (
            <Grid container spacing={1} className={classes.fixSize}>
              {this.state.notes.map((item, index) => {
                return (
                  <Grid
                    key={'note-list-' + index}
                    item
                    className={clsx(classes.maxSized, {
                      [classes.firstNote]: index === 0,
                    })}
                  >
                    <Card raised onClick={() => this.showNote(index)}>
                      <SmallNote id={item.uuid || null} key={'item-' + index} item={item} />
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          )}
          <Grid
            container
            alignItems='center'
            alignContent='center'
            justifyContent='center'
            className={classes.padGridY}
          >
            <Hidden smDown>
              <Grid item md={3}>
                {this.state.selected !== null ? (
                  <Fab
                    color='primary'
                    aria-label='add'
                    className={classes.fabLeft}
                    onClick={() => this.setState({ selected: null })}
                  >
                    <CloseIcon /> <Hidden smDown> Zur Übersicht </Hidden>
                  </Fab>
                ) : (
                  <Fab
                    color='primary'
                    aria-label='add'
                    className={classes.fabLeft}
                    onClick={() => this.setState({ selected: 0 })}
                  >
                    <CloseIcon /> <Hidden smDown> Zurück </Hidden>
                  </Fab>
                )}
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} style={{ display: 'flex', justifyContent: 'center' }}>
              <Hidden smDown>
                <Pagination
                  size='medium'
                  color='primary'
                  count={this.state.notes.length}
                  page={this.state.selected + 1}
                  onChange={this.handlePaginationChange}
                />
              </Hidden>
              <Hidden mdUp>
                <Pagination
                  size='small'
                  color='primary'
                  count={this.state.notes.length}
                  page={this.state.selected + 1}
                  onChange={this.handlePaginationChange}
                />
              </Hidden>
            </Grid>
            <Hidden mdUp>
              <Grid item xs={7}>
                <Button
                  startIcon={<CloseIcon />}
                  color='primary'
                  onClick={() => this.setState({ selected: null })}
                  variant='contained'
                >
                  Zur Übersicht
                </Button>
              </Grid>
            </Hidden>

            <Grid item xs={5} md={3}>
              {!!this.props.User.isAdvancedUser ? (
                <Fab
                  color='primary'
                  aria-label='add'
                  className={classes.fabRight}
                  onClick={this.toggleFabMenu}
                >
                  <AddIcon /> <Hidden smDown> Neu </Hidden>
                </Fab>
              ) : (
                <Fab
                  color='primary'
                  aria-label='add'
                  className={classes.fabRight}
                  onClick={() => this.props.addNote()}
                >
                  <AddIcon /> <Hidden smDown> Neue Notiz </Hidden>
                </Fab>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Notes);
