/* eslint-disable no-unused-vars */
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import { Typography, Tooltip } from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CustomBetweenDatePicker from './CustomBetweenDatePicker';
import PropTypes from 'prop-types';
import React from 'react';

/* eslint-enable no-unused-vars */

class CustomPagination extends React.Component {
  handleFirstPageButtonClick = (event) => {
    this.props.onPageChange(event, 0);
  };

  handleBackButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = (event) => {
    this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event) => {
    this.props.onPageChange(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  };

  render() {
    //console.log("CUSTOM PAGINATION", this.props);
    const classes = this.props.classes;
    const rowsPerPage = this.props.rowsPerPage;
    const rowsPerPageOptions = this.props.rowsPerPageOptions;
    const page = this.props.page;
    const count = this.props.count;
    const onRowsPerPageChange = this.props.onRowsPerPageChange;

    const localization = {
      ...CustomPagination.defaultProps.localization,
      ...this.props.localization,
    };

    //console.log("[CustomPagination] props:", this.props.tableRef);

    return (
      <td className={classes.root} style={{ display: 'flex', height: '53px' }}>
        <div style={{ float: 'right', marginLeft: 'auto', display: 'inherit' }}>
          <Typography
            variant='caption'
            style={{
              flex: 1,
              textAlign: 'center',
              alignSelf: 'center',
              flexBasis: 'inherit',
              marginRight: '16px',
            }}
          >
            {localization.labelRowsPerPage}
          </Typography>
          <FormControl
            variant='outlined'
            className={classes.formControl}
            margin='dense'
            size='small'
          >
            <Select
              id='demo-simple-select-outlined'
              value={rowsPerPage}
              onChange={onRowsPerPageChange}
            >
              {rowsPerPageOptions.map((value, index) => (
                <MenuItem key={`select-row-count-${index}`} value={value}>
                  {value} Einträge
                </MenuItem>
              ))}

              <MenuItem key={`select-row-count-all`} value={-1}>
                Alle Einträge
              </MenuItem>
            </Select>
          </FormControl>
          {this.props.rowsPerPage === -1 ? (
            <>
              <Typography
                variant='caption'
                style={{
                  flex: 1,
                  textAlign: 'center',
                  alignSelf: 'center',
                  flexBasis: 'inherit',
                  marginLeft: '16px',
                  marginRight: '8px',
                }}
              >
                Insgesamt abgerufen:
              </Typography>

              <Typography
                variant='h5'
                style={{
                  flex: 1,
                  textAlign: 'center',
                  alignSelf: 'center',
                  flexBasis: 'inherit',
                  marginRight: '16px',
                }}
              >
                {this.props.count}
              </Typography>
            </>
          ) : (
            <div>
              <Tooltip title={localization.firstTooltip}>
                <span>
                  <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label={localization.firstAriaLabel}
                    size='medium'
                  >
                    <this.props.icons.FirstPage />
                  </IconButton>
                </span>
              </Tooltip>
              <Tooltip title={localization.previousTooltip}>
                <span>
                  <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label={localization.previousAriaLabel}
                    size='medium'
                  >
                    <this.props.icons.PreviousPage />
                  </IconButton>
                </span>
              </Tooltip>
              <Typography
                variant='caption'
                style={{
                  flex: 1,
                  textAlign: 'center',
                  alignSelf: 'center',
                  flexBasis: 'inherit',
                }}
              >
                {localization.labelDisplayedRows
                  .replace(
                    '{from}',
                    this.props.count === 0 ? 0 : this.props.page * this.props.rowsPerPage + 1,
                  )
                  .replace(
                    '{to}',
                    Math.min((this.props.page + 1) * this.props.rowsPerPage, this.props.count),
                  )
                  .replace('{count}', this.props.count)}
              </Typography>
              <Tooltip title={localization.nextTooltip}>
                <span>
                  <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label={localization.nextAriaLabel}
                    size='medium'
                  >
                    <this.props.icons.NextPage />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={localization.lastTooltip}>
                <span>
                  <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label={localization.lastAriaLabel}
                    size='medium'
                  >
                    <this.props.icons.LastPage />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          )}
        </div>
      </td>
    );
  }
}

CustomPagination.propTypes = {
  onPageChange: PropTypes.func,
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  classes: PropTypes.object,
  localization: PropTypes.object,
  theme: PropTypes.any,
  showFirstLastPageButtons: PropTypes.bool,
};

CustomPagination.defaultProps = {
  showFirstLastPageButtons: true,
  localization: {
    firstTooltip: 'First Page',
    previousTooltip: 'Previous Page',
    nextTooltip: 'Next Page',
    lastTooltip: 'Last Page',
    labelDisplayedRows: '{from}-{to} of {count}',
    labelRowsPerPage: 'Rows per page:',
  },
};

export default CustomPagination;
