import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';

import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import Chip from '@material-ui/core/Chip';
//import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'; // wait
//import WorkIcon from '@material-ui/icons/Work'; //work
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import AssignmentIcon from '@material-ui/icons/Assignment';
import GavelIcon from '@material-ui/icons//Gavel';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { DE as TextDE } from '../../../../../lib/Text';

const componentOf = {
  AssignmentIcon: <AssignmentIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  GavelIcon: <GavelIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  DateRangeIcon: <DateRangeIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
  emergency: <NewReleasesIcon style={{ color: 'inherit', backgroundColor: 'inherit' }} />,
};

const styles = (theme) => ({
  chip: {
    width: 'max-content',
    padding: theme.spacing(1.5, 1),
    marginTop: '4px',
    justifyContent: 'center',
    display: 'flex',
  },
  tooltip: {
    border: 'unset',
  },
});

class PriorityChip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: false,
      stateSelectAnchorEl: null,
      states: props.statesAll || [],
      status: props.status,
    };
  }

  handleStateSelectClose = () => {
    this.setState({ stateSelectAnchorEl: null });
  };

  handleStateSelectOpen = (event) => {
    this.setState({ stateSelectAnchorEl: event.currentTarget });
  };

  handleSetStateSelect = async (id) => {
    const newState = this.state.states.find((element) => element.id === id);

    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('priority_id', id);

    await fetch(process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.uuid, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.setState({ status: newState, stateSelectAnchorEl: null });
      })
      .catch((error) => {
        return false;
      });
  };

  render() {
    const { classes } = this.props;

    if (this.state.isFetching) {
      return <Chip size='small' className={classes.chip} label={<CircularProgress />} />;
    }

    return (
      <>
        {/* Status and ID */}
        <Tooltip
          title={TextDE.performanceReport.priorityTooltip}
          aria-label='add'
          className={classes.tooltip}
        >
          <Chip
            className={classes.chip}
            label={this.state.status.name}
            variant='outlined'
            size='small'
            icon={componentOf[this.state.status.icon]}
            style={this.state.status.style}
            onClick={this.handleStateSelectOpen}
          />
        </Tooltip>
        {!!(this.state.states.length > 0) && (
          <Menu
            anchorEl={this.state.stateSelectAnchorEl}
            keepMounted
            open={Boolean(this.state.stateSelectAnchorEl)}
            onClose={this.handleStateSelectClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            {this.state.states.map((item, index) => {
              return (
                <MenuItem key={item.id} onClick={() => this.handleSetStateSelect(item.id)}>
                  {' '}
                  {componentOf[item.icon]} {item.name}
                </MenuItem>
              );
            })}
          </Menu>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PriorityChip);
