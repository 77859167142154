import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    flexShrink: '1',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
  },
  inlineFlex: {
    display: 'inline-flex',
    color: 'inherit',
    backgroundColor: 'inherit',
    borderColor: theme.palette.text.primary,
    borderStyle: 'solid',
    borderWidth: '2px',
    '&>svg': {
      width: '85%',
      height: '85%',
    },
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
  },
});

class TitleIcon extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.wrapper}>
          {!!this.props.icon && (
            <div className={classes.icon}>
              <Avatar className={classes.inlineFlex}>{this.props.icon}</Avatar>
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ Theme: state.Style.Theme });

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TitleIcon);
