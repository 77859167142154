import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { updateCompany } from '../../actions/User';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import DeleteIcon from '@material-ui/icons/Delete';
//import LockIcon from '@material-ui/icons/Lock';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';

import Chip from '@material-ui/core/Chip';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { DE as TextDE } from '../../lib/Text';

// ERROR
//import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  chip: {
    margin: theme.spacing(0, 1, 1, 0),
    maxWidth: '100%',
  },
  flex: {
    display: 'flex',
  },
  noMargin: {
    marginTop: '0px',
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  buttonSpacing: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class UserRoles extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({ isSubmitting: true, hasError: false });
    let formData = {};
    Object.keys(this.state).forEach((value, index) => {
      if (typeof this.state[value] === 'string' || typeof this.state[value] === 'boolean') {
        formData[value] = this.state[value];
      }
    });
    formData['_method'] = 'PUT';

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/companies', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response.company) {
      this.setState({ isSubmitting: false, hasError: false, data: response });
      this.props.dispatch(updateCompany(response));
    } else {
      this.setState({ isSubmitting: false, hasError: true, data: response });
    }
  }

  handleWorkingHoursChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (event.target.type === 'checkbox') {
      this.setState({
        ...this.state,
        addTag: {
          ...this.state.addTag,
          [event.target.name]: event.target.checked,
        },
      });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({
        ...this.state,
        addTag: {
          ...this.state.addTag,
          [event.target.name]: event.target.value,
        },
      });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  generatePassword(passwordLength) {
    var numberChars = '0123456789';
    var upperChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var lowerChars = 'abcdefghijklmnopqrstuvwxyz';
    var symbols = '#?!@$%^&*-';
    var allChars = numberChars + upperChars + lowerChars + symbols;
    var randPasswordArray = Array(passwordLength);
    randPasswordArray[0] = numberChars;
    randPasswordArray[1] = upperChars;
    randPasswordArray[2] = lowerChars;
    randPasswordArray[3] = symbols;
    randPasswordArray = randPasswordArray.fill(allChars, 3);
    return this.shuffleArray(
      randPasswordArray.map(function (x) {
        return x[Math.floor(Math.random() * x.length)];
      }),
    ).join('');
  }

  shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  openUserAddDialog = () => {
    let password = this.generatePassword(12);
    this.setState({
      ...this.state,
      addUser: {
        ...this.state.addUser,
        open: true,
        validationMessage: [],
        validation: true,
        password: password,
      },
    });
  };

  handleChangeAddUser = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (event.target.type === 'checkbox') {
      this.setState({
        ...this.state,
        addUser: {
          ...this.state.addUser,
          [event.target.name]: event.target.checked,
        },
      });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      if (event.target.name === 'email') {
        let emailValid = false;
        //eslint-disable-next-line
        var validRegex = /^[\w\-\.]+@([\w\-]+\.)+[\w-]{2,4}$/;
        emailValid = !!event.target.value.match(validRegex);
        this.setState({
          ...this.state,
          addUser: {
            ...this.state.addUser,
            [event.target.name]: event.target.value,
            emailValid: emailValid,
          },
        });
      } else {
        this.setState({
          ...this.state,
          addUser: {
            ...this.state.addUser,
            [event.target.name]: event.target.value,
          },
        });
      }
      //dispatch({ [event.target.name]: event.target.value });
    }
  };

  handlePasswordChange = (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    let validated = false;
    let validationMessage = [];
    let tmpPassword = event.target.value;
    tmpPassword = tmpPassword.trim();
    if (tmpPassword.indexOf(' ') >= 0) {
      validationMessage.push('Bitte keine Leerzeichen im Passwort!');
    } else {
      //let re = /^.*(?=.{3,})(?=.*[a-zA-ZäöüÄÖÜ])(?=.*[0-9])(?=.*[\d\X])(?=.*[!$#%]).*$/
      let reCaps = /[A-ZÄÖÜ]/;
      let reSmall = /[a-zäöü]/;
      let reDigit = /[0-9]/;
      //let reWhitespace = /\[0-9]/;
      let reCode = /[#?!@$%^&*-]/;
      let reFull = /^(?=.*?[A-ZÄÖÜ])(?=.*?[a-zäöü])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

      if (!tmpPassword.match(reCaps)) {
        validationMessage.push('Es fehlt mindestens 1 Großbuchstabe (ABC...)');
      }
      if (!tmpPassword.match(reSmall)) {
        validationMessage.push('Es fehlt mindestens 1 Kleinbuchstabe (abc...)');
      }
      if (!tmpPassword.match(reDigit)) {
        validationMessage.push('Es fehlt mindestens 1 Zahl (123...)');
      }
      if (!tmpPassword.match(reCode)) {
        validationMessage.push('Es fehlt mindestens 1 Sonderzeichen (!$#%...)');
      }
      if (tmpPassword.length < 8) {
        validationMessage.push(
          'Das Passwort ist zu kurz, bitte noch mindestens ' +
            (8 - event.target.value.length) +
            ' Zeichen',
        );
      }
      if (tmpPassword.match(reFull)) {
        validated = true;
      }
    }
    this.setState({
      ...this.state,
      addUser: {
        ...this.state.addUser,
        validationMessage: validationMessage,
        validation: validated,
        [event.target.name]: tmpPassword,
      },
    });
  };

  handleAddMoreRolesClick = () => (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleCloseMoreRolesClick = () => (event) => {
    this.setState({ anchorEl: null });
  };

  async handleAppendRoleToUser(uuid, role) {
    const formData = { _method: 'PUT', role: role };
    //console.log(process.env.REACT_APP_API_URL + '/api/users/roles/'+uuid, formData)
    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/users/roles/' + this.state.anchorEl.id,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify(formData),
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response.name) {
      let tmp = this.state;
      const index = tmp.users.findIndex((element) => element.uuid === this.state.anchorEl.id);
      tmp.users[index].roles.push(response);
      this.setState({ tmp, anchorEl: null });
    } else if (!!response.state && response.state === 'Already Set') {
      alert('Rolle bereits zugewiesen.');
      this.setState({ anchorEl: null });
    }
  }

  async handleDeleteRoleFromUser(uuid, role) {
    const formData = { _method: 'DELETE', role: role };
    //console.log(process.env.REACT_APP_API_URL + '/api/users/roles/'+uuid, formData)

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/roles/' + uuid, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.name) {
      let tmp = this.state;
      const index = tmp.users.findIndex((element) => element.uuid === uuid);
      tmp.users[index].roles = this.state.users[index].roles.filter(
        (element) => element.id !== role,
      );
      this.setState({ tmp });
    } else if (!!response.error) {
      console.log('error', response.error);
      this.setState({ error: response });
    }
  }

  async handleAddUserClick(event) {
    event.preventDefault();
    let formData = this.state.addUser;
    formData.type = '0';
    formData.invitation = this.props.User.uuid;
    formData['password_confirmation'] = formData.password;

    //let errors = [];
    const response = await fetch(process.env.REACT_APP_API_URL + '/api/register', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        this.setState({
          ...this.state,
          addUser: {
            validationMessage: [],
            open: false,
            email: '',
            emailValid: false,
            firstname: '',
            lastname: '',
            password: '',
          },
        });
        return true;
      })
      .catch((error) => {
        error
          .json()
          .then((data) => {
            this.setState({
              ...this.state,
              addUser: {
                ...this.state.addUser,
                validationMessage: data.errors,
              },
            });
          })
          .catch((errorObject) => {
            console.warn('[error Catch] Response from Server...', errorObject);
          });
        return false;
      });

    if (response) {
      fetch(process.env.REACT_APP_API_URL + '/api/users', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((resJson) => {
          if (!!resJson.error) {
            this.setState({
              ...this.state,
              error: resJson.error,
            });
          } else {
            this.setState({
              ...this.state,
              addUser: {
                validationMessage: [],
                open: false,
                email: '',
                emailValid: false,
                firstname: '',
                lastname: '',
                password: '',
              },
              users: resJson,
            });
          }
          return resJson;
        })
        .catch((error) => {
          return error;
        });
    }
  }

  passwordReset = (uuid) => {
    console.log('passwordReset', uuid);
  };

  userDelete = async (uuid) => {
    const formData = { uuid: uuid };
    //console.log(process.env.REACT_APP_API_URL + '/api/users/roles/'+uuid, formData)

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/' + uuid, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response.deleted) {
      let tmp = this.state;
      const index = tmp.users.findIndex((element) => element.uuid === uuid);
      tmp.users[index].deleted_at = new Date();
      this.setState({ tmp });
      if (uuid === this.props.User.uuid) {
        this.props.dispatch(push('/login'));
      }
    } else if (!!response.error) {
      this.setState({ error: response });
    }
  };

  userRestore = async (uuid) => {
    const formData = { _method: 'PUT', restore: true, uuid: uuid };
    //console.log(process.env.REACT_APP_API_URL + '/api/users/roles/'+uuid, formData)

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/' + uuid, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response.success) {
      let tmp = this.state;
      const index = tmp.users.findIndex((element) => element.uuid === uuid);
      tmp.users[index].deleted_at = false;
      this.setState({ tmp });
    }
  };

  async handleAppendTagToUser(uuid, tag) {
    const formData = { _method: 'PUT', uuid: uuid, tag: tag };
    //console.log(process.env.REACT_APP_API_URL + '/api/users/roles/'+uuid, formData)

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/tags/' + uuid, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response.name) {
      let tmp = this.state;
      const index = tmp.users.findIndex((element) => element.uuid === uuid);
      tmp.users[index].tags.push(response);
      this.setState({ tmp, addTag: { user: {}, open: false } });
    } else if (!!response.state && response.state === 'Already Set') {
      alert('Tag bereits zugewiesen.');
      this.setState({ addTag: { user: {}, open: false } });
    }
  }

  async handleRemoveTagFromUser(uuid, tag) {
    const formData = { _method: 'DELETE', uuid: uuid, tag: tag };
    //console.log(process.env.REACT_APP_API_URL + '/api/users/roles/'+uuid, formData)

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/users/tags/' + uuid, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });
    if (!!response.removed) {
      let tmp = this.state;
      const index = tmp.users.findIndex((element) => element.uuid === uuid);
      tmp.users[index].tags = this.state.users[index].tags.filter(
        (element) => element.id !== response.removed,
      );
      this.setState({ tmp });
    }
  }

  handleCloseMoreTagsClick = () => (event) => {
    this.setState({ ...this.state, addTag: { user: {}, open: false } });
  };

  handleCloseAddUserClick = () => (event) => {
    this.setState({
      ...this.state,
      addUser: {
        validationMessage: [],
        open: false,
        email: '',
        emailValid: false,
        firstname: '',
        lastname: '',
        password: '',
      },
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      hasError: false,
      users: [],
      package: false,
      roles: [],
      addTag: { user: {}, open: false },
      addUser: {
        validationMessage: [],
        open: false,
        email: '',
        emailValid: false,
        firstname: '',
        lastname: '',
        password: '',
      },
      passwordVisible: true,
      anchorEl: null,

      dialogWorkingTimeSubmitting: false,
      dialogWorkingTimeOpen: false,
      targetTime: 0,
      illnessTime: 0,
      vacationTime: 0,
      user: null,
      workingTimeErrors: [],
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAddUserClick = this.handleAddUserClick.bind(this);

    this.handleWorkingHoursFormSubmit = this.handleWorkingHoursFormSubmit.bind(this);
    this.openWorkingHoursDialog = this.openWorkingHoursDialog.bind(this);
    this.closeWorkingHoursDialog = this.closeWorkingHoursDialog.bind(this);
    this.fetchUsers = this.fetchUsers.bind(this);
    this.handleWorkingHoursChange = this.handleWorkingHoursChange.bind(this);
  }

  handleWorkingHoursFormSubmit() {
    let url = process.env.REACT_APP_API_URL + '/api/users/hours/' + this.state.user.uuid;
    let formData = {};
    formData['_method'] = 'PUT';
    formData['user_id'] = this.state.user.uuid;
    formData['target_time'] = this.state.targetTime ?? 0;
    formData['illness_time'] = this.state.illnessTime ?? formData['target_time'];
    formData['vacation_time'] = this.state.vacationTime ?? formData['target_time'];

    fetch(url, {
      method: formData['_method'],
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        //if (res.ok) {
        return res.json();
        //} else {
        //  throw res;
        //}
      })
      .then((json) => {
        //console.log('[onSubmit]', json);
        if (!!json.errors && Object.values(json.errors).length > 0) {
          console.log('[onSubmit] has Errors', json.errors);
          this.setState({ dialogWorkingTimeSubmitting: false, workingTimeErrors: json.errors });
        } else {
          this.fetchUsers();
          this.closeWorkingHoursDialog();
        }
      })
      .catch((error) => {
        console.error('[onSubmit][ERROR]', error);
      });
  }

  closeWorkingHoursDialog() {
    this.setState({
      dialogWorkingTimeOpen: false,
      workingTimeErrors: [],
      targetTime: 0,
      illnessTime: 0,
      vacationTime: 0,
      user: null,
      dialogWorkingTimeSubmitting: false,
    });
  }

  openWorkingHoursDialog(user) {
    this.setState({
      dialogWorkingTimeOpen: true,
      workingTimeErrors: [],
      targetTime: user.working_hours.target_time ?? 0,
      illnessTime: user.working_hours.illness_time ?? 0,
      vacationTime: user.working_hours.vacation_time ?? 0,
      user: user,
      dialogWorkingTimeSubmitting: false,
    });
  }

  fetchUsers() {
    fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        if (!!resJson.error) {
          this.setState({ error: resJson.error, isFetching: false });
        }
        this.setState({ users: resJson, isFetching: false });
      })
      .catch((error) => {
        return error;
      });
  }
  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/package', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        if (!!resJson.package) {
          this.setState({ package: resJson.package });
        }
        return resJson;
      })
      .catch((error) => {
        return error;
      });

    fetch(process.env.REACT_APP_API_URL + '/api/roles', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((resJson) => {
        if (!!resJson.error) {
          this.setState({ error: resJson.error });
        }
        this.setState({ roles: resJson });
        return resJson;
      })
      .catch((error) => {
        return error;
      });
    //.then( errResponse => errResponse.json() )
    //.then( errResponse =>  this.setState({error: errResponse }) );
    //

    //
    this.fetchUsers();
    //.then( errResponse => errResponse.json() )
    //.then( errResponse =>  this.setState({error: errResponse }) );
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root} square>
          <Dialog
            open={this.state.addTag?.open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                this.handleCloseMoreTagsClick();
              }
            }}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>Gruppe/Tag hinzufügen</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Diese Gruppen werden genutzt um z.B später Filter oder Automatismen einzurichten.
              </DialogContentText>
              <Typography p='0' variant='subtitle1'>
                Bisher:
              </Typography>
              <ul>
                {!!this.state.addTag.user.tags
                  ? Object.keys(this.state.addTag.user.tags).map((key) => (
                      <li key={this.state.addTag.user.uuid + '-' + key}>
                        {this.state.addTag.user.tags[key].name.de}
                      </li>
                    ))
                  : ''}
              </ul>
              <TextField
                autoFocus
                margin='dense'
                name='tag'
                label={
                  'Gruppenbezeichnung für ' +
                  (this.state.addTag.user.firstname ?? '') +
                  ' ' +
                  (this.state.addTag.user.lastname ?? '')
                }
                type='text'
                onChange={this.handleChange()}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleCloseMoreTagsClick()} color='secondary'>
                schließen
              </Button>
              <Button
                onClick={() =>
                  this.handleAppendTagToUser(this.state.addTag.user.uuid, this.state.addTag.tag)
                }
                color='primary'
              >
                Hinzufügen
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.dialogWorkingTimeOpen}
            onClose={this.closeWorkingHoursDialog}
            aria-labelledby='form-dialog-title'
          >
            <DialogTitle id='form-dialog-title'>{TextDE.timetracking.title}</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} alignItems='center'>
                <Grid item xs={12} md={4}>
                  {TextDE.timetracking.targetTimeLabel}
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    label={TextDE.timetracking.targetTimeLabel}
                    type='number'
                    value={this.state.targetTime}
                    name='targetTime'
                    fullWidth
                    helperText={TextDE.timetracking.targetTimeHelper}
                    size='small'
                    variant='outlined'
                    onChange={this.handleWorkingHoursChange}
                    inputProps={{ step: 15, min: 0 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Minuten</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>
                    {Number.parseInt(this.state.targetTime / 60)
                      .toString()
                      .padStart(2, '0')}
                    :
                    {Number.parseInt(this.state.targetTime % 60)
                      .toString()
                      .padStart(2, '0')}{' '}
                    HH:MM
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  {TextDE.timetracking.vacationTimeLabel}
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    label={TextDE.timetracking.vacationTimeLabel}
                    type='number'
                    value={this.state.vacationTime}
                    name='vacationTime'
                    fullWidth
                    helperText={TextDE.timetracking.vacationTimeHelper}
                    size='small'
                    variant='outlined'
                    onChange={this.handleWorkingHoursChange}
                    inputProps={{ step: 15, min: 0 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Minuten</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>
                    {Number.parseInt(this.state.vacationTime / 60)
                      .toString()
                      .padStart(2, '0')}
                    :
                    {Number.parseInt(this.state.vacationTime % 60)
                      .toString()
                      .padStart(2, '0')}{' '}
                    HH:MM
                  </Typography>
                </Grid>

                <Grid item xs={12} md={4}>
                  {TextDE.timetracking.illnessTimeLabel}
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    label={TextDE.timetracking.illnessTimeLabel}
                    type='number'
                    value={this.state.illnessTime}
                    name='illnessTime'
                    fullWidth
                    helperText={TextDE.timetracking.illnessTimeHelper}
                    size='small'
                    variant='outlined'
                    onChange={this.handleWorkingHoursChange}
                    inputProps={{ step: 15, min: 0 }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>Minuten</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography>
                    {Number.parseInt(this.state.illnessTime / 60)
                      .toString()
                      .padStart(2, '0')}
                    :
                    {Number.parseInt(this.state.illnessTime % 60)
                      .toString()
                      .padStart(2, '0')}{' '}
                    HH:MM
                  </Typography>
                </Grid>

                {!!this.state.workingTimeErrors &&
                  Object.values(this.state.workingTimeErrors).length > 0 &&
                  Object.values(this.state.workingTimeErrors).map((error, index) => (
                    <Grid key={'error-alerts-' + index} item xs={12}>
                      <Alert severity='error'>{error}</Alert>
                    </Grid>
                  ))}
              </Grid>
            </DialogContent>
            {!!this.state.dialogWorkingTimeSubmitting && (
              <center>
                <CircularProgress />
              </center>
            )}
            <DialogActions>
              <Button
                onClick={this.closeWorkingHoursDialog}
                className={classes.moveLeft}
                color='secondary'
              >
                {TextDE.close}
              </Button>
              <Button
                onClick={this.handleWorkingHoursFormSubmit}
                color='primary'
                variant='contained'
              >
                {TextDE.save}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.addUser?.open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                this.handleCloseAddUserClick();
              }
            }}
            aria-labelledby='form-dialog-title'
          >
            <form className={classes.form} onSubmit={this.handleAddUserClick}>
              <DialogTitle id='form-dialog-title'>Mitarbeiter hinzufügen</DialogTitle>
              <DialogContent>
                <DialogContentText color='textPrimary'>
                  Einen weiteren Mitarbeiter hinzufügen (Derzeit: {this.state.users.length} / Limit:{' '}
                  {this.state.package.user})
                </DialogContentText>
                <Grid container spacing={2}>
                  <Grid item zeroMinWidth xs={12} md={6} lg={6}>
                    <TextField
                      autoComplete='fname'
                      name='firstname'
                      value={this.state.addUser.firstname}
                      onChange={this.handleChangeAddUser}
                      variant='outlined'
                      required
                      fullWidth
                      id='firstname'
                      label='Vorname'
                      autoFocus
                      error={this.state.addUser.firstname.length < 1}
                    />
                  </Grid>
                  <Grid item zeroMinWidth xs={12} md={6} lg={6}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      value={this.state.addUser.lastname}
                      onChange={this.handleChangeAddUser}
                      id='lastname'
                      label='Nachname'
                      name='lastname'
                      autoComplete='lname'
                      error={this.state.addUser.lastname.length < 1}
                    />
                  </Grid>
                  <Grid item zeroMinWidth xs={12}>
                    <TextField
                      variant='outlined'
                      required
                      fullWidth
                      id='email'
                      label='E-Mail Adresse'
                      helperText={
                        !!!this.state.addUser.emailValid
                          ? 'Bitte eine korrekte E-Mail eintragen'
                          : ''
                      }
                      error={!!!this.state.addUser.emailValid}
                      name='email'
                      autoComplete='email'
                      value={this.state.addUser.email}
                      onChange={this.handleChangeAddUser}
                    />
                  </Grid>
                  <Grid item zeroMinWidth xs={12}>
                    <FormControl variant='outlined' fullWidth required>
                      <InputLabel htmlFor='outlined-adornment-password'>Passwort</InputLabel>
                      <OutlinedInput
                        name='password'
                        id='password'
                        autoComplete='current-password'
                        type={!!this.state.passwordVisible ? 'text' : 'password'}
                        onChange={this.handlePasswordChange}
                        value={this.state.addUser.password}
                        endAdornment={
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() =>
                                this.setState({
                                  passwordVisible: !this.state.passwordVisible,
                                })
                              }
                              edge='end'
                            >
                              {!!this.state.passwordVisible ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        labelWidth={70}
                      />
                    </FormControl>
                  </Grid>

                  {(!!this.state.addUser.validationMessage ||
                    this.state.addUser.validation === false) &&
                  this.state.addUser.validationMessage.length > 0 ? (
                    <Grid item zeroMinWidth xs={12}>
                      {this.state.addUser.validationMessage.map((value, key) => (
                        <Alert key={'error-msg-' + key} severity='error' closeText='Schließen'>
                          {' '}
                          {value}{' '}
                        </Alert>
                      ))}
                    </Grid>
                  ) : null}

                  <DialogContentText color='textPrimary'>
                    <Typography variant='button' color='inherit' display='block'>
                      Hinweis:
                    </Typography>
                    Bitte das Passwort an den Mitarbeiter weitergeben, oder via{' '}
                    <strong>"Passwort Vergessen"</strong> ein neues beantragen lassen!
                  </DialogContentText>
                </Grid>
              </DialogContent>
              <DialogActions className={classes.buttonSpacing}>
                <Button
                  onClick={this.handleCloseAddUserClick()}
                  color='secondary'
                  variant='outlined'
                >
                  Schließen
                </Button>
                <Button
                  type='submit'
                  color='primary'
                  size='large'
                  variant='contained'
                  disabled={
                    !(
                      !!this.state.addUser.firstname &&
                      !!this.state.addUser.lastname &&
                      !!this.state.addUser.emailValid &&
                      !!this.state.addUser.validation
                    )
                  }
                >
                  Mitarbeiter Hinzufügen
                </Button>
              </DialogActions>
            </form>
          </Dialog>
          {this.state.isFetching ? (
            <center style={{ minHeight: '30vh' }}>
              <CircularProgress style={{ marginTop: '13vh' }} />
            </center>
          ) : (
            <List>
              {!!this.state.error ? (
                <Alert severity='error' closeText='Schließen'>
                  {' '}
                  {this.state.error.error}{' '}
                </Alert>
              ) : null}
              {Object.keys(this.state.users).map((key) => {
                let value = this.state.users[key];
                return (
                  <ListItem disableGutters divider key={'user_' + value.uuid}>
                    <Grid
                      container
                      spacing={2}
                      className={!!value.deleted_at ? classes.deleted : null}
                    >
                      <Grid item className={classes.flex} zeroMinWidth xs={'auto'} md={4} lg={2}>
                        <ListItemAvatar>
                          <Avatar
                            className={classes.avatar}
                            alt={value.firstname + ' ' + value.lastname}
                            src={process.env.REACT_APP_API_URL + '/avatar/' + value.uuid + '?thumb'}
                          >
                            {(
                              value.firstname.slice(0, 1) + value.lastname.slice(0, 1)
                            ).toUpperCase()}
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          className={classes.noMargin}
                          primary={value.firstname + ' ' + value.lastname}
                          secondary={value.email}
                        />
                      </Grid>

                      {!!value.deleted_at && (
                        <Alert
                          severity='error'
                          variant='filled'
                          style={{ flexGrow: '1', marginTop: 'auto', marginBottom: 'auto' }}
                        >
                          Der User wurde deaktivert! Ein Login ist damit nicht mehr möglich!
                        </Alert>
                      )}

                      {!!!value.deleted_at && (
                        <Grid item zeroMinWidth xs={12} md={4} lg={6}>
                          {/* Rollen Chips/Bullets */}
                          <ListItemText
                            className={classes.noMargin}
                            disableTypography={true}
                            primary={<Typography variant='subtitle1'>Rollen: </Typography>}
                            secondary={
                              <span>
                                {Object.keys(value.roles).map((key_2) => {
                                  return (
                                    <Chip
                                      className={classes.chip}
                                      key={value.uuid + '_' + value.roles[key_2].id}
                                      label={value.roles[key_2].name}
                                      onDelete={
                                        this.props.User.isAdmin
                                          ? () =>
                                              this.handleDeleteRoleFromUser(
                                                value.uuid,
                                                value.roles[key_2].id,
                                              )
                                          : null
                                      }
                                      color='primary'
                                      value={value.roles[key_2].id}
                                    />
                                  );
                                })}
                                {this.props.User.isAdmin && (
                                  <React.Fragment>
                                    <Chip
                                      className={classes.chip}
                                      id={value.uuid}
                                      label='Weitere?'
                                      onClick={this.handleAddMoreRolesClick()}
                                      icon={<AddIcon />}
                                    />
                                    <Menu
                                      anchorEl={this.state.anchorEl}
                                      keepMounted
                                      open={Boolean(this.state.anchorEl)}
                                      onClose={() => this.setState({ anchorEl: null })}
                                    >
                                      {Object.keys(this.state.roles).map((roleKey) => (
                                        <MenuItem
                                          key={this.state.roles[roleKey]['id']}
                                          value={this.state.roles[roleKey]['id']}
                                          onClick={() =>
                                            this.handleAppendRoleToUser(
                                              value.uuid,
                                              this.state.roles[roleKey]['id'],
                                            )
                                          }
                                        >
                                          {this.state.roles[roleKey]['name']}
                                        </MenuItem>
                                      ))}
                                      <MenuItem
                                        key={'close'}
                                        onClick={() => this.setState({ anchorEl: null })}
                                      >
                                        Schließen
                                      </MenuItem>
                                    </Menu>
                                  </React.Fragment>
                                )}
                              </span>
                            }
                          />
                        </Grid>
                      )}

                      {!!!value.deleted_at && (
                        <Grid item zeroMinWidth xs={12} md={3} lg={3}>
                          <ListItemText
                            disableTypography={true}
                            primary={
                              <Typography
                                component='ul'
                                style={{ listStyle: 'none', padding: '0 0 6px 0' }}
                              >
                                <li style={{ display: 'flex' }}>
                                  {TextDE.timetracking.targetTimeLabel}:
                                  <b style={{ marginLeft: 'auto' }}>
                                    {Number.parseInt(value.working_hours.target_time / 60)
                                      .toString()
                                      .padStart(2, '0')}
                                    :
                                    {Number.parseInt(value.working_hours.target_time % 60)
                                      .toString()
                                      .padStart(2, '0')}
                                  </b>
                                  &nbsp;HH:MM
                                </li>
                                <li style={{ display: 'flex' }}>
                                  {TextDE.timetracking.vacationTimeLabel}:
                                  <b style={{ marginLeft: 'auto' }}>
                                    {Number.parseInt(value.working_hours.vacation_time / 60)
                                      .toString()
                                      .padStart(2, '0')}
                                    :
                                    {Number.parseInt(value.working_hours.vacation_time % 60)
                                      .toString()
                                      .padStart(2, '0')}
                                  </b>
                                  &nbsp;HH:MM
                                </li>
                                <li style={{ display: 'flex' }}>
                                  {TextDE.timetracking.illnessTimeLabel}:
                                  <b style={{ marginLeft: 'auto' }}>
                                    {Number.parseInt(value.working_hours.illness_time / 60)
                                      .toString()
                                      .padStart(2, '0')}
                                    :
                                    {Number.parseInt(value.working_hours.illness_time % 60)
                                      .toString()
                                      .padStart(2, '0')}
                                  </b>
                                  &nbsp;HH:MM
                                </li>
                              </Typography>
                            }
                            secondary={
                              !!this.props.User.isAdmin && (
                                <Button
                                  fullWidth
                                  variant='contained'
                                  onClick={() => this.openWorkingHoursDialog(value)}
                                >
                                  Arbeitszeit eintragen
                                </Button>
                              )
                            }
                          />
                        </Grid>
                      )}

                      {this.props.User.isAdmin ? (
                        <Grid item zeroMinWidth xs={12} md={1} lg={1}>
                          <ListItemSecondaryAction className={classes.noMargin}>
                            {/* <IconButton */}
                            {/* 	edge="end" */}
                            {/* 	aria-label="reset password" */}
                            {/* 	onClick={() => this.passwordReset(value.uuid)}*/}
                            {/* >*/}
                            {/* 	<LockIcon />*/}
                            {/* </IconButton>*/}
                            {!!value.deleted_at ? (
                              <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => this.userRestore(value.uuid)}
                              >
                                <SettingsBackupRestoreIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge='end'
                                aria-label='delete'
                                onClick={() => this.userDelete(value.uuid)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </ListItemSecondaryAction>
                        </Grid>
                      ) : null}
                    </Grid>
                  </ListItem>
                );
              })}
              {this.props.User.isAdmin && this.state.users.length < this.state.package.user ? (
                <ListItem
                  disableGutters
                  style={{ marginTop: '10px' }}
                  button={true}
                  alignItems='center'
                  onClick={this.openUserAddDialog}
                  key='addUser'
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <AddIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText primary='Weiteren User hinzufügen' />
                </ListItem>
              ) : (
                <ListItem
                  disableGutters
                  style={{ marginTop: '10px' }}
                  button={true}
                  alignItems='center'
                  onClick={() => this.props.dispatch(push('/settings/account'))}
                  key='addUser'
                  color='error'
                >
                  <ListItemAvatar>
                    <Avatar className={classes.avatar}>
                      <AddIcon />
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary='Weiteren User hinzufügen'
                    secondary='Ihr derzeit gebuchte Paket lässt maximal {this.state.package.user} User zu - bitte wechseln Sie das Paket'
                  />
                </ListItem>
              )}
            </List>
          )}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(UserRoles);
