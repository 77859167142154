import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
//import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
//import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import Badge from '@material-ui/core/Badge';
import CloseIcon from '@material-ui/icons/Close';
//import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ShareIcon from '@material-ui/icons/Share';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
//import Modal from '@material-ui/core/Modal';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DropzoneArea } from 'material-ui-dropzone';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

//import { Document } from 'react-pdf';
//import { pdfjs } from 'react-pdf';
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const iconHeight = 84;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
    width: '70vw',
  },
  card: {
    height: 'inherit',
    flexGrow: 1,
  },
  cardcontentUpload: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  chip: {
    marginRight: theme.spacing(0.8),
    padding: theme.spacing(0.5, 1),
  },
  cardHeaderGrid: {
    width: 'auto',
    padding: '4px',
    '&>div': {
      width: '100%',
    },
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundColor: 'grey',
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  button: {
    flexGrow: 1,
    float: 'right',
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '90vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      right: iconHeight / 1.5 + 30 + 'px',
      marginTop: '-' + iconHeight / 1.5 + 'px',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: 1001,
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  pointer: {
    cursor: 'pointer',
    flexGrow: 1,
    justifyContent: 'center',
    display: 'flex',
  },
  box: {
    maxHeight: '72vh',
    maxWidth: '100%',
  },
  buttongroup: {
    backgroundColor: '#ffffff',
    '&>button': {
      backgroundColor: '#f2f2f2ed',
    },
  },
  dropzoneParagraph: {
    display: 'inline-flex',
  },
  dropzoneSize: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&>div': {
        '&>svg': {
          top: '12px',
          marginLeft: '10px',
          position: 'relative',
        },
      },
    },
    minHeight: '140px',
    maxHeight: '140px',
    [theme.breakpoints.up('md')]: {
      minHeight: '165px',
      maxHeight: '165px',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: '194px',
      maxHeight: '194px',
    },
    '&>div': {
      '&>p': {
        fontSize: '16px',
      },
    },
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  fixSize: {
    maxWidth: '100%',
    //maxHeight: '100%',
    overflow: 'auto',
    flexFlow: 'row',
    flexWrap: 'wrap',
    margin: 0,
    //[theme.breakpoints.up('md')]: {
    //	maxHeight: '40vh',
    //}
  },

  viewerBox: {
    /*
		height: '100vh',
		width: '100vw',
		position: 'fixed',
		overflow: 'none',
		top: 0,
		left: 0,
		padding: 0,
		zIndex: 1298,
		backgroundColor: 'rgba(0,0,0,0.87)',
		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
		*/
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(0, 1),
    },
  },
  viewerContent: {
    [theme.breakpoints.up('md')]: {
      marginTop: 'calc(50vh - ' + theme.spacing(2) + 'px)',
      transform: 'translate(0, -50%)',
      //height: '60vh',
      width: 'calc(100% - ' + theme.spacing(4) + 'px)',
    },
    width: 'auto',
    //height: 'calc(100vh - '+ (60 + theme.spacing(4))+'px)',
    height: 'auto',
    margin: '60px 0 0 0',
    padding: 0,
    overflow: 'hidden',
    zIndex: 1299,
  },
  sizeMe: {
    width: '95vw',
    height: '67.5vh',

    [theme.breakpoints.up('sm')]: {
      width: theme.breakpoints.values.sm,
    },
    [theme.breakpoints.up('md')]: {
      height: '60vh',
      width: theme.breakpoints.values.md,
    },
    [theme.breakpoints.up('lg')]: {
      width: theme.breakpoints.values.lg,
    },
  },
  buttonbox: {
    display: 'flex',
    justifyContent: 'center',
  },
  cardcontent: {
    padding: 0,
    minHeight: '197px',
    height: 'auto',
  },
  uploadTile: {
    height: '200px',
    overflow: 'clip',
    width: '100%',
    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      height: '225px',
      width: '66.666666%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      height: '260px',
    },
  },
  maxSized: {
    height: '200px',
    overflow: 'clip',
    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: '33.333333%',
    },
    [theme.breakpoints.up('lg')]: {
      height: '260px',
      width: '25%',
    },
  },
  sizedIcons: {
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(4),
      width: theme.spacing(4),
    },
  },
});

class FileCard extends React.Component {
  constructor(props) {
    super(props);

    let tmp = [];
    props.files.inbox.forEach((item, index) => {
      item.url =
        process.env.REACT_APP_API_URL +
        '/auth/file/inboxitem/' +
        props.uuid +
        '/' +
        item.id +
        '/' +
        item.file_name;
      item.download =
        process.env.REACT_APP_API_URL +
        '/api/file/inboxitem/' +
        props.uuid +
        '/' +
        item.id +
        '/' +
        item.file_name;
      tmp.push(item);
    });

    props.files.procedure.forEach((item, index) => {
      item.url =
        process.env.REACT_APP_API_URL +
        '/auth/file/procedure/' +
        props.uuid +
        '/' +
        item.id +
        '/' +
        item.file_name;
      item.download =
        process.env.REACT_APP_API_URL +
        '/api/file/procedure/' +
        props.uuid +
        '/' +
        item.id +
        '/' +
        item.file_name;
      tmp.push(item);
    });

    tmp.sort(function (a, b) {
      return b.id - a.id;
    });

    this.state = {
      files: tmp,
      isSubmitting: false,
      hasError: false,
      newFiles: [],
      data: null,
      drawerOpen: false,
      //pdf: false,
      selectedData: null,
      toDelete: false,
    };

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleShareClick = this.handleShareClick.bind(this);
    this.downloadGallery = this.downloadGallery.bind(this);
    this.showGallery = this.showGallery.bind(this);
    this.nextGallery = this.nextGallery.bind(this);
    this.previousGallery = this.previousGallery.bind(this);
    this.closeGallery = this.closeGallery.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    //this.fetchPDF = this.fetchPDF.bind(this);
    this.handleDownloadClick = this.handleDownloadClick.bind(this);
    this.setSelected = this.setSelected.bind(this);
  }

  async handleFileChange(files) {
    if (files.length > 0) {
      this.setState({ newFiles: files });
    }

    //this.handleUpload(false);
    /*
		if (files.length > 0) {
			this.setState({ isSubmitting: true, hasError: false, newFiles: files });
	    	
			let formData = new FormData();
			formData.append('_method', 'PUT');

	    	files.forEach((file, i) => {
	      		formData.append('uploads[]', file,  file.file_name);
	    	})

			const response = await fetch(process.env.REACT_APP_API_URL + '/api/procedures/'+this.props.uuid,
			{
				method: "POST",
				headers: {
					Authorization: `Bearer ${this.props.Authentication.access_token}`
				},
				body: formData,
			})	
			.then( response => response.json() )
			.then( function(response) {
				return response
			})
			if (!!response) {
				let tmp = [];
				this.props.files.inbox.forEach( (item, index) => { 
					item.url = process.env.REACT_APP_API_URL	+ '/auth/file/inboxitem/' + this.props.uuid	+ '/' + item.id + '/' + item.file_name;
					item.download = process.env.REACT_APP_API_URL	+ '/api/file/inboxitem/' + this.props.uuid	+ '/' + item.id + '/' + item.file_name;
					tmp.push(item);
				})

				response.media?.forEach( (item, index) => { 
					item.url = process.env.REACT_APP_API_URL	+ '/auth/file/procedure/' + this.props.uuid	+ '/' + item.id + '/' + item.file_name;
					item.download = process.env.REACT_APP_API_URL	+ '/api/file/procedure/' + this.props.uuid	+ '/' + item.id + '/' + item.file_name;
					tmp.push(item);
				})

				tmp.sort(function (a, b) {
		  			return b.id - a.id;
				});
				this.setState({ newFiles: [], files: tmp, isSubmitting:   false, hasError: false });//, this.props.callbackFunction(null));
				//this.props.handleClose()
			} else {
				this.setState({ newFiles: files, isSubmitting: false, hasError: true });//, this.props.callbackFunction(null));
			}
		} else {
			this.setState({ newFiles: files });
		}
		*/
  }

  async handleUpload(event) {
    if (!!event) {
      event.preventDefault();
    }

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'PUT');

    this.state.newFiles.forEach((file, i) => {
      formData.append('uploads[]', file, file.file_name);
    });

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response) {
      if (this.props.model === 'inbox') {
        this.props.callbackFunction(true);
      } else {
        let tmp = [];
        this.props.files.inbox.forEach((item, index) => {
          item.url =
            process.env.REACT_APP_API_URL +
            '/auth/file/inboxitem/' +
            this.props.uuid +
            '/' +
            item.id +
            '/' +
            item.file_name;
          item.download =
            process.env.REACT_APP_API_URL +
            '/api/file/inboxitem/' +
            this.props.uuid +
            '/' +
            item.id +
            '/' +
            item.file_name;
          tmp.push(item);
        });

        response.media?.forEach((item, index) => {
          item.url =
            process.env.REACT_APP_API_URL +
            '/auth/file/procedure/' +
            this.props.uuid +
            '/' +
            item.id +
            '/' +
            item.file_name;
          item.download =
            process.env.REACT_APP_API_URL +
            '/api/file/procedure/' +
            this.props.uuid +
            '/' +
            item.id +
            '/' +
            item.file_name;
          tmp.push(item);
        });

        tmp.sort(function (a, b) {
          return b.id - a.id;
        });
        this.setState({
          newFiles: [],
          files: tmp,
          isSubmitting: false,
          hasError: false,
        });
        //this.props.handleClose()
      }
    } else {
      this.setState({ isSubmitting: false, hasError: true });
    }
  }

  handleDrawerOpen = (value) => {
    this.setState({ drawerOpen: true });
  };

  handleDrawerClose = () => {
    this.setState({ drawerOpen: false });
  };

  handleDownloadClick = (item) => {
    fetch(item.download + '?download', {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', item.file_name);
        a.click();
      });
    });
  };

  deleteDialog(item) {
    if (!!item.download && typeof item.download === 'string') {
      this.setState({ toDelete: item.download });
    }
  }
  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  async handleDeleteClick() {
    let url = new URL(this.state.toDelete);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.deleted) {
      let tmp = this.state.files.filter((element) => element.download !== this.state.toDelete);
      this.setState({ ...this.state, files: tmp, toDelete: false });
      if (!!this.state.selected) {
        this.closeGallery();
      }
    }
  }

  handleDownloadData = async (item) => {
    let url = item.download;
    return await fetch(url + '?download', {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then(function (t) {
        return t.blob().then((b) => {
          return URL.createObjectURL(b);
        });
      })
      .catch((error) => false);
  };

  setSelected = async (key) => {
    if (key === false) {
      this.setState({ selected: false, selectedData: null });
    }
    if (
      this.state.files[key]?.mime_type.toLowerCase().indexOf('pdf') >= 0 &&
      this.state.files[key]?.size < 8192 * 1024
    ) {
      var data = await this.handleDownloadData(this.state.files[key]);
      if (data === false) {
        data = this.state.files[key]?.url + '?download';
      }
      this.setState({ selected: key, selectedData: data });
      return;
    }
    this.setState({ selected: key });
  };

  handleShareClick = (item) => {
    console.log('handleShareClick ');
  };

  downloadGallery = (index) => {
    this.handleDownloadClick(this.state.files[this.state.selected]);
  };
  showGallery = (index) => {
    //if (!(this.state.files[index]?.file_name.toLowerCase().indexOf(".pdf") === -1)) {
    //	this.fetchPDF(index);
    //} else {
    //	this.setState({selected: index, pdf: false})
    //}
    this.setSelected(index);
  };
  nextGallery = () => {
    const newSelected =
      this.state.selected === this.state.files.length - 1 ? 0 : this.state.selected + 1;
    //if (!(this.state.files[newSelected]?.file_name.toLowerCase().indexOf(".pdf") === -1)) {
    //	this.fetchPDF(newSelected);
    //} else {
    //	this.setState({selected: newSelected, pdf: false })
    //}
    this.setSelected(newSelected);
  };
  previousGallery = () => {
    const newSelected =
      this.state.selected === 0 ? this.state.files.length - 1 : this.state.selected - 1;
    //if (!(this.state.files[newSelected]?.file_name.toLowerCase().indexOf(".pdf") === -1)) {
    //	this.fetchPDF(newSelected);
    //} else {
    //	this.setState({selected: newSelected, pdf: false })
    //}
    this.setSelected(newSelected);
  };
  closeGallery = () => {
    this.setSelected(false);
  };

  //async fetchPDF(index) {
  //	let pdf = await fetch(this.state.files[index]?.download + '?base64',{
  //			headers: {
  //				Authorization: `Bearer ${this.props.Authentication.access_token}`
  //			},
  //		})
  //		.then( res => {
  //			if (res.ok) {
  //				res.json()
  //			} else {
  //				throw res;
  //			}
  //		})
  //		.then(json => json)
  //		.catch( (error) => "DOWNLOAD");
  //
  //	this.setState({selected: index, pdf: <Document file={'data:' + this.state.pdf.mime + ';base64,' + this.state.pdf.base64 }/> });
  //}

  render() {
    const { classes } = this.props;

    return (
      <>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>Löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText>Die Datei sicher aus dem Vorgang löschen?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              Abbrechen
            </Button>
            <Button variant='contained' onClick={this.handleDeleteClick} color='secondary'>
              LÖSCHEN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          classes={{ paper: classes.viewerBox }}
          maxWidth='lg'
          open={!!this.state.files[this.state.selected]?.url}
          onClose={() => this.setSelected(null)}
        >
          <CardHeader
            action={
              <IconButton onClick={this.closeGallery}>
                <CloseIcon />
              </IconButton>
            }
            title={this.state.files[this.state.selected]?.file_name}
            subheader={this.state.files[this.state.selected]?.mime_type}
          />

          {this.state.files[this.state.selected]?.mime_type.toLowerCase().indexOf('image') ===
          -1 ? (
            <CardContent className={classes.cardcontent}>
              <object
                className={classes.sizeMe}
                data={this.state.selectedData}
                name={this.state.files[this.state.selected]?.file_name}
                type={this.state.files[this.state.selected]?.mime_type}
                title='Falls Sie dies lesen können, kann Ihr Broser die Datei leider nicht anzeigen. Bitte laden Sie die Datei herunter!'
                onAbort={(abort) => console.log('abbort!', abort)}
                onError={(error) => console.error('error!', error)}
              >
                Falls Sie dies lesen können, kann Ihr Broser die Datei leider nicht anzeigen. Bitte
                laden Sie die Datei herunter!
              </object>
            </CardContent>
          ) : (
            <CardContent className={classes.cardcontent} component='center'>
              <img
                align='middle'
                src={this.state.files[this.state.selected]?.url}
                alt={this.state.files[this.state.selected]?.file_name}
                className={classes.box}
              ></img>
            </CardContent>
          )}
          <CardActions disableSpacing className={classes.buttonbox}>
            <ButtonGroup color='default' className={classes.buttongroup}>
              <Button
                variant='contained'
                startIcon={<NavigateBeforeIcon />}
                onClick={this.previousGallery}
              >
                <Hidden mdDown>Zurück</Hidden>
              </Button>
              <Button variant='contained' onClick={this.downloadGallery}>
                <DownloadIcon />
                <Hidden mdDown>Herunterladen</Hidden>
              </Button>
              <Button
                variant='contained'
                onClick={() => this.deleteDialog(this.state.files[this.state.selected])}
              >
                <DeleteIcon />
                <Hidden mdDown>Löschen</Hidden>
              </Button>
              <Button variant='contained' onClick={this.closeGallery}>
                <CloseIcon />
                <Hidden mdDown>Schließen</Hidden>
              </Button>

              <Button variant='contained' endIcon={<NavigateNextIcon />} onClick={this.nextGallery}>
                <Hidden mdDown>Weiter</Hidden>
              </Button>
            </ButtonGroup>
          </CardActions>
        </Dialog>

        <Grid container className={classes.fixSize} spacing={1}>
          <Grid key={'file-list-upload'} item className={classes.uploadTile}>
            <Card className={classes.card}>
              <CardContent className={classes.cardcontentUpload}>
                {this.state.isSubmitting ? (
                  <center className={classes.dropzoneSize}>
                    <CircularProgress style={{ marginTop: '10%' }} />
                  </center>
                ) : (
                  <DropzoneArea
                    filesLimit={99}
                    maxFileSize={30000000}
                    dropzoneText={
                      this.state.newFiles.length > 0 ? null : 'Datei hier ablegen, oder klicken.'
                    }
                    showPreviewsInDropzone={true}
                    showFileNames={false}
                    useChipsForPreview={true}
                    previewChipProps={{ style: { maxWidth: '100%' } }}
                    showAlerts={false}
                    onChange={this.handleFileChange}
                    dropzoneClass={classes.dropzoneSize}
                  />
                )}
              </CardContent>
              <CardActions disableSpacing className={classes.justifyContentEnd}>
                <Button
                  variant='contained'
                  disabled={!(this.state.newFiles.length > 0)}
                  onClick={this.handleUpload}
                >
                  <SaveIcon /> Hochladen
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {this.state.files.map((item, index) => {
            return (
              <Grid key={'file-list-' + index} item className={classes.maxSized}>
                <Card raised>
                  <CardHeader
                    className={classes.cardHeaderGrid}
                    title={item.file_name}
                    titleTypographyProps={{
                      variant: 'caption',
                      noWrap: true,
                      style: { maxWidth: '100%' },
                    }}
                  />

                  <CardMedia
                    className={classes.media}
                    image={
                      !!item.mime_type &&
                      [
                        'application/pdf',
                        'image/jpeg',
                        'image/gif',
                        'image/png',
                        'image/webp',
                      ].includes(item.mime_type)
                        ? item.url + '?thumb&download'
                        : '/assets/404.jpg'
                    }
                    title={item.file_name}
                    onClick={() => this.showGallery(index)}
                  />

                  <CardActions disableSpacing>
                    <IconButton
                      className={classes.sizedIcons}
                      onClick={() => this.handleDownloadClick(item)}
                    >
                      <DownloadIcon />
                    </IconButton>
                    <IconButton
                      className={classes.sizedIcons}
                      onClick={() => this.deleteDialog(item)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      className={classes.sizedIcons}
                      onClick={() => this.handleShareClick(item)}
                    >
                      <ShareIcon />
                    </IconButton>
                    <Hidden mdDown>
                      <Typography align='right' display='inline' className={classes.card}>
                        {String(item.size / (1024 * 1024)).substring(0, 4)} MB
                      </Typography>
                    </Hidden>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(FileCard);
