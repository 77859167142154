import React from 'react';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
//import Link from '@material-ui/core/Link';
//import { push } from 'connected-react-router'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const generateCrumbs = (path, dispatch, small) => {
  var parts = path.split('/');
  let last = parts.length - 1;
  while (
    parts[last].match(/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi)
  ) {
    parts.pop();
    last = parts.length - 1;
  }

  if (small) {
    return (
      <Typography
        style={{
          border: '1px solid',
          padding: '4px 12px',
          boxShadow: '2px 2px',
          borderRadius: '4px',
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        key={'breadcrumb-1'}
        color='inherit'
      >
        {parts[last].toUpperCase()}
      </Typography>
    );
  }
  const crumb = parts.map((part, i) => {
    let counter = 0;
    let newPath = '/';
    while (counter <= i) {
      if (newPath === '/') {
        newPath = newPath + parts[counter];
      } else {
        newPath = newPath + '/' + parts[counter];
      }
      counter++;
    }

    //if (i === last && parts.length > 2) {
    //  return ( <Typography key={ "breadcrumb-" + i }  color="inherit">{part}</Typography> )
    //}
    if (!part.trim()) {
      return '';
    }
    return (
      <Typography
        style={{
          border: '1px solid',
          padding: '4px 12px',
          boxShadow: '2px 2px',
          borderRadius: '4px',
          maxWidth: '200px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        key={'breadcrumb-' + i}
        color='inherit'
      >
        {part.toUpperCase()}
      </Typography>
    );
  });
  return crumb;
};
function Breadcrumb(props) {
  if (isWidthUp('lg', props.width)) {
    return (
      <Breadcrumbs
        separator=''
        style={{ display: 'flex', flexGrow: '1' }}
        color='inherit'
        aria-label='breadcrumb'
      >
        {generateCrumbs(props.path, props.dispatch, false)}
      </Breadcrumbs>
    );
  } else {
    return (
      <Breadcrumbs
        separator=''
        style={{ display: 'flex', flexGrow: '1' }}
        color='inherit'
        aria-label='breadcrumb'
      >
        {generateCrumbs(props.path, props.dispatch, true)}
      </Breadcrumbs>
    );
  }
}
export default withWidth()(Breadcrumb);
