import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { compose } from 'react-recompose';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';
//import { ThemeProvider } from '@material-ui/core/styles';
import { DE as TextDE } from '../../lib/Text';
import SupportRequestWithoutUser from '../Other/SupportRequestWithoutUser';
import FooterHint from '../Other/FooterHint';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: '15vh',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  NavLinkItem: {
    width: '33%',
    padding: theme.spacing(3),
  },
  alert: {
    width: '100%',
  },
  size: {},
});

class Register extends React.Component {
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePasswordChange = () => (event) => {
    //let re = /^.*(?=.{3,})(?=.*[a-zA-ZäöüÄÖÜ])(?=.*[0-9])(?=.*[\d\X])(?=.*[!$#%]).*$/
    let re = /^.*(?=.{3,})(?=.*[a-zA-ZäöüÄÖÜ])(?=.*[0-9])(?=.*[\d])(?=.*[!$#%]).*$/;
    if (event.target.value.match(re) && event.target.value.length >= 8) {
      //console.log("All Criteria matched");
      this.setState({
        validation: true,
        [event.target.name]: event.target.value,
      });
      //dispatch({ validation: true, [event.target.name]: event.target.value });
    } else {
      this.setState({
        validation: false,
        [event.target.name]: event.target.value,
      });
      //dispatch({ validation: false, [event.target.name]: event.target.value });
    }
  };

  handleDelete = () => {
    this.setState({ error: false });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true, error: false, success: false });
    if (!this.state.email.trim()) {
      return;
    }

    let formData = new FormData();
    formData.append('email', this.state.email);
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/password/email', {
      method: 'POST',
      //headers: headers,
      body: formData,
      redirect: 'manual',
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => alert(JSON.stringify(error)));

    if (!!response && !!response?.message) {
      this.setState({ loading: false, success: true, email: '' });
    } else {
      this.setState({ loading: false, error: JSON.stringify(response) });
    }
  }

  constructor() {
    super();
    this.state = { loading: false, error: false, email: '', success: false };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Card className={classes.card} variant='outlined'>
              <div
                style={{
                  backgroundImage: 'url(/assets/icon.png)',
                  width: '100%',
                  minHeight: '20vh',
                  height: 'auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  marginBottom: '1em',
                }}
              ></div>

              <div
                style={{
                  backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
                  width: '100%',
                  minHeight: '60px',
                  height: 'auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              ></div>

              {this.state.loading ? (
                <Box my={4}>
                  {' '}
                  <CircularProgress />{' '}
                </Box>
              ) : (
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Typography align='center' variant='h5' gutterBottom={true}>
                        {TextDE.passwordReset.line1}
                      </Typography>
                      <Typography align='center' component='div' variant='h6'>
                        {TextDE.passwordReset.line2}
                      </Typography>
                      <Typography align='center' component='div' variant='caption'>
                        {TextDE.passwordReset.line3}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        required
                        fullWidth
                        id='email'
                        label={TextDE.form.email.label}
                        name='email'
                        type='email'
                        autoComplete='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </Grid>
                  </Grid>
                  {!!this.state.error ? (
                    <Alert classes={classes.alert} severity='error'>
                      {this.state.error}
                    </Alert>
                  ) : null}
                  {!!this.state.success && (
                    <Alert classes={classes.alert}>{TextDE.passwordReset.mailSent}</Alert>
                  )}
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    endIcon={<RotateLeftIcon />}
                    disabled={!(this.state.email.length > 0)}
                  >
                    {TextDE.passwordReset.requestResetButton}
                  </Button>
                </form>
              )}

              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.my}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Button
                    href='/login'
                    variant='text'
                    color='primary'
                    size='small'
                    style={{ textAlign: 'center' }}
                  >
                    {TextDE.backToLogin}
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <SupportRequestWithoutUser />
                </Grid>
              </Grid>

              <FooterHint />
            </Card>
          </div>
        </Container>
      </>
    );
  }
}
//export default Login;

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});
//export default connect(mapStateToProps)(Login);
export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    //, mapDispatchToProps
  ),
)(Register);
