import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Grid from '@material-ui/core/Grid';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Badge from '@material-ui/core/Badge';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import Modal from '@material-ui/core/Modal';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import { Constants } from '../../../lib/Constants';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { DE as TextDE } from '../../../lib/Text';
const iconHeight = 64;
function arrayUnique(array) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}
const styles = (theme) => ({
  backdrop: {
    opacity: '1',
    transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    color: '#fff',
    zIndex: '1001',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(1, 2),
  },
  noPad: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  chip: {
    maxWidth: '100%',
  },
  card: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  list: {
    flexGrow: 1,
    padding: 0,
  },
  center: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  button: {
    float: 'right',
  },
  paper: {
    padding: theme.spacing(2, 1),
  },
  inlineAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeOut,
    }),

    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 'px',
      height: iconHeight / 1.5 + 'px',
      '&>svg': {
        fontSize: iconHeight / 2.1,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 'px',
      height: iconHeight + 'px',
      '&>svg': {
        fontSize: iconHeight - 30,
      },
    },
  },
  avatarOpen: {
    transform: 'rotate(90deg)',
    marginRight: '-12px',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeIn,
    }),
    [theme.breakpoints.down('sm')]: {
      width: iconHeight / 1.5 + 10 + 'px',
      height: iconHeight / 1.5 + 10 + 'px',
      '&>svg': {
        fontSize: iconHeight / 1.5 - 10,
      },
    },
    [theme.breakpoints.up('md')]: {
      width: iconHeight + 20 + 'px',
      height: iconHeight + 20 + 'px',
      '&>svg': {
        fontSize: iconHeight - 20,
      },
    },
  },
  avatarRed: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  position: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    position: 'fixed',
    maxWidth: '90vw',
    minWidth: '35vw',
    [theme.breakpoints.down('sm')]: {
      //right: iconHeight/1.5+30+'px',
      //marginTop: '-'+iconHeight/1.5+'px',
      top: '10vh',
      left: '1vw',
      maxWidth: '98vw',
      minWidth: '98vw',
    },
    [theme.breakpoints.up('md')]: {
      right: '20px',
      top: '20%',
    },
    zIndex: '1002',
  },
  bigBadge: {
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: iconHeight / 1.5 + 24 + 'px',
      width: iconHeight / 1.5 + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 4.5,
      },
    },
    [theme.breakpoints.up('md')]: {
      height: iconHeight + 24 + 'px',
      width: iconHeight + 24 + 'px',
      '&>span': {
        height: iconHeight / 3 + 4 + 'px',
        fontSize: iconHeight / 3,
      },
    },
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
  },
  results: {
    zIndex: 123123,
    position: 'absolute',
    maxHeight: '300px',
    overflow: 'auto',
  },
  cardHeaderContent: {
    flex: '1 1 auto',
    width: 0,
    height: 'auto',
    paddingBottom: 0,
  },
  cardHeaderAction: {
    margin: theme.spacing(2, -1, 0, 0),
  },
  cardHeaderRoot: {
    padding: theme.spacing(1, 2),
    width: '100%',
  },
  cardUser: {
    borderRight: '1px solid rgba(0,0,0,0.12)',
    borderTop: '1px solid rgba(0,0,0,0.12)',
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    borderBottom: 'none',
    overflow: 'visible',
    backgroundColor: '#ff8a65',
    minHeight: '106px',
  },
  cardUserCollapsed: {
    justifyContent: 'start',
    alignContent: 'center',
    display: 'flex',
  },
  maximize: {
    [theme.breakpoints.up('sm')]: {
      //position: 'absolute',
      //right: '32px',
      transform: 'translate(-100%, 2px)',
      marginRight: theme.spacing(0.25),
      marginLeft: '100.5%',
      backgroundColor: 'inherit',
      marginTop: theme.spacing(3),
      borderBottom: '1px solid rgba(0,0,0,0.12)',
      borderRight: '1px solid rgba(0,0,0,0.12)',
      //borderTop: "1px solid rgba(0,0,0,0.12)",
      borderTop: 'none',
      borderRadius: 'inherit',
    },
  },
});

class UserCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersAll: [],
      users: this.props.users || [],
      cardUserExpand: false,
      cardUserNew: false,
      form: {
        model: this.props.model || 'procedures',
        uuid: this.props.uuid || '',
        isSubmitting: false,
        hasError: false,
        user: null,
        newContact: false,
        tags: [],
      },
      selectedUser: null,
      toDelete: false,
    };

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.handleCardUserExpandClick = this.handleCardUserExpandClick.bind(this);
    this.handleCardUserNewClick = this.handleCardUserNewClick.bind(this);

    this.handleUserEdit = this.handleUserEdit.bind(this);
    this.handleUserDelete = this.handleUserDelete.bind(this);
  }

  componentDidMount() {
    let tmp = [];
    this.state.users.forEach((value, key) => {
      tmp.push({
        uuid: value.user.uuid,
        displayName:
          value.user.firstname +
          (!!value.user.nickname ? ' "' + value.user.nickname + '" ' : ' ') +
          value.user.lastname,
        email: value.user.email,
        phone: value.user.phone,
        tags: value.tags.map((tag) => tag.name.de),
        deleted: value.user.deleted_at === null ? false : true,
      });
    });
    this.setState({ users: tmp, titleUser: tmp[0] || null });

    fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = this.state.usersAll;
        json.forEach((value, key) => {
          if (!!!value.deleted_at) {
            tmp.push({
              uuid: value.uuid,
              displayName:
                value.firstname +
                (!!value.nickname ? ' "' + value.nickname + '" ' : ' ') +
                value.lastname,
              email: value.email,
              phone: value.phone,
              roles: value.roles.map((role) => role.name),
            });
          }
        });
        this.setState({ usersAll: tmp });
      })
      .catch((error) => {
        return false;
      });
  }

  handleCardUserExpandClick = () => {
    this.props.toggleFunction('UserCard');
    //this.setState({ cardUserExpand: !this.state.cardUserExpand});
  };

  handleCardUserNewClick = () => {
    this.setState({
      ...this.state,
      cardUserExpand: true,
      cardUserNew: !this.state.cardUserNew,
      form: {
        ...this.state.form,
        isSubmitting: false,
        hasError: false,
        user: null,
        tags: [],
      },
    });
  };

  handleUserChange = (event, value) => {
    if (typeof value !== 'undefined' && typeof value.key === 'undefined') {
      if (!!value.uuid) {
        this.setState({
          form: {
            ...this.state.form,
            user: value,
            newContact: false,
            tags: value.roles || value.tags || [],
          },
        });
      } else if (Array.isArray(value)) {
        this.setState({ form: { ...this.state.form, tags: value } });
      } else {
        // Kein Tag/ Kein Kontalt -> Neuer Kollege!
        this.setState({
          form: { ...this.state.form, user: false, newContact: value },
        });
      }
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.value },
      });
    }
  };

  handleUserAdd = () => {
    this.setState({
      ...this.state,
      form: { ...this.state.form, isSubmitting: true },
    });

    // Create new User or use existing
    let formData = {};
    formData['user'] = this.state.form.user.uuid;
    formData['_method'] = 'PUT';
    formData['tags'] = this.state.form.tags;
    formData['context'] = 'users';
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint + '/api/' + this.state.form.model + '/' + formData.uuid + '/' + formData.context;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        //Meeh
      });

    let control = false;
    let tmp = this.state.users;
    let user = this.state.form.user;
    user.tags = this.state.form.tags || [];

    for (var i = tmp.length - 1; i >= 0; i--) {
      if (tmp[i].uuid === user.uuid) {
        tmp[i] = {
          ...tmp[i],
          tags: arrayUnique([...tmp[i].tags, ...user.tags]),
        };
        //console.log(tmp[i])
        control = true;
      }
    }
    if (!control) {
      let hasUser = this.state.usersAll.find((element) => element.uuid === user.uuid);
      //console.log(hasUser);
      if (!!hasUser) {
        user = {
          ...hasUser,
          tags: arrayUnique([...user.tags, ...hasUser.tags]),
        };
      } else {
        user.deleted = false;
        user.displayName = this.state.form.newContact;
      }
      //console.log(contact)
      tmp.push(user);
    }

    this.setState({
      ...this.state,
      users: tmp,
      form: {
        model: this.state.form.model,
        uuid: this.state.form.uuid,
        isSubmitting: false,
        hasError: false,
        user: null,
        tags: [],
      },
    });
    this.props.callbackFunction(true);
  };

  handleUserEdit = (value) => {
    this.setState({
      ...this.state,
      cardUserExpand: true,
      cardUserNew: true,
      form: {
        ...this.state.form,
        isSubmitting: false,
        hasError: false,
        user: value,
        tags: value.tags,
      },
    });
  };

  deleteDialog(value) {
    if (!!value.uuid && typeof value.uuid === 'string' && !!value.tags) {
      this.setState({ toDelete: value });
    }
  }
  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }

  handleUserDelete() {
    let users = this.state.users;
    users = users.filter((user) => user.uuid !== this.state.toDelete.uuid);
    this.setState({ users: users });

    // Drop User from procedure
    let formData = {};
    formData['_method'] = 'DELETE';
    formData['tags'] = this.state.toDelete.tags;
    formData['context'] = 'users';
    formData['user'] = this.state.toDelete.uuid;
    formData['uuid'] = this.state.form.uuid;

    let endpoint = process.env.REACT_APP_API_URL;
    endpoint =
      endpoint + '/api/' + this.state.form.model + '/' + formData.uuid + '/' + formData.context;
    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        //console.log(response);
        return true;
      });
    this.setState({ toDelete: false });
    this.props.callbackFunction(true);
  }

  handleUserClick = (value) => {
    this.setState({ selectedUser: value });
  };

  handleUserClose = () => {
    this.setState({ selectedUser: null });
  };

  render() {
    const { classes } = this.props;
    return (
      <Box ml={{ xs: 0, sm: 0, md: 0, lg: 1, xl: 1 }} mt={{ xs: 1, sm: 1, md: 0, lg: 0, xl: 0 }}>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>{TextDE.user.deleteTitle}</DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.user.deleteText}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.handleUserDelete} color='secondary'>
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Card
          raised={false}
          className={clsx(classes.cardUser, {
            [classes.cardUserCollapsed]: !this.props.open,
          })}
        >
          {!!this.state.titleUser && !this.props.open ? (
            <CardHeader
              classes={{
                content: classes.cardHeaderContent,
                root: !this.props.open ? classes.cardHeaderRoot : null,
                action: classes.cardHeaderAction,
              }}
              avatar={
                <IconButton size='small'>
                  <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                    <SupervisorAccountIcon />
                  </Avatar>
                </IconButton>
              }
              action={
                <Badge
                  overlap='circular'
                  max={9}
                  badgeContent={this.state.users.length}
                  color='secondary'
                >
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.props.open,
                    })}
                    onClick={this.handleCardUserExpandClick}
                    aria-expanded={this.props.open}
                    aria-label={TextDE.more}
                  >
                    {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Badge>
              }
              disableTypography={false}
              titleTypographyProps={{
                onClick: this.handleCardUserExpandClick,
                noWrap: false,
                variant: 'subtitle1',
              }}
              subheaderTypographyProps={{
                onClick: this.handleCardUserExpandClick,
                noWrap: true,
              }}
              title={this.state.titleUser.displayName}
              subheader={this.state.titleUser.tags.map((item, index) => (
                <Chip
                  size='small'
                  key={index}
                  className={classes.chip}
                  label={item?.name?.de ?? item}
                />
              ))}
            />
          ) : (
            <CardHeader
              classes={{
                content: classes.cardHeaderContent,
                root: !this.props.open ? classes.cardHeaderRoot : null,
                action: classes.cardHeaderAction,
              }}
              avatar={
                <Avatar aria-label='recipe' className={classes.inlineAvatar}>
                  <SupervisorAccountIcon />
                </Avatar>
              }
              action={
                <Badge
                  overlap='circular'
                  max={9}
                  badgeContent={this.state.users.length}
                  color='secondary'
                >
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: this.props.open,
                    })}
                    onClick={this.handleCardUserExpandClick}
                    aria-expanded={this.props.open}
                    aria-label={TextDE.more}
                  >
                    {this.props.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Badge>
              }
              titleTypographyProps={{ noWrap: true, variant: 'h6' }}
              title={TextDE.user.labelHelper(this.state.users?.length)}
            />
          )}
          <Collapse
            classes={{
              wrapperInner: classes.inheritBackground,
              wrapper: classes.inheritBackground,
            }}
            className={clsx(classes.inheritBackground, {
              [classes.maximize]:
                this.props.open &&
                this.props.expandedWidth >= this.props.Theme.breakpoints.values['md'],
            })}
            style={{
              width:
                this.props.expandedWidth > 599 && this.props.open
                  ? this.props.expandedWidth - 38
                  : '100%',
            }}
            in={this.props.open}
            timeout={{
              enter: 214,
              exit: this.props.expandedWidth < 599 ? 106 : 0,
            }}
            unmountOnExit
          >
            <CardContent className={classes.noPad}>
              <List className={classes.list}>
                {this.state.users.map((value, key) => {
                  const labelId = `list-users-label-${key}`;
                  return (
                    <ListItem key={labelId} className={!!value.deleted ? classes.deleted : null}>
                      <ListItemAvatar>
                        <Avatar
                          alt={value.displayName}
                          src={process.env.REACT_APP_API_URL + '/avatar/' + value.uuid + '?thumb'}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        id={labelId}
                        disableTypography={true}
                        primary={<Typography variant='body1'>{value.displayName}</Typography>}
                        secondary={value.tags.map((item, index) => (
                          <Chip
                            size='small'
                            key={index}
                            className={classes.chip}
                            label={item?.name?.de ?? item}
                          />
                        ))}
                      />

                      <ListItemSecondaryAction>
                        <IconButton
                          color='default'
                          edge='end'
                          aria-label='edit'
                          onClick={() => this.handleUserEdit(value)}
                        >
                          <EditIcon />
                        </IconButton>

                        <IconButton
                          color='default'
                          edge='end'
                          aria-label='delete'
                          onClick={() => this.deleteDialog(value)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  );
                })}
              </List>
              <Box component='div' p={2}>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={this.handleCardUserNewClick}
                  aria-expanded={this.state.cardUserNew}
                  aria-label={TextDE.add}
                  fullWidth={true}
                >
                  {this.state.cardUserNew ? <CloseIcon /> : <AddIcon />}
                  {this.state.cardUserNew ? TextDE.close : TextDE.add}
                </Button>
              </Box>
            </CardContent>

            <Collapse in={this.state.cardUserNew} timeout='auto' unmountOnExit>
              <Divider />
              <CardContent className={classes.noPad}>
                {this.state.form.isSubmitting ? (
                  <center>
                    <CircularProgress />
                  </center>
                ) : (
                  <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                      <Autocomplete
                        id='userselect'
                        name='uuid'
                        options={this.state.usersAll}
                        getOptionLabel={(option) => option.displayName || option}
                        getOptionSelected={(option) => option.uuid || false}
                        defaultValue={null}
                        value={this.state.form.user?.displayName || null}
                        onChange={this.handleUserChange}
                        renderOption={(option, state) => (
                          <CardHeader
                            key={option.uuid}
                            avatar={
                              <Avatar
                                alt={option.firstname + ' ' + option.lastname}
                                src={
                                  process.env.REACT_APP_API_URL +
                                  '/avatar/' +
                                  option.uuid +
                                  '?thumb'
                                }
                              ></Avatar>
                            }
                            className='dense'
                            titleTypographyProps={{ variant: 'body1' }}
                            title={option.displayName}
                            subheader={option.roles.map((value, index) => (
                              <Chip size='small' key={index} label={value} />
                            ))}
                          />
                        )}
                        disableClearable
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            label={TextDE.user.label}
                            placeholder={TextDE.user.inputPlaceholder}
                            helperText={TextDE.user.helperText}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        multiple
                        freeSolo
                        id='tags-standard'
                        name='tags'
                        options={Constants.tags.users.all()}
                        getOptionLabel={(option) => option}
                        defaultValue={null}
                        value={this.state.form.tags}
                        onChange={this.handleUserChange}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={TextDE.user.tagLabel}
                            placeholder={TextDE.user.tagPlaceholder}
                            helperText={TextDE.user.tagHelperText}
                            fullWidth
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant='contained'
                        color='primary'
                        className={classes.button}
                        startIcon={<SaveIcon />}
                        onClick={this.handleUserAdd}
                      >
                        {TextDE.add}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Collapse>
          </Collapse>
        </Card>
        <Modal
          open={!!this.state.selectedUser}
          onClose={this.handleUserClose}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card className={classes.paper}>
            <CardHeader
              title={<Typography variant='h3'> {this.state.selectedUser?.displayName} </Typography>}
              action={
                <IconButton aria-label='close' onClick={this.handleUserClose}>
                  {' '}
                  <CloseIcon />{' '}
                </IconButton>
              }
            ></CardHeader>
            <CardContent>
              <pre>{JSON.stringify(this.state.selectedUser, 'undefined', 4)}</pre>
            </CardContent>
          </Card>
        </Modal>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(UserCard);
