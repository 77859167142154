import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { Constants } from '../../../../lib/Constants';
import { readableDate } from '../../../../lib/dateFunctions';
import PostAddIcon from '@material-ui/icons/PostAdd';
import TodayIcon from '@material-ui/icons/Today';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NoteWrapNew from './NoteWrapNew';
import SendIcon from '@material-ui/icons/Send';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';

const styles = (theme) => ({
  inputHeight: {
    marginTop: '8px',
    height: '50px',
  },
  fastNoteRoot: {
    marginTop: '8px',
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    border: ' 1px solid grey',
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(1),
    },
  },
  fastNoteInput: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  fastNoteIconButton: {
    padding: 10,
  },
  notesCard: {
    backgroundColor: 'transparent',
    overflow: 'hidden',
  },
  line: {
    height: '100%',
    marginLeft: '80%',
    width: '6px',
    color: 'grey',
    backgroundColor: 'grey',
    boxShadow: theme.shadows[12],
  },
  chip: {
    padding: theme.spacing(1, 2),
    fontSize: '115%',
    fontWeight: 'bold',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow: theme.shadows[12],
  },
  fixSize: {
    maxWidth: '100%',
    //maxHeight: '240px',
    overflowX: 'unset',
    display: 'flex',
    //flexFlow: 'row',
    //flexFlow: 'column',
    margin: 0,
  },
  buttonLabelSplit: {
    justifyContent: 'space-between',
  },
});

const types = Constants.getInboxItemTypesAsObject();

class Protocol extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterLabel: 'Zeige: Alles',
      filterValue: [],
      protocol: props.protocol,
      filterMenuOpen: false,
      loading: true,
      fastnote: '',
      isSubmitting: false,
      hasError: false,
    };

    this.listRef = React.createRef();
    this.Row = this.Row.bind(this);
    this.getItemSize = this.getItemSize.bind(this);
    this.setFilter = this.setFilter.bind(this);
    this.unsetFilter = this.unsetFilter.bind(this);
    this.setFilterToProtocol = this.setFilterToProtocol.bind(this);
    this.openFilterMenu = this.openFilterMenu.bind(this);
    this.closeFilterMenu = this.closeFilterMenu.bind(this);
    this.sendFastNote = this.sendFastNote.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    var elm = document.getElementById('fastNoteInput');
    if (elm.value.length >= 163) {
      alert('Die Notiz ist zu lang, wir wechseln nun zu einer normalen Notiz.');
      this.props.editNote({
        data: { body: elm.value, subject: 'Schnellnotiz', type: 'note' },
        uuid: null,
      });
    }
  }
  sendFastNote = async (event) => {
    event.preventDefault();
    var elm = document.getElementById('fastNoteInput');

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();
    formData.append('_method', 'PUT');
    formData.append('type_id', Constants.getInboxItemTypeId('TelegramIcon'));
    formData.append('subject', 'Schnellnotiz');
    formData.append('body', elm.value);

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid + '/item',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (response === 'Created') {
      this.setState(
        {
          isSubmitting: false,
          hasError: false,
        },
        () => this.props.noteSendCallback(),
      );
      elm.value = '';
    } else {
      this.setState({ isSubmitting: false, hasError: response });
    }
  };

  unsetFilter(id) {
    this.setState(
      { filterValue: [], loading: true, filterLabel: 'Zeige: Alles' },
      this.setFilterToProtocol,
    );
    this.closeFilterMenu();
  }

  setFilter(newFilter) {
    let filters = this.state.filterValue;

    var indexOfState = filters.findIndex((value) => value === newFilter.id);

    if (indexOfState > -1) {
      filters = filters.filter((value) => value !== newFilter.id);
    } else {
      filters.push(newFilter.id);
    }

    let label = [];
    if (filters.length === 0) {
      label.push('Alles');
    }

    filters.forEach((filterId) => label.push(Constants.getInboxItemType(filterId).description));

    this.setState(
      {
        filterValue: filters,
        loading: true,
        filterLabel: 'Zeige: ' + label.join(', '),
      },
      this.setFilterToProtocol,
    );
  }

  setFilterToProtocol() {
    var protocol = this.props.protocol;
    if (
      !!this.state.filterValue &&
      this.state.filterValue != null &&
      this.state.filterValue.length > 0
    ) {
      protocol = protocol.filter((el) => this.state.filterValue.includes(el.type_id));
    }
    this.setState({ protocol: protocol, loading: false });
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.protocol !== this.props.protocol) {
      this.setFilterToProtocol();
    }
  }

  openChatForm = () => {
    this.props.addChat();
  };
  openEmailForm = () => {
    this.props.addEmail();
  };
  openNoteForm = () => {
    this.props.addNote();
  };
  openAppointmentForm = () => {
    this.props.addAppointment();
  };

  // Start menu/customFunction Stuff
  openFilterMenu = (event) => {
    this.setState({
      filterMenuOpen: [event.clientX - 2, event.clientY - 4],
    });
  };

  closeFilterMenu = () => {
    this.setState({
      filterMenuOpen: false,
    });
  };

  getItemSize = (index) => {
    //card-contetn base height ~ 85px adding bottom margin of 8px
    // 85px + 8px
    let size = 130;
    if (index > 0 && index <= this.state.protocol.length) {
      let resetIndex = index - 1;
      switch (this.state.protocol[resetIndex].type_id) {
        case types.TelegramIcon.id:
          size = 100;
          break;
        case types.email.id:
          //mailheader is ~ 130
          size = 223;
          break;
        default:
          // SmallNoteHEader ~ 96
          size = 189;
          break;
      }
    } else {
      if (this.props.Dimensions.maxWidth >= this.props.Theme.breakpoints.values['md']) {
        size = 80;
      }
      if (!!this.props.isInbox) {
        size = 8;
      }
    }
    //console.log("Got a size of: " + size + ' on index ' + index + " by proto lenght: " + this.state.protocol.length + "  -- index >= 1 : " + (index >= 1 ? "true" : "false") + ", index <= this.state.protocol.length : " + (index <= this.state.protocol.length ? "true" : "false")) ;
    return size;
  };

  Row = ({ index, style }) => {
    const { classes } = this.props;
    if (!!this.props.isFetching || this.state.loading) {
      return (
        <Box component='div' style={style} className={classes.fixSize}>
          <CircularProgress />
        </Box>
      );
    }

    if (index === 0) {
      if (!!this.props.isInbox) {
        return null;
      }
      return (
        <Grid container spacing={0} style={style} className={classes.fixSize}>
          <Hidden smDown>
            <Grid item md={2} id='protocol-begin' key='note-stepper-new'>
              <Box
                display='flex'
                flexGrow={1}
                justifyContent='flex-start'
                alignContent='center'
                height='100%'
              >
                <div
                  className={classes.line}
                  style={{ borderTopRightRadius: '4px', borderTopLeftRadius: '4px' }}
                >
                  <Box
                    bgcolor='transparent'
                    style={{ position: 'relative', transform: 'translate(-100%, 20px)' }}
                    height='48px'
                    display='inline-flex'
                  >
                    <Chip
                      icon={<TodayIcon />}
                      deleteIcon={<ArrowRightAltIcon />}
                      label='Jetzt!'
                      color='primary'
                      className={classes.chip}
                    />
                  </Box>
                </div>
              </Box>
            </Grid>
          </Hidden>
          <Grid key='note-list-spacer' item xs={12} md={7}>
            {this.state.isSubmitting ? (
              <CircularProgress />
            ) : (
              <Paper
                component='form'
                className={classes.fastNoteRoot}
                elevation={3}
                onSubmit={this.sendFastNote}
              >
                <InputBase
                  className={classes.fastNoteInput}
                  placeholder='Schnellnotiz'
                  inputProps={{ 'aria-label': 'schnellnotiz', maxLength: '165', required: true }}
                  name='fastnote'
                  id='fastNoteInput'
                  onChange={this.handleChange}
                />
                {!!this.state.hasError && <p>{this.state.hasError}</p>}
                <IconButton
                  type='submit'
                  className={classes.fastNoteIconButton}
                  aria-label='search'
                  onClick={this.sendFastNote}
                >
                  <SendIcon />
                </IconButton>
              </Paper>
            )}
          </Grid>
          <Grid key='note-list-filter' item xs={12} md={3}>
            <Button
              classes={{ label: classes.buttonLabelSplit, root: classes.inputHeight }}
              variant='contained'
              fullWidth
              color='primary'
              onClick={this.openFilterMenu}
              endIcon={<ArrowDropDownIcon />}
            >
              {this.state.filterLabel}
            </Button>
          </Grid>
        </Grid>
      );
    } else if (index === this.state.protocol.length + 1) {
      return (
        //If end
        <Grid container spacing={0} style={style} className={classes.fixSize}>
          <Hidden smDown>
            <Grid item md={2} key='note-stepper-begin'>
              <Box
                display='flex'
                flexGrow={1}
                justifyContent='flex-start'
                alignContent='center'
                height='84px'
              >
                <div
                  className={classes.line}
                  style={{ borderBottomRightRadius: '4px', borderBottomLeftRadius: '4px' }}
                >
                  <Box
                    bgcolor='transparent'
                    style={{ position: 'relative', transform: 'translate(-100%, 20px)' }}
                    height='84px'
                    display='inline-flex'
                  >
                    <Chip
                      icon={<PostAddIcon />}
                      label={readableDate(this.props.createdAt)}
                      color='primary'
                      variant='outlined'
                      className={classes.chip}
                    />
                  </Box>
                </div>
              </Box>
            </Grid>
            <Grid key='note-list-begin' item xs={12} md={10}>
              <Box
                mt={2}
                display='flex'
                flexGrow={1}
                justifyContent='space-between'
                alignContent='center'
                height='36px'
              >
                <Typography variant='h5'>
                  {!!this.props.createdBy
                    ? `Erstellt durch ${this.props.createdBy.firstname} ${this.props.createdBy.lastname}`
                    : !!this.props.isInbox
                    ? 'Anfrage eingegangen.'
                    : 'Vorgang erstellt.'}
                </Typography>

                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => this.listRef.current.scrollToItem(0)}
                  endIcon={<ArrowDropUpIcon />}
                >
                  Zurück zum anfang
                </Button>
              </Box>
            </Grid>
          </Hidden>
        </Grid>
      );
    } else {
      let resetIndex = index - 1;
      if (resetIndex >= 0 && resetIndex <= this.state.protocol.length) {
        return (
          <NoteWrapNew
            currentUserCanEdit={
              !!this.state.protocol[resetIndex].creator &&
              this.state.protocol[resetIndex].creator.uuid === this.props.User.uuid
            }
            item={this.state.protocol[resetIndex]}
            index={resetIndex - 1}
            style={style}
            expandScrollTo={() => this.listRef.current.scrollToItem(index, 'start')}
            respondEmail={this.props.respondEmail}
            respondChat={this.props.respondChat}
            editNote={this.props.editNote}
            deleteNote={this.props.deleteNote}
            smDown={this.props.smDown}
            isSameDate={
              resetIndex - 1 >= 0 &&
              this.state.protocol[resetIndex].created_at.substr(0, 10) ===
                this.state.protocol[resetIndex - 1]?.created_at?.substr(0, 10)
            }
            inboxItemTypes={types}
            switchToFile={this.props.switchToFile}
          />
        );
      }
    }
  };

  render() {
    if (!!this.props.isFetching) {
      return (
        <Box component='center' mt={8}>
          <CircularProgress />
        </Box>
      );
    }

    return (
      <>
        <Menu
          id='add-menu'
          open={Boolean(this.state.filterMenuOpen)}
          onClose={this.closeFilterMenu}
          keepMounted={true}
          anchorReference='anchorPosition'
          anchorPosition={
            this.state.filterMenuOpen !== false && this.state.filterMenuOpen.length === 2
              ? { top: this.state.filterMenuOpen[1], left: this.state.filterMenuOpen[0] }
              : undefined
          }
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              maxWidth: '50ch',
              minWidth: '20ch',
              maxHeight: '30em',
            },
          }}
        >
          <MenuItem disabled>
            <ListItemText primary='Nach welchen Typen sollen wir filtern?' />
          </MenuItem>

          {Constants.inboxItemTypes
            .filter((value) => value.canBeSelected === true)
            .sort((a, b) => {
              if (a.description < b.description) {
                return -1;
              }
              if (a.description > b.description) {
                return 1;
              }
              return 0;
            })
            .map((filter) => (
              <MenuItem key={'filter-id-' + filter.type} onClick={() => this.setFilter(filter)}>
                <ListItemIcon>{filter.icon}</ListItemIcon>
                <ListItemText primary={filter.description} />
                <Checkbox
                  style={{ marginLeft: 'auto' }}
                  checked={this.state.filterValue.findIndex((value) => value === filter.id) > -1}
                />
              </MenuItem>
            ))}

          <MenuItem onClick={this.unsetFilter}>
            <ListItemIcon>
              <FilterNoneIcon />
            </ListItemIcon>
            <ListItemText primary='Alles' />
          </MenuItem>
        </Menu>
        <AutoSizer>
          {({ height, width }) => (
            <VariableSizeList
              ref={this.listRef}
              height={height}
              width={width}
              itemCount={this.state.protocol.length + 2}
              itemSize={this.getItemSize}
            >
              {this.Row}
            </VariableSizeList>
          )}
        </AutoSizer>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
  User: state.User,
  Theme: state.Style.Theme,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Protocol);
