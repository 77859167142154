import React from 'react';
import { connect } from 'react-redux';
import { collapse as navMenuCollapse } from '../actions/NavMenu';
import { fetch as setDimensions } from '../actions/Dimensions';
import Home from './Home';
import SimplifiedInbox from './SimplifiedInbox/SimplifiedInbox';
import SimplifiedMessageDetail from './SimplifiedInbox/SimplifiedMessageDetail';
import Inbox from './Inbox/List';
import MessageDetail from './Inbox/MessageDetail';
import Settings from './Settings';
import Statistics from './Statistics';
import Timetracking from './Timetracking';
import Contact from './Contact';
import Calendar from './Calendar';
import VerifyEmail from './VerifyEmail';
import Loading from './Other/Loading';
//import { compose } from 'react-recompose';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
//import { bindActionCreators } from 'redux';
import { ThemeProvider } from '@material-ui/core/styles';

import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
//import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import TitleBar from './Drawer/TitleBar';
import Hidden from '@material-ui/core/Hidden';
import NavigationList from './Drawer/NavigationList';
import CircularProgress from '@material-ui/core/CircularProgress';
import BugCatcher from './BugCatcher';
//import BuggyComponent from './BuggyComponent';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

const componentOf = {
  Loading: Loading,
  Home: Home,
  Statistics: Statistics,
  Timetracking: Timetracking,
  Inbox: Inbox,
  SimplifiedInbox: SimplifiedInbox,
  SimplifiedMessageDetail: SimplifiedMessageDetail,
  Settings: Settings,
  Calendar: Calendar,
  Contact: Contact,
  //Job: Job,
  //Jobs: Jobs,
  Miss: Home,
  MessageDetail: MessageDetail,
  VerifyEmail: VerifyEmail,
};

const styles = (theme) => ({
  toolbarSm: {
    zIndex: 9999999,
  },
  toolbarMd: {
    zIndex: 1111111,
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
    },
    display: 'flex',
    maxWidth: '100vw',
    minWidth: '100%',
    maxHeight: '100vh',
    minHeight: '100vh',
    padding: 0,
    margin: 0,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  hide: {
    display: 'none',
  },
  center: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    border: '0px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'none!important',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 0, 0, 2),
    ...theme.mixins.toolbar,
  },
  toolbarHeight: {
    ...theme.mixins.toolbar,
  },
  content: {
    backgroundColor: 'inherit',
    width: '100%',
    flexGrow: 1,
    height: '100vh',
    maxHeight: '100vh',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      position: 'sticky',
      bottom: 0,
      left: 0,
    },
  },
  grow: {
    flexGrow: 1,
  },
  fh: {
    height: '100%',
  },
  /* Snackbar (blue items) removed */
  //  snackMeTopRight: {
  //    top: '54px!important',
  //    right: '6px!important',
  //  },
});

function calculateDimensions(collapsed, theme, oldDimensions, rotateEvent) {
  let drawerWidth = 220;
  let height = [];

  Object.keys(theme.mixins.toolbar).forEach((key) => {
    if (!key.startsWith('@media')) {
      height.push(theme.mixins.toolbar[key]);
    } else {
      if (window.matchMedia(key.substring(7)).matches) {
        height.push(theme.mixins.toolbar[key].minHeight);
      }
    }
  });

  let dimensions = {};
  //if(navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && navigator.userAgent.toLowerCase().indexOf('mobile') > -1) {
  //	//console.log("Old Dimensions:" + !!oldDimensions, oldDimensions);
  //	dimensions.height = (!!oldDimensions?.height) ? oldDimensions.height : false;
  //	dimensions.width = (!!oldDimensions?.width) ? oldDimensions.width : false;
  //}

  dimensions.width = !!dimensions.width ? dimensions.width : window.innerWidth;
  dimensions.height = !!dimensions.height ? dimensions.height : window.innerHeight;
  dimensions.drawerWidthClosed = 72;
  dimensions.drawerWidthOpen =
    theme.typography.fontSize === 14
      ? drawerWidth
      : theme.typography.fontSize < 14
      ? Math.ceil(drawerWidth * 0.714286)
      : Math.ceil(drawerWidth * 1.285714);
  dimensions.appBarHeight = Math.max(...height);
  dimensions.drawerWidth = collapsed ? dimensions.drawerWidthOpen : dimensions.drawerWidthClosed;
  dimensions.maxWidth =
    dimensions.width < theme.breakpoints.values.md
      ? Number(dimensions.width)
      : Number(dimensions.width) - Number(dimensions.drawerWidth);
  return dimensions;
}

function rotateScreen(collapsed, theme, oldDimensions, rotateEvent) {
  //console.log("ROTATE EVENT TRIGGERED!", rotateEvent,);
  if (!!rotateEvent) {
    let drawerWidth = 220;
    let height = [];

    Object.keys(theme.mixins.toolbar).forEach((key) => {
      if (!key.startsWith('@media')) {
        height.push(theme.mixins.toolbar[key]);
      } else {
        if (window.matchMedia(key.substring(7)).matches) {
          height.push(theme.mixins.toolbar[key].minHeight);
        }
      }
    });

    let dimensions = {};
    //if(navigator.userAgent.toLowerCase().indexOf('safari/') > -1 && navigator.userAgent.toLowerCase().indexOf('mobile') > -1) {
    //	//console.log("Old Dimensions:" + !!oldDimensions, oldDimensions);
    //	// eslint-disable-next-line no-restricted-globals
    //	let tmpOrientation = (!!screen?.orientation?.angle ? screen?.orientation?.angle : ( (!!window.orientation) ? window.orientation : 0));
    //	if (typeof tmpOrientation !== "undefined") {
    //		//console.log("ROTATE EVENT TRIGGERED!", tmpOrientation, 'Math abs: ', Math.abs(tmpOrientation));
    //		if(Math.abs(tmpOrientation) === 90 ) {
    //			//dimensions.width = !!oldDimensions ? ((oldDimensions.height > oldDimensions.width) ? oldDimensions.height : oldDimensions.width) : window.innerHeight;
    //			dimensions.width = !!oldDimensions?.height ? oldDimensions.height : false ;
    //			//dimensions.height = !!oldDimensions ? ((oldDimensions.width > oldDimensions.height) ? oldDimensions.width : oldDimensions.height) : window.innerWidth;
    //			dimensions.height = !!oldDimensions?.width ? oldDimensions.width : false ;
    //			//dimensions.height = !!oldDimensions ? oldDimensions.width : window.innerWidth;
    //		} else {
    //			//dimensions.height = !!oldDimensions ? ((oldDimensions.height > oldDimensions.width) ? oldDimensions.height : oldDimensions.width) : window.innerHeight;
    //			dimensions.width = !!oldDimensions?.height ? oldDimensions.height : false ;
    //			//dimensions.width = !!oldDimensions ? ((oldDimensions.width > innerHeight.height) ? oldDimensions.width : oldDimensions.height) : window.innerWidth;
    //			dimensions.height = !!oldDimensions?.width ? oldDimensions.width : false ;
    //		}
    //	}
    //}

    dimensions.width = !!dimensions.width ? dimensions.width : window.innerWidth;
    dimensions.height = !!dimensions.height ? dimensions.height : window.innerHeight;
    dimensions.drawerWidthClosed = 72;
    dimensions.drawerWidthOpen =
      theme.typography.fontSize === 14
        ? drawerWidth
        : theme.typography.fontSize < 14
        ? Math.ceil(drawerWidth * 0.714286)
        : Math.ceil(drawerWidth * 1.285714);
    dimensions.appBarHeight = Math.max(...height);
    dimensions.drawerWidth = collapsed ? dimensions.drawerWidthOpen : dimensions.drawerWidthClosed;

    dimensions.maxWidth =
      dimensions.width < theme.breakpoints.values.md
        ? Number(dimensions.width)
        : Number(dimensions.width) - Number(dimensions.drawerWidth);

    return dimensions;
  }
  return oldDimensions;
}

class AppContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.renderComponent = this.renderComponent.bind(this);

    this.orientationchange = this.orientationchange.bind(this);
    this.resize = this.resize.bind(this);
    this.fetchRedirect = this.fetchRedirect.bind(this);
  }
  orientationchange(event) {
    this.props.dispatch(
      setDimensions({
        dimensions: rotateScreen(
          this.props.NavMenu.collapsed,
          this.props.Theme,
          this.props.Dimensions || false,
          event,
        ),
      }),
    );
  }

  resize() {
    this.props.dispatch(
      setDimensions({
        dimensions: calculateDimensions(
          this.props.NavMenu.collapsed,
          this.props.Theme,
          this.props.Dimensions || false,
        ),
      }),
    );
  }
  componentDidMount() {
    //console.log("MOUNTED APP CONTAINER");
    this.props.dispatch(
      setDimensions({
        dimensions: calculateDimensions(
          this.props.NavMenu.collapsed,
          this.props.Theme,
          this.props.Dimensions || false,
        ),
      }),
      this.setState({ loading: false }),
    );

    window.addEventListener('resize', this.resize, false);
    window.addEventListener('orientationchange', this.orientationchange, false);
  }
  componentWillUnmount() {
    //console.log("UNMOUNTING APP CONTAINER");
    window.removeEventListener('resize', this.resize, false);
    window.removeEventListener('orientationchange', this.orientationchange, false);
  }
  handleDrawerToggle = () => {
    this.props.dispatch(navMenuCollapse());
    this.props.dispatch(
      setDimensions({
        dimensions: calculateDimensions(
          !this.props.NavMenu.collapsed,
          this.props.Theme,
          this.props.Dimensions,
        ),
      }),
    );
    //if (this.props.Dimensions.maxWidth >= this.props.Theme.breakpoints.values.md) {
    //	this.props.dispatch(setDimensions( { dimensions: calculateDimensions(!this.props.NavMenu.collapsed , this.props.Theme, this.props.Dimensions) }));
    //}
  };

  renderComponent() {
    //return componentOf[this.props.component]
    //console.log("Rendering Component ...");
    let Component;
    if (this.props.isAdvancedUser === false) {
      // FETCH UUID OF PROCEDURE AND REDIRECT if its a JOB
      switch (this.props.component) {
        case 'MessageDetail':
          Component = componentOf['SimplifiedMessageDetail'];
          break;
        case 'Inbox':
          Component = componentOf['SimplifiedInbox'];
          break;
        case 'Job':
          Component = componentOf['Loading'];
          this.fetchRedirect();
          break;
        //case 'Jobs':
        //  Component = componentOf['SimplifiedInbox'];
        //  break;
        default:
          Component = componentOf[this.props.component ?? 'Loading'];
          break;
      }
    } else if (this.props.component === 'Job') {
      Component = componentOf['Loading'];
      this.fetchRedirect();
    } else {
      Component = componentOf[this.props.component ?? 'Loading'];
    }
    return <Component />;
  }

  async fetchRedirect() {
    const uuid = this.props.match.params.uuid;

    let response = await fetch(process.env.REACT_APP_API_URL + '/api/jobs/' + uuid + '/redirect', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => json)
      .catch((error) => {
        return error;
      });

    if (!!response.inbox_uuid) {
      //console.log(Redirect);
      //return Redirect({ to: { pathname: "/nachrichten/detail/" + response.inbox_uuid, state: { from: this.props.location } }});
      const location = {
        pathname: '/nachrichten/detail/' + response.inbox_uuid,
        state: { openPerformanceReport: response.job_uuid },
      };
      this.props.history.replace(location);
    } else {
      const location = {
        pathname: '/nachrichten/',
        state: { openPerformanceReport: response.job_uuid },
      };
      this.props.history.replace(location);
    }
  }

  render() {
    if (
      (this.state.loading && this.props.isLoading) ||
      this.state.loading ||
      this.props.isLoading
    ) {
      return <></>;
    }

    const { classes } = this.props;

    return (
      <ThemeProvider theme={responsiveFontSizes(createTheme(this.props.Theme))}>
        <BugCatcher>
          {this.props.isLoading ? (
            <div className={classes.center}>
              <CircularProgress />{' '}
            </div>
          ) : (
            <div id='app-root' className={classes.root}>
              <AppBar
                position='fixed'
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: this.props.NavMenu.collapsed,
                })}
                style={
                  this.props.NavMenu.collapsed
                    ? {
                        width:
                          this.props.Dimensions.width >= this.props.Theme.breakpoints.values.md
                            ? `calc(100% - ${this.props.Dimensions.drawerWidth}px)`
                            : '100%',
                        marginLeft: this.props.Dimensions.drawerWidth,
                      }
                    : {}
                }
              >
                <Toolbar disableGutters={true} className={classes.toolbar}>
                  <IconButton
                    id='logo-button'
                    color='inherit'
                    aria-label='open drawer'
                    onClick={this.handleDrawerToggle}
                    edge='start'
                    className={clsx(classes.menuButton, {
                      [classes.hide]: this.props.NavMenu.collapsed,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <TitleBar dimensions={this.props.Dimensions} />
                </Toolbar>
              </AppBar>

              <nav className={classes.fh}>
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation='js'>
                  <Drawer
                    variant='temporary'
                    anchor={this.props.Theme.direction === 'rtl' ? 'right' : 'left'}
                    open={this.props.NavMenu.collapsed}
                    onClose={this.handleDrawerToggle}
                    classes={{
                      paper: classes.drawerPaper,
                    }}
                    ModalProps={{
                      keepMounted: true, // Better open performance on mobile.
                    }}
                  >
                    <NavigationList
                      className={classes.toolbarMd}
                      toggleMenu={this.handleDrawerToggle}
                      isCollapsed={this.props.NavMenu.collapsed}
                      dimensions={this.props.Dimensions}
                    />
                  </Drawer>
                </Hidden>

                <Hidden smDown implementation='js'>
                  <Drawer
                    variant='permanent'
                    className={clsx(classes.drawer, {
                      [classes.drawerOpen]: this.props.NavMenu.collapsed,
                      [classes.drawerClose]: !this.props.NavMenu.collapsed,
                    })}
                    classes={{
                      paper: clsx(classes.drawer, {
                        [classes.drawerOpen]: this.props.NavMenu.collapsed,
                        [classes.drawerClose]: !this.props.NavMenu.collapsed,
                      }),
                    }}
                    style={{
                      width: this.props.Dimensions.drawerWidth + 'px',
                    }}
                    open={this.props.NavMenu.collapsed}
                  >
                    <NavigationList
                      className={classes.toolbarSm}
                      toggleMenu={this.handleDrawerToggle}
                      isCollapsed={this.props.NavMenu.collapsed}
                      dimensions={this.props.Dimensions}
                    />
                  </Drawer>
                </Hidden>
              </nav>

              <main className={classes.content}>
                <div className={classes.toolbarHeight} />
                {!this.state.loading ? this.renderComponent() : 'Loading ...'}
              </main>
            </div>
          )}
        </BugCatcher>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  isAdvancedUser: state.User?.isAdvancedUser ?? true,
  Authentication: state.Authentication,
  Router: state.router,
  User: state.User,
  Theme: state.Style.Theme,
  NavMenu: state.NavMenu,
  Dimensions: state.Dimensions,
  isLoading: state.Authentication.loading,
});
//const mapDispatchToProps = dispatch => bindActionCreators( {}, dispatch)
const mapDispatchToProps = (dispatch) => ({ dispatch });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(AppContainer);
