import { ADD, GET, REMOVE, RESET } from '../actions/Notifications';

const initialState = [];

const Notifications = (state = initialState, action) => {
  switch (action.type) {
    case GET:
      if (typeof action.notifications === 'object') {
        //state = 'data:' + action.config.logo.mime + ';base64,' + action.config.logo.img;
        state = action.notifications;
      } else {
        state = [];
      }
      return state;
    case ADD:
      if (!!action.notification.id) {
        return [action.notification, ...state];
      }
      return state;
    case REMOVE:
      if (!!action.notification.id) {
        let tmp = state.filter((element) => element.id !== action.notification.id);
        return tmp;
      } else if (!!action.notification.key) {
        let tmp = state.filter((element) => element.id !== action.notification.key);
        return tmp;
      }
      return state;
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default Notifications;
