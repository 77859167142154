function updateURLParameter(url, param, paramVal) {
  var TheAnchor = null;
  var newAdditionalURL = '';
  var tempArray = url.split('?');
  var baseURL = tempArray[0];
  var additionalURL = tempArray[1];
  var temp = '';
  var tmpAnchor = null;
  var TheParams = null;

  if (additionalURL) {
    tmpAnchor = additionalURL.split('#');
    TheParams = tmpAnchor[0];
    TheAnchor = tmpAnchor[1];
    if (TheAnchor) additionalURL = TheParams;

    tempArray = additionalURL.split('&');

    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = '&';
      }
    }
  } else {
    tmpAnchor = baseURL.split('#');
    TheParams = tmpAnchor[0];
    TheAnchor = tmpAnchor[1];

    if (TheParams) baseURL = TheParams;
  }

  if (TheAnchor) paramVal += '#' + TheAnchor;
  var rows_txt = '';
  if (Array.isArray(paramVal)) {
    paramVal.forEach((value) => {
      rows_txt +=
        value.length > 0 || value > 0 ? temp + '' + param + '=' + encodeURIComponent(value) : '';
      temp = '&';
    });
  } else {
    rows_txt =
      paramVal.length > 0 || paramVal > 0
        ? temp + '' + param + '=' + encodeURIComponent(paramVal)
        : '';
  }

  return baseURL + '?' + newAdditionalURL + rows_txt;
}
export default updateURLParameter;
