import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import TodayIcon from '@material-ui/icons/Today';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Calendar from '@toast-ui/react-calendar';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@material-ui/core/Fab';
import Collapse from '@material-ui/core/Collapse';
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@material-ui/core/Dialog';
import DetailDialog from './DetailDialog';
import AddAppointmentDialog from './AddAppointmentDialog';
import { DE as TextDE } from '../../lib/Text';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import TextField from '@material-ui/core/TextField';

import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import './calendar.css';
import calendarTheme from './calendarTheme';

const styles = (theme) => ({
  copyAlert: {
    height: '28px',
    padding: theme.spacing(0, 5),
    margin: '0',
    zIndex: 999,
    top: '2px',
    position: 'relative',
    border: '1px solid #000000cc',
    '&>div': {
      padding: '0',
      alignItems: 'center',
      display: 'flex',
    },
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  root: {
    padding: theme.spacing(1, 2),
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: '120px',
  },
  fab: {
    zIndex: 800,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  cardheader: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1),
    },
  },
});

const isSameDay = (start, end) => {
  const compareStart = `${start.getDate()}${start.getFullYear()}${start.getMonth()}`;
  const compareEnd = `${end.getDate()}${end.getFullYear()}${end.getMonth()}`;
  return compareStart === compareEnd;
};

const getCalWeek = (date) => {
  // In JavaScript the Sunday has value 0 as return value of getDay() function.
  // So we have to order them first ascending from Monday to Sunday
  // Monday: ((1+6) % 7) = 0
  // Tuesday ((2+6) % 7) = 1
  // Wednesday: ((3+6) % 7) = 2
  // Thursday: ((4+6) % 7) = 3
  // Friday: ((5+6) % 7) = 4
  // Saturday: ((6+6) % 7) = 5
  // Sunday: ((0+6) % 7) = 6
  // (3 - result) is necessary to get the Thursday of the current week.
  // If we want to have Tuesday it would be (1-result)
  var currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);

  // At the beginnig or end of a year the thursday could be in another year.
  var yearOfThursday = currentThursday.getFullYear();

  // Get first Thursday of the year
  var firstThursday = new Date(
    new Date(yearOfThursday, 0, 4).getTime() +
      (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000,
  );

  // +1 we start with week number 1
  // +0.5 an easy and dirty way to round result (in combinationen with Math.floor)
  var weekNumber = Math.floor(
    1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7,
  );

  return weekNumber;
};

let isDstObserved = (date) => {
  let summertimeBeginn = {
    2022: '1648346400000',
    2023: '1648260000000',
    2024: '1711850400000',
  };
  let summertimeEnd = {
    2022: '1667098800000',
    2023: '1698548400000',
    2024: '1729998000000',
  };
  let year =
    typeof date.getFullYear === 'function' ? date.getFullYear() : new Date(date).getFullYear();
  if (date > summertimeBeginn[year] && date < summertimeEnd[year]) {
    return true;
  }
  return false;
  //let jan = new Date(date.getFullYear(), 0, 1);
  //let jul = new Date(date.getFullYear(), 6, 1);
  //const offset = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  //return date.getTimezoneOffset() < offset;
};

const template = {
  task(schedule) {
    return `<span style="color:#fff;background-color: ${schedule.backgroundColor};">${schedule.title}</span>`;
  },
  taskTitle() {
    return 'Aufgaben';
  },
  allday(schedule) {
    return `${schedule.title}<i class="fa fa-refresh"></i>`;
  },
  alldayTitle() {
    return 'Tag';
  },
  timegridDisplayPrimaryTime: function (time) {
    return time.time.getHours() + ':00 Uhr';
  },
  timegridDisplayTime: function (time) {
    return time.time.getHours() + ':' + time.time.getMinutes() + ' Uhr';
  },
  popupDetailDate: function (isAllDay, start, end) {
    start = new Date(start);
    end = new Date(end);
    if (isAllDay) {
      if (isSameDay(start, end)) {
        return start.toLocaleDateString();
      }
      return start.toLocaleDateString() + ' bis ' + end.toLocaleDateString();
    }
    return (
      start.toLocaleTimeString() +
      ' Uhr bis ' +
      end.toLocaleTimeString() +
      ' Uhr am ' +
      end.toLocaleDateString()
    );
  },
  popupDetailLocation: function (schedule) {
    return schedule.location;
  },
  popupDetailState: function (schedule) {
    return 'popupDetailState: <pre>' + JSON.stringify(schedule.state, undefined, 4) + '</pre>';
  },
  popupDetailRepeat: function (schedule) {
    return (
      'popupDetailRepeat: <pre>' + JSON.stringify(schedule.recurrenceRule, undefined, 4) + '</pre>'
    );
  },
  popupDetailBody: function (schedule) {
    return schedule.body;
  },
  popupEdit: function () {
    return 'popupEdit';
  },
  popupDelete: function () {
    return 'popupDelete';
  },
  time: function (schedule) {
    let title = '<div style="display: inline-block;">' + schedule.title;
    if (schedule.attendees.length > 0) {
      title = title + '<br /><div style="display: flex;">';
      schedule.attendees.forEach((atd) => {
        if (!!atd.user && !!atd.user.firstname) {
          title =
            title +
            '<span style="margin: 2px 4px; background-position: center;background-size: contain;background-image: url(' +
            process.env.REACT_APP_API_URL +
            '/avatar/' +
            atd.user.uuid +
            ');display: flex;border: 0px solid white; background-color: black; color: white; border-radius: 50%; height: 48px; width: 48px;justifyContent-content: center;padding-top: 12px;">' +
            atd.user.firstname.substring(0, 1) +
            '' +
            atd.user.lastname.substring(0, 1) +
            '</span><br />';
        }
      });
      title = title + '</div>';
    }
    return title + '</div>';
  },
  monthGridHeader: function (dayModel) {
    var date = parseInt(dayModel.date.split('-')[2], 10);
    var classNames = ['tui-full-calendar-weekday-grid-date '];

    if (dayModel.isToday) {
      classNames.push('tui-full-calendar-weekday-grid-date-decorator');
    }

    if (dayModel.day === 1) {
      return (
        '<span class="' +
        classNames.join(' ') +
        '">' +
        date +
        ' <small style="margin-left: 12px; vertical-align: top;"><b>[KW: ' +
        getCalWeek(new Date(dayModel.date)) +
        ']</b></small> </span>'
      );
    }
    return '<span class="' + classNames.join(' ') + '">' + date + '</span>';
  },
};

class CalendarWrapper extends React.Component {
  calendarRef = React.createRef();
  calendarInst = null;

  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      calendars: [],
      dateRange: '...',
      isReadOnly: this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.sm,
      view:
        this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.sm ? 'day' : 'week',
      timeRangeAll: false,
      viewModeOptions: [
        {
          title: TextDE.calendar.overview.day,
          value: 'day',
        },
        {
          title: TextDE.calendar.overview.month,
          value: 'month',
        },
        {
          title: TextDE.calendar.overview.week,
          value: 'week',
        },
      ],
      detailDialogOpen: false,
      addDialogOpen: false,
      calendarLinkOpen: false,
      calendarLink: false,
      calendarLinkCopied: false,
      detail: {},
      contacts: [],
      users: [],
      timezones: [
        {
          timezoneOffset: 60,
          displayLabel: 'GMT+01:00 - Winterzeit',
          tooltip: 'Berlin/Winterzeit',
        },
        {
          timezoneOffset: 120,
          displayLabel: 'GMT+02:00 - Sommerzeit',
          tooltip: 'Berlin/Sommerzeit',
        },
      ],
      selectedTimezone: [
        {
          timezoneOffset: 60,
          displayLabel: 'GMT+01:00 - Winterzeit',
          tooltip: 'Berlin/Winterzeit',
        },
      ],
    };

    this.handleCalendarLinkToggle = this.handleCalendarLinkToggle.bind(this);
    this.handleClickDayname = this.handleClickDayname.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);

    //this.onAfterRenderSchedule = this.onAfterRenderSchedule.bind(this)
    this.onBeforeCreateSchedule = this.onBeforeCreateSchedule.bind(this);
    this.onBeforeUpdateSchedule = this.onBeforeUpdateSchedule.bind(this);
    //this.onBeforedeleteEvent = this.onBeforedeleteEvent.bind(this)

    this.getCalendar = this.getCalendar.bind(this);

    this.handleDetailDialogClose = this.handleDetailDialogClose.bind(this);
    this.handleDetailDialogOpen = this.handleDetailDialogOpen.bind(this);
    this.handleDetailDialogUpdate = this.handleDetailDialogUpdate.bind(this);

    this.handleAddDialogUpdateClose = this.handleAddDialogUpdateClose.bind(this);
    this.handleAddDialogClose = this.handleAddDialogClose.bind(this);
    this.handleAddDialogOpen = this.handleAddDialogOpen.bind(this);
  }

  handleCalendarLinkToggle() {
    this.loadCalendarLink();
    this.setState({ calendarLinkOpen: !this.state.calendarLinkOpen, calendarLink: null });
  }
  async loadCalendarLink() {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/calendarlink');
    let response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return !!json ? json : false;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.link) {
      this.setState({ calendarLink: response.link });
    } else {
      this.setState({ calendarLink: !!response.error ? response.error : 'Unbekannter Fehler' });
    }
  }

  async getCalendar() {
    if (!!!this.state.isFetching) {
      this.setState({ isFetching: true, calendar: [] });
    }

    this.calendarInst = this.calendarRef.current.getInstance();
    let startdate = null;
    let params = '';
    let detail = false;
    let reRender = false;
    if (window.location.pathname.includes('.ics')) {
      detail = window.location.pathname.split('/');
      detail = detail[detail.length - 1];
      params = 'ics=' + detail;
    } else {
      if (!!this.calendarInst) {
        //console.log("in if, has calendar", this.calendarInst.renderRange.start.d, this.calendarInst.renderRange.start.getTime());
        startdate = this.calendarInst.renderRange.start.toDate();
        if (isDstObserved(startdate)) {
          //its summertime bitch!
          if (this.state.selectedTimezone[0].timezoneOffset === 60) {
            reRender = true;
          }
          this.calendarInst.setOptions({ timezones: [this.state.timezones[1]] });
          this.setState({ selectedTimezone: [this.state.timezones[1]] });
        } else {
          if (this.state.selectedTimezone[0].timezoneOffset === 120) {
            reRender = true;
          }
          this.calendarInst.setOptions({ timezones: [this.state.timezones[0]] });
          this.setState({ selectedTimezone: [this.state.timezones[0]] });
        }
        switch (this.state.view) {
          case 'week':
            params +=
              'type=week&start=' +
              Date.parse(this.calendarInst.renderRange.start.toDate().toUTCString()); //+ '&end=' + Date.parse(this.calendarInst.renderRange.end.toUTCString());
            break;
          case 'month':
            params +=
              'type=month&start=' +
              Date.parse(this.calendarInst.renderRange.start.toDate().toUTCString()); //+ '&end=' + Date.parse(this.calendarInst.renderRange.end.toUTCString());
            break;
          case 'day':
            params +=
              'type=day&start=' +
              Date.parse(this.calendarInst.renderRange.start.toDate().toUTCString()); //+ '&end=' + Date.parse(this.calendarInst.renderRange.end.toUTCString());
            break;
          default:
            params +=
              'type=week&start=' +
              Date.parse(this.calendarInst.renderRange.start.toDate().toUTCString()); //+ '&end=' + Date.parse(this.calendarInst.renderRange.end.toUTCString());
            break;
        }
      } else {
        //console.log("in else, has no calendar", this.getStartOfWeek(), this.getStartOfMonth(), new Date().setHours(0,0,0,0), this.getStartOfWeek());
        switch (this.state.view) {
          case 'week':
            params += 'type=week&start=' + this.getStartOfWeek();
            startdate = this.getStartOfWeek();
            break;
          case 'month':
            params += 'type=month&start=' + this.getStartOfMonth();
            startdate = this.getStartOfMonth();
            break;
          case 'day':
            params += 'type=day&start=' + new Date().setHours(0, 0, 0, 0);
            startdate = new Date().setHours(0, 0, 0, 0);
            break;
          default:
            params += 'type=week&start=' + this.getStartOfWeek();
            startdate = this.getStartOfWeek();
            break;
        }
        if (isDstObserved(startdate)) {
          //its summertime bitch!
          if (this.state.selectedTimezone[0].timezoneOffset === 60) {
            reRender = true;
          }
          this.calendarInst.setOptions({ timezones: [this.state.timezones[1]] });
          this.setState({ selectedTimezone: [this.state.timezones[1]] });
        } else {
          if (this.state.selectedTimezone[0].timezoneOffset === 120) {
            reRender = true;
          }
          this.calendarInst.setOptions({ timezones: [this.state.timezones[0]] });
          this.setState({ selectedTimezone: [this.state.timezones[0]] });
        }
      }
    }

    //this.setState({view: this.props.view});
    let url = new URL(process.env.REACT_APP_API_URL + '/api/calendar?' + params);
    let response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return !!json ? json : false;
      })
      .catch((error) => {
        return error;
      });
    if (response) {
      let data = Object.values(response).map((event) => {
        if (event.calendarId === this.props.User.calendarid) {
          event.color = this.props.Theme.palette.primary.contrastText;
          event.backgroundColor = this.props.Theme.palette.primary.main;
          event.borderColor = this.props.Theme.palette.primary.dark;
        } else {
          event.color = this.props.Theme.palette.secondary.contrastText;
          event.backgroundColor = this.props.Theme.palette.secondary.main;
          event.borderColor = this.props.Theme.palette.secondary.dark;
        }
        return event;
      });
      this.calendarInst.clear(true);
      if (!!detail) {
        let tmp = response[detail.slice(0, -4)];
        if (!!tmp) {
          let dateStart = this.getStartOfWeekByDate(tmp.start);
          if (window.location.pathname.includes('.ics')) {
            this.calendarInst.setDate(dateStart);
          }
          //console.log(this.calendarInst._options.timezones[0].displayLabel);
          if (isDstObserved(dateStart)) {
            //its summertime bitch!
            if (this.state.selectedTimezone[0].timezoneOffset === 60) {
              reRender = true;
            }
            this.calendarInst.setOptions({ timezones: [this.state.timezones[1]] });
            this.setState({ selectedTimezone: [this.state.timezones[1]] });
            //this.setState({selectedTimezone: [ this.state.timezones[1]], isFetching: false, calendars: data}, () => { console.log("clear calendareIns"); this.calendarInst.clear(true); /* this.calendarInst.render(true);*/ });
          } else {
            if (this.state.selectedTimezone[0].timezoneOffset === 120) {
              reRender = true;
            }
            this.calendarInst.setOptions({ timezones: [this.state.timezones[0]] });
            this.setState({ selectedTimezone: [this.state.timezones[0]] });
            //this.setState({selectedTimezone: [ this.state.timezones[0]], isFetching: false, calendars: data}, () => { console.log("clear calendareIns"); this.calendarInst.clear(true); /* this.calendarInst.render(true);*/ });
          }
          this.calendarInst.createEvents(data);

          this.setRenderRangeText();
          //console.log(this.calendarInst._options.timezones[0].displayLabel);
          //console.log(this.calendarInst._options);

          var schedule = this.calendarInst.getEvent(tmp.id, tmp.calendarId);
          //console.log("[ success - has detail - defined]", schedule);
          this.setState({
            detailDialogOpen: true,
            detail: schedule,
            isFetching: false,
          });
        } else {
          this.calendarInst.createEvents(data);
          window.history.pushState('', '', '/kalender/');
          this.setState({ isFetching: false });
        }
      } else {
        // Removed this -> this would have set the date again with teh data fom the server - but we do have the correct mont/week now!
        //this.calendarInst.setDate(startdate);
        //console.log(this.calendarInst._options.timezones[0].displayLabel);
        if (isDstObserved(startdate)) {
          //its summertime bitch!
          if (this.state.selectedTimezone[0].timezoneOffset === 60) {
            reRender = true;
          }
          this.calendarInst.setOptions({ timezones: [this.state.timezones[1]] });
          this.setState({ selectedTimezone: [this.state.timezones[1]] });
          //this.setState({selectedTimezone: [ this.state.timezones[1]], isFetching: false, calendars: data}, () => { console.log("clear calendareIns"); this.calendarInst.clear(true); /* this.calendarInst.render(true);*/ });
        } else {
          if (this.state.selectedTimezone[0].timezoneOffset === 120) {
            reRender = true;
          }
          this.calendarInst.setOptions({ timezones: [this.state.timezones[0]] });
          this.setState({ selectedTimezone: [this.state.timezones[0]] });
          //this.setState({selectedTimezone: [ this.state.timezones[0]], isFetching: false, calendars: data}, () => { console.log("clear calendareIns"); this.calendarInst.clear(true); /* this.calendarInst.render(true);*/ });
        }
        this.calendarInst.createEvents(data);
        this.setRenderRangeText();
        this.setState({ isFetching: false });
        //this.setState({isFetching: false, calendars: data}, () => { /* this.calendarInst.render(true);*/  this.setRenderRangeText(); });
      }
    } else {
      console.error('[ error while loading ]', response);
      this.calendarInst.createEvents([]);
      // Removed this -> this would have set the date again with teh data fom the server - but we do have the correct mont/week now!
      //this.calendarInst.setDate(startdate);
      this.setRenderRangeText();
      this.setState({ isFetching: false, calendars: [], error: response });
      /*
            this.setState({isFetching: false, calendars: [], error: response}, () => { 
                // this.calendarInst.render(true);
                this.setRenderRangeText();
              });
            */
    }
    if (reRender) {
      //console.log("rerender", this.calendarInst);
      this.calendarInst.render(true);
      reRender = false;
    }
  }

  async componentDidMount() {
    let contacts = await fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = [];
        for (var key in json) {
          if (!!json[key].emails) {
            if (json[key].emails.length > 1) {
              for (var email in json[key].emails) {
                tmp.push({
                  full:
                    json[key].salutation.name +
                    (json[key].salutation_id === 4
                      ? ' ' + json[key].name
                      : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                        json[key].firstname +
                        ' ' +
                        json[key].name),
                  mail: json[key].emails[email].email,
                  pref: json[key].emails[email].pref,
                  work: json[key].emails[email].work,
                });
              }
            } else if (json[key].emails.length === 1) {
              tmp.push({
                full:
                  json[key].salutation.name +
                  (json[key].salutation_id === 4
                    ? ' ' + json[key].name
                    : (!!json[key].title?.id ? ' ' + json[key].title.name + ' ' : ' ') +
                      json[key].firstname +
                      ' ' +
                      json[key].name),
                mail: json[key].emails[0].email,
                pref: json[key].emails[0].pref,
                work: json[key].emails[0].work,
              });
            }
          }
        }
        return tmp;
      })
      .catch((error) => {
        console.log(TextDE.mistake, error);
        return [];
      });

    let users = await fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = [];
        json.forEach((value, key) => {
          if (!!!value.deleted_at) {
            tmp.push({
              uuid: value.uuid,
              //full: value.firstname +((!!value.nickname) ? ' \''+value.nickname+'\' ': ' ')+ value.lastname,
              full: value.firstname + ' ' + value.lastname,
              mail: value.email,
              roles: value.roles.map((role) => role.name),
            });
          }
        });
        return tmp;
      })
      .catch((error) => {
        console.log(TextDE.mistake, error);
        return [];
      });

    this.setState({ contacts: contacts, users: users }, this.getCalendar);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.view !== prevState.view) {
      this.getCalendar();
    }
    //else if (this.state.dateRange !== prevState.dateRange ) {
    //    this.getCalendar();
    //}
  }

  handleClickIconButton = () => {
    this.calendarRef.current.getRootElement().classList.add('calendar-root');
  };

  handleClickNextIconButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.next();
    //console.log("handleClickNextIconButton", calendarInstance.renderRange.start, calendarInstance.renderRange.start.getTime(), calendarInstance, )
    this.getCalendar();
  };

  handleClickPreviousIconButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.prev();
    //console.log("handleClickPreviousIconButton", calendarInstance.renderRange.start, calendarInstance.renderRange.start.getTime(), calendarInstance, )
    this.getCalendar();
  };

  handleClickTodayIconButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.today();
    this.getCalendar();
  };

  handleClickDayname(ev) {
    let start = new Date(Date.parse(ev.date));
    start.setHours(0, 0, 1, 1);
    let end = new Date(Date.parse(ev.date));
    end.setHours(23, 59, 59, 999);

    let schedule = {
      isAllDay: true,
      start: start,
      end: end,
    };
    this.setState({ addDialogOpen: true, detail: schedule });
  }

  getStartOfWeekByDate(date) {
    let d = new Date(date);
    var day = d.getDay();
    var diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    let res = new Date(d.setDate(diff)).setHours(0, 0, 0, 0);
    return res;
  }

  getStartOfWeek() {
    let d = new Date();
    var day = d.getDay();
    var diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    let date = new Date(d.setDate(diff)).setHours(0, 0, 0, 0);
    return date;
  }

  getStartOfMonth() {
    let d = new Date();
    d.setDate(1);
    return d.setHours(0, 0, 0, 0);
  }

  //onAfterRenderSchedule(res) {
  //  console.group('onAfterRenderSchedule');
  //  console.log('Schedule Info : ', res.schedule);
  //  console.groupEnd();
  //}

  onChangeSelect(ev) {
    this.setState({ view: ev.target.value });
    this.setRenderRangeText();
  }

  setRenderRangeText() {
    const view = this.calendarInst.getViewName();
    const calDate = this.calendarInst.getDate();
    const rangeStart = this.calendarInst.renderRange.start.toDate();
    const rangeEnd = this.calendarInst.renderRange.end.toDate();

    let year = calDate.getFullYear();
    let month = calDate.getMonth() + 1;
    let date = calDate.getDate();
    let dateRangeText = '';
    let endMonth, endDate, start, end;

    let months = [
      TextDE.calendar.overview.month1,
      TextDE.calendar.overview.month2,
      TextDE.calendar.overview.month3,
      TextDE.calendar.overview.month4,
      TextDE.calendar.overview.month5,
      TextDE.calendar.overview.month6,
      TextDE.calendar.overview.month7,
      TextDE.calendar.overview.month8,
      TextDE.calendar.overview.month9,
      TextDE.calendar.overview.month10,
      TextDE.calendar.overview.month11,
      TextDE.calendar.overview.month12,
    ];
    switch (view) {
      case 'month':
        let monthDate = new Date(
          this.calendarInst.renderRange.start.getTime() +
            (this.calendarInst.renderRange.end.getTime() -
              this.calendarInst.renderRange.start.getTime()) /
              2,
        );
        dateRangeText = months[monthDate.getMonth()] + ' ' + monthDate.getFullYear();
        break;
      case 'week':
        month = rangeStart.getMonth() + 1;
        date = rangeStart.getDate();
        endMonth = rangeEnd.getMonth() + 1;
        endDate = rangeEnd.getDate();
        year = rangeEnd.getFullYear();

        start = `${date < 10 ? '0' : ''}${date}.${month < 10 ? '0' : ''}${month}`;
        end = `${endDate < 10 ? '0' : ''}${endDate}.${
          endMonth < 10 ? '0' : ''
        }${endMonth} - ${year}`;

        dateRangeText = `${start}  bis ${end} [KW: ${getCalWeek(rangeStart)}]`;
        break;
      default:
        dateRangeText = `${date < 10 ? '0' : ''}${date}.${month < 10 ? '0' : ''}${month}.${year}`;
    }

    this.setState({ dateRange: dateRangeText, detail: {} });
  }

  async updateCalendar(schedule, changes) {
    let data = {
      uid: schedule.raw.uid,
      calendarId: schedule.calendarId,
      id: schedule.id,
    };

    let attendees = [];
    let users = [];
    if (!!schedule?.attendees) {
      schedule.attendees.forEach((attendee) => {
        if (!!attendee.user) {
          let tmp = this.state.users.find((element) => element.uuid === attendee.user.uuid);
          if (!!tmp && !!tmp.uuid) {
            users.push(tmp);
          } else {
            users.push({
              full: attendee.full || attendee.email,
              mail: attendee.email,
              uuid: attendee.user.uuid,
            });
          }
        } else {
          attendees.push({
            full: attendee.cn || attendee.email,
            mail: attendee.email,
          });
        }
      });
    }
    //console.log("[UPDATE Calendar] get Attendee and users", attendees, users);

    data['linkType'] =
      !!schedule?.raw && !!schedule?.raw?.linkItem && !!schedule?.raw?.linkItem?.linkType
        ? schedule.raw.linkItem.linkType
        : null;
    data['linkTo'] =
      !!schedule?.raw && !!schedule?.raw?.linkItem && !!schedule?.raw?.linkItem?.uuid
        ? schedule.raw.linkItem.uuid
        : null;
    data['attendees'] = JSON.stringify([...attendees, ...users]);
    data['users'] = JSON.stringify(users);

    //console.log("[UPDATE Calendar] final insert data", data);

    if (!!changes.start) {
      data.firstoccurence = changes.start.getTime() / 1000;
    }
    if (!!changes.end) {
      data.lastoccurence = changes.end.getTime() / 1000;
    }

    let url = new URL(process.env.REACT_APP_API_URL + '/api/calendar/' + schedule.raw.uid);
    let response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return !!json ? json : false;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.id && !!response.calendarId) {
      return response;
    } else {
      return false;
    }
  }

  async onBeforeUpdateSchedule(tmp) {
    //console.log('onBeforeUpdateSchedule: ', tmp);
    const { event } = tmp;
    const { changes } = tmp;
    let update = await this.updateCalendar(event, changes);
    //console.log('onBeforeUpdateSchedule   # 2: ', update);
    if (!!update) {
      //console.log('onBeforeUpdateSchedule   # 3: ', update);
      this.calendarInst.updateEvent(event.id, event.calendarId, update);
    }
  }

  onBeforeCreateSchedule(scheduleData) {
    //console.log("onBeforeCreateSchedule", scheduleData);

    let start = new Date(Date.parse(scheduleData.start));
    let end = new Date(Date.parse(scheduleData.end));
    if (end.getTime() === start.getTime() && !!scheduleData.isAllday) {
      start = start.setHours(0, 0, 1, 1);
      end = end.setHours(23, 59, 59, 999);
    }

    let schedule = {
      isAllDay: scheduleData.isAllday,
      start: start,
      end: end,
    };

    this.setState({ addDialogOpen: true, detail: schedule }, () =>
      this.calendarRef.current.getInstance().clearGridSelections(),
    );
  }

  async handleDetailDialogClose(reload) {
    //this.props.dispatch(push('/kalender/'));
    window.history.pushState('', '', '/kalender/');
    //document.location.href = "/kalender/";
    //console.log('handleDetailDialogClose', reload);
    if (!!reload.id && !!reload.calendarId) {
      this.calendarInst.deleteEvent(reload.id, reload.calendarId);
      this.setState({ detailDialogOpen: false, detail: {} });
    } else {
      this.setState({ detailDialogOpen: false, detail: {} });
    }
  }

  async handleDetailDialogUpdate(data) {
    //this.props.dispatch(push('/kalender/'));
    //document.location.href = "/kalender/";
    if (!!data.id && !!data.calendarId) {
      var schedule = this.calendarInst.getEvent(data.id, data.calendarId);
      schedule = { ...schedule, ...data };
      this.setState({ detailDialogOpen: true, detail: schedule });
    }
  }

  async handleDetailDialogEdit(schedule) {
    if (!!schedule.id && !!schedule.calendarId) {
      this.setState({ detailDialogOpen: false, detail: schedule }, () =>
        this.setState({ addDialogOpen: true }),
      );
    }
  }

  handleDetailDialogOpen(res) {
    //console.log("handleDetailDialogOpen", res)
    window.history.pushState('', '', '/kalender/' + res.event?.raw?.uri);
    this.setState({ detailDialogOpen: true, detail: res.event });
  }

  async handleAddDialogClose(reload) {
    //console.log("handleAddDialogClose - check if reload ", reload, !!reload.id);
    if (!!reload.id) {
      this.calendarInst.createEvents([reload]);
      this.setState({ addDialogOpen: false });
    } else {
      this.setState({ addDialogOpen: false });
    }
  }
  async handleAddDialogUpdateClose(reload) {
    //console.log("handleAddDialogUpdateClose - check if reload ", reload, !!reload.id);
    if (!!reload.id) {
      this.calendarInst.updateEvent(reload.id, reload.calendarId, reload);
      this.setState({ addDialogOpen: false, detail: {} });
    } else {
      this.setState({ addDialogOpen: false, detail: {} });
    }
  }

  handleAddDialogOpen(schedule) {
    this.setState({ addDialogOpen: true, detail: schedule });
  }

  copyToClipboard = (element) => {
    this.setState({ calendarLinkCopied: true });
    navigator.clipboard.writeText(this.state.calendarLink);
    setTimeout(() => this.setState({ calendarLinkCopied: false }), 3000);
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Fab
          onClick={() => this.handleAddDialogOpen({})}
          className={classes.fab}
          color='primary'
          aria-label='edit'
        >
          <AddIcon />
        </Fab>

        <Dialog
          open={this.state.calendarLinkOpen}
          onClose={this.handleCalendarLinkToggle}
          aria-labelledby='form-dialog-title'
        >
          <DialogTitle id='form-dialog-title'>Kalender Abonnement</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Diesen Link in Ihrem Smartphone als Kalender Abonnement hinzufügen
            </DialogContentText>
            {this.state.calendarLink === null ? (
              <CircularProgress />
            ) : (
              <TextField
                autoFocus
                variant='outlined'
                margin='dense'
                id='calendarlink'
                label='Kalender Abo Link'
                type='text'
                value={this.state.calendarLink}
                fullWidth
              />
            )}
            <Collapse
              in={!!this.state.calendarLinkCopied}
              style={{ width: '100%', height: 0, zIndex: 999 }}
            >
              <Alert
                className={classes.copyAlert}
                onClose={this.handleCopyClose}
                severity='success'
              >
                Kopiert
              </Alert>
            </Collapse>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.copyToClipboard('calendarlink')} color='primary'>
              Kopieren
            </Button>
            <Button onClick={this.handleCalendarLinkToggle} color='primary'>
              Schließen
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.sm}
          maxWidth='md'
          onClose={this.handleDetailDialogClose}
          open={this.state.detailDialogOpen}
        >
          <DetailDialog
            data={this.state.detail}
            handleeditfunction={(schedule) => this.handleDetailDialogEdit(schedule)}
            handleclosefunction={(reload) => this.handleDetailDialogClose(reload)}
            handleupdatefunction={(data) => this.handleDetailDialogUpdate(data)}
          />
        </Dialog>

        <Dialog
          fullScreen={this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.sm}
          maxWidth='lg'
          onClose={this.handleAddDialogClose}
          open={this.state.addDialogOpen}
        >
          <AddAppointmentDialog
            contacts={this.state.contacts}
            users={this.state.users}
            data={this.state.detail}
            handleclosefunction={(reload) => this.handleAddDialogClose(reload)}
            handleupdatefunction={(reload) => this.handleAddDialogUpdateClose(reload)}
          />
        </Dialog>
        <div
          style={{
            margin: 0,
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'flex',
          }}
        >
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column',
              flexGrow: '1',
              margin: '8px',
            }}
          >
            <Hidden smDown>
              <CardHeader
                className={classes.cardheader}
                disableTypography
                title={
                  <Grid
                    container
                    spacing={2}
                    direction='row'
                    justifyContent='space-between'
                    alignItems='center'
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing={0}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='flex-start'
                      >
                        <Grid item>
                          <IconButton onClick={this.handleClickPreviousIconButton}>
                            <ArrowBackIosIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={this.handleClickTodayIconButton}>
                            <TodayIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={this.handleClickNextIconButton}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Typography variant='h5'> {this.state.dateRange}</Typography>
                    </Grid>
                    <Grid item>
                      <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                        <Grid item>
                          <Button
                            className={classes.mr}
                            size='large'
                            variant='outlined'
                            color='primary'
                            onClick={this.handleCalendarLinkToggle}
                          >
                            Kalender Link
                          </Button>
                        </Grid>
                        {this.state.view !== 'month' && (
                          <Grid item>
                            <Button
                              size='large'
                              color={!!!this.state.timeRangeAll ? 'primary' : 'secondary'}
                              label={!!!this.state.timeRangeAll ? '6-22' : '24 H'}
                              variant={!!!this.state.timeRangeAll ? 'outlined' : 'contained'}
                              onClick={() =>
                                this.setState({
                                  timeRangeAll: !!!this.state.timeRangeAll,
                                })
                              }
                            >
                              {!!!this.state.timeRangeAll
                                ? TextDE.calendar.appointmentForm.possibleTimeWindow
                                : '24 H'}
                            </Button>
                          </Grid>
                        )}
                        <Grid item>
                          <FormControl
                            size='small'
                            variant='outlined'
                            className={classes.formControl}
                          >
                            <InputLabel id='view-selector'>{TextDE.calendar.display}</InputLabel>
                            <Select
                              labelId='view-selector'
                              value={this.state.view}
                              onChange={this.onChangeSelect}
                              label='Anzeige'
                            >
                              {this.state.viewModeOptions.map((option, index) => (
                                <MenuItem value={option.value} key={index}>
                                  {option.title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                }
                style={{ padding: '8px 16px' }}
              />
            </Hidden>

            <Hidden mdUp>
              <CardHeader
                className={classes.cardheader}
                disableTypography
                title={
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item>
                      <Grid
                        container
                        spacing={0}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                      >
                        <Grid item>
                          <IconButton onClick={this.handleClickPreviousIconButton}>
                            <ArrowBackIosIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={this.handleClickTodayIconButton}>
                            <TodayIcon />
                          </IconButton>
                        </Grid>
                        <Grid item>
                          <IconButton onClick={this.handleClickNextIconButton}>
                            <ArrowForwardIosIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 'auto' }}>
                      <IconButton color='primary' onClick={this.handleCalendarLinkToggle}>
                        <FileCopyIcon />
                      </IconButton>
                    </Grid>
                    <Grid item className={classes.mr}>
                      <FormControl variant='outlined' className={classes.formControl}>
                        <InputLabel id='view-selector'>Anzeige</InputLabel>
                        <Select
                          labelId='view-selector'
                          value={this.state.view}
                          onChange={this.onChangeSelect}
                          label='Anzeige'
                        >
                          {this.state.viewModeOptions.map((option, index) => (
                            <MenuItem value={option.value} key={index}>
                              {option.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                }
                subheader={
                  <Typography variant='h6' component='center'>
                    {this.state.dateRange}
                  </Typography>
                }
              />
            </Hidden>

            <CardContent style={{ flexGrow: 1, padding: '0', margin: '0' }}>
              <LinearProgress
                variant={!!this.state.isFetching ? 'indeterminate' : 'determinate'}
                value={!!this.state.isFetching ? 0 : 100}
              />
              <Calendar
                height='100%'
                usageStatistics={false}
                useCreationPopup={false}
                useDetailPopup={false}
                defaultView='week'
                gridSelection={true}
                isReadOnly={this.state.isReadOnly}
                month={{
                  isAlways6Weeks: false,
                  dayNames: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
                  startDayOfWeek: 1,
                  narrowWeekend: true,
                  taskView: false,
                  eventView: true,
                }}
                events={this.state.calendars}
                template={template}
                theme={calendarTheme}
                view={this.state.view}
                timezones={this.state.selectedTimezone}
                week={{
                  showTimezoneCollapseButton: false,
                  startDayOfWeek: 1,
                  timezonesCollapsed: true,
                  taskView: false,
                  eventView: true,
                  narrowWeekend: true,
                  hourStart: !!this.state.timeRangeAll ? 0 : 6,
                  hourEnd: !!this.state.timeRangeAll ? 24 : 22,
                  dayNames: [
                    TextDE.calendar.overview.day7,
                    TextDE.calendar.overview.day1,
                    TextDE.calendar.overview.day2,
                    TextDE.calendar.overview.day3,
                    TextDE.calendar.overview.day4,
                    TextDE.calendar.overview.day5,
                    TextDE.calendar.overview.day6,
                  ],
                }}
                ref={this.calendarRef}
                onBeforeUpdateEvent={this.onBeforeUpdateSchedule}
                onClickDayname={this.handleClickDayname}
                onClickEvent={this.handleDetailDialogOpen}
                onSelectDateTime={this.onBeforeCreateSchedule}
              />
            </CardContent>
          </Card>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(CalendarWrapper);
