import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';

const CustomDatePicker = (props) => {
  const [date, setDate] = useState(null);

  const handleDelete = () => {
    setDate(null);
    props.onFilterChanged(props.columnDef.tableData.id, null);
  };

  const handleChange = (event) => {
    if (event === null) {
      setDate(null);
      props.onFilterChanged(props.columnDef.tableData.id, null);
      return;
    }
    setDate(event);
    props.onFilterChanged(
      props.columnDef.tableData.id,
      `${event.getDate()}-${event.getMonth() + 1}-${event.getFullYear()}`,
    );
  };

  /* For DatePicker
          InputProps={{
            style: {paddingRight: '0'},
            endAdornment: !!date 
              ? <InputAdornment position="end" >
                  <IconButton
                    onClick={handleDelete}
                    aria-label='delete'                    
                  >
                    <ClearIcon />
                  </IconButton> 
                </InputAdornment>
            : null
          }}*/

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          style={{ flexGrow: 1, justifyContent: 'center' }}
          disableFuture
          margin='none'
          id='date-picker-dialog'
          format='dd.MM.yyyy'
          clearable={false}
          value={date}
          size='small'
          inputVariant='outlined'
          onChange={handleChange}
        />
      </MuiPickersUtilsProvider>
      {!!date && (
        <IconButton aria-label='delete' onClick={handleDelete}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};
export default CustomDatePicker;
