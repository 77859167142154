import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import Zoom from '@material-ui/core/Zoom';
import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import { DE as TextDE } from '../../../../../lib/Text';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  paper: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    minWidth: '50vw',
    maxWidth: '90vw',
    maxHeight: '90vh',
    overflow: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  input: {
    backgroundColor: 'antiquewhite',
    fontSize: '1.5em',
  },
  font: {
    fontSize: '1.3em',
  },
  checkboxSize: {
    '&>span': {
      '&>svg': {
        width: '1.5em',
        height: '1.5em',
      },
    },
  },
  head: {
    fontWeight: '800',
    fontSize: '120%',
  },
  description: {
    maxWidth: '65%',
    minWidth: '65%',
  },
  count: {
    maxWidth: '28%',
    minWidth: '20%',
  },
  delete: {
    display: 'block',
    maxWidth: '160px',
    minWidth: '7%',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  materialsTable: {
    marginBottom: theme.spacing(2),
    '&>tbody': {
      '&>tr': {
        '&>th': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: '5',
          WebkitBoxOrient: 'vertical',
        },
      },
    },
  },
  floatRight: {
    float: 'right',
  },
  alignEndIfBig: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'end',
    },
  },
});

class Parts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      hasError: false,
      edit: false,
      deleteMaterial: false,
      [props.name]: props[props.name],
      product_id: '',
      partsSearch: [],
      selectedPart: null,
      part: null,
      unit_id: 14,
      amount: 1,
      price: 0.0,
      allowance: false,
      name: '',
      category: '',
      unit: [
        {
          id: 0,
          name: 'Dienstleistung',
          short: 'Dienstl.',
        },
        {
          id: 1,
          name: 'Stück',
          short: 'Stk.',
        },
        {
          id: 2,
          name: 'Packung',
          short: 'P.',
        },
        {
          id: 3,
          name: 'Paket',
          short: 'Pkt.',
        },
        {
          id: 4,
          name: 'Dose',
          short: 'Dose',
        },
        {
          id: 5,
          name: 'Rolle',
          short: 'Rolle',
        },
        {
          id: 6,
          name: 'Tube',
          short: 'Tube',
        },
        {
          id: 7,
          name: 'Meter',
          short: 'm',
        },
        {
          id: 8,
          name: 'Laufmeter',
          short: 'Lfm.',
        },
        {
          id: 9,
          name: 'Kilometer',
          short: 'Km',
        },
        {
          id: 10,
          name: 'Quadratmeter',
          short: 'm²',
        },
        {
          id: 11,
          name: 'Kubikmeter',
          short: 'm³',
        },
        {
          id: 12,
          name: 'Liter',
          short: 'l',
        },
        {
          id: 13,
          name: 'Kilogramm',
          short: 'Kg',
        },
        {
          id: 14,
          name: '(Keine Einheit)',
          short: '',
        },
      ],
    };

    this.closeWindow = this.closeWindow.bind(this);
  }

  async fetchParts(query) {
    //api/material?per_page=25&page=1&search=Kategor
    query = encodeURI(query.trim());

    let parts = await fetch(process.env.REACT_APP_API_URL + '/api/material?search=' + query, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        if (json?.data?.length > 0) {
          return json.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        return [];
      });

    return parts;
  }

  editMaterial = (index, data) => {
    this.setState(
      {
        edit: data.id,
        part: data.part,
        amount: data.amount,
      },
      () => this.props.zoomFunction(),
    );
  };

  deleteMaterial = (index, data) => {
    this.setState({ deletePart: data }, () => this.props.zoomFunction());
  };

  deleteFetch = async () => {
    if (!!!this.state.deletePart) {
      this.setState({
        hasError: {
          status: TextDE.performanceReport.tableParts.notFound,
        },
      });
      return;
    }
    let data = this.state.deletePart;
    let url = new URL(process.env.REACT_APP_API_URL + '/api/parts/' + this.props.uuid);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'DELETE', id: data.id }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
        return error;
      });
    if (!!response.deleted) {
      let tmp = this.state[this.props.name].filter((element) => element.id !== data.id);

      if (data.id === this.state.edit) {
        this.setState({
          unit_id: 0,
          amount: 1,
          allowance: false,
          price: 0.0,
          category: '',
          isSubmitting: false,
          edit: false,
          part: null,
          validation: '',
          newPart: false,
        });
      }
      this.setState({ [this.props.name]: tmp }, () =>
        this.props.onChange(this.props.name, this.state[this.props.name]),
      );
      return;
    }
    this.setState({
      isSubmitting: false,
      hasError: response,
    });
  };

  handleSearchChange = async (event, value) => {
    if (event.target.value.length >= 3) {
      let parts = await this.fetchParts(event.target.value);
      this.setState({ partsSearch: parts });
    }
  };

  handlePartChange = (event, value) => {
    //console.log(event, value);
    if (typeof value !== 'undefined' && typeof value === 'object') {
      if (!!value.key) {
        this.setState({ unit_id: value.props.value });
      } else if (!!value.id || value.id !== false) {
        this.setState({
          unit_id: value.unit_id,
          product_id: value.product_id,
          amount: value.default_amount || 1,
          allowance: value.allowance,
          price: value.price,
          category: value.category,
          newPart: !!value.newPart,
          part: value,
        });
      } else {
        // Kein Tag/ Kein Kontalt -> Neuer Kontakt!
        //console.log("should be here")
        this.setState({
          unit_id: value.unit_id,
          amount: value.amount,
          product_id: value.product_id,
          allowance: value.allowance,
          price: value.price,
          category: value.category,
          newPart: !!value.newPart,
          part: value,
        });
      }
    } else {
      //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
      if (event.target.type === 'checkbox') {
        this.setState({ [event.target.name]: event.target.checked });
      } else {
        this.setState({ [event.target.name]: event.target.value });
      }
    }
  };

  closeWindow = () => {
    this.setState(
      {
        newPart: false,
        part: null,
        product_id: '',
        unit_id: 14,
        amount: 1,
        price: 0.0,
        allowance: false,
        name: '',
        category: '',
      },
      () => this.props.onChange(this.props.name, this.state[this.props.name]),
    );
  };

  updatePart = async () => {
    this.setState({ isSubmitting: true });
    let url = new URL(process.env.REACT_APP_API_URL + '/api/parts/' + this.props.uuid);
    let formData = {
      part_id: this.state.part.id,
      amount: this.state.amount,
      edit: this.state.edit,
    };
    this.save(url, formData);
  };

  handlePartAdd = async () => {
    if (!!this.state.part === false) {
      this.setState({ validation: 'Da fehlt was!' });
      return;
    }
    if (!!this.state.part.newPart && !!!this.state.edit) {
      return this.addPart();
    }
    return this.updatePart();
  };

  addPart = async () => {
    this.setState({ isSubmitting: true });
    let url = new URL(process.env.REACT_APP_API_URL + '/api/parts/' + this.props.uuid);
    let formData = {
      name: this.state.part.inputValue,
      unit_id: this.state.unit_id,
      product_id: this.state.product_id,
      amount: this.state.amount,
      default_amount: this.state.amount,
      allowance: this.state.allowance,
      category: this.state.category,
    };
    this.save(url, formData);
  };

  save = async (url, formData) => {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        return !!json?.part ? json : false;
      })
      .catch((error) => {
        this.setState({
          isSubmitting: false,
          hasError: error,
        });
        return error;
      });

    if (!!this.state.hasError) {
      return;
    }

    let tmp = this.state[this.props.name];
    let part = {};
    let control = false;

    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //console.log(formData, response);
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    if (!!formData.part_id) {
      for (var i = tmp.length - 1; i >= 0; i--) {
        if (tmp[i].part_id === formData.part_id) {
          tmp[i].amount = (!!this.state.edit ? 0 : Number(tmp[i].amount)) + Number(formData.amount);
          control = true;
        }
      }
    }
    if (!control) {
      let hasPart = !!formData.part_id
        ? this.state.parts.find((element) => element.part_id === formData.part_id)
        : false;
      if (!!hasPart) {
        part = {
          ...hasPart,
          amount: (!!this.state.edit ? 0 : Number(hasPart.amount)) + Number(formData.amount),
        };
      } else {
        part = response;
      }
      tmp.push(part);
    }
    this.setState({
      [this.props.name]: tmp,
      unit_id: 0,
      amount: 1,
      allowance: false,
      price: 0.0,
      category: '',
      isSubmitting: false,
      edit: false,
      part: null,
      validation: '',
      newPart: false,
    });
  };

  addMaterial = () => {
    if (!!this.state.edit) {
      this.setState(
        {
          unit_id: 0,
          amount: 1,
          allowance: false,
          price: 0.0,
          category: '',
          isSubmitting: false,
          edit: false,
          part: null,
          validation: '',
          newPart: false,
        },
        () => this.props.zoomFunction(),
      );
      return;
    }
    this.props.zoomFunction();
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <Backdrop className={classes.backdrop} open={this.props.open}>
          <Zoom in={this.props.open}>
            {!!this.state.isSubmitting ? (
              <Paper elevation={4} className={classes.paper} component='center'>
                <CircularProgress />
              </Paper>
            ) : !!!this.state.deletePart ? (
              <Paper elevation={4} className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Autocomplete
                      id='partselect'
                      name='id'
                      required
                      error={this.state.validation}
                      freeSolo
                      classes={{ inputRoot: classes.input }}
                      options={this.state.partsSearch}
                      groupBy={(option) => option.category}
                      renderOption={(option) => {
                        return (
                          <ListItem component='div' key={option.id}>
                            <ListItemAvatar>
                              <Avatar variant='rounded' className={classes.small}>
                                {option.unit.short}
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={option.name + ' / ' + option.unit.name}
                              secondary={option.category}
                            />
                          </ListItem>
                        );
                      }}
                      value={this.state.part}
                      getOptionSelected={(option) => option.id || false}
                      onChange={this.handlePartChange}
                      defaultValue={null}
                      includeInputInList={true}
                      autoSelect={false}
                      disableClearable
                      disabled={!!this.state.edit}
                      variant='outlined'
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label='Material'
                          variant='outlined'
                          helperText={TextDE.performanceReport.tableParts.labelPart}
                          required
                          error={!!this.state.validation}
                          onChange={this.handleSearchChange}
                        />
                      )}
                      filterOptions={(options, params) => {
                        // Suggest the creation of a new value
                        if (params.inputValue !== '') {
                          options.push({
                            inputValue: params.inputValue,
                            id: false,
                            name: '"' + params.inputValue + '" ' + TextDE.add,
                            unit_id: 14,
                            unit: {
                              id: 14,
                              short: TextDE.performanceReport.tableParts.noUnit,
                              name: TextDE.performanceReport.tableParts.noUnit,
                            },
                            allowance: false,
                            category: '',
                            price: 0.0,
                            amount: 1,
                            newPart: true,
                          });
                        }
                        return options;
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        if (!!option.part) {
                          return option.part.name ?? ' - ';
                        }
                        // Regular option
                        return option.name;
                      }}
                    />
                  </Grid>

                  <Grid item xs={3}>
                    <TextField
                      id='amount'
                      name='amount'
                      label={TextDE.performanceReport.tableParts.labelAmount}
                      required
                      variant='outlined'
                      helperText={TextDE.performanceReport.tableParts.helperAmount}
                      type='number'
                      fullWidth
                      lang='de'
                      value={this.state.amount}
                      onChange={this.handlePartChange}
                      inputProps={{
                        className: classes.input,
                        min: 0,
                        max: 999999,
                      }}
                    />
                  </Grid>

                  {!!this.state.newPart ? (
                    <>
                      <Grid item xs={6} md={4}>
                        <TextField
                          select
                          id='new-unit'
                          name='unit_id'
                          label={TextDE.performanceReport.tableParts.labelUnit}
                          fullWidth
                          required
                          helperText={TextDE.performanceReport.tableParts.helperUnit}
                          variant='outlined'
                          value={this.state.unit_id}
                          disabled={!!!this.state.newPart}
                          onChange={this.handlePartChange}
                          inputProps={{ className: classes.input }}
                        >
                          {this.state.unit.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          id='product_id'
                          name='product_id'
                          label={TextDE.performanceReport.tableParts.labelProductId}
                          helperText={TextDE.performanceReport.tableParts.helperProductId}
                          type='text'
                          value={this.state.product_id}
                          onChange={this.handlePartChange}
                          inputProps={{ className: classes.input }}
                          disabled={!!!this.state.newPart}
                        />
                      </Grid>
                      <Grid item xs={6} md={4}>
                        <TextField
                          fullWidth
                          variant='outlined'
                          id='category'
                          name='category'
                          label={TextDE.performanceReport.tableParts.labelCategory}
                          helperText={TextDE.performanceReport.tableParts.helperCategory}
                          type='text'
                          value={this.state.category}
                          onChange={this.handlePartChange}
                          inputProps={{ className: classes.input }}
                          disabled={!!!this.state.newPart}
                        />
                      </Grid>
                      {/* 
											<Grid item xs={6} md={4}>
												<TextField  
													fullWidth
													variant="outlined"
													id="price" 
													name="price"
													label={TextDE.performanceReport.tableParts.labelPrice}
													helperText={TextDE.performanceReport.tableParts.helperPrice}
													type="text"
													value={this.state.price}
													onChange={this.handlePartChange}
													disabled={ !!!this.state.newPart }
													inputProps={{className: classes.input}} 
												/>
											</Grid>
										*/}
                      <Grid item xs={6} md={12} className={classes.alignEndIfBig}>
                        <FormControlLabel
                          value={this.state.allowance}
                          id='allowance'
                          name='allowance'
                          control={<Checkbox color='primary' className={classes.checkboxSize} />}
                          onChange={this.handlePartChange}
                          label={TextDE.performanceReport.tableParts.labelAllowance(
                            !!this.state.allowance,
                          )}
                          classes={{ label: classes.font }}
                          labelPlacement='end'
                          disabled={!!!this.state.newPart}
                        />
                      </Grid>
                      {/*
											<Grid item xs={12} >
												<TextField  
													variant="outlined"
													id="notes" 
													name="notes"
													label={TextDE.performanceReport.tableParts.labelNote}
													type="text"
													fullWidth
													value={this.state.notes}
													onChange={this.handlePartChange}
													disabled={ !!!this.state.newPart }
													inputProps={{className: classes.input}} 
												/>
											</Grid>
										*/}
                    </>
                  ) : null}
                  <Grid item xs={12}>
                    <Button
                      className={classes.floatRight}
                      startIcon={<AddIcon color='inherit' />}
                      variant={
                        this.state.amount > 0 && !!this.state.part ? 'contained' : 'outlined'
                      }
                      color={this.state.amount > 0 && !!this.state.part ? 'primary' : 'secondary'}
                      disabled={!(this.state.amount > 0 && !!this.state.part)}
                      onClick={this.handlePartAdd}
                    >
                      {!!this.state.edit
                        ? TextDE.performanceReport.tableParts.editPart
                        : TextDE.performanceReport.tableParts.addPart}
                    </Button>
                  </Grid>
                </Grid>

                {!!this.state.hasError && (
                  <Alert severity='error' className={classes.center}>
                    <AlertTitle>
                      {this.state.hasError?.status ?? TextDE.mistake} -{' '}
                      {this.state.hasError?.statusText ?? TextDE.mistake}
                    </AlertTitle>
                    {JSON.stringify(this.state.hasError, null, 4)}
                  </Alert>
                )}
                <Button onClick={this.closeWindow}>{TextDE.close}</Button>
              </Paper>
            ) : (
              <Paper elevation={4} className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='h6'>
                      {TextDE.performanceReport.tableParts.labelPartDelete}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography gutterBottom>{this.state.deletePart.part.name ?? ' - '}</Typography>
                  </Grid>

                  {!!this.state.hasError && (
                    <Grid item xs={12}>
                      <Alert severity='error' className={classes.center}>
                        <AlertTitle>
                          {this.state.hasError?.status ?? TextDE.mistake} -{' '}
                          {this.state.hasError?.statusText ?? TextDE.mistake}
                        </AlertTitle>
                        {JSON.stringify(this.state.hasError, null, 4)}
                      </Alert>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Button
                      onClick={() =>
                        this.setState({ deletePart: false }, () => this.props.zoomFunction())
                      }
                    >
                      {TextDE.abort}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      className={classes.floatRight}
                      startIcon={<DeleteIcon color='inherit' />}
                      variant='contained'
                      color='secondary'
                      onClick={this.deleteFetch}
                    >
                      {TextDE.delete}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            )}
          </Zoom>
        </Backdrop>

        <TableContainer component={Grid}>
          <Table className={classes.materialsTable} size='small'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>
                  {TextDE.performanceReport.tableParts.labelPart}
                </TableCell>
                <TableCell align='right' className={classes.head}>
                  {TextDE.performanceReport.tableParts.labelProductId}
                </TableCell>
                <TableCell align='right' className={classes.head}>
                  {TextDE.performanceReport.tableParts.labelAmount}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state[this.props.name].map((data, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell scope='row' className={classes.description}>
                      {data.part.name ?? ' - '}
                    </TableCell>
                    <TableCell align='right' className={classes.count}>
                      {data.part.product_id ?? '-'}
                    </TableCell>
                    <TableCell align='right' className={classes.count}>
                      {data.allowance ? 'Pauschale' : data.amount + ' ' + data.part.unit.name}
                    </TableCell>
                    <TableCell align='right' className={classes.delete}>
                      <IconButton onClick={() => this.editMaterial(index, data)}>
                        <CreateIcon />
                      </IconButton>
                      <IconButton onClick={() => this.deleteMaterial(index, data)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          size='small'
          endIcon={<CreateIcon />}
          fullWidth
          variant='contained'
          color='primary'
          onClick={this.addMaterial}
        >
          {TextDE.performanceReport.tableParts.labelPartPlural} {TextDE.add}
        </Button>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ Authentication: state.Authentication });

export default compose(withStyles(styles), connect(mapStateToProps))(Parts);
