/*
 * action types
 */

export const DISPLAY = 'FETCH_NEW_VERSION';
export const RESET = 'RESET_NEW_VERSION';
/*
 * action creators
 */
export const displayNewVersionHint = () => {
  return { type: DISPLAY, display: true };
};

export const resetNewVersionHint = () => {
  return { type: RESET, display: false };
};
