import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import HelpIcon from '@material-ui/icons/Help';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
//import PhoneIcon from '@material-ui/icons/Phone';
const styles = (theme) => ({
  inherit: {
    padding: theme.spacing(1, 2),
    textDecoration: 'inherit',
    color: theme.palette.info.dark,
    justifyContent: 'center',
    width: '100%',
    minWidth: '1px',
    backgroundColor: 'inherit',
    '&:hover': {
      textDecoration: 'inherit',
      color: theme.palette.info.contrastText,
      backgroundColor: theme.palette.info.dark,
    },
  },
  iconInherit: {
    color: 'inherit',
    backgroundColor: 'inherit',
    minWidth: 'unset',
  },
  paddingLeft: {
    paddingLeft: theme.spacing(1),
  },
  flexBox: {
    display: 'flex',
    maxWidth: '100%',
    width: '100%',
  },
  marginAuto: {
    marginLeft: 'auto',
  },
  form: {
    padding: theme.spacing(1, 2),
  },
});

class SupportRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      text: '',
      textError: '',
      files: [],
      callMeBack: false,
      phone: props.User?.mobile ?? props.User?.phone ?? '',
      phoneError: '',
    };
    this.submit = this.submit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handlePhoneCall = this.handlePhoneCall.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  submit(event) {
    event.preventDefault();
    let errors = 0;
    if (!(this.state.text.length >= 3)) {
      this.setState({ textError: 'Bitte (be)schreiben Sie uns Ihr Anliegen.' });
      errors += 1;
    }

    if (this.state.callMeBack && this.state.phone.length < 3) {
      this.setState({ phoneError: 'Bitte geben Sie uns Ihre Telefonnummer um Sie zurückzurufen.' });
      errors += 1;
    }

    if (errors > 0) {
      return;
    }

    let data = {
      request: this.state.text,
      callMeBack: this.state.callMeBack ? true : false,
      who:
        'Support Anfrage von ' +
        this.props.User?.firstname +
        ' ' +
        this.props.User?.lastname +
        '( ' +
        this.props.User?.email +
        '/' +
        (this.props.User?.mobile || this.props.User?.phone) +
        ' / UUID: ' +
        this.props.User?.uuid +
        ' )',
    };

    let url = new URL(process.env.REACT_APP_API_URL + '/api/send/supportmail');
    data['_method'] = 'POST';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return false;
      });
    this.handleClose();
  }

  handleOpen() {
    this.setState({ open: true, text: '', errorText: '', errorPhone: '', callMeBack: false });
  }

  handleClose(event, reason) {
    if (!!reason && reason === 'backdropClick') {
      return;
    }
    if (!!event) {
      event.preventDefault();
    }
    this.setState({ open: false, errorText: '', errorPhone: '', text: '', files: [] });
  }

  handleChange(event, value) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePhoneCall() {
    this.setState({ callMeBack: !this.state.callMeBack });
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <ListItem button component='div' className={classes.inherit} onClick={this.handleOpen}>
          <ListItemIcon className={classes.iconInherit}>
            <HelpIcon />
          </ListItemIcon>
          {this.props.collapsed && (
            <ListItemText className={classes.paddingLeft} primary='Hilfe!' />
          )}
        </ListItem>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          disableEscapeKeyDown={true}
          aria-labelledby='form-dialog-title'
        >
          <form key='AddSupportRequest' className={classes.form} onSubmit={this.submit}>
            <DialogTitle id='form-dialog-title'>Ihr Kontakt zu uns!</DialogTitle>
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  <Typography>
                    Falls Sie Fragen, Anmerkungen oder auch Vorschläge haben, teilen Sie uns diese
                    gerne mit!
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    multiline
                    autoFocus
                    name='text'
                    value={this.state.text}
                    minRows='4'
                    margin='dense'
                    required
                    label='Ihr Anliegen'
                    type='textarea'
                    fullWidth
                    variant='outlined'
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.flexBox}
                    classes={{ label: classes.flexBox }}
                    control={
                      <Checkbox
                        name='callMeBack'
                        checked={this.state.callMeBack}
                        onChange={this.handlePhoneCall}
                      />
                    }
                    label={
                      <Container className={classes.flexBox}>
                        <Typography display='inline' align='left' variant='button'>
                          Bitte um Rückruf (Rückrufanfrage)
                        </Typography>
                      </Container>
                    }
                  />
                </Grid>
                {this.state.callMeBack && (
                  <Grid item xs={12}>
                    <TextField
                      name='phone'
                      type='tel'
                      value={this.state.phone}
                      margin='dense'
                      label='Ihre Telefonnummer'
                      fullWidth
                      variant='outlined'
                      onChange={this.handleChange}
                      required={this.state.callMeBack}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Typography gutterBottom>
                    Wir melden uns schnellstmöglichst bei Ihnen{' '}
                    {!!!this.state.callMeBack && <b> via E-Mail: {this.props.User?.email}</b>}!
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography gutterBottom>
                    Gerne können Sie uns auch Telefonisch unter <b>+49 761 897 50 180</b> erreichen.
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} size='large' variant='outlined' color='secondary'>
                Abbrechen
              </Button>
              <Button
                type='submit'
                size='large'
                variant='contained'
                color='primary'
                className={classes.marginAuto}
              >
                Absenden
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Theme: state.Style.Theme,
});

export default compose(withStyles(styles), connect(mapStateToProps))(SupportRequest);
