import React, { Fragment } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import CardHeader from '@material-ui/core/CardHeader';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
//import CircularProgress from '@material-ui/core/CircularProgress';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import BackupIcon from '@material-ui/icons/Backup';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import Avatar from '@material-ui/core/Avatar';

function flattenObject(ob) {
  var toReturn = {};

  for (var i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] == 'object' && ob[i] !== null) {
      var flatObject = flattenObject(ob[i]);
      for (var x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

const styles = (theme) => ({
  element: {
    background: 'red',
    maxWidth: '255px',
  },

  forceWidth: {
    width: '100%',
  },
  spacing: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
});

class ContactImport extends React.Component {
  async startImportJob(event) {
    event.preventDefault();
    let formData = new FormData(document.getElementById('tableMapping-form'));
    formData.append('filePath', this.state.filePath);

    if (!!!this.state.addressIsSplitted) {
      formData.delete('route');
      formData.delete('street_number');
      formData.delete('postal_code');
      formData.delete('locality');
      formData.delete('administrative_area_level_1');
      formData.delete('country');
    } else {
      formData.delete('address_raw');
    }

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/contacts/import/run', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        let hasError = flattenObject(error);
        hasError = Object.values(hasError);
        this.setState({ isSubmitting: false, hasError: hasError });
        return false;
      });

    if (!!response.errors) {
      let hasError = flattenObject(response.errors);
      if (!!response.message) {
        hasError = [response.message];
      } else {
        hasError = Object.values(hasError);
      }
      this.setState({ isSubmitting: false, hasError: hasError });
      return;
    }
    if (!!response.success) {
      this.setState({
        isSubmitting: false,
        success: response.success,
        filename: '',
        filePath: '',
        tableMap: {},
        descriptors: {},
        hasError: false,
        hasErrorUpload: false,
      });
    }
  }

  async uploadContactCSV(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true, hasErrorUpload: false });

    let file = document.getElementById('contained-button-file').files[0];
    if (!!!file) {
      this.setState({
        isSubmitting: false,
        hasErrorUpload: ['Konnte die Datei nicht auswählen - Bitte erneut versuchen!'],
        filename: '',
        filePath: '',
        tableMap: {},
        descriptors: {},
      });
      return;
    }

    let formData = new FormData();

    formData.append('file', file);

    const response = await fetch(process.env.REACT_APP_API_URL + '/api/contacts/import/view', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })

      .catch((error) => {
        let hasErrorUpload = flattenObject(error);
        hasErrorUpload = Object.values(hasErrorUpload);
        this.setState({
          isSubmitting: false,
          hasErrorUpload: hasErrorUpload,
          filename: '',
          filePath: '',
          tableMap: {},
          descriptors: {},
        });
        return false;
      });

    if (!!response.errors) {
      let hasErrorUpload = flattenObject(response.errors);

      if (!!response.message) {
        hasErrorUpload = [response.message];
      } else {
        hasErrorUpload = Object.values(hasErrorUpload);
      }
      this.setState({
        isSubmitting: false,
        hasErrorUpload: hasErrorUpload,
        filename: '',
        filePath: '',
        tableMap: {},
        descriptors: {},
      });
      return;
    }

    if (!!response && !!response.descriptors) {
      this.setState({
        filePath: response.filePath,
        tableMap: response.tableMapping,
        descriptors: response.descriptors,
        isSubmitting: false,
        hasErrorUpload: false,
      });
    }
  }

  constructor() {
    super();
    this.state = {
      isFetching: false,
      success: false,
      filename: '',
      filePath: '',
      skipFirstLine: true,
      descriptors: {},
      tableMap: {},
      values: {},
      formData: {},
      addressIsSplitted: true,
      phones: [0],
      emails: [0],
      webpages: [0],
      hasErrorUpload: false,
      hasError: false,
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.uploadContactCSV = this.uploadContactCSV.bind(this);
    this.startImportJob = this.startImportJob.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.addressSplitSwitch = this.addressSplitSwitch.bind(this);
    this.renderListComponent = this.renderListComponent.bind(this);
  }

  componentDidMount() {
    //  "Autostart Funktion"
    //this.refreshState();
  }
  addressSplitSwitch() {
    this.setState({ addressIsSplitted: !!!this.state.addressIsSplitted });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // nach  Veränderung des "Vaters"
  }
  onFileChange(e) {
    if (e.target.files.length > 0) {
      this.setState({ filename: e.target.files[0].name });
    }
    this.uploadContactCSV(e);
  }

  handleChange(event) {
    this.setState({
      formData: { ...this.state.formData, [event.target.name]: event.target.value },
    });
  }

  handleDelete(component, key) {
    let tmp = [];
    if (component.toLowerCase().includes('phone')) {
      tmp = this.state.phones.filter((elm) => elm !== key);
      this.setState({ phones: tmp });
    } else if (component.toLowerCase().includes('mail')) {
      tmp = this.state.emails.filter((elm) => elm !== key);
      this.setState({ emails: tmp });
    } else if (component.toLowerCase().includes('webpage')) {
      tmp = this.state.webpages.filter((elm) => elm !== key);
      this.setState({ webpages: tmp });
    }
  }
  // component = "phone"
  handleAdd(component, key) {
    let tmp = [];
    if (component.toLowerCase().includes('phone')) {
      tmp = this.state.phones;
      tmp.push(tmp.length);
      this.setState({ phones: tmp });
    } else if (component.toLowerCase().includes('mail')) {
      tmp = this.state.emails;
      tmp.push(tmp.length);
      this.setState({ emails: tmp });
    } else if (component.toLowerCase().includes('webpage')) {
      tmp = this.state.webpages;
      tmp.push(tmp.length);
      this.setState({ webpages: tmp });
    }
  }

  renderListComponent(tableMap) {
    return (
      <>
        {Object.values(tableMap).map((columnName, label) => {
          let component = <></>;
          switch (columnName) {
            case 'address_raw':
              component = (
                <>
                  <Grid item xs={12} style={{ display: 'flex' }}>
                    <Grid item xs={5} md={2} style={{ flexGrow: 1 }}></Grid>
                    <Grid item xs={5} md={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            value={this.state.addressIsSplitted}
                            checked={!!this.state.addressIsSplitted}
                            onChange={this.addressSplitSwitch}
                            name='addressIsSplitted'
                          />
                        }
                        label='Ist die Adresse in mehrere Felder aufgeteilt (In Straße, Hausnummer, Postleitzahl, ...)?'
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Collapse in={!!!this.state.addressIsSplitted}>
                      <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                        <Grid item xs={5} md={2} style={{ display: 'flex' }}>
                          <Typography
                            component='label'
                            align='right'
                            variant='h5'
                            htmlFor={'columnName-' + columnName}
                            style={{
                              marginLeft: 'auto',
                              marginTop: 'auto',
                              marginBottom: 'auto',
                              marginRight: '16px',
                            }}
                          >
                            {Object.keys(this.state.tableMap)[label]}
                          </Typography>
                        </Grid>

                        <Grid item xs={5} md={6}>
                          <Select
                            fullWidth
                            name={columnName}
                            variant='outlined'
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            id={'columnName-' + columnName}
                          >
                            <MenuItem
                              key={'columnName-' + columnName + '-default'}
                              disabled
                              selected
                              value='undefined'
                            >
                              Bitte auswählen
                            </MenuItem>
                            <MenuItem key={'columnName-' + columnName + '-dont-import'} value={-1}>
                              Nicht importieren
                            </MenuItem>
                            {Object.values(this.state.descriptors).map((descriptor, key) => {
                              let component = (
                                <MenuItem key={'columnName-' + columnName + '-' + key} value={key}>
                                  {descriptor}
                                </MenuItem>
                              );
                              return component;
                            })}
                          </Select>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </Grid>
                </>
              );
              break;
            case 'route':
            case 'street_number':
            case 'postal_code':
            case 'locality':
            case 'administrative_area_level_1':
            case 'country':
              component = (
                <Grid item xs={12}>
                  <Collapse in={!!this.state.addressIsSplitted}>
                    <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                      <Grid item xs={5} md={2} style={{ display: 'flex' }}>
                        <Typography
                          component='label'
                          align='right'
                          variant='h5'
                          htmlFor={'columnName-' + columnName}
                          style={{
                            marginLeft: 'auto',
                            marginTop: 'auto',
                            marginBottom: 'auto',
                            marginRight: '16px',
                          }}
                        >
                          {Object.keys(this.state.tableMap)[label]}
                        </Typography>
                      </Grid>

                      <Grid item xs={5} md={6}>
                        <Select
                          fullWidth
                          name={columnName}
                          variant='outlined'
                          displayEmpty
                          inputProps={{ 'aria-label': 'Without label' }}
                          id={'columnName-' + columnName}
                        >
                          <MenuItem
                            key={'columnName-' + columnName + '-default'}
                            disabled
                            selected
                            value='undefined'
                          >
                            Bitte auswählen
                          </MenuItem>
                          <MenuItem key={'columnName-' + columnName + '-dont-import'} value={-1}>
                            Nicht importieren
                          </MenuItem>
                          {Object.values(this.state.descriptors).map((descriptor, key) => {
                            let component = (
                              <MenuItem key={'columnName-' + columnName + '-' + key} value={key}>
                                {descriptor}
                              </MenuItem>
                            );
                            return component;
                          })}
                        </Select>
                      </Grid>
                    </Grid>
                  </Collapse>
                </Grid>
              );
              break;

            case 'webpage[]':
              component = (
                <>
                  {this.state.webpages.length > 0 &&
                    Object.values(this.state.webpages).map((empty, key) => (
                      <Grid item xs={12}>
                        <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                          <Grid item xs={5} md={2} style={{ display: 'flex' }}>
                            <Typography
                              component='label'
                              align='right'
                              variant='h5'
                              htmlFor={'columnName-' + columnName}
                              style={{
                                marginLeft: 'auto',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                marginRight: '16px',
                              }}
                            >
                              {key > 0 && 'weitere '} {Object.keys(this.state.tableMap)[label]}
                            </Typography>
                          </Grid>

                          <Grid item xs={5} md={6}>
                            <Select
                              fullWidth
                              name={'webpage[' + empty + ']'}
                              variant='outlined'
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              id={'columnName-' + columnName + '-' + empty}
                            >
                              <MenuItem
                                key={'columnName-' + columnName + '-default'}
                                disabled
                                selected
                                value='undefined'
                              >
                                Bitte auswählen
                              </MenuItem>
                              <MenuItem
                                key={'columnName-' + columnName + '-dont-import'}
                                value={-1}
                              >
                                Nicht importieren
                              </MenuItem>
                              {Object.values(this.state.descriptors).map((descriptor, key) => {
                                let component = (
                                  <MenuItem
                                    key={'columnName-' + columnName + '-' + key}
                                    value={key}
                                  >
                                    {descriptor}
                                  </MenuItem>
                                );
                                return component;
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            {key === 0 && (
                              <IconButton
                                onClick={() =>
                                  this.handleAdd(
                                    columnName,
                                    Object.keys(this.state.tableMap)[label],
                                  )
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                            {key > 0 && key + 1 === this.state.webpages.length && (
                              <IconButton onClick={() => this.handleDelete(columnName, empty)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </>
              );
              break;
            case 'phone[]':
              component = (
                <>
                  {this.state.phones.length > 0 &&
                    Object.values(this.state.phones).map((empty, key) => (
                      <Grid item xs={12}>
                        <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                          <Grid item xs={5} md={2} style={{ display: 'flex' }}>
                            <Typography
                              component='label'
                              align='right'
                              variant='h5'
                              htmlFor={'columnName-' + columnName}
                              style={{
                                marginLeft: 'auto',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                marginRight: '16px',
                              }}
                            >
                              {key > 0 && 'weitere '} {Object.keys(this.state.tableMap)[label]}
                            </Typography>
                          </Grid>

                          <Grid item xs={5} md={6}>
                            <Select
                              fullWidth
                              name={'phone[' + empty + ']'}
                              variant='outlined'
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              id={'columnName-' + columnName + '-' + empty}
                            >
                              <MenuItem
                                key={'columnName-' + columnName + '-default'}
                                disabled
                                selected
                                value='undefined'
                              >
                                Bitte auswählen
                              </MenuItem>
                              <MenuItem
                                key={'columnName-' + columnName + '-dont-import'}
                                value={-1}
                              >
                                Nicht importieren
                              </MenuItem>
                              {Object.values(this.state.descriptors).map((descriptor, key) => {
                                let component = (
                                  <MenuItem
                                    key={'columnName-' + columnName + '-' + key}
                                    value={key}
                                  >
                                    {descriptor}
                                  </MenuItem>
                                );
                                return component;
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            {key === 0 && (
                              <IconButton
                                onClick={() =>
                                  this.handleAdd(
                                    columnName,
                                    Object.keys(this.state.tableMap)[label],
                                  )
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                            {key > 0 && key + 1 === this.state.phones.length && (
                              <IconButton onClick={() => this.handleDelete(columnName, empty)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </>
              );
              break;
            case 'email[]':
              component = (
                <>
                  {this.state.emails.length > 0 &&
                    Object.values(this.state.emails).map((empty, key) => (
                      <Grid item xs={12}>
                        <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                          <Grid item xs={5} md={2} style={{ display: 'flex' }}>
                            <Typography
                              component='label'
                              align='right'
                              variant='h5'
                              htmlFor={'columnName-' + columnName}
                              style={{
                                marginLeft: 'auto',
                                marginTop: 'auto',
                                marginBottom: 'auto',
                                marginRight: '16px',
                              }}
                            >
                              {key > 0 && 'weitere '} {Object.keys(this.state.tableMap)[label]}
                            </Typography>
                          </Grid>

                          <Grid item xs={5} md={6}>
                            <Select
                              fullWidth
                              name={'email[' + empty + ']'}
                              variant='outlined'
                              displayEmpty
                              inputProps={{ 'aria-label': 'Without label' }}
                              id={'columnName-' + columnName + '-' + empty}
                            >
                              <MenuItem
                                key={'columnName-' + columnName + '-default'}
                                disabled
                                selected
                                value='undefined'
                              >
                                Bitte auswählen
                              </MenuItem>
                              <MenuItem
                                key={'columnName-' + columnName + '-dont-import'}
                                value={-1}
                              >
                                Nicht importieren
                              </MenuItem>
                              {Object.values(this.state.descriptors).map((descriptor, key) => {
                                let component = (
                                  <MenuItem
                                    key={'columnName-' + columnName + '-' + key}
                                    value={key}
                                  >
                                    {descriptor}
                                  </MenuItem>
                                );
                                return component;
                              })}
                            </Select>
                          </Grid>
                          <Grid item xs={2} md={2}>
                            {key === 0 && (
                              <IconButton
                                onClick={() =>
                                  this.handleAdd(
                                    columnName,
                                    Object.keys(this.state.tableMap)[label],
                                  )
                                }
                              >
                                <AddIcon />
                              </IconButton>
                            )}
                            {key > 0 && key + 1 === this.state.emails.length && (
                              <IconButton onClick={() => this.handleDelete(columnName, empty)}>
                                <DeleteIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                </>
              );
              break;

            default:
              component = (
                <Grid item xs={12} id={'columnName-' + columnName + '-default'}>
                  <Grid container spacing={2} style={{ marginBottom: '8px' }}>
                    <Grid item xs={5} md={2} style={{ display: 'flex' }}>
                      <Typography
                        component='label'
                        align='right'
                        variant='h5'
                        htmlFor={'columnName-' + columnName}
                        style={{
                          marginLeft: 'auto',
                          marginTop: 'auto',
                          marginBottom: 'auto',
                          marginRight: '16px',
                        }}
                      >
                        {Object.keys(this.state.tableMap)[label]}
                      </Typography>
                    </Grid>

                    <Grid item xs={5} md={6}>
                      <Select
                        fullWidth
                        name={columnName}
                        variant='outlined'
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem
                          key={'columnName-' + columnName + '-default'}
                          disabled
                          selected
                          value='undefined'
                        >
                          Bitte auswählen
                        </MenuItem>
                        <MenuItem key={'columnName-' + columnName + '-dont-import'} value={-1}>
                          Nicht importieren
                        </MenuItem>
                        {Object.values(this.state.descriptors).map((descriptor, key) => {
                          let component = (
                            <MenuItem key={'columnName-' + columnName + '-' + key} value={key}>
                              {descriptor}
                            </MenuItem>
                          );
                          return component;
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </Grid>
              );
              break;
          }
          return component;
        })}
      </>
    );
  }

  render() {
    const { classes } = this.props;
    if (this.state.success) {
      return (
        <>
          <Grid
            component='form'
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={0}
            style={{ paddingTop: '16px', paddingBottom: '16px' }}
          >
            <Grid item xs={12} md={4} className={classes.spacing}>
              <Card raised>
                <CardContent>
                  <Typography variant='h3' align='center' gutterBottom>
                    Erfolgreich hochgeladen!
                  </Typography>
                  <Typography variant='h4' color='textSecondary'>
                    {this.state.success || 'Platzhalter Text'}
                  </Typography>
                </CardContent>
                <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    onClick={() => this.setState({ success: false })}
                    variant='contained'
                    color='primary'
                  >
                    Weitere Datei importieren
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </>
      );
    }
    return (
      <>
        <Grid
          component='form'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          spacing={0}
          style={{ paddingTop: '16px', paddingBottom: '16px' }}
        >
          <Grid item xs={12} md={4} className={classes.spacing}>
            <Collapse in={!!!this.state.filename}>
              <Card raised>
                <CardContent>
                  <Typography variant='h3' color='textPrimary' gutterBottom>
                    Kontakt Importer
                  </Typography>
                  <Typography variant='body1' color='textSecondary' gutterBottom>
                    Bitte wählen Sie eine Datei aus um mit dem Kontakt Import zu beginnen.
                  </Typography>
                  <input
                    accept='.csv'
                    style={{ display: 'none' }}
                    id='contained-button-file'
                    multiple
                    type='file'
                    onChange={this.onFileChange}
                  />

                  {!!this.state.hasErrorUpload && (
                    <Typography color='error'>{this.state.hasErrorUpload}</Typography>
                  )}
                </CardContent>
                <CardActions>
                  <label htmlFor='contained-button-file'>
                    <Button
                      component='span'
                      type='submit'
                      variant='outlined'
                      color='primary'
                      className={classes.submit}
                      disabled={this.state.isSubmitting}
                      fullWidth
                    >
                      CSV Hochladen
                    </Button>
                  </label>
                  <Typography variant='button'>Dateiname: {this.state.filename}</Typography>
                </CardActions>
              </Card>
            </Collapse>
          </Grid>

          <Grid item xs={12} className={classes.spacing}>
            <Collapse in={!!this.state.filename} classes={{ root: classes.forceWidth }}>
              <Card raised>
                <CardHeader
                  avatar={
                    <Avatar aria-label='uploaded file'>
                      <AttachFileIcon />
                    </Avatar>
                  }
                  action={
                    <>
                      <Hidden smUp>
                        <label htmlFor='contained-button-file'>
                          <IconButton
                            component='span'
                            type='submit'
                            color='primary'
                            disabled={this.state.isSubmitting}
                          >
                            <BackupIcon />
                          </IconButton>
                        </label>
                      </Hidden>
                      <Hidden mdDown>
                        <label htmlFor='contained-button-file'>
                          <Button
                            //onClick={this.uploadContactCSV}
                            component='span'
                            type='submit'
                            variant='outlined'
                            color='primary'
                            className={classes.submit}
                            disabled={this.state.isSubmitting}
                            fullWidth
                          >
                            Andere CSV Laden
                          </Button>
                        </label>
                      </Hidden>
                    </>
                  }
                  titleTypographyProps={{ variant: 'h3' }}
                  title={'Dateiname: ' + this.state.filename}
                />
                <CardContent>
                  <Typography variant='body1'>
                    Bitte geben Sie an, in welcher Spalte der gewünschte Inhalt zu finden ist
                  </Typography>
                  {!!this.state.hasErrorUpload && (
                    <Typography color='error'> {this.state.hasErrorUpload} </Typography>
                  )}
                </CardContent>
              </Card>
            </Collapse>
          </Grid>
        </Grid>

        <Grid
          id='tableMapping-form'
          component='form'
          container
          direction='column'
          justifyContent='center'
          alignItems='stretch'
          spacing={0}
        >
          <Grid item xs={12} className={classes.spacing}>
            <Collapse in={!!this.state.filename} classes={{ root: classes.forceWidth }}>
              <Card raised>
                <CardContent>
                  <Grid
                    container
                    spacing={0}
                    direction='row'
                    justifyContent='flex-start'
                    alignItems='center'
                  >
                    <Grid item xs={12}>
                      <Typography gutterBottom variant='h5' align='left'>
                        Hier sehen Sie links den Eintrag innerhalb eines Kontaktes in FLiXWORKER,
                        rechts sehen Sie die "Kopfzeile" Ihres Imports. <br />
                        Bitte Ordnen Sie diese zu.
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', marginTop: '16px' }}>
                      <Grid item xs={5} md={2} style={{ flexGrow: 1 }}></Grid>
                      <Grid item xs={5} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={this.state.skipFirstLine}
                              checked={!!this.state.skipFirstLine}
                              onChange={() =>
                                this.setState({ skipFirstLine: !!!this.state.skipFirstLine })
                              }
                              name='skipFirstLine'
                            />
                          }
                          label='Erste Zeile enthält eine Beschreibung und soll nicht importiert werden'
                        />
                      </Grid>
                    </Grid>

                    {this.renderListComponent(this.state.tableMap)}

                    <Grid item xs={12}>
                      {!!this.state.hasError &&
                        this.state.hasError.map((value, tmp) => (
                          <Typography align='right' color='error' style={{ fontWeight: 'bold' }}>
                            {' '}
                            - {value}{' '}
                          </Typography>
                        ))}
                    </Grid>
                  </Grid>
                </CardContent>
                <CardActions style={{ display: 'flex', justifyContent: 'end' }}>
                  <Button
                    size='large'
                    variant='contained'
                    color='primary'
                    onClick={this.startImportJob}
                  >
                    Kontakt Import Starten!
                  </Button>
                </CardActions>
              </Card>
            </Collapse>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ContactImport);
