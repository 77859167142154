//roles: (4) ["Frisch Registriert!", "Organisator/in", "Administrator/in", "Telefonist/in"]
//type: "App\Notifications\NewInboxItem"
//__proto__: Object.keys
//id: "ea94db59-00e8-47ed-939b-619c13e72fac"
//link: "/nachrichten/detail/0b6c07d1-bb0b-4a14-87e0-2c779dcc4d07"
//body: "Testnachricht KörperMit freundlichen Grüßen,Tob..."
//subject: "Testnachricht Betreff"

export const ADD = 'ADD_NOTFICATIONS';
export const REMOVE = 'REMOVE_NOTFICATIONS';
export const GET = 'GET_NOTFICATIONS';
export const RESET = 'RESET_NOTFICATIONS';

export const addNotificationWrapper = (notification) => {
  return async function (dispatch) {
    dispatch(addNotification(notification));
  };
};

export const removeNotificationWrapper = (notification) => {
  return async function (dispatch, getState) {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/notifications/' + notification.key);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getState().Authentication.access_token}`,
      },
    })
      .then((res) => {
        return res.statusText === 'OK';
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
    if (!!response) {
      dispatch(removeNotification(notification));
    }
  };
};

export const addNotification = (notification) => {
  return { type: ADD, notification };
};

export const removeAllNotification = () => {
  return { type: RESET };
};

export const removeNotification = (notification) => {
  return { type: REMOVE, notification };
};
export const getNotifications = (notifications) => {
  return { type: GET, notifications: notifications };
};

export const getNotificationsWrapper = (notifications) => {
  return function (dispatch) {
    dispatch(getNotifications(notifications));
  };
};

export const reset = () => {
  return { type: RESET };
};
