import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from "@material-ui/core/styles";
import { withRouter } from 'react-router';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PerformanceReportCard from './PerformanceReportCard';
import PerformanceReportNew from './PerformanceReportNew';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PrintIcon from '@material-ui/icons/Print';
import Fab from '@material-ui/core/Fab';
import { DE as TextDE } from '../../../../lib/Text';

const styles = (theme) => ({
  fab: {
    zIndex: 999,
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  fixSize: {
    maxWidth: '100%',
    //maxHeight: '100%',
    overflow: 'auto',
    flexFlow: 'row',
    flexWrap: 'wrap',
    margin: 0,
    //[theme.breakpoints.up('md')]: {
    //	maxHeight: '40vh',
    //}
  },
  newTile: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      width: '50%',
    },
    cursor: 'pointer',
  },
  maxSized: {
    overflow: 'clip',
    width: '100%',
    [theme.breakpoints.up('xl')]: {
      width: '50%',
    },
  },
  chip: {
    width: 'max-content',
    padding: theme.spacing(1.5, 1),
    marginTop: '4px',
    justifyContent: 'center',
    display: 'flex',
  },
});

class PerformanceReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = { overwrite: false, delete: false, edit: false };
    this.viewPDF = this.viewPDF.bind(this);
    this.getPDF = this.getPDF.bind(this);
    this.newPDF = this.newPDF.bind(this);
    this.editPDF = this.editPDF.bind(this);
    this.closePDF = this.closePDF.bind(this);
    this.callbackAfterCreate = this.callbackAfterCreate.bind(this);
    this.showDeleteDialog = this.showDeleteDialog.bind(this);
    this.closeDelete = this.closeDelete.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.previewPDF = this.previewPDF.bind(this);
  }

  componentDidMount() {
    if (!!this.props.openJob) {
      if (
        !!this.props.openJob?.status &&
        !!this.props.openJob.status?.name &&
        this.props.openJob.status.name.toLowerCase() === 'erledigt' &&
        !!this.props.openJob?.performancereports &&
        this.props.openJob.performancereports.length > 0
      ) {
        this.getPDF(
          this.props.openJob.uuid,
          this.props.openJob.performancereports[0].uuid,
          this.props.openJob.performancereports[0].data.serial,
        );
      } else {
        if (!!this.props.openJob?.uuid) {
          this.editPDF(this.props.openJob);
        } else {
          this.editPDF({ uuid: this.props.openJob });
        }
      }
    }
  }

  componentDidUpdate(oldProps, oldState) {
    //if ( prevProps.Router.location.key !== this.props.Router.location.key) {
    if (oldProps?.openJob !== this.props?.openJob) {
      if (
        !!this.props.openJob?.status &&
        !!this.props.openJob.status?.name &&
        this.props.openJob.status.name.toLowerCase() === 'erledigt' &&
        !!this.props.openJob?.performancereports &&
        this.props.openJob.performancereports.length > 0
      ) {
        this.getPDF(
          this.props.openJob.uuid,
          this.props.openJob.performancereports[0].uuid,
          this.props.openJob.performancereports[0].data.serial,
        );
      } else {
        if (!!this.props.openJob?.uuid) {
          this.editPDF(this.props.openJob);
        } else {
          this.editPDF({ uuid: this.props.openJob });
        }
      }
    }
  }

  callbackAfterCreate() {
    this.setState({ edit: false }, () => this.props.callbackNewReport());
  }

  viewPDF(jobUuid, reportUuid, serial) {
    this.getPDF(jobUuid, reportUuid, serial);
  }

  async previewPDF(data) {
    let endpoint = process.env.REACT_APP_API_URL + '/api/jobs/' + data.uuid + '/preview';
    fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute('download', TextDE.workorder.previewFilename + '.pdf');
        a.click();
      });
    });
  }

  getPDF(jobUuid, reportUuid, serial) {
    let endpoint =
      process.env.REACT_APP_API_URL + '/api/performancereports/' + jobUuid + '/' + reportUuid;
    fetch(endpoint, {
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    }).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(b);
        a.setAttribute(
          'download',
          ('arbeitsauftrag_' + serial.replace(/[^a-z0-9]/gi, '_'))
            .replace(/_{2,}/gi, '_')
            .toLowerCase() + '.pdf',
        );
        a.click();
      });
    });
  }
  showDeleteDialog(data) {
    this.setState({ delete: data });
  }
  closeDelete() {
    this.setState({ delete: false });
  }
  async deleteJob() {
    if (!!!this.state.delete?.uuid) {
      console.log('Errorrrr');
      return;
    }
    let endpoint =
      process.env.REACT_APP_API_URL + '/api/jobs/' + this.state.delete.uuid + '/delete';

    let response = await fetch(endpoint, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'DELETE' }),
    })
      .then((response) => response.json())
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
    if (response.closed === true || response.closed === 'true') {
      this.closeDelete();
      this.props.callbackDeletedReport();
    }

    this.setState({
      hasError: response,
    });
  }

  async editPDF(data) {
    let job = await this.getJob(data.uuid);
    this.setState({ edit: job });
  }
  async newPDF() {
    let response = await this.createJob();
    this.props.callbackNewReport();
    this.setState({ edit: response });
  }
  closePDF() {
    this.setState({ edit: false }, () => {
      const regex =
        /(job)=([0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12})/gm;
      const str = window.location.hash;
      const subst = ``;
      const result = str.replace(regex, subst);
      window.location.hash = result;
      this.props.callbackCloseReportDetailView();
    });
  }

  getJob = async (uuid) => {
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/jobs/' + uuid, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
    if (!!response.uuid === false) {
      console.log('Something failed', response);
      return false;
    }
    return response;
  };

  createJob = async () => {
    if (!!this.props.inboxUuid) {
      if (!!!this.props.serial) {
        let formData = new FormData();
        formData.append('_method', 'PUT');
        let response = await fetch(
          process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.inboxUuid,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${this.props.Authentication.access_token}`,
            },
            body: formData,
          },
        )
          .then((res) => {
            if (res.ok) {
              return res.json();
            } else {
              throw res;
            }
          })
          .then((json) => {
            return json;
          })
          .catch((error) => {
            console.log(error);
            return false;
          });

        if (!!response !== true) {
          console.error('Something failed');
          return;
        }
      }

      let formData = new FormData();
      formData.append('_method', 'POST');
      formData.append('uuid', this.props.inboxUuid);
      let response = false;

      response = await fetch(
        process.env.REACT_APP_API_URL + '/api/jobs/' + this.props.inboxUuid + '/true',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.props.Authentication.access_token}`,
          },
          body: formData,
        },
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          return json;
        })
        .catch((error) => {
          console.log(error);
          return false;
        });
      if (!!response.uuid === false) {
        console.log('Something failed', response);
        return false;
      }
      return response;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <>
        <Dialog open={!!this.state.delete} onClose={this.closeDelete}>
          <DialogTitle>{TextDE.workorder.deleteDialog.title(this.state.delete.serial)}</DialogTitle>
          <DialogContent>
            <DialogContentText>{TextDE.workorder.deleteDialog.text}</DialogContentText>
            <Typography noWrap variant='body1'>
              {this.state.delete?.data?.subject ??
                this.state.delete?.data?.subtitle ??
                this.state.delete?.data?.body}
            </Typography>
            <ul>
              {this.state.delete?.items?.length > 0 && (
                <li>{TextDE.workorder.tasks(this.state.delete.items.length)}</li>
              )}
              {this.state.delete?.parts?.length > 0 && (
                <li>{TextDE.workorder.parts(this.state.delete.parts.length)}</li>
              )}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDelete} color='secondary'>
              {TextDE.abort}
            </Button>
            <Button
              onClick={this.deleteJob}
              style={{ marginLeft: 'auto' }}
              color='primary'
              variant='contained'
            >
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={1} className={classes.fixSize}>
          <Grid item xs={12} md={6}>
            <Typography color='initial' component='h4' variant='h4' noWrap gutterBottom>
              {TextDE.workorder.labelOpenWorkorders(
                this.props.jobs.filter((value) => value.status.name.toLowerCase() !== 'erledigt')
                  .length,
              )}
            </Typography>
            {this.props.jobs
              .filter((value) => value.status.name.toLowerCase() !== 'erledigt')
              .map((job, key) => (
                <PerformanceReportCard
                  key={'open-' + job.uuid}
                  procedureSerial={this.props.serial}
                  job={job}
                  viewPDF={this.viewPDF}
                  getPDF={this.getPDF}
                  newPDF={this.newPDF}
                  editPDF={this.editPDF}
                  closePDF={this.closePDF}
                  callbackAfterCreate={this.callbackAfterCreate}
                  callbackUserUpdate={this.props.callbackNewReport}
                  showDeleteDialog={this.showDeleteDialog}
                  closeDelete={this.closeDelete}
                  deleteJob={this.deleteJob}
                  previewPDF={this.previewPDF}
                  canInviteUsers={this.props.canInviteUsers}
                  usersAll={this.props.usersAll}
                />
              ))}
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography color='initial' component='h4' variant='h4' noWrap gutterBottom>
              {TextDE.workorder.labelDoneWorkorders(
                this.props.jobs.filter((value) => value.status.name.toLowerCase() === 'erledigt')
                  .length,
              )}
            </Typography>
            {this.props.jobs
              .filter((value) => value.status.name.toLowerCase() === 'erledigt')
              .map((job, key) => (
                <PerformanceReportCard
                  key={'done-' + job.uuid}
                  procedureSerial={this.props.serial}
                  job={job}
                  viewPDF={this.viewPDF}
                  getPDF={this.getPDF}
                  newPDF={this.newPDF}
                  editPDF={this.editPDF}
                  closePDF={this.closePDF}
                  callbackAfterCreate={this.callbackAfterCreate}
                  callbackUserUpdate={this.props.callbackNewReport}
                  showDeleteDialog={this.showDeleteDialog}
                  closeDelete={this.closeDelete}
                  deleteJob={this.deleteJob}
                  previewPDF={this.previewPDF}
                  canInviteUsers={this.props.canInviteUsers}
                  usersAll={this.props.usersAll}
                />
              ))}
          </Grid>
        </Grid>

        <Dialog fullScreen={true} open={!!this.state.edit} onClose={this.closePDF}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Typography variant='h5' className={classes.title}>
                Auftrag:&nbsp;
                {this.props.serial} /{' '}
                {!!this.state.edit?.serial ? this.state.edit.serial : this.props.jobs?.length + 1}
              </Typography>
              <IconButton
                style={{ float: 'right' }}
                onClick={() => this.previewPDF(this.state.edit)}
              >
                <PrintIcon
                  style={{
                    color: this.props.Theme.palette.primary.contrastText,
                  }}
                />
              </IconButton>
              <IconButton style={{ float: 'right' }} onClick={this.closePDF}>
                <CloseIcon
                  style={{
                    color: this.props.Theme.palette.primary.contrastText,
                  }}
                />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Grid
            container
            spacing={0}
            style={{
              overflow: 'auto',
              maxHeight: '100%',
              padding: '8px 12px 16px 12px',
            }}
          >
            <Grid item xs={12}>
              <PerformanceReportNew
                uuid={this.props.uuid}
                serial={this.props.serial}
                subject={this.props.subject}
                comment={this.props.comment}
                subtitle={this.props.subtitle}
                job={this.state.edit}
                usersAll={this.props.usersAll}
                preSelectedUsers={this.props.preSelectedUsers}
                callbackFunction={this.callbackAfterCreate}
                closeFunction={this.closePDF}
              />
            </Grid>
          </Grid>
        </Dialog>

        <Fab color='primary' aria-label='add' className={classes.fab} onClick={this.newPDF}>
          <AddIcon />
        </Fab>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Theme: state.Style.Theme,
  Authentication: state.Authentication,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(PerformanceReport);
