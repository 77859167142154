import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
//import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { compose } from 'react-recompose';
import Alert from '@material-ui/lab/Alert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { DE as TextDE } from '../../lib/Text';
const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {},
  avatar: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  NavLinkItem: {
    width: '33%',
    padding: theme.spacing(3),
  },
  alert: {
    width: '100%',
  },
  alignEnd: {
    float: 'right',
  },
  popup: {
    position: 'absolute',
    zIndex: 2000,
    top: '10vh',
    left: '3vw',
    width: '94vw',
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,

    [theme.breakpoints.up('lg')]: {
      fontSize: '120%',
      top: '10vh',
      left: '50vw',
      transform: 'translate(-50%, 0)',
      width: 'auto',
    },
    '&>div': {
      padding: theme.spacing(1),
      '&>svg': {
        fontSize: '140%',
      },
      fontSize: '140%',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: 1000,
    width: '100vw',
    height: '100vh',
  },
});

class Decline extends React.Component {
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleDelete = () => {
    this.setState({ error: false });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    await this.props.declineInquiry({
      email: this.state.email,
      reason: this.state.reason,
      neverAgain: this.state.neverAgain,
    });
    this.setState({ loading: false });
  }
  handleCheckbox() {
    this.setState({ neverAgain: !this.state.neverAgain });
  }
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      neverAgain: false,
      email: props.user.email ?? '',
      reason: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            this.props.close();
          }
        }}
        open={this.props.open}
      >
        <DialogContent>
          <div
            style={{
              backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
              width: '100%',
              minHeight: '60px',
              height: 'auto',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundPosition: 'center',
            }}
          >
            <IconButton
              aria-label={TextDE.close}
              onClick={this.props.close}
              edge='end'
              className={classes.alignEnd}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {this.state.loading ? (
            <Box my={4}>
              {' '}
              <CircularProgress />{' '}
            </Box>
          ) : (
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    required
                    fullWidth
                    multiline
                    disabled={false}
                    type='text'
                    id='reason'
                    label={TextDE.inbox.competitive.dialog.decline.label}
                    helperText={TextDE.inbox.competitive.dialog.decline.text}
                    name='reason'
                    value={this.state.reason}
                    onChange={this.handleChange}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    value='end'
                    control={
                      <Checkbox checked={this.state.neverAgain} onChange={this.handleCheckbox} />
                    }
                    label={
                      <Typography>{TextDE.inbox.competitive.dialog.decline.neverAgain}</Typography>
                    }
                    labelPlacement='end'
                  />
                </Grid>

                <Grid item xs={12}>
                  {!!this.state.error ? (
                    <Alert classes={classes.alert} severity='error'>
                      {this.state.error}
                    </Alert>
                  ) : null}
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='secondary'
                    className={classes.submit}
                    endIcon={<CloseIcon />}
                  >
                    {TextDE.decline}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </DialogContent>

        <DialogActions>
          <NavLink
            to='/agb'
            className={classes.NavLinkItem}
            key='/agb'
            activeClassName='NavLinkItem-selected'
            target='_blank'
            rel='noopener noreferrer'
          >
            AGBs
          </NavLink>
          <NavLink
            to='/privacy'
            className={classes.NavLinkItem}
            key='/privacy'
            activeClassName='NavLinkItem-selected'
            target='_blank'
            rel='noopener noreferrer'
          >
            Datenschutz
          </NavLink>
        </DialogActions>
      </Dialog>
    );
  }
}
//export default Login;

const mapStateToProps = (state) => ({ Authentication: state.Authentication });
//export default connect(mapStateToProps)(Login);
export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    //, mapDispatchToProps
  ),
)(Decline);
