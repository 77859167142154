/* eslint-disable no-unused-vars */
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
/* eslint-enable no-unused-vars */

function calculateTimeTotal(group) {
  let sum = 0;
  let unpayed = 0;
  if (group.data.length > 0) {
    group.data.forEach((value) => {
      if (value.category?.calculate === true || value.category?.calculate === 'true') {
        sum += value.total;
      } else {
        unpayed += value.total;
      }
    });
  } else if (group.groups.length > 0) {
    group.groups.forEach((tmpGroup) => {
      let tmp = calculateTimeTotal(tmpGroup);
      sum += tmp['sum'];
      unpayed += tmp['unpayed'];
    });
  }
  return { sum: sum, unpayed: unpayed };
}

export default class CustomGroupRow extends React.Component {
  rotateIconStyle = (isOpen) => ({
    transform: isOpen ? 'rotate(90deg)' : 'none',
  });

  render() {
    //console.log("[CustomGroupRow] Props", this.props);

    let colSpan = this.props.columns.filter((columnDef) => !columnDef.hidden).length;
    this.props.options.selection && colSpan++;
    this.props.detailPanel && colSpan++;
    this.props.actions && this.props.actions.length > 0 && colSpan++;
    const column = this.props.groups[this.props.level];

    let detail;
    if (this.props.groupData.isExpanded) {
      if (this.props.groups.length > this.props.level + 1) {
        // Is there another group
        detail = this.props.groupData.groups.map((groupData, index) => (
          <this.props.components.GroupRow
            actions={this.props.actions}
            key={groupData.value || '' + index}
            columns={this.props.columns}
            components={this.props.components}
            detailPanel={this.props.detailPanel}
            getFieldValue={this.props.getFieldValue}
            groupData={groupData}
            groups={this.props.groups}
            icons={this.props.icons}
            level={this.props.level + 1}
            path={[...this.props.path, index]}
            onGroupExpandChanged={this.props.onGroupExpandChanged}
            onRowSelected={this.props.onRowSelected}
            onRowClick={this.props.onRowClick}
            onToggleDetailPanel={this.props.onToggleDetailPanel}
            onTreeExpandChanged={this.props.onTreeExpandChanged}
            onEditingCanceled={this.props.onEditingCanceled}
            onEditingApproved={this.props.onEditingApproved}
            options={this.props.options}
            hasAnyEditingRow={this.props.hasAnyEditingRow}
            isTreeData={this.props.isTreeData}
            cellEditable={this.props.cellEditable}
            onCellEditStarted={this.props.onCellEditStarted}
            onCellEditFinished={this.props.onCellEditFinished}
            scrollWidth={this.props.scrollWidth}
          />
        ));
      } else {
        detail = this.props.groupData.data.map((rowData, index) => {
          if (rowData.tableData.editing) {
            return (
              <this.props.components.EditRow
                columns={this.props.columns}
                components={this.props.components}
                data={rowData}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                localization={this.props.localization}
                key={index}
                mode={rowData.tableData.editing}
                options={this.props.options}
                isTreeData={this.props.isTreeData}
                detailPanel={this.props.detailPanel}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                getFieldValue={this.props.getFieldValue}
                onBulkEditRowChanged={this.props.onBulkEditRowChanged}
                scrollWidth={this.props.scrollWidth}
              />
            );
          } else {
            return (
              <this.props.components.Row
                actions={this.props.actions}
                key={index}
                columns={this.props.columns}
                components={this.props.components}
                data={rowData}
                detailPanel={this.props.detailPanel}
                getFieldValue={this.props.getFieldValue}
                icons={this.props.icons}
                path={[...this.props.path, index]}
                onRowSelected={this.props.onRowSelected}
                onRowClick={this.props.onRowClick}
                onToggleDetailPanel={this.props.onToggleDetailPanel}
                options={this.props.options}
                isTreeData={this.props.isTreeData}
                onTreeExpandChanged={this.props.onTreeExpandChanged}
                onEditingCanceled={this.props.onEditingCanceled}
                onEditingApproved={this.props.onEditingApproved}
                hasAnyEditingRow={this.props.hasAnyEditingRow}
                cellEditable={this.props.cellEditable}
                onCellEditStarted={this.props.onCellEditStarted}
                onCellEditFinished={this.props.onCellEditFinished}
                scrollWidth={this.props.scrollWidth}
              />
            );
          }
        });
      }
    }

    const freeCells = [];
    for (let i = 0; i < this.props.level; i++) {
      freeCells.push(<TableCell padding='checkbox' key={i} />);
    }

    let value = this.props.groupData.value;
    if (column.lookup) {
      value = column.lookup[value];
    }

    let title = column.title;
    if (typeof this.props.options.groupTitle === 'function') {
      title = this.props.options.groupTitle(this.props.groupData);
    } else if (typeof title !== 'string') {
      title = React.cloneElement(title);
    }

    let separator = this.props.options.groupRowSeparator || ': ';
    let timeTotal = calculateTimeTotal(this.props.groupData);
    //console.log("[CustomGroupRow] Render", colSpan,column, value,);
    return (
      <>
        <TableRow>
          {freeCells}
          <this.props.components.Cell
            colSpan={colSpan - (3 + freeCells.length)}
            padding='none'
            columnDef={column}
            value={value}
            icons={this.props.icons}
          >
            <>
              <IconButton
                style={{
                  transition: 'all ease 200ms',
                  ...this.rotateIconStyle(this.props.groupData.isExpanded),
                }}
                onClick={(event) => {
                  this.props.onGroupExpandChanged(this.props.path);
                }}
                size='medium'
              >
                <this.props.icons.DetailPanel />
              </IconButton>
              <b>
                {title}
                {separator}
              </b>
            </>
          </this.props.components.Cell>
          <TableCell align='right' colSpan={3} key={encodeURI({ value }) + '-key'}>
            {(!!this.props.groupData.isExpanded === false ||
              this.props.groupData.groups.length === 0) && (
              <Typography style={{ display: 'flex', flexDirection: 'column' }}>
                <span>
                  Unbezahlt:&nbsp;
                  <b>
                    <big>
                      {Number.parseInt(timeTotal['unpayed'] / 60)
                        .toString()
                        .padStart(2, '0')}
                      :
                      {Number.parseInt(timeTotal['unpayed'] % 60)
                        .toString()
                        .padStart(2, '0')}
                    </big>
                    &nbsp;
                    <small>({(timeTotal['unpayed'] / 60).toFixed(2)} Stunden)</small>
                  </b>
                </span>
                <span>
                  Bezahlt:&nbsp;
                  <b>
                    <big>
                      {Number.parseInt(timeTotal['sum'] / 60)
                        .toString()
                        .padStart(2, '0')}
                      :
                      {Number.parseInt(timeTotal['sum'] % 60)
                        .toString()
                        .padStart(2, '0')}
                    </big>
                    &nbsp;
                    <small>({(timeTotal['sum'] / 60).toFixed(2)} Stunden)</small>
                  </b>
                </span>
              </Typography>
            )}
          </TableCell>
        </TableRow>
        {detail}
      </>
    );
  }
}

CustomGroupRow.defaultProps = {
  columns: [],
  groups: [],
  options: {},
  level: 0,
};

CustomGroupRow.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.arrayOf(PropTypes.object),
  components: PropTypes.object,
  detailPanel: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.object)]),
  getFieldValue: PropTypes.func,
  groupData: PropTypes.object,
  groups: PropTypes.arrayOf(PropTypes.object),
  hasAnyEditingRow: PropTypes.bool,
  icons: PropTypes.object,
  isTreeData: PropTypes.bool.isRequired,
  level: PropTypes.number,
  localization: PropTypes.object,
  onGroupExpandChanged: PropTypes.func,
  onRowSelected: PropTypes.func,
  onRowClick: PropTypes.func,
  onToggleDetailPanel: PropTypes.func.isRequired,
  onTreeExpandChanged: PropTypes.func.isRequired,
  onEditingCanceled: PropTypes.func,
  onEditingApproved: PropTypes.func,
  options: PropTypes.object,
  path: PropTypes.arrayOf(PropTypes.number),
  scrollWidth: PropTypes.number.isRequired,
  cellEditable: PropTypes.object,
  onCellEditStarted: PropTypes.func,
  onCellEditFinished: PropTypes.func,
  onBulkEditRowChanged: PropTypes.func,
};
