import { saveMenu } from './NavMenu';
/*
 * action types
 */
export const RESET = 'RESET_USER';
export const FETCH = 'FETCH_USER';
export const UPDATE = 'UPDATE_USER';
export const UPDATECOMPANY = 'UPDATE_COMPANY';
export const UPDATEDUSEREXTERNAL = 'UPDATED_USER_EXTERNAL';
export const UPDATEDTHEMEEXTERNAL = 'UPDATED_THEME_EXTERNAL';
export const UPDATEDCUSTOMER = 'UPDATED_CUSTOMER';
export const UPDATEDCUSTOMEREXTERNAL = 'UPDATED_CUSTOMER_EXTERNAL';
export const UPDATEDUSEREMAILVERIFIED = 'UPDATED_USER_EMAIL_VERIFIED';

/*
 * action creators
 */
export const get = (res) => {
  return { type: FETCH, res };
};

export const fetch = (res) => {
  return async function (dispatch, getState) {
    if (!!res.config.menu) {
      let menu = getState().NavMenu.menu;
      if (!Array.isArray(res.config.menu)) {
        res.config.menu = Object.values(res.config.menu);
      }
      res.config.menu.forEach((entry) => {
        let parentIndex = menu.findIndex((element) => element.key === entry.parent);
        if (!!menu[parentIndex]) {
          let existIndex = menu[parentIndex].submenu.findIndex(
            (element) =>
              element.key === entry.key &&
              element.name === entry.name &&
              element.path === entry.path,
          );
          if (existIndex === -1) {
            dispatch(saveMenu(entry));
          }
        }
      });
    }
    dispatch(get(res));
  };
};

export const update = (res) => {
  return { type: UPDATE, res };
};

export const updateCompany = (res) => {
  return { type: UPDATECOMPANY, res };
};

export const updateCustomer = (res) => {
  return { type: UPDATEDCUSTOMER, res };
};

export const updateThemeExternal = (res) => {
  return { type: UPDATEDTHEMEEXTERNAL, res };
};

export const updateCustomerExternal = (res) => {
  return { type: UPDATEDCUSTOMEREXTERNAL, res };
};

export const updateUserExternal = (res) => {
  return { type: UPDATEDUSEREXTERNAL, res };
};

export const updateUserEmailVerified = (res) => {
  return { type: UPDATEDUSEREMAILVERIFIED, res };
};

export const reset = () => {
  return { type: RESET };
};
