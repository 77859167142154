import {
  FETCH,
  UPDATE,
  UPDATECOMPANY,
  UPDATEDUSEREXTERNAL,
  UPDATEDTHEMEEXTERNAL,
  UPDATEDCUSTOMEREXTERNAL,
  RESET,
  UPDATEDUSEREMAILVERIFIED,
  UPDATEDCUSTOMER,
} from '../actions/User';

//const initialState = { uuid: null, last_name: null, first_name: null }
const initialState = null;

const User = (state = initialState, action) => {
  switch (action.type) {
    case FETCH:
      return {
        uuid: action.res.uuid,
        customer: action.res.customer,
        firstname: action.res.firstname,
        lastname: action.res.lastname,
        nickname: action.res.nickname,
        verified: action.res.email_verified_at,
        email: action.res.email,
        calendarid: action.res.calendarid,
        fax: action.res.fax,
        mobile: action.res.mobile,
        phone: action.res.phone,
        notification: action.res.notification,
        config: action.res.config,
        avatar: action.res.avatar ?? state.avatar,
        permissions: action.res.permissions,
        has_mail_account: action.res.has_mail_account?.length > 0,
        preferred_mail_account: action.res.preferred_mail_account,
        roles: action.res.roles,
        isAdvancedUser: !!action.res.is_advanced_user,
        isAdmin:
          action.res.roles.filter((role) => role.name.toUpperCase().includes('ADMINISTRATOR'))
            .length > 0,
        tags: action.res.tags,
        unreadNotificationCount: action.res.unreadNotificationCount,
        unread_notifications: action.res.unread_notifications,
        working_hours: action.res.working_hours,
      };
    case UPDATE:
      let notification = state.notification;
      notification.mail = action.res.notificationMail ?? notification.mail;
      notification.push = action.res.notificationPush ?? notification.push;
      notification.mobile = action.res.notificationMobile ?? notification.mobile;
      return {
        ...state,
        firstname: action.res.firstname ?? state.firstname,
        lastname: action.res.lastname ?? state.lastname,
        nickname: action.res.nickname ?? state.nickname,
        verified: action.res.email_verified_at ?? state.verified,
        isAdvancedUser: action.res.is_advanced_user ?? state.isAdvancedUser,
        email: action.res.email ?? state.email,
        calendarid: action.res.calendarid ?? state.calendarid,
        fax: action.res.fax ?? state.fax,
        mobile: action.res.mobile ?? state.mobile,
        phone: action.res.phone ?? state.phone,
        notification: notification,
        config: action.res.config ?? state.config,
        avatar: action.res.avatar ?? state.avatar,
        working_hours: action.res.working_hours ?? state.working_hours,
      };

    case UPDATECOMPANY:
      return {
        ...state,
        customer: {
          ...state.customer,
          company: action.res.company,
        },
      };

    case UPDATEDUSEREMAILVERIFIED:
      return {
        ...state,
        verified: action.res.verified,
      };
    case UPDATEDUSEREXTERNAL:
      return {
        ...state,
        firstname: action.res.firstname ?? state.firstname,
        lastname: action.res.lastname ?? state.lastname,
        nickname: action.res.nickname ?? state.nickname,
        verified: action.res.email_verified_at ?? state.verified,
        fax: action.res.fax ?? state.fax,
        calendarid: action.res.calendarid ?? state.calendarid,
        mobile: action.res.mobile ?? state.mobile,
        phone: action.res.phone ?? state.phone,
        notification: action.res.notification ?? state.notification,
        permissions: action.res.permissions ?? state.permissions,
        has_mail_account:
          action.res.has_mail_account?.length > 0 ?? state.has_mail_account.length > 0,
        preferred_mail_account: action.res.preferred_mail_account ?? state.preferred_mail_account,
        roles: action.res.roles ?? state.roles,
        isAdvancedUser: !!action.res.is_advanced_user,
        isAdmin: !!action.res.roles
          ? action.res.roles.filter((role) => role.name.toUpperCase().includes('ADMINISTRATOR'))
              .length > 0
          : state.roles.filter((role) => role.name.toUpperCase().includes('ADMINISTRATOR')).length >
            0,
        tags: action.res.tags ?? state.tags,
        working_hours: action.res.working_hours ?? state.working_hours,
      };

    case UPDATEDTHEMEEXTERNAL:
      return {
        ...state,
        config: action.res.config ?? state.config,
      };
    case UPDATEDCUSTOMER:
      return {
        ...state,
        customer: action.res.customer ?? state.customer,
      };
    case UPDATEDCUSTOMEREXTERNAL:
      return {
        ...state,
        customer: action.res.customer ?? state.customer,
      };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default User;
