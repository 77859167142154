import * as React from 'react';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

export function CustomSummaryRow({ data, columns, currentData, rowProps, renderSummaryRow }) {
  if (!renderSummaryRow) {
    return null;
  }

  if (!!currentData && !!currentData[0] && !!currentData[0].groups) {
    return null;
  }
  if (!!currentData && currentData.length <= 0) {
    return null;
  }

  let sum = 0;
  let sumTarget = 0;

  currentData.forEach((value) => {
    if (value.category?.calculate === true || value.category?.calculate === 'true') {
      sum += value.total;
      sumTarget += value.user.working_hours[value.category.base] ?? 480;
    }
  });

  let placeholderLeftColumns = 0;
  let placeholderRightColumns = 0;

  // Create empty columns corresponding to selection, actions, detail panel, and tree data icons
  if (rowProps.options.selection) {
    placeholderLeftColumns += 1;
  }
  if (
    rowProps.actions &&
    rowProps.actions.filter((a) => a.position === 'row' || typeof a === 'function').length > 0
  ) {
    if (rowProps.options.actionsColumnIndex === -1) {
      placeholderRightColumns += 1;
    } else if (rowProps.options.actionsColumnIndex >= 0) {
      placeholderLeftColumns += 1;
    }
  }
  if (rowProps.detailPanel && rowProps.options.showDetailPanelIcon) {
    if (rowProps.options.detailPanelColumnAlignment === 'right') {
      placeholderRightColumns += 1;
    } else {
      placeholderLeftColumns += 1;
    }
  }
  if (rowProps.isTreeData) {
    placeholderLeftColumns += 1;
  }

  let lengthOfRow = columns.length + placeholderLeftColumns + placeholderRightColumns;

  let payedString = (
    <>
      <b>
        {Number.parseInt(sum / 60)
          .toString()
          .padStart(2, '0')}
        :
        {Number.parseInt(sum % 60)
          .toString()
          .padStart(2, '0')}
      </b>{' '}
      <small>({(sum / 60).toFixed(2)} Stunden)</small>
    </>
  );
  let payedTargetString = (
    <>
      <b>
        {Number.parseInt(sumTarget / 60)
          .toString()
          .padStart(2, '0')}
        :
        {Number.parseInt(sumTarget % 60)
          .toString()
          .padStart(2, '0')}
      </b>{' '}
      <small>({(sumTarget / 60).toFixed(2)} Stunden)</small>
    </>
  );

  return (
    <>
      <TableRow>
        <TableCell
          colSpan={lengthOfRow}
          style={{ border: 'none', padding: '0 82px 0 0', margin: '0' }}
        >
          <Typography
            variant='h3'
            component='div'
            style={{
              textAlign: 'end',
              paddingRight: '16px',
              paddingTop: '12px',
              display: 'flex',
              alignItems: 'end',
            }}
          >
            <span style={{ flexGrow: 1 }}>
              <small>Ist&nbsp;</small>
              {payedString}
            </span>
            <span
              style={{
                width: '20vw',
                textAlign: 'start',
                textDecoration: 'none',
                borderLeft: '2px solid',
                marginLeft: '8px',
                paddingLeft: '8px',
              }}
            >
              <small>Soll&nbsp;</small>
              {payedTargetString}
            </span>
          </Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

CustomSummaryRow.propTypes = {
  data: PropTypes.array,
  currentData: PropTypes.array,
  columns: PropTypes.array,
  renderSummaryRow: PropTypes.func,
};

export const styles = (theme) => ({});

export default withStyles(styles)(CustomSummaryRow);
