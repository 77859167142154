/*
 * How to Use?
 *
 * import { DE as TextDE } from '../../../lib/Text';
 *
 * Access in JSX via <p>{TextDE.inbox.title}</p>
 * ==>  Betreff
 */

import { Fragment } from 'react';

const inboxPreview = {
  title: 'Anfrage einsehen',
  titleHelper:
    'Bitte verifizieren Sie Ihre E-Mail Adresse, so dass wir sicher sein können das die Anfrage bei Ihnen ankommt!',
};

const remoteForm = {
  title: 'Kontaktformular',
  titlePreview: 'Neue Anfrage einsehen',
  text: (companyCount) => {
    return <Fragment> Sie haben eine Neue Anfrage über Ihr Kontaktformular bekommen!</Fragment>;
  },
  linkText: 'Die anfrAge im verlauf lesen',
  buttonsLabel: 'Bitte wählen Sie aus ob Sie diese Anfrage bearbeiten wollen!',
  deletedError: {
    title: 'Gelöschte Anfrage!',
    text: (uuid) => `Bitte mit der ID: '${uuid}' beim Support melden!`,
  },
  dialog: {
    accept: {
      title: '',
    },
    decline: {
      title: 'Anfrage ablehnen',
      text: 'Bitte teilen Sie hier dem Kunden mit weshalb Sie diese Anfrage nicht bearbeiten werden. Diese Nachricht wird an den Kunden weitergeleitet.',
      label: 'Ich werde die Anfrage nicht bearbeiten, da:',
    },
  },
};

const timetracking = {
  helperText: 'Bitte tragen Sie Ihre Daten ein...',
  title: 'Zeiterfassung',
  editTitle: 'Eintrag ändern',
  helperEditText: 'Die erfasste Zeit ändern',
  endtimeLabel: 'Ende',
  starttimeLabel: 'Beginn',
  breakLabel: 'Pause',
  trackedAtLabel: 'Am',
  categoryLabel: 'Art',
  coworkersLabel: 'Für',
  totalLabel: 'Saldo',
  labelUserFilter: 'Mitarbeiter filtern',
  helperUserFilter: 'Nach Mitarbeiter Name filtern',
  labelCategoryFilter: 'Kategorie filtern',
  helperCategoryFilter: 'Nach Art der Erfassten Zeit filtern',
  targetTimeLabel: 'Regel-/Soll- Arbeitszeit',
  vacationTimeLabel: 'Urlaubszeit',
  illnessTimeLabel: 'Krankheitszeit',
  addButtonLabel: 'Arbeitszeit erfassen',
  columns: {
    user_id: {
      label: 'Person',
    },
    tracked_at: {
      label: 'Datum',
    },
    start_time: {
      label: 'Beginn',
    },
    end_time: {
      label: 'Ende',
    },
    break: {
      label: 'Pause',
    },
    categories: {
      label: 'Kategorie',
    },
    total: {
      label: 'Saldo',
      asHoursLabel: 'Saldo in HH:MM',
      asDecimalLabel: 'Saldo in Std',
    },
    target_time: {
      label: 'Soll',
      asHoursLabel: 'Soll in HH:MM',
      asDecimalLabel: 'Soll in Std',
    },
    updated_at: {
      label: 'letzte Änderung',
    },
    startDate: {
      label: 'startDate',
    },
    endDate: {
      label: 'endDate',
    },
  },
};

const consumerApp = {
  title: 'Anfrage',
  titlePreview: 'Neue Anfrage einsehen',
  text: (companyCount) => {
    companyCount = parseInt(companyCount) - 1;
    if (companyCount === 1) {
      return (
        <Fragment>
          Diese Anfrage wurde an <strong>Sie und ein weiteres Unternehmen</strong> gesendet.
        </Fragment>
      );
    } else if (companyCount >= 2) {
      return (
        <Fragment>
          Diese Anfrage wurde an <strong>Sie und {companyCount} andere Unternehmen</strong>{' '}
          gesendet.
        </Fragment>
      );
    }
    return (
      <Fragment>
        Diese Anfrage wurde <strong>exklusiv an Sie</strong> gesendet.
      </Fragment>
    );
  },
  linkText: 'Die anfrAge im verlauf lesen',
  buttonsLabel: 'Bitte wählen Sie aus ob Sie diese Anfrage bearbeiten wollen!',
  deletedError: {
    title: 'Gelöschte Anfrage!',
    text: (uuid) => `Bitte mit der ID: '${uuid}' beim Support melden!`,
  },
  dialog: {
    accept: {
      title: '',
    },
    decline: {
      title: 'Anfrage ablehnen',
      text: 'Diese Nachricht wird NICHT an den Kunden weitergeleitet - sagen Sie uns warum Sie die Anfrage nicht bearbeiten möchten und wir werden in Zukunft noch genauer auf Ihre Bedürfnisse eingehen!',
      label: 'Ich möchte die Anfrage derzeit nicht bearbeiten, da:',
      neverAgain: 'Ich möchte keinerlei Anfragen mehr erhalten!',
    },
  },
};

const list = {
  addMenu: {
    procedure: {
      label: 'Neuer Vorgang',
    },
    email: {
      label: 'E-Mail senden',
    },
    fast: {
      label: 'Schnelleintrag',
    },
  },
  order: {
    oldestFirstTooltip: 'Die ältesten Vorgänge auf Seite eins anzeigen',
    oldestFirstLabel: 'Älteste zuerst',
    newestFirstTooltip: 'Die neuesten Vorgänge auf Seite eins anzeigen',
    newestFirstLabel: 'Neueste zuerst',
    updatedFirstTooltip: 'Die zuletzt aktualisierten Vorgänge auf Seite eins anzeigen',
    updatedFirstLabel: 'zuletzt Aktualisiert',
  },
  countOnPage: 'Einträge pro Seite',
  stateFilter: {
    label: 'Status Filter',
    tooltip: 'Nur Vorgänge mit den ausgewählten Stati anzeigen',
    save: 'Filter anwenden',
  },
  userFilter: {
    label: 'Mitarbeiter',
    tooltip: 'Nur Vorgänge mit den ausgewählten Mitarbeitern anzeigen',
    save: 'Filter anwenden',
  },
  customMenuDialog: {
    title: '',
    text: 'Name für die gespeicherte Suche',
    itemName: 'Name',
  },
};

const inbox = {
  title: 'Betreff',
  titleHelper: 'In der Listenansicht deutlich sichtbarer Betreff',
  titleTooltip: 'Hier klicken um den Betreff zu bearbeiten',
  subtitle: 'Beschreibung',
  subtitleHelper: 'In der Listenansicht sichtbare Beschreibung',
  subtitleTooltip: 'Hier klicken um diese Beschreibung zu bearbeiten',
  cantShowInboxItem:
    'Die gesuchte Nachricht existiert nicht, oder Ihnen fehlen die Berechtigungen um darauf zuzugreifen — Wenden Sie sich an Ihren Administrator oder an den Support.',
  deleteInboxItemDialog: {
    title: 'Vorgang löschen?',
    text: 'Vorgang sicher löschen?',
  },
  makeNewProcedureLabel: 'Aus dieser neuen Anfrage einen Vorgang machen?',
  makeNewProcedureHint: 'Hieraus einen eigenständigen Vorgang erstellen!',
  deleteLabel: 'Sie wollen den Vorgang Löschen?',
  deleteHint: 'Dieser Vorgang lässt sich somit nicht mehr wiederherstellen!',
  alerts: {
    alreadyWritingAppointment:
      'Sie erstellen/bearbeiten bereits einen Termin! Bitte speichern Sie diesen zunächst oder schließen das Formular.',
    alreadyWritingChat:
      'Sie schreiben bereits eine Chat Nachricht! Bitte senden Sie diese zunächst ab oder schließen das Formular.',
    alreadyWritingEmail:
      'Sie schreiben bereits eine E-Mail! Bitte senden Sie diese zunächst oder schließen das Formular.',
    alreadyWritingNote:
      'Sie erstellen/bearbeiten bereits eine Notiz! Bitte speichern Sie diese zunächst oder schließen das Formular.',
    deleteNote: (subject = false) =>
      'Wollen Sie die Notiz' + (!!subject ? ': "' + subject + '"' : '') + ' wirklich löschen?',
    deleteInquiry: (subject = false) =>
      'Wollen Sie die Anfrage' + (!!subject ? ': "' + subject + '"' : '') + ' wirklich löschen?',
    cantDeleteCompetetive:
      'Sie können die Anfrage nicht löschen da Sie sie noch nicht angenommen/abgelehnt haben,',
    cantMakeNewProcedureCompetetive:
      'Sie können die Anfrage nicht zu einem Vorgang machen, da Sie sie noch nicht angenommen haben,',
    informCustomerWhyNotAccept:
      'Bitte schreiben Sie dem Kunden eine Nachricht so dass dieser Nachvollziehen kann weshalb die Anfrage abgelehnt wird.',
  },
  customFunctions: {
    label: 'Schnellfunktion',
    labelPlural: 'Schnellfunktionen',
    executeLabel: 'ausführen',
    dialogHint: 'Bitte ergänze die Informationen',
  },
  dialog: {
    pleaseConfirm: 'Bitte bestätigen',
  },
  navigation: {
    toMail: 'zur E-Mail',
    overwriteMail: 'E-Mail überschreiben',
    toChat: 'zum Chat',
    overwriteChat: 'Chat überschreiben',
    toNote: 'zur Notiz',
    overwriteNote: 'Notiz überschreiben',
    toAppointment: 'zum Termin',
    overwriteAppointment: 'Termin überschreiben',
  },
  tabs: {
    email: 'E-Mail Nachricht',
    chat: 'Chat Nachricht',
    appointment: 'Termin',
    note: 'Neue Notiz',
    noteEdit: 'Notiz ändern',
    procedure: 'Vorgang anlegen',
  },
  assign: {
    title: 'Bestehendem Vorgang zuweisen',
    label: 'Zu einem Vorgang hinzufügen',
    helper: 'Die Nachricht einer bestehenden Vorgangsnummer zuweisen, Suchen Sie hier nach der ID',
    moveToButton: 'In Vorgang Verschieben',
    alerts: {
      searchResponse: (value) => 'Zu Vorgang "ID#' + value.toString() + '" hinzufügen',
      isCompetitive:
        'Sie können die Anfrage nicht zuweisen da Sie sie noch nicht angenommen haben.',
    },
  },
  adminForm: consumerApp,
  flixworkerInbox: consumerApp,
  consumerApp: consumerApp,
  remoteForm: remoteForm,
  customerPrefForm: consumerApp,
};

const procedure = {
  addProcedureLabel: 'Neuer Vorgang',
  addProcedureBySendingMailLabel: 'E-Mail senden',
  addFastInboxLabel: 'Schnelleintrag',
  label: 'Vorgang',
  labelPlural: 'Vorgänge',
  serial: 'Vorgangsnummer',
  serialHelper: 'In der Listenansicht sichtbare Vorgangsnummer',
  serialTooltip: 'Die eindeutige Nummer für diesen Vorgang',
  stateChangeHint: 'Status ändern',
  labelNotes: 'Verlauf',
  tagLabel: 'Schlagwort',
  tagPlaceholder: 'z.B. Werkstattauftrag',
  tagHelperText: 'Mit Enter bestätigen! - Freitext, z.B Kundennummer, Art des Auftrags, etc.',
  tagText: 'Diese Tags werden genutzt um später z.B Filter einzurichten.',
  addTag: (option) => `"${option}" als neues Schlagwort hinzufügen`,
  fileCanNotBeDisplayed:
    'Ihr Browser kann die Datei leider nicht anzeigen. Bitte laden Sie die Datei herunter!',
  protocol: {
    expand: 'Original aufklappen',
    collapse: 'einklappen',
    more: 'Mehr',
    edit: 'Ändern',
    respond: 'Antworten',
    mail: {
      mailLabel: 'E-Mail',
      senderLabel: 'Absender',
      receiverLabel: 'Empfänger',
      ccLabel: 'CC',
      bccLabel: 'BCC',
      attachmentLabel: 'Anhang',
      subjectLabel: 'Betreff',
    },
  },
  addEmailForm: {
    titleResponse: 'E-Mail Antwort',
    previousMessageInline: 'Vorherige Nachricht',
    isConnectUser: 'Der Kunde kann die Nachricht via FLiXWORKER Connect einsehen',
    connectUserCountLabel: (count = 0) =>
      (count > 1 ? 'Folgende Empfänger können ' : 'Folgender Empfänger kann ') +
      'die Mail (und den Vorgang) in FLiXWORKER Connect einsehen',
    alerts: {
      couldNotBeSaved: 'Die Notiz konnte nicht erstellt werden',
      emptyMessage: 'Ihre Nachricht ist leer!',
    },
  },
  addNoteForm: {
    alerts: {
      couldNotBeSaved: 'Die Notiz konnte nicht erstellt werden',
    },
  },
  addAppointmentForm: {
    alerts: {
      couldNotBeSaved: 'Die Notiz konnte nicht erstellt werden',
    },
    externalContactsLabel: 'Weitere (Externe) Teilnehmer am Termin',
    externalContactsHelper: 'Wer soll an dem Termin teilnehmen',
  },
  shareConnectDialog: {
    title: 'Vorgang Teilen?',
    revokeAccessText: (name = 'den Kontakt') =>
      `Den Zugriff für ${name} über die FLiXWORKER Connect App entziehen?`,
    revokeAccessButton: 'Ja, Zugriff aufheben!',
    grantAccessText: (name = 'diesem Kontakt') =>
      `Wollen Sie diesen Vorgang mit ${name} über die FLiXWORKER Connect App teilen?`,
    grantAccessButton: 'Ja, Zugriff freigeben!',
    alerts: {
      unknownIssue: 'Es gab ein Problem! Bitte dieses Fenster schließen und erneut probieren!',
    },
  },
  addProcedureForm: {
    descriptionLabel: 'Vorgangsbeschreibung',
    descriptionText: 'Vorgangsbeschreibung',
    descriptionHint: 'Was muss erledigt werden?',
    contactLabel: 'Kontakt',
    contactText: (type) => (!!type ? type : 'Ansprechpartner') + ' für diesen Vorgang',
    coworkerLabel: 'Mitarbeiter/in',
    coworkersLabel: 'Mitarbeiter-innen',
    coworkerText: 'Folgende Mitarbeiter/innen sollen diesen Vorgang bearbeiten',
    coworkerTypeLabel: 'Art der Mitarbeit',
    coworkerTypeText: 'Welche Verantwortung soll der Mitarbeiter in diesem Vorgang übernehmen',
    addAdditionalCoworkerButton: 'weitere/n Mitarbeiter/in hinzufügen',
    checkLabel: 'Kontrolle',
    checkText: 'Zur Kontrolle',
    addEntry: 'Eintragen',
    addButton: 'Fertig & Vorgang anlegen!',
    addAddressManually: 'Adresse eingeben',
    addAddressViaSearch: 'zur Suche',
    addAddressLabel: 'Bitte die Adresse eingeben',
    addAddressButton: 'Adresse hinzufügen',
    alerts: {
      subjectMissing: 'Kein Betreff eingetragen...',
      descriptionMissing: 'Keine Beschreibung eingetragen...',
      contactToUse: 'Der Kontakt muss einem der Schlagworte zugeordnet werden!',
      addressToUse: 'Die Adresse muss einem der Schlagworte zugeordnet werden!',
      additionalAddressesInProcedure: 'Weitere Adressen bitte mit Kontakt im Vorgang einpflegen!',
      contactsMissing: 'Keine Ansprechpartner hinterlegt...',
      addressesMissing: 'Keine Adresse hinterlegt...',
      usersMissing: 'Kein/e Mitarbeiter/in hinterlegt...',
      success: 'Perfekt! Alles ist eingetragen!',
    },
  },
};

const job = {
  label: 'Auftrag',
  labelPlural: 'Aufträge',
  serial: 'Auftragsnummer',
};

const notifications = {
  label: 'Benachrichtigungen',
  deleteAllNotificationsLabel: 'Alle Löschen',
};

const note = {
  label: 'Notiz',
  labelPlural: 'Notizen',
  noNote: 'Noch keine Notizen',
  deleteText: 'Die Adresse sicher löschen?',
  deleteTitle: 'Löschen?',
  inputPlaceholder: 'Telefonprotokoll mit Hr Muster, Material ist angekommen ...',
  inputHelperText: 'Éine Notiz zu diesem Vorgang',
};

const file = {
  label: 'Datei',
  labelPlural: 'Dateien',
  noFile: 'Noch keine Dateien',
  errors: {
    toBig: (filename = '', size = 0) =>
      `Die Datei "${filename}" ist mit ${size / (1024 * 1024)} MB zu groß.`,
    couldNotBeUploaded: (filename = '') => `Die Datei "${filename}" kann nicht hochgeladen werden.`,
  },
};
const workorder = {
  label: 'Arbeitsauftrag',
  labelPlural: 'Arbeitsaufträge',
  labelShort: 'Auftrag',
  coworkerText: 'Folgende Mitarbeiter/innen sollen diesen Auftrag bearbeiten',
  coworkersLabel: 'Mitarbeiter-innen',
  labelPluralShort: 'Aufträge',
  labelOpenWorkorders: (count = 1) => (count > 1 ? 'Offene Aufträge' : 'Offener Auftrag'),
  labelDoneWorkorders: (count = 1) => (count > 1 ? 'Nachweise' : 'Nachweis'),
  previewButton: 'Entwurf',
  previewFilename: 'vorschau_arbeitsauftrag',
  idLabel: 'Auftrags ID',
  idTooltip: 'Auftrags - ID, dient zur eindeutigen Identifikation des Arbeitsauftrages/ Nachweises',
  deleteDialog: {
    title: (serial) => `Arbeitsauftrag ${serial} löschen?`,
    text: 'Wollen Sie diesen Arbeitsauftrag wirklich löschen?',
  },
  tasks: (count = 1) => `${count} ${count > 1 ? 'Tätigkeiten' : 'Tätigkeit'}`,
  parts: (count = 1) => `${count} ${count > 1 ? 'Materialien/Pauschalen' : 'Material/Pauschale'}`,
};
const performanceReport = {
  label: 'Leistungsnachweis',
  labelPlural: 'Leistungsnachweise',
  labelShort: 'Nachweis',
  labelPluralShort: 'Nachweise',
  noReport: 'Noch keine Leistungsnachweise',
  performanceDate: 'Leistungsdatum',
  executedBy: 'Ausgeführt durch',
  labelNotes: 'Zusätzliche Anmerkungen',
  comment: 'Anmerkungen',
  helperNotes:
    'Hier können Sie alles mögliche zum Nachweis hinzufügen, z.B. Anmerkungen oder weitere Wartungs Hinweise',
  priorityTooltip: 'Welche Art von Auftrag/Leistung kann erbracht werden?',
  tableTodo: {
    label: 'Ausgeführte Tätigkeiten',
    labelTodo: 'Tätigkeit',
    labelTodoDelete: 'Wollen Sie diese Tätigkeit tatsächlich löschen?',
    labelTodoPlural: 'Tätigkeiten',
    helperTodo: 'Welche Tätigkeit wurde durch wen in welcher Zeit erbracht?',
    labelTime: 'Zeit',
    helperTime: ' in Stunden : Minuten',
    labelTimeMinutes: 'Minuten',
    helperTimeMinutes: 'Arbeitszeit in Minuten',
    labelTimeHours: 'Stunden ',
    helperTimeHours: 'Arbeitszeit in Stunden',
    labelTotalTime: 'Gesamtzeit',
    labelThisIsAnAllowanceJob: 'Hier handelt es sich um einen Pauschalauftrag',
    labelUsers: 'Person',
    helperUsers: 'Eine Person der Tätigkeit zuweisen',
    addTodo: 'Tätigkeit hinzufügen',
    editTodo: 'Tätigkeit editieren',
    validationError: '- mindestens eine Tätigkeit oder Person',
    notFound: 'Der ausgewählte Eintrag exisitiert nicht (mehr) - Bitte laden Sie die Seite erneut',
    labelDoneAtDate: 'Tag der Bearbeitung',
    timeNotShownTooltip:
      'Im generierten Leistungsnachweis (als PDF) werden dann die Zeiten nicht angezeigt.',
  },
  tableParts: {
    label: 'Eingesetztes Material',
    labelPart: 'Material oder Pauschale Dienstleistung',
    labelPartDelete: 'Wollen Sie dieses Material oder diese Pauschale tatsächlich löschen?',
    labelPartPlural: 'Materialien',
    labelAmount: 'Anzahl',
    labelNote: 'Anmerkungen',
    helperAmount: 'Menge/Länge...',
    addPart: 'Material hinzufügen',
    editPart: 'Eintrag anpassen',
    validationError: '- mindestens eine Material',
    noUnit: '(Keine Einheit)',
    labelUnit: 'Einheit',
    helperUnit: 'Die Einheit/ Mengenangabe des Materials',
    labelProductId: 'Artikelnummer',
    helperProductId: 'Artikelnummer',
    labelCategory: 'Kategorie',
    helperCategory: 'Die Kategorie des neuen Eintrags',
    labelPrice: 'Preis',
    helperPrice: 'Standardpreis',
    labelAllowance: (isAllowance = false) =>
      !!isAllowance ? 'Es handelt sich um eine Pauschale' : 'Handelt es sich um eine Pauschale?',
    notFound: 'Der ausgewählte Eintrag exisitiert nicht (mehr) - Bitte laden Sie die Seite erneut',
  },
  worker: 'Ausführender',
  signatureWorker: 'Unterschrift Ausführender',
  customer: 'Kunde',
  signatureCustomer: 'Unterschrift Kunde',
  customerNotAvailable:
    'Der Kunde/ Leistungsempfänger ist nicht anzutreffen, ohne Unterschrift weiter.',
  procedureIsFinished: 'Der Auftrag ist fertig und kann geschlossen werden.',
  stillSomethingTodo: 'Es müssen noch weitere Arbeiten ausgeführt werden!',
  nextThingsTodo: 'Weitere Arbeiten:',
  labelSendCopy: 'Kopie senden an',
  helperSendCopy: 'An wen soll der Leistungsnachweis zusätzlich gesendet werden?',
  helperSendCopyNeeded:
    'Da der Kunde nicht anzutreffen ist/ nicht unterschreiben kann, bitte mindestens einen Empfänger angeben!',
  confirmMessage:
    'Ich möchte den Leistungsnachweis nun Unterschreiben, abschließen und ggfs. dem Kunden eine Kopie via E-Mail zukommen lassen. Danach kann ich diesen nicht mehr editieren. Erst danach werden die eingetragenen Zeiten meinem Stundenkonto gutgeschrieben',
  confirmButton: 'Unterschreiben, absenden & abschließen!',
};

const address = {
  label: 'Adresse',
  labelPlural: 'Adressen',
  deleteText: 'Die Adresse sicher löschen?',
  deleteTextNamedFunction: (name) => `Die Adresse "${name}" sicher aus dem Vorgang löschen?`,
  deleteTitle: 'Löschen?',
  noAddress: 'Noch keine Adresse',
  inputPlaceholder: 'Musterstrasse 12, 12345 Musterhausen',
  tagLabel: 'Zweck',
  tagPlaceholder: 'z.B Rechnungsanschrift',
  tagHelperText: 'Um welche Adresse handelt es sich hierbei?',
  placeOfPerformance: 'Leistungsort',
  invoiceAddress: 'Rechnungsanschrift',
  street_number: 'Hausnummer',
  route: 'Straße',
  locality: 'Stadt',
  postal_code: 'Postleitzahl',
  administrative_area_level_1: 'Bundesland',
  state: 'Bundesland',
  country: 'Land',
  missingHintAddendum: 'fehlt oder konnte nicht gefunden werden!',
  newAddress: 'Neue Adresse',
  addAsNewAddress: (name) => `"${name}" als neue Adresse hinzufügen`,
};

const contact = {
  contactForm: {
    contactTag: 'Der Kontakt ist ...?',
    contactTagUndefinedLabel: 'anderer Kontakt',
    tagPlaceholder: 'Gruppen',
    company: 'Firma',
    companyName: 'Firmenname',
    salutation_male: 'Herr',
    salutation_female: 'Frau',
    salutation_other: 'Divers',
    notes: 'Notizen',
    adress: 'Anschrift',
    adressHelperText: 'Gesamte Addresse angeben, Beispiel: Am Lusbühl 16, 79110 Freiburg',
    number: 'Nummer',
    numberHelperText: 'Einen Nummer dem neuen Kontakt zuweisen (*empfohlen)',
    numberMobile: 'Mobil',
    numberFax: 'Faxnummer',
    email: 'E-Mail',
    emailHelperText: 'Einen E-Mail Addresse dem neuen Kontakt zuweisen (*empfohlen!)',
    labelFavorite: 'Favorit',
    labelWork: 'Arbeit',
    websiteHelperText: 'Eine Webseite dem neuen Kontakt zuweisen',
    websiteWrongFormat: "Das Format ist falsch! Bitte 'https://www.beispiel.de' einhalten!",
    isConnectUser: 'Dieser Kontakt nutzt die FLiXWORKER Connect App',
    hasAccessViaConnect: 'Hat bereits über FLiXWORKER Connect zugriff auf diesen Vorgang',
    noAccessViaConnect: 'Hat noch keinen Zugang zu diesem Vorgang',
  },
  addContact: {
    name: 'Name',
    phone: 'Telefon',
    errorMessage: {
      noHouseNumber: ' - Hausnummer fehlt!\n\r',
      noStreet: ' - Straße fehlt!\n\r',
      noCity: ' - Stadt fehlt!\n\r',
      noCountry: ' - Land fehlt!\n\r',
      noZipCode: ' - Postleitzahl fehlt!\n\r',
    },
  },
  label: 'Kontakt',
  addLabel: 'Kontakt hinzufügen',
  savedAddressTag: 'Gespeicherte Adresse',
  newContact: 'Neuer Kontakt',
  addAsNewContact: (name) => `"${name}" als neuen Kontakt hinzufügen`,
  labelPlural: 'Kontakte',
  inputPlaceholder: 'Frau Manuela Muster',
  helperText: 'Einen Kontakt hier suchen, oder neu eintragen',
  deleteText: 'Den Kontakt sicher löschen?',
  deleteTextNamedFunction: (name) => `${name} sicher aus dem Vorgang löschen?`,
  generateFullName: function (contact) {
    return `${contact.salutation?.name ?? ''}${
      !!contact.title?.name ? ' ' + contact.title?.name + ' ' : ' '
    }${!!contact.firstname ? contact.firstname + ' ' : ''}${contact.name}`.trim();
  },
  generateFormOfAddress: function (contact) {
    return (
      (contact.salutation?.name === 'Herr'
        ? 'Sehr geehrter '
        : contact.salutation?.name === 'Frau'
          ? 'Sehr geehrte '
          : contact.salutation?.name === 'Firma'
            ? 'Zu Händen der zuständigen Stelle der '
            : 'Sehr geehrte(r) ') +
      `${contact.salutation?.name ?? ''}${
        !!contact.title?.name ? ' ' + contact.title?.name + ' ' : ' '
      }${contact.name},`.trim()
    );
  },
  deleteTitle: 'Löschen?',
  noContact: 'Noch kein Kontakt',
  tagLabel: 'Kontaktart',
  tagPlaceholder: 'z.B Rechnungsempfänger',
  tagHelperText: 'Wofür ist der Kontakt, ist es der Anwohner oder der Empfänger der Rechnung?',
};

const user = {
  label: 'Kollege',
  labelPlural: 'Kollege',
  inputPlaceholder: 'Max Muster',
  helperText: 'Einen Kollegen hier suchen',
  deleteText: 'Den Kollegen sicher aus dem Vorgang löschen?',
  deleteTextNamedFunction: (name) => `Den Kollegen ${name} sicher aus dem Vorgang löschen?`,
  deleteTitle: 'Löschen?',
  noUser: 'Noch kein Kollege',
  tagLabel: 'Rolle',
  tagPlaceholder: 'z.B Arbeiter',
  tagHelperText: 'Welche Rolle soll der Kollege in diesem Vorgang übernehmen?',
};

const appointment = {
  label: 'Termin',
  labelPlural: 'Termine',
  deleteText: 'Den Termin sicher löschen?',
  deleteTextLong: (title = 'Termin', date = 'heute', location = false) =>
    'Den Kalendereintrag ' +
    title +
    ' von ' +
    date +
    (!!location ? ' in ' + location : '') +
    'wirklich löschen?',
  deleteTitle: 'Löschen?',
  noAppointment: 'Noch kein Termin',
  organizerLabel: 'Organisator',
  navigateTo: 'Zum Termin',
};

export const DE = {
  ok: 'Ok',
  copied: 'Kopiert',
  template: 'Template',
  templateSub: 'Fill me!',
  login: 'Anmelden',
  logout: 'Abmelden',
  mistake: 'FEHLER',
  change: 'Ändern',
  hint: 'Anmerkung',
  date: 'Datum',
  close: 'Schließen',
  assign: 'Zuordnen',
  add: 'Hinzufügen',
  accept: 'Annehmen',
  save: 'Speichern',
  send: 'Versenden',
  sign: 'Unterschreiben',
  delete: 'Löschen',
  discard: 'Verwerfen',
  decline: 'Ablehnen',
  lastChange: 'Letzte Änderung',
  abort: 'Abbrechen',
  skip: 'Überspringen',
  success: 'Erfolgreich',
  failure: 'Fehlgeschlagen',
  error: 'Fehler',
  thankYou: 'Vielen dank für Ihr Vertrauen!',
  back: 'Zurück',
  backToLogin: 'Zurück zur Anmeldung',
  backToSearch: 'Zurück zur Suche',
  next: 'Weiter',
  hello: (name) => `Hallo ${name}`,
  edit: 'Anpassen',
  more: 'Mehr anzeigen',
  download: 'Herunterladen',
  info: 'Info',
  inbox: inbox,
  inboxPreview: inboxPreview,
  textModule: 'Textbaustein',
  new: 'Neu',
  agb: 'AGB',
  agbAndAvvHint:
    'hier finden Sie die AGBs und den AV-Vertrag. Diese benötigen wir um Ihnen FLiXWORKER bieten zu können. Sie können die Daten jederzeit in der von Ihnen Unterzeichneten Version hier herunterladen und einsehen.',
  imprint: 'Impressum',
  privacy: 'Datenschutz',
  agbLong: 'Allgemeine Geschäftsbedingungen',
  agbAccept: 'Ich akzeptiere die AGB',
  accountConfirmationLabel: 'Accountbestätigung',
  accountNotYetConfirmedAlert: 'Ihr Account wurde noch nicht bestätigt!',
  accountVerifiedAlert: 'Ihr Account wurde bestätigt!',
  accountNotYetConfirmedText: 'Haben Sie keine E-Mail erhalten?',
  accountVerifyEmailLabel:
    'Bitte bestätigen Sie Ihre E-Mail Adresse - ansonsten wird Ihr Account nach 7 Tagen gesperrt und gelöscht.',
  accountVerifyButtonHint:
    'Mit einem Klick auf den folgenden Button bestätigst du die AGB und den AV Vertrag',
  accountVerifyButtonLabel: 'Ich Akzeptiere die AGB und den AV Vertrag',
  accountNotVerifiedHint:
    'Sie müssen die AGB und den AV-Vertrag akzeptieren und mit einem Klick unten bestätigen!',
  accountVerifyEmailResendButton: 'Bestätigungsmail erneut senden!',
  accountVerifyEmailWasResent: 'Bestätigungsmail wurde versandt!',
  accountVerifyEmailTooOld:
    'Der Bestätigungslink ist leider abgelaufen. Wir haben Ihnen soeben einen neuen Link zugesandt.',
  accountVerifyText: (firstname, lastname, email) =>
    `Ich, ${firstname} ${lastname} (${email}), habe die AGB und den AV Vertrag gelesen und bin mir über die Notwendigkeit der Datenspeicherung und Cookies im klaren.`,
  accountAlreadyConfirmed: (date) =>
    `Sie haben die AGB und den AV Vertrag ${date} akzeptiert. Ihr Account ist bereits bestätigt!`,
  agbHintEmailSent:
    'Bekommen Sie von uns während der Registrierung zugeschickt. Dabei müssen diese bestätigt werden.',
  my: 'Meine',
  upcoming: 'Nächste',
  all: 'Alle',
  choose: 'Auswählen',
  register: {
    button: 'Registrieren',
    label: 'Noch kein Zugang? Registrieren!',
    agbHint:
      'Mit Ihrer Anmeldung bestätigen Sie, dass Sie unsere Nutzungsbedingungen und Datenschutzbestimmungen gelesen und akzeptiert haben.',
  },

  passwordReset: {
    label: 'Passwort vergessen?',
    line1: 'Sie können sich nicht anmelden?',
    line2: 'Kein Problem!',
    line3: 'Wir senden Ihnen eine E-Mail mit einem Widerherstellungslink!',
    requestResetButton: 'Widerherstellungslink anfordern!',
    mailSent: 'Ihr Widerherstellungslink wurde versandt! - Bitte schauen Sie in Ihr Postfach',
    confirmDataLabel: 'Bitte bestätigen Sie hier Ihre Angaben.',
    button: 'Passwort zurücksetzen',
  },
  timetracking: { ...timetracking },
  appointment: {
    ...appointment,
    labelHelper: (count) =>
      count > 1
        ? appointment.labelPlural
        : count > 0
          ? appointment.label
          : appointment.noAppointment,
  },
  contact: {
    ...contact,
    labelHelper: (count) =>
      count > 1 ? contact.labelPlural : count > 0 ? contact.label : contact.noContact,
  },
  address: {
    ...address,
    labelHelper: (count) =>
      count > 1 ? address.labelPlural : count > 0 ? address.label : address.noAddress,
  },
  settings: {
    settingsLabel: 'Einstellungen',
  },
  procedure: {
    ...inbox,
    ...procedure,
  },
  job: {
    ...inbox,
    ...job,
  },
  list: {
    ...list,
  },
  notifications: notifications,
  workorder: workorder,
  performanceReport: {
    ...performanceReport,
    labelHelper: (count) =>
      count > 1
        ? performanceReport.labelPlural
        : count > 0
          ? performanceReport.label
          : performanceReport.noReport,
  },
  user: {
    ...user,
    labelHelper: (count) => (count > 1 ? user.labelPlural : count > 0 ? user.label : user.noUser),
  },
  file: {
    ...file,
    labelHelper: (count) => (count > 1 ? file.labelPlural : count > 0 ? file.label : file.noFile),
  },
  note: {
    ...note,
    labelHelper: (count) => (count > 1 ? note.labelPlural : count > 0 ? note.label : note.noFile),
  },
  chatform: {
    to: {
      label: 'An',
      placeholder: '',
      helperText: 'An wen soll die Nachricht gehen',
      suggestions: 'Vorschläge',
      noneFound: 'Kein mit Connect verbundener Kontakt gefunden ...',
      addNew: 'Als Empfänger hinzufügen',
    },
  },
  mailform: {
    to: {
      label: 'An',
      placeholder: '',
      helperText: 'An wen soll die Nachricht gehen',
      suggestions: 'Vorschläge',
      noneFound: 'Kein bekannter Kontakt oder keine korrekte E-Mail Adresse gefunden ...',
      addNew: 'Als Empfänger hinzufügen',
    },
    cc: {
      label: 'CC',
    },
    bcc: {
      label: 'BCC',
    },
    from: {
      label: 'Von',
      placeholder: '',
      helperText: '',
    },
    subject: {
      label: 'Betreff',
      placeholder: '',
      helperText: '',
    },
    message: {
      label: 'Nachricht',
      placeholder: '',
      helperText: '',
    },
    signature: {
      label: 'Diese Signatur wird automatisch an das Ende Ihrer Nachricht angefügt.',
      greeting: 'Mit freundlichen Grüßen,',
      expandLabel: '(Klicken um mehr zu sehen)',
    },
    attachment: {
      label: 'Anhang',
      labelPlural: 'Anhänge',
      attachFilesButton: 'Anhang hinzufügen',
      expandedAttachFiles: 'Zuklappen',
      placeholder: '',
      helperText: 'Dateien auswählen oder hochladen',
      helperTextBig: 'Dateien auswählen, hier ablegen oder hier klicken',
      uploadingLabel: (count = 1) =>
        `Hochladen von ${count > 1 ? count : 'einer'} Datei${count > 1 ? 'en' : ''} ...`,
      labelHelper: (count = 0) =>
        count > 1 ? `Anhänge (${count} Dateien)` : count > 0 ? 'Ein Anhang' : 'Noch kein Anhang...',
    },
    send: {
      label: 'Absenden',
      placeholder: '',
      helperText: '',
    },
    alerts: {
      couldNotBeSent: 'Die Mail konnte nicht versandt werden...',
    },
  },
  loginForm: {
    errorCredentials: 'Das Passwort oder die E-Mail stimmt nicht!',
    errorRetryCount: (count) => `Server antwortet nicht.(Versuch: ${count})`,
    errorRetryCountFailed: (count) =>
      `Login Abgebrochen nach dem ${count}. Versuch - bitte versuchen Sie es später nocheinmal!`,
  },
  form: {
    subject: {
      label: 'Betreff',
      placeholder: '',
      helperText: '',
    },
    description: {
      label: 'Beschreibung',
      placeholder: '',
      helperText: '',
    },
    email: {
      label: 'E-Mail',
      placeholder: 'max.muster@mail.de',
      helperText: 'E-Mail Adresse',
      validation: {
        wrongFormat: 'E-Mail Adresse fehlerhaft',
      },
    },
    phone: {
      label: 'Telefon',
      placeholder: '0049761 1234567',
      helperText: 'Telefonnummer',
      validation: {
        wrongFormat: 'Telefonnummer fehlerhaft',
      },
    },
    password: {
      label: 'Passwort',
      placeholder: 'S!ch3rEsKeNnw0rT',
      helperText:
        'Das Passwort muss aus mindestens 8 Zeichen (!$#%...), Zahlen, Buchstaben bestehen',
      validation: {
        missingBigLetter: 'Es fehlt mindestens 1 Großbuchstabe (ABC...)',
        missingSmallLetter: 'Es fehlt mindestens 1 Kleinbuchstabe (abc...)',
        missingNumber: 'Es fehlt mindestens 1 Zahl (123...)',
        missingSpecialChar: 'Es fehlt mindestens 1 Sonderzeichen (!$#%...)',
        tooShort: (count) => `Das Passwort ist zu kurz, bitte noch mindestens ${8 - count} Zeichen`,
        criteriaMet: 'Sehr gut!',
      },
    },
    landline: {
      label: 'Festnetz',
      placeholder: '0049761 1234567',
      helperText: 'Festnetz Telefonnummer',
    },
    mobile: {
      label: 'Mobilfunknummer',
      placeholder: '0049171 98765432',
      helperText: 'Mobilfunknummer',
    },
    fax: {
      label: 'Fax',
      placeholder: '0049761 567987',
      helperText: '',
    },
    salutation: {
      label: 'Anrede',
      placeholder: 'Frau',
      helperText: 'Anrede',
    },
    firstname: {
      label: 'Vorname',
      placeholder: 'Manuela',
      helperText: 'Vorname',
    },
    lastname: {
      label: 'Nachname',
      placeholder: 'Muster',
      helperText: 'Nachname',
    },
    companyname: {
      label: 'Firmenname',
      placeholder: 'Muster AG',
      helperText: 'Firmenname',
    },
    gender: {
      label: 'Anrede',
      placeholder: 'Fr.',
      helperText: 'Anrede',
    },
    title: {
      label: 'Titel',
      placeholder: 'Dr.',
      helperText: 'Namenstitel',
    },
  },
  statistic: {
    alertTitle: 'Zu wenig Platz!',
  },
  search: {
    label: 'Suche',
    addSearchToMenuLabel: 'Suche speichen',
    addSearchToMenuTooltip: 'Suche als Menupunkt speichern',
    helperText: 'In den Vorgängen suchen...',
    nothingFoundLabel: 'Nichts gefunden',
    resultsLabel: 'Ergebnisse',
    errors: {
      inputTooShort: (count = 3) => `zum Suchen bitte min. ${count} Zeichen eingeben`,
    },
  },
  system: {
    systemstateLabel: 'Systemstatus',
    stateAllFineLabel: 'Alles OK!',
    systemCleanCacheLabel: 'Cache leeren',
    systemUpdateLabel: 'Aktualisieren',
    systemUpdateText:
      'Es gibt ein Update! Bitte schließen Sie alle Browser-Fenster oder laden diese nach einem Klick auf aktualisieren neu!',
  },
  calendar: {
    calWeekShortLabel: 'KW',
    calWeekLongLabel: 'Kalenderwoche',
    appointmentInformation: {
      participate: 'Teilnehmen',
      maybe: 'Vielleicht',
      cancel: 'Absagen',
      toEvent: 'Zum Vorgang',
    },
    overview: {
      day: 'Tag',
      month: 'Monat',
      week: 'Woche',
      month1: 'Januar',
      month2: 'Februar',
      month3: 'März',
      month4: 'April',
      month5: 'Mai',
      month6: 'Juni',
      month7: 'Juli',
      month8: 'August',
      month9: 'September',
      month10: 'Oktober',
      month11: 'November',
      month12: 'Dezember',
      day1: 'Montag',
      day2: 'Dienstag',
      day3: 'Mittwoch',
      day4: 'Donnerstag',
      day5: 'Freitag',
      day6: 'Sam',
      day7: 'Son',
    },
    display: 'Anzeige',
    reallyDeleteAppointment: 'Den Kalendereintrag sicher löschen?',
    toCalendar: 'Zum Terminkalender',
    targetDate: 'Zieldatum',
    rememberDate: 'Erst wieder erinnern ab...',
    appointmentForm: {
      title: 'Neuer Termin:',
      timeOfDay: 'Uhrzeit',
      until: 'bis',
      possibleTimeWindow: '6 - 22 Uhr',
      isAllDay: 'Ganztägiger Termin?',
      beginDateLabel: 'Beginn am',
      endsDateLabel: 'Endet am',
      helperTextDate: 'Bitte Datum eingeben',
      assignAppointment: 'Kollegen dem Termin zuweisen',
      assignExerternalAttendee: 'Weitere (Externe) Teilnehmer am Termin',
      assignToEvent: 'Zu einem Vorgang/Auftrag hinzufügen',
      infoWhenAssignedToExternalAttendee:
        'Die eingeladenen Teilnehmer erhalten eine Termineinladung und können diesenTermin ebenfalls einsehen, annehmen, ablehnen oder auch eine Terminänderungvorschlagen.',
      errorMessage: {
        somethingWentWrong: 'Da ist etwas schiefgelaufen, bitte erneut senden! ',
        startDateTimeWrong:
          'Das Start-Datum/ die Start-Zeit ist leider nicht korrekt - bitte prüfen! #1',
        endDateTimeWrong: 'Das End-Datum/ die End-Zeit ist leider nicht korrekt - bitte prüfen! #1',
        endDateTimeEarlierThanStart:
          'Das End-Datum/ die End-Zeit ist früher als das Start-Datum/ die Start-Zeit - bitte prüfen!',
        wrongDate: 'Bitte ein korrektes Datum eingeben',
        noDateTime: 'Bitte eine Uhrzeit angeben',
        wrongDateTime: 'Bitte eine korrekte Uhrzeit eingeben',
        wrongDateTimeTooShort: 'Bitte eine spätere Uhrzeit als die Startzeit eingeben',
        wrongDateTimeHour: 'Die Stunde ist nicht korrekt',
        wrongDateTimeMinute: 'Die Minute ist nicht korrekt',
        noDate: 'Bitte Datum eingeben',
      },
    },
  },
  withoutUserFilter: 'Alle',
};
