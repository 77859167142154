import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import ImageIcon from '@material-ui/icons/Image';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Slider from '@material-ui/core/Slider';
import Cropper from 'react-easy-crop';
import { getCroppedImg, getRotatedImage, getResizedImage } from '../../lib/cropImage';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { getOrientation } from 'get-orientation/browser';
import RotateRightIcon from '@material-ui/icons/RotateRight';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  submit: {
    marginTop: theme.spacing(2),
  },
});

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

class UserConfigForm extends React.Component {
  async uploadAvatar(event) {
    this.setState({ isSubmitting: true, hasError: false });
    let formData = new FormData();
    formData.append('_method', 'PUT');
    let blob = false;
    if (!!this.state.croppedImage) {
      blob = await fetch(this.state.croppedImage).then((r) => r.blob());
    } else {
      let croppedImage = await getCroppedImg(this.state.imageSrc, false);
      blob = await fetch(croppedImage).then((r) => r.blob());
    }

    formData.append('avatar', blob, this.state.filename || 'avatar.jpg');

    const response = await fetch(
      process.env.REACT_APP_API_URL + '/api/users/' + this.props.User.uuid,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: formData,
      },
    )
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })

      .catch((error) => {
        this.setState({ isSubmitting: false, hasError: error });
        return false;
      });
    if (!!response.success) {
      this.setState({
        avatar: this.state.croppedImage,
        imageSrc: null,
        isSubmitting: false,
        hasError: false,
      });
      //this.props.dispatch(updateCompany(response))
    } else {
      this.setState({ isSubmitting: false, hasError: response });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      avatar: this.props.User.avatar,
      isSubmitting: false,
      hasError: false,
      validation: false,

      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 1,
      croppedAreaPixels: null,
      croppedImage: null,
      isCropping: false,
      zoomMin: 0.55,
      zoomMax: 3,
      zoomStepping: 0.15,
    };
    this.onCropChange = this.onCropChange.bind(this);
    this.onCropComplete = this.onCropComplete.bind(this);
    this.onZoomChange = this.onZoomChange.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
    this.rotateLeft = this.rotateLeft.bind(this);
    this.rotateRight = this.rotateRight.bind(this);
  }

  setCroppedImage = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(this.state.imageSrc, croppedAreaPixels);
    this.setState({ croppedImage });
  };

  rotateLeft = async () => {
    const rotatedImage = await getRotatedImage(this.state.imageSrc, -90);
    this.setState({ imageSrc: rotatedImage });
  };

  rotateRight = async () => {
    const rotatedImage = await getRotatedImage(this.state.imageSrc, 90);
    this.setState({ imageSrc: rotatedImage });
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setCroppedImage(croppedAreaPixels);
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onZoomOutClick = () => {
    let newZoom = this.state.zoom - this.state.zoomStepping;
    this.setState({
      zoom: newZoom <= this.state.zoomMin ? this.state.zoomMin : newZoom,
    });
  };

  onZoomInClick = () => {
    let newZoom = this.state.zoom + this.state.zoomStepping;
    this.setState({
      zoom: newZoom >= this.state.zoomMax ? this.state.zoomMax : newZoom,
    });
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 8000000) {
        this.setState({
          validation: 'Datei ist zu groß! (' + file.size / (1024 * 1024) + ' MB)',
        });
      } else {
        let imageDataUrl = await readFile(file);

        // apply rotation if needed
        const orientation = await getOrientation(file);
        const rotation = ORIENTATION_TO_ANGLE[orientation];
        if (rotation) {
          imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
        }
        imageDataUrl = await getResizedImage(imageDataUrl);

        this.setState({
          imageSrc: imageDataUrl,
          crop: { x: 0, y: 0 },
          zoom: 1,
          validation: false,
        });
      }
    }
  };

  componentDidMount() {}

  render() {
    return (
      <>
        <Avatar
          style={{
            minHeight: '180px',
            minWidth: '180px',
            width: '100%',
            height: 'auto',
            maxWidth: '400px',
            marginLeft: 'auto',
            marginRight: 'auto',
            padding: !!this.state.avatar ? '0' : '25% 0px',
          }}
          alt={this.props.User.firstname + ' ' + this.props.User.lastname}
          src={this.state.imageSrc === null ? this.state.avatar : null}
        >
          {(
            this.props.User.firstname.slice(0, 1) + this.props.User.lastname.slice(0, 1)
          ).toUpperCase()}
          {this.state.imageSrc && (
            <div>
              <Cropper
                image={this.state.imageSrc}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={1}
                cropShape='round'
                showGrid={false}
                restrictPosition={false}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
          )}
        </Avatar>

        {this.state.isSubmitting ? (
          <center>
            <CircularProgress />
          </center>
        ) : this.props.User && !!!this.state.hasError ? (
          <>
            {this.state.imageSrc && (
              <Grid container spacing={2}>
                <Grid item>
                  <RotateLeftIcon onClick={this.rotateLeft} />
                  <ZoomOutIcon onClick={this.onZoomOutClick} />
                </Grid>
                <Grid item xs>
                  <Slider
                    value={this.state.zoom}
                    min={this.state.zoomMin}
                    max={this.state.zoomMax}
                    step={this.state.zoomStepping}
                    aria-labelledby='Zoom'
                    onChange={(e, zoom) => this.onZoomChange(zoom)}
                  />
                </Grid>
                <Grid item>
                  <ZoomInIcon onClick={this.onZoomInClick} />
                  <RotateRightIcon onClick={this.rotateRight} />
                </Grid>
              </Grid>
            )}

            <Grid component='center' item xs={12}>
              <input
                accept='image/*'
                style={{ display: 'none' }}
                id='contained-button-file'
                multiple
                type='file'
                onChange={this.onFileChange}
              />
              {this.state.imageSrc ? (
                <>
                  <label htmlFor='contained-button-file'>
                    <Button
                      variant='outlined'
                      color='primary'
                      style={{ float: 'left' }}
                      component='span'
                    >
                      <DeleteIcon /> Löschen & Neu wählen
                    </Button>
                  </label>
                  <Button
                    onClick={this.uploadAvatar}
                    variant='outlined'
                    color='primary'
                    style={{ float: 'right' }}
                    component='span'
                  >
                    <SaveIcon /> Speichern
                  </Button>
                </>
              ) : (
                <label htmlFor='contained-button-file'>
                  <Button variant='outlined' color='primary' component='span'>
                    <ImageIcon /> Neuen Avatar hochladen
                  </Button>
                </label>
              )}
            </Grid>

            <Grid component='center' item xs={12}>
              {!!this.state.validation && (
                <Typography variant='button' color='error'>
                  {' '}
                  {this.state.validation}{' '}
                </Typography>
              )}
              <Typography variant='caption' color={!!this.state.validation ? 'error' : 'inherit'}>
                {' '}
                Erlaubt: jpeg,png,jpg,gif | Max: 8MB{' '}
              </Typography>
            </Grid>
          </>
        ) : (
          <>
            {this.state.hasError?.errors?.map((value) => (
              <Alert severity='error'>{value}</Alert>
            ))}
          </>
        )}
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(UserConfigForm);
