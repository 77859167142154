/*
 * action types
 */
export const THEME = 'FETCH_THEME';
export const LOGO = 'FETCH_LOGO';
export const RESET = 'RESET_STYLE';
/*
 * action creators
 */
export const getTheme = (theme) => {
  return { type: THEME, theme };
};

export const getLogo = (logo) => {
  return { type: LOGO, logo };
};

export const setStyling = (config) => {
  return function (dispatch) {
    dispatch(getTheme(config.theme));
    dispatch(getLogo(config.logo));
  };
};

export const reset = () => {
  return { type: RESET };
};
