import { FETCH, RESET } from '../actions/Dimensions';

//const initialState = { uuid: null, last_name: null, first_name: null }
const initialState = null;

/*
const checkVersion = function () {
    var agent = window.navigator.userAgent,
    start = agent.indexOf( 'OS ' );
    if( ( agent.indexOf( 'iPhone' ) > -1 || agent.indexOf( 'iPad' ) > -1 ) && start > -1 ){
        return window.Number( agent.substr( start + 3, 3 ).replace( '_', '.' ) );
    }
    return 0;
}
*/

const Dimensions = (state = initialState, action) => {
  switch (action.type) {
    case FETCH:
      let tmp = {};
      if (!!action.config?.dimensions) {
        tmp.height = !!action.config.dimensions.height
          ? action.config.dimensions.height
          : state.height;
        tmp.width = !!action.config.dimensions.width ? action.config.dimensions.width : state.width;
        tmp.maxWidth = !!action.config.dimensions.maxWidth
          ? action.config.dimensions.maxWidth
          : state.maxWidth;
        tmp.appBarHeight = !!action.config.dimensions.appBarHeight
          ? action.config.dimensions.appBarHeight
          : state.appBarHeight;
        tmp.drawerWidth = !!action.config.dimensions.drawerWidth
          ? action.config.dimensions.drawerWidth
          : state.drawerWidth;
        tmp.drawerWidthClosed = !!action.config.dimensions.drawerWidthClosed
          ? action.config.dimensions.drawerWidthClosed
          : state.drawerWidthClosed;
        tmp.drawerWidthOpen = !!action.config.dimensions.drawerWidthOpen
          ? action.config.dimensions.drawerWidthOpen
          : state.drawerWidthOpen;
      } else {
        tmp = state;
      }
      //console.log("[REDUCER] NEW - height: " + tmp.height  + ', width: ' + tmp.width  + ', maxWidth: ' + tmp.maxWidth);
      return tmp;
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default Dimensions;
