import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
});

class EMailConfigForm extends React.Component {
  async handleFormSubmit(event) {
    event.preventDefault();
    this.setState({
      form: { ...this.state.form, isSubmitting: true, hasError: false },
    });
    let formData = {};
    Object.keys(this.state.form).forEach((value, index) => {
      if (
        typeof this.state.form[value] === 'string' ||
        typeof this.state.form[value] === 'boolean'
      ) {
        formData[value] = this.state.form[value];
      }
    });
    if (
      formData.uuid === 'primary' ||
      formData.uuid === 'additionalReciever' ||
      formData.uuid === 'additionalSender' ||
      formData.uuid === 'additionalSenderAndReciever'
    ) {
      delete formData.uuid;
    }
    const endpoint =
      process.env.REACT_APP_API_URL +
      '/api/mailaccounts/' +
      formData.accountType +
      (!!formData.uuid ? '/' + formData.uuid : '');
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      });

    if (!!response) {
      this.setState({
        form: { ...this.state.form, isSubmitting: false, hasError: false },
      });
    } else {
      this.setState({
        form: { ...this.state.form, isSubmitting: false, hasError: true },
      });
    }
  }

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (Typ: ${event.target.type}) : ${event.target.value} (checked: ${event.target.checked})`);
    if (event.target.type === 'checkbox') {
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.checked },
      });
    } else {
      this.setState({
        form: { ...this.state.form, [event.target.name]: event.target.value },
      });
    }

    if (event.target.name === 'accountType') {
      const array = event.target.value.split('_');
      if (!!this.state.AccountList[array[1]].data) {
        let tmp = this.state.AccountList[array[1]].data;
        if (array[1].indexOf('-') > -1) {
          //console.log("Has uuid", array[1])
        } else {
          tmp.uuid = 'primary';
        }
        this.setState({ form: this.state.AccountList[array[1]].data });
        //	CacheableRequests.customer.mail.getConfig(authState.token, setStateEmail, stateEmail, {'type': array[1], 'uuid': array[2]});
      } else {
        this.setState({
          form: {
            ...this.state.form,
            accountType: array[0],
            uuid: array[1],
            displayName: '',
            email: '',
            imapServer: '',
            imapPort: '',
            imapSecurity: 'none',
            imapAllowUnsecure: false,
            imapUser: '',
            imapPassword: '',
            smtpServer: '',
            smtpPort: '',
            smtpSecurity: 'none',
            smtpAllowUnsecure: false,
            smtpUser: '',
            smtpPassword: '',
          },
        });
      }

      switch (array[0]) {
        case 'primary':
          //CacheableRequests.customer.mail.getConfig(authState.token, setStateEmail, stateEmail, {});
          this.setState({ display: { showImap: true, showSmtp: true } });
          break;
        case 'additionalReciever':
          this.setState({ display: { showImap: true, showSmtp: false } });
          break;
        case 'additionalSender':
          this.setState({ display: { showImap: false, showSmtp: true } });
          break;
        case 'additionalSenderAndReciever':
          this.setState({ display: { showImap: true, showSmtp: true } });
          break;
        default:
          this.setState({ display: { showImap: true, showSmtp: true } });
          break;
      }
    }
  };

  renderList(list) {
    list.default = {
      type: 'default',
      displayName: 'Bitte auswählen...',
    };

    return Object.keys(list).map((key) => {
      return (
        <MenuItem
          disabled={key === 'default'}
          hidden={key === 'default'}
          key={key}
          value={this.state.AccountList[key].type + '_' + key}
        >
          {this.state.AccountList[key].displayName}
        </MenuItem>
      );
    });
  }

  constructor() {
    super();

    const initialDisplayState = {
      showImap: false,
      showSmtp: false,
    };
    const initialStateEmailList = {
      primary: { type: 'primary', displayName: 'Primärkonto' },
      additionalReciever: {
        type: 'additionalReciever',
        displayName: 'Zusätzliches Empfangskonto',
      },
    };

    const initialEmailState = {
      isSubmitting: false,
      hasError: false,
      accountType: 'default',
      uuid: 'default',
      displayName: '',
      email: '',
      imapServer: '',
      imapPort: '',
      imapSecurity: 'none',
      imapAllowUnsecure: false,
      imapUser: '',
      imapPassword: '',
      smtpServer: '',
      smtpPort: '',
      smtpSecurity: 'none',
      smtpAllowUnsecure: false,
      smtpUser: '',
      smtpPassword: '',
    };

    this.state = {
      form: initialEmailState,
      display: initialDisplayState,
      AccountList: initialStateEmailList,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    fetch(process.env.REACT_APP_API_URL + '/api/mailaccounts/list', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = this.state.AccountList;
        for (var key in json) {
          if (json[key].accountType === 'primary') {
            tmp.primary = {
              type: json[key].accountType,
              displayName: json[key].extendedName,
              name: json[key].displayName,
              data: json[key],
            };
          } else {
            tmp[json[key].uuid] = {
              type: json[key].accountType,
              displayName: json[key].extendedName,
              name: json[key].displayName,
              data: json[key],
            };
          }
        }
        this.setState({ AccountList: tmp });
      })
      .catch((error) => {
        return false;
      });
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Paper className={classes.root} square>
          {this.state.isFetching ||
          this.state.form.isSubmitting ||
          (this.state.isFetching && this.state.form.isSubmitting) ? (
            <center>
              <CircularProgress />
            </center>
          ) : (
            <form key='mailform' onSubmit={this.handleFormSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    select
                    id='accountType'
                    name='accountType'
                    label='Art des Accounts'
                    onChange={this.handleChange()}
                    value={
                      this.state.form.accountType +
                      '_' +
                      (this.state.form.uuid ?? this.state.form.accountType)
                    }
                    helperText='Welchen Zweck soll dieser Account haben?'
                  >
                    {this.renderList(this.state.AccountList)}
                  </TextField>
                </Grid>
              </Grid>
              {this.state['form-accountType'] === '' ? (
                <div className={classes.root}>
                  <center>Bitte zunächst auswählen ...</center>
                </div>
              ) : (
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <TextField
                      fullWidth
                      required
                      id='displayName'
                      name='displayName'
                      label='Alias/Anzeigename'
                      type='text'
                      onChange={this.handleChange()}
                      value={this.state.form.displayName}
                      autoComplete='email'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      required
                      id='email'
                      name='email'
                      label='E-Mail'
                      type='email'
                      autoComplete='email'
                      onChange={this.handleChange()}
                      value={this.state.form.email}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {/* SPACER */}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper style={{ padding: '0em 1em 1em 1em' }}>
                      <Typography
                        style={{ width: '100%', display: 'flex' }}
                        variant='h6'
                        gutterBottom
                      >
                        IMAP Konfiguration
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required={this.state.display.showImap}
                            disabled={!this.state.display.showImap}
                            id='imapServer'
                            name='imapServer'
                            label='IMAP-Server'
                            type='text'
                            onChange={this.handleChange()}
                            value={this.state.form.imapServer}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required={this.state.display.showImap}
                            disabled={!this.state.display.showImap}
                            id='imapPort'
                            name='imapPort'
                            label='IMAP-Port'
                            type='number'
                            onChange={this.handleChange()}
                            value={this.state.form.imapPort}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            required={this.state.display.showImap}
                            disabled={!this.state.display.showImap}
                            select
                            id='imapSecurity'
                            name='imapSecurity'
                            label='Verschlüsselung'
                            onChange={this.handleChange()}
                            value={this.state.form.imapSecurity}
                            helperText='Welches Protokoll wird zur Verschlüsselung eingesetzt?'
                          >
                            <MenuItem key='ssl' value='ssl'>
                              {' '}
                              SSL/TLS{' '}
                            </MenuItem>
                            <MenuItem key='tls' value='tls'>
                              {' '}
                              STARTLS{' '}
                            </MenuItem>
                            <MenuItem key='none' value='none'>
                              {' '}
                              Keine Verschlüsselung{' '}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<Lock />}
                                checkedIcon={<LockOpen />}
                                value={this.state.form.imapAllowUnsecure}
                                onChange={this.handleChange()}
                                disabled={!this.state.display.showImap}
                                checked={this.state.form.imapAllowUnsecure}
                              />
                            }
                            id='imapAllowUnsecure'
                            name='imapAllowUnsecure'
                            label='Erlaube unsichere Veschlüsselung?'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required={this.state.display.showImap}
                            disabled={!this.state.display.showImap}
                            id='imapUser'
                            name='imapUser'
                            label='IMAP-User'
                            type='text'
                            onChange={this.handleChange()}
                            value={this.state.form.imapUser}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            disabled={!this.state.display.showImap}
                            type={this.state.display.showImapPassword ? 'text' : 'password'}
                            label='Passwort'
                            value={this.state.form.imapPassword ?? ''}
                            onChange={this.handleChange()}
                            name='imapPassword'
                            helperText={
                              this.state.form.uuid !== ''
                                ? 'Dass Passwort bleibt gleich, sofern hier nichts eingetragen wird'
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Paper style={{ padding: '0em 1em 1em 1em' }}>
                      <Typography
                        style={{ width: '100%', display: 'flex' }}
                        variant='h6'
                        gutterBottom
                      >
                        SMTP Konfiguration
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required={this.state.display.showSmtp}
                            disabled={!this.state.display.showSmtp}
                            id='smtpServer'
                            name='smtpServer'
                            label='SMTP-Server'
                            type='text'
                            onChange={this.handleChange()}
                            value={this.state.form.smtpServer}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required={this.state.display.showSmtp}
                            disabled={!this.state.display.showSmtp}
                            id='smtpPort'
                            name='smtpPort'
                            label='SMTP-Port'
                            type='number'
                            onChange={this.handleChange()}
                            value={this.state.form.smtpPort}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            required={this.state.display.showSmtp}
                            disabled={!this.state.display.showSmtp}
                            select
                            id='smtpSecurity'
                            name='smtpSecurity'
                            label='Verschlüsselung'
                            onChange={this.handleChange()}
                            value={this.state.form.smtpSecurity}
                            helperText='Welches Protokoll wird zur Verschlüsselung eingesetzt?'
                          >
                            <MenuItem key='ssl' value='ssl'>
                              {' '}
                              SSL/TLS{' '}
                            </MenuItem>
                            <MenuItem key='tls' value='tls'>
                              {' '}
                              STARTLS{' '}
                            </MenuItem>
                            <MenuItem key='none' value='none'>
                              {' '}
                              Keine Verschlüsselung{' '}
                            </MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                icon={<Lock />}
                                checkedIcon={<LockOpen />}
                                value={this.state.form.smtpAllowUnsecure}
                                onChange={this.handleChange()}
                                disabled={!this.state.display.showSmtp}
                                checked={this.state.form.smtpAllowUnsecure}
                              />
                            }
                            id='smtpAllowUnsecure'
                            name='smtpAllowUnsecure'
                            label='Erlaube unsichere Veschlüsselung?'
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            required={this.state.display.showSmtp}
                            disabled={!this.state.display.showSmtp}
                            id='smtpUser'
                            name='smtpUser'
                            label='SMTP-User'
                            type='text'
                            onChange={this.handleChange()}
                            value={this.state.form.smtpUser}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            disabled={!this.state.display.showSmtp}
                            type={this.state.display.showSmtpPassword ? 'text' : 'password'}
                            label='Passwort'
                            value={this.state.form.smtpPassword ?? ''}
                            onChange={this.handleChange()}
                            name='smtpPassword'
                            helperText={
                              this.state.form.uuid !== ''
                                ? 'Dass Passwort bleibt gleich, sofern hier nichts eingetragen wird'
                                : ''
                            }
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.wrapper}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        disabled={this.state.isSubmitting}
                        fullWidth
                      >
                        Speichern
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              )}
            </form>
          )}
        </Paper>
      </>
    );
  }
}

// Meh
const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
});
const mapDispatchToProps = (dispatch) => ({ dispatch, push });
export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(EMailConfigForm);
