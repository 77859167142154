import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { get as authFetch, dataCollect } from '../../actions/Authentication';
//import { fetch as userFetch} from '../../actions/User';
//import { reset as StyleReset } from '../../actions//Style';
import Authentication from '../../lib/Authentication';
//import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress';
//import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import IconButton from '@material-ui/core/IconButton';
//import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { compose } from 'react-recompose';
import Alert from '@material-ui/lab/Alert';
//import AlertTitle from '@material-ui/lab/AlertTitle';
import Card from '@material-ui/core/Card';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { DE as TextDE } from '../../lib/Text';
import FooterHint from '../Other/FooterHint';
//import { ThemeProvider } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  card: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      marginTop: '15vh',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    display: 'inline-flex',
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  mt: {
    marginTop: theme.spacing(1),
  },
  NavLinkItem: {
    width: '33%',
    padding: theme.spacing(3),
  },
  alert: {
    width: '100%',
  },
  size: {},
  popup: {
    position: 'absolute',
    zIndex: 2000,
    top: '10vh',
    left: '3vw',
    width: '94vw',
    color: theme.palette.success.contrastText,
    backgroundColor: theme.palette.success.light,

    [theme.breakpoints.up('lg')]: {
      fontSize: '120%',
      top: '10vh',
      left: '50vw',
      transform: 'translate(-50%, 0)',
      width: 'auto',
    },
    '&>div': {
      padding: theme.spacing(1),
      '&>svg': {
        fontSize: '140%',
      },
      fontSize: '140%',
    },
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.8)',
    position: 'fixed',
    top: '0',
    left: '0',
    zIndex: 1000,
    width: '100vw',
    height: '100vh',
  },
});

class Register extends React.Component {
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handlePasswordChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    //let re = /^.*(?=.{3,})(?=.*[a-zA-ZäöüÄÖÜ])(?=.*[0-9])(?=.*[\d\X])(?=.*[!$#%]).*$/
    let reCaps = /[A-ZÄÖÜ]/;
    let reSmall = /[a-zäöü]/;
    let reDigit = /[0-9]/;
    let reCode = /[#?!@$%^&*-]/;
    let reFull = /^(?=.*?[A-ZÄÖÜ])(?=.*?[a-zäöü])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;

    let validated = false;
    let validationMessage = [];
    if (!event.target.value.match(reCaps)) {
      validationMessage.push(TextDE.form.password.validation.missingBigLetter);
    }
    if (!event.target.value.match(reSmall)) {
      validationMessage.push(TextDE.form.password.validation.missingSmallLetter);
    }
    if (!event.target.value.match(reDigit)) {
      validationMessage.push(TextDE.form.password.validation.missingNumber);
    }
    if (!event.target.value.match(reCode)) {
      validationMessage.push(TextDE.form.password.validation.missingSpecialChar);
    }
    if (event.target.value.length < 8) {
      validationMessage.push(TextDE.form.password.validation.tooShort(event.target.value.length));
    }
    if (event.target.value.match(reFull)) {
      validated = true;
    }
    this.setState({
      validationMessage: validationMessage,
      validation: validated,
      [event.target.name]: event.target.value,
    });
  };

  handleDelete = () => {
    this.setState({ error: false });
  };

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ loading: true });
    if (
      !this.state.email.trim() ||
      !this.state.password.trim() ||
      !this.state.firstname.trim() ||
      !this.state.lastname.trim()
    ) {
      return;
    }

    let formData = new FormData();
    formData.append('email', this.state.email);
    formData.append('password', this.state.password);
    formData.append('password_confirmation', this.state.password);
    formData.append('firstname', this.state.firstname);
    formData.append('lastname', this.state.lastname);
    /*
		let body = { 
				"email": this.state.email,
				"password": this.state.password,
				"password_confirmation": this.state.password,
				"firstname": this.state.firstname,
				"lastname": this.state.lastname,
			};
		let headers = {"Content-Type": "application/json","Accept": "application/json"}
		*/
    let response = await fetch(process.env.REACT_APP_API_URL + '/api/register', {
      method: 'POST',
      //headers: headers,
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => response)
      .catch((error) => error);

    if (!!response.errors) {
      this.setState({ loading: false, error: response.errors.join(' | ') });
      return false;
    } else if (typeof response !== 'object') {
      this.setState({ loading: false, error: response });
      return false;
    }

    formData = {
      username: this.state.email,
      password: this.state.password,
      grant_type: 'password',
      client_id: Authentication.client.id,
      client_secret: Authentication.client.secret,
      scope: '',
    };
    const token = await fetch(process.env.REACT_APP_API_URL + '/oauth/token', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        }
        return response.json();
        //throw Error(response.statusText);
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    if (typeof token.error !== 'undefined') {
      this.setState({ loading: false, error: token.message });
      return token;
    }

    const loginFormData = new FormData();
    loginFormData.append('email', formData.username);
    loginFormData.append('password', formData.password);
    //const session =
    await fetch(process.env.REACT_APP_API_URL + '/auth', {
      method: 'POST',
      body: loginFormData,
      credentials: 'include',
    })
      .then(function (response) {
        //response.headers.forEach((val, key) => { console.log(key, val) })
        if (response.ok) {
          return response.json();
        }
        return response.json();
        //throw Error(response.statusText);
      })
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });

    this.props.dispatch(authFetch(token));
    this.props.dispatch(dataCollect(token));

    this.setState({ loading: false });
    this.props.dispatch(push('/home'));
  }

  constructor() {
    super();
    this.state = {
      loading: false,
      error: false,
      validationMessage: [],
      validation: false,
      email: '',
      password: '',
      firstname: '',
      lastname: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <div className={classes.paper}>
            <Card className={classes.card} variant='outlined'>
              <div
                style={{
                  backgroundImage: 'url(/assets/hw+hv.png)',
                  width: '100%',
                  minHeight: '20vh',
                  height: 'auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  marginBottom: '1em',
                }}
              ></div>
              <div
                style={{
                  backgroundImage: 'url(/assets/logo_default_fitted_medium.png)',
                  width: '100%',
                  minHeight: '60px',
                  height: 'auto',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                }}
              ></div>
              {this.state.loading ? (
                <Box my={4}>
                  {' '}
                  <CircularProgress />{' '}
                </Box>
              ) : (
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete='fname'
                        name='firstname'
                        value={this.state.firstname}
                        onChange={this.handleChange}
                        variant='outlined'
                        required
                        fullWidth
                        id='firstname'
                        label={TextDE.form.firstname.label}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant='outlined'
                        required
                        fullWidth
                        value={this.state.lastname}
                        onChange={this.handleChange}
                        id='lastname'
                        label={TextDE.form.lastname.label}
                        name='lastname'
                        autoComplete='lname'
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant='outlined'
                        required
                        fullWidth
                        type='email'
                        id='email'
                        label={TextDE.form.email.label}
                        name='email'
                        autoComplete='email'
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </Grid>
                    {this.state.validation === false && this.state.validationMessage.length > 0 ? (
                      <Grid item zeroMinWidth xs={12}>
                        {this.state.validationMessage.map((value, index) => (
                          <Alert key={index} severity='error' closeText='Schließen'>
                            {' '}
                            {value}{' '}
                          </Alert>
                        ))}
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        required
                        error={this.state.validation === false ? true : false}
                      >
                        <InputLabel htmlFor='outlined-adornment-password'>
                          {TextDE.form.password.label}
                        </InputLabel>
                        <OutlinedInput
                          name='password'
                          id='password'
                          required
                          autoComplete='new-password'
                          type={!!this.state.passwordVisible ? 'text' : 'password'}
                          onChange={this.handlePasswordChange()}
                          value={this.state.password}
                          endAdornment={
                            <InputAdornment position='end'>
                              <IconButton
                                aria-label='toggle password visibility'
                                onClick={() =>
                                  this.setState({
                                    passwordVisible: !this.state.passwordVisible,
                                  })
                                }
                                edge='end'
                              >
                                {!!this.state.passwordVisible ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                        <FormHelperText component='div'>
                          <Typography align='center' display='block' color='primary'>
                            {this.state.validation
                              ? TextDE.form.password.validation.criteriaMet
                              : TextDE.form.password.helperText}
                          </Typography>
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    {!!this.state.error && (
                      <Grid item xs={12}>
                        <Alert className={classes.alert} severity='error'>
                          {this.state.error}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Typography
                        align='center'
                        color='textPrimary'
                        variant='subtitle2'
                        gutterBottom={false}
                      >
                        {TextDE.register.agbHint}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type='submit'
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        endIcon={<VpnKeyIcon />}
                        disabled={
                          !(
                            this.state.firstname.length > 0 &&
                            this.state.lastname.length > 0 &&
                            this.state.email.length > 0 &&
                            this.state.password.length > 0
                          )
                        }
                      >
                        {TextDE.register.button}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
              <Grid
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                className={classes.my}
                spacing={2}
              >
                <Grid item xs={12}>
                  <Button
                    href='/login'
                    variant='text'
                    color='primary'
                    size='small'
                    style={{ textAlign: 'center' }}
                  >
                    {TextDE.backToLogin}
                  </Button>
                </Grid>
              </Grid>

              <FooterHint />
            </Card>
          </div>
        </Container>
      </>
    );
  }
}
//export default Login;

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
});
//export default connect(mapStateToProps)(Login);
export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    //, mapDispatchToProps
  ),
)(Register);
