import { DISPLAY, RESET } from '../actions/NewVersion';

//const initialState = { uuid: null, last_name: null, first_name: null }
const initialState = { display: false };

const NewVersion = (state = initialState, action) => {
  switch (action.type) {
    case DISPLAY:
      return { display: true };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default NewVersion;
