import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  CircularProgress,
  Grid,
  Button,
} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DE as TextDE } from '../../../lib/Text';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Badge from '@material-ui/core/Badge';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

const styles = (theme) => ({
  size: {
    maxWidth: 'calc( 100% - 16px)',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
    maxWidth: 'calc( 100% - 96px)',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&>svg': {
      height: '87%',
      width: '87%',
    },
  },
  action: {
    marginTop: 'inherit',
    marginRight: '18px',
    flex: '0 0 auto',
    alignSelf: 'flex-start',
  },
  chartContainer: {
    margin: 'auto',
    paddingBottom: '12px',
  },
  alignment: {
    justifyContent: 'flex-start',
    height: '100%',
  },
  alignmentIcon: {
    marginLeft: 'auto',
  },
});

class ProceduresSimpleView extends React.Component {
  constructor(props) {
    super(props);
    this.redirectTo = this.redirectTo.bind(this);
  }
  redirectTo(data) {
    //console.log(data);
    let state =
      !!data[0] && !!data[0]._view?.label ? data[0]._view?.label : !!data?.text ? data.text : false;
    if (typeof state === 'string') {
      let url = '/nachrichten/alle?state=' + encodeURI(state);
      if (this.props.currentFetchType !== 'all') {
        url = url + '&user=' + this.props.User.uuid;
      }
      this.props.dispatch(push(url));
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <Card
        style={{
          maxHeight:
            this.props.Dimensions.height -
            this.props.Dimensions.appBarHeight -
            (this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md ? 20 : 8),
          minHeight:
            this.props.Dimensions.height -
            this.props.Dimensions.appBarHeight -
            (this.props.Dimensions.maxWidth > this.props.Theme.breakpoints.values.md ? 20 : 8),
          display: 'flex',
          flexFlow: 'column',
        }}
      >
        <CardHeader
          ref={this.headRef}
          classes={{ action: classes.action, content: classes.content }}
          titleTypographyProps={{
            variant: 'h5',
            component: 'p',
            noWrap: true,
            className: classes.size,
          }}
          title={
            (this.props.currentFetchType !== 'all' ? TextDE.my : TextDE.all) +
            ' ' +
            TextDE.procedure.labelPlural
          }
          action={
            <Badge
              badgeContent={this.props.count}
              overlap='rectangular'
              max={9999999}
              color='primary'
            >
              <ToggleButtonGroup
                value={this.props.currentFetchType}
                exclusive
                onChange={this.props.toggleProcedureFetchType}
                aria-label='switch all or my procedures'
              >
                <ToggleButton value='mine'>
                  <AccountTreeIcon color='inherit' /> &nbsp; {TextDE.my}
                </ToggleButton>
                <ToggleButton value='all'>
                  {TextDE.all} &nbsp; <AllInclusiveIcon color='inherit' />
                </ToggleButton>
              </ToggleButtonGroup>
            </Badge>
          }
          avatar={
            <Avatar className={classes.avatar}>
              {this.props.currentFetchType === 'all' ? (
                <AllInclusiveIcon color='inherit' />
              ) : (
                <AccountTreeIcon color='inherit' />
              )}
            </Avatar>
          }
        />
        {!!this.props.isFetching ? (
          <CardContent className={classes.content}>
            <CircularProgress />
          </CardContent>
        ) : (
          <CardContent style={{ flexGrow: '1', display: 'flex' }}>
            <Grid
              container
              spacing={2}
              justifyContent={
                this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.lg
                  ? 'center'
                  : 'space-between'
              }
              alignItems='stretch'
              style={{ flexGrow: 1 }}
            >
              {this.props.data.labels.map((label, index) => {
                return (
                  <Grid key={index} item xs={12}>
                    <Button
                      onClick={(elems) => this.redirectTo({ text: label })}
                      className={classes.alignment}
                      classes={{ endIcon: classes.alignmentIcon }}
                      size='small'
                      fullWidth
                      variant='contained'
                      color='default'
                      startIcon={
                        <Avatar
                          variant='rounded'
                          className={classes.avatar}
                          style={{
                            backgroundColor: this.props.data.datasets[0].backgroundColor[index],
                          }}
                        >
                          {this.props.data.datasets[0].data[index]}
                        </Avatar>
                      }
                      endIcon={<ArrowForwardIosIcon />}
                    >
                      {label}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </CardContent>
        )}
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  Dimensions: state.Dimensions,
  Theme: state.Style.Theme,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ProceduresSimpleView);
