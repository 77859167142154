import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
//import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import { removeInbox, clearInbox } from '../../actions/AutoRefresh';

import { withRouter } from 'react-router';
import AddIcon from '@material-ui/icons/Add';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
//import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
//import FilterListIcon from '@material-ui/icons/FilterList';

//import ListItemAvatar from '@material-ui/core/ListItemAvatar';

import Typography from '@material-ui/core/Typography';
//import Chip from '@material-ui/core/Chip';
import Fab from '@material-ui/core/Fab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddProcedure from './AddProcedure';
import AddFast from './AddFast';
import AddMail from './AddMail';
import updateURLParameter from '../../lib/updateURLParameter';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
//import ListItemText from '@material-ui/core/ListItemText';
//import ListItem from '@material-ui/core/ListItem';
//import PhoneIcon from '@material-ui/icons/Phone';
//import ForumIcon from '@material-ui/icons/Forum';
//import ComputerIcon from '@material-ui/icons/Computer';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Constants } from '../../lib/Constants';
//import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
//import ErrorOutlineIcon from '@material-ui/icons/Error';
import ListItemOrga from './ListItemOrga';
import SaveIcon from '@material-ui/icons/Save';
import RefreshIcon from '@material-ui/icons/Refresh';
import { addCustomMenuPoint } from './../../actions/NavMenu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DraftsIcon from '@material-ui/icons/Drafts';
import DoneIcon from '@material-ui/icons/Done';

import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SnoozeTwoToneIcon from '@material-ui/icons/SnoozeTwoTone';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FiberNewIcon from '@material-ui/icons/FiberNew';
import Tooltip from '@material-ui/core/Tooltip';

import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
//import PaymentIcon from '@material-ui/icons/Payment';
import EuroIcon from '@material-ui/icons/Euro';
//import ArchiveIcon from '@material-ui/icons/Archive';
import DeleteIcon from '@material-ui/icons/Delete';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ErrorIcon from '@material-ui/icons/Error';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import FastForwardIcon from '@material-ui/icons/FastForward';

import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';
import SortIcon from '@material-ui/icons/Sort';

import Avatar from '@material-ui/core/Avatar';
import { DE as TextDE } from '../../lib/Text';

function unique(data) {
  return data.filter(function (value, index, data) {
    return data.indexOf(value) === index;
  });
}

const filters = [
  {
    key: 'new',
    name: 'Neu',
    state: 'Neu',
    icon: <FiberNewIcon />,
  },
  {
    key: 'working',
    name: 'Bearbeiten',
    state: 'Bearbeiten',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'on_duty',
    name: 'In Bearbeitung',
    state: 'In Bearbeitung',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'waiting',
    name: 'Warten',
    state: 'Warten',
    icon: <SnoozeTwoToneIcon />,
  },
  {
    key: 'newMail',
    name: 'Neue Nachricht!',
    state: 'Neue Nachricht!',
    icon: <FiberNewIcon />,
  },
  {
    key: 'sentMail',
    name: 'Nachricht versandt',
    state: 'Nachricht versandt',
    icon: <MailOutlineIcon />,
  },
  {
    key: 'issue',
    name: 'Problem',
    state: 'Problem',
    icon: <ErrorIcon />,
  },
  {
    key: 'facted',
    name: 'Fakturiert',
    state: 'Fakturiert',
    icon: <EuroIcon />,
  },
  {
    key: 'done',
    name: 'Erledigt',
    state: 'Erledigt',
    icon: <DoneIcon />,
  },
  {
    key: 'abbort',
    name: 'Storno',
    state: 'Storno',
    icon: <NotInterestedIcon />,
  },
  {
    key: 'deleted',
    name: 'Gelöscht',
    state: 'Gelöscht',
    icon: <DeleteIcon />,
  },
];

const defaultFilterStates = [
  {
    key: 'new',
    name: 'Neu',
    state: 'Neu',
    icon: <FiberNewIcon />,
  },
  {
    key: 'working',
    name: 'Bearbeiten',
    state: 'Bearbeiten',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'on_duty',
    name: 'In Bearbeitung',
    state: 'In Bearbeitung',
    icon: <DirectionsRunIcon />,
  },
  {
    key: 'waiting',
    name: 'Warten',
    state: 'Warten',
    icon: <SnoozeTwoToneIcon />,
  },
  {
    key: 'newMail',
    name: 'Neue Nachricht!',
    state: 'Neue Nachricht!',
    icon: <FiberNewIcon />,
  },
  {
    key: 'sentMail',
    name: 'Nachricht versandt',
    state: 'Nachricht versandt',
    icon: <MailOutlineIcon />,
  },
  {
    key: 'issue',
    name: 'Problem',
    state: 'Problem',
    icon: <ErrorIcon />,
  },
];

const styles = (theme) => ({
  inputHeight: {
    minHeight: '54px',
  },
  root: {
    display: 'flex',
    flexFlow: 'column',
  },
  cardheader: {
    display: 'block',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  buttonGroupMargin: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'auto',
    },
  },
  sortingBox: {
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'end',
    },
    alignItems: 'center',
    display: 'flex',
  },
  fab: {
    position: 'fixed',
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(4),
      right: theme.spacing(4),
      width: theme.spacing(9),
      height: theme.spacing(9),
    },
    '&>span': {
      '&>svg': {
        width: '80%',
        height: '80%',
      },
    },
  },
  avatarWrap: {
    height: '36px',
    width: '36px',
  },
  chip: {
    flexShrink: '1',
    marginRight: theme.spacing(0.8),
    padding: theme.spacing(0.5, 1),
    '&>span, &>svg, &>div': { color: 'inherit' },
  },

  header: {
    flexShrink: 1,
    display: 'flex',
    marginBottom: theme.spacing(0),
  },
  footer: {
    justifyContent: 'start',
    alignItems: 'center',
    display: 'flex',
    paddingTop: 0,
  },
  list: {
    height: '100%',
    width: '100%',
  },
  listitem: {
    listStyle: 'none',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  formControl: {
    float: 'left',
    margin: theme.spacing(1),
    minWidth: 120,
  },
  text: {
    margin: theme.spacing(1),
  },
  customColorSelected: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText + '!important',
  },
  pagination: {
    marginRight: 'auto',
    maxWidth: '60vw',
  },
  menu: {
    marginBottom: '1em',
  },
  customWrapper: {
    maxWidth: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('md')]: {
      alignItems: 'center',
      justifyContent: 'space-evenly',
    },
    '&>button': {
      width: '100%',
      whiteSpace: 'nowrap',
    },
  },
  maxWidth: {
    maxWidth: '100%',
  },
  buttonGroupLabel: {
    justifyContent: 'center',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    '&>svg': {
      marginRight: '8px',
    },
  },
  buttonGroupSelected: {
    backgroundColor: theme.palette.primary.main + '!important',
    color: theme.palette.primary.contrastText + '!important',
    borderColor: theme.palette.primary.contrastText + '!important',
  },
});

class Inbox extends React.Component {
  openMenu = (event) => {
    this.state.isMounted === true && this.setState({ menuAnchor: event.currentTarget });
  };

  closeMenu = () => {
    this.state.isMounted === true && this.setState({ menuAnchor: null });
  };

  constructor(props) {
    super(props);
    const urlParams = new URLSearchParams(this.props.Router.location.search);
    //console.log('[constructor]', this.props.Router.location.query.order, props.Router.location.query.order, this.props.match.params.order, props.match.params.order);
    let filterBox = [];
    urlParams.getAll('tags').forEach((element) => {
      if (element.includes(',')) {
        element.split(',').forEach((tag) => {
          filterBox.push('tag:' + tag);
        });
      } else if (element !== '') {
        filterBox.push('tag:' + element);
      }
    });

    urlParams.getAll('search').forEach((element) => {
      if (element.includes(',')) {
        filterBox.push(...element.split(','));
      } else if (element !== '') {
        filterBox.push(element);
      }
    });

    this.state = {
      isMounted: false,
      menuAnchor: null,
      searchDone: false,
      usersAll: [],
      filterBoxValue: unique([...filterBox]),
      filterSelectOpen: false,
      config: {
        filtering: false,
        grouping: false,
        getSizeConf:
          this.props.Theme.typography.fontSize * this.props.Theme.typography.body1.lineHeight + 4 >
          20
            ? this.props.Theme.typography.fontSize * this.props.Theme.typography.body1.lineHeight +
              4
            : 21,
      },
      params: {
        users: [],
        filter: !!this.props.Router.location.query.filter
          ? this.props.Router.location.query.filter
          : !!this.props.match.params.filter
            ? this.props.match.params.filter
            : 'none',
        order: !!this.props.Router.location.query.order
          ? this.props.Router.location.query.order
          : !!this.props.match.params.order
            ? this.props.match.params.order
            : 'oldest',
        size: !!this.props.Router.location.query.size
          ? parseInt(this.props.Router.location.query.size)
          : 15,
        page: !!this.props.Router.location.query.page
          ? parseInt(this.props.Router.location.query.page)
          : 1,
        //tags: ((!!this.props.Router.location.query.tags) ? this.props.Router.location.query.tag : ''),
        tags: [...urlParams.getAll('tags')],

        //search: ((!!this.props.Router.location.query.search) ? this.props.Router.location.query.search : ''),
        search: urlParams.getAll('search'),

        state: [],
        input: '',
      },
      toDelete: {},
      deleteOpen: false,
      isFetching: true,
      error: false,
      openedMessage: { id: null, timestamp: null },
      procedureDialogOpen: false,
      fastDialogOpen: false,
      mailDialogOpen: false,
      dialogData: {},
      saveSearchDialogOpen: false,
      saveSearchName: '',
      messages: {},
      tags: [],
      isAdmin: Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
      ]),
      // CHANGE ME TO NEGATION !Constants.roles....
      isWorker: !Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
        Constants.roles.ROLES_PHONE,
      ]),
    };

    this.openMenu = this.openMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
    this.newItem = this.newItem.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.fetchList = this.fetchList.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleDeleteFunction = this.handleDeleteFunction.bind(this);
    this.handleDeleteClose = this.handleDeleteClose.bind(this);
    this.handleDeleteOpen = this.handleDeleteOpen.bind(this);
    this.markAsSeen = this.markAsSeen.bind(this);
    this.saveSearch = this.saveSearch.bind(this);
    this.setOrder = this.setOrder.bind(this);
    this.openFilterSelect = this.openFilterSelect.bind(this);
    this.closeFilterSelect = this.closeFilterSelect.bind(this);
    this.toggleOpenFilterSelect = this.toggleOpenFilterSelect.bind(this);

    this.openUserSelect = this.openUserSelect.bind(this);
    this.closeUserSelect = this.closeUserSelect.bind(this);
    this.toggleOpenUserSelect = this.toggleOpenUserSelect.bind(this);

    this.handleSaveSearchDialogOpen = this.handleSaveSearchDialogOpen.bind(this);
    this.handleSaveSearchDialogClose = this.handleSaveSearchDialogClose.bind(this);
    this.handleSaveSearchChange = this.handleSaveSearchChange.bind(this);

    this.setAllFilter = this.setAllFilter.bind(this);
    this.executeFilters = this.executeFilters.bind(this);

    this.setAllUserFilter = this.setAllUserFilter.bind(this);
    this.executeUserFilters = this.executeUserFilters.bind(this);

    this.refresh = this.refresh.bind(this);
  }

  newItem = () => {
    this.state.isMounted === true &&
      this.setState({
        ...this.state,
        procedureDialogOpen: true,
        menuAnchor: null,
      });
  };
  newMail = () => {
    this.state.isMounted === true && this.setState({ mailDialogOpen: true, menuAnchor: null });
  };
  newFast = () => {
    this.state.isMounted === true && this.setState({ fastDialogOpen: true, menuAnchor: null });
  };

  async handleDialogClose(reload) {
    if (!!reload) {
      this.fetchList(false);
    }
    this.state.isMounted === true && this.setState({ procedureDialogOpen: false });
  }

  handleRedirect(uuid) {
    if (!!uuid && (typeof uuid).toLowerCase() === 'string') {
      this.props.dispatch(push('/nachrichten/detail/' + uuid));
    } else {
      if (!!uuid) {
        this.fetchList(false);
      }
      this.state.isMounted === true && this.setState({ procedureDialogOpen: false });
    }
  }

  async handleMailDialogClose(reload) {
    if (!!reload) {
      this.fetchList(false);
    }
    this.state.isMounted === true && this.setState({ mailDialogOpen: false });
  }

  async handleFastDialogClose(reload) {
    if (!!reload) {
      this.fetchList(false);
    }
    this.state.isMounted === true && this.setState({ fastDialogOpen: false });
  }

  markAsSeen = (item, url) => {
    this.props.dispatch(removeInbox(item.link));
  };

  linkTo = (item, url) => {
    this.markAsSeen(item, url);
    this.props.dispatch(push(url));
  };

  openFilterSelect() {
    this.setState({ filterSelectOpen: true });
  }

  async closeFilterSelect() {
    this.refresh();
    this.setState({ filterSelectOpen: false });
  }

  toggleOpenFilterSelect() {
    this.setState({ filterSelectOpen: !this.state.filterSelectOpen });
  }

  setOrder(event, order) {
    if (
      order === null ||
      this.state.params.order.toString().toLowerCase() === order.toString().toLowerCase()
    ) {
      return;
    }

    window.history.replaceState('', '', updateURLParameter(window.location.href, 'order', order));

    this.setState(
      {
        ...this.state,
        params: {
          ...this.state.params,
          order: order,
        },
      },
      this.refresh,
    );
  }

  handleFilterChange(event, value, reason) {
    //console.log(event, value, reason);
    let tmp = this.state.params;
    let tags = [];
    let search = [];
    switch (reason) {
      case 'select-option':
        //console.log("in select-option: ", value, tmp);
        tmp.tags = value;
        //tmp.input = "";
        break;
      case 'input':
        //console.log("in input change: ", value, typeof value, tmp);
        tmp.input = value;
        break;
      case 'create-option':
        //console.log("in create-option: ", value, tmp);
        tags = [];
        search = [];
        if (typeof value) {
          value.forEach((param) => {
            if (typeof param === 'object') {
              if (!!param.id || !!param['id']) {
                //console.log("APpendding to tags", param);
                tags.push(param);
              } else {
                //console.log("APpendding to search", param);
                search.push(param);
              }
            } else if (typeof param === 'string' && param !== '') {
              //console.log("APpendding to search", param);
              if (param.startsWith('t:')) {
                tags.push(param.substring(2));
              } else if (param.startsWith('tag:')) {
                tags.push(param.substring(4));
              } else {
                search.push(param);
              }
            }
          });
        }
        //tmp.input = "";
        tmp.tags = tags.length > 0 ? tags : [];
        tmp.search = search.length > 0 ? search : [];
        break;
      case 'blur':
        //console.log("found blur action?", reason, value, event)
        tags = [];
        search = [];
        if (typeof value) {
          value.forEach((param) => {
            if (typeof param === 'object') {
              if (!!param.id || !!param['id']) {
                //console.log("APpendding to tags", param);
                tags.push(param);
              } else {
                console.log('[INTERESTING]', param);
                search.push(param);
              }
            } else if (typeof param === 'string' && param !== '') {
              //console.log("APpendding to search", param);
              //console.log("APpendding to search", param);
              if (param.startsWith('t:')) {
                param = param.substring(2);
                tags.push(param);
              } else if (param.startsWith('tag:')) {
                param = param.substring(4);
                tags.push(param);
              } else {
                search.push(param);
              }
            }
          });
        }
        //tmp.input = "";
        tmp.tags = tags.length > 0 ? tags : [];
        tmp.search = search.length > 0 ? search : [];
        break;
      case 'remove-option':
        //console.log("in remove-option: ", value, tmp);
        tags = [];
        search = [];
        if (typeof value) {
          value.forEach((param) => {
            if (typeof param === 'object') {
              if (!!param.id || !!param['id']) {
                //console.log("APpendding to tags", param);
                tags.push(param);
              } else {
                //console.log("APpendding to search", param);
                search.push(param);
              }
            } else if (typeof param === 'string' && param !== '') {
              //console.log("APpendding to search", param);
              search.push(param);
            }
          });
        }
        tmp.tags = tags.length > 0 ? tags : [];
        tmp.search = search.length > 0 ? search : [];
        break;
      case 'clear':
        tmp.tags = [];
        tmp.search = [];
        tmp.input = '';
        break;
      case 'reset':
        //console.log("found reset action?", reason, value, event)
        tmp.input = '';
        break;
      default:
        //console.log("found default action?", reason, value, event)
        break;
    }

    this.state.isMounted === true &&
      this.setState({ ...this.state, params: tmp, filterBoxValue: value });
  }

  async submitFilters() {
    let tags = [];

    let search = [];

    //console.log("In submitFilters", this.state.params);
    if (!!this.state.params.search) {
      if (typeof this.state.params.search === 'object') {
        this.state.params.search.forEach((value) => search.push(value));
      } else {
        search.push(this.state.params.search);
      }
    }

    if (this.state.params.input !== '') {
      search.push(this.state.params.input);
    }

    if (!!this.state.params.tags) {
      this.state.params.tags.forEach((value) => {
        //if (value.id) {
        tags.push(value.name ?? value);
      });
    }
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'tags', tags));
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'search', search));
    //console.log("[SEARCH]", {search: search, tags: tags}, this.state)
    this.state.isMounted === true &&
      this.setState(
        {
          ...this.state,
          searchDone: true,
          params: {
            ...this.state.params,
            search: search,
            tags: tags,
          },
        },
        () => this.fetchList(false),
      );
  }

  async fetchList(search) {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox');
    //let url = new URL(process.env.REACT_APP_API_URL + ((this.state.params.filter === "todos") ? "/api/jobs" : "/api/inbox"));
    url.searchParams.set('filter', this.state.params.filter);
    url.searchParams.set('order', this.state.params.order);
    let tmp = null;

    //console.log('[THIS IS SEARCH]',this.state.params, search);
    if (typeof search !== 'object') {
      search = {
        tags: this.state.params.tags,
        state: this.state.params.state,
        search: this.state.params.search,
      };
      //console.log("FETCH CUSTOM LIST", search );
    }

    if (typeof search === 'object' && !!search.search && !!search.tags) {
      tmp = unique(search.tags);
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "tags - " + index , value);
        url.searchParams.set('tags[' + index + ']', value);
      });

      tmp = unique(search.search);
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "search - " + index , value);
        url.searchParams.set('search[' + index + ']', value);
      });
      if (!!search.state) {
        tmp = unique(search.state);
        tmp.forEach((value, index) => {
          //console.log("[SEARCH]", "search - " + index , value);
          url.searchParams.set('state[' + index + ']', value);
        });
      }
    }
    url.searchParams.set('page', this.state.params?.page || 1);

    var updateState = false;
    if (!!this.state.params.state) {
      tmp = unique(this.state.params.state);
      let newStates = tmp.map((value) => {
        if (typeof value !== 'object') {
          updateState = true;
          return filters.find((filter) => {
            return filter.state.toString().toLowerCase() === value.toString().toLowerCase();
          });
        }
        return value;
      });
      newStates = newStates.filter((value) => !!value && !!value.state);
      if (updateState) {
        await this.setState({ ...this.state, params: { ...this.state.params, state: newStates } });
      }
      updateState = false;
      tmp = newStates.map((value) => value.state);
      window.history.replaceState('', '', updateURLParameter(window.location.href, 'state', tmp));
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "search - " + index , value);
        url.searchParams.set('state[' + index + ']', value);
      });
    }

    if (!!this.state.params.users) {
      updateState = false;
      tmp = unique(this.state.params.users);
      let newUsers = tmp.map((value) => {
        if (typeof value !== 'object') {
          updateState = true;
          return this.state.usersAll.find((user) => {
            return user.uuid.toString().toLowerCase() === value.toString().toLowerCase();
          });
        }
        return value;
      });

      if (updateState) {
        await this.setState({ ...this.state, params: { ...this.state.params, users: newUsers } });
      }

      tmp = newUsers.map((value) => value.uuid);
      window.history.replaceState('', '', updateURLParameter(window.location.href, 'user', tmp));
      tmp.forEach((value, index) => {
        //console.log("[SEARCH]", "search - " + index , value);
        url.searchParams.set('user[' + index + ']', value);
      });
    }

    url.searchParams.set('size', this.state.params.size);

    let response = await fetch(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return !!json?.data ? json : false;
      })
      .catch((error) => {
        return error;
      });

    if (!!response?.data && this.state.isMounted === true) {
      this.setState({ isFetching: false, messages: response });
    } else {
      console.error(response);
      if (this.state.isMounted === true) {
        this.setState({ isFetching: false, messages: {} });
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    let changes = {};
    //console.log("[Component Did Update] Props:", prevProps, this.props);
    //console.log("[Component Did Update] State:",  prevState, this.state );
    const urlParams = new URLSearchParams(this.props.Router.location.search);
    if (prevProps.Router.location.key !== this.props.Router.location.key) {
      //this.props.Router.location.search ) {
      //console.log("Changes in searc:", prevProps.Router.location.search !== this.props.Router.location.search, prevProps.Router.location.search, this.props.Router.location.search)
      changes['order'] = urlParams.has('order') ? urlParams.get('order') : 'oldest';
      changes['size'] = urlParams.has('size') ? urlParams.get('size') : 15;
      changes['page'] = urlParams.has('page') ? urlParams.get('page') : 1;
      changes['tags'] = urlParams.has('tags') ? urlParams.getAll('tags') : [];

      let states = [];
      let users = [];
      if (this.props.match.params.filter === 'alle') {
        states = unique([...urlParams.getAll('state'), ...urlParams.getAll('state[]')]);
        states = states.length > 0 ? states : defaultFilterStates;

        users = unique([...urlParams.getAll('user'), ...urlParams.getAll('user[]')]);
        users = users.length > 0 ? users : [];
      }
      changes['state'] = states;
      changes['users'] = users;

      changes['search'] = urlParams.has('search') ? urlParams.getAll('search') : [];
    }
    if (prevProps.match.params.filter !== this.props.match.params.filter) {
      //console.log("Changes in filter:", prevProps.match.params.filter !== this.props.match.params.filter, prevProps.match.params.filter, this.props.match.params.filter)
      let states = [];
      let users = [];
      if (this.props.match.params.filter === 'alle') {
        states = unique([...urlParams.getAll('state'), ...urlParams.getAll('state[]')]);
        states = states.length > 0 ? states : defaultFilterStates;

        users = unique([...urlParams.getAll('user'), ...urlParams.getAll('user[]')]);
        users = users.length > 0 ? users : [];
      }
      changes['filter'] = this.props.match.params.filter;
      changes['state'] = states;
      changes['users'] = users;

      //changes['order'] = 'asc';
      //changes['size'] =  15;
      //changes['page'] =  1;
      //changes['tags'] = [];
      //changes['search'] = [];
    }

    //console.log("CHANGES", Object.keys(changes).length)
    if (Object.keys(changes).length >= 1) {
      //console.log("CHANGES", changes);#

      let filterBoxValue = [];
      var tags = [];
      if (Array.isArray(changes.tags)) {
        changes.tags.forEach((param) => {
          if (typeof param === 'object' && (!!param.name || !!param['name'])) {
            tags.push('tag:' + param.name);
          } else if (typeof param === 'string' && param !== '') {
            //console.log("Appendding to search", param);
            tags.push('tag:' + param);
          }
        });
      }

      filterBoxValue = filterBoxValue.concat(tags);
      filterBoxValue = filterBoxValue.concat(Array.isArray(changes.search) ? changes.search : []);
      //console.log('Setting changes', filterBoxValue, changes);
      this.setState(
        {
          ...this.state,
          isMounted: true,
          filterBoxValue: filterBoxValue,
          messages: [],
          isFetching: true,
          params: {
            ...this.state.params,
            ...changes,
          },
        },
        () => this.fetchList(false),
      );
    }
  }

  componentWillUnmount() {
    this.state.isMounted === true && this.setState({ ...this.state, isMounted: false });
  }
  async componentDidMount() {
    let states = [];
    let users = [];

    let usersAll = await fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = [];
        json.forEach((value, key) => {
          if (!!!value.deleted_at) {
            tmp.push({
              uuid: value.uuid,
              firstname: value.firstname,
              nickname: value.nickname,
              lastname: value.lastname,
              email: value.email,
              roles: value.roles.map((role) => role.name),
            });
          }
        });
        tmp = tmp.sort((a, b) => (a.lastname > b.lastname ? 1 : b.lastname > a.lastname ? -1 : 0));
        return tmp;
      })
      .catch((error) => {
        return false;
      });

    if (this.state.params.filter === 'alle') {
      const urlParams = new URLSearchParams(this.props.Router.location.search);

      let tmpFilters = unique([...urlParams.getAll('state'), ...urlParams.getAll('state[]')]);
      if (tmpFilters.length > 0) {
        // get from filters ...
        for (var i = 0; i < tmpFilters.length; i++) {
          var clickedState = filters.find(
            // eslint-disable-next-line
            (value) =>
              value.state.toString().toLowerCase() === tmpFilters[i].toString().toLowerCase(),
          );
          if (!!clickedState) {
            states.push(clickedState);
          }
        }
      } else {
        states = defaultFilterStates;
      }

      let tmpUsers = unique([...urlParams.getAll('user'), ...urlParams.getAll('user[]')]);
      if (tmpUsers.length > 0) {
        // get from filters ...
        for (var j = 0; j < tmpUsers.length; j++) {
          var clickedStateUsers = usersAll.find(
            // eslint-disable-next-line
            (value) => value.uuid.toString().toLowerCase() === tmpUsers[j].toString().toLowerCase(),
          );
          if (!!clickedStateUsers) {
            users.push(clickedStateUsers);
          }
        }
      }
    }

    this.setState(
      {
        ...this.state,
        isMounted: true,
        usersAll: usersAll,
        params: {
          ...this.state.params,
          state: states,
          users: users,
        },
      },
      () => this.fetchList(false),
    );
  }

  setAllFilter = (data) => {
    this.setState(
      {
        ...this.state,
        params: { ...this.state.params, state: filters },
      },
      this.closeFilterSelect,
    );
  };

  executeFilters = (data) => {
    this.closeFilterSelect();
  };

  handleStateChange(event, data) {
    //console.log("[handleStateChange]", event, data, this.state.params.state, filters);
    if (data.props.value === 'ignore') {
      return;
    }

    var clickedState = filters.find(
      (value) => value.state.toString().toLowerCase() === data.props.value.toString().toLowerCase(),
    );

    if (!!!clickedState) {
      return;
    }
    var newState = this.state.params.state;
    var indexOfState = newState.findIndex(
      (value) =>
        value.state.toString().toLowerCase() === clickedState.state.toString().toLowerCase(),
    );

    if (indexOfState > -1) {
      newState = newState.filter(
        (value) =>
          value.state.toString().toLowerCase() !== clickedState.state.toString().toLowerCase(),
      );
    } else {
      newState.push(clickedState);
    }

    this.setState({
      ...this.state,
      params: { ...this.state.params, state: newState },
    });
    //this.refresh();
  }

  async closeUserSelect() {
    this.refresh();
    this.setState({ userSelectOpen: false });
  }

  openUserSelect() {
    this.setState({ userSelectOpen: true });
  }

  toggleOpenUserSelect() {
    this.setState({ userSelectOpen: !this.state.userSelectOpen });
  }

  setAllUserFilter = (data) => {
    this.setState(
      {
        ...this.state,
        params: { ...this.state.params, users: [] },
      },
      this.closeUserSelect,
    );
  };

  executeUserFilters = (data) => {
    this.closeUserSelect();
  };

  handleUserChange(event, data) {
    //console.log("[handleUserChange]", event, data, this.state.params.users);
    if (data.props.value === 'ignore') {
      return;
    }
    if (data.props.value === 'close') {
      this.closeUserSelect();
    }

    if (!!!data.props.value?.uuid) {
      return;
    }

    var clickedState = this.state.usersAll.find(
      (value) =>
        value.uuid.toString().toLowerCase() === data.props.value?.uuid.toString().toLowerCase(),
    );

    if (!!!clickedState) {
      return;
    }
    var newUsers = this.state.params.users;
    var indexOfUser = newUsers.findIndex(
      (value) => value.uuid.toString().toLowerCase() === clickedState.uuid.toString().toLowerCase(),
    );

    if (indexOfUser > -1) {
      newUsers = newUsers.filter(
        (value) =>
          value.uuid.toString().toLowerCase() !== clickedState.uuid.toString().toLowerCase(),
      );
    } else {
      newUsers.push(clickedState);
    }

    this.setState({
      ...this.state,
      params: { ...this.state.params, users: newUsers },
    });
    //this.refresh();
  }

  async handleDeleteFunction() {
    let url = new URL(process.env.REACT_APP_API_URL + '/api/inbox/' + this.state.toDelete.uuid);
    let response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify({ _method: 'delete' }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        return json;
      })
      .catch((error) => {
        return error;
      });

    if (response === 'Deleted') {
      this.setState({
        ...this.state,
        toDelete: {},
        deleteOpen: false,
        messages: {
          ...this.state.messages,
          data: this.state.messages.data.filter((element) => element.id !== this.state.toDelete.id),
        },
      });
      return true;
    }
    return false;
  }

  handleDeleteOpen(message) {
    this.state.isMounted === true &&
      this.setState({ ...this.state, toDelete: message, deleteOpen: true });
  }

  handleDeleteClose(message) {
    this.state.isMounted === true &&
      this.setState({ ...this.state, toDelete: {}, deleteOpen: false });
  }

  handleSaveSearchDialogOpen() {
    this.state.isMounted === true && this.setState({ saveSearchDialogOpen: true });
  }
  handleSaveSearchDialogClose() {
    this.state.isMounted === true && this.setState({ saveSearchDialogOpen: false });
  }

  handleSaveSearchChange(event) {
    this.state.isMounted === true && this.setState({ saveSearchName: event.target.value });
  }

  saveSearch() {
    let str = '';
    this.state.params.tags.forEach((value, index) => {
      if (typeof value === 'string') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('tags') + '=' + encodeURIComponent(value);
      } else if (value?.name?.trim() !== '') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('tags') + '=' + encodeURIComponent(value.name);
      }
    });

    this.state.params.search.forEach((value, index) => {
      if (value.trim() !== '') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('search') + '=' + encodeURIComponent(value);
      }
    });

    this.state.params.state.forEach((value, index) => {
      if (value.state.trim() !== '') {
        if (str !== '') {
          str += '&';
        }
        str += encodeURIComponent('state') + '=' + encodeURIComponent(value.state);
      }
    });

    let name = this.state.saveSearchName !== '' ? this.state.saveSearchName : str.substring(0, 20);

    this.props.dispatch(
      addCustomMenuPoint({
        key: name,
        parent: 'inbox',
        name: name,
        icon: 'ListAltIcon',
        path: '/nachrichten/' + this.state.params.filter + '/' + name + '?' + str,
      }),
    );
    if (this.state.isMounted === true) {
      this.setState({ saveSearchName: '', saveSearchDialogOpen: false });
    }
  }

  async handleSizeChange(event) {
    let size = event.target.value;
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'size', size));
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'page', 1));

    this.state.isMounted === true &&
      this.setState(
        {
          isFetching: true,
          params: { ...this.state.params, page: 1, size: size },
        },
        () => this.fetchList(false),
      );
  }
  async refresh() {
    this.props.dispatch(clearInbox());
    this.state.isMounted === true &&
      this.setState({ isFetching: true }, () => this.fetchList(false));
  }
  async handlePageChange(event, page) {
    window.history.replaceState('', '', updateURLParameter(window.location.href, 'page', page));
    this.state.isMounted === true &&
      this.setState({ isFetching: true, params: { ...this.state.params, page: page } }, () =>
        this.fetchList(false),
      );
  }

  render() {
    const { classes } = this.props;

    const expanded = false; // this.props.Dimensions.width > this.props.Theme.breakpoints.values.md;
    return (
      <>
        <Dialog
          fullScreen={this.props.Dimensions.maxWidth < this.props.Theme.breakpoints.values.md}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleDialogClose(false);
            }
          }}
          open={this.state.procedureDialogOpen}
        >
          <AddProcedure
            dialogData={this.state.dialogData}
            handleclosefunction={(uuid) => this.handleRedirect(uuid)}
          />
        </Dialog>

        <Dialog
          fullScreen={false}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleFastDialogClose(false);
            }
          }}
          open={this.state.fastDialogOpen}
        >
          <AddFast handleclosefunction={(uuid) => this.handleRedirect(uuid)} />
        </Dialog>

        <Dialog
          fullScreen={false}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleMailDialogClose(false);
            }
          }}
          open={this.state.mailDialogOpen}
        >
          <AddMail handleclosefunction={(uuid) => this.handleRedirect(uuid)} />
        </Dialog>

        <Dialog
          fullWidth
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleDeleteClose();
            }
          }}
          open={this.state.deleteOpen}
        >
          <DialogTitle>{TextDE.inbox.deleteInboxItemDialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {TextDE.inbox.deleteInboxItemDialog.text}
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={this.handleDeleteClose} color='primary'>
              {TextDE.abort}
            </Button>
            <Button
              onClick={this.handleDeleteFunction}
              variant='outlined'
              color='secondary'
              autoFocus
            >
              {TextDE.delete}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.saveSearchDialogOpen}
          onClose={this.handleSaveSearchDialogClose}
          aria-labelledby='form-dialog-title'
        >
          <DialogContent>
            <DialogContentText>{TextDE.list.customMenuDialog.text}:</DialogContentText>
            <TextField
              autoFocus
              margin='dense'
              value={this.state.saveSearchName}
              onChange={this.handleSaveSearchChange}
              label={TextDE.list.customMenuDialog.itemName}
              type='text'
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleSaveSearchDialogClose} color='primary'>
              {TextDE.abort}
            </Button>
            <Button variant='contained' onClick={this.saveSearch} color='primary'>
              {TextDE.save}
            </Button>
          </DialogActions>
        </Dialog>

        <div
          style={{
            margin: 0,
            height: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            maxHeight: this.props.Dimensions.height - this.props.Dimensions.appBarHeight,
            width: this.props.Dimensions.maxWidth,
            maxWidth: this.props.Dimensions.maxWidth,
            overflow: 'auto',
            backgroundColor: 'inherit',
            display: 'flex',
          }}
        >
          <Card
            style={{
              display: 'flex',
              flexFlow: 'column',
              flexGrow: '1',
              margin: '8px',
            }}
          >
            <CardHeader
              disableTypography
              classes={{
                root: classes.cardheader,
                content: classes.maxWidth,
              }}
              title={
                <Grid container spacing={2}>
                  <Grid item xs={9} sm={this.state.searchDone ? 8 : 10}>
                    <Autocomplete
                      autoComplete={true}
                      autoSelect={true}
                      includeInputInList={true}
                      autoHighlight={true}
                      size='small'
                      value={this.state.filterBoxValue}
                      multiple
                      freeSolo
                      options={this.state.tags}
                      getOptionLabel={(tag) => {
                        if (!!tag.name) {
                          return tag.name;
                        } else {
                          return tag;
                        }
                      }}
                      style={{ width: '100%' }}
                      onChange={this.handleFilterChange}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          value={this.state.params.input}
                          name='searchInput'
                          label={TextDE.search.helperText}
                          variant='outlined'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={3} sm={2}>
                    <Button
                      fullWidth
                      size='medium'
                      color='primary'
                      variant='outlined'
                      onClick={this.submitFilters}
                      aria-label='Back'
                    >
                      <SearchIcon />
                      <Hidden smDown>{TextDE.search.label}</Hidden>
                    </Button>
                  </Grid>

                  {this.state.searchDone && (
                    <Grid item xs={12} sm={2}>
                      <Tooltip title={TextDE.search.addSearchToMenuTooltip}>
                        <Button
                          fullWidth
                          size='medium'
                          color='primary'
                          variant='contained'
                          onClick={this.handleSaveSearchDialogOpen}
                          aria-label='Back'
                        >
                          <SaveIcon />
                          &nbsp;{TextDE.search.addSearchToMenuLabel}
                        </Button>
                      </Tooltip>
                    </Grid>
                  )}

                  {'alle' === this.state.params.filter && (
                    <Grid item xs={8}>
                      <Tooltip title={TextDE.list.stateFilter.tooltip}>
                        <FormControl fullWidth variant='outlined' size='small' margin='dense'>
                          <InputLabel id='filter-mutiple-chip-label' shrink>
                            {TextDE.list.stateFilter.label}
                          </InputLabel>
                          <Select
                            className={classes.inputHeight}
                            labelId='filter-mutiple-chip-label'
                            label={TextDE.list.stateFilter.label}
                            id='filter-mutiple-chip'
                            key='filter-mutiple-chip'
                            multiple
                            open={this.state.filterSelectOpen}
                            value={this.state.params.state}
                            onOpen={this.openFilterSelect}
                            onChange={this.handleStateChange}
                            renderValue={(selected) => (
                              <div key='filter-mutiple-chip-container' className={classes.chips}>
                                {selected.map((value) => {
                                  return (
                                    <Chip
                                      icon={value.icon}
                                      key={value.key}
                                      label={value.name}
                                      className={classes.chip}
                                      color='primary'
                                      variant='outlined'
                                    />
                                  );
                                })}
                              </div>
                            )}
                          >
                            <MenuItem disabled key='label-key'>
                              <ListItemText primary='Nach welchen Stati sollen wir filtern?' />
                            </MenuItem>
                            {filters.map((state) => {
                              return (
                                <MenuItem key={state.key} value={state.state}>
                                  <Box mx={2} />
                                  <ListItemIcon>{state.icon}</ListItemIcon>
                                  <ListItemText primary={state.name} />
                                  <Checkbox
                                    style={{ marginLeft: 'auto' }}
                                    checked={
                                      this.state.params.state.findIndex(
                                        (value) => value.name === state.name,
                                      ) > -1
                                    }
                                  />
                                </MenuItem>
                              );
                            })}
                            <Box key='ignore-box' value='ignore' px={4} pb={1} pt={2}>
                              <Grid
                                key='ignore-grid'
                                container
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                spacing={2}
                              >
                                <Grid key='ignore-grid-item' item xs={12} md={6}>
                                  <Button
                                    key='ignore-grid-item-button-all'
                                    onClick={this.setAllFilter}
                                    value='selectAll'
                                    fullWidth
                                    color='primary'
                                    variant='outlined'
                                    aria-label='all'
                                  >
                                    <AllInclusiveIcon />
                                    &nbsp;
                                    {TextDE.all}
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Button
                                    key='ignore-grid-item-button-execute'
                                    onClick={this.executeFilters}
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                  >
                                    <SearchIcon />
                                    &nbsp;
                                    {TextDE.list.stateFilter.save}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </Grid>
                  )}

                  {'alle' === this.state.params.filter && (
                    <Grid item xs={4}>
                      <Tooltip title={TextDE.list.userFilter.tooltip}>
                        <FormControl fullWidth variant='outlined' size='small' margin='dense'>
                          <InputLabel id='mutiple-user-chip-label' shrink>
                            {TextDE.list.userFilter.label}
                          </InputLabel>
                          <Select
                            className={classes.inputHeight}
                            labelId='mutiple-user-chip-label'
                            label={TextDE.list.userFilter.label}
                            id='mutiple-user-chip'
                            key='mutiple-user-chip'
                            multiple
                            notched={true}
                            open={!!this.state.userSelectOpen}
                            value={this.state.params.users}
                            onOpen={this.openUserSelect}
                            onChange={this.handleUserChange}
                            displayEmpty={true}
                            renderValue={(selected) => (
                              <div key='filter-user-container' className={classes.chips}>
                                {selected.length === 0 && (
                                  <Typography color='textSecondary'>
                                    {TextDE.withoutUserFilter}
                                  </Typography>
                                )}
                                {selected.map((value) => (
                                  <Chip
                                    key={value.uuid}
                                    icon={
                                      <Avatar
                                        alt={`${value.firstname} ${value.lastname}`}
                                        src={
                                          process.env.REACT_APP_API_URL +
                                          '/avatar/' +
                                          value.uuid +
                                          '?thumb'
                                        }
                                        style={{ height: '130%', width: 'auto' }}
                                      />
                                    }
                                    label={`${value.firstname} ${value.lastname}`}
                                    className={classes.chip}
                                    color='primary'
                                    variant='outlined'
                                  />
                                ))}
                              </div>
                            )}
                          >
                            <MenuItem disabled>
                              <ListItemText primary='Nach welchen Usern sollen wir filtern?' />
                            </MenuItem>
                            {(this.state.usersAll ?? []).map((user) => {
                              return (
                                <MenuItem key={user.uuid} value={user}>
                                  <Box mx={2} />
                                  <ListItemIcon>
                                    <Avatar
                                      alt={`${user.firstname} ${user.lastname}`}
                                      src={
                                        process.env.REACT_APP_API_URL +
                                        '/avatar/' +
                                        user.uuid +
                                        '?thumb'
                                      }
                                      classes={{ root: classes.avatarWrap }}
                                    />
                                  </ListItemIcon>
                                  <ListItemText primary={`${user.firstname} ${user.lastname}`} />
                                  <Checkbox
                                    style={{ marginLeft: 'auto' }}
                                    checked={
                                      this.state.params.users.findIndex(
                                        (value) => value.uuid === user.uuid,
                                      ) > -1
                                    }
                                  />
                                </MenuItem>
                              );
                            })}

                            <Box key='ignore-box' value='ignore' px={4} pb={1} pt={2}>
                              <Grid
                                key='ignore-grid'
                                container
                                direction='row'
                                justifyContent='center'
                                alignItems='center'
                                spacing={2}
                              >
                                <Grid key='ignore-grid-item' item xs={12} md={6}>
                                  <Button
                                    key='ignore-grid-item-button-all'
                                    onClick={this.setAllUserFilter}
                                    value='selectAll'
                                    fullWidth
                                    color='primary'
                                    variant='outlined'
                                    aria-label='all'
                                  >
                                    <AllInclusiveIcon />
                                    &nbsp;
                                    {TextDE.all}
                                  </Button>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <Button
                                    key='ignore-grid-item-button-execute'
                                    onClick={this.executeUserFilters}
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                  >
                                    <SearchIcon />
                                    &nbsp;
                                    {TextDE.list.userFilter.save}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              }
            />
            <CardActions className={classes.footer}>
              <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                spacing={0}
              >
                <Grid item xs={7} md={6}>
                  <Pagination
                    count={Math.ceil(this.state.messages.total / this.state.messages.per_page)}
                    showFirstButton={false}
                    showLastButton={false}
                    page={this.state.params.page}
                    total={this.state.messages.total}
                    size='medium'
                    variant='outlined'
                    color='primary'
                    onChange={this.handlePageChange}
                  />
                </Grid>
                <Grid item xs={5} md={6} className={classes.sortingBox}>
                  <Hidden smDown>
                    <InputLabel
                      htmlFor='size'
                      style={{
                        fontSize: this.props.Theme.typography.caption.fontSize,
                      }}
                    >
                      {TextDE.list.countOnPage}
                    </InputLabel>
                    <Select
                      native
                      value={this.state.params.size}
                      onChange={this.handleSizeChange}
                      inputProps={{
                        name: 'size',
                        id: 'size',
                      }}
                    >
                      <option value={this.state.params.size}>{this.state.params.size}</option>
                      <option value={15}>15</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </Select>
                  </Hidden>

                  <Hidden smDown>
                    <IconButton onClick={this.refresh}>
                      <RefreshIcon />
                    </IconButton>
                  </Hidden>

                  <ToggleButtonGroup
                    className={classes.buttonGroupMargin}
                    size='small'
                    value={this.state.params.order}
                    exclusive
                    onChange={this.setOrder}
                    aria-label='text alignment'
                  >
                    <ToggleButton
                      classes={{ selected: classes.customColorSelected }}
                      value='oldest'
                      aria-label='left aligned'
                    >
                      <Tooltip title={TextDE.list.order.oldestFirstTooltip}>
                        <Box display='flex'>
                          <SortIcon />
                          <Hidden mdDown> &nbsp; {TextDE.list.order.oldestFirstLabel}</Hidden>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      classes={{ selected: classes.customColorSelected }}
                      value='newest'
                      aria-label='right aligned'
                    >
                      <Tooltip title={TextDE.list.order.newestFirstTooltip}>
                        <Box display='flex'>
                          <SortIcon style={{ transform: 'scaleY(-1)' }} />
                          <Hidden mdDown> &nbsp; {TextDE.list.order.newestFirstLabel}</Hidden>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                    <ToggleButton
                      classes={{ selected: classes.customColorSelected }}
                      value='updated'
                      aria-label='centered'
                    >
                      <Tooltip title={TextDE.list.order.updatedFirstTooltip}>
                        <Box display='flex'>
                          <RefreshIcon />
                          <Hidden mdDown> &nbsp; {TextDE.list.order.updatedFirstLabel}</Hidden>
                        </Box>
                      </Tooltip>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
            </CardActions>
            <Divider />
            <CardContent style={{ overflow: 'auto', flexGrow: 1, padding: '0' }}>
              <List disablePadding>
                {this.state.isFetching ? (
                  <center>
                    <CircularProgress />
                  </center>
                ) : !!this.state.messages?.data &&
                  Object.keys(this.state.messages?.data).length > 0 ? (
                  Object.values(this.state.messages.data).map((value) => (
                    <ListItemOrga
                      key={'item-' + value.uuid}
                      expanded={expanded}
                      isAdmin={this.state.isAdmin}
                      message={value}
                      delete={this.handleDeleteOpen}
                      hasChange={-1}
                      linkTo={(url) => this.props.dispatch(push(url))}
                    />
                  ))
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginTop: '3em',
                    }}
                  >
                    <CheckCircleIcon fontSize='large' color='primary' />
                    <Typography variant='h5' color='primary'>
                      {TextDE.search.nothingFoundLabel}
                    </Typography>
                  </Box>
                )}
              </List>
            </CardContent>
          </Card>
        </div>

        <Fab onClick={this.openMenu} className={classes.fab} color='primary' aria-label='add'>
          <AddIcon />
        </Fab>

        <Menu
          elevation={8}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          id='customized-menu'
          anchorEl={this.state.menuAnchor}
          keepMounted
          open={Boolean(this.state.menuAnchor)}
          onClose={this.closeMenu}
          classes={{ paper: classes.menu }}
        >
          <MenuItem onClick={this.newItem}>
            <ListItemIcon>
              <VerticalSplitIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.list.addMenu.procedure.label} />
          </MenuItem>
          <MenuItem onClick={this.newMail}>
            <ListItemIcon>
              <DraftsIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.list.addMenu.email.label} />
          </MenuItem>
          <MenuItem onClick={this.newFast}>
            <ListItemIcon>
              <FastForwardIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary={TextDE.list.addMenu.fast.label} />
          </MenuItem>
        </Menu>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  User: state.User,
  Authentication: state.Authentication,
  Theme: state.Style.Theme,
  Router: state.router,
  Dimensions: state.Dimensions,
  //AutoRefresh: state.AutoRefresh,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Inbox);
