/* eslint-disable no-unused-vars */
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { lighten } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import classNames from 'classnames';
//import { CsvBuilder } from '@material-table/core/filefy';
//import "jspdf-autotable";
import PropTypes from 'prop-types';
import React from 'react';

import CustomBetweenDatePicker from './CustomBetweenDatePicker';

//const jsPDF = typeof window !== "undefined" ? require("jspdf").jsPDF : null;
/* eslint-enable no-unused-vars */

export class CustomToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      columnsButtonAnchorEl: null,
      exportButtonAnchorEl: null,
      searchText: props.searchText,
    };
  }

  onSearchChange = (searchText) => {
    this.props.dataManager.changeSearchText(searchText);
    this.setState({ searchText }, this.props.onSearchChanged(searchText));
  };

  getTableData = (selectedData) => {
    //console.log("[getTableData] selectedData", selectedData);
    //console.log("[getTableData] props", this.props);
    //console.log("[getTableData] exportAllData", this.props.exportAllData);
    //console.log("[getTableData] data", this.props.data);
    //console.log("[getTableData] renderData", this.props.renderData);
    //console.log("[getTableData] allData", this.props.tableRef.current.state.originalData);
    const columns = this.props.columns
      .filter(
        (columnDef) =>
          (!columnDef.hidden || columnDef.export === true) &&
          columnDef.export !== false &&
          columnDef.field,
      )
      .sort((a, b) => (a.tableData.columnOrder > b.tableData.columnOrder ? 1 : -1));

    let allData =
      this.props.tableRef.current.state.originalData.length !== this.props.data.length
        ? this.props.tableRef.current.state.originalData
        : this.props.data;

    const data = (this.props.exportAllData ? allData : this.props.renderData).map((rowData) =>
      columns.map((columnDef) => this.props.getFieldValue(rowData, columnDef)),
    );

    return [columns, data];
  };

  defaultExportCsv = (selectedData = null) => {
    //console.log("[defaultExportCsv] selectedData",selectedData);
    const [columns, data] = this.getTableData(selectedData);
    //console.log("[defaultExportCsv]",columns, data);

    let columnsHeaders = columns.map((column) => column.title);

    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        }
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|;|\n)/g) >= 0) result = '"' + result + '"';
        if (j > 0) finalVal += ';';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    csvFile += processRow(columnsHeaders);

    for (var i = 0; i < data.length; i++) {
      csvFile += processRow(data[i]);
    }

    let filename = 'export.csv';
    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  exportCurrentDataCsv = () => {
    //console.log("[exportCurrentDataCsv]", this.props.data);
    this.defaultExportCsv();
    this.setState({ exportButtonAnchorEl: null });
  };
  exportSelectedCsv = (selectedData) => {
    //console.log("[exportSelectedCsv]", selectedData);
    this.defaultExportCsv(selectedData);
    this.setState({ exportButtonAnchorEl: null });
  };

  renderSearch() {
    const localization = {
      ...CustomToolbar.defaultProps.localization,
      ...this.props.localization,
    };
    if (this.props.search) {
      return (
        <TextField
          autoFocus={this.props.searchAutoFocus}
          className={
            this.props.searchFieldAlignment === 'left' && this.props.showTitle === false
              ? null
              : this.props.classes.searchField
          }
          value={this.state.searchText}
          onChange={(event) => this.onSearchChange(event.target.value)}
          placeholder={localization.searchPlaceholder}
          variant={this.props.searchFieldVariant}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Tooltip title={localization.searchTooltip}>
                  <this.props.icons.Search fontSize='small' />
                </Tooltip>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  disabled={!this.state.searchText}
                  onClick={() => this.onSearchChange('')}
                  aria-label={localization.clearSearchAriaLabel}
                  size='medium'
                >
                  <this.props.icons.ResetSearch fontSize='small' aria-label='clear' />
                </IconButton>
              </InputAdornment>
            ),
            style: this.props.searchFieldStyle,
            inputProps: {
              'aria-label': localization.searchAriaLabel,
            },
          }}
        />
      );
    } else {
      return null;
    }
  }

  renderDefaultActions() {
    const localization = {
      ...CustomToolbar.defaultProps.localization,
      ...this.props.localization,
    };
    const { classes } = this.props;

    //console.log("[CustomToolbar] Props:", this.props, this.props.tableRef.current);
    return (
      <div>
        {this.props.columnsButton && (
          <span>
            <Tooltip title={localization.showColumnsTitle}>
              <IconButton
                color='inherit'
                onClick={(event) =>
                  this.setState({
                    columnsButtonAnchorEl: event.currentTarget,
                  })
                }
                aria-label={localization.showColumnsAriaLabel}
                size='medium'
              >
                <this.props.icons.ViewColumn />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={this.state.columnsButtonAnchorEl}
              open={Boolean(this.state.columnsButtonAnchorEl)}
              onClose={() => this.setState({ columnsButtonAnchorEl: null })}
            >
              <MenuItem
                key={'text'}
                disabled
                style={{
                  opacity: 1,
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {localization.addRemoveColumns}
              </MenuItem>
              {this.props.columns.map((col) => {
                if (!col.hidden || col.hiddenByColumnsButton) {
                  return (
                    <li key={col.tableData.id}>
                      <MenuItem
                        className={classes.formControlLabel}
                        component='label'
                        htmlFor={`column-toggle-${col.tableData.id}`}
                        disabled={col.removable === false}
                      >
                        <Checkbox
                          checked={!col.hidden}
                          id={`column-toggle-${col.tableData.id}`}
                          onChange={() => this.props.onColumnsChanged(col, !col.hidden)}
                        />
                        <span>{col.title}</span>
                      </MenuItem>
                    </li>
                  );
                }
                return null;
              })}
            </Menu>
          </span>
        )}

        <span>
          <Tooltip title='Daten erneut Laden/ Aktualisieren'>
            <IconButton
              color='inherit'
              onClick={() =>
                this.props.tableRef.current && this.props.tableRef.current.onQueryChange()
              }
              aria-label='refresh'
              size='medium'
            >
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </span>
        <span>
          {this.props.selectedRows.length > 0 ? (
            <Button
              variant='contained'
              key='export-selected-csv'
              endIcon={<this.props.icons.Export />}
              onClick={() => this.exportSelectedCsv(this.props.selectedRows)}
            >
              Auswahl exportieren ({this.props.selectedRows.length} Zeilen)
            </Button>
          ) : (
            <Button
              variant='contained'
              key='export-all-csv'
              endIcon={<this.props.icons.Export />}
              onClick={() => this.exportCurrentDataCsv()}
            >
              Aktuelle Daten exportieren
            </Button>
          )}
        </span>
      </div>
    );
  }

  renderSelectedActions() {
    return (
      <React.Fragment>
        <this.props.components.Actions
          actions={this.props.actions.filter((a) => a.position === 'toolbarOnSelect')}
          data={this.props.selectedRows}
          components={this.props.components}
        />
      </React.Fragment>
    );
  }

  renderActions() {
    const { classes } = this.props;

    return (
      <div className={classes.actions}>
        <div>{this.renderDefaultActions()}</div>
      </div>
    );
  }

  renderToolbarTitle(title) {
    const { classes } = this.props;
    const toolBarTitle =
      typeof title === 'string' ? (
        <Typography
          variant='h6'
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {title}
        </Typography>
      ) : (
        title
      );

    return <div className={classes.title}>{toolBarTitle}</div>;
  }

  render() {
    const { classes } = this.props;
    return (
      <Toolbar className={classNames(classes.root, {})}>
        <div style={{ float: 'left' }}>
          <CustomBetweenDatePicker
            tableRef={this.props.tableRef}
            setDateStart={this.props.setDateStart}
            setDateEnd={this.props.setDateEnd}
            dateStart={this.props.dateStart}
            dateEnd={this.props.dateEnd}
          />
        </div>

        <div className={classes.spacer} />
        {this.renderSearch()}
        {this.renderActions()}
      </Toolbar>
    );
  }
}

CustomToolbar.defaultProps = {
  actions: [],
  columns: [],
  columnsButton: false,
  localization: {
    addRemoveColumns: 'Add or remove columns',
    nRowsSelected: '{0} row(s) selected',
    showColumnsTitle: 'Show Columns',
    showColumnsAriaLabel: 'Show Columns',
    exportTitle: 'Export',
    exportAriaLabel: 'Export',
    exportCSVName: 'Export as CSV',
    exportPDFName: 'Export as PDF',
    searchTooltip: 'Search',
    searchPlaceholder: 'Search',
    searchAriaLabel: 'Search',
    clearSearchAriaLabel: 'Clear Search',
  },
  search: true,
  showTitle: true,
  searchText: '',
  showTextRowsSelected: true,
  toolbarButtonAlignment: 'right',
  searchAutoFocus: false,
  searchFieldAlignment: 'right',
  searchFieldVariant: 'standard',
  selectedRows: [],
  title: 'No Title!',
};

CustomToolbar.propTypes = {
  actions: PropTypes.array,
  columns: PropTypes.array,
  columnsButton: PropTypes.bool,
  components: PropTypes.object.isRequired,
  getFieldValue: PropTypes.func.isRequired,
  localization: PropTypes.object.isRequired,
  onColumnsChanged: PropTypes.func.isRequired,
  dataManager: PropTypes.object.isRequired,
  searchText: PropTypes.string,
  onSearchChanged: PropTypes.func.isRequired,
  search: PropTypes.bool.isRequired,
  searchFieldStyle: PropTypes.object,
  searchFieldVariant: PropTypes.string,
  selectedRows: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showTitle: PropTypes.bool.isRequired,
  showTextRowsSelected: PropTypes.bool.isRequired,
  toolbarButtonAlignment: PropTypes.string.isRequired,
  searchFieldAlignment: PropTypes.string.isRequired,
  renderData: PropTypes.array,
  data: PropTypes.array,
  exportAllData: PropTypes.bool,
  exportButton: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ csv: PropTypes.bool, pdf: PropTypes.bool }),
  ]),
  exportDelimiter: PropTypes.string,
  exportFileName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  exportCsv: PropTypes.func,
  classes: PropTypes.object,
  searchAutoFocus: PropTypes.bool,
};

export const styles = (theme) => ({
  root: {
    paddingRight: theme.spacing(1),
    paddingLeft: 0,
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: '1 1 10%',
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    overflow: 'hidden',
  },
  searchField: {
    minWidth: 150,
    paddingLeft: theme.spacing(2),
  },
  formControlLabel: {
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(1),
  },
});

export default withStyles(styles)(CustomToolbar);
