import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

const CustomBetweenDatePicker = (props) => {
  const [dateStart, setDateStart] = useState(null);
  const [dateEnd, setDateEnd] = useState(new Date());

  //function getDateStartCustom() {
  //	if(props.tableRef.current != null) {
  //  	let columnIndexDateStart = props.tableRef.current.props.columns.findIndex((column) => column.field === "startDate");
  //  	let tmp = props.tableRef.current.columns[columnIndexDateStart];
  //  	setDateStart(tmp);
  //	} else {
  //		//console.log("[function getDateStartCustom] tableRef.current === null!");
  //		setDateStart(null);
  //	}
  //}

  function setDateStartCustom(event) {
    if (props.tableRef.current != null) {
      let columnIndexDateStart = props.tableRef.current.props.columns.findIndex(
        (column) => column.field === 'startDate',
      );
      //console.log("columnIndexDateStart: ", columnIndexDateStart, props.tableRef.current.props.columns);
      props.tableRef.current.onFilterChange(columnIndexDateStart, event);
      setDateStart(event);
    } else {
      //console.log("[function setDateStartCustom] tableRef.current === null!");
    }
  }

  //function getDateEndCustom() {
  //	if(props.tableRef.current != null) {
  //  	let columnIndexDateEnd = props.tableRef.current.props.columns.findIndex((column) => column.field === "endDate");
  //  	let tmp = props.tableRef.current.columns[columnIndexDateEnd];
  //  	setDateEnd(tmp);
  //	} else {
  //		//console.log("[function getDateEndCustom] tableRef.current === null!");
  //		setDateEnd(null);
  //	}
  //}
  function setDateEndCustom(event) {
    if (props.tableRef.current != null) {
      let columnIndexDateEnd = props.tableRef.current.props.columns.findIndex(
        (column) => column.field === 'endDate',
      );
      //console.log("columnIndexDateEnd: ", columnIndexDateEnd, props.tableRef.current.props.columns);
      props.tableRef.current.onFilterChange(columnIndexDateEnd, event);
      setDateEnd(event);
    } else {
      //console.log("[function setDateEndCustom] tableRef.current === null!");
    }
  }

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <Typography
        variant='h4'
        style={{
          flex: 1,
          textAlign: 'center',
          alignSelf: 'center',
          flexBasis: 'inherit',
          marginRight: '16px',
          marginLeft: '16px',
        }}
      >
        Eingetragen Zeiten von
      </Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          style={{
            flexShrink: 1,
            textAlign: 'center !important',
            fontSize: '1.2rem !important',
            justifyContent: 'center',
          }}
          disableFuture
          margin='none'
          id='date-start-picker-dialog'
          format='dd.MM.yyyy'
          clearable={true}
          value={dateStart}
          onChange={(event) => {
            setDateStartCustom(event);
          }}
        />
      </MuiPickersUtilsProvider>
      <IconButton
        aria-label='delete'
        onClick={() => {
          setDateStartCustom(null);
        }}
      >
        <ClearIcon />
      </IconButton>
      <Typography
        variant='h4'
        style={{
          flex: 1,
          textAlign: 'center',
          alignSelf: 'center',
          flexBasis: 'inherit',
          marginRight: '16px',
          marginLeft: '16px',
        }}
      >
        bis
      </Typography>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          autoOk
          style={{
            flexShrink: 1,
            textAlign: 'center !important',
            fontSize: '1.2rem !important',
            justifyContent: 'center',
          }}
          disableFuture
          margin='none'
          id='date-setDateEnd-picker-dialog'
          format='dd.MM.yyyy'
          clearable={true}
          value={dateEnd}
          onChange={(event) => {
            setDateEndCustom(event);
          }}
        />
        <IconButton
          aria-label='delete'
          onClick={() => {
            setDateEndCustom(null);
          }}
        >
          <ClearIcon />
        </IconButton>
      </MuiPickersUtilsProvider>

      <Typography
        variant='h4'
        style={{
          flex: 1,
          textAlign: 'center',
          alignSelf: 'center',
          flexBasis: 'inherit',
          marginRight: '16px',
          marginLeft: '16px',
        }}
      >
        anzeigen:
      </Typography>
    </div>
  );
};
export default CustomBetweenDatePicker;
