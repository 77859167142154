import { FETCH, COLLAPSE, COLLAPSEITEM, RESET, ADD, REMOVE } from '../actions/NavMenu';

//const initialState = { uuid: null, last_name: null, first_name: null }
const initialState = {
  collapsed: true,
  menu: [
    {
      key: 'home',
      name: 'Dashboard',
      icon: 'HomeIcon',
      path: '/dashboard',
    },
    {
      key: 'contacts',
      name: 'Kontakte',
      icon: 'ContactsIcon',
      path: '/kontakte',
    },
    {
      key: 'calendar',
      name: 'Kalender',
      icon: 'TodayIcon',
      path: '/kalender',
    },
    {
      key: 'tasks',
      name: 'Vorgänge',
      icon: 'InboxIcon',
      path: '/nachrichten',
      collapsed: false,
      submenu: [
        {
          key: 'new',
          name: 'Neu',
          icon: 'FiberNewIcon',
          path: '/nachrichten/neu',
        },
        {
          key: 'procedures',
          name: 'Meine',
          icon: 'AccountTreeIcon',
          path: '/nachrichten/meine',
        },
      ],
    },
    {
      key: 'inbox',
      name: 'Vorgänge',
      icon: 'InboxIcon',
      path: '/nachrichten',
      collapsed: false,
      submenu: [
        {
          key: 'new',
          name: 'Neu',
          icon: 'FiberNewIcon',
          path: '/nachrichten/new',
        },
        {
          key: 'procedures',
          name: 'Meine',
          icon: 'AccountTreeIcon',
          path: '/nachrichten/meine',
        },
        {
          key: 'all',
          name: 'Alle',
          icon: 'AllInclusiveIcon',
          path: '/nachrichten/alle',
        },
      ],
    },
    {
      key: 'timetracking',
      name: 'Zeiterfassung',
      icon: 'HourglassFullIcon',
      path: '/zeiterfassung',
    },
    {
      key: 'statistics',
      name: 'Aktivitätsprotokoll',
      icon: 'TimerIcon',
      path: '/statistics',
    },
  ],
};

const NavMenu = (state = initialState, action) => {
  let menu = null;
  switch (action.type) {
    case FETCH:
      if (!!action.menu) {
        state = action.menu;
      }
      return state;
    case COLLAPSE:
      return { ...state, collapsed: !state.collapsed };
    case COLLAPSEITEM:
      return { ...state };
    case ADD:
      menu = [...state.menu];
      let addIndex = menu.findIndex((element) => element.key === action.parent);
      menu[addIndex].submenu.push(action.entry);
      return { ...state, menu: menu };
    case REMOVE:
      menu = [...state.menu];
      let removeIndex = menu.findIndex((element) => element.key === action.parent);
      menu[removeIndex].submenu = menu[removeIndex].submenu.filter(
        (element) => element.key !== action.entry.key,
      );
      return { ...state, menu: menu };
    case RESET:
      return initialState;
    default:
      return state;
  }
};
export default NavMenu;
