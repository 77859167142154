import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { DropzoneArea } from 'material-ui-dropzone';
import Badge from '@material-ui/core/Badge';
import { Constants } from '../../../lib/Constants';
//import AddIcon from '@material-ui/icons/Add';
import StarIcon from '@material-ui/icons/Star';
import WorkIcon from '@material-ui/icons/Work';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState } from 'draft-js';
//import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';
import CustomToolbarComponent from '../../Inbox/components/CustomToolbarComponent';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import InputAdornment from '@material-ui/core/InputAdornment';
import Slide from '@material-ui/core/Slide';
import { DE as TextDE } from '../../../lib/Text';
import MinimizeIcon from '@material-ui/icons/Minimize';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1, 2),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    position: 'absolute',
    top: '2.5vh',
    right: '5vw',
    zIndex: 99999999,
    [theme.breakpoints.down('sm')]: {
      width: 'calc(95vw - ' + theme.spacing(3) + 'px)',
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      width: '60vw',
      padding: theme.spacing(2, 4, 3),
    },
    maxHeight: '95vh',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: '#005555',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  floatRight: {
    float: 'right',
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  newProcedure: {
    float: 'right',
    '&>span': {
      fontSize: '200%!important',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  editor: {
    padding: theme.spacing(1, 1),
    borderRadius: '2px',
    borderStyle: 'solid',
    borderColor: '#EEEEEE',
    borderWidth: '1px',
    minHeight: '200px',
    '&>div': {
      padding: theme.spacing(1, 1),
      '&>div': {
        '&>div': {
          '&>div': {
            '&>div': {
              '&>div': {
                margin: 0,
              },
            },
          },
        },
      },
    },
  },
  active: {
    borderColor: theme.palette.primary.main,
    borderWidth: '2px',
    '&>div': {},
  },
  toolbar: {
    backgroundColor: '#EEEEEE',
  },
  dropzoneSize: {
    height: 'auto',
    minHeight: '218px',
  },
  avatar: {
    width: '90px',
    height: '90px',
  },
  xs: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&>svg': {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
});

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    let qoute = '';
    if (!!props.message?.body?.text) {
      qoute = props.message.body.text;
    } else if (!!props.message?.body?.html) {
      qoute = props.message.body.html;
      qoute = qoute.replace(/<[^>]*>?/gm, '');
    }
    qoute = qoute !== '' ? '\r\nVorherige Nachricht:\r\n' + qoute.replace(/^.*/gim, '>$&') : '';

    let suggestions = [];
    props.contactSuggestions.forEach((value) => {
      value.contact.emails.forEach((email) => {
        suggestions.push({
          full: `${value.contact.salutation?.name ?? ''} ${value.contact.title?.name ?? ''} ${
            value.contact.firstname ?? ''
          } ${value.contact.name}`.trim(),
          mail: email.email,
          pref: email.pref,
          work: email.work,
          isConnectUser: !!value.contact.connect_uuid ? value.contact.uuid : null,
        });
      });
    });

    this.state = {
      createNewProcedure: !!!props.isProcedure,
      subject: props.subject || props.message?.subject || '',
      contacts: [],
      contactSuggestions: suggestions,
      isSubmitting: false,
      hasError: false,
      openModal: props.openModal || false,
      editorState: EditorState.createWithContent(ContentState.createFromText(qoute)),
      editorFocus: false,
      files: { left: [], right: [] },
      to: [],
      shareToConnect: [],
      checked: {},
      newFiles: [],
      texttemplates: [],
      minimized: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleContactChange = this.handleContactChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleMinimize = this.handleMinimize.bind(this);
    this.pushContact = this.pushContact.bind(this);
    this.handleShareToConnect = this.handleShareToConnect.bind(this);
    /*this.customOnSearchChange = this.customOnSearchChange.bind(this);*/
  }

  handleToggle = (value) => {
    let checked = this.state.checked;

    if (!!checked[value.id]) {
      // remove
      delete checked[value.id];
    } else {
      // add
      checked[value.id] = value;
    }

    this.setState({ checked: checked });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState });
  };

  handleOpen = () => {
    this.setState({ openModal: true });
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };

  handleChange = () => (event) => {
    //console.log(`${event.target.name} (${event.target.type}) : ${event.target.value} (${event.target.checked})`);
    if (typeof event === 'string') {
      this.setState({ address: event });
    } else if (event.target.type === 'checkbox') {
      this.setState({ [event.target.name]: event.target.checked });
      //dispatch({ [event.target.name]: event.target.checked });
    } else {
      this.setState({ [event.target.name]: event.target.value });
      //dispatch({ [event.target.name]: event.target.value });
    }
  };
  pushContact(contact) {
    let contacts = this.state.to ?? [];
    if (contacts.findIndex((value) => value.mail === contact.mail) === -1) {
      let shareToConnect = this.state.shareToConnect;
      if (contact.isConnectUser !== null && !shareToConnect.includes(contact.isConnectUser)) {
        shareToConnect.push(contact.isConnectUser);
        this.setState({ shareToConnect: shareToConnect });
      }
      contacts.push(contact);
      this.handleContactChange(null, contacts);
    }
  }

  handleContactChange = (event, value) => {
    let tmp = value
      .filter((element) => element.isConnectUser !== null)
      .map((element) => element.isConnectUser);
    let shareToConnect = this.state.shareToConnect;
    let difference = value.filter((x) => !this.state.to?.includes(x));

    if (difference.length > 0) {
      difference.forEach((element) => {
        if (element.isConnectUser != null) {
          shareToConnect.push(element.isConnectUser);
        }
      });
    }
    shareToConnect = shareToConnect.filter((element) => tmp.includes(element));

    //console.log('filtered shareToConnect:' , shareToConnect);

    if (value && value.inputValue) {
      this.setState({ shareToConnect: shareToConnect, to: value });
      return;
    }
    if (
      value.length > 0 &&
      stateToHTML(this.state.editorState.getCurrentContent()) === '<p><br></p>'
    ) {
      let greeting = value
        .map((contact) => {
          if (!!contact.full) {
            return contact.full;
          }
          return false;
        })
        .filter((contact) => contact !== false)
        .join(', ');
      this.setState({
        shareToConnect: shareToConnect,
        to: value,
        editorState: EditorState.createWithContent(
          ContentState.createFromText('Hallo ' + greeting + ',\n'),
        ),
      });
    } else {
      this.setState({ shareToConnect: shareToConnect, to: value });
    }
  };

  handleFileChange = async (files) => {
    this.setState({ newFiles: files });
  };

  handleMinimize = () => {
    this.setState({ minimized: !this.state.minimized });
  };

  async handleFormSubmit(event) {
    event.preventDefault();

    this.setState({ isSubmitting: true, hasError: false });

    let formData = new FormData();

    formData.append('type_id', Constants.getInboxItemTypeId('email'));
    formData.append('to', JSON.stringify(this.state.to));
    formData.append('shareToConnect', JSON.stringify(this.state.shareToConnect));
    formData.append('subject', this.state.subject);
    formData.append('body', stateToHTML(this.state.editorState.getCurrentContent()));

    Object.keys(this.state.checked).forEach((file) => {
      formData.append('attachFiles[]', JSON.stringify(this.state.checked[file]));
    });

    this.state.newFiles.forEach((file, i) => {
      formData.append('uploads[]', file, file.name);
    });
    let url = '';
    if (!!this.state.createNewProcedure) {
      url = process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid + '/newAndMail';
    } else {
      url = process.env.REACT_APP_API_URL + '/api/procedures/' + this.props.uuid + '/mail';
      formData.append('_method', 'PUT');
    }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then(function (response) {
        return response;
      })
      .catch((error) => {
        return error;
      });

    if (!!response.sent) {
      this.setState({
        subject: '',
        editorState: EditorState.createEmpty(),
        to: [],
        newFiles: [],
        isSubmitting: false,
        hasError: false,
      });
      this.props.handleClose(true);
    } else {
      if (!!response.message) {
        this.setState({ isSubmitting: false, hasError: response.message });
      } else {
        this.setState({
          isSubmitting: false,
          hasError: JSON.stringify(response),
        });
      }
    }
  }

  handleShareToConnect = (event, value) => {
    let tmp = this.state.shareToConnect || [];

    if (tmp.includes(event.target.name)) {
      tmp = tmp.filter((element) => element !== event.target.name);
    } else {
      tmp.push(event.target.name);
    }
    //console.log("new shareToConnect", tmp);
    this.setState({ shareToConnect: tmp });
  };

  componentDidMount() {
    this.setState({ to: this.props.recipients });
    fetch(process.env.REACT_APP_API_URL + '/api/inbox/' + this.props.uuid, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = json.media?.map((item, index) => {
          item.url =
            process.env.REACT_APP_API_URL +
            '/auth/file/inboxitem/' +
            this.props.uuid +
            '/' +
            item.id +
            '/' +
            item.file_name;
          item.download =
            process.env.REACT_APP_API_URL +
            '/api/file/inboxitem/' +
            this.props.uuid +
            '/' +
            item.id +
            '/' +
            item.file_name;
          return item;
        });

        if (!!json.procedure?.media) {
          tmp = tmp.concat(
            json.procedure?.media?.map((item, index) => {
              item.url =
                process.env.REACT_APP_API_URL +
                '/auth/file/procedure/' +
                this.props.uuid +
                '/' +
                item.id +
                '/' +
                item.file_name;
              item.download =
                process.env.REACT_APP_API_URL +
                '/api/file/procedure/' +
                this.props.uuid +
                '/' +
                item.id +
                '/' +
                item.file_name;
              return item;
            }),
          );
        }

        let files = { left: [], right: [] };
        for (var i = 0; i < tmp.length; i++) {
          if (i % 2 === 0) {
            files.left.push(tmp[i]);
          } else {
            files.right.push(tmp[i]);
          }
        }
        this.setState({ message: json, files: files });
      })
      .catch((error) => {
        return false;
      });

    fetch(process.env.REACT_APP_API_URL + '/api/contacts', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = [];
        for (var key in json) {
          if (!!json[key].emails) {
            if (json[key].emails.length > 1) {
              for (var email in json[key].emails) {
                if (json[key].emails[email].email.length > 2) {
                  tmp.push({
                    full: `${json[key].salutation?.name ?? ''} ${json[key].title?.name ?? ''} ${
                      json[key].firstname ?? ''
                    } ${json[key].name}`.trim(),
                    mail: json[key].emails[email].email,
                    pref: json[key].emails[email].pref,
                    work: json[key].emails[email].work,
                    isConnectUser: !!json[key].connect_uuid ? json[key].uuid : null,
                  });
                }
              }
            } else if (json[key].emails.length === 1) {
              if (json[key].emails[0].email.length > 2) {
                tmp.push({
                  full: `${json[key].salutation?.name ?? ''} ${json[key].title?.name ?? ''} ${
                    json[key].firstname ?? ''
                  } ${json[key].name}`.trim(),
                  mail: json[key].emails[0].email,
                  pref: json[key].emails[0].pref,
                  work: json[key].emails[0].work,
                  isConnectUser: !!json[key].connect_uuid ? json[key].uuid : null,
                });
              }
            }
          }
        }
        this.setState({ contacts: tmp });
      })
      .catch((error) => {
        return false;
      });
  }

  render() {
    const filter = createFilterOptions();
    const { classes } = this.props;
    return (
      <>
        <Slide
          direction='left'
          in={!this.state.minimized}
          mountOnEnter={false}
          unmountOnExit={false}
        >
          <Paper className={classes.paper}>
            <Box textAlign='center'>
              <Typography variant='h4'>
                Neue Mail
                <IconButton
                  className={classes.floatRight}
                  onClick={() => this.props.handleClose(false)}
                >
                  <CloseIcon />
                </IconButton>
                <IconButton className={classes.floatRight} onClick={this.handleMinimize}>
                  <MinimizeIcon />
                </IconButton>
              </Typography>
            </Box>

            {this.state.isSubmitting ? (
              <center>
                <CircularProgress />
              </center>
            ) : (
              <form key='EmailForm' onSubmit={this.handleFormSubmit}>
                <Grid
                  container
                  spacing={0}
                  style={{
                    width: 'calc(98% - 10px)',
                    marginLeft: 'calc(1% + 5px)',
                  }}
                >
                  <Grid item xs={12}>
                    <Autocomplete
                      id='contactselect'
                      name='uuid'
                      required
                      noOptionsText='Keine bekannte/korrekte E-Mail Adresse ...'
                      multiple
                      options={this.state.contacts}
                      renderOption={(option) => {
                        return (
                          <ListItem component='div' key={option.mail}>
                            <ListItemAvatar>
                              <Badge
                                color='primary'
                                badgeContent={option.pref ? <StarIcon className={classes.xs} /> : 0}
                                overlap='rectangular'
                                variant='standard'
                              >
                                <Avatar variant='rounded' className={classes.small}>
                                  {option.work ? <WorkIcon /> : <AlternateEmailIcon />}
                                </Avatar>
                              </Badge>
                            </ListItemAvatar>
                            <ListItemText primary={option.mail} secondary={option.full} />
                            <ListItemSecondaryAction>
                              {!!option.isConnectUser && (
                                <Tooltip title='Der Kunde kann die Nachricht via FLiXWORKER Connect einsehen'>
                                  <SettingsEthernetIcon color='primary' />
                                </Tooltip>
                              )}
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      }}
                      value={this.state.to || []}
                      onChange={this.handleContactChange}
                      disableClearable
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          label='Kontakt'
                          name='newContact'
                          helperText='An wen soll die Nachricht gehen'
                          onChange={this.handleChange}
                        />
                      )}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        // Suggest the creation of a new value
                        // eslint-disable-next-line
                        if (
                          params.inputValue !== '' &&
                          params.inputValue.match(
                            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          )
                        ) {
                          filtered.push({
                            inputValue: params.inputValue,
                            mail: params.inputValue,
                            full: `"${params.inputValue}" Hinzufügen`,
                          });
                        }

                        return filtered;
                      }}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.mail;
                      }}
                    />
                  </Grid>
                  {this.state.contactSuggestions.length > 0 ? (
                    <Grid item xs={12} className={classes.marginBottom}>
                      {this.state.contactSuggestions.map((contact, index) => (
                        <Chip
                          key={index}
                          className={classes.pointer}
                          onClick={() => this.pushContact(contact)}
                          label={contact.full + ' <' + contact.mail + '>'}
                          color='primary'
                          avatar={
                            <Avatar>
                              <AlternateEmailIcon />
                            </Avatar>
                          }
                        />
                      ))}
                    </Grid>
                  ) : null}
                  {this.state.to?.filter((element) => !!element.isConnectUser).length > 0 ? (
                    <>
                      <Grid item xs={12}>
                        <Typography>
                          {this.state.to?.filter((element) => !!element.isConnectUser).length > 1
                            ? 'Die Kontakte können '
                            : 'Der Kontakt kann '}
                          die Mail (und den Vorgang) in FLiXWORKER Connect einsehen:
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.marginBottom}>
                        {this.state.to
                          ?.filter((element) => !!element.isConnectUser)
                          .map((contact, index) => (
                            <FormControlLabel
                              key={'contact-0' + index}
                              control={
                                <Checkbox
                                  checked={this.state.shareToConnect.includes(
                                    contact.isConnectUser,
                                  )}
                                  onChange={this.handleShareToConnect}
                                  name={contact.isConnectUser}
                                />
                              }
                              label={contact.full + ' <' + contact.mail + '>'}
                            />
                          ))}
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12} className={classes.marginBottom}>
                    <TextField
                      autoFocus
                      fullWidth
                      name='subject'
                      label={TextDE.form.subject.label}
                      InputProps={
                        !!this.props.serial
                          ? {
                              startAdornment: (
                                <InputAdornment position='start'>
                                  [ID#{this.props.serial}]
                                </InputAdornment>
                              ),
                            }
                          : {}
                      }
                      autoComplete='subject'
                      value={this.state.subject}
                      onChange={this.handleChange()}
                      className={this.state.subject.trim() === '' ? classes.error : null}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Editor
                      editorState={this.state.editorState}
                      onEditorStateChange={this.onEditorStateChange}
                      wrapperClassName='wrapper-class'
                      editorClassName={clsx(classes.editor, {
                        [classes.active]: this.state.editorFocus,
                      })}
                      toolbarClassName={classes.toolbar}
                      onFocus={(event) => {
                        this.setState({ editorFocus: true });
                      }}
                      onBlur={(event) => {
                        this.setState({ editorFocus: false });
                      }}
                      toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'emoji', 'link'],
                        inline: {
                          options: ['bold', 'italic', 'underline'],
                          bold: { className: 'bordered-option-classname' },
                          italic: { className: 'bordered-option-classname' },
                          underline: { className: 'bordered-option-classname' },
                          strikethrough: {
                            className: 'bordered-option-classname',
                          },
                        },
                        blockType: {
                          options: ['Normal', 'H1'],
                          className: 'bordered-option-classname',
                        },
                        fontSize: {
                          options: [10, 12, 16, 20],
                          className: 'bordered-option-classname',
                        },
                        fontFamily: {
                          className: 'bordered-option-classname',
                        },
                        link: {
                          className: 'bordered-option-classname',
                        },
                        emoji: {
                          className: 'bordered-option-classname',
                        },
                      }}
                      toolbarCustomButtons={[
                        <CustomToolbarComponent onChange={this.onEditorStateChange} />,
                      ]}
                    />
                    <TextField
                      style={{ marginLeft: '1em' }}
                      inputProps={{ style: { color: 'black' } }}
                      disabled
                      multiline
                      fullWidth
                      value={
                        'Mit freundlichen Grüßen,\r\n' +
                        this.props.User.firstname +
                        ' ' +
                        this.props.User.lastname +
                        '\r\n\r\n--\r\n' +
                        (!!this.props.User.customer.company?.name
                          ? this.props.User.customer.company?.name
                          : this.props.User.firstname + ' ' + this.props.User.lastname) +
                        (!!this.props.User.customer.company?.address
                          ? '\r\n' +
                            this.props.User.customer.company?.address?.route +
                            ' ' +
                            this.props.User.customer.company?.address?.street_number +
                            ', ' +
                            this.props.User.customer.company?.address?.postal_code +
                            ' ' +
                            this.props.User.customer.company?.address?.city?.locality +
                            '\r\n'
                          : '') +
                        (!!this.props.User.customer.company?.phone
                          ? '\r\nTelefon:\t' + this.props.User.customer.company?.phone
                          : !!this.props.User.phone
                          ? '\r\nTelefon:\t' + this.props.User.phone
                          : '') +
                        (!!this.props.User.customer.company?.email
                          ? '\r\nE-Mail:\t' + this.props.User.customer.company?.email
                          : '\r\nE-Mail:\t' + this.props.User.email)
                      }
                    />
                    <Typography variant='subtitle1' color='textSecondary'>
                      {' '}
                      Diese Signatur wird automatisch an das Ende Ihrer Nachricht angefügt.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Hidden smDown>
                      <DropzoneArea
                        dropzoneText='Datei hier ablegen, oder klicken.'
                        showPreviewsInDropzone={true}
                        useChipsForPreview={false}
                        showFileNames={true}
                        showAlerts={true}
                        onChange={this.handleFileChange}
                        maxFileSize={30000000}
                        filesLimit={8}
                      />
                    </Hidden>
                    <Hidden mdUp>
                      <DropzoneArea
                        dropzoneText='Zum auswählen hier klicken.'
                        showPreviewsInDropzone={true}
                        useChipsForPreview={true}
                        previewChipProps={{ style: { maxWidth: '98%' } }}
                        showFileNames={true}
                        showAlerts={true}
                        onChange={this.handleFileChange}
                        maxFileSize={30000000}
                        filesLimit={8}
                        dropzoneClass='small'
                      />
                    </Hidden>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <List dense>
                      {this.state.files.left.map((item, index) => {
                        return (
                          <ListItem key={item.id} button onClick={() => this.handleToggle(item)}>
                            <ListItemAvatar>
                              <Avatar
                                className={classes.avatar}
                                variant='square'
                                alt={item.file_name}
                                src={item.url + '?thumb&download'}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { padding: '8px' },
                                noWrap: true,
                                variant: 'h6',
                                component: 'p',
                              }}
                              primary={item.file_name}
                              secondaryTypographyProps={{
                                align: 'right',
                                noWrap: true,
                                component: 'p',
                              }}
                              secondary={String(item.size / (1024 * 1024)).substring(0, 4) + ' Mb'}
                            />
                            <ListItemSecondaryAction>
                              <Checkbox
                                onClick={() => this.handleToggle(item)}
                                edge='end'
                                checked={!!this.state.checked[item.id]}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <List dense>
                      {this.state.files.right.map((item, index) => {
                        return (
                          <ListItem key={item.id} button onClick={() => this.handleToggle(item)}>
                            <ListItemAvatar>
                              <Avatar
                                className={classes.avatar}
                                variant='square'
                                alt={item.file_name}
                                src={item.url + '?thumb&download'}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primaryTypographyProps={{
                                style: { padding: '8px' },
                                noWrap: true,
                                variant: 'h6',
                                component: 'p',
                              }}
                              primary={item.file_name}
                              secondaryTypographyProps={{
                                align: 'right',
                                noWrap: true,
                                component: 'p',
                              }}
                              secondary={String(item.size / (1024 * 1024)).substring(0, 4) + ' Mb'}
                            />
                            <ListItemSecondaryAction>
                              <Checkbox
                                onClick={() => this.handleToggle(item)}
                                edge='end'
                                checked={!!this.state.checked[item.id]}
                              />
                            </ListItemSecondaryAction>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  {!!this.props.isProcedure === false ? (
                    <Grid item xs={12}>
                      <FormControlLabel
                        className={classes.newProcedure}
                        control={
                          <Checkbox
                            checked={this.state.createNewProcedure}
                            onChange={this.handleChange()}
                            name='createNewProcedure'
                          />
                        }
                        label='Einen Neuen Vorgang erstellen'
                        disabled
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={4}>
                    <div className={classes.wrapper}>
                      <Button
                        onClick={() => this.props.handleClose(false)}
                        variant='outlined'
                        color='secondary'
                        fullWidth
                      >
                        Schließen
                      </Button>
                    </div>
                  </Grid>

                  <Grid item xs={8}>
                    {!!this.state.hasError ? (
                      <Alert severity='error' style={{ width: '100%' }}>
                        <AlertTitle>{this.state.hasError}</AlertTitle>
                        <Typography variant='caption'>
                          {' '}
                          Die Mail konnte nicht versandt werden...{' '}
                        </Typography>
                      </Alert>
                    ) : (
                      ''
                    )}
                    <div className={classes.wrapper}>
                      <Button
                        type='submit'
                        variant='contained'
                        color='primary'
                        className={classes.submit}
                        disabled={!(this.state.to?.length > 0 && this.state.subject !== '')}
                        fullWidth
                      >
                        Senden
                      </Button>
                      {this.state.isSubmitting && (
                        <CircularProgress size={24} className={classes.buttonProgress} />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </form>
            )}
          </Paper>
        </Slide>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(EmailForm);
