import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import IconButton from '@material-ui/core/IconButton';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import Menu from '@material-ui/core/Menu';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { DE as TextDE } from '../../../../lib/Text';

const customHeight = 64;

const styles = (theme) => ({
  avatarGroupWrap: {
    minWidth: customHeight + 'px!important',
    height: customHeight + 'px!important',
    marginLeft: '-' + customHeight * 0.25 + 'px!important',
    '&:last-child': {
      marginLeft: '-' + customHeight * 0.4 + 'px!important',
    },
    '&:first-child': {
      marginLeft: '0px!important',
    },
  },
  avatarIcon: {
    height: customHeight * 0.6 + 'px!important',
    borderColor: theme.palette.primary.main,
    width: customHeight + 'px!important',
    transform: 'scaleX(-1)',
  },
  avatarWrap: {
    height: 'inherit',
    width: 'auto',
  },
  alignEnd: {
    justifyContent: 'end',
  },
  tooltipWrap: {
    height: 'inherit',
    width: 'auto',
  },
  noPad: {
    padding: '0!important',
  },
});

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      selectedUsers: [],
      userMenu: false,
    };

    this.submitData = this.submitData.bind(this);
    this.handleUser = this.handleUser.bind(this);
  }

  componentDidMount() {
    // diddndunuffin
  }

  openUserMenu = (event) => {
    if (!!this.props.disableEdit) {
      return;
    }

    this.setState({
      userMenu: [event.clientX - 2, event.clientY - 4],
    });
  };

  closeUserMenu = (event) => {
    this.setState({
      userMenu: false,
    });
  };

  handleUser = (user) => {
    let formData = {};
    let isCurrentUser = this.props.users.find((userWrap) => userWrap.user.uuid === user.uuid);
    if (!!isCurrentUser) {
      //remove User
      formData['_method'] = 'DELETE';
    } else {
      // Add User
      formData['_method'] = 'PUT';
      formData['tags'] = user.roles;
    }

    formData['user'] = user.uuid;
    formData['context'] = 'users';
    formData['uuid'] = this.props.jobUuid;
    this.submitData(formData);
  };

  submitData = async (formData) => {
    let endpoint = process.env.REACT_APP_API_URL;
    endpoint = endpoint + '/api/jobs/' + formData.uuid + '/' + formData.context;

    let response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(function (response) {
        //console.log(response);
        return true;
      })
      .catch((error) => false);

    if (!!response) {
      this.props.callback();
      this.closeUserMenu();
    }
  };

  render() {
    const { classes } = this.props;

    if (!!this.props.userIsFetching) {
      return (
        <Box>
          <center>
            <CircularProgress />
          </center>
        </Box>
      );
    }
    return (
      <Box>
        <AvatarGroup
          max={7}
          classes={{ avatar: classes.avatarGroupWrap, root: classes.alignEnd }}
          onClick={this.openUserMenu}
        >
          {this.props.users.map((userWrap) => (
            <Tooltip
              key={userWrap.user.uuid}
              title={`${userWrap.user.firstname} ${userWrap.user.lastname}`}
              className={classes.tooltipWrap}
            >
              <Avatar
                alt={`${userWrap.user.firstname} ${userWrap.user.lastname}`}
                src={process.env.REACT_APP_API_URL + '/avatar/' + userWrap.user.uuid + '?thumb'}
                classes={{ root: classes.avatarWrap }}
              />
            </Tooltip>
          ))}
          {!!!this.props.disableEdit && (
            <Tooltip
              key='edit-button'
              title={TextDE.workorder.coworkerText}
              className={classes.tooltipWrap}
            >
              <IconButton classes={{ root: classes.noPad }}>
                <GroupAddIcon color='primary' className={classes.avatarIcon} />
              </IconButton>
            </Tooltip>
          )}
        </AvatarGroup>

        <Menu
          id='add-menu'
          open={Boolean(this.state.userMenu)}
          onClose={this.closeUserMenu}
          keepMounted={true}
          anchorReference='anchorPosition'
          anchorPosition={
            this.state.userMenu !== false && this.state.userMenu.length === 2
              ? { top: this.state.userMenu[1], left: this.state.userMenu[0] }
              : undefined
          }
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            style: {
              maxWidth: '90ch',
              minWidth: '30ch',
              maxHeight: '30em',
            },
          }}
        >
          <Typography variant='h5' style={{ margin: '8px 8px 12px 8px' }}>
            {TextDE.workorder.coworkersLabel}
          </Typography>
          {this.props.usersAll?.map((option) => (
            <ListItem
              ContainerComponent='div'
              ContainerProps={{ style: { width: '100%' } }}
              key={option.uuid}
            >
              <ListItemAvatar>
                <Avatar
                  alt={option.firstname + ' ' + option.lastname}
                  src={process.env.REACT_APP_API_URL + '/avatar/' + option.uuid + '?thumb'}
                />
              </ListItemAvatar>
              <ListItemText
                secondaryTypographyProps={{ component: 'div' }}
                primary={option.firstname + ' ' + option.lastname}
                secondary={option.roles.map((value, index) => (
                  <Chip key={index} label={value} />
                ))}
              />
              <ListItemSecondaryAction>
                <IconButton onClick={() => this.handleUser(option)}>
                  {this.props.users.map((user) => user.user.uuid).includes(option.uuid) ? (
                    <CheckBoxIcon color='primary' />
                  ) : (
                    <CheckBoxOutlineBlankIcon color='error' />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </Menu>
      </Box>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Router: state.router,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(Users);
