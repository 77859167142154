import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
//import { ThemeProvider } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import AddIcon from '@material-ui/icons/Add';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import WorkIcon from '@material-ui/icons/Work';
import DescriptionIcon from '@material-ui/icons/Description';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import DeleteIcon from '@material-ui/icons/Delete';
import Avatar from '@material-ui/core/Avatar';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PhoneIcon from '@material-ui/icons/Phone';
import StarIcon from '@material-ui/icons/Star';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import HttpIcon from '@material-ui/icons/Http';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import UpdateIcon from '@material-ui/icons/Update';
import ShareIcon from '@material-ui/icons/Share';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import AddValue from './components/AddValue';
import clsx from 'clsx';
import Constants from '../../lib/Constants';
import { readable } from '../../lib/dateFunctions';
import Tooltip from '@material-ui/core/Tooltip';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
//import Tooltip from '@material-ui/core/Tooltip';
//import updateURLParameter from '../../lib/updateURLParameter';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import AddMail from '../Inbox/AddMail';

var vCard = function () {
  var start = 'BEGIN:VCARD\nVERSION:3.0';
  var end = 'END:VCARD';
  var data = '';

  var init = function () {
    data = '';
  };

  var name = function (surname, lastname, title) {
    data += 'N:' + lastname + ';' + surname + ';;' + title;
    data += '\n';
  };

  var tel = function (number, pref, work, fax, cell) {
    let params = [];
    !!work && params.push('WORK,VOICE');
    !!fax && params.push('FAX');
    !!cell && params.push('CELL');
    !!pref && params.push('PREF');
    if (params.length > 0) {
      data += 'TEL;TYPE=' + params.join(',') + ':' + number;
    } else {
      data += 'TEL;TYPE=OTHER:' + number;
    }
    data += '\n';
  };

  var email = function (email, pref) {
    data += 'EMAIL;TYPE=' + (!!pref ? 'PREF,INTERNET' : 'INTERNET') + ':' + email;
    data += '\n';
  };

  var address = function (street, number, city, zip, country) {
    data += 'ADR;TYPE=home:;;' + street + ' ' + number + ';' + city + ';;' + zip + ';' + country;
    data += '\n';
  };
  var company = function (company) {
    data += 'ORG:' + company + ';;';
    data += '\n';
  };

  var get = function () {
    return start + '\n' + data + end;
  };

  return {
    init: init,
    name: name,
    tel: tel,
    email: email,
    address: address,
    company: company,
    get: get,
  };
};

const styles = (theme) => ({
  height: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  align: {
    display: 'flex',
    alignItems: 'center',
    '& > b': {
      margin: theme.spacing(0, 2, 0, 1),
    },
  },
  alignInset: {
    display: 'block',
    alignItems: 'center',
    margin: theme.spacing(0, 0, 2, 4),
    fontSize: '138%',
  },
  icon: {
    paddingTop: '3px',
    width: theme.typography.subtitle1.fontSize,
    height: theme.typography.subtitle1.fontSize,
  },
  xs: {
    width: theme.spacing(1.8),
    height: theme.spacing(1.8),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&>svg': {
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    '&>svg': {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  padding: {
    padding: theme.spacing(0, 2),
  },
  deleted: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  },
  fullWidth: {
    width: '100%',
  },
  chipColumn: {
    width: '100%',
    minHeight: '56px',
  },
  listAction: {
    right: 0,
  },
  changeButton: {
    float: 'right',
  },
  deleteButton: {
    float: 'right',
    marginLeft: '8px',
  },
  button: {
    margin: theme.spacing(0, 2, 2, 4),
  },
  addButton: {
    margin: theme.spacing(0, 2, 2, 4),
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.warning.contrastText,
  },
  updated: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
});

class ContactDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contact: this.props.contact,
      isFetching: true,
      submitting: false,
      error: false,
      nameEdit: false,
      tagsEdit: false,
      companyEdit: false,
      companyAdd: false,
      addressEdit: false,
      addressAdd: false,
      notesEdit: false,
      notesAdd: false,
      numbersEdit: false,
      numberAdd: false,
      mailEdit: false,
      mailAdd: false,
      webpagesEdit: false,
      webpageAdd: false,
      mailDialogOpen: false,
      toDelete: false,
    };

    this.deleteDialog = this.deleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.delete = this.delete.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.addCallback = this.addCallback.bind(this);
    this.editCallback = this.editCallback.bind(this);
    this.generateVcard = this.generateVcard.bind(this);
    this.downloadVcard = this.downloadVcard.bind(this);
    this.shareContact = this.shareContact.bind(this);
    this.handleMailDialogClose = this.handleMailDialogClose.bind(this);
    this.handleMailDialogOpen = this.handleMailDialogOpen.bind(this);
  }

  componentDidMount() {
    if (this.props.reload) {
      fetch(process.env.REACT_APP_API_URL + '/api/contacts/' + this.state.contact.uuid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          this.setState({ contact: json, isFetching: false });
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    } else {
      this.setState({ isFetching: false });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    //console.log("componentDidUpdate Props ", `!(${this.props.contact.uuid} === ${prevProps.contact.uuid}) : ` , !(this.props.contact.uuid === prevProps.contact.uuid));
    //console.log("componentDidUpdate State ", `!(${this.state.contact.uuid} === ${prevState.contact.uuid}) : ` , !(this.state.contact.uuid === prevState.contact.uuid));

    if (!(this.props.contact?.uuid === prevProps.contact?.uuid)) {
      this.setState({ isFetching: true });
      fetch(process.env.REACT_APP_API_URL + '/api/contacts/' + this.props.contact.uuid, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          this.setState({
            contact: json,
            isFetching: false,
            submitting: false,
            error: false,
            nameEdit: false,
            tagsEdit: false,
            companyEdit: false,
            companyAdd: false,
            addressEdit: false,
            addressAdd: false,
            notesEdit: false,
            notesAdd: false,
            numbersEdit: false,
            numberAdd: false,
            mailEdit: false,
            mailAdd: false,
            webpagesEdit: false,
            webpageAdd: false,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            contact: this.props.contact,
            isFetching: false,
            submitting: false,
            error: false,
            nameEdit: false,
            tagsEdit: false,
            companyEdit: false,
            companyAdd: false,
            addressEdit: false,
            addressAdd: false,
            notesEdit: false,
            notesAdd: false,
            numbersEdit: false,
            numberAdd: false,
            mailEdit: false,
            mailAdd: false,
            webpagesEdit: false,
            webpageAdd: false,
          });
          return false;
        });
    }
  }

  shareContact() {
    // WEBSHARE API TEST
    if (navigator.share) {
      navigator
        .share({
          title: `Kontakt: ${this.state.contact.title.name || ''} ${
            this.state.contact.firstname || ''
          } ${this.state.contact.name || ''}`,
          //url: '/kontakte?uuid='+this.state.contact.uuid,
          files: [this.generateVcard],
          text: `Hier der Kontakt von ${this.state.contact.title.name || ''} ${
            this.state.contact.firstname || ''
          } ${this.state.contact.name || ''}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    } else {
      console.error('WebShare API Not Supported');
    }
  }

  generateVcard() {
    let vcard = vCard();
    vcard.name(
      this.state.contact.name || '',
      this.state.contact.firstname || '',
      this.state.contact.title.name || '',
    );
    if (this.state.contact.phones.length > 0) {
      this.state.contact.phones.forEach((item) => {
        vcard.tel(item.number, item.pref, item.work, item.fax, item.cell);
      });
    }

    if (this.state.contact.emails.length > 0) {
      this.state.contact.emails.forEach((item) => {
        vcard.email(item.email, item.pref);
      });
    }

    if (!!this.state.contact.address) {
      vcard.address(
        this.state.contact.address.route,
        this.state.contact.address.street_number,
        this.state.contact.address.city.locality,
        this.state.contact.address.postal_code,
        this.state.contact.address.city.country,
      );
    }

    if (!!this.state.contact.company && !!this.state.contact.company.name) {
      vcard.company(this.state.contact.company.name);
    }

    return new Blob([vcard.get()], { type: 'text/vcard' });
  }

  downloadVcard() {
    let data = this.generateVcard();
    let a = document.createElement('a');
    a.href = URL.createObjectURL(data);
    a.setAttribute(
      'download',
      this.state.contact.firstname + '_' + this.state.contact.name + '.vcf',
    );
    a.click();
  }

  reset() {
    this.setState({
      contact: this.props.contact,
      isFetching: false,
      submitting: false,
      error: false,
      nameEdit: false,
      tagsEdit: false,
      companyEdit: false,
      companyAdd: false,
      addressEdit: false,
      addressAdd: false,
      notesEdit: false,
      notesAdd: false,
      numbersEdit: false,
      numberAdd: false,
      mailEdit: false,
      mailAdd: false,
      webpagesEdit: false,
      webpageAdd: false,
      toDelete: false,
    });
  }
  deleteDialog(contact) {
    if (!!contact.uuid && typeof contact.uuid === 'string') {
      this.setState({ toDelete: { uuid: contact.uuid, id: contact.id } });
    }
  }
  closeDeleteDialog() {
    this.setState({ toDelete: false });
  }
  delete() {
    if (!!this.state.toDelete.uuid && typeof this.state.toDelete.uuid === 'string') {
      fetch(process.env.REACT_APP_API_URL + '/api/contacts/' + this.state.toDelete.uuid, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.props.Authentication.access_token}`,
        },
        body: JSON.stringify({
          _method: 'delete',
          uuid: this.state.toDelete.uuid,
          id: this.state.toDelete.id,
        }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw res;
          }
        })
        .then((json) => {
          this.props.deleteCallback(this.state.toDelete);
        })
        .catch((error) => {
          console.error(error);
          return false;
        });
    }
  }

  deleteItem(type, id) {
    let endpoint = process.env.REACT_APP_API_URL + '/api/contacts/' + this.state.contact.uuid;
    let formData = {
      _method: 'delete',
      uuid: this.state.contact.uuid,
      id: this.state.contact.id,
      item: id,
    };

    switch (type) {
      case 'company':
        endpoint = endpoint + '/company';
        break;
      case 'address':
        endpoint = endpoint + '/address';
        break;
      case 'notes':
        break;
      case 'number':
        endpoint = endpoint + '/number';
        break;
      case 'mail':
        endpoint = endpoint + '/mail';
        break;
      case 'webpages':
        endpoint = endpoint + '/webpages';
        break;
      default:
        break;
    }

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        this.props.deleteItemCallback(this.state.contact);
      })
      .catch((error) => {
        console.error(error);
        return false;
      });
  }

  addCallback(type) {
    let tmp = this.state;
    switch (type) {
      case 'company':
        tmp.companyAdd = false;
        break;
      case 'address':
        tmp.addressAdd = false;
        break;
      case 'notes':
        tmp.notesAdd = false;
        break;
      case 'number':
        tmp.numberAdd = false;
        break;
      case 'mail':
        tmp.mailAdd = false;
        break;
      case 'webpages':
        tmp.webpageAdd = false;
        break;
      default:
        break;
    }
    this.setState({ tmp });
    this.props.addItemCallback(this.state.contact);
  }

  editCallback(type) {
    let tmp = this.state;
    switch (type) {
      case 'company':
        tmp.companyEdit = false;
        break;
      case 'address':
        tmp.addressEdit = false;
        break;
      case 'notes':
        tmp.notesEdit = false;
        break;
      case 'number':
        tmp.numberEdit = false;
        break;
      case 'mail':
        tmp.mailEdit = false;
        break;
      case 'webpages':
        tmp.webpageEdit = false;
        break;
      case 'name':
        tmp.nameEdit = false;
        break;
      case 'tags':
        tmp.tagsEdit = false;
        break;
      default:
        break;
    }
    this.setState({ tmp });
    this.props.addItemCallback(this.state.contact);
  }

  handleMailDialogClose(reload) {
    //if (!!reload) {
    //
    //} else {
    //
    //}
    this.setState({ mailDialogOpen: false });
  }

  handleMailDialogOpen(email) {
    console.log(email, this.state.contact);
    this.setState({ mailDialogOpen: true, clickedMail: [email] });
  }
  render() {
    const { classes } = this.props;
    let mayDelete = Constants.checkUserRolesAndPermissions(
      this.props.User,
      [Constants.roles.ROLES_ADMIN, Constants.roles.ROLES_ORGANIZER],
      [Constants.permissions.PERM_CONTACT_CHANGE],
    );
    let mayEdit = mayDelete;
    if (!mayDelete) {
      mayEdit = Constants.checkUserRolesAndPermissions(
        this.props.User,
        [Constants.roles.ROLES_ADMIN, Constants.roles.ROLES_ORGANIZER],
        [Constants.permissions.PERM_CONTACT_CHANGE],
      );
    }
    if (this.state.isFetching) {
      return (
        <center>
          <CircularProgress />
        </center>
      );
    }

    if (this.state.contact.deleted_at !== null) {
      mayDelete = false;
      mayEdit = false;
    }

    return (
      <>
        <Dialog open={!!this.state.toDelete} onClose={this.closeDeleteDialog}>
          <DialogTitle>Löschen?</DialogTitle>
          <DialogContent>
            <DialogContentText>Den Kontakt sicher löschen?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={this.closeDeleteDialog} color='primary'>
              Abbrechen
            </Button>
            <Button variant='contained' onClick={this.delete} color='secondary'>
              LÖSCHEN
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={false}
          fullWidth={true}
          maxWidth='lg'
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              this.handleMailDialogClose(false);
            }
          }}
          open={this.state.mailDialogOpen}
        >
          <AddMail
            handleclosefunction={(reload) => this.handleMailDialogClose(reload)}
            message={{
              body: {
                text:
                  'Hallo ' +
                  (
                    (!!this.state.contact.salutation?.name
                      ? this.state.contact.salutation?.name + ' '
                      : '') +
                    (!!this.state.contact.title?.name ? this.state.contact.title?.name + ' ' : '') +
                    (this.state.contact.salutation_id !== 4
                      ? this.state.contact.firstname + ' '
                      : '') +
                    (!!this.state.contact.name ? this.state.contact.name + ' ' : '')
                  ).trim() +
                  ',\n',
              },
            }}
            email={this.state.clickedMail}
          />
        </Dialog>
        <Card
          className={clsx(classes.height, {
            [classes.deleted]: !!this.state.contact.deleted_at,
          })}
          raised={true}
        >
          <CardHeader
            disableTypography={false}
            avatar={
              <Avatar aria-label='recipe' className={classes.avatar}>
                {this.state.contact.salutation_id === 4 ? <WorkIcon /> : <PersonOutlineIcon />}
              </Avatar>
            }
            action={
              <>
                {!!this.state.contact.connect_uuid && (
                  <Tooltip title='Ist auf FLiXWORKER Connect'>
                    <IconButton
                      color='primary'
                      disableRipple
                      aria-label='Ist auf FLiXWORKER Connect'
                    >
                      <SettingsEthernetIcon />
                    </IconButton>
                  </Tooltip>
                )}
                {mayDelete && (
                  <IconButton
                    aria-label='DeleteOutlineIcon'
                    onClick={() => this.deleteDialog(this.state.contact)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}

                <IconButton onClick={this.props.close} aria-label='ClearIcon'>
                  <ClearIcon />
                </IconButton>
              </>
            }
            title='&nbsp;'
            subheader={this.state.contact.salutation_id === 4 ? 'Firma' : 'Person'}
          />

          <CardContent className={classes.padding}>
            <>
              {this.state.nameEdit ? (
                <AddValue
                  contact={this.state.contact}
                  callback={this.editCallback}
                  edit={this.state.contact.name}
                  type={'name'}
                />
              ) : (
                <>
                  <Typography variant='h3' gutterBottom>
                    {this.state.contact.salutation?.name} {this.state.contact.title?.name}{' '}
                    {this.state.contact.firstname} {this.state.contact.name}
                    {mayEdit && (
                      <Button
                        variant='contained'
                        size='small'
                        className={classes.changeButton}
                        onClick={() => this.setState({ nameEdit: true })}
                      >
                        <EditIcon />
                        <Hidden smDown>&nbsp; Ändern</Hidden>
                      </Button>
                    )}
                  </Typography>
                </>
              )}
              <>
                {this.state.tagsEdit ? (
                  <AddValue
                    contact={this.state.contact}
                    callback={this.editCallback}
                    edit={this.state.contact.tags}
                    type={'tags'}
                  />
                ) : (
                  <div className={classes.chipColumn}>
                    {this.state.contact.tags.map((item, i) => (
                      <Chip key={i} variant='outlined' label={item?.name?.de} />
                    ))}
                    {mayEdit && (
                      <Button
                        variant='contained'
                        size='small'
                        className={classes.changeButton}
                        onClick={() => this.setState({ tagsEdit: true })}
                      >
                        <EditIcon />
                        <Hidden smDown>&nbsp; Schlagworte ändern</Hidden>
                      </Button>
                    )}
                  </div>
                )}
                {!!this.state.contact.deleted_at && (
                  <Chip label='Bereits gelöscht - nur noch Anzeige möglich' />
                )}
              </>
              <Typography className={classes.align} variant='subtitle1'>
                <WorkIcon />
                <b>Firma:</b>
              </Typography>
              {!!this.state.contact.company ? (
                this.state.companyEdit ? (
                  <AddValue
                    contact={this.state.contact}
                    callback={this.editCallback}
                    edit={this.state.contact.company}
                    type={'company'}
                  />
                ) : (
                  <>
                    <Typography
                      className={classes.alignInset}
                      variant='body1'
                      gutterBottom
                      paragraph
                    >
                      {this.state.contact.company?.name}

                      {mayDelete && (
                        <Button
                          variant='outlined'
                          size='small'
                          className={classes.deleteButton}
                          onClick={() => this.deleteItem('company', this.state.contact.company.id)}
                        >
                          <DeleteIcon />
                          <Hidden smDown>&nbsp; Entfernen</Hidden>
                        </Button>
                      )}

                      {mayEdit && (
                        <Button
                          variant='contained'
                          size='small'
                          className={classes.changeButton}
                          onClick={() => this.setState({ companyEdit: true })}
                        >
                          <EditIcon />
                          <Hidden smDown>&nbsp; Ändern</Hidden>
                        </Button>
                      )}
                    </Typography>
                  </>
                )
              ) : !this.state.companyAdd && mayEdit ? (
                <Button
                  variant='contained'
                  size='small'
                  className={classes.addButton}
                  onClick={() => this.setState({ companyAdd: true })}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Hinzufügen{' '}
                </Button>
              ) : null}
              {this.state.companyAdd && mayEdit ? (
                <AddValue
                  contact={this.state.contact}
                  callback={this.addCallback}
                  type={'company'}
                />
              ) : null}
            </>

            <>
              <Typography className={classes.align} variant='subtitle1'>
                <LocationOnIcon />
                <b>Anschrift:</b>
              </Typography>

              {!!this.state.contact.address ? (
                this.state.addressEdit ? (
                  <AddValue
                    contact={this.state.contact}
                    callback={this.editCallback}
                    edit={`${this.state.contact.address?.route} ${this.state.contact.address?.street_number} , ${this.state.contact.address?.postal_code} ${this.state.contact.address?.city?.locality} , ${this.state.contact.address?.city?.state} ${this.state.contact.address?.city?.country} `}
                    type={'address'}
                  />
                ) : (
                  <>
                    <Typography
                      className={classes.alignInset}
                      variant='body1'
                      gutterBottom
                      paragraph
                    >
                      <Link
                        color='inherit'
                        target='_blank'
                        rel='noreferrer'
                        href={`https://www.google.de/maps/place/${this.state.contact.address?.route} ${this.state.contact.address?.street_number} , ${this.state.contact.address?.postal_code} ${this.state.contact.address?.city?.locality} , ${this.state.contact.address?.city?.state} ${this.state.contact.address?.city?.country}`}
                      >
                        {this.state.contact.address?.route}{' '}
                        {this.state.contact.address?.street_number} ,{' '}
                        {this.state.contact.address?.postal_code}{' '}
                        {this.state.contact.address?.city?.locality} ,{' '}
                        {this.state.contact.address?.city?.state}{' '}
                        {this.state.contact.address?.city?.country}
                      </Link>
                      {mayDelete && (
                        <Button
                          variant='outlined'
                          size='small'
                          className={classes.deleteButton}
                          onClick={() => this.deleteItem('address', this.state.contact.address.id)}
                        >
                          <DeleteIcon />
                          <Hidden smDown>&nbsp; Entfernen</Hidden>
                        </Button>
                      )}

                      {mayEdit && (
                        <Button
                          variant='contained'
                          size='small'
                          className={classes.changeButton}
                          onClick={() => this.setState({ addressEdit: true })}
                        >
                          <EditIcon />
                          <Hidden smDown>&nbsp; Ändern</Hidden>
                        </Button>
                      )}
                    </Typography>
                  </>
                )
              ) : !this.state.addressAdd && mayEdit ? (
                <Button
                  variant='contained'
                  size='small'
                  className={classes.addButton}
                  onClick={() => this.setState({ addressAdd: true })}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Hinzufügen{' '}
                </Button>
              ) : null}
              {this.state.addressAdd && mayEdit ? (
                <AddValue
                  contact={this.state.contact}
                  callback={this.addCallback}
                  type={'address'}
                />
              ) : null}
            </>

            <>
              <Typography className={classes.align} variant='subtitle1' gutterBottom>
                <EditIcon />
                <b>Notizen:</b>
              </Typography>

              {!!this.state.contact.notes ? (
                this.state.notesEdit ? (
                  <AddValue
                    contact={this.state.contact}
                    callback={this.editCallback}
                    edit={this.state.contact.notes}
                    type={'notes'}
                  />
                ) : (
                  <>
                    <Typography
                      className={classes.alignInset}
                      variant='body1'
                      gutterBottom
                      paragraph
                    >
                      {this.state.contact.notes}
                      {mayEdit && (
                        <Button
                          variant='contained'
                          size='small'
                          className={classes.changeButton}
                          onClick={() => this.setState({ notesEdit: true })}
                        >
                          <EditIcon />
                          <Hidden smDown>&nbsp; Ändern</Hidden>
                        </Button>
                      )}
                    </Typography>
                  </>
                )
              ) : !this.state.notesAdd && mayEdit ? (
                <Button
                  variant='contained'
                  size='small'
                  className={classes.addButton}
                  onClick={() => this.setState({ notesAdd: true })}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Hinzufügen{' '}
                </Button>
              ) : null}
              {this.state.notesAdd && mayEdit ? (
                <AddValue contact={this.state.contact} callback={this.addCallback} type={'notes'} />
              ) : null}
            </>

            <>
              <Typography className={classes.align} variant='subtitle1'>
                <PhoneIcon />
                <b>Nummern:</b>
              </Typography>
              {this.state.contact.phones.length > 0 ? (
                <List>
                  {this.state.contact.phones.map((item, key) => (
                    <ListItem
                      key={'tel-' + key}
                      button
                      component='a'
                      href={'tel:' + item.number}
                      disableGutters
                    >
                      <ListItemAvatar>
                        <Badge
                          color='primary'
                          badgeContent={item.pref ? <StarIcon className={classes.xs} /> : 0}
                          variant='standard'
                          overlap='rectangular'
                        >
                          <Avatar variant='rounded' className={classes.small}>
                            {item.work ? <WorkIcon /> : null}
                            {item.cell ? (
                              <PhoneAndroidIcon />
                            ) : item.fax ? (
                              <DescriptionIcon />
                            ) : (
                              <PhoneIcon />
                            )}
                          </Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        style={{ fontSize: '146%' }}
                        primary={item.number}
                      />
                      <ListItemSecondaryAction className={classes.listAction}>
                        {mayDelete && (
                          <Button
                            variant='outlined'
                            size='small'
                            className={classes.deleteButton}
                            onClick={() => this.deleteItem('number', item.id)}
                          >
                            <DeleteIcon />
                            <Hidden smDown>&nbsp; Entfernen</Hidden>
                          </Button>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : null}
              {!this.state.numberAdd && mayEdit ? (
                <Button
                  variant='contained'
                  size='small'
                  className={classes.addButton}
                  onClick={() => this.setState({ numberAdd: true })}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Hinzufügen{' '}
                </Button>
              ) : null}
              {this.state.numberAdd && mayEdit ? (
                <AddValue
                  contact={this.state.contact}
                  callback={this.addCallback}
                  type={'numbers'}
                />
              ) : null}{' '}
            </>
            <>
              <Typography className={classes.align} variant='subtitle1'>
                <AlternateEmailIcon />
                <b>Email:</b>
              </Typography>
              {this.state.contact.emails.length > 0 ? (
                <List>
                  {this.state.contact.emails.map((item, key) => (
                    <ListItem
                      key={'mail-' + key}
                      button
                      component='a'
                      onClick={() => this.handleMailDialogOpen(item.email)}
                    >
                      <ListItemAvatar>
                        <Badge
                          color='primary'
                          badgeContent={item.pref ? <StarIcon className={classes.xs} /> : 0}
                          variant='standard'
                          overlap='rectangular'
                        >
                          <Avatar variant='rounded' className={classes.small}>
                            {item.work ? <WorkIcon /> : <AlternateEmailIcon />}
                          </Avatar>
                        </Badge>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        style={{ fontSize: '146%' }}
                        primary={item.email}
                      />
                      <ListItemSecondaryAction className={classes.listAction}>
                        {mayDelete && (
                          <Button
                            variant='outlined'
                            size='small'
                            className={classes.deleteButton}
                            onClick={() => this.deleteItem('mail', item.id)}
                          >
                            <DeleteIcon />
                            <Hidden smDown>&nbsp; Entfernen</Hidden>
                          </Button>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : null}
              {!this.state.mailAdd && mayEdit ? (
                <Button
                  variant='contained'
                  size='small'
                  className={classes.addButton}
                  onClick={() => this.setState({ mailAdd: true })}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Hinzufügen{' '}
                </Button>
              ) : null}
              {this.state.mailAdd && mayEdit ? (
                <AddValue contact={this.state.contact} callback={this.addCallback} type={'mails'} />
              ) : null}
            </>

            <>
              <Typography className={classes.align} variant='subtitle1'>
                <HttpIcon />
                <b>Webseiten:</b>
              </Typography>
              {this.state.contact.webpages?.length > 0 ? (
                <List>
                  {Object.keys(this.state.contact.webpages).map((item, key) => (
                    <ListItem
                      key={'web-' + key}
                      button
                      component='a'
                      target='_blank'
                      rel='noreferrer'
                      href={
                        this.state.contact.webpages[item]?.webpage ||
                        this.state.contact.webpages[item]
                      }
                    >
                      <ListItemAvatar>
                        <Avatar variant='rounded' className={classes.small}>
                          <HttpIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        disableTypography={true}
                        style={{ fontSize: '146%' }}
                        primary={
                          this.state.contact.webpages[item]?.webpage ||
                          this.state.contact.webpages[item]
                        }
                      />
                      <ListItemSecondaryAction className={classes.listAction}>
                        {mayDelete && (
                          <Button
                            variant='outlined'
                            size='small'
                            className={classes.deleteButton}
                            onClick={() =>
                              this.deleteItem(
                                'webpages',
                                this.state.contact.webpages[item]?.webpage ||
                                  this.state.contact.webpages[item],
                              )
                            }
                          >
                            <DeleteIcon />
                            <Hidden smDown>&nbsp; Entfernen</Hidden>
                          </Button>
                        )}
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              ) : null}
              {!this.state.webpageAdd && mayEdit ? (
                <Button
                  variant='contained'
                  size='small'
                  className={classes.addButton}
                  onClick={() => this.setState({ webpageAdd: true })}
                  startIcon={<AddIcon />}
                >
                  {' '}
                  Hinzufügen{' '}
                </Button>
              ) : null}
              {this.state.webpageAdd && mayEdit ? (
                <AddValue
                  contact={this.state.contact}
                  callback={this.addCallback}
                  type={'webpages'}
                />
              ) : null}
            </>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton
              aria-label='download'
              disabled={!!this.state.contact.deleted_at}
              onClick={this.downloadVcard}
            >
              <GetAppIcon />
            </IconButton>
            {navigator.share ? (
              <IconButton
                aria-label='share'
                disabled={!!this.state.contact.deleted_at}
                onClick={this.shareContact}
              >
                <ShareIcon />
              </IconButton>
            ) : null}

            <Typography variant='subtitle1' component='span' className={classes.updated}>
              <UpdateIcon /> {readable(this.state.contact.updated_at)}
            </Typography>
          </CardActions>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  User: state.User,
  Theme: state.Style.Theme,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(ContactDetail);
