import React from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { withStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import MaterialTable from '@material-table/core';
import CustomToolbar from './CustomToolbar';
import CustomDatePicker from './CustomDatePicker';
import CustomCategoryPicker from './CustomCategoryPicker';
import CustomEditRow from './CustomEditRow';
import CustomPagination from './CustomPagination';
import CustomGroupRow from './CustomGroupRow';
import CustomSummaryRow from './CustomSummaryRow';
import TimeTrackingAdd from './TimeTrackingAdd';
import CustomUserPicker from './CustomUserPicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { DE as TextDE } from '../../lib/Text';
import { Constants } from '../../lib/Constants';
import { readableWithTime } from '../../lib/dateFunctions';

const styles = (theme) => ({
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: '16em',
    flexWrap: 'wrap',
  },
  chip: {
    margin: '2px 4px',
    maxWidth: 'calc( 100% - 16px)',
    fontWeight: 'bold',
  },
});

function calcTargetTime(rowData) {
  let time = rowData.user.working_hours[rowData.category.base] ?? 0;
  return time;
}

function renderTargetTimeAsString(rowData) {
  if (rowData.category.calculate === false) {
    return '';
  }

  let time = calcTargetTime(rowData);
  return (
    <div style={{ padding: '2px 8px', borderLeft: '2px solid grey' }}>
      {Number.parseInt(time / 60)
        .toString()
        .padStart(2, '0')}
      :
      {Number.parseInt(time % 60)
        .toString()
        .padStart(2, '0')}
    </div>
  );
}
function renderTargetTimeAsDecimal(rowData) {
  if (rowData.category.calculate === false) {
    return '';
  }

  let time = calcTargetTime(rowData);
  return (
    <div style={{ padding: '2px 8px', borderLeft: '2px solid grey' }}>
      {parseFloat(time / 60).toFixed(2)}
    </div>
  );
}

class TimetrackingTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteDialogOpen: false,
      toDeleteRow: null,
      usersAll: [],
      loadingUsersAll: true,
      loadingCategories: true,
      payedTimeSum: '',
      unpayedTimeSum: '',
      editRow: null,
      isOrganizer: Constants.roles.check(this.props.User, [
        Constants.roles.ROLES_NEWLY_REGISTERED,
        Constants.roles.ROLES_ADMIN,
        Constants.roles.ROLES_ORGANIZER,
      ]),
    };

    this.redirectTo = this.redirectTo.bind(this);
    this.handleDeleteDialogOpen = this.handleDeleteDialogOpen.bind(this);
    this.handleDeleteDialogClose = this.handleDeleteDialogClose.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.tableRef = React.createRef();
    this.fetchUsers = this.fetchUsers.bind(this);
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchCategories();
  }

  fetchUsers() {
    fetch(process.env.REACT_APP_API_URL + '/api/users', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        let tmp = {};
        json.forEach((value, key) => {
          if (!!!value.deleted_at) {
            tmp[value.uuid] = {
              uuid: value.uuid,
              firstname: value.firstname,
              nickname: value.nickname,
              lastname: value.lastname,
              email: value.email,
              working_hours: value.working_hours,
              roles: value.roles.map((role) => role.name),
            };
          }
        });
        //tmp = tmp.sort((a, b) => (a.lastname > b.lastname ? 1 : b.lastname > a.lastname ? -1 : 0));
        this.setState({ usersAll: tmp, loadingUsersAll: false });
      })
      .catch((error) => {
        return false;
      });
  }

  fetchCategories() {
    fetch(process.env.REACT_APP_API_URL + '/api/timetracking/categories', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.props.Authentication.access_token}`,
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((json) => {
        if (!!json.categories) {
          this.setState({ categories: json.categories, loadingCategories: false });
        } else {
          alert('NEU LADEN!');
        }
      })
      .catch((error) => {
        return false;
      });
  }

  handleDeleteDialogOpen(data) {
    //console.log("[handleDeleteDialogOpen]", data);
    this.setState({
      deleteDialogOpen: true,
      toDeleteRow: data,
    });
  }
  f;
  handleDeleteDialogClose() {
    this.setState({
      deleteDialogOpen: false,
      toDeleteRow: null,
    });
  }
  async handleDelete() {
    // do some network shenananiugans
    this.handleDeleteDialogClose();
    this.tableRef.current.onQueryChange();
  }

  redirectTo(uuid) {
    this.props.dispatch(push('/nachrichten/detail/' + encodeURI(uuid)));
  }

  componentDidUpdate(oldProps, oldState) {
    //console.log("[componentDidUpdate] STATE:",this.state, oldState)
    //console.log("[componentDidUpdate] PROPS",this.props, oldProps)
  }

  render() {
    const { classes } = this.props;

    const localization = {
      header: {
        actions: ' ',
      },
      toolbar: {
        searchPlaceholder: TextDE.search,
      },

      pagination: {
        firstAriaLabel: 'Erste Seite',
        firstTooltip: 'Erste Seite',
        previousAriaLabel: 'Vorherige Seite',
        previousTooltip: 'Vorherige Seite',
        nextAriaLabel: 'Nächste Seite',
        nextTooltip: 'Nächste Seite',
        lastAriaLabel: 'Letzte Seite',
        lastTooltip: 'Letzte Seite',
        labelDisplayedRows: '{from}-{to} von {count}',
        labelRowsSelect: 'Einträge',
        labelRowsPerPage: 'Einträge pro Seite',
      },
      grouping: {
        placeholder: 'Spaltenüberschriften hier her ziehen um zu gruppieren',
        groupedBy: 'Gruppiert nach:',
      },
      body: {
        deleteTooltip: 'Diese Zeile löschen',
        emptyDataSourceMessage: 'Keine Zeiten erfasst',
        filterRow: {
          filterTooltip: 'Filtern nach ...',
        },
        editRow: {
          deleteText: 'Diesen Zeiterfassungseintrag endgültig löschen? (Nicht wiederherstellbar!)',
          cancelTooltip: 'Abbrechen',
          saveTooltip: 'Löschen',
          edit: 'Editieren',
        },
      },
    };

    return (
      <>
        {this.state.loadingCategories || this.state.loadingUsersAll ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          <>
            <TimeTrackingAdd
              users={this.state.usersAll}
              categories={this.state.categories}
              edit={this.state.editRow}
              callback={(reload) => {
                this.setState({ editRow: null });
                if (!!reload) {
                  this.tableRef.current && this.tableRef.current.onQueryChange();
                }
              }}
            />

            <MaterialTable
              tableRef={this.tableRef}
              title='Zeiterfassung'
              localization={localization}
              components={{
                EditRow: (props) => <CustomEditRow {...props} />,
                SummaryRow: (props) => <CustomSummaryRow {...props} />,
                GroupRow: (props) => <CustomGroupRow {...props} />,
                Toolbar: (props) => (
                  <CustomToolbar
                    {...props}
                    usersAll={this.state.usersAll}
                    tableRef={this.tableRef}
                  />
                ),
                Pagination: (props) => (
                  <CustomPagination
                    classes={props.classes}
                    rowsPerPage={props.rowsPerPage}
                    rowsPerPageOptions={props.rowsPerPageOptions}
                    page={props.page}
                    count={props.count}
                    onPageChange={props.onPageChange}
                    onRowsPerPageChange={props.onRowsPerPageChange}
                    localization={localization.pagination}
                    icons={props.icons}
                    tableRef={this.tableRef}
                  />
                ),
              }}
              icons={{
                Filter: SearchIcon,
              }}
              actions={[
                {
                  icon: () => <EditIcon />,

                  position: 'row',
                  tooltip: localization.body.editRow.edit,
                  hidden: false,
                  onClick: (event, rowData) => {
                    this.setState({ editRow: rowData });
                  },
                },
              ]}
              options={{
                thirdSortClick: false,
                //addRowPosition: "first",
                pageSize: 15,
                pageSizeOptions: [15, 25, 50],
                paginationType: 'normal',
                export: true,
                columnsButton: false,
                exportButton: true,
                exportAllData: true,
                exportDelimiter: ';',
                filtering: true,
                grouping: true,
                search: false,
                showTitle: false,
                toolbar: true,
                actionsColumnIndex: -1,
                //selection: true,
                maxBodyHeight: `${this.props.height - 170}px`,
                minBodyHeight: `${this.props.height - 170}px`,
                filterCellStyle: { padding: '4px' },
                headerStyle: { padding: '4px 8px' },
                editCellStyle: { padding: '4px 8px' },
              }}
              editable={{
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    let url = new URL(process.env.REACT_APP_API_URL + '/api/timetracking');
                    let formData = {};
                    formData['_method'] = 'DELETE';
                    formData['id'] = oldData.id;

                    fetch(url, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.props.Authentication.access_token}`,
                      },
                      body: JSON.stringify(formData),
                    })
                      .then((res) => {
                        if (res.ok) {
                          return res.json();
                        } else {
                          throw res;
                        }
                      })
                      .then((json) => {
                        //console.log("[onRowDelete] after delete", json );
                        if (json.success === true) {
                          resolve();
                        } else {
                          reject();
                        }
                      })
                      .catch((error) => {
                        reject(error);
                      });
                  }),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    console.log('[onRowUpdate]', newData, oldData);
                    resolve();
                  }),
                isEditHidden: () => true,
                isDeleteHidden: () => false,
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  //console.log("My Query is:", query);
                  let url = new URL(process.env.REACT_APP_API_URL + '/api/timetracking');

                  query.filters.forEach((filter, index) => {
                    if (filter.column.field === 'startDate') {
                      let tmp =
                        filter.value != null
                          ? `${filter.value.getDate()}-${
                              filter.value.getMonth() + 1
                            }-${filter.value.getFullYear()}`
                          : null;
                      url.searchParams.set('dateStart', tmp);
                    } else if (filter.column.field === 'endDate') {
                      let tmp =
                        filter.value != null
                          ? `${filter.value.getDate()}-${
                              filter.value.getMonth() + 1
                            }-${filter.value.getFullYear()}`
                          : null;
                      url.searchParams.set('dateEnd', tmp);
                    } else {
                      let field = filter.column.field.toString().split('.').pop();
                      url.searchParams.set(
                        'filter[' + index + ']',
                        field + '|' + filter.operator + '|' + filter.value,
                      );
                    }
                  });

                  //url.searchParams.set('orderDirection', query.orderDirection);
                  if (query.orderBy != null) {
                    let field = query.orderBy.field.toString().split('.').pop();
                    url.searchParams.set('orderBy', field + '|' + query.orderDirection);
                  }

                  if (query.pageSize === 'Alle') {
                    url.searchParams.set('per_page', -1);
                  } else {
                    url.searchParams.set('per_page', query.pageSize);
                  }

                  url.searchParams.set('page', query.page + 1);

                  //if (query.search.trim() !== '' && query.search.length > 3) {
                  //  url.searchParams.set('search', query.search);
                  //}

                  fetch(url, {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${this.props.Authentication.access_token}`,
                    },
                  })
                    .then((res) => {
                      if (res.ok) {
                        return res.json();
                      } else {
                        throw res;
                      }
                    })
                    .then((json) => {
                      //this.setState({currentTableData: json.data});
                      resolve({
                        data: json.data,
                        page: json.current_page - 1,
                        totalCount: json.total,
                      });

                      //let sum = 0;
                      //let unpayed = 0;
                      //json.data.forEach(value => {
                      //  if (value.category?.calculate === "true") {
                      //    sum += value.total;
                      //  } else {
                      //    unpayed += value.total;
                      //  }
                      //})
                      //this.setState({payedTimeSum: sum, unpayedTimeSum: unpayed});
                    })
                    .catch((error) => {
                      return error;
                    });
                })
              }
              renderSummaryRow={() => true}
              columns={[
                {
                  title: TextDE.timetracking.columns.user_id.label,
                  field: 'user_id',
                  cellStyle: { padding: '4px 8px' },
                  type: 'string',
                  align: 'left',
                  grouping: true,
                  readonly: true,
                  editable: 'never',
                  export: true,
                  customExport: (rowData) => `${rowData.user.firstname} ${rowData.user.lastname}`,
                  filtering: true,
                  hidden: false,
                  searchable: true,
                  sorting: true,
                  render: (rowData, type) => {
                    if (type === 'group') {
                      rowData = { user: this.state.usersAll[rowData] };
                      return rowData.user.firstname + ' ' + rowData.user.lastname;
                    }
                    return (
                      <ListItem ContainerComponent='div' style={{ padding: '0px' }}>
                        <ListItemAvatar>
                          <Avatar
                            alt={rowData.user.firstname + ' ' + rowData.user.lastname}
                            src={
                              process.env.REACT_APP_API_URL +
                              '/avatar/' +
                              rowData.user.uuid +
                              '?thumb'
                            }
                          />
                        </ListItemAvatar>
                        <ListItemText
                          secondaryTypographyProps={{ component: 'div' }}
                          primary={rowData.user.firstname + ' ' + rowData.user.lastname}
                        />
                      </ListItem>
                    );
                  },
                  filterComponent: (props) =>
                    this.state.isOrganizer ? (
                      <CustomUserPicker usersAll={Object.values(this.state.usersAll)} {...props} />
                    ) : null,
                },
                {
                  title: TextDE.timetracking.columns.tracked_at.label,
                  field: 'tracked_at',
                  cellStyle: { padding: '4px 8px' },
                  width: '12em',
                  type: 'date',
                  grouping: true,
                  readonly: true,
                  editable: 'always',
                  export: true,
                  filtering: true,
                  hidden: false,
                  searchable: true,
                  sorting: true,
                  defaultSort: 'desc',
                  dateSetting: { locale: 'de-DE', format: 'dd.MM.yyyy' },
                  filterComponent: (props) => <CustomDatePicker {...props} />,
                },
                {
                  title: TextDE.timetracking.columns.start_time.label,
                  field: 'start_time',
                  cellStyle: { padding: '4px 8px' },
                  type: 'time',
                  width: '8em',
                  grouping: false,
                  readonly: true,
                  editable: 'always',
                  export: true,
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: true,
                  dateSetting: { locale: 'de-DE', format: 'HH:mm' },
                  render: (rowData) => `${rowData.start_time} Uhr`,
                },
                {
                  title: TextDE.timetracking.columns.end_time.label,
                  field: 'end_time',
                  cellStyle: { padding: '4px 8px' },
                  type: 'time',
                  width: '8em',
                  grouping: false,
                  readonly: true,
                  editable: 'always',
                  export: true,
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: true,
                  dateSetting: { locale: 'de-DE', format: 'HH:mm' },
                  render: (rowData) => `${rowData.end_time} Uhr`,
                },
                {
                  title: TextDE.timetracking.columns.break.label,
                  field: 'break',
                  cellStyle: { padding: '4px 8px' },
                  type: 'numeric',
                  align: 'left',
                  width: '6em',
                  grouping: false,
                  readonly: true,
                  editable: 'always',
                  export: true,
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: true,
                  render: (rowData) => `${rowData.break} Min`,
                },
                {
                  title: TextDE.timetracking.columns.categories.label,
                  field: 'category.category',
                  cellStyle: { padding: '0px' },
                  width: '12em',
                  align: 'left',
                  grouping: true,
                  readonly: true,
                  editable: 'always',
                  export: true,
                  filtering: true,
                  hidden: false,
                  searchable: true,
                  sorting: true,
                  render: (rowData, type, somethingElse) => {
                    if (type === 'group') {
                      return rowData;
                    }
                    return (
                      <div className={classes.chipContainer}>
                        {!!rowData.category ? (
                          <Chip
                            key={'labels-of-' + rowData.category}
                            label={rowData.category.category.toString().toUpperCase()}
                            className={classes.chip}
                            style={{
                              color: rowData.category.color,
                              backgroundColor: rowData.category.backgroundColor,
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  },
                  filterComponent: (props) => (
                    <CustomCategoryPicker categories={this.state.categories} {...props} />
                  ),
                },
                {
                  title: TextDE.timetracking.columns.total.asHoursLabel,
                  field: 'total',
                  width: '4.5em',
                  cellStyle: { padding: '4px  8px' },
                  align: 'right',
                  grouping: false,
                  readonly: true,
                  editable: 'never',
                  export: true,
                  customExportTitle: 'Zeit in Minuten',
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: true,
                  render: (rowData) =>
                    `${Number.parseInt(rowData.total / 60)
                      .toString()
                      .padStart(2, '0')}:${Number.parseInt(rowData.total % 60)
                      .toString()
                      .padStart(2, '0')}`,
                },
                {
                  title: TextDE.timetracking.columns.target_time.asHoursLabel,
                  width: '4.5em',
                  cellStyle: { margin: '2px 0px', padding: '0' },
                  align: 'left',
                  grouping: false,
                  readonly: true,
                  editable: 'never',
                  export: true,
                  customExportTitle: 'Soll in Minuten',
                  customExport: (rowData) => calcTargetTime(rowData),
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: false,
                  render: (rowData) => renderTargetTimeAsString(rowData),
                },
                {
                  title: TextDE.timetracking.columns.total.asDecimalLabel,
                  field: 'total',
                  width: '4.5em',
                  cellStyle: { padding: '4px 8px' },
                  align: 'right',
                  grouping: false,
                  readonly: true,
                  editable: 'never',
                  export: false,
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  render: (rowData) => `${parseFloat(rowData.total / 60).toFixed(2)}`,
                  sorting: true,
                },
                {
                  title: TextDE.timetracking.columns.target_time.asDecimalLabel,
                  field: null,
                  width: '4.5em',
                  cellStyle: { margin: '2px 0px', padding: '0' },
                  align: 'left',
                  grouping: false,
                  readonly: true,
                  editable: 'never',
                  export: false,
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: false,
                  render: (rowData) => renderTargetTimeAsDecimal(rowData),
                },
                {
                  title: TextDE.timetracking.columns.updated_at.label,
                  field: 'updated_at',
                  width: '12em',
                  cellStyle: { padding: '4px 8px' },
                  readonly: true,
                  align: 'right',
                  export: true,
                  customExport: (rowData) =>
                    `${readableWithTime(rowData.updated_at)} durch ${
                      rowData.updated_by.firstname
                    } ${rowData.updated_by.lastname}`,
                  grouping: false,
                  editable: 'never',
                  filtering: false,
                  hidden: false,
                  searchable: false,
                  sorting: false,
                  render: (rowData) =>
                    `${readableWithTime(rowData.updated_at)} durch ${
                      rowData.updated_by.firstname
                    } ${rowData.updated_by.lastname}`,
                },
                {
                  title: TextDE.timetracking.columns.startDate.label,
                  field: 'startDate',
                  cellStyle: { padding: '4px 8px' },
                  type: 'date',
                  defaultFilter: new Date(new Date() - 24 * 60 * 60 * 30 * 1000),
                  readonly: true,
                  editable: 'never',
                  export: false,
                  filtering: false,
                  hidden: true,
                  searchable: false,
                  sorting: false,
                },
                {
                  title: TextDE.timetracking.columns.endDate.label,
                  field: 'endDate',
                  cellStyle: { padding: '4px 8px' },
                  type: 'date',
                  defaultFilter: new Date(),
                  readonly: true,
                  editable: 'never',
                  export: false,
                  filtering: false,
                  hidden: true,
                  searchable: false,
                  sorting: false,
                },
              ]}
            />
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  Authentication: state.Authentication,
  Dimensions: state.Dimensions,
  Theme: state.Style.Theme,
  User: state.User,
});

const mapDispatchToProps = (dispatch) => ({ dispatch, push });

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(TimetrackingTable);
